import { t } from 'i18next';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { FilterContextInitalType, FilterProviderProps } from './types';

export const initialValues: FilterContextInitalType = {
	reportType: { label: t('Tipo de relatório'), value: '' },
	setReportType: () => {},
	financialType: 'projeto',
	setFinancialType: () => {},
	totalHourType: 'day',
	setTotalHourType: () => {},
	costType: 'projeto',
	setCostType: () => {},
	timeInterval: { value: '', label: t('Intervalo de Tempo') },
	setTimeInterval: () => {},
	startDate: undefined,
	setStartDate: () => {},
	endDate: undefined,
	setEndDate: () => {},
	idleHour: false,
	setIdleHour: () => {},
	selectedClient: { label: t('Clientes'), value: '' },
	setSelectedClient: () => {},
	selectedProject: [{ label: t('Projetos'), value: '' }],
	setSelectedProject: () => {},
	selectedTask: [{ label: t('Tarefas'), value: '' }],
	setSelectedTask: () => {},
	selectedPerson: { label: t('Pessoas'), value: '' },
	setSelectedPerson: () => {},
	resetFilters: () => {},
	search: '',
	setSearch: () => {},
};

export const FilterContext = createContext(initialValues);

const FilterGReportProvider = ({ children }: FilterProviderProps) => {
	const [reportType, setReportType] = useState(initialValues.reportType);
	const [financialType, setFinancialType] = useState(
		initialValues.financialType,
	);
	const [totalHourType, setTotalHourType] = useState(
		initialValues.totalHourType,
	);
	const [costType, setCostType] = useState(initialValues.costType);
	const [timeInterval, setTimeInterval] = useState(initialValues.timeInterval);
	const [startDate, setStartDate] = useState(initialValues.startDate);
	const [endDate, setEndDate] = useState(initialValues.endDate);
	const [idleHour, setIdleHour] = useState(initialValues.idleHour);
	const [selectedClient, setSelectedClient] = useState(
		initialValues.selectedClient,
	);
	const [selectedProject, setSelectedProject] = useState(
		initialValues.selectedProject,
	);
	const [selectedTask, setSelectedTask] = useState(initialValues.selectedTask);
	const [selectedPerson, setSelectedPerson] = useState(
		initialValues.selectedPerson,
	);
	const [search, setSearch] = useState(initialValues.search);

	const resetFilters = () => {
		setReportType(initialValues.reportType);
		setFinancialType(initialValues.financialType);
		setTotalHourType(initialValues.totalHourType);
		setCostType(initialValues.costType);
		setIdleHour(initialValues.idleHour);
		setSelectedClient(initialValues.selectedClient);
		setSelectedProject(initialValues.selectedProject);
		setSelectedTask(initialValues.selectedTask);
		setSelectedPerson(initialValues.selectedPerson);
	};

	const filterValue = useMemo(
		() => ({
			reportType,
			financialType,
			totalHourType,
			costType,
			timeInterval,
			startDate,
			endDate,
			idleHour,
			selectedClient,
			selectedProject,
			selectedTask,
			selectedPerson,
			search,
			resetFilters,
			setReportType,
			setFinancialType,
			setTotalHourType,
			setCostType,
			setTimeInterval,
			setStartDate,
			setEndDate,
			setIdleHour,
			setSelectedClient,
			setSelectedProject,
			setSelectedTask,
			setSelectedPerson,
			setSearch,
		}),
		[
			reportType,
			financialType,
			totalHourType,
			costType,
			timeInterval,
			startDate,
			endDate,
			idleHour,
			selectedClient,
			selectedProject,
			selectedTask,
			selectedPerson,
			search,
			resetFilters,
			setReportType,
			setFinancialType,
			setTotalHourType,
			setCostType,
			setTimeInterval,
			setStartDate,
			setEndDate,
			setIdleHour,
			setSelectedClient,
			setSelectedProject,
			setSelectedTask,
			setSelectedPerson,
			setSearch,
		],
	);

	return (
		<FilterContext.Provider value={filterValue}>
			{children}
		</FilterContext.Provider>
	);
};

const useGReportFilters = () => {
	const context = useContext(FilterContext);
	return context;
};

export { FilterGReportProvider, useGReportFilters };
