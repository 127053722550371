import { t } from 'i18next';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { SelectOptions } from '../../../models/Select';
import { FilterContextInitialType, FilterProvidedProps } from './types';

export const initialValues: FilterContextInitialType = {
	checkClosedTask: false,
	searchFilter: '',
	selectedStatus: { value: '', label: t('Datas') },
	selectedProject: [{ value: '', label: t('Selecionar Projeto') }],
	selectedClient: [{ value: '', label: t('Clientes') }],
	selectedTag: [{ value: '', label: t('Tag') }],
	selectedKeyResult: [{ value: '', label: t('Selecionar Key Result') }],
	setCheckClosedTask: () => {},
	setSearchFilter: () => {},
	setSelectedClient: () => {},
	setSelectedProject: () => {},
	setSelectedStatus: () => {},
	setSelectedTag: () => {},
	setSelectedKeyResult: () => {},
	setTagsFilter: () => {},
	tagsFilter: '',
	endDate: new Date(),
	startDate: new Date(),
	setEndDate: () => {},
	setStartDate: () => {},
	selectedFilters: [
		{ label: t('Minhas'), value: 'mine' },
		{ label: t('Clientes'), value: 'client' },
		{ label: t('Projetos'), value: 'project' },
		{ label: t('Status'), value: 'status' },
		{ label: t('Tag'), value: 'tag' },
		{ label: t('Encerradas'), value: 'encerradas' },
	],
	setSelectedFilters: () => {},
	checkCreatedByMe: true,
	setCheckCreatedByMe: () => {},
	checkMyTasks: true,
	setCheckMyTasks: () => {},
	selectedAuthor: [{ value: '', label: t('Autor') }],
	selectedPerson:  [{ value: '', label: t('Responsáveis') }],
	setSelectedAuthor: () => {},
	setSelectedPerson: () => {}

};

export const FilterContext = createContext(initialValues);

const FilterProvider = ({ children }: FilterProvidedProps) => {
	const [selectedClient, setSelectedClient] = useState<SelectOptions[]>(
		initialValues.selectedClient,
	);
	const [selectedProject, setSelectedProject] = useState<
		SelectOptions[] | SelectOptions
	>(initialValues.selectedProject);

	const [selectedKeyResult, setSelectedKeyResult] = useState<
		SelectOptions[] | SelectOptions
	>(initialValues.selectedKeyResult);

	const [selectedTag, setSelectedTag] = useState<SelectOptions[]>(
		initialValues.selectedTag,
	);
	const [checkClosedTask, setCheckClosedTask] = useState<boolean>(
		initialValues.checkClosedTask,
	);
	const [searchFilter, setSearchFilter] = useState<string>(
		initialValues.searchFilter,
	);
	const [selectedStatus, setSelectedStatus] = useState<SelectOptions>(
		initialValues.selectedStatus,
	);
	const [selectedFilters, setSelectedFilters] = useState<SelectOptions[]>(
		initialValues.selectedFilters,
	);
	const [startDate, setStartDate] = useState<Date>();
	const [endDate, setEndDate] = useState<Date>();

	const [tagsFilter, setTagsFilter] = useState<string>(
		initialValues.tagsFilter,
	);
	const [checkMyTasks, setCheckMyTasks] = useState<boolean>(initialValues.checkMyTasks);
	const [checkCreatedByMe, setCheckCreatedByMe] = useState<boolean>(initialValues.checkCreatedByMe);
	const [selectedAuthor, setSelectedAuthor] = useState<SelectOptions[]>(
		initialValues.selectedAuthor,
	);	const [selectedPerson, setSelectedPerson] = useState<SelectOptions[]>(
		initialValues.selectedPerson,
	);

	const filterValue = useMemo(
		() => ({
			selectedClient,
			setSelectedClient,
			selectedProject,
			setSelectedProject,
			selectedTag,
			setSelectedTag,
			checkClosedTask,
			setCheckClosedTask,
			searchFilter,
			setSearchFilter,
			tagsFilter,
			setTagsFilter,
			selectedStatus,
			setSelectedStatus,
			startDate,
			setStartDate,
			endDate,
			setEndDate,
			selectedFilters,
			setSelectedFilters,
			checkMyTasks,
			setCheckMyTasks,
			checkCreatedByMe,
			setCheckCreatedByMe,
			selectedPerson,
			setSelectedPerson,
			selectedAuthor,
			setSelectedAuthor,
			setSelectedKeyResult,
			selectedKeyResult
		}),
		[
			selectedClient,
			setSelectedClient,
			selectedProject,
			setSelectedProject,
			selectedTag,
			setSelectedTag,
			checkClosedTask,
			setCheckClosedTask,
			searchFilter,
			setSearchFilter,
			tagsFilter,
			setTagsFilter,
			selectedStatus,
			setSelectedStatus,
			startDate,
			setStartDate,
			endDate,
			setEndDate,
			selectedFilters,
			setSelectedFilters,
			checkMyTasks,
			setCheckMyTasks,
			checkCreatedByMe,
			setCheckCreatedByMe,
			selectedPerson,
			setSelectedPerson,
			selectedAuthor,
			setSelectedAuthor,
			setSelectedKeyResult,
			selectedKeyResult			
		],
	);

	return (
		<FilterContext.Provider value={filterValue}>
			{children}
		</FilterContext.Provider>
	);
};

function withFilter (Child: any){
	return function FilterComponent (props: any) {
		return (
			<FilterContext.Consumer>
				{context => <Child {...props} {...context} />}
			</FilterContext.Consumer>
		);
	}
} 


const useFilters = () => {
	const context = useContext(FilterContext);
	return context;
};

export { FilterProvider, withFilter, useFilters };
