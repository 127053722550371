import React from 'react';
import FormConfig from '../Form/form';
import { useLocation, useParams, } from 'react-router-dom';
import PageContainerProjectDetail, { LocationProjectDetails } from '../../../../../components/containers/PageContainer/ProjectDetails';



export default function ProjectParams() {
    
	const location = useLocation<LocationProjectDetails>();
	const { id } = useParams<{id: string}>();
   
    return (
        <PageContainerProjectDetail 
			location={location}
			projectId={parseInt(id)}
		>
				<FormConfig />
		</PageContainerProjectDetail>
	
    )
}