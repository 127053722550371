import React, { useState, useEffect } from 'react';

import CalendarioHome from './calendarioHome';

import TurTutorial from '../../components/tourTutorial';

import { useCalendarioRegistros } from '../../contexts/CalendarioRegistros';

import moment from 'moment';
import ReactGA from 'react-ga';

import './style.css';
import '../../i18n';
import { useUser } from '../../contexts/User';
import HeaderLink from '../../components/common/HeaderLink';
import { useHistory } from 'react-router-dom';

interface DiasDaSemanaProps {
  diaSemana: string;
  data: string;
}

export default function HomePage() {
	const {savedDataInicial, refreshCalendarioRegistros} = useCalendarioRegistros();
	const [diasDaSemanaArray, setDiasDaSemanaArray] = useState<DiasDaSemanaProps[]>([]);
	// const [loadingHoras, setLoadingHoras] = useState(false);

	const [dataInicialFilter, setDataInicialFilter] = useState('');
	const [dataFinalFilter, setDataFinalFilter] = useState('');

	// const [layoutSemanaMes, setLayoutSemanaMes] = useState(false);
	const {userDetails} = useUser();
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		if(userDetails?.id_pessoa.toString() !== '') {
			manipulaPeriodoSelecionado('semana');
		}
	}, [userDetails]);




	const manipulaPeriodoSelecionado = (opcao: string, diaAux = savedDataInicial) => {
		const periodoDeDias = [];
		let diaAlvo = moment(diaAux).format('ddd');
		let dataInicial = moment(diaAux).format('YYYY-MM-DD');
		let dataFinal = moment(diaAux).format('YYYY-MM-DD');

		switch (opcao) {
			case 'semana':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}

				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});

					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			case 'next':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}
				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});
					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			case 'prev':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}
				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});
					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			default:
				break;
		}

		if(dataInicial !== savedDataInicial){
			refreshCalendarioRegistros({
				dataInicial: dataInicial,
				dataFinal: dataFinal,
				idPessoa: userDetails?.id_pessoa
			});
		}
		setDataInicialFilter(dataInicial);
		setDataFinalFilter(dataFinal);
		setDiasDaSemanaArray(periodoDeDias);
	};

	return (
		<div id="pagina-principal" className='flex flex-col gap-5'>
			{userDetails && !userDetails?.has_finished_tour && <TurTutorial nomeRota="home" />}
			
      <HeaderLink type={'home'} />

      <CalendarioHome
        diasDaSemana={diasDaSemanaArray}
        dataInicial={dataInicialFilter}
        dataFinal={dataFinalFilter}
        weekNavigation={manipulaPeriodoSelecionado}
      />
		</div>
	);
}


export function RedirectHome() {
  const navigate = useHistory();

  navigate.push('/home/calendar')
  return
}