import React from 'react';

import { Skeleton } from '@mui/material';

import { random } from 'lodash';

function KanbanTaskSkeleton() {
	return (
		<div
			className="w-full bg-gray-200 flex flex-col justify-between gap-5 relative "
		>
			
			<Skeleton
				variant='rectangular'
				className='w-full h-[150px] z-30 rounded-default '
				sx={{ bgcolor: 'transparent' }}
			/>
			<div className="absolute w-full h-[150px] p-2 flex flex-col justify-between gap-5">
				<div className="w-3/4">
					<Skeleton variant='text' className='w-full bg-gray-50' />
				</div>
				<div className="flex flex-col gap-2">
					<div className='flex flex-row justify-between'>
					<Skeleton
						variant='rectangular'
						className='h-4 w-20 rounded-default bg-slate-300'
					/>
					<Skeleton
						variant="circular"
						width="24px"
						height="24px"
						className='bg-ivory-primary-lighter'
					/>
					</div>
					<div className='pt-3 border-t border-solid border-gray-300 '>
						<Skeleton
							variant='rectangular'
							className='h-6 w-28 bg-gray-300 rounded-default' />
					</div>
					
				</div>
			</div>
		</div>
	);
}

interface KanbanColumnSkeletonProps {
	columnArray: number[]
}

function KanbanColumnSkeleton({ columnArray }:KanbanColumnSkeletonProps) {
	return (
		<div className='flex flex-col gap-3 pr-5 border-r border-solid border-gray-300'>
			<Skeleton
				variant="rectangular"
				className="w-72 h-12 bg-gray-200 rounded-default"
			/>
			<Skeleton
				variant="rectangular"
				className="w-full h-10 bg-gray-300 rounded-default"
				
			/>
			{columnArray.map((item, index) => {
				return <KanbanTaskSkeleton key={`${index}-${item}`} />;
			})}
			
		</div>
	);
}

export default function KanbanSkeleton() {
	const columnOne = [1, 2, 3, 4, 5, 6, 7, 8];
	const columnTwo = [1, 2, 3, 4, 5];
	const columnThree = [1, 2, 3, 4, 5, 6, 7];
	const columnFour = [1, 2, 3];
	const columnCount = [columnOne, columnTwo, columnThree, columnFour];

	return (
		<div className='flex flex-row gap-5' id='react-tour_kanban'>
			{columnCount.map((columns) => (
				<KanbanColumnSkeleton columnArray={columns} key={random(0, 100)} />
			))}
		</div>
	);
}
