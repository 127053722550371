// import moment from 'moment';

export const removeSeconds = hour => {
	if (hour === 0) {
		return 0;
	} else {
		return hour.slice(0, -3);
	}
};

export const subtractHours = (hours, hoursSubtract) => {
	// if (hours === 0) {
	// 	return `-${hoursSubtract}`;
	// } else {
	// const hoursConvert = moment(`${hours}00`, 'hmm').format('HH:mm');

	let hoursInMinutes = convertIntHoursToMin(hours);
	let hoursSubtractInMinutes = convertHoursToMin(hoursSubtract);
	const finalSubtractMinute = hoursInMinutes - hoursSubtractInMinutes;
	const finalToPositiveValue = Math.abs(finalSubtractMinute);
	const finalHour = convertMinsToHrsMins(finalToPositiveValue);
	return `${finalSubtractMinute >= 0 ? '' : '-'}${finalHour}`;
	// }
};

export const convertMinToHours = min =>
	`0${(min / 60) ^ 0}`.slice(-2) + ':' + ('0' + (min % 60)).slice(-2);

export const convertHoursToMin = hours => {
	const hourSplit = hours.split(':');
	const hoursInMinutes = parseInt(hourSplit[0]) * 60 + parseInt(hourSplit[1]);
	return hoursInMinutes;
};

const convertIntHoursToMin = hours => {
	const min = hours * 60;
	return min;
};

const convertMinsToHrsMins = mins => {
	let h = Math.floor(mins / 60);
	let m = mins % 60;
	h = h < 10 ? '0' + h : h;
	m = m < 10 ? '0' + m : m;
	return `${h}:${m}`;
};

export const sumHoursWithMinutesString = (hours) => {
    let totalH = 0;
    let totalM = 0;

    hours.forEach(hour => {
        totalH += Number(hour.split(":")[0]);
        totalM += Number(hour.split(":")[1]);
    });

    if (totalM >= 60) {
        totalH += Math.floor(totalM / 60);
        totalM = totalM % 60;
    }

	const totalMinutesWithHours = convertIntHoursToMin(totalH) + totalM;

    return convertMinsToHrsMins(totalMinutesWithHours);
};

export const verificaData = () => {
	let dateObj = new Date();
	let month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	let date = ('0' + dateObj.getDate()).slice(-2);
	let year = dateObj.getFullYear();
	let shortDate = year + '-' + month + '-' + date;
	return shortDate;
};