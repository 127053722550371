import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	ModalConteudoGenerico,
	ModalPrincipalGenerico,
} from '../../../components/containers/Modais/ModalComSideGenerico';
import CicloForm from './form';
import { useTranslation } from 'react-i18next';
import { ModalContainerCiclo } from './style';
import { CicloResponse } from '../../../services/providers/okrs/ciclo/types';
import { toast } from 'react-toastify';
import { ciclosOkrsAtom } from '../../../atoms/Okrs';
import { useAtom } from 'jotai';
import CicloService from '../../../services/providers/okrs/ciclo';

export interface ModalCicloProps {
	setModalShow: Dispatch<SetStateAction<boolean>>;
	ciclo?: CicloResponse;
    getCiclosPagination: (page:number) => Promise<void>
	editingCiclo?: boolean;
}

export const ModalCiclo = ({
	setModalShow,
	editingCiclo = false,
	ciclo,
    getCiclosPagination
}: ModalCicloProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

    const cicloService = new CicloService()
    const [ciclosOrks, setCiclosOrks] = useAtom(ciclosOkrsAtom)

	const deleteCiclo = (id: number) => {	
		setLoading(true)	
		cicloService.deleteCiclo(
			id
		).then(async () => {
			toast.success(t('Ciclo removido com sucesso!'));

			if(ciclo) {
				setCiclosOrks(prev => {
					const tempCiclos = [...prev.ciclosOkrs].filter(item => item.id !== id);
					
					return {
						...prev,
						ciclosOkrs: tempCiclos
					}
				});

				await getCiclosPagination((ciclosOrks?.currentPage ?? 1))
				setModalShow(false);
			}
		}).catch((err: any) => {
			toast.error(err)
		}).finally(() => {
			setLoading(false)	
		});
	};
	
	return (
		<ModalPrincipalGenerico modalGenericoShow={setModalShow}>
			<ModalContainerCiclo>
				<ModalConteudoGenerico
					copyText=""
					title={editingCiclo ? t('Edição de Ciclo') : t('Novo Ciclo')}
					modalGenericoShow={setModalShow}
					btnSave={true}
					loadingBtn={loading}
					modalIsShowing={true}
					itemId={ciclo?.id}
					handleDeletar={editingCiclo ? deleteCiclo : undefined}
					saveAndContinue={false}
					onClickSave={() => {
						document.getElementById('formSideModal-ciclo')?.click();
					}}
					onClickSaveAndContinue={() => {}}
					showEllipsis={true}
				>
					<CicloForm
						editing={editingCiclo}
						ciclo={ciclo}
						loading={loading}
						setLoading={setLoading}
						setModalShow={setModalShow}
					/>
				</ModalConteudoGenerico>
			</ModalContainerCiclo>
		</ModalPrincipalGenerico>
	);
};
