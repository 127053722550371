export interface VerifyHasHeaderProps {
    dateValue: string;
    dateHeader: string;
    handleChangeDateHeader: (newDate: string) => void;
}

export const verifyHasHeader = ({dateValue, dateHeader, handleChangeDateHeader}: VerifyHasHeaderProps) => {
    if (dateHeader === '' || dateHeader !== dateValue) {
        handleChangeDateHeader(dateValue);
        return true;
    } else {
        return false;
    }
}