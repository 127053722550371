import React from 'react';
import './style.css';
const PlanusLogo = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 433.1 132.55"
			>
				<defs>
					<linearGradient
						id="Gradiente_sem_nome_25"
						x1="320.93"
						y1="825.71"
						x2="320.93"
						y2="682.95"
						gradientTransform="translate(1115.35 -301.68) rotate(90)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stopColor="#d7f205" />
						<stop offset="0.11" stopColor="#cef10e" />
						<stop offset="0.29" stopColor="#b4ed28" />
						<stop offset="0.52" stopColor="#8be751" />
						<stop offset="0.79" stopColor="#52df89" />
						<stop offset="0.99" stopColor="#23d9b7" />
					</linearGradient>
				</defs>
				<g id="Camada_2" data-name="Camada 2">
					<g id="Camada_1-2" data-name="Camada 1">
						<path
							className="cls-1"
							d="M75.32,59.11a35.42,35.42,0,0,1-2.9,14.3A36.32,36.32,0,0,1,64.55,85a35.87,35.87,0,0,1-11.71,7.66,37.41,37.41,0,0,1-14.26,2.75H20.81v35H0V23.13H38.6a37.41,37.41,0,0,1,14.26,2.75,36.4,36.4,0,0,1,11.7,7.59,35.56,35.56,0,0,1,7.86,11.41A34.92,34.92,0,0,1,75.32,59.11Zm-20.81-.27a19.84,19.84,0,0,0-5.78-14.1,20.61,20.61,0,0,0-6.31-4.29,18.82,18.82,0,0,0-7.78-1.61H20.81V79.38H34.64a18.25,18.25,0,0,0,7.78-1.68,20.66,20.66,0,0,0,6.31-4.49A21.18,21.18,0,0,0,53,66.69,20.1,20.1,0,0,0,54.51,58.84Z"
						/>
						<path
							className="cls-1"
							d="M118.68,130a26.55,26.55,0,0,1-5.17,1.74,26.2,26.2,0,0,1-6,.67,29.69,29.69,0,0,1-8.26-1.14,18,18,0,0,1-6.91-3.69,17.73,17.73,0,0,1-4.7-6.65,25,25,0,0,1-1.75-9.86V19h19.74v88.74c0,3.22.65,5.44,1.94,6.64a6.57,6.57,0,0,0,4.64,1.82,11.58,11.58,0,0,0,6.44-2Z"
						/>
						<path
							className="cls-1"
							d="M191,130.4H171.71v-4.16a31.8,31.8,0,0,1-10.81,4.83,51.36,51.36,0,0,1-11.74,1.34A29.39,29.39,0,0,1,139,130.73,22.82,22.82,0,0,1,131,125.9a21.57,21.57,0,0,1-5.17-7.45,24.23,24.23,0,0,1-1.81-9.53,22.15,22.15,0,0,1,2.89-11.48,24.83,24.83,0,0,1,7.85-8.19,36.3,36.3,0,0,1,11.61-4.9,60.29,60.29,0,0,1,14.3-1.61h11.14q0-6.85-4.43-10.88t-12.75-4a24.4,24.4,0,0,0-16.92,6.84L126.33,63.14A43.86,43.86,0,0,1,140.63,54a44.91,44.91,0,0,1,16.71-3.23,37.94,37.94,0,0,1,14.35,2.56,29.44,29.44,0,0,1,17.29,18A52.9,52.9,0,0,1,191,87.44Zm-19.33-19.33V96.7h-8.86a50.58,50.58,0,0,0-7.59.54,21.34,21.34,0,0,0-6.17,1.81,11.34,11.34,0,0,0-4.16,3.29,7.69,7.69,0,0,0-1.55,4.83,8.06,8.06,0,0,0,3.16,6.58,12.5,12.5,0,0,0,8.12,2.55,28.12,28.12,0,0,0,8.52-1.34A41,41,0,0,0,171.71,111.07Z"
						/>
						<path
							className="cls-1"
							d="M276.56,130.4H257.23V84.08A19.48,19.48,0,0,0,256,76.76a15.08,15.08,0,0,0-3.44-5.23,13.83,13.83,0,0,0-4.92-3.09,16.66,16.66,0,0,0-5.73-1A25.86,25.86,0,0,0,233.09,69a21.84,21.84,0,0,0-7.81,4.9V130.4H205.94V52.8h19.34l.13,5.77a20.7,20.7,0,0,1,4.3-3.22,33.62,33.62,0,0,1,5.3-2.48,34.1,34.1,0,0,1,5.64-1.55,31.13,31.13,0,0,1,5.57-.54,34.5,34.5,0,0,1,11.34,1.88,27.31,27.31,0,0,1,9.67,5.78A27.84,27.84,0,0,1,274,68.51a38.17,38.17,0,0,1,2.55,14.63Z"
						/>
						<path
							className="cls-1"
							d="M360.07,130.4H340.73v-5.91a20.12,20.12,0,0,1-4.36,3.29,34.7,34.7,0,0,1-5.24,2.42,35.24,35.24,0,0,1-5.63,1.54,29.71,29.71,0,0,1-5.44.54,34.6,34.6,0,0,1-11.35-1.88,26.74,26.74,0,0,1-9.6-5.77,27.75,27.75,0,0,1-6.64-10.07A39.08,39.08,0,0,1,290,99.92V52.8h19.33V99a19.42,19.42,0,0,0,1.27,7.32,15.41,15.41,0,0,0,3.36,5.23,13.15,13.15,0,0,0,4.83,3.09,16.32,16.32,0,0,0,5.71,1,22.91,22.91,0,0,0,16.24-6.44V52.8h19.34Z"
						/>
						<path
							className="cls-1"
							d="M433.1,108.65a19.65,19.65,0,0,1-2.69,10.27,24.52,24.52,0,0,1-7,7.52,32.25,32.25,0,0,1-9.69,4.56,38.41,38.41,0,0,1-10.63,1.55,40.17,40.17,0,0,1-16.35-3.29,34,34,0,0,1-13.12-10.54L385.3,106.9a26.22,26.22,0,0,0,8.06,6.92,20.13,20.13,0,0,0,9.8,2.48,15.5,15.5,0,0,0,7.59-1.68,5.41,5.41,0,0,0,3-5c0-2.33-1.44-4.27-4.3-5.84a62.8,62.8,0,0,0-12.08-4.63,57.35,57.35,0,0,1-8.86-3.43,32.17,32.17,0,0,1-7.66-5.1,24.49,24.49,0,0,1-5.37-6.91,19.37,19.37,0,0,1-2-9,19.71,19.71,0,0,1,2.62-10.13A23.88,23.88,0,0,1,383,57a32.14,32.14,0,0,1,9.62-4.63,37.63,37.63,0,0,1,10.9-1.62q17.76,0,29.06,13.43L421,75.89a26.57,26.57,0,0,0-7.66-6.58A18.81,18.81,0,0,0,404,66.9a15.44,15.44,0,0,0-3.7.47,16,16,0,0,0-3.62,1.34A8,8,0,0,0,393.9,71a5.06,5.06,0,0,0-1.08,3.16q0,3.07,4.1,5.37a60.72,60.72,0,0,0,11.74,4.56,54.39,54.39,0,0,1,9.4,3.76,36.65,36.65,0,0,1,7.79,5.3,23.61,23.61,0,0,1,5.3,6.85A18.8,18.8,0,0,1,433.1,108.65Z"
						/>
						<polygon
							className="cls-2"
							points="289.64 38.5 319.64 19.07 289.64 0 432.4 0 432.4 38.5 289.64 38.5"
						/>
					</g>
				</g>
			</svg>
		</>
	);
};

export default PlanusLogo;
