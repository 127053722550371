import React from 'react';
import {  ProjectPage } from '../../../../../../models/Project';
import { CiBookmark } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import { verifyCanEditProject } from '../../../../../../utils/funcoes/validarPerfil';
import { useUser } from '../../../../../../contexts/User';
import { t } from 'i18next';
import { LuKanbanSquare } from 'react-icons/lu';
import { Edit } from '@mui/icons-material';

interface ProjectCardBodyProps {
	data: ProjectPage;
	onSetProject: () => void;
	loading: boolean;
	onClickEdit: () => void;
}

export default function ProjectCardBody({
	data,
	onSetProject,
	loading,
	onClickEdit,
}: Readonly<ProjectCardBodyProps>) {
	const { userDetails } = useUser();
	const notMember = loading || !data.is_member;
	return (
		<div
			className={`flex ${
				notMember ? 'bg-border-gray opacity-50' : 'bg-transparent'
			}   flex-row items-center px-3   w-full `}
		>
			<div className={`border-b border-solid py-3 ${notMember ? 'border-gray-400' : 'border-border-gray'}   flex flex-row justify-between items-center w-full`}>
				<div
					className={`flex flex-row items-center  gap-3  w-full  hover:opacity-70`}
				>
					<CiBookmark size={20} />
					<Link
						to={{
							pathname: `/registros/tarefas`,
							state: { projeto: { value: data.id, label: data.nome } },
						}}
						className=" hover:underline hover:text-ivory-primary"
					>
						<span>
							{Array.isArray(data.tarefas)
									? data.tarefas.length
									: data.tarefas}{' '}
							{t('tarefas')}
						</span>
					</Link>
				</div>
				<div className="flex flex-row gap-2 items-center">
					{(verifyCanEditProject(userDetails, data.is_member, data.admins) ||
						data.is_member) && (
						<Link
							to={{
								pathname: `/registros/kanban`,
								state: { data },
							}}
							onClick={onSetProject}
							className="  hover:opacity-70 hover:text-ivory-primary"
						>
							<LuKanbanSquare size={20} className="" />
						</Link>
					)}
					{verifyCanEditProject(userDetails, data.is_member, data.admins) && (
						<button
							onClick={onClickEdit}
							className={`hover:opacity-70  ${
								loading ? 'cursor-progress' : 'cursor-pointer'
							}`}
						>
							<Edit fontSize="small" titleAccess={t('Editar')} />
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
