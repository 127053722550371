import { faPrint, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoaderCircle } from '../../../../components/common/loaders/LoaderCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ExcelHeader = ({
	title,
	getRelatorioCSV,
	loadingRelatorioCSV,
	getRelatorioXLSX,
	type = null,
}) => {
	const {t} = useTranslation();

	return (
	<div
		className="relatorio-header-container mr-6"
	>
		<p className="relatorio-title">{title}</p>
		<div id='react-tour__report-print' style={{ display: 'flex' }}>
			<div
				onClick={() => (type ? getRelatorioCSV(type) : getRelatorioCSV)}
				className="relatorio-print-container"
				disabled={true}
				style={{
					pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
					opacity: loadingRelatorioCSV ? '0.5' : '1',
				}}
			>
				<FontAwesomeIcon icon={faTable} className="print-icon-relatorio" />
				<p className="print-text-relatorio">{t("Gerar CSV")}</p>
				{loadingRelatorioCSV ? (
					<span style={{ marginLeft: '4px' }}>
						<LoaderCircle />{' '}
					</span>
				) : (
					<></>
				)}
			</div>
			<div
				onClick={() => (type ? getRelatorioXLSX(type) : getRelatorioXLSX)}
				className="relatorio-print-container"
				disabled={true}
				style={{
					pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
					opacity: loadingRelatorioCSV ? '0.5' : '1',
				}}
			>
				<FontAwesomeIcon icon={faTable} className="print-icon-relatorio" />
				<p className="print-text-relatorio">{t("Gerar Excel")}</p>
				{loadingRelatorioCSV ? (
					<span style={{ marginLeft: '4px' }}>
						<LoaderCircle />{' '}
					</span>
				) : (
					<></>
				)}
			</div>
			<div onClick={() => window.print()} className="relatorio-print-container">
				<FontAwesomeIcon icon={faPrint} className="print-icon-relatorio" />
				<p className="print-text-relatorio">{t("Imprimir")}</p>
			</div>
		</div>
	</div>
)};

export default ExcelHeader;
