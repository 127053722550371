import React from 'react';
import {
	faFile,
	faFileArchive,
	faFileExcel,
	faFileImage,
	faFilePdf,
	faFilePowerpoint,
	faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import Icon from '../Icons/icon';

export const IconFileType = ({ fileType }) => {
	switch (fileType) {
		case 'png' || 'jpeg':
			return (
				<>
					<Icon icon={faFileImage} />
				</>
			);
		case 'jpg':
			return (
				<>
					<Icon icon={faFileImage} />
				</>
			);
		case 'rar' || 'zip' || '7zip':
			return (
				<>
					<Icon icon={faFileArchive} />
				</>
			);
		case 'xlsx' || 'xls' || 'csv':
			return (
				<>
					<Icon icon={faFileExcel} />
				</>
			);
		case 'docx' || 'doc':
			return (
				<>
					<Icon icon={faFileWord} />
				</>
			);
		case 'pptx' || 'ppt':
			return (
				<>
					<Icon icon={faFilePowerpoint} />
				</>
			);
		case 'pdf':
			return (
				<>
					<Icon icon={faFilePdf} />
				</>
			);
		default:
			return (
				<>
					<Icon icon={faFile} />
				</>
			);
	}
};
