import { AxiosResponse } from "axios";
import { StopwatchRecord } from "../../../models/HoursRecord";
import HttpService from "../../config/http";

interface StopwatchParams {
    id_tarefa?: string;
    data?: string;
    horas_extras?: boolean;
}

export class StopwatchService {
    private api: HttpService;
    constructor(props?: any) {
        this.api = new HttpService(props);
    }
    postStopwatch = async ({id_tarefa = '', data = undefined, horas_extras = true}: StopwatchParams) => {
        const payload = {
            id_tarefa,
            data,
            horas_extras
        }

        const result: AxiosResponse<StopwatchRecord> = await this.api.post(`api/v1/pessoas-planus/cronometro/`, payload)
        return result;
    }

    getStopwatch = async ({id_tarefa = '', data = ''}: StopwatchParams) => {
        try {
            const result: AxiosResponse<StopwatchRecord | ""> = await this.api.get(`api/v1/pessoas-planus/cronometro/?id_tarefa=${id_tarefa}&data=${data}`)
            const tempData = result?.data ? result?.data : "";
            return tempData;
        }catch {
            console.log("erro");
            return "";
        }
    }

    
}