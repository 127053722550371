import React, { useCallback, useEffect, useRef, useState } from 'react';

import { t } from 'i18next';
import { GroupBase, MultiValue, SingleValue } from 'react-select';

import FilterContainer from '../../../components/containers/Filter';
import { Option } from '../../../components/containers/Filter/Option';
import { SelectFilterContainer } from '../../../components/containers/Filter/styled';
import { LoadOptionsAdditional, SelectOptions } from '../../../models/Select';
import { selectFilterStyle } from '../../../utils/extenal-libs';
import AsyncSelector from '../../../components/common/AsyncSelector';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { LoadOptions } from 'react-select-async-paginate';
import { MultiValueContainer } from '../../Registros/Tarefas/filtro/MultiValueContainer';
import { useAtom } from 'jotai';
import { filtrosTimesOkrsAtom, filtrosTimesOkrsInitialValues, loadingTimesAtom, objetivosManagerAtom, timesOkrsAtom } from '../../../atoms/Okrs';
import TimeOkrsService from '../../../services/providers/okrs/times';
import CicloService from '../../../services/providers/okrs/ciclo';
import moment from 'moment';

export interface TimesOkrsFilterProps {
	readonly getTimesPagination: (page: number) => Promise<void>
}

export function TimesOkrsFilter({getTimesPagination}: TimesOkrsFilterProps) {
    const timesOkrsService = new TimeOkrsService()
    const cicloService = new CicloService()

    const filterOptions: SelectOptions[] =  [
		{label: t('Times'), value: 'times' },
        {label: t('Ciclos'), value: 'ciclo'},
    ]

	const timeFilterRef = useRef(filtrosTimesOkrsInitialValues.times)
	const cicloFilterRef = useRef(filtrosTimesOkrsInitialValues.ciclo)

    const [openFilters, setOpenFilters] = useState(filterOptions); 
	const [timesFilters, setTimesFilters] = useAtom(filtrosTimesOkrsAtom)
    const [loadingTimes, setLoadingTimes] = useAtom(loadingTimesAtom)
    const [, setObjetivosManager] = useAtom(objetivosManagerAtom)
    const [timesOkrs,] = useAtom(timesOkrsAtom)

	const handleSearch = () => {
		setLoadingTimes(true)

		getTimesPagination(1).finally(() => {
			setLoadingTimes(false)
		})
	}

    const resetFilters = useCallback(() => {
		setTimesFilters({
			ciclo: { value: '', label: t('Ciclos') },
			search: "",
			times: [{ value: '', label: t('Times') }]
		})
    }, [])

    const elementsRef = {
        times: useRef<HTMLDivElement>(null),
        ciclo: useRef<HTMLDivElement>(null),
    }

	const handleChangeTimes = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
		setTimesFilters((prev) => ({
			...prev,
			times: (e as SelectOptions[])
		}))
	}, [setTimesFilters])

	const handleChangeCiclo = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
		setObjetivosManager({objetivosManager: {}})
		
		setTimesFilters((prev) => ({
			...prev,
			ciclo: (e as SelectOptions)
		}))
	}, [setTimesFilters])

	const handleChangeSearch = (search: string) => {
        setTimesFilters((prev) => ({
			...prev,
			search
		}));
    }

	const checkVisible = (value: keyof typeof elementsRef) => {
		const hasValue = (item: SelectOptions) => item.value === value; 
		const isVisible = openFilters.some(hasValue)
		return isVisible;
	}

	const formatComponents = (): Partial<SelectComponents<SelectOptions, boolean, GroupBase<SelectOptions>>> => {
		return {MultiValueContainer, Option}
	}

	useEffect(() => {
		if(timeFilterRef.current !== timesFilters.times) {
			handleSearch()
		}
	}, [timesFilters.times]);

	useEffect(() => {
		if((cicloFilterRef.current !== timesFilters.ciclo) || timesOkrs.timesOkrs.length === 0) {
			handleSearch()
		}
	}, [timesFilters.ciclo]);

    return (
        <FilterContainer
			filterOptions={filterOptions}
			handleSearch={handleSearch}
			loadingFilters={loadingTimes}
			openFilters={openFilters}
			resetFilters={resetFilters}
			searchFilter={timesFilters.search}
			selectedFilters={{...timesFilters}}
			setOpenFilters={setOpenFilters}
			setSearchFilter={handleChangeSearch}
			search={true}
		>

			{checkVisible('times') && 
				(<SelectFilterContainer  ref={elementsRef.times}>
					<AsyncSelector
						loadOptions={timesOkrsService.loadTimesSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional>}
						additional={{
							page: 1,
							placeholder: t('Todos'),
						}}
						components={formatComponents()}
						isMulti={true}
						value={timesFilters.times}
						isClearable={false}
						onChange={handleChangeTimes}
						placeholder={t('Times')}
						style={selectFilterStyle}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						blurInputOnSelect={false}
					/>
				</SelectFilterContainer>)
			}

			{checkVisible('ciclo') && 
				(<SelectFilterContainer  ref={elementsRef.ciclo}>
					<AsyncSelector
						loadOptions={cicloService.loadCiclosSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional>}
						additional={{
							page: 1,
							placeholder: t('Todos'),
						}}
						isMulti={false}
						components={formatComponents()}
						value={timesFilters.ciclo}
						isClearable={false}
						onChange={handleChangeCiclo}
						placeholder={t('Ciclos')}
						style={selectFilterStyle}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						blurInputOnSelect={false}
					/>
				</SelectFilterContainer>)
			}

			{timesFilters.ciclo?.value !== '' && (
				<div className='text-sm text-ivory-primary'>
					<p>{"Início do ciclo: "}{timesFilters.ciclo?.extra?.data_inicial && moment(timesFilters.ciclo?.extra?.data_inicial).format("DD/MM/YYYY")}
						{" -   Fim do ciclo: "}{timesFilters.ciclo?.extra?.data_final && moment(timesFilters.ciclo?.extra?.data_final).format("DD/MM/YYYY")}</p>
				</div>
			)}

    </FilterContainer>
    )
}