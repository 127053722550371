import React from 'react';
import { LoaderAlign, LoaderContent } from './styled';

interface LoaderCircleProps {
	color?: string;
}

export const LoaderCircle = ({color}: LoaderCircleProps) => (
	<>
		<LoaderAlign>
			<LoaderContent role='progressbar' color={color} />
		</LoaderAlign>
	</>
);
