import styled from "styled-components";

export const StopwatchContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

export const StopwatchContent = styled.div`
    height: 100%;
`;