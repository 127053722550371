import React, { useEffect, useState } from 'react';

import { PessoaSchema } from './validation';
import logo from '../../../../../assets/images/profile.png';

import { currencyMask } from '../../../../../utils/funcoes/currencyMask';

import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faExclamationTriangle,
	faPen,
} from '@fortawesome/free-solid-svg-icons';

import '../../../../../form/formGeral.css';
import './style.css';
import { useTranslation } from 'react-i18next';

import {getExtraFieldType} from '../../../../PersonManagement/utils';
import { PessoaFormProps } from './types';
import { Responsible } from '../../../../../models/Responsible';
import { extraColumnsAtom } from '../../../../../atoms/PersonManagementAtoms';
import { useAtom } from 'jotai';

const { useRef } = React;

export const PessoaForm = (props: PessoaFormProps) => {
	const {t} = useTranslation();
	const [custoHora, setCustoHora] = useState('');
	const [cargaHoraria] = useState(40);
	const [extraColumns] = useAtom(extraColumnsAtom);
	const buttonRef = useRef<HTMLInputElement>(null);

	let initialValues: any = {};
	let foto_perfil = logo;

	if (typeof props.item_edit !== 'undefined') {
		initialValues = {
			id: props.item_edit.id,
			nome: props.item_edit.nome,
			email: props.item_edit.email,
			acesso: props.item_edit.acesso,
			// notas: props.item_edit.notas,
			carga_horaria: props.item_edit.carga_horaria,
			custo_hora: null,
		};
		extraColumns?.forEach(item => {
			initialValues = {...initialValues, [item.nome]: props?.item_edit?.[item.nome as keyof Responsible]}
		})
		foto_perfil = props.item_edit.foto_perfil
			? props.item_edit.foto_perfil
			: logo;
	} else {
		initialValues = {
			nome: '',
			email: '',
			acesso: '',
			notas: '',
			carga_horaria: cargaHoraria,
			custo_hora: null,
		};
		extraColumns?.forEach(item => {
			initialValues = {...initialValues, [item.nome]: ""}
		})
		foto_perfil = logo;
	}

	useEffect(() => {
		if (props.item_edit) {
			setCustoHora(props.item_edit ? props.item_edit.custo_hora.toString() : '');
		} else {
			setCustoHora('');
		}
	}, [props.item_edit]);

	const fileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		const reader = new FileReader();
		const imgInfo = document.getElementById('img-form-edit') as HTMLImageElement;
		reader.onloadend = function() {
			imgInfo.src = reader.result ? reader.result?.toString() : "";
		};
		if (file) {
			reader.readAsDataURL(file);
		} else {
			foto_perfil = props?.item_edit?.foto_perfil
				? props.item_edit.foto_perfil
				: logo;
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={PessoaSchema}
			enableReinitialize={true}
			onSubmit={(values, { setFieldValue, resetForm }) => {
				const image_field = document.getElementsByClassName(
					'form-imgFile-Pessoa',
				) as HTMLCollectionOf<HTMLInputElement>;

				values['foto_perfil'] = image_field?.[0]?.files?.[0] || "";
				values['custo_hora'] = custoHora ? parseFloat(custoHora).toString() : "0";

				const form_data = new FormData();

				for (const key in values) {
					form_data.append(key, values[key]);
				}
				
				if (props.editing) {
					props.handleSubmit({
						params: form_data,
						extraFields: extraColumns?.map(item => ({valor: values[item.nome], campoExtra: item})) || [],
						itemId: props?.item_edit?.id
					});
				} else {
					props.handleSubmit({
						params: form_data,
						extraFields: extraColumns?.map(item => ({valor: values[item.nome], campoExtra: item})) || []
					});
				}

				const image_profile = document.getElementById('img-form-edit') as HTMLImageElement;

				setFieldValue('foto_perfil', '');
				image_field[0].value = '';
				image_profile.src = logo;
				resetForm(initialValues);
			}}
		>
			{({ errors, touched, values, handleChange }) => (
				<FormikForm>
					<fieldset>
						<div className="">
							<div className="col-12 col-md-12 col-lg-12">
								<div className="form-container-style">
									<div className="row">
										<div className="profile-header-container">
											<div className="profile-header-img">
												<img
													id="img-form-edit"
													className="img-circle"
													src={foto_perfil}
													alt="Foto de Perfil"
												/>
												<input
													className="form-imgFile-Pessoa"
													style={{ display: 'none' }}
													type="file"
													onChange={fileSelect}
													accept="image/*"
													ref={buttonRef}
												></input>
												<div
													className="edit-icon-container"
													onClick={() => {
														buttonRef.current?.click();
													}}
												>
													<FontAwesomeIcon
														icon={faPen}
														className="item-form-edit-icon"
													/>
												</div>
											</div>
										</div>
									</div>
									<label className="modal-label-side" htmlFor="nome">
										{t("Nome")}*
									</label>
									<Field
										autoComplete={'off'}
										type="text"
										name="nome"
										className={
											errors.nome && touched.nome
												? 'form-error-style-input'
												: 'form-default-style-input'
										}
									/>
									<ErrorMessage name="nome">
										{msg => (
											<div>
												<div className="input-error-style" role="alert">
													<FontAwesomeIcon
														icon={faExclamationTriangle}
														className="mr-1"
													/>
													{msg}
												</div>
											</div>
										)}
									</ErrorMessage>

									<label className="modal-label-side" htmlFor="email">
										E-mail*
									</label>
									<Field
										autoComplete={'off'}
										type="email"
										name="email"
										className={
											errors.email && touched.email
												? 'form-error-style-input'
												: 'form-default-style-input'
										}
									/>
									<ErrorMessage name="email">
										{msg => (
											<div>
												<div className="input-error-style" role="alert">
													<FontAwesomeIcon
														icon={faExclamationTriangle}
														className="mr-1"
													/>
													{msg}
												</div>
											</div>
										)}
									</ErrorMessage>
									<label className="modal-label-side" htmlFor="email">
										{t("Perfil do Usuário")}*
									</label>
									<select
										name="acesso"
										value={values.acesso}
										onChange={handleChange}
										className={
											errors.acesso && touched.acesso
												? 'form-error-style-input'
												: 'form-default-style-input'
										}
										style={{ display: 'block' }}
									>
										<option value="" label={t("Escolha um Perfil")} />
										<option value="m" label={t("Membro")} />
										<option value="g" label={t("Gerente de Projeto")} />
										<option value="a" label={t("Administador")} />
										<option value="e" label={t("Visitante")} />
									</select>
									<ErrorMessage name="acesso">
										{msg => (
											<div>
												<div className="input-error-style" role="alert">
													<FontAwesomeIcon
														icon={faExclamationTriangle}
														className="mr-1"
													/>
													{msg}
												</div>
											</div>
										)}
									</ErrorMessage>
									{values.acesso !== 'e' && (
										<>
											{' '}
											<label
												className="modal-label-side"
												htmlFor="carga_horaria"
											>
												{t("Carga Horária (Semanal)")}
											</label>
											<Field
												autoComplete={'off'}
												type="number"
												name="carga_horaria"
												className={
													errors.carga_horaria && touched.carga_horaria
														? 'form-error-style-input'
														: 'form-default-style-input'
												}
											/>
											<ErrorMessage name="carga_horaria">
												{msg => (
													<div>
														<div className="input-error-style" role="alert">
															<FontAwesomeIcon
																icon={faExclamationTriangle}
																className="mr-1"
															/>
															{msg}
														</div>
													</div>
												)}
											</ErrorMessage>
											<label className="modal-label-side" htmlFor="custo_hora">
												{t("Custo Hora")}
											</label>
											<Field
												autoComplete={'off'}
												type="text"
												value={custoHora}
												name="custo_hora"
												id="custo-hora"
												onChange={(e: React.ChangeEvent) => currencyMask(e, setCustoHora)}
												className={
													errors.custo_hora && touched.custo_hora
														? 'form-error-style-input'
														: 'form-default-style-input'
												}
											/>
											<ErrorMessage name="custo_hora">
												{msg => (
													<div>
														<div className="input-error-style" role="alert">
															<FontAwesomeIcon
																icon={faExclamationTriangle}
																className="mr-1"
															/>
															{msg}
														</div>
													</div>
												)}
											</ErrorMessage>
										</>
									)}

									{
										extraColumns?.map(item => {
											return (
												<>
													<label className="modal-label-side" htmlFor={item.nome}>
														{t(item.nome)}
													</label>
													<Field
														autoComplete={'off'}
														type={getExtraFieldType(item.tipo_expanded?.tipo || "").form}
														style={{width: getExtraFieldType(item.tipo_expanded?.tipo || "").form === "checkbox" ? "fit-content" : "auto"}}
														name={item.nome}
														id={item.nome}
														className={
															errors[item.nome] && touched[item.nome]
																? 'form-error-style-input'
																: 'form-default-style-input'
														}
													/>
													<ErrorMessage name={item.nome}>
														{msg => (
															<div>
																<div className="input-error-style" role="alert">
																	<FontAwesomeIcon
																		icon={faExclamationTriangle}
																		className="mr-1"
																	/>
																	{msg}
																</div>
															</div>
														)}
													</ErrorMessage>
												</>
											)
										})
									}
									<br />
									<input
										style={{ display: 'none' }}
										id="formSideModal-pessoa"
										type="submit"
									/>
								</div>
							</div>
						</div>
					</fieldset>
				</FormikForm>
			)}
		</Formik>
	);
};
