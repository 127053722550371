import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ModalSide } from "../../../components/containers/Modais/ModalSide";
import PessoaService from "../../../services/providers/responsible";
import { PessoaForm } from "../../Registros/RegistroNavBar/Pessoas/form";
import { ExtraFieldsPostProps, PostPessoaProps } from "../../Registros/RegistroNavBar/Pessoas/form/types";
import { BackBox, ModalWrapper } from "./styled";
import { PeopleManagementService } from "../../../services/providers/peopleManagement";
import { Responsible } from "../../../models/Responsible";

export interface PersonModalProps {
    isShowingModal: boolean;
    setIsShowingModal: Dispatch<boolean>;
	handleAfterPost?: (person: Responsible) => void;
}


export default function PersonModal({isShowingModal, setIsShowingModal,handleAfterPost}: PersonModalProps) {
	const peopleManagementService = new PeopleManagementService;

    const {t} = useTranslation();
	const [loadingScreen, setLoadingScreen] = useState(false);
	const [submitting, setSubmitting] = useState(false);

    const handleCloseModal = useCallback(() => {
        setIsShowingModal(false);
	}, [setIsShowingModal]);

    const handleClick = useCallback(() => {
		if (!submitting) {
			document.getElementById('formSideModal-pessoa')?.click();
		}
	}, [submitting]);

	const postExtraValues = async (person: Responsible,extraFields: ExtraFieldsPostProps[]) => {
		const extraArrayResult = await Promise.all(extraFields.map(async (extra) => {
			const result = await peopleManagementService.postUserValueColumn({
				coluna_empresa: extra.campoExtra.id,
				pessoa: person.id.toString(), 
				valor: extra.valor.toString()
			})

			return {
				coluna_empresa: result.coluna_empresa,
				pessoa: person.id,
				valor: result.valor,
				coluna_expanded: extra.campoExtra,
				id: result.id
			}
		}))
		.catch(error => {
			toast.error(error);
			setLoadingScreen(false);
			setSubmitting(false);
			return []
		});		

		
		toast.success(t('Pessoa cadastrada com sucesso!'));

		if(handleAfterPost !== undefined) {
			handleAfterPost({
				...person,
				valor_colunas_preenchidas: extraArrayResult
			});
		}
		handleCloseModal();
		setLoadingScreen(false);
		setSubmitting(false);
	};

	const _postPessoa = ({params, extraFields}: PostPessoaProps) => {
		setLoadingScreen(true);
		setSubmitting(true);

		const pessoaService = new PessoaService();
		pessoaService.postPessoa(
			params,
			response => {
				postExtraValues(response.data, extraFields);
			},
			error => {
				toast.error(error);
				setLoadingScreen(false);
				setSubmitting(false);
			},
		);
	};

	const handlePost = useCallback((params: PostPessoaProps) => _postPessoa(params),[_postPessoa]);
	return (
		<div>
			{isShowingModal && (
				<>
					<BackBox onClick={handleCloseModal}></BackBox>
					<ModalWrapper>
						<ModalSide
							title={t('Convidar pessoas')}
							handleCloseModal={handleCloseModal}
							btnSave={true}
							onClickSave={handleClick}
							loadingBtn={loadingScreen}
							saveAndContinue={false}
							isSide={false}
							isActived={true}
							width={400}
						>
							<PessoaForm
								handleSubmit={handlePost}
								editing={false}
							/>
						</ModalSide>
					</ModalWrapper>
				</>
            )}
		</div>
	);
}