import HttpService from '../../config/http';
import environment from '../../config/environment';
import { CallbackType, DataType } from '../types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { HoursRecord } from '../../../models/HoursRecord';
import { Report } from '../../../models/Report';
import { ProjectSimple } from '../../../models/Project';
import { t } from 'i18next';

interface ReqFilterProps {
	cliente: string;
	projetos: string;
	tarefas: string;
	pessoas: string;
	dataInicial: string;
	dataFinal: string;
}

interface PrintFilterProps {
	clientID: string;
	projectID: string;
	personID: string;
	initialDate: string;
	finalDate: string;
}

export default class RelatoriosService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getHoras = (
		{
			cliente,
			projetos,
			tarefas,
			pessoas,
			dataInicial,
			dataFinal,
		}: ReqFilterProps,
		success: CallbackType<AxiosResponse<DataType<HoursRecord[]>>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.get(
				`api/v1/registra-hora/?cliente=${cliente}&projeto__in=${projetos}&tarefa__in=${tarefas}&pessoa__in=${pessoas}&data__gte=${dataInicial}&data__lte=${dataFinal}&all=ocio&all=true`,
			)
			.then(result => {
				if (result?.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t('Erro ao tentar carregar os registros!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
	getHorasAsync = async (
		{
			cliente,
			projetos,
			tarefas,
			pessoas,
			dataInicial,
			dataFinal,
		}: ReqFilterProps,
		success: CallbackType<{ data: HoursRecord[] }>,
		errorCallback: CallbackType<string>,
		paginaAtual: (page: number) => void,
		paginaFinal: (page: number) => void,
		config: AxiosRequestConfig,
	) => {
		let resposta: HoursRecord[] = [];
		let url:
			| string
			| null = `api/v1/registra-hora/?paginate=123&relatoriototalhora=true&cliente=${cliente}&projeto__in=${projetos}&tarefa__in=${tarefas}&pessoa__in=${pessoas}&data__gte=${dataInicial}&data__lte=${dataFinal}&all=true`;
		let page_count = 1;
		let status: null | number = null;
		const result = (await this.api
			.get(url, config)
			.catch(err => errorCallback(err))) as AxiosResponse<
			DataType<HoursRecord[]>
		>;
		status = result?.status;
		if (status && status !== 499) {
			page_count = result.data.total_pages;
			resposta = resposta.concat(result.data.results);
			url = result.data.next;
			// Somente a primeira página existe
			if (url === null) {
				success({ data: resposta });
			}
			paginaAtual(1);
			paginaFinal(page_count);
			for (let page = 2; page <= page_count; page++) {
				paginaAtual(page);
				url = url
					? url.replace(
							environment.baseUrl[
								environment.currentEnvironment as keyof typeof environment.baseUrl
							].urlApi,
							'',
					)
					: '';
				const data = (await this.api
					.get(url, config)
					.catch(err => errorCallback(err))) as AxiosResponse<
					DataType<HoursRecord[]>
				>;
				status = data.status;
				if (status && status !== 499) {
					resposta = resposta.concat(data.data.results);
					url = data.data.next;
				}else {
					break;
				}
			}
			if (status && status !== 499) {
				success({ data: resposta });
			}
		}
	};

	getFinanceiroAsync = async (
		{
			cliente,
			projetos,
			tarefas,
			pessoas,
			dataInicial,
			dataFinal,
		}: ReqFilterProps,
		success: CallbackType<{ data: Report[] }>,
		errorCallback: CallbackType<string>,
		paginaAtual: (page: number) => void,
		paginaFinal: (page: number) => void,
		config: AxiosRequestConfig,
	) => {
		let resposta: Report[] = [];
		let url:
			| string
			| null = `api/v1/relatorios/financeiro-consolidado?paginate=123&cliente=${cliente}&projeto__in=${projetos}&tarefa__in=${tarefas}&pessoa__in=${pessoas}&data__gte=${dataInicial}&data__lte=${dataFinal}&all=true`;
		let page_count = 1;
		let status: number | null = null;

		const result = (await this.api
			.get(url, config)
			.catch(err => errorCallback(err))) as AxiosResponse<DataType<Report[]>>;
		status = result?.status;
		if (status && status !== 499) {
			page_count = result.data.total_pages;
			resposta = resposta.concat(result.data.results);
			url = result.data.next;
			// Somente a primeira página existe
			if (url == null) {
				success({ data: resposta });
			}
			paginaAtual(1);
			paginaFinal(page_count);

			for (let page = 2; page <= page_count; page++) {
				paginaAtual(page);
				url = url
					? url.replace(
							environment.baseUrl[
								environment.currentEnvironment as keyof typeof environment.baseUrl
							].urlApi,
							'',
					)
					: '';
				const data = (await this.api
					.get(url, config)
					.catch(err => errorCallback(err))) as AxiosResponse<
					DataType<Report[]>
				>;
				status = data?.status;
				if (status && status !== 499) {
					resposta = resposta.concat(data.data.results);
					url = data.data.next;
				}else{
					break;
				}
			}
			if (status && status !== 499) {
				success({ data: resposta });
			}
		}
	};
	getFinanceiro = (
		{
			cliente,
			projetos,
			tarefas,
			pessoas,
			dataInicial,
			dataFinal,
		}: ReqFilterProps,
		success: CallbackType<AxiosResponse<DataType<Report[]>>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.get(
				`api/v1/relatorios/financeiro-consolidado/?paginate=123&cliente=${cliente}&projeto__in=${projetos}&tarefa__in=${tarefas}&pessoa__in=${pessoas}&data__gte=${dataInicial}&data__lte=${dataFinal}&all=true`,
			)
			.then(result => {
				if (result?.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t('Erro ao tentar carregar os registros!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
	getRelatorioCusto = (
		page: number,
		{ clientID, projectID, personID, initialDate, finalDate }: PrintFilterProps,
		success: CallbackType<AxiosResponse<DataType<ProjectSimple[]>>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.get(
				`api/v1/relatorios/custo-projetos/?page=${page}&cliente=${clientID}&projeto__in=${projectID}&pessoa__in=${personID}&data__gte=${initialDate}&data__lte=${finalDate}&timestamp=${new Date().getTime()}&paginate=tre&all=true`,
			)
			.then(result => {
				if (result?.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(
							t('Erro ao tentar carregar os dados do relatorio!'),
						);
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
	getRelatorioCustoAsync = async (
		{ clientID, projectID, personID, initialDate, finalDate }: PrintFilterProps,
		type: string,
		success: CallbackType<{ data: ProjectSimple[] }>,
		errorCallback: CallbackType<string>,
		paginaAtual: (page: number) => void,
		paginaFinal: (page: number) => void,
		config: AxiosRequestConfig,
	) => {
		let resposta: ProjectSimple[] = [];
		let url:
			| string
			| null = `api/v1/relatorios/${type}/?paginate=tre&cliente=${clientID}&projeto__in=${projectID}&pessoa__in=${personID}&data__gte=${initialDate}&data__lte=${finalDate}&tipo=trab&all=true`;
		let page_count = 1;
		let status: null | number = null;
		const result = (await this.api
			.get(url, config)
			.catch(err => errorCallback(err))) as AxiosResponse<
			DataType<ProjectSimple[]>
		>;
		status = result?.status;
		if (status && status !== 499) {
			page_count = result.data.total_pages;
			resposta = resposta.concat(result.data.results);
			url = result.data.next;
			// Somente a primeira página existe
			if (url == null) {
				success({ data: resposta });
			}
			paginaAtual(1);
			paginaFinal(page_count);

			for (let page = 2; page <= page_count; page++) {
				paginaAtual(page);
				url = url
					? url.replace(
							environment.baseUrl[
								environment.currentEnvironment as keyof typeof environment.baseUrl
							].urlApi,
							'',
					)
					: '';
				const data = (await this.api
					.get(url, config)
					.catch(err => errorCallback(err))) as AxiosResponse<
					DataType<ProjectSimple[]>
				>;
				status = data?.status;
				if (status && status !== 499) {
					resposta = resposta.concat(data.data.results);
					url = data.data.next;
				}else {
					break;
				}
			}
			if (status !== 499) {
				success({ data: resposta });
			}
		}
	};

	getRelatorioCustoTempoOciosoAsync = async (
		{ clientID, projectID, personID, initialDate, finalDate }: PrintFilterProps,
		success: CallbackType<{ data: ProjectSimple[] }>,
		errorCallback: CallbackType<string>,
		paginaAtual: (page: number) => void,
		paginaFinal: (page: number) => void,
		config = {},
	) => {
		let resposta: any = [];
		let url = `api/v1/relatorios/custos-tempo-ocioso/?paginate=true&cliente=${clientID}&projeto__in=${projectID}&pessoa__in=${personID}&data__gte=${initialDate}&data__lte=${finalDate}&all=true&all=true`;
		let page_count = 1;
		let status: null | number = null;

		const result = (await this.api
			.get(url, config)
			.catch(error => errorCallback(error))) as AxiosResponse<DataType<any[]>>;
		
		status = result?.status;
		if (status && status !== 499) {
			page_count = result.data.total_pages;
			resposta = resposta.concat(result.data.results);
			url = result.data.next as string;
	
			// Somente a primeira página existe
			if (url == null) {
				success(resposta);
			}
			paginaAtual(1);
			paginaFinal(page_count);
	
			for (let page = 2; page <= page_count; page++) {
				paginaAtual(page);
				url = url.replace(
					environment.baseUrl[
						environment.currentEnvironment as keyof typeof environment.baseUrl
					].urlApi,
					'',
				);
				const data = (await this.api
					.get(url)
					.catch(error => errorCallback(error))) as AxiosResponse<
					DataType<any[]>
				>;
				status= data.status;
				if (status && status !== 499) {
					resposta = resposta.concat(data.data.results);
	
					url = data.data.next as string;
				}else{
					break;
				}
				
			}
			if(status && status !== 499) {
				success({ data: resposta });

			}
		}
	};

	getRelatorioCSV = (
		{
			cliente,
			projetos,
			tarefas,
			pessoas,
			dataInicial,
			dataFinal,
		}: ReqFilterProps,
		nome: string,
		success: CallbackType<AxiosResponse<string>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.getWithCustomHeader(
				`api/v1/relatorios/export/${nome}/?cliente=${cliente}&projeto__in=${projetos}&tarefa__in=${tarefas}&pessoa__in=${pessoas}&data__gte=${dataInicial}&data__lte=${dataFinal}&all=true`,
				// {
				// 	responseType: 'blob',
				// 	timeout: 30000,
				// },
			)
			.then(resp => {
				const downloadUrl = window.URL.createObjectURL(new Blob([resp.data]));
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', `${nome}.csv`); //any other extension
				document.body.appendChild(link);
				link.click();
				link.remove();
				if (resp.status === 200) {
					success(resp);
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
	getRelatorioXLSX = (
		{
			cliente,
			projetos,
			tarefas,
			pessoas,
			dataInicial,
			dataFinal,
		}: ReqFilterProps,
		nome: string,
		success: CallbackType<AxiosResponse<ArrayBuffer>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.getWithCustomHeader(
				`api/v1/relatorios/export/xlsx/${nome}/?cliente=${cliente}&projeto__in=${projetos}&tarefa__in=${tarefas}&pessoa__in=${pessoas}&data__gte=${dataInicial}&data__lte=${dataFinal}&all=true`,
				{
					responseType: 'arraybuffer',
					headers: {
						'Content-Type': 'blob',
					},
					timeout: 300000,
				},
			)
			.then(resp => {
				const downloadUrl = window.URL.createObjectURL(new Blob([resp.data]));
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', `${nome}.xlsx`); //any other extension
				document.body.appendChild(link);
				link.click();
				link.remove();
				if (resp.status === 200) {
					success(resp);
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getRelatorioSaldoXLSX = (
		cliente: string,
		projetos: string,
		administrador: string,
		tags: string,
		success: CallbackType<AxiosResponse<ArrayBuffer>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.getWithCustomHeader(
				`api/v1/relatorios/export/xlsx/relatorio-horas-totais/?cliente=${cliente}&projeto_id__in=${projetos}&administrador=${administrador}&tags=${tags}&all=true`,
				{
					responseType: 'arraybuffer',
					headers: {
						'Content-Type': 'blob',
					},
					timeout: 300000,
				},
			)
			.then(resp => {
				const downloadUrl = window.URL.createObjectURL(new Blob([resp.data]));
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', `relatorio-horas-totais.xlsx`); //any other extension
				document.body.appendChild(link);
				link.click();
				link.remove();
				if (resp.status === 200) {
					success(resp);
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getRelatorioSaldoCSV = (
		cliente: string,
		projetos: string,
		administrador: string,
		tags: string,
		success: CallbackType<AxiosResponse<ArrayBuffer>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.getWithCustomHeader(
				`api/v1/relatorios/export/relatorio-horas-totais/?cliente=${cliente}&projeto__in=${projetos}&administrador=${administrador}&tags=${tags}&all=true`,
				{
					responseType: 'arraybuffer',
					headers: {
						'Content-Type': 'blob',
					},
					timeout: 300000,
				},
			)
			.then(resp => {
				const downloadUrl = window.URL.createObjectURL(new Blob([resp.data]));
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', `relatorio-horas-totais.csv`); //any other extension
				document.body.appendChild(link);
				link.click();
				link.remove();
				if (resp.status === 200) {
					success(resp);
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
}
