import React, { ReactNode } from 'react';
import THeader, { THeaderProps } from './THeader';
import { LoaderPagination } from '../loaders/LoaderPagination';

interface TableProps extends Readonly<THeaderProps> {
	children: ReactNode;
	className?: string;
	pagination?: {
		loading: boolean;
		showLoader: boolean;
		onClick: (e?: React.UIEvent<HTMLDivElement | HTMLButtonElement, UIEvent> ) => void;
	}
}
export default function Table({ children, pagination, className, ...props }: Readonly<TableProps>) {
	return (
		<div onScroll={pagination?.onClick} className={`flex flex-col gap-2 overflow-x-auto max-h-full w-full`}>
		<table className={`w-full border-collapse indent-0  ${className}`}>
			<THeader {...props} />
			<tbody>
				{children}
			</tbody>
		</table>
		{pagination && <LoaderPagination {...pagination} />}
		</div>
	);
}
