import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function MenuIcon(props: IconProps) {
  return (
    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M0.125 0.25H18.875V2.34961H0.125V0.25ZM0.125 7.52539V5.47461H18.875V7.52539H0.125ZM0.125 12.75V10.6504H18.875V12.75H0.125Z" fill="white"/>
    </svg>
    
  )
}