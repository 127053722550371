import React from 'react';
import TotalHorasDiarias from './totalhorasdiarias';
import TotalHorasTarefas from './totalhorastarefas';

const TotalDeHoras = ({
	relatorioType,
	registros,
	dataInicial,
	dataFinal,
	loadingGetHoras,
	getRelatorioCSV,
	getRelatorioXLSX,
	loadingRelatorioCSV,
	handlePrint
}) => {
	return (
		<>
			{(() => {
				switch (relatorioType) {
					case 'totalHorasdia':
						return (
							<TotalHorasDiarias
								handlePrint={handlePrint}
								loadingGetHoras={loadingGetHoras}
								registros={registros}
								dataInicial={dataInicial}
								dataFinal={dataFinal}
								getRelatorioCSV={getRelatorioCSV}
								getRelatorioXLSX={getRelatorioXLSX}
								loadingRelatorioCSV={loadingRelatorioCSV}
							/>
						);
					case 'totalHorasTarefa':
						return (
							<TotalHorasTarefas
								handlePrint={handlePrint}
								loadingGetHoras={loadingGetHoras}
								registros={registros}
								dataInicial={dataInicial}
								dataFinal={dataFinal}
								getRelatorioCSV={getRelatorioCSV}
								getRelatorioXLSX={getRelatorioXLSX}
								loadingRelatorioCSV={loadingRelatorioCSV}
							/>
						);

					default:
						return null;
				}
			})()}
		</>
	);
};

export default TotalDeHoras;
