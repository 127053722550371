import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import { Link } from 'react-router-dom';

export const ModalBgCenter = styled.div`
	background: #f7f6f5;
`;

export const EmailAlert = styled.div`
	height: 20px;
	font-size: 14px;
	top: 18px;
	font-weight: 500;
	color: var(--subtitle-text-color);
`;

export const EmailLabel = styled.label`
	margin-left: 6px;
`;

export const ExtraAddButtons = styled.div `
	display: flex;
	gap: 16px;
`;

export const ButtonDetailTask = styled.div`
	width: fit-content;
	margin: 2.5vh 0 0 0;
	color: #637d8a;
	border-radius: 8px;
	padding: 8px;
	font-size: var(--small-text-size);
	font-weight: 500;
	background: #c0cfd826;
	outline: none;
	cursor: pointer;
`;

export const LinkDetailTask = styled(Link)`
	color: #637d8a;
	text-decoration: none;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const IconDetailTask = styled(FontAwesomeIcon)`
	font-size: 15px;
	margin-left: 6px;
`;

export const ModalLabel = styled.label`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	font-size: var(--medium-text-size);
	font-weight: 500;
	color: var(--subtitle-text-color);
	margin: 16px 0 2px 0;
`;

export const InputError = styled.div`
	height: 24px;
	margin-top: -15px;
	color: #f16d84;
	font-size: 12px;
	font-weight: 500;
	border-radius: 6px;
`;

export const FormikField = styled(Field)`
	border-radius: 7px;
	display: block;
	width: 100%;
	font-size: 14px;
	padding: 7px;
	outline: none;
	box-shadow: none;
	transition: 0.3s ease;

	${({ error, touched }) =>
		error && touched
			? `
    height: 37px;
    min-height: 37px;
    margin-bottom: 18px;
    color: #355060;
    border: 1px solid #f16d84;


    `
			: `

    color: #808080;
    border: 1px solid #c0cfd8d9;

    `}
`;

export const TextAreaField = styled(Field)`
	display: block;
	width: 100%;
	font-size: 14px;
	padding: 7px;
	outline: none;
	box-shadow: none;
	transition: 0.3s ease;
	color: #355060;
	resize: none;
	border-radius: 7px;
	min-height: calc(100% - 37px);
	border: 1px solid rgba(192, 207, 216, 0.8509803921568627);
	${({ error, touched }) =>
		error && touched
			? `
			min-height: calc(100% - 38px);
			margin-bottom: 18px !important;
			box-shadow: none;
			border: 1px solid #f16d84;
			transition: 0.3s ease;
	`
			: ``}
`;
