import React from 'react';

import HeaderLink from '../../../components/common/HeaderLink';
import ResizableKanban from '../../../components/containers/ResizableKanban';
import { Container } from './styled';

function ApontamentoHoras() {
	return (
		<>
			<HeaderLink type="task" />
			<Container>
				<ResizableKanban />
			</Container>
		</>
	);
}

export default ApontamentoHoras;
