import styled from "styled-components";
import { theme } from "../../../../../styles/theme";

import InputMask from "react-input-mask";


export const Td = styled.td`
    text-align:start;
    font-weight: 500;
    color: rgb(128, 153, 167);
    vertical-align: middle;
`


export interface InputProps {
    inputWidth?: string;
    fontSize?: string;
    customStyle?: string;
    isCurrency?: boolean;
}

export const Input = styled(InputMask)<InputProps>`
    text-align: start;
    color: ${({color}) => color ? color : 'rgb(128, 153, 167)'};
    font-weight: ${({color}) => color ? '700' : '400'};
    border: none;
    background-color: transparent;
    ${({fontSize}) => fontSize ? `${fontSize}` : 'font-size: 14px'};
    padding:  6px 0;
    width: ${({inputWidth}) => inputWidth ? `${inputWidth}px` : '-webkit-fill-available'};
    min-width: ${({inputWidth}) => inputWidth ? `${inputWidth}px` : '-webkit-fill-available'};
    white-space: nowrap;
    text-overflow: ellipsis;
    ${({customStyle}) => customStyle}
    ${({isCurrency}) => isCurrency && "padding-left: 32px"};

    &:focus{
        outline: 1px solid ${theme.colors.input.borderBackgroundInput};
    }
    &::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ${props => props.type === 'number' && `
        -moz-appearance: textfield;
    `}
`

export const Span = styled.span`
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(128, 153, 167);
    font-weight: 400;
    font-size: 12px
`