import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import animationData from '../../assets/animacao/planus-animation-primeiroacesso-2.json';
import AnimacaoBack from '../../assets/animacao/AnimaçaoBackground.svg';
import logoPlanus from '../../assets/images/logo-planus-beta.png';

import Lottie from 'react-lottie';
import ReactGA from 'react-ga';
import './style.css';

import "../../i18n";
import { useTranslation } from 'react-i18next';

export default function InvitePage({ match }) {
	const {t} = useTranslation();
	const myParam = match ? match.params.invite : null;
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	return (
		<>
			<div className="acesso-conteiner">
				<header className="acesso-header">
					<div>
						<img alt="" src={logoPlanus} />
					</div>
				</header>
				<div className="acesso-body">
					<div className="acesso-animacao">
						<img className="anim-back" src={AnimacaoBack} alt="" />
						<Lottie
							options={{
								animationData: animationData,
								loop: true,
							}}
							height={650}
							width={650}
						/>
					</div>
					<div className="acesso-convite">
						<div className="acesso-conviteContainer">
							<div className="acesso-title">
								{t("Você foi convidado a participar do Planus da sua empresa.")}
							</div>
							<NavLink
								to={`/primeiro_acesso/login/${myParam}`}
								className="acesso-convite-btn"
							>
								<div>{t("Acessar conta no Planus")}</div>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
