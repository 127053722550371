import React, { createContext, useContext, useMemo, useState } from 'react';

import { RecordHistoryContextInitialType, RecordHistoryProviderProps } from './types';
import { HoursRecord } from '../../models/HoursRecord';
import { sortByRecentDate } from '../../utils/funcoes/sort.ts';
import { record0 } from '../../__mocks__/fakeData';
import { useRegistrosDeHoras } from '../RegistrosDeHoras';
import { useCalendarioRegistros } from '../CalendarioRegistros';

export const initialValues: RecordHistoryContextInitialType = {
	handleAddRecordToHistory: () => {},
	recordsHistory: [
		record0
	],
	setRecordsHistory: () => {},
	loadingHistory: false,
	setLoadingHistory: () => {},
	totalPagesHistory: 1,
	setTotalPagesHistory: () => {},
	currentPageHistory: 1,
	setCurrentPageHistory: () => {},
	handleUpdateRecordToHistory: () => {},
	updateRecordHistory: () => {}
};

export const RecordHistoryContext = createContext(initialValues);

const RecordHistoryProvider = ({ children }: RecordHistoryProviderProps) => {
	const [recordsHistory, setRecordsHistory] = useState<HoursRecord[]>([]);
	const [loadingHistory, setLoadingHistory] = useState(false);
	const [totalPagesHistory, setTotalPagesHistory] = useState<number | null>(null);
	const [currentPageHistory, setCurrentPageHistory] = useState(1);

	const {saveRegistro, updateRegistroTime} = useRegistrosDeHoras();
	const {saveCalendarioRegistro, updateCalendarioRegistroTime} = useCalendarioRegistros();

	const handleAddRecordToHistory = (record: HoursRecord) => {
		const tempRecords = recordsHistory.slice(0, -1);

		saveRegistro(record);
		saveCalendarioRegistro(record);
		setRecordsHistory(sortByRecentDate([record, ...tempRecords], "data"))
	};

	const handleUpdateRecordToHistory = (record: HoursRecord) => {
		updateRegistroTime(record);
		updateCalendarioRegistroTime(record);
		updateRecordHistory(record);
	}

	const updateRecordHistory = (record: HoursRecord) => {
		const isInRecord = recordsHistory.find(item => item.id === record.id);
		if(isInRecord) {
			setRecordsHistory(sortByRecentDate([{...record}, ...recordsHistory],'data'))
		}
	}

	const historyValue = useMemo(
		() => ({
			handleAddRecordToHistory,
			recordsHistory,
			setRecordsHistory,
			loadingHistory,
			setLoadingHistory,
			totalPagesHistory,
			setTotalPagesHistory,
			currentPageHistory,
			setCurrentPageHistory,
			handleUpdateRecordToHistory,
			updateRecordHistory
			
		}),
		[
			handleAddRecordToHistory,
			recordsHistory,
			setRecordsHistory,
			loadingHistory,
			setLoadingHistory,
			totalPagesHistory,
			setTotalPagesHistory,
			currentPageHistory,
			setCurrentPageHistory,
			handleUpdateRecordToHistory,
			updateRecordHistory
		],
	);

	return (
		<RecordHistoryContext.Provider value={historyValue}>
			{children}
		</RecordHistoryContext.Provider>
	);
};

const useRecordHistory = () => {
	const context = useContext(RecordHistoryContext);
	return context;
};

export { RecordHistoryProvider, useRecordHistory };
