import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { FilterContextInitialType } from '../../../contexts/Filter/Task/types';
import { Column } from '../../../models/Column';
import { Task } from '../../../models/Task';
import formatArrayToFilter from '../../../utils/funcoes/formatArrayToFilter';
import HttpService from '../../config/http';
import { getDeliveryDateFilters } from '../../helpers/deliveryDate';
import { DataType } from '../types';
import { GetProjectByIdResponse } from './types';

export class KanbanService {
	private api: HttpService;
	
	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getProjectById = async (id: string) => {
		const result: AxiosResponse<GetProjectByIdResponse> = await this.api
			.get(`api/v1/projetos/${id}/?timestamp=${new Date().getTime()}`);

		if (result.status === 200) {
			return (result);
		}  else {
			throw new Error(t('Erro ao tentar carregar os projetos') + "!");
		}
	};

	getColumnsByProject = async (filterContext: FilterContextInitialType, isOkr: boolean) => {
		const { selectedProject } =  filterContext;
		const project = Array.isArray(selectedProject) ? selectedProject : [selectedProject];
		const projectId = project[0].value
		const result: AxiosResponse<DataType<Column[]>> = await this.api
			.get(`/api/v1/kanban/colunas-kanban/?projeto=${projectId}`);

		const tempArray = await Promise.all(result.data.results.map(async (item: any) => {
			const tasksResponse = await this.getTasksByColumn({
				columnId: item.id,
				projectId: projectId as number,
				filterContext,
				page: 1,
				isOkr
			});

			return {
				...item,
				total_pages: tasksResponse.total_pages,
				tarefas: tasksResponse.results
			}
		}));

		if (result.status === 200) {
			return ({ ...result.data, results: tempArray });
		} else {
			throw new Error(t('Erro ao recuperar colunas') + "!");
		}
	};

	postColumn = async (columnData: any) => {
		const result: AxiosResponse<Column> = await this.api
			.post(`/api/v1/kanban/colunas-kanban/`, columnData)

		if (result.status === 201) {
			return (result.data);
		} else {
			if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof Column]}`);
				}
			} else {
				throw new Error(t('Erro criar coluna') + "!");
			}
		}
	};

	patchColumn = async (columnData: any) => {
		const result: AxiosResponse<Column> = await this.api
			.patch(`/api/v1/kanban/colunas-kanban/${columnData.id}/?projeto=${columnData.projeto}`, columnData)

		if (result.status === 200) {
			return (result.data);
		} else {
			if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof Column]}`);
				}
			} else {
				throw new Error(t('Erro ao editar coluna') + "!");
			}
		}
	};

	deleteColumn = async ({columnId, projectId}: any) => {
		const result: AxiosResponse<null> = await this.api
			.delete(`/api/v1/kanban/colunas-kanban/${columnId}/?projeto=${projectId}`)

		if (result.status === 204) {
			return;
		} else {
			throw new Error(t('Erro ao deletar') + "!");
		}
	};

	getTasksByColumn = async ({
		columnId,
		filterContext,
		isOkr,
		projectId,
		page=1
	}: {
		columnId: string, projectId: number,  filterContext: FilterContextInitialType, page?: number, isOkr: boolean
	}) => {
		const {
			selectedAuthor,
			selectedPerson,
			checkClosedTask,
			searchFilter,
			selectedStatus,
			startDate,
			endDate,
			selectedTag,
			selectedKeyResult
		} =  filterContext;

		const {data_conclusao, data_conclusao__gte,data_conclusao__lte } = getDeliveryDateFilters(selectedStatus?.value as string, startDate, endDate );

		const keyresultId = Array.isArray(selectedKeyResult)
		? selectedKeyResult[0].value
		: selectedKeyResult.value;
		const okrFilter = isOkr ? `keyresults__in=${keyresultId}&projeto=${projectId}` : `projeto=${projectId}`

		const result: AxiosResponse<DataType<Task[]>> = await this.api
			.get(`/api/v1/tarefas/?serializer=kanban&paginate=true&all=true&coluna_kanban=${columnId}&${okrFilter}&page=${page}&search=${searchFilter}&responsaveis__in=${formatArrayToFilter(selectedPerson)}&criador__in=${formatArrayToFilter(selectedAuthor)}&status=${checkClosedTask ? 'done' : ''}&tags=${formatArrayToFilter(selectedTag)}&data_conclusao=${data_conclusao}&data_conclusao__gte=${data_conclusao__gte}&data_conclusao__lte=${data_conclusao__lte}`);
		if (result.status === 200) {
			return ({
				...result.data, 
				results: result.data.results.map((task: Task) => (
				{
					...task,
					sub_tarefas: [],
				}
				))
			});
		} else {
			throw new Error(t('Erro ao recuperar tarefas') + "!");
		}
	};

	getTasksWithNoColumn = async ({
		filterContext,
		isOkr,
		projectId,
		page=1,
		status=""
	} : {
		projectId: number,  filterContext: FilterContextInitialType, page?: number, status?: string, isOkr: boolean
	}): Promise<DataType<Task[]>>=> {
		const {
			selectedAuthor,
			selectedPerson,
			checkClosedTask,
			searchFilter,
			selectedTag,
			selectedStatus,
			startDate,
			endDate,
			selectedKeyResult
		} =  filterContext;
			const {data_conclusao, data_conclusao__gte,data_conclusao__lte } = getDeliveryDateFilters(selectedStatus?.value as string, startDate, endDate);
		if(status === '' && checkClosedTask) {
			return ({
				count: 0,
				next: null,
				previous: null,
				results: [],
				total_pages: 1
			})
		}
		
		const keyresultId = Array.isArray(selectedKeyResult)
		? selectedKeyResult[0].value
		: selectedKeyResult.value;
		const okrFilter = isOkr ? `keyresults__in=${keyresultId}&projeto=${projectId}` : `projeto=${projectId}`

		const result: AxiosResponse<DataType<Task[]>> = await this.api
			.get(`api/v1/tarefas/?serializer=kanban&paginate=true&colune_kanban_null=true&${okrFilter}&page=${page}&status=${status}&search=${searchFilter}&responsaveis__in=${formatArrayToFilter(selectedPerson)}&criador__in=${formatArrayToFilter(selectedAuthor)}&tags=${formatArrayToFilter(selectedTag)}&data_conclusao=${data_conclusao}&data_conclusao__gte=${data_conclusao__gte}&data_conclusao__lte=${data_conclusao__lte}`);

		if (result.status === 200) {
			return ({
				...result.data, 
				results: result.data.results.map((task: any) => (
				{
					...task,
					sub_tarefas: [],
				}
				))
			});
		} else {
			throw new Error(t('Erro ao recuperar tarefas') + "!");
		}
	};


	patchTaskInColumn = async (taskData: any) => {
		const result: AxiosResponse<Task> = await this.api
			.patch(`/api/v1/tarefas/${taskData.id}/?all=true&serializer=kanban`, taskData);

		if (result.status === 200) {
			return (result.data);
		} else {
			if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof Task]}`);
				}
			} else {
				throw new Error(t('Erro ao reposicionar tarefa') + "!");
			}
		}
	}

}
