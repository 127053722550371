import { t } from "i18next";

export const parseTaskDetailsToReadbleString = (status: string) => {
    switch (status) {
        case 'started':
            return t('Iniciada');
        case 'stopped':
            return t('Encerrada');
        case 'running':
            return t('Em progresso');
        case 'done':
            return t('Finalizada');
        default:
            return null;
    }
}