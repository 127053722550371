import React, { ReactElement } from 'react';

import { Container } from './styled';

interface LabelProps {
	text: string;
}

function Label({ text, ...rest }: LabelProps): ReactElement {
	return <Container {...rest}>{text}</Container>;
}

export default Label;
