import React, { useCallback, useState } from 'react';
import i18n from '..';

import imgBR from '../../assets/images/selectLanguage/ptBR.png';
import imgUS from '../../assets/images/selectLanguage/enUS.png';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';
import { customStylesSideModal } from '../../utils/extenal-libs';
import { useTranslation } from 'react-i18next';
import { SelectOptions } from '../../models/Select';
import styled from 'styled-components';


const LanguageOption = styled.div`
	display: flex;
	gap: 8px;

	img {
		width: 20px;
		height: 20px;
	}
`;

interface LanguageSelectorProps {
	handleAfterChange?: (lang: string) => void;
}

const LanguageSelector = ({handleAfterChange}: LanguageSelectorProps) => {
	const { t }= useTranslation();
	const options = [
		{ value: 'pt-BR', label: 'Português BR', image: imgBR },
		{ value: 'en-UK', label: 'English UK', image: imgUS },
		
	]

	const getCurrentLanguage = () => { 
		return options.filter((option) => option.value === i18n.language)[0]
	}
	const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguage())
	
	function changeLang(language: string) {
		i18n.changeLanguage(language, (err: any) => {
			if (err) return console.log('something went wrong loading', err);
		}).then(() => {
			if(language) {
				localStorage.setItem("PlanusLanguage", language);
			}
			setSelectedLanguage(getCurrentLanguage())
		});

		if(handleAfterChange !== undefined){
			handleAfterChange(language);
		}
	}

	const handleChangeLang = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
		const option = e as SelectOptions;
		changeLang(option.value.toString())
	}, [changeLang]);
	

	return (
		<ReactSelect
			options={options}
			defaultValue={selectedLanguage}
			value={selectedLanguage}
			name="language"
			onChange={handleChangeLang}
			placeholder={t("Selecione").toString()}
            formatOptionLabel={option => (
                <LanguageOption>
					<img src={option.image} alt="" />
					<span>{option.label}</span>
                </LanguageOption>
            )}
			styles={customStylesSideModal}
		/>
	);
};

export default LanguageSelector;