import React, { ReactNode } from 'react';
import DefaultCheckbox from '../../DefaultCheckbox';

interface TCellSelectAllProps {
	onClick: () => void;
	actions?: ReactNode;
	checked?: boolean;
	onCheck?: () => void;
}

export default function TCellSelectAll({
	onClick,
	actions,
	checked,
	onCheck
}: Readonly<TCellSelectAllProps>) {
	return (
		<th>
			<div className="flex flex-row gap-3 items-center justify-start cursor-pointer">
				<DefaultCheckbox
					onChange={onCheck}
					checked={checked}
					type="checkbox"
					onClick={() => onClick()}
				/>
				{actions}
			</div>
		</th>
	);
}
