import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPrint,
	faArrowAltCircleLeft,
	faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import './style.css';
import { useTranslation } from 'react-i18next';

export default function Calendario({
	registros,
	diasDaSemana,
	dataInicial,
	dataFinal,
	navegacaoSemanal,
	loadingGetHoras,
	handlePrint,
}) {
	const { t } = useTranslation();
	const [listDatas, setListDatas] = useState([]);
	const [listTarefas, setListTarefas] = useState([]);
	const [listRegistrosDataTarefa, setListRegistrosDataTarefa] = useState([]);
	const [diaAlvo, setDiaAlvo] = useState(moment());
	const [periodoDias, setPeriodoDias] = useState([]);
	const [periodoString, setPeriodoString] = useState('');

	useEffect(() => {
		GenerateDataReport();
		showThisWeekOrlastTextOrTwoDateBetweenWeek(dataInicial, dataFinal);
		setPeriodoDias(diasDaSemana);
	}, [registros]);

	const GenerateDataReport = () => {
		const listDatasAux = [];
		const listTarefasAux = [];
		const listRegistrosDataTarefaAux = [];
		// FILTRO DE DATAS E TAREFAS REPETIDAS E CRIAÇÃO DO ARRAY
		registros.forEach(registro => {
			// Datas
			let datasDuplicadas =
				listDatasAux.findIndex(data => {
					return registro.data === data.data;
				}) !== -1;

			if (!datasDuplicadas) {
				listDatasAux.push({
					data: registro.data,
					registros: [],
				});
			}
			// Tarefa
			let tarefaDuplicada =
				listTarefasAux.findIndex(tarefa => {
					return registro.tipo === 'trab'
						? registro.tarefa === tarefa.id
						: registro.pessoa_nome == tarefa.pessoa_nome &&
								tarefa.tipo == 'ocio';
				}) !== -1;

			if (!tarefaDuplicada) {
				listTarefasAux.push({
					tipo: registro.tipo,
					id: registro.tipo == 'trab' ? registro.tarefa : registro.tipo,
					nome: registro.tipo == 'trab' ? registro.tarefa_nome : 'Tempo ocioso',
					cliente_nome:
						registro.tipo == 'trab' ? registro.cliente_nome : 'Tempo ocioso',
					projeto_nome:
						registro.tipo == 'trab' ? registro.projeto_nome : 'Tempo ocioso',
					pessoa_nome: registro.pessoa_nome,
					registros: [],
				});
			}
		});

		// ADIÇÃO DE REGISTROS À PROPRIEDADE 'registros' DOS OBJETOS DOS ARRAY'S  "ListDatas" e "ListTarefas"
		registros.forEach(registro => {
			// Datas
			listDatasAux.forEach(data => {
				if (registro.data === data.data) {
					data.registros.push(registro.duracao);
				}
			});

			// Tarefas
			listTarefasAux.forEach(tarefa => {
				if (
					registro.tipo === 'trab'
						? registro.tarefa === tarefa.id
						: registro.pessoa_nome == tarefa.pessoa_nome &&
						  tarefa.tipo == 'ocio'
				) {
					tarefa.registros.push(registro.duracao);
				}
			});
		});
		// GERAÇÃO DE ARRAY DE CÉLULAS DA TABELA
		listDatasAux.forEach(data => {
			listTarefasAux.forEach(tarefa => {
				listRegistrosDataTarefaAux.push({
					tipo: tarefa.tipo,
					data: data.data,
					tarefa: tarefa.id,
					pessoa_nome: tarefa.pessoa_nome,
					registros: [],
				});
			});
		});
		// ADIÇÃO DE REGISTROS À CADA CÉLULA DA TABELA
		registros.forEach(registro => {
			listRegistrosDataTarefaAux.forEach(dataTarefa => {
				if (
					((registro.tipo === 'trab' &&
						registro.tarefa === dataTarefa.tarefa) ||
						(registro.tipo === 'ocio' &&
							registro.pessoa_nome === dataTarefa.pessoa_nome)) &&
					registro.data === dataTarefa.data &&
					registro.tipo === dataTarefa.tipo
				) {
					dataTarefa.registros.push(registro.duracao);
				}
			});
		});

		setListDatas(listDatasAux);
		setListTarefas(listTarefasAux);
		setListRegistrosDataTarefa(listRegistrosDataTarefaAux);
	};

	const calculaTotalHorasGeral = () => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;
		let totalSegundos = 0;

		registros.forEach(registro => {
			totalHoras = totalHoras + parseInt(registro.duracao.slice(0, 2));
			totalMinutos = totalMinutos + parseInt(registro.duracao.slice(3, 5));
			totalSegundos = totalSegundos + parseFloat(registro.duracao.slice(6, registro.duracao.length));
		});
		const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60);
		const minutes = ((tempTotalMinutos) % 60);
		let finalTotalMinutos = minutes < 10 ? `0${minutes}` : `${minutes}`;
        totalAbsoluto = `${totalHoras + parseInt(tempTotalMinutos / 60)}:${finalTotalMinutos}`;
		return totalAbsoluto;
	};
	const calculaTotalHorasEspecifico = registros => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;
		let totalSegundos = 0;

		registros.forEach(dado => {
			totalHoras = totalHoras + parseInt(dado.slice(0, 2));
			totalMinutos = totalMinutos + parseInt(dado.slice(3, 5));
			totalSegundos = totalSegundos + parseFloat(dado.slice(6, dado.length));
		});

		const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60);
		const minutes = ((tempTotalMinutos) % 60);
		let finalTotalMinutos = minutes < 10 ? `0${minutes}` : `${minutes}`;
        totalAbsoluto = `${totalHoras + parseInt(tempTotalMinutos / 60)}:${finalTotalMinutos}`;
		return totalAbsoluto;
	};

	const upperCaseDiaSemana = data => {
		return data[0].toUpperCase() + data.slice(1);
	};

	const showThisWeekOrlastTextOrTwoDateBetweenWeek = (
		dataInicial,
		dataFinal,
	) => {
		const diaAtualConvertido = moment().format('YYYY-MM-DD');
		if (
			moment(diaAtualConvertido).isBetween(
				dataInicial,
				dataFinal,
				undefined,
				'[]',
			)
		) {
			setPeriodoString(t('Esta Semana'));
		} else {
			if (
				moment(diaAtualConvertido)
					.subtract(7, 'days')
					.isBetween(dataInicial, dataFinal, undefined, '[]')
			) {
				setPeriodoString(t('Semana Passada'));
			} else {
				setPeriodoString(
					`${moment(dataInicial).format('L')} - ${moment(dataFinal).format(
						'L',
					)}`,
				);
			}
		}
	};

	const handlePrintData = () => {
		const dataInfo = listTarefas.map(tarefa => {
			const tempRow = {
				[t('Usuário')]: tarefa.pessoa_nome,
				[t('Tarefa')]: `${tarefa.nome}\n${tarefa.cliente_nome} - ${tarefa.projeto_nome}`,
			}

			return tempRow
		})

		let dataValues = listTarefas.map(tarefa => {
			const tempRow = {
			}

			periodoDias.forEach(date => {
				tempRow[`${upperCaseDiaSemana(date.diaSemana)} \n ${moment(date.data).format('DD/MM')}`] = listRegistrosDataTarefa.map(dataTarefa => {
					if (
						dataTarefa.tarefa === tarefa.id &&
						dataTarefa.data === date.data &&
						dataTarefa.registros.length !== 0
					) {
						return calculaTotalHorasEspecifico(dataTarefa.registros);
					}
					return null;
				}).filter(item => item)[0]
			})

			tempRow[t('Total')] = calculaTotalHorasEspecifico(tarefa.registros)

			return tempRow
		})
	
		const totalRow = {
		}

		periodoDias.forEach(date => {
			totalRow[`${upperCaseDiaSemana(date.diaSemana)} \n ${moment(date.data).format('DD/MM')}`] = date.registros ? calculaTotalHorasEspecifico(date.registros) : null
		})

		totalRow[t('Total')] = calculaTotalHorasGeral()

		dataValues = [...dataValues, totalRow]
		
		handlePrint({
			dataInfo, 
			dataValues, 
			periodoString, 
			title: t('Calendário Semanal')
		})
	}

	return (
		<div className="print-background">
			<div className="relatorio-header-container">
				<p className="relatorio-title">{t('Calendário Semanal')}</p>
				<div
					onClick={() => handlePrintData()}
					className="relatorio-print-container"
				>
					<FontAwesomeIcon icon={faPrint} className="print-icon-relatorio" />
					<p className="print-text-relatorio">{t('Imprimir')}</p>
				</div>
			</div>
			<div className="relatorio-weekNavigation">
				<FontAwesomeIcon
					onClick={() => {
						if (!loadingGetHoras) {
							navegacaoSemanal('prev', moment(diaAlvo).subtract(7, 'days'));
							setDiaAlvo(moment(diaAlvo).subtract(7, 'days'));
						}
					}}
					icon={faArrowAltCircleLeft}
					className="navigation-icon-relatorio"
				/>
				<div style={{ textAlign: 'center' }}>
					<p className="relatorio-weekNavigation-text">{periodoString}</p>
				</div>
				<FontAwesomeIcon
					onClick={() => {
						if (!loadingGetHoras) {
							navegacaoSemanal('next', moment(diaAlvo).add(7, 'days'));
							setDiaAlvo(moment(diaAlvo).add(7, 'days'));
						}
					}}
					icon={faArrowAltCircleRight}
					className="navigation-icon-relatorio"
				/>
			</div>
			<div id="printable">
				{loadingGetHoras ? (
					<CarregandoDados />
				) : (
					<table className="relatorio-table">
						<thead>
							<tr style={{ maxHeight: 52 }}>
								{/* <th
									style={{ textAlign: 'left', maxHeight: 52 }}
									className="cabecalho-periodo-totalhorasdiarias"
								>
									{dataInicialFinal}
								</th> */}
								<th className="cabecalho-table" style={{ textAlign: 'center' }}>
									{t('Usuário')}
								</th>
								<th
									className="cabecalho-table"
									style={{
										textAlign: 'center',
										borderLeft: '1px solid #c1c1c1',
									}}
								>
									{t('Tarefa')}
								</th>
								{periodoDias.map((dia, index) => (
									<th
										className="cabecalho-table-calendario-data-inativa"
										style={{
											color: index === 0 || index === 6 ? '#a2a2a2' : '#000',
										}}
									>
										<span className="cabecalho-table-calendario-diaSemana">
											{upperCaseDiaSemana(dia.diaSemana)}
										</span>
										<br />
										<span className="cabecalho-table-calendario-data">
											{moment(dia.data).format('DD/MM')}
										</span>
									</th>
								))}
								<th
									className="cabecalho-total"
									style={{
										background: '#406173',
										textAlign: 'center',
										color: '#ffff',
									}}
								>
									{t('Total')}
								</th>
							</tr>
						</thead>
						<tbody>
							{listTarefas.map(tarefa => (
								<tr style={{ maxHeight: 52 }}>
									<th
										style={{
											textAlign: 'center',
											background: '#fff',
											borderTop: '1px solid #c1c1c1',
										}}
									>
										{tarefa.pessoa_nome}
									</th>
									<th
										className="tarefa-cliente-projeto-th-calendario"
										style={{ maxHeight: 52, borderLeft: '1px solid #c1c1c1' }}
									>
										<span className="tarefa-th-calendario">{tarefa.nome}</span>
										<br />
										{`${tarefa.cliente_nome} - ${tarefa.projeto_nome}`}
									</th>
									{diasDaSemana.map((data, index) => (
										<td
											className="calendario-td-hora"
											style={{
												backgroundColor:
													index === 0 || index === 6 ? '#f1f1f1' : '#fff',
											}}
										>
											{listRegistrosDataTarefa.map(dataTarefa => {
												if (
													dataTarefa.tarefa === tarefa.id &&
													dataTarefa.data === data.data &&
													dataTarefa.registros.length !== 0
												) {
													return calculaTotalHorasEspecifico(
														dataTarefa.registros,
													);
												}
												return null;
											})}
										</td>
									))}

									<td
										style={{
											fontWeight: 'bold',
											textAlign: 'center',
											background: '#fff',
											borderTop: '1px solid #c1c1c1',
										}}
									>
										{calculaTotalHorasEspecifico(tarefa.registros)}
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td
									colSpan="1"
									className="span-table-botton-totalhorasdiarias"
									style={{
										textAlign: 'center',
									}}
								></td>
								<td
									colSpan="1"
									style={{
										textAlign: 'center',
										borderBottomLeftRadius: 'none',
										background: '#406173',
									}}
								></td>
								{diasDaSemana.map((dia, index) => (
									<td className="total-col-calendario">
										{listDatas.map(data => {
											if (dia.data === data.data) {
												return calculaTotalHorasEspecifico(data.registros);
											}
											return null;
										})}
									</td>
								))}
								<th id="total-table">{calculaTotalHorasGeral()}</th>
							</tr>
						</tfoot>
					</table>
				)}
			</div>
		</div>
	);
}

const CarregandoDados = () => (
	<>
		<div className="loader-alinhamento">
			<div className="loader-conteudo"></div>
		</div>
	</>
);
