import { t } from 'i18next';
import * as Yup from 'yup';

let tempObject = {
	nome: Yup.string().required(t('Campo obrigatório.')),
	email: Yup.string().required(t('Campo obrigatório.')),
	acesso: Yup.string().required(t('Campo obrigatório.')),
}

export const PessoaSchema = Yup.object().shape(tempObject);
