import React, { useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IoMdClose } from 'react-icons/io';
import { ConfirmModal } from '../../../containers/Modais/ConfirmModal';
import { t } from 'i18next';
import { CellData } from '../types';

export interface THeadCellProps {
	handleChange?: (value: string, id: string) => void;
	handleDelete?: (id: string) => void;
	item: CellData;
}

export default function THeadCell({
	handleChange,
	handleDelete,
	item:{title, id, isDragDisabled, width, ordenacao}
}: Readonly<THeadCellProps>) {
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const handleConfirmDelete = useCallback(() => {
		if (handleDelete && id) {
			handleDelete(id);
		}
	}, [id, handleDelete]);

	return (
		<>
			{id && handleChange && ordenacao ? (
				<Draggable isDragDisabled={isDragDisabled}  draggableId={id} index={ordenacao}>
					{(provided) => {
						return (
							<th
								className="text-ivory-primary text-base font-black group pr-4 "
								style={{ width: width }}
								{...provided.draggableProps}
								ref={provided.innerRef}
								{...provided.dragHandleProps}
								
							>
								
								<div className="flex flex-row items-center gap-1">
									{isEditing ? (
										<input
											onBlur={(e) => {
								
												handleChange(e.target.value, id);
												setIsEditing(false);
											}}
											className={`border-none bg-transparent w-32 min-w-[120px] focus:ring-neutral-600 pr-8`}
											disabled={!handleChange}
											onKeyUp={(e) => {
												if (e.key === 'Enter') {
													handleChange(e.currentTarget.value, id);
													setIsEditing(false);
												}
											}}
											defaultValue={title}
											type="text"
											autoFocus
										/>
									) : (
										<button
											disabled={!handleChange}
											className="bg-transparent p-0 border-none whitespace-nowrap text-start"
											onClick={() => setIsEditing(true)}
										>
											{title}
										</button>
									)}
									{id && handleDelete && (
										<IoMdClose
											className="cursor-pointer opacity-0 group-hover:opacity-100"
											onClick={() => setIsConfirmModalOpen(true)}
										/>
									)}
								</div>
							</th>
						);
					}}
				</Draggable>
			) : (
				<th
					className="text-ivory-primary text-base font-black group pr-4"
					style={{ width: width }}
				>
					<p className="bg-transparent p-0 border-none whitespace-nowrap">
						{title}
					</p>
				</th>
			)}
			<ConfirmModal
				loading={false}
				text={t('Excluir coluna selecionada?')}
				handleConfirm={handleConfirmDelete}
				isConfirmModalOpen={isConfirmModalOpen}
				setIsConfirmModalOpen={setIsConfirmModalOpen}
			/>
		</>
	);
}
