import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faTable } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import './style.css';
import { useTranslation } from 'react-i18next';

export default function TotalHorasDiarias({
    registros,
    dataInicial,
    dataFinal,
    loadingGetHoras,
    getRelatorioCSV,
    getRelatorioXLSX,
    loadingRelatorioCSV,
	handlePrint
}) {
	const {t} = useTranslation();
    // Objetos gerados a partir da requisição
    const [listDatas, setListDatas] = useState([]);
    const [listTarefas, setListTarefas] = useState([]);
    const [listRegistrosDataTarefa, setListRegistrosDataTarefa] = useState([]);
	const relatorioTitle = t("Total de horas por dia")

    const [dataInicialFinal, setDataInicialFinal] = useState('');

    useEffect(() => {
        setDataInicialFinal(
            `${moment(dataInicial).format('L')} - ${moment(dataFinal).format('L')}`,
        );
    }, []);

    useEffect(() => {
        GenerateDataReport();
        setDataInicialFinal(
            `${moment(dataInicial).format('L')} - ${moment(dataFinal).format('L')}`,
        );
    }, [registros]);

    const GenerateDataReport = () => {
        const listDatasAux = [];
        const listTarefasAux = [];
        const listRegistrosDataTarefaAux = [];
        // FILTRO DE DATAS E TAREFAS REPETIDAS E CRIAÇÃO DO ARRAY
        registros.forEach(registro => {
            // Datas
            let datasDuplicadas =
                listDatasAux.findIndex(data => {
                    return registro.data === data.data;
                }) !== -1;

            if (!datasDuplicadas) {
                listDatasAux.push({
                    data: registro.data,
                    registros: [],
                });
            }
            // Tarefa
            let tarefaDuplicada =
                listTarefasAux.findIndex(tarefa => {
                      return registro.tipo === 'trab' ? registro.tarefa === tarefa.id : registro.pessoa_nome == tarefa.pessoa_nome && tarefa.tipo == 'ocio';
                }) !== -1;

            if (!tarefaDuplicada) {
                listTarefasAux.push({
                    tipo: registro.tipo,
                    id:   registro.tipo ==  'trab' ? registro.tarefa : registro.tipo,
                    nome: registro.tipo == 'trab' ? registro.tarefa_nome : 'Tempo ocioso',
                    cliente_nome: registro.tipo == 'trab' ? registro.cliente_nome : 'Tempo ocioso',
                    projeto_nome: registro.tipo == 'trab' ? registro.projeto_nome : 'Tempo ocioso',
                    pessoa_nome: registro.pessoa_nome,
                    registros: [],
                });
            }
        });

        // ADIÇÃO DE REGISTROS À PROPRIEDADE 'registros' DOS OBJETOS DOS ARRAY'S  "ListDatas" e "ListTarefas"
        registros.forEach(registro => {
            // Datas
            listDatasAux.forEach(data => {
                if (registro.data === data.data) {
                    data.registros.push(registro.duracao);
                }
            });

            // Tarefas
            listTarefasAux.forEach(tarefa => {
                if (registro.tipo === 'trab' ? registro.tarefa === tarefa.id : registro.pessoa_nome == tarefa.pessoa_nome && tarefa.tipo == 'ocio') {
                    tarefa.registros.push(registro.duracao);
                }
            });
        });
        // GERAÇÃO DE ARRAY DE CÉLULAS DA TABELA
        listDatasAux.forEach(data => {
            listTarefasAux.forEach(tarefa => {
                listRegistrosDataTarefaAux.push({
                    tipo: tarefa.tipo,
                    data: data.data,
                    tarefa: tarefa.id,
                    pessoa_nome: tarefa.pessoa_nome,
                    registros: [],
                });
            });
        });
        // ADIÇÃO DE REGISTROS À CADA CÉLULA DA TABELA
        registros.forEach(registro => {
            listRegistrosDataTarefaAux.forEach(dataTarefa => {
                if (
                    (
						(registro.tipo === 'trab' && registro.tarefa === dataTarefa.tarefa) || 
						(registro.tipo === 'ocio' && registro.pessoa_nome === dataTarefa.pessoa_nome)
					) &&
                    registro.data === dataTarefa.data &&
                    registro.tipo === dataTarefa.tipo
              ){
                dataTarefa.registros.push(registro.duracao);
              }
            });
        });

        setListDatas(listDatasAux);
        setListTarefas(listTarefasAux);
        setListRegistrosDataTarefa(listRegistrosDataTarefaAux);
    };

    const upperCaseDiaSemana = data => {
        var diaSemana = moment(data).format('ddd');
        return diaSemana[0].toUpperCase() + diaSemana.slice(1);
    };

    const calculaTotalHorasGeral = () => {
        let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;
		let totalSegundos = 0;
		registros.forEach(registro => {
			totalHoras = totalHoras + parseInt(registro.duracao.slice(0, 2));
			totalMinutos = totalMinutos + parseInt(registro.duracao.slice(3, 5));
			totalSegundos = totalSegundos + parseFloat(registro.duracao.slice(6, registro.duracao.length));
		});

		const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60);
		const minutes = ((tempTotalMinutos) % 60);
		let finalTotalMinutos = minutes < 10 ? `0${minutes}` : `${minutes}`;
		totalAbsoluto = `${totalHoras + parseInt(tempTotalMinutos / 60)}:${finalTotalMinutos}`;
	return totalAbsoluto;
	};
	const calculaTotalHorasEspecifico = registros => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;			
		let totalSegundos = 0;

		registros.forEach(registro => {
			totalHoras = totalHoras + parseInt(registro.slice(0, 2));
			totalMinutos = totalMinutos + parseInt(registro.slice(3, 5));
			totalSegundos = totalSegundos + parseFloat(registro.slice(6, registro.length));
		});
		
		const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60);
		const minutes = ((tempTotalMinutos) % 60);
		let finalTotalMinutos = minutes < 10 ? `0${minutes}` : `${minutes}`;
		totalAbsoluto = `${totalHoras + parseInt(tempTotalMinutos / 60)}:${finalTotalMinutos}`;
		return totalAbsoluto;
	};

	const handlePrintData = () => {
		const dataInfo = listTarefas.map(tarefa => {
			const tempRow = {
				[t("Projeto")]: tarefa.projeto_nome,
				[t("Responsável")]: tarefa.pessoa_nome,
				[t("Tarefa")]: tarefa.nome
			}

			return tempRow
		})

		let dataValues = listTarefas.map(tarefa => {
			const tempRow = {
			}

			listDatas.forEach(date => {
				tempRow[`${upperCaseDiaSemana(date.data)} \n ${moment(date.data).format('DD/MM')}`] = listRegistrosDataTarefa.map(dataTarefa => {
					if (
						dataTarefa.tarefa === tarefa.id &&
						dataTarefa.data === date.data &&
						dataTarefa.registros.length !== 0
					) {
						return calculaTotalHorasEspecifico(dataTarefa.registros);
					}
					return null;
				}).filter(item => item)[0]
			})

			tempRow[t("Total")] = calculaTotalHorasEspecifico(tarefa.registros)

			return tempRow
		})
	
		const totalRow = {
		}

		listDatas.forEach(date => {
			totalRow[`${upperCaseDiaSemana(date.data)} \n ${moment(date.data).format('DD/MM')}`] = date.registros.length > 0 ? calculaTotalHorasEspecifico(date.registros) : null
		})

		totalRow[t("Total")] = calculaTotalHorasGeral()

		dataValues = [...dataValues, totalRow]

		const periodoString = dataInicialFinal
		
		handlePrint({
			dataInfo, 
			dataValues, 
			periodoString, 
			title: relatorioTitle
		})
	}

	return (
		<>
			<div id="printable">
				<div className="relatorio-header-container">
					<p className="relatorio-title">{relatorioTitle}</p>

					<div style={{ display: 'flex' }}>
						<div
							onClick={() => getRelatorioCSV()}
							className="relatorio-print-container"
							disabled={true}
							style={{
								pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
								opacity: loadingRelatorioCSV ? '0.5' : '1',
							}}
						>
							<FontAwesomeIcon
								icon={faTable}
								className="print-icon-relatorio"
							/>
							<p className="print-text-relatorio">{t("Gerar CSV")}</p>
							{loadingRelatorioCSV ? (
								<span style={{ marginLeft: '4px' }}>
									<CarregandoDados />{' '}
								</span>
							) : (
								<></>
							)}
						</div>
						<div
							onClick={() => getRelatorioXLSX()}
							className="relatorio-print-container"
							disabled={true}
							style={{
								pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
								opacity: loadingRelatorioCSV ? '0.5' : '1',
							}}
						>
							<FontAwesomeIcon
								icon={faTable}
								className="print-icon-relatorio"
							/>
							<p className="print-text-relatorio">{t("Gerar Excel")}</p>
							{loadingRelatorioCSV ? (
								<span style={{ marginLeft: '4px' }}>
									<CarregandoDados />{' '}
								</span>
							) : (
								<></>
							)}
						</div>
						<div
							onClick={handlePrintData}
							className="relatorio-print-container"
						>
							<FontAwesomeIcon
								icon={faPrint}
								className="print-icon-relatorio"
							/>
							<p className="print-text-relatorio">{t("Imprimir")}</p>
						</div>
					</div>
				</div>
				{loadingGetHoras ? (
					<CarregandoDados />
				) : (
					<>
						<table className="relatorio-table">
							<thead>
								<tr>
									<th className="cabecalho-table-financeiro" colSpan="1">
										{dataInicialFinal}
									</th>
								</tr>
								<tr>
									<th className="cabecalho-periodo-totalhorasdiarias">
										{t("Projeto")}
									</th>
									<th className="cabecalho-periodo-totalhorasdiarias">
										{t("Responsável")}
									</th>
									<th className="cabecalho-periodo-totalhorasdiarias">
										{t("Tarefa")}
									</th>
									{listDatas.map(data => (
										<th key={data.data} className="cabecalho-table">
											<span
												style={{
													fontWeight: '700',
													textAlign: 'center',
													width: '100%',
													display: 'inline-block',
												}}
											>
												{upperCaseDiaSemana(data.data)}
											</span>
											<br />
											{moment(data.data).format('DD/MM')}
										</th>
									))}
									<th className="cabecalho-total">Total</th>
								</tr>
							</thead>
							<tbody>
								{listTarefas.map(tarefa => (
									<tr key={tarefa.id}>
										<th className="tarefa-th-totalhorasdiarias">
											{tarefa.projeto_nome}
										</th>

										<th className="tarefa-th-totalhorasdiarias">
											{/* {tarefa.admins ? (
												tarefa.admins.map(item => <div>{item.nome}</div>)
											) : (
												<div>Sem administradores</div>
											)} */}
											{tarefa.pessoa_nome}
										</th>
										<th className="tarefa-th-totalhorasdiarias">
											{tarefa.nome}
										</th>

										{listDatas.map(data => (
											<td className="registros-td-totalhorasdiarias">
												{listRegistrosDataTarefa.map(dataTarefa => {
													if (
														dataTarefa.tarefa === tarefa.id &&
														dataTarefa.data === data.data &&
														dataTarefa.registros.length !== 0
													) {
														return calculaTotalHorasEspecifico(dataTarefa.registros);
													}
													return null;
												})}
											</td>
										))}

										<td className="total-row-registro-totalhorasdiarias">
											{calculaTotalHorasEspecifico(tarefa.registros)}
										</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td
										className="span-table-botton-totalhorasdiarias"
										colSpan="3"
									></td>
									{listDatas.map(data => (
										<th className="total-col-registro-totalhorasdiarias">
											{calculaTotalHorasEspecifico(data.registros)}
										</th>
									))}
									<th
										style={{
											background: '#5ad8e3',
											color: '#112631',
										}}
										id="total-table"
									>
										{calculaTotalHorasGeral()}
									</th>
								</tr>
							</tfoot>
						</table>
					</>
				)}
			</div>
		</>
	);
}

const CarregandoDados = () => (
	<>
		<div className="loader-alinhamento">
			<div className="loader-conteudo"></div>
		</div>
	</>
);
