import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { NavLink, useHistory } from 'react-router-dom';
import pessoaImage from '../../../assets/images/profile.png';
import AuthService from '../../../services/security/auth';
import PessoaService from '../../../services/providers/responsible';
import { LoaderCircle } from '../../common/loaders/LoaderCircle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSignOutAlt,
	faUser,
	faPlug,
	faLock,
	faLightbulb,
} from '@fortawesome/free-solid-svg-icons';

import { useKanban } from '../../../contexts/Kanban/Kanban';


import {   
	ToolTip,
} from './styled';

import './styles.css';
import { useUser } from '../../../contexts/User';
import { useProject } from '../../../contexts/Project';
import { HomeIcon } from '../../../assets/svgs/iconsComponents/HomeIcon';
import { t } from 'i18next';
import { ProjectsIcon } from '../../../assets/svgs/iconsComponents/ProjectsIcon';
import { TasksIcon } from '../../../assets/svgs/iconsComponents/TasksIcon';
import { RecordsIcon } from '../../../assets/svgs/iconsComponents/RecordsIcon';
import { ReportsIcon } from '../../../assets/svgs/iconsComponents/ReportsIcon';
import { ManageAccountIcon } from '../../../assets/svgs/iconsComponents/ManageAccountIcon';
import { OkrsIcon } from '../../../assets/svgs/iconsComponents/OkrsIcon';
import { HelpIcon } from '../../../assets/svgs/iconsComponents/HelpIcon';
import { PlanusLogo } from '../../../assets/svgs/iconsComponents/PlanusLogo';
import { MenuIcon } from '../../../assets/svgs/iconsComponents/MenuIcon';
import { ClientsIcon } from '../../../assets/svgs/iconsComponents/ClientsIcon';
import { LogoutIcon } from '../../../assets/svgs/iconsComponents/LogoutIcon';
import { PlanusMiniLogo } from '../../../assets/svgs/iconsComponents/PlanusMiniLogo';
import { useAtomValue } from 'jotai';
import { selectedHeaderOkrsAtom } from '../../../atoms/Okrs';


export default function SideBarContainer() {
	const { t } = useTranslation();
	const service = new AuthService();

  const isMenuOpenStorage = localStorage.getItem('isMenuOpenPlanusSidebar')
	const [isMenuOpen, setIsMenuOpen] = useState(isMenuOpenStorage !== 'false');

	const [openUserOption, setOpenUserOption] = useState(false);
	const [loading, setLoading] = useState(false);

	const { selectedHeaderLink } = useKanban();
	const { selectedProjectLink } = useProject();
  const selectedHeaderOkrs  = useAtomValue(selectedHeaderOkrsAtom)
	const { userDetails, setUserDetails} = useUser();

	const history = useHistory();

  const toggleMenu = useCallback(() => {
    const newValue = !isMenuOpen;
    setIsMenuOpen(newValue)
    localStorage.setItem('isMenuOpenPlanusSidebar', newValue ? 'true' : 'false')
  }, [isMenuOpen])

	const getUserdetails = () => {
			if (userDetails?.acesso_cod === 'e') {
				history.push('/registros/projetos');
			}
	};

	const resetUser = () => {
		setUserDetails(null);
	}

	const _patchPessoaDicas = () => {
		if(userDetails?.id_pessoa) {
			const valor = !userDetails.has_finished_tour
			const ativarDica = new FormData();
			ativarDica.append('has_finished_tour', valor.toString());
			setLoading(true);
			const pessoaService = new PessoaService();
			pessoaService.patchPessoa(
				ativarDica,
				userDetails.id_pessoa,
				() => {
					setUserDetails(prev => {
						if(prev) {
							return {
								...prev,
								has_finished_tour: valor
							}
						}
						return null;
					});
					if (userDetails?.acesso_cod === 'e') {
						history.push('/registros/projetos');
					} else {
						history.push('/home/calendar');
					}
					setLoading(false);
			},
			() => {
				setLoading(false);
			},
		);
		}
	};

	const handleToggleUserToolTip = useCallback(() => {
		setOpenUserOption(!openUserOption);
	}, [setOpenUserOption, openUserOption]);

	const handleCloseProfileToolTip = useCallback(() => {
		setOpenUserOption(false);
	}, [setOpenUserOption])

	const handleOpenFeedBackModal = useCallback(() => {
		document.getElementById('show-feedback-modal')?.click();
	},[document]);

	const handleOpenProfileInfoModal = useCallback(() => {
		document.getElementById('show-infoPessoa-modal')?.click();
		handleCloseProfileToolTip();
	}, [document, handleCloseProfileToolTip]);


	const handleOpenPasswordModal = useCallback(() => {
		document.getElementById('show-conta-modal')?.click();
		handleCloseProfileToolTip();
	}, [document, handleCloseProfileToolTip])

	const handleOpenIntegrationModal = useCallback(() => {
		document.getElementById('show-integracoes-modal')?.click();
		handleCloseProfileToolTip();
	}, [document, handleCloseProfileToolTip])

	useEffect(() => {
		const checkMenu = () => {
			const checkResize = document.getElementById('check') as HTMLInputElement;
			if (checkResize) {
				checkResize.checked = false;
			}
		};
		if (checkMenu) {
			window.addEventListener('resize', checkMenu);
		}
	}, []);

	useEffect(() => {
		getUserdetails();
	}, [userDetails])

	const getStyle = () => {
		const PlanusVisualizacao = localStorage.getItem('PlanusVisualizacao');
		if(PlanusVisualizacao === 'g') {
			return 'registros/projetos';
		}else if(PlanusVisualizacao === 'l') {
			return 'registros/projetos-list';
		}else if(PlanusVisualizacao === 'k') {
			return 'registros/projetos-kanban';
		}
			
	}

	return (
		<div className={`flex flex-col h-full  ease-in-out duration-200 ${isMenuOpen ? 'w-72' : 'w-20'}`}>
			<div>
				<div className={`flex items-center h-20 border-b border-white ${isMenuOpen ? 'justify-between p-5 pl-7' : 'justify-center'}`}>
					<div className={`relative gap-6 ease-linear delay-100 duration-200 ${isMenuOpen ? 'w-auto opacity-100' : 'w-0 opacity-0 overflow-hidden'}`}>
            <div>
              <ToolTip
                title={t('Menu de configurações').toString()}
                openUserOption={openUserOption}
                usuarioPerfil={userDetails?.acesso_cod}
                onMouseLeave={handleCloseProfileToolTip}
              >
                <div>
                  <div>
                    <div
                      onClick={handleOpenProfileInfoModal}
                      className="tooltip-card"
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        className="faSidebarTooltip-icon"
                      />
                      <>{t('Informações Pessoais')}</>
                    </div>
                  </div>
                  
                  <div>
                    <div
                      onClick={handleOpenPasswordModal}
                      className="tooltip-card"
                      
                    >
                      <FontAwesomeIcon
                        icon={faLock}
                        className="faSidebarTooltip-icon"
                      />
                      <>{t('Mudar Senha')}</>
                    </div>
                  </div>
                  
                  <div>
                    {userDetails?.acesso_cod === 'g' || userDetails?.acesso_cod === 'a' ? (
                      <div
                        onClick={handleOpenIntegrationModal}
                        className="tooltip-card"

                      >
                        <FontAwesomeIcon
                          icon={faPlug}
                          className="faSidebarTooltip-icon"
                        />
                        <>{t('Integrações')}</>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <div
                      className="tooltip-card"
                      onClick={_patchPessoaDicas}
                    >
                      <FontAwesomeIcon
                        icon={faLightbulb}
                        className="faSidebarTooltip-icon"
                      />
                      {!userDetails?.has_finished_tour ? (
                        <span>
                          <>{t('Desativar Ajuda')}</>
                        </span>
                      ) : (
                        <span>
                          <>{t('Ativar Ajuda')}</>
                        </span>
                      )}
                      {loading ? (
                        <span style={{ marginLeft: '3em' }}>
                          <LoaderCircle />{' '}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      onClick={() => service.logout(resetUser)}
                      className="tooltip-card"
                      style={{border: 'none'}}
                    >
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="faSidebarTooltip-icon"
                      />
                      <>{t('Sair')}</>
                    </div>
                  </div>
                </div>
              </ToolTip>

              <div className='flex items-center justify-center'>
                <button 
                  onClick={handleToggleUserToolTip}
                >
                  <img
                    id="react-tour__img-user"
                    className='rounded-full w-10 h-10 border-2 border-solid border-ivory-green  cursor-pointer'
                    title={t('Abrir configurações').toString()}
                    alt={userDetails?.username}
                    src={userDetails?.foto_perfil !== null ? userDetails?.foto_perfil : pessoaImage}
                  />
                </button>
              </div>
            </div>
          </div>
          
          <div className={`ease-linear delay-100 duration-200 ${isMenuOpen ? 'w-auto opacity-100' : 'w-0 opacity-0 overflow-hidden'}`}>
            <div>
              <PlanusLogo className='w-32 h-8' />
            </div>
          </div>

          <button className='p-2 ' onClick={toggleMenu}>
            {isMenuOpen ? (
              <MenuIcon />
            ) : (
              <PlanusMiniLogo className='w-8 h-8' />
            )}
          </button>
				</div>
			</div>

      <div className='flex flex-col h-full justify-between bg-ivory-primary-light'>
        <div>
            <ul className="list-unstyled">
              {userDetails?.acesso_cod !== 'e' && (
                <li>
                  <SiderBarLink 
                    Icon={HomeIcon} 
                    mini={!isMenuOpen}
                    title='Início' 
                    to='/home/calendar' 
                  />
                </li>
              )}
              <li>
                <SiderBarLink 
                  Icon={ClientsIcon} 
                  mini={!isMenuOpen}
                  title='Clientes'  
                  to={'/clientes'} 
                />
              </li>
              <li>
                <SiderBarLink 
                  Icon={ProjectsIcon} 
                  mini={!isMenuOpen}
                  title='Projetos'  
                  to={`${selectedProjectLink === 'registros/projetos' ? getStyle() : selectedProjectLink}`} 
                />
              </li>
              <li>
                <SiderBarLink 
                  Icon={TasksIcon} 
                  mini={!isMenuOpen}
                  title='Tarefas'  
                  to={{
                    pathname: `/registros/${selectedHeaderLink}`,
                    state: { fromHome: true },
                  }} 
                />
              </li>
              <li>
                <SiderBarLink 
                  Icon={RecordsIcon} 
                  mini={!isMenuOpen}
                  title='Horas'  
                  to={'/registros/horas'} 
                />
              </li>
              <li>
                <SiderBarLink 
                  Icon={OkrsIcon} 
                  mini={!isMenuOpen}
                  title='OKRs'  
                  to={`/okrs/${selectedHeaderOkrs}`} 
                />                
              </li>
              <li>
                <SiderBarLink 
                  Icon={ReportsIcon} 
                  mini={!isMenuOpen}
                  title='Relatórios'  
                  to={'/relatorios'} 
                />  
              </li>
              {userDetails?.acesso_cod === 'a' && 
                <li>
                  <SiderBarLink 
                    Icon={ManageAccountIcon} 
                    mini={!isMenuOpen}
                    title='Pessoas'  
                    to={'/pessoas'} 
                  /> 
                </li>
              }
            </ul>
        </div>
        
        <div className='flex flex-col'>
          <SiderBarButton mini={!isMenuOpen} onClick={handleOpenFeedBackModal} Icon={HelpIcon} title='Ajuda' />
          <SiderBarButton mini={!isMenuOpen} onClick={() => service.logout(resetUser)} Icon={LogoutIcon} title='Sair' />
        </div>
      </div>
    </div>
	);
}

interface SiderBarLinkProps {
  title: string;
  to: any;
  mini: boolean;
  Icon: (props: HTMLAttributes<HTMLOrSVGElement>) => JSX.Element
}

function SiderBarLink({Icon, title, to, mini}: Readonly<SiderBarLinkProps>) {

  return(
    <NavLink
      to={to}
      activeClassName="active-sidebar-button"
      className={'hover:no-underline'}
      title={t(title).toString()}
    >
        <div className={`nav-container relative flex items-center p-5 pl-8 hover:bg-ivory-primary ease-linear duration-100 ${!mini && 'gap-4'}`} >
          <div className='separator-style absolute left-0 w-2 h-full bg-ivory-green hidden'></div>
          <div className='h-5 w-5'>
            <Icon className={'svg-icon-style h-5 w-5 fill-white'}   />
          </div>
          <p className={`svg-icon-style font-medium text-xl text-white overflow-hidden ease-linear delay-100 duration-200  ${mini ? 'w-0 opacity-0' : 'w-auto opacity-100'}`}>
            {t(title)}
          </p>
        </div> 
    </NavLink>
  )
}

interface SiderBarButtonProps {
  title: string;
  mini: boolean;
  onClick: () => void;
  Icon: (props: HTMLAttributes<HTMLOrSVGElement>) => JSX.Element
}

function SiderBarButton({Icon, title, onClick, mini}: Readonly<SiderBarButtonProps>) {
  return(
    <button
      onClick={onClick}
      title={t(title).toString()}
    >
        <div className={`nav-container flex items-center p-5 pl-8 hover:bg-ivory-primary overflow-hidden ease-linear duration-100  ${!mini && 'gap-4'}`} >
          <div className='h-5 w-5'>
            <Icon className={'svg-icon-style h-5 w-5  fill-white'}   />
          </div>
          <p className={`svg-icon-style font-medium text-xl text-white overflow-hidden ease-linear delay-100 duration-200  ${mini ? 'w-0 opacity-0' : 'w-auto opacity-100'}`}>
            {t(title)}
          </p>
        </div> 
    </button>
  )
}