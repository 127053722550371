import React, { ReactNode } from "react";
import { FieldWrapper, FormikField, Label,FieldContainer, IconContainer } from "./styled";
import ErrorMsg from "../../ErrorMsg";
import { InputProps } from "react-select";

interface FieldProjectProps {
    name: string;
    title: string;
    type: string;
    disabled?: boolean;
    id?: string;
    onChange: () => void;
    value: string;
    errors: string;
    touched: boolean;
    component?: JSX.Element;
    InputProps?: InputProps;
    money?: boolean;
    icon?: ReactNode;
}

export default function FieldProjectDetail({name, icon, title, type, disabled, money, ...rest}: FieldProjectProps) {
    return(
        <FieldContainer>
            <Label htmlFor={name}>{title}</Label>
            <FieldWrapper money={money}>
                <FormikField 
                    name={name}
                    type={type}
                    autoComplete='off'
                    disabled={disabled}
                    money={money}
                    {...rest}
                />
                {icon && <IconContainer>
                    {icon}
                    </IconContainer>}
            </FieldWrapper>
            <ErrorMsg name={name} />
        </FieldContainer>
    )
}