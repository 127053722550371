import React, { useCallback, useRef } from 'react';
import { Image, InputImage, Td } from './styled';

interface TableCellImgProps {
	name: string; 
	value: string; 
	src: string;
	alt?: string;
	id?: number | string;
	onSubmit: (e: string | number | File, name: string, id?: number | string | undefined) => void;
}

export default function TableCellImg({
	name,
	src,
	value,
	alt,
	id,
	onSubmit
}: TableCellImgProps) {
	const imgRef = useRef<HTMLInputElement>(null);



	const handleClick = useCallback(() => {
		imgRef.current?.click();
	}, [imgRef])

	const fileSelect = useCallback( (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		const reader = new FileReader();
		const imgInfo = document.getElementById(`img-form-edit-${id}`) as HTMLImageElement;
		if(imgInfo) {
			reader.onloadend = () => {
				imgInfo.src = reader.result as string;
				const input = document.getElementById(`img-input-${id}`) as HTMLInputElement;
				const arquivo = input.files![0];
				onSubmit(arquivo, name, id);
			}
		}

		if(file) {
			reader.readAsDataURL(file);
		}
	}, [value, name, id, onSubmit])
	return (
		<Td>
			<Image src={src} alt={alt} onClick={handleClick} id={`img-form-edit-${id}`}/>
			<InputImage 
				ref={imgRef} 
				onChange={fileSelect} 
				type={'file'} 
				accept="image/*" 
				id={`img-input-${id}`}
				/>
		</Td>
	);
}
