import styled from 'styled-components';

export const InfoResponsaveisContainer = styled.div`
	background: #fff;
	border-radius: 10px;
	margin-top: 20px;
`;

export const InfoResponsavelBody = styled.div`
	display: grid;
	grid-template-columns: 20% 20% 20% 20% 20%;
	padding: 10px;
	max-height: 130px;
	overflow-y: auto;
	resize: none;
	@media (max-width: 1350px) {
		grid-template-columns: 25% 25% 25% 25%;
		max-height: 100px;
	}
	@media (max-width: 1050px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media (max-width: 690px) {
		grid-template-columns: 1fr;
		max-height: 150px;
	}
`;

export const InfoDescriptionTitle = styled.div`
	border-bottom: 2px solid rgb(233, 238, 241);
	font-weight: 500;
	color: #8099a7;
	font-size: 16px;
	padding: 10px 15px 10px 15px;
`;

export const InfoResponsavelPessoa = styled.div`
	height: 30px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	@media (max-width: 690px) {
		height: 25px;
	}
`;

export const ResponsavelImgContainer = styled.span``;

export const ResponsavelImg = styled.img`
	border-radius: 50%;
	width: 24px;
	height: 24p;
`;

export const ResponsavelName = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: 0 5px 0 5px;
	color: #406173;
	font-weight: normal;
`;
