import React, { useEffect, useState } from 'react';

import ProjetoService from '../../../services/providers/project';
import DinamicPagination from '../../../components/common/DinamicPagination';
import ListRelatorio from '../../../components/common/Lists/ListRelatorio';
import PageContainerHora from '../../../components/containers/PageContainer/pageContainerHora';
import FilterRelatorioProjeto from './filter.js';
import DisplayCounterDelivery from '../../../components/common/DisplayCounterDelivery';
import ExcelHeader from '../components/excelHeader';
import RelatoriosService from '../../../services/providers/report';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProject } from '../../../contexts/Project';
import TurTutorial from '../../../components/tourTutorial';
import { useAtom } from 'jotai';
import { currentPageAtom, filterAtom, onFilterEncerradosAtom, projectReportAtom, selectShowAtom, totalPagesAtom } from '../../../atoms/ProjectReport';

const RelatorioProjeto = () => {
	const {t} = useTranslation();
	const location = useLocation();
	const [projetoData, setProjetoData] = useAtom(projectReportAtom);
	const [currentPage, setCurrentPage] = useAtom(currentPageAtom)
	const [totalPages, setTotalPages] = useAtom(totalPagesAtom)
	const [loading, setLoading] = useState(false);
	const [loadingFilter, setLoadingFilter] = useState(false);
	const [onFilterEncerrados, setOnFilterEncerrados] = useAtom(onFilterEncerradosAtom)
	const [loadingSpreadSheet, setLoadingSpreadSheet] = useState(false);
	const { selectReport } = useProject();
	const [selectShow, setSelectShow] = useAtom(selectShowAtom);

	const [filter, setFilter] = useAtom(filterAtom);


	useEffect(() => {
		selectReport();
		if (
			location.state?.projetoData &&
			location.state?.currentPage &&
			location.state?.totalPages &&
			location.state?.onFilterEncerrados !== undefined &&
			location.state?.onFilterEncerrados !== null &&
			location.state?.selectShow &&
			location.state?.filter
		) {
			setProjetoData(location.state.projetoData);
			setCurrentPage(location.state.currentPage);
			setTotalPages(location.state.totalPages);
			setOnFilterEncerrados(location.state.onFilterEncerrados);
			setSelectShow(location.state.selectShow);
			setFilter(location.state.filter);
		} else {
			_getProjetos();
		}
	}, []);

	const _getProjetos = (
		page = 1,
		id = filter.projeto.value,
		client = filter.cliente.value,
		admin = filter.gerente.value,
		tags = '',
		activeOrFinish = true,
		loading = false,
	) => {
		setLoading(true);
		if (loading) {
			setLoadingFilter(true);
		}
		const projetoService = new ProjetoService();
		projetoService.getProjetosRelatorio(
			{
				page: page,
				id: id,
				client: client,
				tags: tags,
				encerrados: activeOrFinish,
				admin: admin,
				search: "",
			},
			response => {
				if (response) {
					if (page === 1) {
						setProjetoData(response.results);
						setLoadingFilter(false);
					} else {
						setProjetoData(prevData => prevData.concat(response.results));
					}
					setCurrentPage(page);
					setTotalPages(response.total_pages);
				}
				setLoading(false);
				setLoadingFilter(false);
			},
			error => {
				setLoading(false);
			},
		);
	};

	const handlePagination = page => {
		_getProjetos(page);
	};

	const sendFilter = (
		filter,
		activeOrFinish,
		pessoasSelectShow,
		projetoSelectShow,
		clienteSelectShow,
	) => {
		setOnFilterEncerrados(activeOrFinish);
		setSelectShow({ projetoSelectShow, pessoasSelectShow, clienteSelectShow });
		let tags = '';
		try {
			tags = filter.tag.map(item => item.label).toString();
		} catch {}

		setFilter(filter);
		_getProjetos(
			undefined,
			filter.projeto.value,
			filter.cliente.value,
			filter.gerente.value,
			activeOrFinish ? tags : '',
			activeOrFinish,
			true,
		);
	};

	const _getRelatorioCSV = tipo_relatorio => {
		setLoadingSpreadSheet(true);
		const relatorioService = new RelatoriosService();
		let tags = '';
		try {
			tags = filter.tag.map(item => item.label).toString();
		} catch {}

		relatorioService.getRelatorioSaldoCSV(
			filter.cliente.value,
			filter.projeto.value,
			filter.gerente.value,
			tags,
			() => {
				setLoadingSpreadSheet(false);
			},
			() => {
				setLoadingSpreadSheet(false);
			},
		);
	};

	const _getRelatorioXLSX = tipo_relatorio => {
		setLoadingSpreadSheet(true);
		const relatorioService = new RelatoriosService();
		let tags = '';
		try {
			tags = filter.tag.map(item => item.label).toString();
		} catch {}

		relatorioService.getRelatorioSaldoXLSX(
			filter.cliente.value,
			filter.projeto.value,
			filter.gerente.value,
			tags,
			() => {
				setLoadingSpreadSheet(false);
			},
			() => {
				setLoadingSpreadSheet(false);
			},
		);
	};

	return (
		<PageContainerHora
			headerType="projectWithReport"
			rightHeaderContent={
				<DisplayCounterDelivery
					dataTarefa={projetoData}
					onFilterEncerrados={!onFilterEncerrados}
				/>
			}
			showHeader
			filter={
				<FilterRelatorioProjeto
					sendFilter={sendFilter}
					filter={filter}
					selectShow={selectShow}
					onFilterEncerrados={onFilterEncerrados}
				/>
			}
		>
			<ExcelHeader
				title={t('Relatório de Projetos')}
				type={'relatorio-horas-totais'}
				getRelatorioCSV={_getRelatorioCSV}
				getRelatorioXLSX={_getRelatorioXLSX}
				loadingRelatorioCSV={loadingSpreadSheet}
			/>
			<DinamicPagination
				loading={loading}
				totalPages={totalPages}
				currentPage={currentPage}
				lengthData={projetoData.length}
				returnPage={handlePagination}
				contentHeight={'285'}
				msgToEmptyData={t('Sem registros de horas nesse período')}
			>
				<>
				<TurTutorial nomeRota={'projectWithReport'} />
				{!loadingFilter && (
					<ListRelatorio
						projetoData={projetoData}
						projetoEncerrados={!onFilterEncerrados}
						isRelatorioProjeto={true}
						toState={{
							from: location.pathname,
							projetoData,
							currentPage,
							totalPages,
							onFilterEncerrados,
							filter,
							selectShow,
						}}
					/>
				)}
				</>
			</DinamicPagination>
		</PageContainerHora>
	);
};

export default RelatorioProjeto;
