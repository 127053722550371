import React, {useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';
import FieldFormik from '../../../../components/common/Fields/FieldFormik';
import { KeyResultSchema } from './validation';
import { PatchKeyResultProps, KeyResultProps, KeyResultResponse } from '../../../../services/providers/okrs/keyresult/types';
import KeyResultService from '../../../../services/providers/okrs/keyresult';
import { toast } from 'react-toastify';
import { SelectOptions } from '../../../../models/Select';
import AsyncSelector from '../../../../components/common/AsyncSelector';
import { MultiValue, SingleValue } from 'react-select';
import { FinalidadeInput } from './FinalidadeInput';
import moment from 'moment';
import { useSetAtom } from 'jotai';
import { keyresultsManagerAtom } from '../../../../atoms/Okrs';

interface KeyResultFormProps {
	keyresult?: PatchKeyResultProps
	objetivoId: number
	editing: boolean
	setLoading: (loading: boolean) => void
	setModalShow: (loading: boolean) => void
	handleRefresh: () => void
	handleAfterPutKeyresult: (keyresult: KeyResultResponse) => void
}

const KeyResultForm = ({
	editing = false,
	keyresult,
	objetivoId,
	setLoading,
	setModalShow,
	handleRefresh,
	handleAfterPutKeyresult
}: KeyResultFormProps) => {
	const tempEditing = editing && keyresult !== undefined
	const keyResultService = new KeyResultService();

	const medidasOptions = keyResultService.getMedidas().options;
	const medida = tempEditing && medidasOptions.find(item => item.value === keyresult.medida)
	const [selectedMedida, setSelectedMedida] = useState<SelectOptions>(medida ? medida : medidasOptions[0]);

	const { t } = useTranslation();
	const initialValues: KeyResultProps = tempEditing ? {
		objetivo: objetivoId,
		nome: keyresult?.nome,
		descricao: keyresult?.descricao,
		finalidade: keyresult?.finalidade,
		valor_inicial: keyresult?.valor_inicial,
		valor_final: keyresult?.valor_final,
		valor_atual: keyresult?.valor_atual,
		data_entrega: keyresult?.data_entrega,
		medida: keyresult?.medida,
	} : {
		objetivo: objetivoId,
		nome: '',
		descricao: '',
		finalidade: '',
		valor_inicial: '',
		valor_final: '',
		valor_atual: '',
		data_entrega: '',
		medida: 'P',
	};

    const setKeyresultManager = useSetAtom(keyresultsManagerAtom)

	const handleAfterPostKeyresult = (keyresultResponse: KeyResultResponse) => {
		setKeyresultManager(prev => {
			const prevKeyresult = prev.keyresultsManager[keyresultResponse.objetivo]?.keyresults ?? []
			const tempKeyresult = [...prevKeyresult, keyresultResponse]

			return {
				keyresultsManager: {
					...prev.keyresultsManager,
					[keyresultResponse.objetivo]: {
						...prev.keyresultsManager[keyresultResponse.objetivo],
						keyresults: tempKeyresult,
					}
				}
			}
		})
	}

	const _postKeyResult = (params: KeyResultProps) => {
		setLoading(true);
		keyResultService.postKeyResult(
			params
		).then(response => {
			console.log(response)

			if (response) {
				toast.success(t('Key Result salvo com sucesso!'));
				setLoading(false);
				setModalShow(false);
				handleAfterPostKeyresult(response)
				handleRefresh()
			}
		}).catch(() => {
			toast.error(t('Erro ao tentar salvar o Key Result!'));
			setLoading(false);
		});
	};

	const _patchKeyResult = (params: KeyResultProps) => {
		if(tempEditing) {
			setLoading(true);
			keyResultService.patchKeyResult({
				...params,
				id: keyresult.id
			}).then(response => {
				if (response) {
					toast.success(t('Key Result salvo com sucesso!'));
					setLoading(false);
					setModalShow(false);
					handleAfterPutKeyresult(response)
					handleRefresh()
				}
			}).catch(() => {
				toast.error(t('Erro ao tentar salvar o Key Result!'));
				setLoading(false);
			});
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={KeyResultSchema}
			onSubmit={values => {
				tempEditing ? _patchKeyResult(values) : _postKeyResult(values);
			}}
		>
			{({ errors, touched, setFieldValue, values }) => {
				return (
					<FormikForm>
						<div className="row max-w-2xl">
							<div className="col-sm-12 col-md-12">
								<FieldFormik
									title={`${t('Nome')}*`}
									autoComplete={'off'}
									nameField="nome"
									errors={errors.nome ? errors.nome : ''}
									touched={touched.nome ? touched.nome : false}
									name="nome"
									// placeholder={t('Nome do Key Result')}
								/>
							</div>
							<div className="col-sm-12 col-md-12">
								<FieldFormik
									title={`${t('Descrição')}`}
									autoComplete={'off'}
									type="text"
									renderComponent={"textarea"}
									nameField="descricao"
									errors={errors.descricao ? errors.descricao : ''}
									touched={touched.descricao ? touched.descricao : false}
									name="descricao"
									onChange={e => {
										const target = e.target as HTMLInputElement;
										setFieldValue('descricao', target.value);
									}}
								/>
							</div>
							<div className="col-sm-12  col-md-12">
								<FieldFormik
									title={`${t('Finalidade')}*`}
									autoComplete={'off'}
									type="text"
									nameField="finalidade"
									errors={errors.finalidade ? errors.finalidade : ''}
									touched={touched.finalidade ? touched.finalidade : false}
									name="finalidade"
									value={tempEditing ? keyresult.finalidade : ""}
									className={errors.finalidade && touched.finalidade ? 'mb-3 border-none' : 'border-none'}
									renderComponent={FinalidadeInput}
									onChange={e => {
										const target = e.target as HTMLInputElement;
										
										if(target.value === 'E') {
											setFieldValue('valor_inicial', 0);
											setFieldValue('valor_final', 100);
											setFieldValue('medida', "P");
											setSelectedMedida(medida ? medida : medidasOptions[0]);
										} else {
											setFieldValue('data_entrega', moment().format("YYYY-MM-DD"));
										}

										setFieldValue('finalidade', target.value);
									}}
								/>
							</div>

							{values.finalidade === "E" ? (
								<div className="col-sm-12 col-md-3">
									<FieldFormik
										title={`${t('Data entrega')}*`}
										autoComplete={'off'}
										type="date"
										nameField="data_entrega"
										name="valor_idata_entreganicial"
										errors={errors.data_entrega ? errors.data_entrega : ''}
										touched={touched.data_entrega ? touched.data_entrega : false}

									/>
								</div>
							) : (
								<>
									<div className="col-sm-12 col-md-3">
										<FieldFormik
											title={`${t('Valor inicial')}*`}
											autoComplete={'off'}
											type="number"
											nameField="valor_inicial"
											errors={errors.valor_inicial ? errors.valor_inicial : ''}
											touched={touched.valor_inicial ? touched.valor_inicial : false}
											name="valor_inicial"
											onChange={e => {
												const target = e.target as HTMLInputElement;
												setFieldValue('valor_inicial', target.value);
											}}
										/>
									</div>

									<div className="col-sm-12 col-md-3">
										<FieldFormik
											title={`${t('Valor final')}*`}
											autoComplete={'off'}
											type="number"
											nameField="valor_final"
											errors={errors.valor_final ? errors.valor_final : ''}
											touched={touched.valor_final ? touched.valor_final : false}
											name="valor_final"
											onChange={e => {
												const target = e.target as HTMLInputElement;
												setFieldValue('valor_final', target.value);
											}}
										/>
									</div>

									{tempEditing && (
										<div className="col-sm-12 col-md-3">
											<FieldFormik
												title={`${t('Valor atual')}*`}
												autoComplete={'off'}
												type="number"
												nameField="valor_atual"
												errors={errors.valor_atual ? errors.valor_atual : ''}
												touched={touched.valor_atual ? touched.valor_atual : false}
												name="valor_atual"
												onChange={e => {
													const target = e.target as HTMLInputElement;
													setFieldValue('valor_atual', target.value);
												}}
											/>
										</div>
									)}

									<div className="col-sm-12 col-md-3">
										<AsyncSelector
											loadOptions={keyResultService.getMedidas as any}
											title={`${t('Medida')}*`}
											id={'medida'}
											FieldName={'medida'}
											value={selectedMedida}
											onChange={(event:  MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
												const e = event as SelectOptions;
												setSelectedMedida(e);
												setFieldValue('medida', e !== null ? e.value : '')
											}}
											placeholder={t('Selecione uma medida') ?? undefined}
											error={errors.medida as string}
											touched={touched.medida as boolean}
											errorMessage
											isClearable={false}
										/>
									</div>
								</>
							)}
						</div>
						<input
							style={{ display: 'none' }}
							id="formSideModal-KeyResult"
							type="submit"
						/>
					</FormikForm>
				);
			}}
		</Formik>
	);
};

export default KeyResultForm;
