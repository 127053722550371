import { StylesConfig } from 'react-select';
import { SelectOptions } from '../models/Select';
import { theme } from '../styles/theme';

export const customStyles: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		minHeight: '32px !important',
		opacity: state.isDisabled ? '0.5' : '1',
	}),
	control: (provided, state) => ({
		...provided,
		borderRadius: 7,
		border: state.isFocused
			? '1px solid #c0cfd8d9 !important'
			: '1px solid #c0cfd8d9 !important',
		boxShadow: state.isFocused ? 'none' : 'none',
		overflow: 'auto',
		backgroundColor: '#fff',
		minHeight: '35px !important',
	}),
	option: (provided, state) => ({
		...provided,
		// backgroundColor: state.isSelected ? '#93f1f9' : '#fff',
		color: state.isSelected ? '#fff' : '#426172',
		padding: 2,
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		alignItems: 'flex-start !important',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 'unset',
		margin: '0 0 0 4px',
		fontSize: '14px',
		// color: '#426172',
		color: '#426172',
		// zIndex: '5',
	}),
	singleValue: (provided) => ({
		...provided,
		// color: '#426172',
		color: '#426172',
	}),
	placeholder: (provided) => ({
		...provided,
		// color: '#426172',
		color: '#426172',
		fontSize: '14px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		padding: 2,
	}),
};

export const customStylesHoras: StylesConfig<SelectOptions, boolean> = {
	container: (provided) => ({
		...provided,
		minHeight: '32px !important',
	}),
	control: (provided, state) => ({
		...provided,
		borderRadius: 7,
		border: state.isFocused
			? '1px solid #c0cfd8d9 !important'
			: '1px solid #c0cfd8d9 !important',
		boxShadow: state.isFocused ? 'none' : 'none',
		overflow: 'auto',
		backgroundColor: 'white',
		minHeight: '35px !important',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? '#93f1f9' : '#fff',
		// color: state.isSelected ? 'red' : 'blue',
		padding: 2,
		color: '#426172',
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		alignItems: 'flex-start !important',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 'unset',
		margin: '0 0 0 4px',
		fontSize: '14px',
		// color: '#426172',
		color: '#426172',
	}),
	singleValue: (provided) => ({
		...provided,
		// color: '#426172',
		color: '#426172',
	}),
	placeholder: (provided) => ({
		...provided,
		// color: '#426172',
		color: '#426172',
		fontSize: '14px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		padding: 2,
	}),
};

export const timerSelectStyle: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		minHeight: '32px !important',
		opacity: state.isDisabled ? '0.5' : '1',
	}),
	control: (provided, state) => ({
		...provided,
		borderRadius: 7,
		border: state.isFocused
			? '1px solid #333 !important'
			: '1px solid #333 !important',
		boxShadow: 'none',
		backgroundColor: '#fff',
		minHeight: '35px !important',
		height: '42px !important',
		overflow: 'hidden',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
        marginLeft: '8px'

	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 'unset',
		margin: '0 0 0 4px',
		fontSize: '16px',
        
		color: '#000',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#000',
        marginLeft: '8px'
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#000',
		fontSize: '16px',
        
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
        color: '#000'        
	}),
};

export const timerSelectErrorStyle: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		minHeight: '32px !important',
		opacity: state.isDisabled ? '0.5' : '1',
	}),
	control: (provided) => ({
		...provided,
		borderRadius: 7,
		border: '1px solid #333 !important',
		boxShadow: 'none',
		backgroundColor: '#fff',
		minHeight: '35px !important',
		height: '42px !important',
		overflow: 'hidden',
		marginBottom: '18px',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
        marginLeft: '8px'

	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 'unset',
		margin: '0 0 0 4px',
		fontSize: '16px',
        
		color: '#000',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#000',
        marginLeft: '8px'
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#000',
		fontSize: '16px',
        
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
        color: '#000'        
	}),
};

export const customStylesModal: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		minHeight: '32px !important',
		opacity: state.isDisabled ? '0.5' : '1',
	}),
	control: (provided, state) => ({
		...provided,
		borderRadius: 7,
		border: '1px solid #f16d84 !important',
		boxShadow: state.isFocused ? '0 0 1.8px 1.2px #007bff40' : 'none',
		overflow: 'auto',
		backgroundColor: '#fff',
		minHeight: '35px !important',
		marginBottom: '18px',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 'unset',
		margin: '0 0 0 4px',
		fontSize: '14px',
		color: '#426172',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#426172',
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#426172',
		fontSize: '14px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
};

export const customStylesSideModal: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		minHeight: '32px !important',
		opacity: state.isDisabled ? '0.5' : '1',
	}),
	control: (provided, state) => ({
		...provided,
		borderRadius: 7,
		border: state.isFocused
			? '1px solid #c0cfd8d9 !important'
			: '1px solid #c0cfd8d9 !important',
		boxShadow: state.isFocused ? '0 0 1.8px 1.2px #007bff40' : 'none',
		backgroundColor: '#fff',
		minHeight: '35px !important',
		height: '38px !important',
		overflow: 'hidden',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 'unset',
		margin: '0 0 0 4px',
		fontSize: '14px',
		color: '#426172',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#426172',
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#426172',
		fontSize: '14px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
	}),
};

export const selectKanbanStyle: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		height: '18px !important',
		opacity: state.isDisabled ? '0.5' : '1',
		borderWidth: 0,
		backgroundColor: 'transparent',
		width: '17.625rem',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '18px',
		letterSpacing: '0.25px',
		color: '#496070',
	}),
	control: (provided, state) => ({
		...provided,
		borderWidth: 0,
		height: '18px !important',
		minHeight: '18px !important',
		backgroundColor: 'transparent',
		border: state.isFocused ? 0 : 0,
		'&:hover': {
			border: state.isFocused ? 0 : 0,
		},
		cursor: 'pointer',
		boxShadow: 'none !important',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 0,
		margin: 0,
		fontSize: '14px',
		color: '#426172',
		minHeight: '18px !important',
		height: '18px !important',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#496070',
		margin: 0,
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#496070',
		fontSize: '16px',
		margin: 0,
		padding: 0,
		position: 'absolute',
		height: '18px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		height: '18px',
		margin: 0,
		padding: 0,
		color: '#496070',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	option: (provided, state) => ({
		...provided,
		margin: 3,
		padding: 2,
		fontSize: '16px',
		color: '#496070',
		backgroundColor: state.isSelected ? '#CED8DF' : 'white',
	}),
};
export const selectFilterStyle: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		opacity: state.isDisabled ? '0.5' : '1',
		borderWidth: 0,
		backgroundColor: 'transparent',
		padding: '8px 0',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '18px',
		fontSize: '0.875rem',
		letterSpacing: '0.25px',
		color: '#496070'

	}),
	control: (provided, state) => ({
		...provided,
		borderWidth: 0,
		height: 'min-content !important',
		minHeight: 'min-content !important',
		backgroundColor: 'transparent',
		border: state.isFocused ? 0 : 0,
		boxShadow: 'none !important',
		'&:hover': {
			border: state.isFocused ? 0 : 0,
		},
		cursor: 'pointer',
		flexWrap: 'nowrap'
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '0',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
		color: theme.colors.text.inputText,
		fontSize: '0.875rem',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#496070',
		margin: 0,
		overflow: 'hidden'
	}),
	placeholder: () => ({
		fontSize: '0.875rem',
		color: '#496070',
		margin: 0,
		padding: 0,
		position: 'absolute',
		height: '18px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		height: '18px',
		margin: 0,
		padding: 0,
		color: '#496070',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	option: (provided, state) => ({
		...provided,
		margin: 3,
		padding: 2,
		fontSize: '16px',
		color: '#496070',
		backgroundColor: state.isSelected ? '#CED8DF' : 'white',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 0,
		margin: 0,
		fontSize: '0.875rem',
		color: '#426172',
		minHeight: '24px !important',
		height: '24px !important',
		overflow: 'hidden',
		paddingRight: '2px',
		flexWrap: 'nowrap',
		display: 'flex'
	}),
	multiValue: (provided) => ({
		...provided,
		margin: 0,
		minWidth: 'min-content',
	}),
	menuList: (provided) => ({
		...provided,
		overflowX: 'hidden',
	}),
	menu: (provided) => ({
		...provided,
		minWidth: '200px',
	}),
	menuPortal: (provided) => ({
		...provided,
		zIndex: 50
	}),
};

export const selectFiltrosStyle: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		opacity: state.isDisabled ? '0.5' : '1',
		borderWidth: 0,
		backgroundColor: 'transparent',
		padding: '8px 0',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '18px',
		fontSize: '0.875rem',
		letterSpacing: '0.25px',
		color: '#496070',
		width: '150px',
	}),
	control: (provided, state) => ({
		...provided,
		borderWidth: 0,
		height: 'min-content !important',
		minHeight: 'min-content !important',
		backgroundColor: 'transparent',
		border: state.isFocused ? 0 : 0,
		boxShadow: 'none !important',
		'&:hover': {
			border: state.isFocused ? 0 : 0,
		},
		cursor: 'pointer',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '0',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#496070',
		margin: 0,
		fontWeight: 'bold',
	}),
	placeholder: () => ({
		fontSize: '0.875rem',
		color: '#496070',
		margin: 0,
		padding: 0,
		position: 'absolute',
		height: '18px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		height: '18px',
		margin: 0,
		padding: 0,
		color: '#496070',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '10px',
	}),
	option: (provided, state) => ({
		...provided,
		margin: 3,
		padding: 2,
		fontSize: '16px',
		color: '#496070',
		backgroundColor: state.isSelected ? '#CED8DF' : 'white',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 0,
		margin: 0,
		fontSize: '0.875rem',
		color: '#426172',
		minHeight: '24px !important',
		height: '24px !important',
		overflow: 'auto',
		paddingRight: '2px',
	}),
	multiValue: (provided) => ({
		...provided,
		margin: 0,
	}),
	menuList: (provided) => ({
		...provided,
		overflowX: 'hidden',
	}),
	menu: (provided) => ({
		...provided,
		minWidth: '150px',
		alignSelf: 'center',
		borderRadius: '10px',
		marginTop: '15px',
	}),
	menuPortal: (provided) => ({
		...provided,
		zIndex: 50
	})
};

export const selectFilterStyleProject: StylesConfig<SelectOptions, boolean> = {
	container: (provided, state) => ({
		...provided,
		opacity: state.isDisabled ? '0.5' : '1',
		borderWidth: 0,
		borderBottom: '1px solid #c1d0d9',
		backgroundColor: 'transparent',
		padding: '8px 0',
		width: '12.5rem',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '18px',
		fontSize: '0.875rem',
		letterSpacing: '0.25px',
		color: '#496070',
	}),
	control: (provided, state) => ({
		...provided,
		borderWidth: 0,
		height: 'min-content !important',
		minHeight: 'min-content !important',
		backgroundColor: 'transparent',
		border: state.isFocused ? 0 : 0,
		boxShadow: 'none !important',
		'&:hover': {
			border: state.isFocused ? 0 : 0,
		},
		cursor: 'pointer',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '0',
	}),
	input: (provided) => ({
		...provided,
		padding: 'unset',
		margin: 'unset',
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#496070',
		margin: 0,
	}),
	placeholder: (provided) => ({
		...provided,
		fontSize: '0.875rem',
		color: '#496070',
		margin: 0,
		padding: 0,
		position: 'absolute',
		height: '18px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		height: '18px',
		margin: 0,
		padding: 0,
		color: '#496070',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	option: (provided, state) => ({
		...provided,
		margin: 3,
		padding: 2,
		fontSize: '16px',
		color: '#496070',
		backgroundColor: state.isSelected ? '#CED8DF' : 'white',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 0,
		margin: 0,
		fontSize: '0.875rem',
		color: '#426172',
		minHeight: '24px !important',
		height: '24px !important',
		overflow: 'auto',
	}),
	multiValue: (provided) => ({
		...provided,
		margin: 0,
	}),
	menuList: (provided) => ({
		...provided,
		overflowX: 'hidden',
	}),
};

