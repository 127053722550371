import React, {
	useCallback,
	useRef,
	useState,
} from 'react';
import Icon from '@mdi/react';
import { mdiClipboardTextClockOutline } from '@mdi/js';


import {  faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Stopwatch } from '../../../common/StopwatchForm';
import {
	BackButton,
	MenuItem,
	ModalAlignPrincipal,
	ModalAlignSide,
	ModalContent,
	ModalNav,
	ModalWrapper,
	SideModal,
	StopwatchModalContainer,
} from './styled';
import { FormikProps } from 'formik';
import { FormValueProps } from '../../../common/StopwatchForm/form/types';
import { RecordHistoryProvider } from '../../../../contexts/RecordHistoryContext';
import { MdAddCircleOutline, MdPlayCircleOutline } from 'react-icons/md';
import { useStopwatch } from '../../../../contexts/Stopwatch';
import { HoursRecord } from '../../../../models/HoursRecord';
import { RecordHistory } from '../../../common/RecordHistory';
import { OpenCard } from '../../../common/RecordHistory/styled';
import { theme } from '../../../../styles/theme';
import { FaTrash } from 'react-icons/fa';
import HourService from '../../../../services/providers/hour';
import { toast } from 'react-toastify';
import { useRegistrosDeHoras } from '../../../../contexts/RegistrosDeHoras';
import { useCalendarioRegistros } from '../../../../contexts/CalendarioRegistros';
import { ConfirmModal } from '../ConfirmModal';


export function StopwatchModal() {
	const [isDetailsOpen, setIsDetailsOpen] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const {currentStopwatch, preSelectedRecord, handleStopwatch, selectedMenuIndex, setSelectedMenuIndex, setPreSelectedRecord, loadingStopwatch, handleToggleModal} = useStopwatch();
	const {setRegistros, registros } = useRegistrosDeHoras();
	const {deleteCalendarioRegistroById} = useCalendarioRegistros();

	const onUnfocusModal = useCallback((event: React.MouseEvent) => {
		const target = event.target as HTMLInputElement;
		if (target.id === 'modal-align-stopwatch') {
			handleToggleModal(false);
		}
	}, []);
	const formRef = useRef<FormikProps<FormValueProps>>(null);
	const stopwatchProps = {
		isOpenSide: isDetailsOpen,
		setOpenSide: setIsDetailsOpen,
		formRef
	}
	const menu =  [
		{
			title: t('Cronômetro'),
			elem: (
				<Stopwatch
					isOpenSide={false}
					setOpenSide={() => {}}
					formRef={formRef}
					selectedMenu="timer"
				/>
			),
		},
		{
			title: t('Manual'),
			elem: (
				<Stopwatch
					{...stopwatchProps}
					selectedMenu="manual"
				/>
			),
		},
	];

	const handlePreSelectedRecord = (record: HoursRecord) => {
        setPreSelectedRecord(prev => ({...prev, projeto: record.projeto, projeto_nome: record.projeto_nome, tarefa: record.tarefa, tarefa_nome: record.tarefa_nome, tipo: record.tipo}));
    }

    const handleStopwatchRecord = (record: HoursRecord) => {
        setPreSelectedRecord({projeto: record.projeto, projeto_nome: record.projeto_nome, tarefa: record.tarefa, tarefa_nome: record.tarefa_nome});
        if(currentStopwatch === "") {
            handleStopwatch({projectId: record.projeto, projectName: record.projeto_nome, taskId: record.tarefa, taskName: record.tarefa_nome})
        }
    }

	const historyProps = selectedMenuIndex === 0 ? {
        CallbackIcon: MdPlayCircleOutline,
        iconTitle: "Iniciar cronômetro com essa tarefa",
        callbackFunction: handleStopwatchRecord,
        setPreSelectedRecord: setPreSelectedRecord,
        disabled: currentStopwatch !== "" || loadingStopwatch
    } : {
        CallbackIcon: MdAddCircleOutline,
        iconTitle: "Salvar registro com essa tarefa",
        callbackFunction: handlePreSelectedRecord,
        setPreSelectedRecord: setPreSelectedRecord
    }


	const handleDeletar = () => {
			const horaService = new HourService();
			if(preSelectedRecord?.hourId) {
				horaService.deleteHoras(
					preSelectedRecord.hourId,
					(response: any) => {
						if (response) {
							handleToggleModal(false);
							setRegistros(registros.filter((item: any) => item.id !== preSelectedRecord.hourId));
							deleteCalendarioRegistroById(preSelectedRecord.hourId);
							toast.success(t('Registro de hora removido com sucesso!'));
						}
					},
					error => {
						toast.error(error);
					},
				)
			}
	};
	document.addEventListener('keyup', event => {
		if (event.defaultPrevented) {
			return;
		}
		const key = event.key;

		if (key === 'Escape' || key === 'Esc') {
			handleToggleModal(false);
		}
	});


	const handleCloseModal = () => {
		handleToggleModal(false);
	}

	const openConfirmModal = () => setConfirmModalOpen(true)
	return (
		<RecordHistoryProvider>
			{confirmModalOpen && <ConfirmModal handleConfirm={handleDeletar} isConfirmModalOpen={confirmModalOpen} setIsConfirmModalOpen={setConfirmModalOpen} />}
			<ModalWrapper  onMouseUp={onUnfocusModal}>
				<ModalAlignSide id={'modal-align-stopwatch'}>
				<ModalAlignPrincipal>
					<StopwatchModalContainer>
						<ModalNav>
							

							<ul>
								{menu.map((menu, index) => {
									return (
										<li key={`menu-${menu.title}`}>
											<MenuItem
												key={menu.title}
												type="button"
												onClick={() => {
													setSelectedMenuIndex(index);
												}}
												isActived={selectedMenuIndex === index}
											>
												{menu.title}
											</MenuItem>
										</li>
									);
								})}
							</ul>
							{preSelectedRecord?.hourId && selectedMenuIndex === 1 && 
							<BackButton title={'Deletar'} onClick={openConfirmModal}>
								<FaTrash color={theme.colors.background.mainBg} />
							</BackButton>}
							<BackButton onClick={handleCloseModal}>
								<FontAwesomeIcon icon={faXmark} color={theme.colors.background.mainBg} />
							</BackButton>
						</ModalNav>
						<ModalContent>{menu[selectedMenuIndex].elem}</ModalContent>
					</StopwatchModalContainer>
					<SideModal isOpen={isDetailsOpen}>
						<RecordHistory 
							isOpen={isDetailsOpen}
						{...historyProps}
						/>
						<OpenCard onClick={() => setIsDetailsOpen(prev => !prev)} >
							<Icon color={'#426172'} path={mdiClipboardTextClockOutline} size={1} />
						</OpenCard>
					</SideModal>
				</ModalAlignPrincipal>
			</ModalAlignSide>
		</ModalWrapper>
		</RecordHistoryProvider>
	);
}
