import React, { ReactNode } from "react";

interface ActionButtonProps {
    children: ReactNode;
    onClick?: () => void;
}

export default function ActionButton({children, onClick}: Readonly<ActionButtonProps>) {
    return <button onClick={onClick} className="w-button h-button flex items-center justify-center bg-ivory-primary text-white rounded-default hover:opacity-70">
        {children}
    </button>
}