import type { HTMLAttributes } from "react";

interface ErrorInputMessageProps extends HTMLAttributes<HTMLSpanElement> {
  message?: string;
}

export function ErrorInputMessage({ message, ...props }: ErrorInputMessageProps) {
  return (
    <>
        <div className="relative pt-2">
          <span {...props} className="absolute top-0 left-0 text-xs text-red-400 pt-1">
            {message}
          </span>
        </div>
    </>
  )
}