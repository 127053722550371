import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { InfoDetailsContainer, InfoDetailsTable, InfoDetailsTableMobile, InfoDetailsTd, InfoDetailsTh, InfoDetailsTrMobile } from "./styled";
import moment from "moment";
import { Task } from "../../../../../../models/Task";
import PessoaService from "../../../../../../services/providers/responsible";
import { parseTaskDetailsToReadbleString } from "../../../../../../utils/funcoes/taskStatus";

interface InfoTarefaTableProps {
    task: Task;
}

export default function InfoTarefaTable({task}: InfoTarefaTableProps) {
    const [authorName, setAuthorName] = useState('');
    const formatTotalHours = (horaDuracao: string) => {
		const horasSlice = horaDuracao?.split(':');
		const horas = horasSlice[0];
		const minutos = horasSlice[1];
		return `${horas}h ${minutos}m`;
	};

	useEffect(() => {
		getAuthor();
	}, [task])

	const getAuthor = () => {
		const pessoaService = new PessoaService();
		pessoaService.getPessoasID(
			task?.responsavel,
			(response: any) => {
				if (response.data) {
					setAuthorName(response.data.nome);
				}
			},
			() => {},
		);
	}

    return (
        <InfoDetailsContainer className="border border-solid border-ivory-task-detail-border-gray">
		<InfoDetailsTable role={'table'}>
			<thead >
				<tr role={'row'} >
					<InfoDetailsTh role={'cell'}>
						{t('Autor da tarefa')}
					</InfoDetailsTh>
					<InfoDetailsTh role={'cell'}>
						{t('Data de criação')}
					</InfoDetailsTh>
					<InfoDetailsTh role={'cell'}>{t('Cliente')}</InfoDetailsTh>
					<InfoDetailsTh role={'cell'}>{t('Projeto')}</InfoDetailsTh>
					<InfoDetailsTh role={'cell'}>{t('Status')}</InfoDetailsTh>
					<InfoDetailsTh role={'cell'}>{t('Total de Horas')}</InfoDetailsTh>
					<InfoDetailsTh role={'cell'} style={{border: 'none'}}>

						{t('Data de Entrega')}
					</InfoDetailsTh>
				</tr>
			</thead>
			<tbody>
				<tr>

					<InfoDetailsTd>{authorName}</InfoDetailsTd>
					<InfoDetailsTd>
						{moment(task?.created_at).format('L')}
					</InfoDetailsTd>
					<InfoDetailsTd>
						{task?.cliente_nome}
					</InfoDetailsTd>
					<InfoDetailsTd>									{task?.projeto_nome}
					</InfoDetailsTd>
					<InfoDetailsTd>									
						{(parseTaskDetailsToReadbleString(task.status))}
					</InfoDetailsTd>
					<InfoDetailsTd>{formatTotalHours(task?.duracao_total ?? "")}
					</InfoDetailsTd>
					<InfoDetailsTd
						style={{ border: 'none' }}
					>
						{moment(task?.data_conclusao).format('L')}
					</InfoDetailsTd>
				</tr>
			</tbody>
		</InfoDetailsTable>

		<InfoDetailsTableMobile>
			<thead>
				<tr>
					<InfoDetailsTh>
						{t('Autor da tarefa')}
					</InfoDetailsTh>
					<InfoDetailsTh
						style={{ borderRight: 'none' }}
					>
						{t('Data de criação')}
					</InfoDetailsTh>
				</tr>
			</thead>
			<tbody>
				<InfoDetailsTrMobile>
					<InfoDetailsTd>{authorName}</InfoDetailsTd>
					<InfoDetailsTd
						style={{ borderRight: 'none' }}
					>
						{moment(task?.created_at).format('L')}
					</InfoDetailsTd>
				</InfoDetailsTrMobile>
			</tbody>
			<thead>
				<tr>
					<InfoDetailsTh
						style={{ borderRight: 'none' }}
					>
						Cliente
					</InfoDetailsTh>
					<InfoDetailsTh
						style={{
							borderRight: 'none',
							borderLeft: '2px solid #e9eef1',
						}}
					>
						{t('Projeto')}
					</InfoDetailsTh>
				</tr>
			</thead>

			<tbody>
				<tr>
					<InfoDetailsTd
						style={{
							borderBottom: '2px solid #e9eef1',
							borderRight: 'none',
						}}
					>
						{task?.cliente_nome}
					</InfoDetailsTd>
					<InfoDetailsTd
						style={{
							borderRight: 'none',
							borderBottom: '2px solid #e9eef1',
							borderLeft: '2px solid #e9eef1',
						}}
					>
						{task?.projeto_nome}
					</InfoDetailsTd>
				</tr>
			</tbody>
		</InfoDetailsTableMobile>

		<InfoDetailsTableMobile>
			<thead>
				<tr>
					<InfoDetailsTh>{t('Status')}</InfoDetailsTh>
					<InfoDetailsTh>{t('Total de Horas')}</InfoDetailsTh>
					<InfoDetailsTh
						style={{ border: 'none' }}
					>
						{t('Data de Entrega')}
					</InfoDetailsTh>
				</tr>
			</thead>
			<tbody>
				<tr>
					<InfoDetailsTd>
						{(() => {
							switch (task?.status) {
								case 'started':
									return t('Iniciada');
								case 'stopped':
									return t('Encerrada');
								case 'running':
									return t('Em progresso');
								case 'done':
									return t('Finalizada');
								default:
									return null;
							}
						})()}
					</InfoDetailsTd>
					<InfoDetailsTd>
						{formatTotalHours(task?.duracao_total ?? "")}
					</InfoDetailsTd>
					<InfoDetailsTd
						style={{ border: 'none' }}
					>
						{moment(task?.data_conclusao).format('L')}
					</InfoDetailsTd>
				</tr>
			</tbody>
		</InfoDetailsTableMobile>
	</InfoDetailsContainer>
    )
}