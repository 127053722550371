import React, { ForwardedRef, ReactNode, forwardRef, useEffect } from 'react';
import HeaderLink from '../../../common/HeaderLink';
import H from 'history';
import { useHistory } from 'react-router-dom';
import { Project } from '../../../../models/Project';
import ProjetoService from '../../../../services/providers/project';
import { verifyCanEditProject } from '../../../../utils/funcoes/validarPerfil';
import { useUser } from '../../../../contexts/User';
import { toast } from 'react-toastify';
import { useAtom } from 'jotai';
import { projectInfoAtom } from '../../../../atoms/ProjectDetails';
import { LoaderPagination } from '../../../common/loaders/LoaderPagination';

export interface LocationProjectDetails {
	data?: Project,
	relatorioProjetos: any
}

interface PageContainerProps {
    projectId: number;
    location: H.Location<LocationProjectDetails>;
    children:  ReactNode;
	loading?: boolean;
}

const PageContainerProjectDetail = forwardRef(({children, projectId, location, loading}: PageContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
	const [projectInfo, setProjectInfo] = useAtom(projectInfoAtom);
    const {userDetails} = useUser();

    const history = useHistory();
    useEffect(() => {
		if (location.state?.data) {
			setProjectInfo(location.state.data);

		} else {
			const projetoService = new ProjetoService();
			projetoService.getProjectDetail({id: projectId, serializer: "none"}).then(details => {
				const canEdit = verifyCanEditProject(userDetails, details.is_member, details.admins);
				if(canEdit) {
					projetoService.getProject(projectId).then((result) => {
						setProjectInfo(result)
					}).catch(() => {
						history.push('/registros/projetos');
					});
				} else {
					history.push('/registros/projetos');
				}

			}).catch(err => {
				toast.error(err);
                history.push('/registros/projetos');

			});
		}

	}, [projectId]);
    return (
        <>
        <HeaderLink projectId={projectId} type='projectDetails' />
       <div className='overflow-auto mt-5 px-2 pt-2 pb-5 h-[calc(100%-120px)] md:h-[calc(100%-80px)]' >

       <div ref={ref} className={`print-background gap-5 flex flex-col print:bg-red-600`}>
           {!projectInfo || (projectId !== projectInfo?.id) || loading ? <LoaderPagination showLoader={false} loading={true} /> : children}
        </div>
       </div>
        </>
    )

})

PageContainerProjectDetail.displayName = "PageContainerProjectDetail"

export default PageContainerProjectDetail;