import React, { useEffect, useState } from 'react';

import { Formik, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';

import ProjetoService from '../../../../../../services/providers/project';
import { TarefaSchema } from '../../../../Tarefas/ModalTarefa/form/validation';
import TarefaService from '../../../../../../services/providers/task';
import AsyncSelector from '../../../../../../components/common/AsyncSelector';

import './style.css';
import { useTranslation } from 'react-i18next';

export default function TarefaFormContainer(props) {
	const {t} = useTranslation();
	const [selectedProject, setSelectedProject] = useState({});

	//services
	const projectService = new ProjetoService();

	const { hourSelectedProject } = props;

	useEffect(() => {
		if (hourSelectedProject) {
			setSelectedProject(hourSelectedProject);
		}
	}, [hourSelectedProject]);

	useEffect(() => {
		_getProjetosPorPessoasID(selectedProject.value);
	}, [selectedProject]);

	const _postTarefa = params => {
		props.handleLoading(true);
		const tarefaService = new TarefaService();
		tarefaService.postTarefa( 
			params,
			response => {
				if (response) {
					const project = {
						value: response.projeto,
						label: response.projeto_nome,
					};
					const task = { value: response.id, label: response.nome };
					props.fillHourModal(project, task);
					props.toogleSideModal(false);
					toast.success('Tarefa salva com sucesso!');

					if(props.handlePostPatchTarefa){
						props.handlePostPatchTarefa(props.saveAndContinue);
					}
				}
				props.handleLoading(false);
			},
			error => {
				toast.error(error);
				props.handleLoading(false);
			},
		);
	};

	const verificaData = () => {
		const dateTeste = moment(new Date());
		dateTeste.add(1, 'days');
		return dateTeste.format('YYYY-MM-DD');
	};
	let initialValues = null;
	if (hourSelectedProject) {
		initialValues = {
			projeto: hourSelectedProject.value,
			nome: '',
			data_conclusao: verificaData(),
			descricao: '',
			responsaveis: [],
			alertar_responsaveis: false,
		};
	} else {
		initialValues = {
			projeto: '',
			nome: '',
			data_conclusao: verificaData(),
			descricao: '',
			responsaveis: [],
			alertar_responsaveis: false,
		};
	}

	const _getProjetosPorPessoasID = e => {
		props.getProjetoID(e);
	};

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			validationSchema={TarefaSchema}
			onSubmit={values => {
				const dataConvertida = moment(
					values.data_conclusao,
					'YYYY-MM-DD',
				).format();

				const form_data = new FormData();

				for (var key in values) {
					if (key === 'data_conclusao') {
						form_data.append(key, dataConvertida);
					} else if (key === 'responsaveis') {
						form_data.append(key, props?.getTimeSelecionado?.toString());
					} else {
						form_data.append(key, values[key]);
					}
				}

				_postTarefa(form_data);

				values['nome'] = '';
				values['descricao'] = '';
			}}
		>
			{props => {
				const { handleSubmit, setFieldValue, errors, touched } = props;
				return (
					<form onSubmit={handleSubmit}>
						<fieldset>
							<div className="container">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-12">
										<AsyncSelector
											loadOptions={projectService.loadProjectsSelect}
											additional={{
												page: 1,
											}}
											title={t('Projeto*')}
											type={'text'}
											id={'projeto'}
											FieldName={'projeto'}
											value={selectedProject}
											onChange={e => {
												setSelectedProject(e);
												setFieldValue('projeto', e !== null ? e.value : '');
											}}
											autoComplete={'off'}
											placeholder={t('Selecione um Projeto')}
											error={errors.projeto}
											touched={touched.projeto}
											errorMessage={true}
											isClearable={false}
										/>
										<label className="modal-label" htmlFor={'nome'}>
											{t("Nome da Tarefa*")}
										</label>
										<Field
											id="input-tarefa-nome"
											autoComplete={'off'}
											type="text"
											name="nome"
											className={
												errors.nome && touched.nome
													? 'form-error-style-input-projetos'
													: 'form-default-style-input-projetos'
											}
										/>
										<ErrorMessage name="nome">
											{msg => (
												<div>
													<div className="input-error-style" role="alert">
														<FontAwesomeIcon
															icon={faExclamationTriangle}
															className="mr-1"
														/>
														{msg}
													</div>
												</div>
											)}
										</ErrorMessage>
										<label className="modal-label" htmlFor={'data_conclusao'}>
											{t("Data de Entrega*")}
										</label>
										<Field
											type="date"
											name="data_conclusao"
											autoComplete={'off'}
											className={
												errors.data_conclusao && touched.data_conclusao
													? 'form-error-style-input-projetos'
													: 'form-default-style-input-projetos'
											}
										/>

										<ErrorMessage name="data_conclusao">
											{msg => (
												<div>
													<div className="input-error-style" role="alert">
														<FontAwesomeIcon
															icon={faExclamationTriangle}
															className="mr-1"
														/>
														{msg}
													</div>
												</div>
											)}
										</ErrorMessage>
									</div>
									<div className="col-12 col-md-6 col-lg-12">
										<label className="modal-label" htmlFor={'descricao'}>
											{t("Descrição")}
										</label>
										<Field
											// placeholder={'teste'}
											autoComplete={'off'}
											type="text"
											component="textarea"
											name="descricao"
											className={
												errors.descricao && touched.descricao
													? 'form-error-style-input-tarefas'
													: 'form-textArea-style-input-tarefas'
											}
										/>
										<ErrorMessage name="descricao">
											{msg => (
												<div>
													<div className="input-error-style" role="alert">
														<FontAwesomeIcon
															icon={faExclamationTriangle}
															className="mr-1"
														/>
														{msg}
													</div>
												</div>
											)}
										</ErrorMessage>
									</div>
									<div className="col-12 tarefas-notificacao-email">
										<Field
											// value={enviarEmail}
											type="checkbox"
											name="alertar_responsaveis"
											// onChange={() => setEnviarEmail(!enviarEmail)}
										/>
										<label
											className="checkbox"
											style={{ margin: '-2px 2px 0 6px' }}
										>
											{t("Notificar responsáveis")}
										</label>
									</div>
								</div>
							</div>
							<br />
							<input
								style={{ display: 'none' }}
								id="input-submit-tarefa-side"
								type="submit"
							/>
						</fieldset>
					</form>
				);
			}}
		</Formik>
	);
}
