import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import Table from '../../../components/common/Table';
import { dinamicPagination } from '../../../utils/funcoes/dinamicPagination';
import PessoaService from '../../../services/providers/responsible';
import { ExtraColumn } from '../../../models/ExtraColumn';
import { PeopleManagementService } from '../../../services/providers/peopleManagement';
import { useAtom } from 'jotai';
import {
	columnsTypesAtom,
	extraColumnsAtom,
} from '../../../atoms/PersonManagementAtoms';
import { t } from 'i18next';
import { DropResult } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { acessoOptions, initialHeaderData } from '../utils';
import { CellData } from '../../../components/common/Table/types';
import { BsPersonDash } from 'react-icons/bs';
import { Responsible } from '../../../models/Responsible';
import TRow from '../../../components/common/Table/TRow';
import TCellSelectAll from '../../../components/common/Table/TCellSelectAll';
import DefaultCell from '../../../components/common/Table/Cells/DefaultCell';
import TextCell from '../../../components/common/Table/Cells/TextCell';
import SelectCell from '../../../components/common/Table/Cells/SelectCell';
import moment from 'moment';
import ToggleCell from '../../../components/common/Table/ToggleCell';
import { InputValuesType } from '../../../services/providers/responsible/types';
import { ExtraColumnValues } from '../../../models/ExtraColumnValue';

interface UserValueColumn {
	coluna_empresa?: string;
	pessoa: string;
	valueId?: string;
	value: string | number | boolean
}
interface PersonListProps {
	setIsConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
	people: Responsible[];
	setPeople: Dispatch<SetStateAction<Responsible[]>>;
	checked: number[];
	setChecked: Dispatch<SetStateAction<number[]>>;
	getPeople: (
		page?: number,
		values?: { acesso: string; search: string; deleted: boolean },
		additional?: string,
		signal?: AbortSignal,
	) => void;
	loadingPeople: boolean;
	currentPage: number;
	totalPages: number | null;
}

export default function PersonList({
	setIsConfirmModalOpen,
	people,
	setPeople,
	checked,
	setChecked,
	getPeople,
	currentPage,
	loadingPeople,
	totalPages,
}: Readonly<PersonListProps>) {
	//pessoas
	const [columnsTypes, setColumnsTypes] = useAtom(columnsTypesAtom);
	const [extraColumns, setExtraColumns] = useAtom(extraColumnsAtom);
	console.log('extraColumns', extraColumns);
	//filters
	const [isSelectAll, setIsSelectAll] = useState(false);
	//services
	const peopleManagementService = new PeopleManagementService();

	const updatePerson = async (
		personId: number,
		key: keyof Responsible,
		newValue: InputValuesType,
	) => {
		try {
			const updatedValue = key === 'deleted' ? !newValue : newValue;
			await patchPessoa(personId, key, updatedValue);
			toast.success(t('Usuário atualizado com sucesso!'));
		} catch (error) {
			toast.error(t('Ocorreu um erro!'));
		}
	};

	const updatePeopleState = (prevPeople: Responsible[], personId: number, response: Responsible) => {
		return prevPeople.map((person) =>
			person.id === personId ? { ...person, ...response } : person,
		)
	}
	const patchPessoa = async (personId: number, key: keyof Responsible, updatedValue: InputValuesType) => {
		const personService = new PessoaService();
		personService.patchPessoa(
			{ [key]: updatedValue },
			personId,
			(response) => {
				setPeople((prevPeople) => updatePeopleState(prevPeople, personId, response.data));
				toast.success(t('Usuário atualizado com sucesso!'));
			},
			() => toast.error(t('Ocorreu um erro!')),
			'all=true',
		);
	};
	const fetchExtraColumns = async () => {
		const [columnTypes, extraColumnsData] = await Promise.all([
			peopleManagementService.getColumnsTypes(),
			peopleManagementService.getCompanyColumns(),
		]);

		const formattedExtraColumns = extraColumnsData.results.map((column) => ({
			...column,
			tipo_expanded: columnTypes.results.find(
				(type) => type.id === column.tipo,
			),
		}));

		setColumnsTypes(columnTypes.results);
		setExtraColumns(formattedExtraColumns);

		return formattedExtraColumns;
	};

	useEffect(() => {
		fetchExtraColumns();
	}, []);

	const handleScroll = useCallback(
		(e?: React.UIEvent<HTMLDivElement | HTMLButtonElement, UIEvent>) => {
			if (e) {
				dinamicPagination({
					element: e.target,
					callBack: getPeople,
					loading: loadingPeople,
					page: currentPage,
					totalPages: totalPages,
				});
			}
		},
		[dinamicPagination, getPeople, loadingPeople, currentPage, totalPages],
	);

	const updateExtraColumnHeader = async (newName: string, columnId: string) => {
		try {
			await peopleManagementService.patchCompanyColumn({
				id: columnId,
				nome: newName,
			});
			const updatedColumns = extraColumns?.map((column) =>
				column.id === columnId ? { ...column, nome: newName } : column,
			);
			setExtraColumns(updatedColumns);
		} catch (error) {
			toast.error(t('Erro ao editar coluna!'));
		}
	};

	const updateColumnOrder = async (order: number, columnId: string) => {
		try {
			await peopleManagementService.patchCompanyColumn({
				id: columnId,
				ordenacao: order,
			});
		} catch (error) {
			toast.error(t('Failed to update column order.'));
		}
	};

	const handleOnDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return;
			}

			const { source, destination } = result;

			if (source.index === destination.index) {
				return;
			}

			const tempExtraColumns = [...extraColumns];
			const [removedColumn] = tempExtraColumns.splice(source.index - 1, 1);
			tempExtraColumns.splice(destination.index - 1, 0, removedColumn);

			const minOrder = Math.min(source.index, destination.index);
			const maxOrder = Math.max(source.index, destination.index);

			for (let i = minOrder; i <= maxOrder; i++) {
				const column = tempExtraColumns[i - 1];
				column.ordenacao = i;
			}

			updateColumnOrder(removedColumn.ordenacao, removedColumn.id);

			setExtraColumns(tempExtraColumns);
		},
		[extraColumns, updateColumnOrder],
	);

	const headerData: CellData[] = [
		...initialHeaderData,
		...(extraColumns?.map((item) => ({
			title: item.nome,
			id: item.id,
			ordenacao: item.ordenacao,
		})) ?? []),
	];

	const addNewColumn = (column: ExtraColumn) => {
		const columnType = columnsTypes.find((type) => type.id === column.tipo);
		console.log('columnType', columnType);
		setExtraColumns((currentColumns) => {
			if (currentColumns) {
				return [...currentColumns, { ...column, tipo_expanded: columnType }];
			}
			return [column];
		});
	};
	const deleteExtraColumn = async (columnId: string) => {
		try {
			await peopleManagementService.deleteCompanyColumn(columnId);
			const updatedPeople = people.map((person) => ({
				...person,
				valor_colunas_preenchidas: person.valor_colunas_preenchidas.filter(
					(column) => column.coluna_empresa !== columnId,
				),
			}));

			setExtraColumns(
				(prevColumns) =>
					prevColumns?.filter((column) => column.id !== columnId) ??
					prevColumns,
			);
			setPeople(updatedPeople);
		} catch (error) {
			toast.error(t('Erro ao deletar coluna!'));
		}
	};

	const handleSelectAll = () => {
		setIsSelectAll((prev) => !prev);
		setChecked(isSelectAll ? [] : people.map((item) => item.id));
	};

	const toggleChecked = (id: number) => {
		setChecked((prevChecked) =>
			prevChecked.includes(id)
				? prevChecked.filter((item) => item !== id)
				: [...prevChecked, id],
		);
	};

	const handleChangeColumn = (
		personId: number,
		columnKey: keyof Responsible,
		columnValue: any,
	) => {
		const updatedPersonIndex = people.findIndex(
			(person) => person.id === personId,
		);
		const updatedPerson = {
			...people[updatedPersonIndex],
			[columnKey]: columnValue,
		};

		setPeople((prevPeople) => {
			return prevPeople.map((person) => {
				if (person.id === personId) {
					return updatedPerson;
				}
				return person;
			});
		});

		updatePerson(personId, columnKey, columnValue);
	};

	const handleCreateUserValueResponse = (response: any) => {
		const updatedPerson = updatePersonWithExtraValue(response);
		setPeople((prevPeople) =>
		prevPeople.map((person) =>
			person.id === updatedPerson?.id ? updatedPerson : person,
		),
		);
		toast.success(t('Usuário atualizado com sucesso!'));
	}; 
	const createUserValue = (body: UserValueColumn) => {
		const { pessoa, coluna_empresa, value } = body;
		if (coluna_empresa) {
			return peopleManagementService
			.postUserValueColumn({
			coluna_empresa,
			pessoa,
			valor: value.toString(),
			})
			.then(handleCreateUserValueResponse);
		}
	};

	const updateUserValue = (body: UserValueColumn) => {
		const { pessoa, valueId, value } = body;
		if (valueId) {
			return peopleManagementService
				.patchUserValueColumn({ pessoa, valueId, valor: value.toString() })
				.then(() => toast.success(t('Usuário atualizado com sucesso!')));
		}
	};


	const postExtraValue = (
		columnId: string,
		personId: string,
		value: string | number | boolean,
		valueId?: string,
	) => {
		

		if (valueId) {
			updateUserValue({ valueId, pessoa: personId, value});
		} else {
			createUserValue({ coluna_empresa: columnId, pessoa: personId, value });
		}
	};

	const updatePersonWithExtraValue = (response: ExtraColumnValues) => {
		const person = people.find((person) => person.id === response.pessoa);
		if (person) {
			return {
				...person,
				valor_colunas_preenchidas: [
					...(person.valor_colunas_preenchidas ?? []),
					{
						id: response.id,
						coluna_empresa: response.coluna_empresa,
						pessoa: response.pessoa,
						valor: response.valor,
						coluna_expanded: extraColumns?.find(
							(column) => column.id === response.coluna_empresa,
						),
					},
				],
			};
		}
		return null;
	};

	return (
		<div className="overflow-hidden">
			<Table
				pagination={{
					loading: loadingPeople,
					onClick: (e) => handleScroll(e),
					showLoader: totalPages === currentPage && loadingPeople === false,
				}}
				onDragEnd={handleOnDragEnd}
				headerData={headerData}
				onAddNewColumn={addNewColumn}
				handleChangeHeader={updateExtraColumnHeader}
				handleDeleteHeader={deleteExtraColumn}
				selectAll={{
					actions: (
						<BsPersonDash
							className="text-ivory-primary"
							onClick={() => setIsConfirmModalOpen(true)}
						/>
					),
					onSelectAll: handleSelectAll,
					position: 'start',
				}}
			>
				{people.map((person) => {
					return (
						<TRow key={person.id}>
							<TCellSelectAll
								onClick={() => toggleChecked(person.id)}
								checked={checked.includes(person.id)}
							/>
							<DefaultCell>
								<img
									className="rounded-full"
									alt={person.nome}
									src={person.foto_perfil ?? ''}
								/>
							</DefaultCell>
							<TextCell
								onSave={(value) => {
									handleChangeColumn(person.id, 'email', value);
								}}
							>
								{person.email}
							</TextCell>
							<SelectCell
								options={acessoOptions}
								onSave={(value) => {
									handleChangeColumn(person.id, 'acesso', value);
								}}
								value={person.acesso}
							/>
							<TextCell
								onSave={(value) => {
									handleChangeColumn(person.id, 'carga_horaria', value);
								}}
								type="number"
							>
								{person.carga_horaria}
							</TextCell>
							<TextCell
								onSave={(value) => {
									handleChangeColumn(person.id, 'custo_hora', value);
								}}
								type="number"
							>
								{person.custo_hora}
							</TextCell>
							<ToggleCell
								checked={!person.deleted}
								onToggle={() => {
									handleChangeColumn(person.id, 'deleted', person.deleted);
								}}
							/>
							<DefaultCell>
								{person.datahora_ultimo_login
									? moment(person.datahora_ultimo_login).format('DD/MM/YYYY')
									: '-'}
							</DefaultCell>
							{extraColumns
								?.toSorted((a, b) => (a < b ? -1 : 1))
								.map((column) => {
									const objectValue = person.valor_colunas_preenchidas.find(
										(coluna) => coluna.coluna_empresa === column.id,
									);
									const valor = objectValue ? objectValue.valor : '';
									switch (column.tipo_expanded?.tipo) {
										case 'text':
											return (
												<TextCell
													type="text"
													onSave={(value) => {
														postExtraValue(
															column.id,
															person.id.toString(),
															value,
															objectValue?.id,
														);
													}}
												>
													{valor}
												</TextCell>
											);
										case 'number':
											return (
												<TextCell
													onSave={(value) => {
														postExtraValue(
															column.id,
															person.id.toString(),
															value,
															objectValue?.id,
														);
													}}
													type="number"
												>
													{valor}
												</TextCell>
											);
										case 'boolean':
											return (
												<ToggleCell
													checked={valor === 'true'}
													onToggle={() => {
														postExtraValue(
															column.id,
															person.id.toString(),
															valor === 'true' ? 'false' : 'true',
															objectValue?.id,
														);
													}}
												/>
											);
									}
								})}
						</TRow>
					);
				})}
			</Table>
		</div>
	);
}
