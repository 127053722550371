import styled from 'styled-components';

interface OptionWrapperProps {
	isDisabled?: boolean;
	isSelected?: boolean;
}

export const OptionWrapper = styled.div<OptionWrapperProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: ${({isSelected}) => isSelected && '#eeebe7'};
	cursor: pointer;

	&:hover {
		background-color: #eeebe7;
	}
`;
