import { atom } from 'jotai'

export const projectReportAtom = atom<object[]>([]);

export const currentPageAtom = atom<number>(1);

export const totalPagesAtom = atom<number | null>(null);

export const onFilterEncerradosAtom = atom<boolean>(true);


interface FilterAtom {
	cliente: { value: string, label: string },
	projeto: { value: string, label: string },
	gerente: { value: string, label: string },
}

export const filterAtom = atom<FilterAtom>({
		cliente: { value: '', label: '' },
		projeto: { value: '', label: '' },
		gerente: { value: '', label: '' },
	});

interface SelectShowAtom {
	projetoSelectShow: string,
	pessoasSelectShow: string,
	clienteSelectShow: string,
}

export const selectShowAtom = atom<SelectShowAtom>({
		projetoSelectShow: '',
		pessoasSelectShow: '',
		clienteSelectShow: '',
	})