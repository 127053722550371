import { t } from 'i18next';
import React, { useCallback } from 'react';
import { ToggleCheck, ToggleContainer, ToggleSpan, ToggleButton } from './styled';

interface ToggleSwitchProps {
    checked: boolean;
    setChecked: () => void;
    label?: string;
    disabled?: boolean;
}

export default function ToggleSwitch ({checked, disabled, setChecked, label}: ToggleSwitchProps) {
    
    const handleToggle = useCallback(() => {
        setChecked()
    }, [setChecked])
    
    return (
        <ToggleContainer>
            <ToggleButton disabled={disabled} onClick={handleToggle}>
            <ToggleSpan checked={checked}>
                <ToggleCheck checked={checked} />
                </ToggleSpan>
            </ToggleButton>
            {label && t(label)}
        </ToggleContainer>
    )
}