import React, { useEffect } from 'react';
import HeaderLink from '../../../components/common/HeaderLink';
import { PageInsideContainer } from '../../../components/containers/PageInsideContainer';
import { Kanban } from '../../Registros/Tarefas/Kanban/Kanban';
import FilterOkrKanban from './filtro';
import { useSetAtom } from 'jotai';
import { selectedHeaderOkrsAtom } from '../../../atoms/Okrs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../utils/hooks/useQuery';
import { useFilters } from '../../../contexts/Filter/Task/Filter';
import { SelectOptions } from '../../../models/Select';

const KanbanOKRsPage = () => {
  const setSelectedHeader = useSetAtom(selectedHeaderOkrsAtom);

  useEffect(() => {
    setSelectedHeader("kanban");
    }, []);

	const history = useHistory();
	const query = useQuery();
  const {
		selectedKeyResult
	} = useFilters();

  const keyresultId = (selectedKeyResult as SelectOptions).value

  useEffect(() => {
		setSelectedHeader("kanban");
	}, []);

  return (
    <>
      <HeaderLink type="okrs" headerButtons={[]}></HeaderLink>

      <PageInsideContainer >
        <div className='flex items-center justify-center gap-2  '>
          {!!query.get('urlBack') && (
            <button
              onClick={() => history.push(`/okrs/times?keyresultId=${keyresultId}`)}
              className="flex items-center justify-center border-none p-2 hover:opacity-85 focus:bg-ivory-primary-lighter bg-ivory-lighter rounded-lg text-ivory-primary font-semibold transition-all duration-200 ease-linear text-base tracking-normal "
            >
              <FontAwesomeIcon icon={faArrowLeft} className="voltar-icon" />
              {t('Voltar')}
            </button>
          )}
          <FilterOkrKanban kanban={true} />
        </div>

        <Kanban />
      </PageInsideContainer>
    </>
  );
};

export default KanbanOKRsPage;