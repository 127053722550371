import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Páginas //
import AppTemplate from './pages/_templates/App';
import { RedirectHome } from './pages/Home';
import HomePage from './pages/HomeOld';
import InvitePage from './pages/InvitePage';
import Projetos from './pages/Registros/Projetos';
import Horas from './pages/Registros/Horas';
import ReportPage from './pages/Relatorios';
import ApontamentoHoras from './pages/Registros/ApontamentoHoras';

import RelatorioProjeto from './pages/Relatorios/Projetos';
import './global.css';

import { LoginPage } from './pages/Login/form/LoginPage';
import { RegisterPage } from './pages/Login/form/RegisterPage';
import { LoginForm } from './pages/Login/form/LoginForm';
import { EsqueciSenha } from './pages/Login/form/EsqueciSenha';
import { PrimeiroAcesso } from './pages/Login/form/PrimeiroAcesso';
import { RedefinirSenha } from './pages/Login/form/RedefinirSenha';
import Cadastro from './pages/Login/form/Cadastro';
import { PoliticasDeUso } from './pages/Login/PoliticasDeUso';
import { RegistrosDeHorasProvider } from './contexts/RegistrosDeHoras';
import { CalendarioRegistrosProvider } from './contexts/CalendarioRegistros';
import InfoTarefa from './pages/Registros/Tarefas/InfoTarefa';
import { FilterProvider } from './contexts/Filter/Task/Filter';
import { DataTaskProvider } from './contexts/InfoTarefa/DataTask';
import { KanbanProvider } from './contexts/Kanban/Kanban';
import { FilterCommonStateProvider } from './contexts/Filter/FilterCommonState';
import { ProjectProvider } from './contexts/Project';
import { ProjectFilterProvider } from './contexts/Filter/Project';
import TasksContainer from './pages/Registros/Tarefas';
import { FilterHourProvider } from './contexts/Filter/Hour';
import { FilterGReportProvider } from './contexts/Filter/GeneralReport';
import { FilterProjectRProvider } from './contexts/Filter/ProjectReport';
import { ProjectReportProvider } from './contexts/Reports/Project';
import PersonManagement from './pages/PersonManagement';
import { useUser } from './contexts/User';
import { StopwatchProvider } from './contexts/Stopwatch';
import ProjectParams from './pages/Registros/Projetos/ConfigAvancada/Params';
import ProjectTeam from './pages/Registros/Projetos/ConfigAvancada/Team';
import ProjectsAttachments from './pages/Registros/Projetos/ConfigAvancada/Attachments';
import OKRs from './pages/OKRs';
import KanbanOKRs from './pages/OKRs/KanbanOKRsPage';
import CiclosPage from './pages/OKRs/CiclosPage';
import { ClientesPage } from './pages/Clientes';
import { GraficosHomePage } from './pages/Home/Graficos';

const Error404 = () => {
	return <div>Error404</div>;
};

const routes = [
	{ path: '/', exact: true, component: RedirectHome},
	{ path: '/home', exact: true, component: RedirectHome},
	{ path: '/home/calendar', exact: true, component: HomePage, isHome: true },
	{ path: '/home/reports', exact: true, component: HomePage },
	{ path: '/home/graphs', exact: true, component: GraficosHomePage },
	{ path: '/registros/projetos', exact: true, component: Projetos },
	{ path: '/registros/projetos-list', exact: true, component: Projetos },
	{ path: '/registros/projetos-kanban', exact: true, component: Projetos },
	{ path: '/registros/tarefas', exact: true, component: TasksContainer },
	{ path: '/registros/kanban', exact: true, component: TasksContainer },
	{ path: '/registros/apontamento', exact: true, component: ApontamentoHoras },
	{
		path: '/registros/horas',
		exact: true,
		component: Horas,
		autoRefresh: false,
	},
	{ path: '/relatorios', exact: true, component: ReportPage },
	{ path: '/relatorios/total_horas', exact: true, component: ReportPage },
	{ path: '/relatorios_projeto', exact: true, component: RelatorioProjeto },
	{ path: '/pessoas', exact: true, component: PersonManagement },

	{ path: '/cadastro', exact: true, component: RegisterPage },
	{
		path: '/registros/projetos/:id',
		exact: true,
		component: ProjectParams,
	},
	{
		path: '/registros/projetos/:id/parametros',
		exact: true,
		component: ProjectParams,
	},
	{
		path: '/registros/projetos/:id',
		exact: true,
		component: ProjectParams,
	},
	{
		path: '/registros/projetos/:id/time',
		exact: true,
		component: ProjectTeam,
	},
	{
		path: '/registros/projetos/:id/anexos',
		exact: true,
		component: ProjectsAttachments,
	},
	{
		path: '/registros/tarefas/:id/:name?',
		exact: true,
		component: InfoTarefa,
	},
	{
		path: '/okrs/times',
		exact: true,
		component: OKRs,
	},
	{
		path: '/okrs/kanban',
		exact: true,
		component: KanbanOKRs,
	},
	{
		path: '/okrs/ciclos',
		exact: true,
		component: CiclosPage,
	},
  {
    path: '/clientes',
    exact: true,
    component: ClientesPage
  },
	{
		path: '',
		exact: true,
		component: Error404,
	},
];

const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			!(
				localStorage.getItem('planustk') &&
				localStorage.getItem('planustk') !== null &&
				localStorage.getItem('planustk') !== undefined
			) ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/home/calendar',
					}}
				/>
			)
		}
	/>
);
const FirstAccessRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => <Component {...props} />} />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			localStorage.getItem('planustk') &&
			localStorage.getItem('planustk') !== null &&
			localStorage.getItem('planustk') !== undefined ? (
				<Component {...props} routes={routes} />
			) : (
				<Redirect
					to={{
						pathname: '/login',
					}}
				/>
			)
		}
	/>
);

export default function Routes() {
	const { getUserDetails } = useUser();
	useEffect(() => {
		getUserDetails();
	}, []);
	return (
		<>
			{/* <Suspense fallback={<Loading />}> */}
			<Suspense>
				<Switch>
					<PublicRoute exact path="/login" component={LoginPage} />
					<PublicRoute exact path="/cadastro" component={RegisterPage} />
					<PublicRoute
						exact
						path="/politicas_de_uso"
						component={PoliticasDeUso}
					/>
					<PublicRoute
						exact
						path="/login/esqueci_senha"
						component={EsqueciSenha}
					/>
					<FirstAccessRoute
						exact
						path="/primeiro_acesso/login/:invite"
						component={PrimeiroAcesso}
					/>
					<FirstAccessRoute
						exact
						path="/primeiro_acesso/:invite"
						component={InvitePage}
					/>
					<FirstAccessRoute
						exact
						path="/redefinePassword/:token?"
						component={RedefinirSenha}
					/>

					<CalendarioRegistrosProvider>
						<RegistrosDeHorasProvider>
							<StopwatchProvider>
								<FilterCommonStateProvider>
									<ProjectProvider>
										<FilterProvider>
											<FilterHourProvider>
												<FilterGReportProvider>
													<FilterProjectRProvider>
														<KanbanProvider>
															<ProjectFilterProvider>
																<ProjectReportProvider>
																	<DataTaskProvider>
																		<PrivateRoute
																			path="/"
																			component={AppTemplate}
																		/>
																	</DataTaskProvider>
																</ProjectReportProvider>
															</ProjectFilterProvider>
														</KanbanProvider>
													</FilterProjectRProvider>
												</FilterGReportProvider>
											</FilterHourProvider>
										</FilterProvider>
									</ProjectProvider>
								</FilterCommonStateProvider>
							</StopwatchProvider>
						</RegistrosDeHorasProvider>
					</CalendarioRegistrosProvider>
				</Switch>
			</Suspense>
		</>
	);
}
