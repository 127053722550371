import { t } from 'i18next';
import * as Yup from 'yup';

export const KeyResultSchema = Yup.object().shape({
	nome: Yup.string().required(t('Campo obrigatório.').toString()),
	descricao: Yup.string(),
	valor_inicial: Yup.string().required(t('Campo obrigatório.').toString()),
	valor_final: Yup.string().required(t('Campo obrigatório.').toString()),
	finalidade: Yup.string().required(t('Campo obrigatório.').toString()),
	medida: Yup.string().required(t('Campo obrigatório.').toString()),
	data_entrega: Yup.string().required(t('Campo obrigatório.').toString()),
});

