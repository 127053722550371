import React from 'react';

import Moment from 'react-moment';

import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import { useUser } from '../../../../contexts/User';
import { useStopwatch } from '../../../../contexts/Stopwatch';
import Table from '../../../../components/common/Table';
import { CellData } from '../../../../components/common/Table/types';
import TRow from '../../../../components/common/Table/TRow';
import DefaultCell from '../../../../components/common/Table/Cells/DefaultCell';
import TitleSubtitleCell from '../../../../components/common/Table/Cells/TitleSubtitleCell';
import { HoursRecord } from '../../../../models/HoursRecord';
import PeopleCell from '../../../../components/common/Table/Cells/PeopleCell';
import ActionsCell from '../../../../components/common/Table/ActionsCell';
import { Edit } from '@mui/icons-material';
import TRowHeader from '../../../../components/common/Table/TRowHeader';
import { dinamicPagination } from '../../../../utils/funcoes/dinamicPagination';

interface ListProps {
	data: HoursRecord[];
	loading: boolean;
	totalPages: number | null;
	handlePagination: () => void;
	currentPage: number;
	loadingPagination: boolean;
}

const List = ({
	data,
	loading,
	currentPage,
	handlePagination,
	totalPages,
	loadingPagination,
}: ListProps) => {
	const { t } = useTranslation();
	const { setPreSelectedRecord, setSelectedMenuIndex, handleToggleModal } =
		useStopwatch();
	const { userDetails } = useUser();
	let datas = '';
	const calendarStrings = {
		sameDay: `[${t('Hoje')}], LL`,
		lastDay: `[${t('Ontem')}], LL`,
		lastWeek: 'LL',
		sameElse: 'LL',
	};
	const headerHoras = (dataValue: string) => {
		if (datas === '' || datas !== dataValue) {
			datas = dataValue;
			return (
				<TRowHeader>
					<DefaultCell colSpan={5} className="font-bold text-sm">
						<Moment locale={i18n.language} calendar={calendarStrings}>
							{dataValue}
						</Moment>
					</DefaultCell>
				</TRowHeader>
			);
		} else if (datas === dataValue) {
			return;
		}
	};

	const isUserExterno = userDetails?.acesso_cod === 'e' || !userDetails;

	const headerDataExterno: CellData[] = [
		{ title: t('ID'), width: '10%' },
		{ title: t('Nome da Tarefa'), width: '62%' },
		{ title: t('Responsável'), width: '16%' },
		{ title: t('Horas'), width: '6%' },
	];

	const headerData: CellData[] = [
		...headerDataExterno,
		{ title: t('Ações'), width: '6%' },
	];

	const handleEditHour = (item: HoursRecord) => {
		if (loading) return;
		setPreSelectedRecord({
			projeto: item.projeto,
			projeto_nome: item.projeto_nome,
			tarefa: item.tarefa,
			tarefa_nome: item.tarefa_nome,
			duracao: item.duracao,
			hourId: item.id,
			tipo: item.tipo,
			data: item.data,
			descricao: item.descricao,
		});
		setSelectedMenuIndex(1);
		handleToggleModal(true);
	};
	return (
		<Table
			className="xl:table-fixed"
			headerData={isUserExterno ? headerDataExterno : headerData}
			pagination={{
				loading: loadingPagination,
				onClick: (e) => dinamicPagination({element: e?.target, page: currentPage, totalPages, loading, callBack: handlePagination}),
				showLoader: totalPages === currentPage && loadingPagination === false,
			}}
		>
			{data.map((hour) => {
				return (
					<React.Fragment key={hour.id}>
						{headerHoras(hour.data)}
						<TRow>
							<DefaultCell>{hour.id}</DefaultCell>
							<TitleSubtitleCell
								title={hour.tarefa_nome}
								subtitle={hour.projeto_nome}
							/>
							<PeopleCell
								people={[
									{ nome: hour.pessoa_nome, foto_perfil: hour.pessoa_foto },
								]}
							/>
							<DefaultCell>{hour.duracao.slice(0, 5)}</DefaultCell>
							{!isUserExterno && (
								<ActionsCell
									icons={[
										{
											icon: <Edit fontSize="small" />,
											onClick: () => handleEditHour(hour),
										},
									]}
								/>
							)}
						</TRow>
					</React.Fragment>
				);
			})}
		</Table>
	);
};

export default List;
