import styled from "styled-components";

interface SelectProps {
    width?: string;
    showArrow?: boolean;
}

export const Td = styled.td`
    text-align:start;
    font-weight: 500;
    color: rgb(128, 153, 167);
    vertical-align: middle;
`


export const Select = styled.select<SelectProps>`
    text-align: start;
    color: rgb(128, 153, 167);
    border: none;
    background-color: transparent;
    font-size: 14px;
    padding:  6px 0;
    width: ${({width}) => width ? `${width}px` : '-webkit-fill-available'};
    min-width: ${({width}) => width ? `${width}px` : '-webkit-fill-available'};
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
    ${({showArrow}) => !showArrow && `
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
      ::-ms-expand {
        display: none;
    }`}
    &:focus{
        outline: none;
        padding: 0;
    };
    
    
`

export const Option = styled.option`
    padding: 0;
`