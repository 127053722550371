import React, { createContext, useContext, useState, useMemo } from 'react';
import ClienteService from '../../../services/providers/client';
import { formatValuesToSelectAsync } from '../../../utils/funcoes/reactSelect';

const ProjectFilterContext = createContext();

const ProjectFilterProvider = ({ children }) => {
	const [clientOptions, setClientOptions] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(null);

	const loadClientesSelect = async (
		search, 
		loadedOptions,
		{ placeholder = '', ...rest },
	) => {
		const clientService = new ClienteService();
		if (!totalPages || totalPages >= currentPage) {
			const clientes = await clientService.getClientesSelect(
				currentPage,
				search,
				rest.hasActiveProjects
			);
			setClientOptions(prevData => [
				...prevData,
				...formatValuesToSelectAsync(clientes?.data?.results, placeholder),
			]);
			setCurrentPage(prevValue => prevValue + 1);
			setTotalPages(clientes.data?.total_pages);

			return {
				options: formatValuesToSelectAsync(
					clientes?.data?.results,
					placeholder,
				),
				hasMore: !!clientes?.data?.next,
				additional: {
					placeholder,
				},
			};
		}
		setCurrentPage(1);
		setTotalPages(null);
		return {
			options: [],
			hasMore: false,
			additional: {
				placeholder,
			},
		};
	};

	const projectValue = useMemo(() => ({ clientOptions, loadClientesSelect }), [
		clientOptions,
		loadClientesSelect
	]);

	return (
		<ProjectFilterContext.Provider value={projectValue}>
			{children}
		</ProjectFilterContext.Provider>
	);
};

const useProjectFilters = () => {
	const context = useContext(ProjectFilterContext);
	const { clientOptions, loadClientesSelect } = context;
	return { clientOptions, loadClientesSelect };
};

export { ProjectFilterProvider, useProjectFilters };
