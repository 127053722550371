import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useProject } from '../../../../contexts/Project';
import ProjectCard from '../components/ProjetoCard';
import { useUser } from '../../../../contexts/User';
import { ProjectBasic, ProjectPage } from '../../../../models/Project';
import { sortAlphabetically } from '../../../../utils/funcoes/sort.ts';

interface GridProjectProps {
	setEditingProject: Dispatch<SetStateAction<boolean>>;
	setEditedProject: Dispatch<SetStateAction<ProjectBasic | undefined>>;
}

export default function GridProject({
	setEditingProject,
	setEditedProject,
}: Readonly<GridProjectProps>) {
	const { projects, selectProjectGrid } = useProject();
	const { userDetails } = useUser();

	useEffect(() => {
		selectProjectGrid();
	}, []);
	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-y-5 gap-x-7">
			{sortAlphabetically<ProjectPage>(projects, 'nome')
				.map((item) => {
					return (
						<ProjectCard
							accessCode={userDetails?.acesso_cod}
							data={item}
							id={item.id}
							key={`lista-${item.id}`}
							setEditingProjeto={setEditingProject}
							setEditedProject={setEditedProject}
						/>
					);
				})}
		</div>
	);
}
