import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { SelectOptions } from '../../models/Select';
import { Task } from '../../models/Task';
import TarefaService from '../../services/providers/task';
import { PostPatchResponse } from '../../services/providers/task/types';
import formatArrayToFilter from '../../utils/funcoes/formatArrayToFilter';
import { useFilters } from '../Filter/Task/Filter';
import { DataTaskInitialType, DataTaskProvidedProps } from './types';
import { useUser } from '../User';

let controller: AbortController | null = null; 

const initialValues: DataTaskInitialType = {
	dataTask: [],
	getTasksPagination: () => {},
	loadingTasks: false,
	totalPages: null,
	pages: 1,
	deletedId: null,
	setDeletedId: () => {},
	patchResponse: null,
	setPatchResponse: () => {},
	closedId: null,
	setClosedId: () => {},
	setDataTask: () => {},
	handleAfterPost: () => {},
};

export const DataTaskContext = createContext(initialValues);

const DataTaskProvider = ({ children }: DataTaskProvidedProps) => {
	const [loadingTasks, setLoadingTasks] = useState<boolean>(
		initialValues.loadingTasks,
	);
	const [dataTask, setDataTask] = useState<Task[]>(initialValues.dataTask);
	const [totalPages, setTotalPages] = useState<number | null>(
		initialValues.totalPages,
	);
	const [pages, setPages] = useState<number>(initialValues.pages);
	const [deletedId, setDeletedId] = useState<number | null>(
		initialValues.deletedId,
	);
	const [closedId, setClosedId] = useState<number | null>(
		initialValues.closedId,
	);
	const [patchResponse, setPatchResponse] = useState<PostPatchResponse | null>(
		initialValues.patchResponse,
	);

	const {
		selectedClient,
		selectedProject,
		checkClosedTask,
		searchFilter,
		selectedStatus,
		startDate,
		endDate,
		selectedTag,
		checkCreatedByMe,
		checkMyTasks
	} = useFilters();

	const { userDetails } = useUser();

	const getTasksPagination = (
		projetosFilter: string | number = formatArrayToFilter(
			selectedProject as SelectOptions[],
		),
		page = 1,
	) => {
		if (page === 1) {
			setTotalPages(null);
		}
		setLoadingTasks(true);

		if (controller !== null) {
			controller.abort();
		}

		controller = new AbortController();

		const tarefaService = new TarefaService();
		tarefaService.getTarefasPaginacao(
			{
				page: page,
				search: searchFilter || '',
				cliente: formatArrayToFilter(selectedClient),
				projeto: projetosFilter || '',
				pessoa: checkMyTasks ? userDetails?.id_pessoa ?? '' : '',
				autor: checkCreatedByMe ? userDetails?.id_pessoa ?? '' : '',
				encerradas: checkClosedTask ? 'done' : '',
				tarefa: '',
				tags: formatArrayToFilter(selectedTag) || '',
				status: selectedStatus.value.toString(),
				startDate: startDate,
				endDate: endDate,
				signal: controller?.signal
			},			
			(response) => {
				if (response) {
					if (page === 1) {
						setDataTask(response.results);
						setTotalPages(response.total_pages);
						setPages(page);
					} else {
						setDataTask(prevData => prevData.concat(response.results));
						setTotalPages(response.total_pages);
						setPages(page);
					}
				}
				setLoadingTasks(false);
				window.history.replaceState(null, '');
			},
			() => {
				setDataTask([]);
				setLoadingTasks(false);
			},
		);
	};

	const handleAfterPost = () => {
		getTasksPagination();
	};

	const dataTaskValue = useMemo(
		() => ({
			dataTask,
			getTasksPagination,
			loadingTasks,
			totalPages,
			pages,
			deletedId,
			setDeletedId,
			patchResponse,
			setPatchResponse,
			closedId,
			setClosedId,
			setDataTask,
			handleAfterPost,
		}),
		[
			dataTask,
			getTasksPagination,
			loadingTasks,
			totalPages,
			pages,
			deletedId,
			setDeletedId,
			patchResponse,
			setPatchResponse,
			closedId,
			setClosedId,
			setDataTask,
			handleAfterPost,
		],
	);

	return (
		<DataTaskContext.Provider value={dataTaskValue}>
			{children}
		</DataTaskContext.Provider>
	);
};

function withDataTask (Child: any){
	return function TaskComponent (props: any) {
		return (
			<DataTaskContext.Consumer>
				{context => <Child {...props} {...context} />}
			</DataTaskContext.Consumer>
		);
	}
} 

const useDataTask = () => {
	const context = useContext(DataTaskContext);
	return context;
};

export { DataTaskProvider, withDataTask, useDataTask };
