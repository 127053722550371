import styled from "styled-components";

export const ModalWrapper = styled.div`
    display: flex;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 12;

    .modal-align-generico {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    .modal-content-generico {
        z-index: 2;
        min-width: 55%;
        border-radius: 20px;
        background-color: #f7f6f5;
        padding: 0 15px;
        padding-bottom: 12px;
        border: 1px solid rgb(177, 173, 173);
        max-height: 100vh;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: animatetopGenerico;
        -webkit-animation-duration: 0.3s;
        animation-name: animatetopGenerico;
        animation-duration: 0.3s;
    }

    @keyframes animatetopGenerico {
        from {
            margin-top: -200px;
            opacity: 0;
        }
        to {
            margin-top: 0;
            opacity: 1;
        }
    }
`;

export const Header = styled.div`
    background-color: #f7f6f5;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 50px;
	border-bottom: solid 1px #dbe3e6;

    .modal-header-generico-text {
        margin: 0;
        padding: 0;
        font-size: 20px;
        padding: 6px 10px;
        font-weight: 600;
        color: #426172;
    }
`;

export const Body = styled.div`
    max-width: 85vw;
	max-height: 70vh;
	background-color: #fff;
`;

export const Footer = styled.div`
    display: flex;
	align-items: flex-end;

    .modal-container-button-generico {
        top: 2.1em;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
    }
`;