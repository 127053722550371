import React, { useCallback, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../models/Select';
import { Option, Select, Td } from './styled';

interface TableCellSelectProps {
    value: string;
    name: string;
    options: SelectOptions[];
    width?: string;
    id?: number | string;
    onSubmit: (e: string | number, name: string, id?: number | string) => void;
}

export default function TableCellSelect({
    name,
    options,
    value,
    width,
    id,
    onSubmit
}: TableCellSelectProps) {

    const [showArrow, setShowArrow] = useState(false);
    const [defaultValue, setDefaultValue] = useState(value);

    useEffect(() => {
        setDefaultValue(value);
    }, [value])
    const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        if(e.target.value !== value) {
            onSubmit(e.target.value, name, id);
        }
    }, [name, id, value, onSubmit])

    const handleFocus = useCallback(() => {
        setShowArrow(true)
    }, [])

    const handleBlur = useCallback(() => {
        setShowArrow(false)
    }, [])

    return (
        <Td width={width}>
            <Select 
                name={name} 
                onChange={handleChange} 
                value={defaultValue} 
                onFocus={handleFocus} 
                onBlur={handleBlur} 
                showArrow={showArrow}
                title={options.find(item => item.value === defaultValue)?.label}
            >
                {options.map(option => {
                    return(
                        <Option key={option.value} value={option.value} >{option.label}</Option>
                    )
                })}      
            </Select>
        </Td>
    )
}

