import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import ReactGA from 'react-ga';
import './style.css';

import '../../i18n';
import { useTranslation } from 'react-i18next';
import LanguageChange from '../../i18n/components/LanguageChange';

export default function Login({ children, type }) {
	const {t} = useTranslation();
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	const history = useHistory();
	return (
		<>
			<header className="login-header">
				<div className="login-back" onClick={() => history.goBack()}>
					<FontAwesomeIcon icon={faArrowLeft} />
					<span>{t("Voltar")}</span>
				</div>
				<div style={{ display: 'flex' }}>
					<NavLink to="/login" exact activeClassName="login-header-link-ativo">
						<div>Login</div>
					</NavLink>
					<NavLink to="/cadastro" activeClassName="login-header-link-ativo">
						<div>{t("Cadastre-se")}</div>
					</NavLink>
					{/* <NavLink to="/home" activeClassName="login-header-link-ativo">
					<div>Sobre</div>
				</NavLink>
				<NavLink to="/home" activeClassName="login-header-link-ativo">
					<div>Contrato</div>
				</NavLink> */}
				</div>
			</header>

			<div className={`login-main-container ${type}`}>
				<div
					className="login-alinhar"
					style={{
						maxWidth: type === 'termoDeUso' && '1000px',
						maxHeight: type === 'termoDeUso' && '54vh',
					}}
				>
					<div className={`login-container-modal`}>{children}</div>
				</div>
			</div>
			
			<div className='language'>
				<LanguageChange />
			</div>
		</>
	);
}
