import { atom } from "jotai";
import { ColumnsManager } from "../types";
import { ProjectPage } from "../../../models/Project";

export const initialValues = {
    columnsManager: {
        columns: {},
    columnsOrder: []
    }
}

export const columnsManagerAtom = atom<ColumnsManager<ProjectPage>>(initialValues.columnsManager)