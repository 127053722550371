import moment from "moment";
import React, { createContext, useContext, useState } from "react";
import HorasService from "../services/providers/hour";
import {sortByRecentDate} from '../utils/funcoes/sort.ts';
import { toast } from "react-toastify";

const RegistrosDeHoras = createContext({
    registros: [],
    setRegistros: (registrosArray) => {console.log(registrosArray)  },
    addRegistros: (registrosArray) => {console.log(registrosArray)  },
    saveRegistro: (registro) => { console.log(registro) },
    updateRegistro: ({
        registro,
        paginasHoras,
        searchHoras,
        tarefasSelectFilter ,
        pessoasSelectFilter,
        twoRangeDatesFilter,
    }) => {console.log(registro,
        paginasHoras,
        searchHoras,
        tarefasSelectFilter ,
        pessoasSelectFilter,
        twoRangeDatesFilter)  },
    deleteRegistroById: ({
        registroId,
        paginasHoras,
        searchHoras,
        tarefasSelectFilter ,
        pessoasSelectFilter,
        twoRangeDatesFilter,
    }) => {console.log(registroId,
        paginasHoras,
        searchHoras,
        tarefasSelectFilter ,
        pessoasSelectFilter,
        twoRangeDatesFilter)  },
    loadingRegistros: false,
    updateRegistroTime: (registrosArray) => {console.log(registrosArray) }
});

function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
    })
}

export function RegistrosDeHorasProvider({ children }) {
    const [registros, setRegistros] = useState([]);
    const [loading, setLoading] = useState(false);
    const horaService = new HorasService();
        
    const saveRegistro = (registro) => {
        const tempRegistros = sortByRecentDate([registro, ...registros], "data");
        tempRegistros.pop();
        setRegistros(tempRegistros)
    }

    const updateRegistroTime = (registro) => {
        const isRecordInRegistros = registros.find(item => item.id === registro.id);
        if(isRecordInRegistros) {
            const tempRegistros = registros;
            const registroToUpdateIndex = tempRegistros.findIndex(item => item.id === registro.id);
            tempRegistros.splice(registroToUpdateIndex, 1);
            setRegistros(sortByRecentDate(
                [{...registro}, ...tempRegistros], "data"
            ));
        } else {
            horaService.getHorasById(
                registro.id,
                (result) => {
                    saveRegistro(result.data);
                },
                (error) => {
                    toast.error(error);
                },
            );
        }
    }

    const addRegistros = (registrosArray) => {
        const tempRegistros = sortByRecentDate([...registrosArray, ...registros], "data");
        setRegistros(tempRegistros)
    }


    const deleteRegistroById = ({
        registroId,
        paginasHoras = 1,
        searchHoras = '',
        tarefasSelectFilter = '',
        pessoasSelectFilter = '',
        twoRangeDatesFilter = ['',''],
    }) => {
        const tempRegistros = registros.filter(item => item.id !== registroId);
        setRegistros([...tempRegistros]);
        loadLastRegistro({
            tempRegistros,
            paginasHoras,
            searchHoras,
            tarefasSelectFilter ,
            pessoasSelectFilter,
            twoRangeDatesFilter,
        })
    }

    const updateRegistro = ({
        registro,
        paginasHoras = 1,
        searchHoras = '',
        tarefasSelectFilter = '',
        pessoasSelectFilter = '',
        twoRangeDatesFilter = ['',''],
    }) => {
        const tempRegistros = [...registros];
        const registroToUpdateIndex = tempRegistros.findIndex(item => item.id === registro.id);

        if(registroToUpdateIndex === -1) return;

        if(moment(registro.data).isBefore(registros[registros.length-1].data)) {
            tempRegistros.splice(registroToUpdateIndex, 1);
            setRegistros([...tempRegistros]);
            loadLastRegistro({
                tempRegistros,
                paginasHoras,
                searchHoras,
                tarefasSelectFilter ,
                pessoasSelectFilter,
                twoRangeDatesFilter,
            });
        }else if (
            moment(registro.data).isSame(registros[registros.length-1].data)
            && registro.id > registros[registros.length-1].id
            ) {
            tempRegistros.splice(registroToUpdateIndex, 1);
            setRegistros([...tempRegistros]);
            loadLastRegistro({
                tempRegistros,
                paginasHoras,
                searchHoras,
                tarefasSelectFilter ,
                pessoasSelectFilter,
                twoRangeDatesFilter,
            });
        }else{
            tempRegistros.splice(registroToUpdateIndex, 1, registro);

            setRegistros([...sortByRecentDate(tempRegistros, "data")])
        }
    }

    const loadLastRegistro = ({
        tempRegistros = registros.splice(-1),
        paginasHoras = 1,
        searchHoras = '',
        tarefasSelectFilter = '',
        pessoasSelectFilter = '',
        twoRangeDatesFilter = ['',''],
    }) => {
        setLoading(true);
        const horaService = new HorasService();
        horaService.getHorasPaginacao(
            paginasHoras,
            {
                search: searchHoras,
                tarefa: tarefasSelectFilter,
                pessoa: pessoasSelectFilter,
            },
            twoRangeDatesFilter[0],
            twoRangeDatesFilter[1],
            response => {
                const results = response.results
                if (results) {
                    let unique = [...tempRegistros, results[results.length-1]];
                    unique = removeDuplicates(unique, 'id');
                    setRegistros(unique);
                }
                setLoading(false);
            },
            error => {
                console.log(error);
                setRegistros([]);
                setLoading(false);
                // toast.error(error);
            },
        );
    }

    const handleSetRegistros = (records) => {
        const tempRecords = records.filter(item => item.timestamp === null && item.duracao !== "00:00:00");
        setRegistros(tempRecords);
    };

    return (
        <RegistrosDeHoras.Provider value={{
            registros: registros,
            setRegistros: handleSetRegistros,
            addRegistros: addRegistros,
            saveRegistro: saveRegistro,
            deleteRegistroById: deleteRegistroById,
            updateRegistro: updateRegistro,
            updateRegistroTime: updateRegistroTime,
            loadingRegistros: loading
        }}>
            {children}
        </RegistrosDeHoras.Provider>
    );
}

export function useRegistrosDeHoras() {
    const context = useContext(RegistrosDeHoras);
    return context;
}
