import TextField from '@mui/material/TextField';
import {
  TimePicker as MuiTimePicker
} from '@mui/x-date-pickers/TimePicker';
import * as React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './style.css';
import { FieldFormikProps } from './types';
import { FieldAttributes } from 'formik';


export function TimePicker({ children, ...props }: FieldFormikProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiTimePicker  {...fieldToTimePicker(props)} className={`timePickerModal ${props.className}`}>{children}</MuiTimePicker>
    </LocalizationProvider>
  );
}

TimePicker.displayName = 'FormikMUITimePicker';

function fieldToTimePicker({
  field,
  form: {
    setFieldValue,
    setFieldTouched,
  },
  textField: {  onBlur = () => {}, ...textField } = {},
  disabled,
  onChange,
  renderInput,
  handleSetDuration,
  classNameId,
  style,
  ...props
}: FieldAttributes<any>) {
  return {
    renderInput:
      renderInput ??
      ((params: object) => {
        return(
        <TextField
          className={classNameId}
          {...params}
          // error={false}
          // helperText={false}
          label={false}
          style={style}
          onBlur={
            onBlur ??
            function () {
              setFieldTouched(field.name, true, true);
            }
          }
          {...textField}
        />
      )}),
    disabled: disabled ?? false,
    onChange:
      onChange ??
      function (date: Date) {
        // Do not switch this order, otherwise you might cause a race condition
        // See https://github.com/formium/formik/issues/2083#issuecomment-884831583
        if (date === null || isNaN(date.valueOf())) {
          handleSetDuration(undefined);
          setFieldTouched(field.name, true, false);
          setFieldValue(field.name, '', true);
        } else {
          handleSetDuration(date);
          setFieldTouched(field.name, true, false);
          setFieldValue(field.name, date, true);
        }
      },
    onError: false, 
    ...field,
    ...props,
  };
}