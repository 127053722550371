import React, { ReactNode, TdHTMLAttributes } from 'react';

interface DefaultCellProps extends TdHTMLAttributes<HTMLTableCellElement>{
	children?: ReactNode;
	className?: string;
}

export default function DefaultCell({ children, ...props }: Readonly<DefaultCellProps>) {
	return (
		<td {...props}>
			<div className={`text-ivory-primary px-2`}>{children}</div>
		</td>
	);
}
