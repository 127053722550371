import React, { Dispatch, useEffect, useState } from 'react'
import { TeamPerson } from '../../../../../../../../models/TeamPerson'
import DoughnutCard, { ChartDisplay } from '../../../../../../../../components/common/DoughnutCard';
import { ChartData, ChartOptions } from 'chart.js';
import { PeopleColor } from '../../..';
import { generateRandomColor } from '../../../../../../../../utils/colors';
import { SetStateAction } from 'jotai';
import { t } from 'i18next';

interface HourChartProps {
    projectTeam: TeamPerson[];
    peopleColor?: PeopleColor[];
    loading: boolean;
    setPeopleColor: Dispatch<SetStateAction<PeopleColor[] | undefined>>
}

interface HourResult {
    totalHour: string,
    totalEstimatedHours: string,
    hourByPerson: HourByPerson[]
}

interface HourByPerson {
    id: number;
    hour: string;
    name: string;
}

export default function HourChart({projectTeam, setPeopleColor, loading}: HourChartProps) {
    const [data, setData] = useState<ChartData>({labels: [""],datasets: []});
    const [hourResult, setHourResult] = useState<HourResult>();
    const [chartData, setChartData] = useState<ChartDisplay[]>([]);


    useEffect(() => {
            handleChartData();
            handleHourByProfessional();    
    }, [projectTeam]);

    const plugins = [{
        id: '1',
        beforeDraw: function(chart: any) {

            const width = chart.width;
            const height = chart.height;
            const ctx = chart.ctx;
            ctx.restore();
            const image = new Image();      
            image.src = require('../../../../../../../../assets/svgs/hourChart.svg');      
            const imageWidth = 50;
            const imageHeight = 50;
            ctx.drawImage(image, width / 2 - imageWidth / 2, height / 2 - imageHeight / 2, imageWidth, imageHeight);
            ctx.save();
        } 
      }]

    const extractHour = () => {
        const property = 'horas_realizadas';
        const totalHours = projectTeam.reduce((accumulator, currentTeam) => {
            const splitHour = currentTeam[property].split(":");
            return accumulator + parseInt(splitHour[0]);
        }, 0);
        const totalMinutes = projectTeam.reduce((accumulator, currentTeam) => {
            const splitHour = currentTeam[property].split(":");
            return accumulator + parseInt(splitHour[1]);
        }, 0);
        const totalSeconds = projectTeam.reduce((accumulator, currentTeam) => {
            const splitHour = currentTeam[property].split(":");
            return accumulator + parseInt(splitHour[2]);
        }, 0);
    
        const seconds = totalSeconds % 60;
        const finalSeconds = seconds < 10 ? `0${seconds}` : seconds;
        const minutes = (totalMinutes + Math.floor(seconds / 60));
        const finalMinutes = (minutes % 60) < 10 ? `0${minutes % 60}` : minutes % 60;
        const hours = totalHours + Math.floor(minutes / 60);
        const finalHours = hours < 10 ? `0${hours}` : hours;

        return `${finalHours}:${finalMinutes}:${finalSeconds}`;

    }

    const extractDigit = (num: number) => num < 100 ? ('0'+num).slice(-2) : num;

    const extractHourFromSeconds = (totalSeconds: number) => {
        const totalMinutes = Math.floor(totalSeconds / 60);
    
        const seconds = totalSeconds % 60;
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes/60);

        return `${extractDigit(hours)}:${extractDigit(minutes)}:${extractDigit(seconds)}`
    }

    const options: ChartOptions = {
    
        aspectRatio: 1,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.raw as number;
                        return extractHourFromSeconds(value);
    
                    }
                }
            }
        },
        
    
    }

    const colorPalet =  [
		'#55d8fe',
		'#23d9b7',
		'#f26d85',
		'#a3a0fb',
		'#d7f205',
		'#426172',
		'#5ee21a',
	];

    const handleChartData = () => {
        const labels = projectTeam.map((team) => team.pessoa.nome);
        const data = projectTeam.map((team) => {
            const splitHour = team.horas_realizadas.split(':');
            const hourInSeconds = parseInt(splitHour[0]) * 3600;
            const minutesInSeconds = parseInt(splitHour[1]) * 60;
            const totalSeconds = hourInSeconds + minutesInSeconds + parseInt(splitHour[2]);
            const value = totalSeconds
            return {
                value,
                valueString: team.horas_realizadas,
                color: '',
                title: team.pessoa.nome,
                label: `${team.horas_previstas} ${t('horas')}`
            }
            });
            const dataValue = [...data].sort((a, b) => b.value - a.value);

            const colors: PeopleColor[] = dataValue.map(
                (item, index): PeopleColor => {
                    return {
                        color: index < colorPalet.length ? colorPalet[index] : generateRandomColor(),
                        name: item.title,
                    };
                },
            );
        setPeopleColor(colors);
        const dataWithColor = dataValue.map((item) => ({...item, color: colors.filter(color => color.name === item.title)[0].color}));
        setChartData(dataWithColor);
        
        setData({
            labels: labels,
            datasets: [
                {
                    label: t("Gasto por profissional"),
                    data: dataWithColor.map((item) => item.value),
                    backgroundColor: dataWithColor.map((item) => item.color),
                    borderWidth: 5,
                    borderRadius: 10,
                    fill: 'white',
                }
            ]
        })
    }

    const handleHourByProfessional = () => {
        const hourByPerson: HourByPerson[] = projectTeam.map((team) => {
            return (
                {
                    id: team.id,
                    name: team.pessoa.nome,
                    hour: team.horas_realizadas,
                }
            )
        });
        
        const totalEstimatedHours = projectTeam.reduce((accumulator, currentTeam) => {
            return accumulator + Math.floor(currentTeam.horas_previstas)
        }, 0);
    
        setHourResult({
            totalHour: extractHour(),
            totalEstimatedHours: `${totalEstimatedHours} ${t('horas')}` ,
            hourByPerson
        })


    }

    return(
        <DoughnutCard plugins={plugins} loading={loading} iconSize={50} chartData={chartData.filter((data) => data.value > 0)} data={data} label={hourResult?.totalEstimatedHours ?? ''} labelTitle={t('Horas estimadas')} options={options} title={t("Horas por profissional")} total={hourResult?.totalHour ?? ''} totalTitle={t('Horas do período')} />
    )
}
