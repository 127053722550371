import React, { useCallback, useState } from 'react';
import { useStopwatch } from '../../../contexts/Stopwatch';
import { StopwatchModal } from '../../containers/Modais/StopwatchModal';

import Stopwatch from '../Stopwatch';
import {
	DotsContainer,
	MenuContainer,
	MenuOptions,
	ProjectName,
	StopIcon,
	TaskName,
	TaskNameContainer,
	ThreeDotsIcon,
} from './styled';
import { theme } from '../../../styles/theme';
import HourAddIcon from './HourIcon';
import HourReloadIcon from './HourReloadIcon';
import { ConfirmModal } from '../../containers/Modais/ConfirmModal';
import { TbClockPlus } from 'react-icons/tb';

export default function StopwatchHeader() {
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
	const {
		currentStopwatch,
		stopwatchSeconds,
		handleStopwatch,
		loadingStopwatch,
		handleDeleteStopwatch,
		isModalVisible,
		setSelectedMenuIndex,
		handleToggleModal,
	} = useStopwatch();

	const handleToggleMenu = useCallback(() => {
		setIsMenuVisible((prev) => !prev);
	}, [isMenuVisible]);

	const handleStopStopwatch = useCallback(() => {
		if (currentStopwatch !== '') {
			handleStopwatch({
				taskName: currentStopwatch.tarefa_nome,
				projectId: currentStopwatch.projeto,
				projectName: currentStopwatch.projeto_nome,
				taskId: currentStopwatch.tarefa,
			});
		}
	}, [currentStopwatch, handleStopwatch]);

	const openModal = useCallback(() => {
		setSelectedMenuIndex(0);
		handleToggleModal(true);
	}, []);

	const closeMenu = useCallback(() => {
		setIsMenuVisible(false);
	}, []);

	const handleConfirmModal = useCallback(() => {
		setIsConfirmModalVisible(true);
	}, []);

	const handleRestart = useCallback(() => {
		if (currentStopwatch !== '') {
			handleDeleteStopwatch(currentStopwatch);
		}
	}, [currentStopwatch, handleDeleteStopwatch]);

	return (
		<>
			{isModalVisible && <StopwatchModal />}
			{isConfirmModalVisible && (
				<ConfirmModal
					handleConfirm={handleRestart}
					isConfirmModalOpen={isConfirmModalVisible}
					setIsConfirmModalOpen={setIsConfirmModalVisible}
					text={
						'Deseja reiniciar o cronômetro? Se você fizer isso, todo o tempo corrido será perdido.'
					}
				/>
			)}
			<div className="flex items-center gap-4 px-9 h-full border-x-2 border-zinc-300">
				<button
					title="Registros de Horas"
					className="flex items-center justify-center bg-ivory-green rounded-md w-10 h-10 hover:brightness-95 ease-linear duration-100"
					onClick={currentStopwatch === '' ? openModal : handleStopStopwatch}
				>
					{currentStopwatch === '' && (
						<TbClockPlus className="w-5 h-5 text-white" />
					)}
					{currentStopwatch !== '' && <StopIcon />}
				</button>
				<Stopwatch
					key={`${JSON.stringify(currentStopwatch)}-${JSON.stringify(
						stopwatchSeconds,
					)}`}
					autoStart={currentStopwatch !== ''}
					addSeconds={stopwatchSeconds}
				/>
				{!loadingStopwatch && currentStopwatch !== '' && (
					<>
						<TaskNameContainer>
							<TaskName title={currentStopwatch.tarefa_nome}>
								{currentStopwatch.tarefa_nome}
							</TaskName>
							<ProjectName title={currentStopwatch.projeto_nome}>
								{currentStopwatch.projeto_nome}
							</ProjectName>
						</TaskNameContainer>
						<DotsContainer>
							<ThreeDotsIcon onClick={handleToggleMenu} />
							{isMenuVisible && (
								<MenuContainer onBlur={closeMenu}>
									<MenuOptions onClick={handleConfirmModal}>
										<HourReloadIcon
											size={'16'}
											color={theme.colors.background.mainBg}
										/>
										Descartar cronômetro
									</MenuOptions>
									<MenuOptions onClick={openModal}>
										<HourAddIcon
											color={theme.colors.background.mainBg}
											width={'16'}
										/>
										Adicionar registro de hora
									</MenuOptions>
								</MenuContainer>
							)}
						</DotsContainer>
					</>
				)}
			</div>
		</>
	);
}
