import styled from "styled-components";

export const ActivesCountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

interface CountDiv {
    active?: boolean;
}

export const CountDiv = styled.div<CountDiv>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    background-color: #fff;
    color: ${({active}) => active ? "#23D9B7" : "#D9505D"};
    width: 280px;
    height: 30px;
    border-radius: 10px;
`;