import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { LoaderCircle } from './LoaderCircle';

import '../../../i18n';
import { useTranslation } from 'react-i18next';


interface LoaderProjetoProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	fileData: any[];
	hiddeLoader?: boolean;
	loading?: boolean;
}

const LoaderProjeto: React.FC<LoaderProjetoProps> = ({
	fileData,
	hiddeLoader = false,
	loading = false,
	...rest
}) => {
	const { t } = useTranslation();

	return (
		<>
			{hiddeLoader ? (
				<></>
			) : (
				<div className="projetos-btn-loading">
					{loading ? (
						<>
							{
								<span className="btn-loading-conteudo">
									<span>{<LoaderCircle />}</span>
								</span>
							}
						</>
					) : (
						<>
							{fileData.length > 0 && (
								<button {...rest}>
									<span className="btn-loading-conteudo">
										<span>{t('Carregar mais')}</span>
										<span>
											<FontAwesomeIcon
												icon={faAngleDown}
											/>
										</span>
									</span>
								</button>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
};

export default LoaderProjeto;
