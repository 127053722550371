import styled from 'styled-components';

interface ColumnContentProps {
	isDragging: boolean;
}

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	background-color: #ced8df;
	border-radius: 8px 8px 0 0;

	h3 {
		font-size: 0.75rem;
		font-weight: 700;
		color: #496070;
		margin: 0;
		overflow-wrap: break-word;
	}

	h3:nth-child(2) {
		font-weight: 500;
	}
`;

export const ColumnContent = styled.div<ColumnContentProps>`
	padding: 8px;
	width: 250px;
	border-radius: 0 0 8px 8px;
	height: 100%;
	overflow-y: auto;
	background-color: ${({ isDragging }) =>
		isDragging ? 'lightblue' : '#E6EBEF'};
`;

export const TaskContent = styled.div`
	padding: 2px 8px;
	font-size: 0.75rem;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	height: 100%;

	p {
		margin: 0;
	}
`;

export const HourContainer = styled.div`
	display: flex;
	justify-content: end;
	height: 24px;
	align-items: flex-start;
	font-size: 0.8rem;
	color: #e4e4e4;
`;

export const Title = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-height: 14px;
	font-size: 0.71rem;
	cursor: pointer;
	opacity: 0.6;
	font-weight: 700;
	color: #cccccc;
`;

export const TextDescription = styled.p`
	font-size: 0.8rem;
	color: #e4e4e4;
`;
