import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function ListIcon(props: IconProps) {
  return (
<svg width="16" height="14" viewBox="0 0 16 14" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path d="M3.82031 1.17969H15.5V2.82031H3.82031V1.17969ZM3.82031 7.82031V6.17969H15.5V7.82031H3.82031ZM3.82031 12.8203V11.1797H15.5V12.8203H3.82031ZM0.421875 11.1406C0.682292 10.8802 0.981771 10.75 1.32031 10.75C1.65885 10.75 1.94531 10.8802 2.17969 11.1406C2.4401 11.375 2.57031 11.6615 2.57031 12C2.57031 12.3385 2.4401 12.638 2.17969 12.8984C1.94531 13.1328 1.65885 13.25 1.32031 13.25C0.981771 13.25 0.682292 13.1328 0.421875 12.8984C0.1875 12.638 0.0703125 12.3385 0.0703125 12C0.0703125 11.6615 0.1875 11.375 0.421875 11.1406ZM0.421875 1.10156C0.682292 0.867188 0.981771 0.75 1.32031 0.75C1.65885 0.75 1.94531 0.867188 2.17969 1.10156C2.4401 1.33594 2.57031 1.63542 2.57031 2C2.57031 2.36458 2.4401 2.66406 2.17969 2.89844C1.94531 3.13281 1.65885 3.25 1.32031 3.25C0.981771 3.25 0.682292 3.13281 0.421875 2.89844C0.1875 2.66406 0.0703125 2.36458 0.0703125 2C0.0703125 1.63542 0.1875 1.33594 0.421875 1.10156ZM0.421875 6.10156C0.682292 5.86719 0.981771 5.75 1.32031 5.75C1.65885 5.75 1.94531 5.86719 2.17969 6.10156C2.4401 6.33594 2.57031 6.63542 2.57031 7C2.57031 7.36458 2.4401 7.66406 2.17969 7.89844C1.94531 8.13281 1.65885 8.25 1.32031 8.25C0.981771 8.25 0.682292 8.13281 0.421875 7.89844C0.1875 7.66406 0.0703125 7.36458 0.0703125 7C0.0703125 6.63542 0.1875 6.33594 0.421875 6.10156Z"/>
</svg>

  )
}