import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	ModalConteudoGenerico,
	ModalPrincipalGenerico,
} from '../../../components/containers/Modais/ModalComSideGenerico';
import KeyResultForm from './form';
import { useTranslation } from 'react-i18next';
import { KeyResultResponse, PatchKeyResultProps } from '../../../services/providers/okrs/keyresult/types';
import KeyResultService from '../../../services/providers/okrs/keyresult';
import { toast } from 'react-toastify';
import { keyresultsManagerAtom } from '../../../atoms/Okrs';
import { useAtom } from 'jotai';

export interface ModalKeyResultProps {
	setModalShow: Dispatch<SetStateAction<boolean>>;
	editingKeyResult?: boolean;
	keyresult?: PatchKeyResultProps;
    getKeyresultPagination: (page:number, objetivoId: number) => Promise<void>
    refreshProgresso: ({timeId, objetivoId}: {timeId: number, objetivoId?: number}) => void
	objetivoId: number
	timeId: number
}

export const ModalKeyResult = ({
	setModalShow,
	editingKeyResult = false,
	keyresult,
	objetivoId,
	getKeyresultPagination,
	refreshProgresso,
	timeId
}: ModalKeyResultProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
    const keyResultService = new KeyResultService()
    const [keyResults, setKeyResults] = useAtom(keyresultsManagerAtom)

	const handleRefresh = () => {
		refreshProgresso({
			timeId: timeId,
			objetivoId: objetivoId
		})
	}
	
	const deleteKeyresult = (id: number) => {	
		setLoading(true)	
		
		keyResultService.deleteKeyResult(
			id
		).then(async () => {
			toast.success(t('Key Result removido com sucesso!'));

			if(keyresult) {
				setKeyResults(prev => {
					const keyresultsSelected = prev.keyresultsManager[keyresult.objetivo]
					const tempObjetivos = [...(keyresultsSelected?.keyresults ?? [])].filter(item => item.id !== id);
					
					return {
						keyresultsManager: {
							...prev.keyresultsManager,
							[keyresult.objetivo]: {
								...keyresultsSelected,
								keyresults: tempObjetivos,
							}
						},
					}
				});

				await getKeyresultPagination((keyResults.keyresultsManager[id]?.currentPage ?? 1), keyresult.objetivo)
				handleRefresh()
			setModalShow(false);
			}
		}).catch((err: any) => {
			toast.error(err)
		}).finally(() => {
			setLoading(false)
		});
	};

	const handleAfterPutKeyresult = (keyresultResponse: KeyResultResponse) => {
		setKeyResults(prev => {
			const prevKeyresult = prev.keyresultsManager[keyresultResponse.objetivo]?.keyresults ?? []
			const tempKeyresult = [...prevKeyresult]
			const findIndex = tempKeyresult.findIndex(item => item.id === keyresultResponse.id)

			if(findIndex > -1) {
				const [removed] = tempKeyresult.splice(findIndex, 1)
				tempKeyresult.splice(findIndex, 0, {...removed, ...keyresultResponse})
			}

			return {
				keyresultsManager: {
					...prev.keyresultsManager,
					[keyresultResponse.objetivo]: {
						...prev.keyresultsManager[keyresultResponse.objetivo],
						keyresults: tempKeyresult,
					}
				}
			}
		})
	}

	const encerrarKeyresult = (id: number) => {	
		setLoading(true)	
		
		keyResultService.finalizarKeyResult(
			id
		).then(async (result) => {
			toast.success(t('Key Result finalizado com sucesso!'));

			if(result) {
				handleAfterPutKeyresult(result)

				handleRefresh()
				setModalShow(false);
			}
		}).catch((err: any) => {
			toast.error(err)
		}).finally(() => {
			setLoading(false)
		});
	};

	const resetarKeyresult = (id: number) => {	
		setLoading(true)	
		
		keyResultService.resetarKeyResult(
			id
		).then(async (result) => {
			toast.success(t('Key Result resetado com sucesso!'));

			if(result) {
				handleAfterPutKeyresult(result)

				handleRefresh()
				setModalShow(false);
			}
		}).catch((err: any) => {
			toast.error(err)
		}).finally(() => {
			setLoading(false)
		});
	};

	return (
		<ModalPrincipalGenerico modalGenericoShow={setModalShow}>
			<ModalConteudoGenerico 
				copyText=""
				itemId={keyresult?.id}
				title={editingKeyResult ? t('Edição de Key Result') : t('Novo Key Result')}
				modalGenericoShow={setModalShow}
				btnSave={true}
				loadingBtn={loading}
				modalIsShowing={true}
				saveAndContinue={false}
				handleDeletar={editingKeyResult ? deleteKeyresult : undefined}
				handleEncerrar={(editingKeyResult && keyresult?.finalidade === 'E' ) ? encerrarKeyresult : undefined}
				handleReactivate={(editingKeyResult && keyresult?.finalidade === 'E' ) ? resetarKeyresult : undefined}
				isFinished={keyresult?.valor_atual === keyresult?.valor_final}
				onClickSave={() => {
					document.getElementById('formSideModal-KeyResult')?.click();
				}}
				onClickSaveAndContinue={() => {}}
				showEllipsis={true}
			>
				<KeyResultForm 
					editing={editingKeyResult} 
					setLoading={setLoading} 
					setModalShow={setModalShow} 
					objetivoId={objetivoId} 
					keyresult={keyresult} 
					handleRefresh={handleRefresh}
					handleAfterPutKeyresult={handleAfterPutKeyresult}
				/>
			</ModalConteudoGenerico>
		</ModalPrincipalGenerico>
	);
};
