import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import './style.css';
import { useTranslation } from 'react-i18next';

const ButtonForm = ({ loading, enviadoSucesso, btnType }) => {
	const {t} = useTranslation();
	const TYPES = {
		entrar: { tipo: t('Entrar'), acao: t('Entrando') },
		enviar: { tipo: t('Enviar'), acao: t('Enviando'), feito: t('Enviado') },
		cadastro: { tipo: t('Cadastrar'), acao: t('Cadastrando'), feito: t('Enviado') },
		redefinir: { tipo: t('Redefinir'), acao: t('Redefinindo'), feito: t('Redefinida') },
	};

	const checkBtn = TYPES.hasOwnProperty(btnType)
		? TYPES[btnType]
		: TYPES.entrar;

	return (
		<div className="container-login-form-container-btn">
			<div
				className="wrap-login-form-container-btn"
				style={{
					opacity: loading || enviadoSucesso ? '0.7' : '1',
					pointerEvents: loading || enviadoSucesso ? 'none' : 'visible',
				}}
			>
				<div className="login-form-container-bgbtn"></div>
				<button
					type="submit"
					disabled={loading || enviadoSucesso}
					className="login-form-container-btn"
				>
					{loading || enviadoSucesso
						? ('Loading',
						  (
								<>
									{!enviadoSucesso ? (
										<div>
											<div className="loader-login"></div>
											<span>{checkBtn.acao}...</span>
										</div>
									) : (
										<div>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className="login-check-icon"
											/>

											<span>
												{checkBtn.hasOwnProperty('feito') && checkBtn.feito}
											</span>
										</div>
									)}
								</>
						  ))
						: checkBtn.tipo}
				</button>
			</div>
		</div>
	);
};

export default ButtonForm;
