import moment from 'moment';
import React from 'react';

interface DateCellProps {
	date: Date;
	isDelivery?: boolean;
}

export default function DateCell({ date, isDelivery }: Readonly<DateCellProps>) {
	return (
		<td className='px-2'>
			<div className="flex flex-row gap-2 items-center">
				{moment(date).format('DD/MM/yyyy')}
				{isDelivery && (
					<div
						className={`w-3 h-3 rounded-full ${
							moment(date).isSameOrBefore(new Date())
								? 'bg-delivery-red'
								: 'bg-delivery-green'
						}`}
					/>
				)}
			</div>
		</td>
	);
}
