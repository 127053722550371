import React, { ChangeEvent } from "react"

import { CustomRadioOption } from "../../../../../components/common/CustomRadioOption"

import GraphDecreaseIcon from "./ImagesComponents/GraphDecreaseIcon"
import GraphIncreaseIcon from "./ImagesComponents/GraphIncreaseIcon"
import GraphMaintainIcon from "./ImagesComponents/GraphMaintainIcon"
import GraphCheckIcon from "./ImagesComponents/GraphCheckIcon"
import { twMerge } from "tailwind-merge"

interface FinalidadeInputProps {
    readonly onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    readonly className: string
    readonly value: string
}

export function FinalidadeInput({onChange, className, value}: FinalidadeInputProps) {
    const classes = twMerge(
        'grid grid-cols-4 gap-4 pt-1 px-0 pb-0',
        className
    )

    return (
        <div className={classes}>
            <CustomRadioOption id="finalidade-aumentar" className="w-full whitespace-nowrap" name="finalidade" onChange={onChange} value={"A"} defaultChecked={value === "A"}>
                <GraphIncreaseIcon />
                Aumentar valor
            </CustomRadioOption>

            <CustomRadioOption id="finalidade-reduzir" className="w-full whitespace-nowrap" name="finalidade" onChange={onChange} value={"R"} defaultChecked={value === "R"}>
                <GraphDecreaseIcon />
                Reduzir valor
            </CustomRadioOption>

            <CustomRadioOption id="finalidade-manter" className="w-full whitespace-nowrap" name="finalidade" onChange={onChange} value={"M"} defaultChecked={value === "M"}>
                <GraphMaintainIcon />
                Manter valor
            </CustomRadioOption>

            <CustomRadioOption id="finalidade-entregavel" className="w-full whitespace-nowrap" name="finalidade" onChange={onChange} value={"E"} defaultChecked={value === "E"}>
                <GraphCheckIcon />
                Entregável
            </CustomRadioOption>
        </div>
    )
}