import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { Client } from '../../../models/Client';
import { LoadOptionsAdditional } from '../../../models/Select';
import { formatValuesToSelectAsync } from '../../../utils/funcoes/reactSelect';
import HttpService from '../../config/http';
import { CallbackType, DataType } from '../types';

export default class ClienteService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getClientes = (success: CallbackType<AxiosResponse<Client[]>>, errorCallback: CallbackType<string>, search = '') => {
		this.api
			.get(`api/v1/clientes/?search=${search}`)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t('Erro ao tentar carregar os clientes!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
	getClientesSelect = async (page = 1, search = '', hasActiveProjects?: boolean) => {
		const response: AxiosResponse<DataType<Client[]>> = await this.api
			.get(`api/v1/clientes/?minimal=0&paginate=true&page=${page}&search=${search}&possuiprojetosativos=${hasActiveProjects || ""}`)
			return response;
	};

	loadClientesSelect = async (
		search: string,
		loadedOptions: any,
		{ page = 1, placeholder = '', hasActiveProjects }: LoadOptionsAdditional,
	) => {
		const response: AxiosResponse<DataType<Client[]>> = await this.api.get(
			`api/v1/clientes/?minimal=0&paginate=true&page=${page}&search=${search}&possuiprojetosativos=${hasActiveProjects}`,
		);

		if (response?.status !== 200) {
			if (typeof response?.data === typeof {}) {
				const objError = response?.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof typeof objError]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar salvar o cliente!'));
			}
		}

		return {
			options: formatValuesToSelectAsync(response?.data?.results, placeholder),
			hasMore: !!response?.data?.next,
			additional: {
				page: page + 1,
				placeholder: '',
				hasActiveProjects
			},
		};
	};

	postCliente = (params: FormData, success: CallbackType<AxiosResponse<Client>>, errorCallback: CallbackType<string>) => {
		this.api
			.post('api/v1/clientes/', params, {
				headers: {
					"Content-Type": "multipart/form-data",
				}
			})
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t('Erro ao tentar salvar o cliente!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchCliente = (params: FormData, id: number, success: CallbackType<AxiosResponse<Client>>, errorCallback: CallbackType<string>) => {
		this.api
			.patch(`api/v1/clientes/${id}/`, params, {
				headers: {
					"Content-Type": "multipart/form-data",
				}
			})
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t('Erro ao tentar atualizar o cliente!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteCliente = (id: number, success: CallbackType<AxiosResponse<string>>, errorCallback: CallbackType<string>) => {
		this.api
			.delete('api/v1/clientes/' + id)
			.then(result => {
				if (result.status === 301 || result.status === 204) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t('Erro ao tentar remover o cliente!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
}
