import React, { ReactNode } from "react"

interface TRowHeaderProps {
    children: ReactNode;
}

export default function TRowHeader({children}: Readonly<TRowHeaderProps>) {
    return <tr className={`h-10  bg-table-bgHeader`}>
        {children}
    </tr>
}