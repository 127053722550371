import React from "react";
import { RowWrapper } from "../../../../components/tailwindCommon/Table/RowWrapper";
import { FaCircle } from "react-icons/fa";
import { MdEdit, MdOutlineViewKanban } from "react-icons/md";
import { KeyResultResponse } from "../../../../services/providers/okrs/keyresult/types";
import { NavlinkButton } from "../../../../components/common/HeaderLink/styled";

import { NavLink } from 'react-router-dom';
import { useFilters } from "../../../../contexts/Filter/Task/Filter";
import { FaCirclePlus } from "react-icons/fa6";

interface KeyResultsRowProps {
    readonly keyresults: KeyResultResponse[]
    readonly keyresultModal: () => void
    readonly keyresultModalEdit: (keyresult: KeyResultResponse) => void
}

export function KeyResultsRow({keyresults, keyresultModal, keyresultModalEdit}: KeyResultsRowProps) {
    const {
		setSelectedKeyResult
	} = useFilters();

    return (
        <div>
            <RowWrapper className=' bg-[#F7F6F5] font-medium border-y-border-gray'>
                <div className='col-span-6  pl-14'>
                    <p>Key Results</p>
                </div>

                <div className='col-span-2 flex items-center gap-2'>
                    <p>Progresso por key result</p>
                </div>

                <div className='col-span-1'>
                    Tarefas
                </div>
                
                <div className='col-span-1'>
                    Ações
                </div>
            </RowWrapper>

            <div>
                {keyresults.map(keyresult => {
                    const keyResultWidthLimitMax = keyresult.progresso <= 100 ? keyresult.progresso : 100

                    return (
                        <RowWrapper className='bg-ivory-white font-normal border-y-zinc-300' key={keyresult.id} id={`keyresult-row-${keyresult.id}`}>                            
                            <div className='col-span-6 flex gap-2 items-center pl-14'>
                                <div>
                                    <FaCircle className='h-2 w-2' />
                                </div>
                                <div>
                                    <p className="text-left">{keyresult.nome}</p>
                                </div>
                            </div>

                            <div className='col-span-2 flex items-center gap-2'>
                                <div className='relative w-3/5 flex items-center'>	
                                    <div className={`h-3 w-full bg-slate-300 rounded-full`}></div>
                                    <div className={`absolute left-0 h-3 bg-ivory-green rounded-full`} style={{width: `${keyResultWidthLimitMax}%`}}></div>
                                </div>
                                <p className='min-w-[44px] text-right'>{keyresult.progresso}%</p>
                            </div>

                            <div className='col-span-1'>
                                <p>{keyresult.total_tarefas} {keyresult.total_tarefas === 1 ? "tarefa" : "tarefas"}</p>
                            </div>
                            
                            <div className='col-span-1 flex items-center gap-2'>
                                <button 
                                    className='flex items-center justify-center p-0 m-0 w-7 h-7 bg-ivory-green rounded-md'
                                    onClick={() => keyresultModalEdit(keyresult)}
                                >
                                    <MdEdit className='w-5 h-5 text-white'  />
                                </button>
                                <NavLink 
                                    to={`/okrs/kanban?urlBack=times`} 
                                    className='flex items-center justify-center p-0 m-0 w-7 h-7 bg-ivory-green rounded-md'
                                    onClick={() => {
                                        setSelectedKeyResult({
                                            label: keyresult.nome,
                                            value: keyresult.id
                                        })
                                    }}
                                >
                                    <MdOutlineViewKanban className='w-5 h-5 text-white'  />
                                </NavLink>
                            </div>
                        </RowWrapper>
                    )
                })}

                <RowWrapper className=' bg-[#F7F6F5] font-medium border-y-zinc-300'>
                    <div className='col-span-9  pl-14'>
                    </div>
                    <div className='col-span-1 flex items-center'>
                        <NavlinkButton onClick={keyresultModal} className="whitespace-nowrap px-3 py-3 text-sm ">
                            <FaCirclePlus /> Key Result
                        </NavlinkButton>
                    </div>
                </RowWrapper>

            </div>
        </div>
    )
}