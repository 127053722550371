import React, { useCallback, useEffect, useState } from 'react';

import ClienteService from '../../../services/providers/client';
import PessoaService from '../../../services/providers/responsible';

import LoaderProjeto from '../../../components/common/loaders/LoaderPaginationProjeto';
import TurTutorial from '../../../components/tourTutorial';
import ModalProjeto from './ModalProjeto';

import ReactGA from 'react-ga';
import { useProject } from '../../../contexts/Project';
import { useUser } from '../../../contexts/User';
import HeaderLink from '../../../components/common/HeaderLink';
import ProjectTable from './components/ProjectTable';

import { ProjectsFilter } from './Filter';
import { useTranslation } from 'react-i18next';
import PersonModal from '../../PersonManagement/PersonModal';
import KanbanProject from './Kanban';
import { useHistory, useLocation } from 'react-router-dom';
import { ProjectBasic } from '../../../models/Project';
import GridProject from './Grid';
import HeaderButton from '../../../components/planus2/Buttons/HeaderButton';
import { FaCirclePlus } from 'react-icons/fa6';
import { sortAlphabetically } from '../../../utils/funcoes/sort.ts';

export default function Projetos() {
	const [editingProjeto, setEditingProjeto] = useState(false);
	const [projetoEditado, setProjetoEditado] = useState<ProjectBasic>();
	const [personVisible, setPersonVisible] = useState(false);
	const location = useLocation();

	const { t } = useTranslation();
	const history = useHistory();
	const clientService = new ClienteService();
	const pessoaService = new PessoaService();
	const { loadingUser } = useUser();
	const {
		clienteValorSelecionado,
		myProjects,
		loadingProjects,
		projects,
		getProjects,
		activeOnly,
		totalPages,
		pages,
		modalProjectVisible,
		setModalProjectVisible,
	} = useProject();
	const pagePathname = {
		kanban: '/registros/projetos-kanban',
		grid: '/registros/projetos',
		list: '/registros/projetos-list',
	};
	const { userDetails } = useUser();
	useEffect(() => {
		if (userDetails && location.pathname !== pagePathname['kanban']) {
			getProjects();
		}
	}, [myProjects, clienteValorSelecionado, activeOnly, userDetails, location]);

	useEffect(() => {
		if (projects.length === 0 && userDetails) {
			getProjects();
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [myProjects, clienteValorSelecionado, userDetails]);

	const paginacaoDinamica = (e: any) => {
		const element = e.target;
		if (totalPages === pages || loadingProjects) {
			return;
		} else if (Math.floor(element.scrollHeight - element.scrollTop) === Math.floor(element.clientHeight)) {
				const paginaAtual = pages + 1;

				getProjects(paginaAtual);
				element.scrollTop -= 10;
		}
	};

	const loadStyle = () => {
		const PlanusVisualizacao = localStorage.getItem('PlanusVisualizacao');
		if (!PlanusVisualizacao) {
			pessoaService.getPessoasID(
				userDetails?.id_pessoa,
				(response) => {
					if (
						response.data.tipo_visualizacao === 'g' &&
						location.pathname !== '/registros/projetos'
					) {
						history.push('registros/projetos');
					} else if (
						response.data.tipo_visualizacao === 'l' &&
						location.pathname !== '/registros/projetos-list'
					) {
						history.push('registros/projetos-list');
					} else if (
						response.data.tipo_visualizacao === 'k' &&
						location.pathname !== '/registros/projetos-kanban'
					) {
						history.push('registros/projetos-kanban');
					}
					localStorage.setItem(
						'PlanusVisualizacao',
						`${response.data.tipo_visualizacao}`,
					);
				},
				() => {},
			);
		}
	};

	const handleAddProject = useCallback(() => {
		setEditingProjeto(false);
		setModalProjectVisible(true);
	}, []);

	const handleAddPerson = () => setPersonVisible(true);

	useEffect(() => {
		clientService.getClientesSelect();
	}, []);

	const visualizacoes = {
		'registros/projetos': 'g',
		'registros/projetos-list': 'l',
		'registros/projetos-kanban': 'k',
	};

	type ProjetosViews =
		| 'registros/projetos'
		| 'registros/projetos-list'
		| 'registros/projetos-kanban';
	const saveStyle = () => {
		const pathname = location.pathname as ProjetosViews;
		const tipoVisualizacao = visualizacoes[pathname];
		localStorage.setItem('PlanusVisualizacao', tipoVisualizacao);

		const formData = new FormData();
		formData.append('tipo_visualizacao', tipoVisualizacao);

		if (userDetails) {
			pessoaService.patchPessoa(
				formData,
				userDetails?.id_pessoa,
				() => {},
				() => {},
			);
			loadStyle();
		}
	};
	useEffect(() => {
		saveStyle();
		loadStyle();
	}, [location.pathname]);

	return (
		<>
				<HeaderLink type={'project'} />
				<div className="flex flex-row items-center gap-5 w-full my-5">
					<ProjectsFilter />
					{userDetails && userDetails.acesso_cod === 'a' && (
						<HeaderButton
							onClick={handleAddPerson}
							id={'react-tour__add-pessoa'}
							className="text-white"
						>
							<FaCirclePlus />
							{t('Pessoa')}
						</HeaderButton>
					)}
					{userDetails &&
						(userDetails.acesso_cod === 'a' ||
							userDetails.acesso_cod === 'g') && (
							<HeaderButton
								onClick={handleAddProject}
								id={'react-tour__add-projeto'}
								className="text-white"
							>
								<FaCirclePlus />
								{t('Projeto')}
							</HeaderButton>
						)}
				</div>
				{pagePathname['kanban'] === location.pathname ? (
					<KanbanProject
						setEditingProject={setEditingProjeto}
						setSelectedProject={setProjetoEditado as any}
					/>
				) : (
					<div
						className="overflow-y-auto gap-5 flex flex-col pb-5"
						onScroll={(e) => paginacaoDinamica(e)}
						id="elemento-scroll"
					>
						<div>
							<div className="">
								{!!projects &&
									(location.pathname === pagePathname['grid'] ? (
										<GridProject
											setEditingProject={setEditingProjeto}
											setEditedProject={setProjetoEditado}
										/>
									) : (
										<ProjectTable
											projetoEditado={projetoEditado}
											setEditingProjeto={setEditingProjeto}
											setProjetoEditado={setProjetoEditado}
											setModalProjectVisible={setModalProjectVisible}
										/>
									))}
								{!loadingProjects && !loadingUser && projects.length <= 0 && (
									<p>{t('Nenhum projeto encontrado')}</p>
								)}
							</div>
							<LoaderProjeto
								fileData={sortAlphabetically(projects, 'nome')}
								hiddeLoader={totalPages === pages}
								loading={loadingProjects || loadingUser}
								onClick={() => getProjects(pages + 1)}
							/>
						</div>
					</div>
				)}
			{userDetails && !userDetails?.has_finished_tour && (
				<TurTutorial nomeRota="projeto" />
			)}
			{modalProjectVisible && (
				<ModalProjeto
					setModalShow={setModalProjectVisible}
					editingProjeto={editingProjeto}
					itemSelecionado={projetoEditado}
					needToLoadDetails={!!projetoEditado && !projetoEditado.admins}
				/>
			)}
			{personVisible && <PersonModal
				isShowingModal={personVisible}
				setIsShowingModal={setPersonVisible}
			/>}
		</>
	);
}
