import styled from 'styled-components';

export const ModalWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 12;
`;

export const SideModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin-left: 10px;
	height: 100%;
	width: 100%;
`;

export const SideLabel = styled.label`

`;

export const SideTextarea = styled.textarea`
	height: 100%;
	min-height: max-content;
	outline: none;
	border: 1px solid #838383;
	border-radius: 8px;
	resize: none;
	width: 100%;

`

export const ModalAlignSide = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
`

export const ModalAlignPrincipal = styled.div`
	   display: flex;
    	flex-direction: row;
`

export const StopwatchModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	width: 500px;
	max-width: 80vw;
	height: 470px;
	max-height: 75vh;
	overflow: hidden;

	background: #ffffff;
	z-index: 12;
	-webkit-animation-name: animatetopGenerico;
	-webkit-animation-duration: 0.3s;
	animation-name: animatetopGenerico;
	animation-duration: 0.3s;
	@keyframes animatetopGenerico {
		from {
			margin-top: -200px;
			opacity: 0;
		}
		to {
			margin-top: 0;
			opacity: 1;
		}
	}
`;

export const ModalNav = styled.div`
	display: flex;
	background: #f5f5f5;

	ul {
		display: flex;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
		overflow: auto;

		::-webkit-scrollbar {
			height: 6px;
		}

		li {
			width: 100%;
		}
	}
`;

export const ModalContent = styled.div`
	display: flex;
	padding: 32px;
	overflow: hidden;
	height: 100%;
`;

export const GenericButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	min-width: 36px;
	height: 36px;
	border-radius: 8px;
	border: none;
	background: #5ad8e3;
	font-weight: 500;
	transition: all 0.2s;

	:hover {
		filter: brightness(0.95);
	}
`;

export const AddTaskButton = styled(GenericButton)`
	min-width: 42px;
	height: 42px;
`;

export const BackButton = styled(GenericButton)`
	width: 36px;
	margin: auto;
	margin-left: 12px;
	margin-right: 8px;
`;

export const StartButton = styled(GenericButton)`
	height: 42px;
	width: 100%;
	margin-top: 12px;
	min-height: 42px;
`;

interface MenuItemProps {
	isActived: boolean;
}

export const MenuItem = styled.button<MenuItemProps>`
	display: flex;
	align-items: center;
	width: 100%;
	min-width: 100px;
	justify-content: center;
	border-radius: 8px 8px 0 0;
	font-weight: 500;
	height: 48px;
	border: none;
	background: ${({ isActived }) => (isActived ? '#ffffff' : 'transparent')};
	transition: all 0.1s;

	text-decoration: none;
	color: #000;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		color: #000;
		background: ${({ isActived }) => (isActived ? '#ffffff' : '#fafafa')};
	}
`;

interface SideModalProps {
	isOpen: boolean;
}

export const SideModal = styled.div<SideModalProps>`
		transition: all 0.4s;
		width: 350px;
		height: 402px; 
		margin-left: ${({isOpen = false}) => isOpen ? '-22' : '-340'}px;
		border-radius: 14px 0 0 14px;
		z-index: 2;
		max-height: calc(75vh - 48px);
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		-webkit-animation-name: animatetopGenerico;
		-webkit-animation-duration: 0.3s;
		animation-name: animatetopGenerico;
		animation-duration: 0.3s;
		border-radius: 8px;
		background-color: #EBEBEB;
		align-self: end;
		position: relative;
		display: none;
		@media (min-width: 850px) {
			display: initial;
		}
		@keyframes animatetopGenerico {
        from {
            margin-top: -200px;
            opacity: 1;
        }
        to {
            margin-top: 0;
            opacity: 1;
        }
	}
`
