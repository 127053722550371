import React, { useState, useEffect, ChangeEvent } from 'react';

import ProjetoService from '../../../../../services/providers/project';
import HoraService from '../../../../../services/providers/hour';
import TarefaService from '../../../../../services/providers/task';
import { HorasSchema, HorasSchemaOcio } from './validation';

import { useRegistrosDeHoras } from '../../../../../contexts/RegistrosDeHoras';
import { useCalendarioRegistros } from '../../../../../contexts/CalendarioRegistros';

import { Formik, Field } from 'formik';

import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Checkbox from '../../../../../components/common/Checkboxes/Checkbox';

import FieldFormik from '../../../../../components/common/Fields/FieldFormik'
import { TimePicker } from '../../../../../components/common/Fields/TimePicker';
import AsyncSelector from '../../../../../components/common/AsyncSelector';
import SearchOptionLabel from '../../../../../components/common/AsyncSelector/SearchOptionLabel';
import { emptyLoadOptions } from '../../../../../utils/funcoes/reactSelect';
import  DropdownIndicator  from '../../../../../components/common/SelectDropdowmIndicator';
import { FormValueProps, HorasFormProps, TaskOptions } from './types';

import '../../../../../form/formGeral.css';
import './style.css';
import '../style.css';
import { SelectOptions } from '../../../../../models/Select';
import { useTranslation } from 'react-i18next';

export const HorasForm = (props: HorasFormProps) => {
	const {t} = useTranslation();
	const initialOption: SelectOptions = {
		value: "",
		label: ""
	}
	// selectors
	const [projetoSelectShow, setProjetoSelectShow] = useState<SelectOptions>(initialOption);
	const [tarefaSelectShow, setTarefaSelectShow] = useState<TaskOptions>(initialOption);
	const [duration, setDuration] = useState('');
	const [checkTempoOcioso, setCheckTempoOcioso] = useState(false);
	const { saveRegistro, updateRegistro } = useRegistrosDeHoras();
	const {
		saveCalendarioRegistro,
		updateCalendarioRegistro,
	} = useCalendarioRegistros();

	const { selectedTask, selectedProject } = props;
	//services
	const projetoService = new ProjetoService();
	const tarefaService = new TarefaService();

	const verificaData = () => {
		const dateObj = new Date();
		const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
		const date = ('0' + dateObj.getDate()).slice(-2);
		const year = dateObj.getFullYear();
		const shortDate = year + '-' + month + '-' + date;
		return shortDate;
	};

	const [date, setDate] = useState(verificaData());
	const [isTaskSearchable, setIsTaskSearchable] = useState(
		!projetoSelectShow || projetoSelectShow?.value?.toString()?.length <= 0,
	);
	const [description, setDescription] = useState('');

	useEffect(() => {
		setIsTaskSearchable(
			!projetoSelectShow || projetoSelectShow?.value?.toString()?.length <= 0,
		);
	}, [projetoSelectShow]);

	useEffect(() => {
		verificaData();
		if (props.isFromTarefa && props.item_edit) {
			setProjetoSelectShow({
				value: props.item_edit.projeto,
				label: props.item_edit.projeto_nome,
			});
			setTarefaSelectShow({
				value: props.item_edit.id,
				label: props.item_edit.nome,
				labelAux: props.item_edit.projeto_nome,
				valueAux: props.item_edit.projeto,
			});
		}
	}, []);


	useEffect(() => {
		if(selectedTask && selectedProject) {
			if (
				Object.keys(selectedTask).length > 0 &&
				Object.keys(selectedProject).length > 0
			) {
				setCheckTempoOcioso(false);
				setProjetoSelectShow(selectedProject);
				setTarefaSelectShow(selectedTask);
			}
		}

	}, [selectedTask, selectedProject]);

	useEffect(() => {
		if(projetoSelectShow) {
			props.fillProject(projetoSelectShow);
		}
	}, [projetoSelectShow]);

	useEffect(() => {
		if (props.editing && !props.isFromTarefa && props.item_edit) {
			setProjetoSelectShow({
				value: props.item_edit.projeto,
				label: props.item_edit.projeto_nome,
			});
			setTarefaSelectShow({
				value: props.item_edit.tarefa,
				label: props.item_edit.tarefa_nome,
				labelAux: props.item_edit.projeto_nome,
				valueAux: props.item_edit.projeto,
			});
			setDate(props.item_edit.data);
			setDescription(props.item_edit.descricao);
			setDuration(props.item_edit.duracao);
		}
	}, []);

	useEffect(() => {
		if (checkTempoOcioso) {
			setProjetoSelectShow({
				value: '',
				label: '',
			});
			setTarefaSelectShow({
				value: '',
				label: '',
				labelAux: '',
				valueAux: 0,
			});
		}
	}, [checkTempoOcioso]);

	useEffect(() => {
		if (typeof props.item_edit !== 'undefined') {
			(props.item_edit?.tipo === 'trab' || props.isFromTarefa) &&
			!props?.item_edit?.ocio
				? setCheckTempoOcioso(false)
				: setCheckTempoOcioso(true);
		}
	}, [props.item_edit]);

	let initialValues: FormValueProps;

	if (
		typeof props.item_edit !== 'undefined'
		&& selectedProject
		&& selectedTask
		) {
		if (
			Object.keys(selectedProject).length <= 0 &&
			Object.keys(selectedTask).length <= 0
		) {
			initialValues = {
				projeto: projetoSelectShow?.value || '',
				tarefa: tarefaSelectShow?.value || '',
				data: date || verificaData(),
				duracao: props.isFromTarefa
					? duration !== ''
						? new Date(moment(duration, 'HH:mm:ss').toString())
						: duration
					: new Date(moment(props.item_edit.duracao, 'HH:mm:ss').toString()),
				descricao: description || '',
				tipo: checkTempoOcioso ? 'ocio' : 'trab',
			};
		} else {
			initialValues = {
				projeto: projetoSelectShow?.value || '',
				tarefa: tarefaSelectShow?.value || '',
				data: date || '',
				duracao: props.isFromTarefa
					? duration !== ''
						? new Date(moment(duration, 'HH:mm:ss').toString())
						: duration
					: new Date(moment(props.item_edit.duracao, 'HH:mm:ss').toString()),
				descricao: description || '',
				tipo: checkTempoOcioso ? 'ocio' : 'trab',
			};
		}
	} else {
		if (
			selectedProject && selectedTask &&
			Object.keys(selectedProject).length > 0 &&
			Object.keys(selectedTask).length > 0
		) {
			initialValues = {
				projeto: projetoSelectShow?.value,
				tarefa: tarefaSelectShow?.value,
				data: date,
				duracao:
					duration !== '' ? new Date(moment(duration, 'HH:mm:ss').toString()) : duration,
				descricao: description,
				tipo: checkTempoOcioso ? 'ocio' : 'trab',
			};
		} else {
			initialValues = {
				projeto: projetoSelectShow !== null ? projetoSelectShow?.value : '',
				tarefa: tarefaSelectShow !== null ? tarefaSelectShow?.value : '',
				data: date,
				duracao:
					duration !== '' ? new Date(moment(duration, 'HH:mm:ss').toString()) : duration,
				descricao: description,
				tipo: checkTempoOcioso ? 'ocio' : 'trab',
			};
		}
	}

	const _postHoras = (params: any) => {
		props.handleLoading(true);
		const horaService = new HoraService();
		horaService.postHoras(
			params,
			(response: any) => {
				if (response) {
					toast.success(t('Registro de hora salvo com sucesso!'));
					saveRegistro(response.data);
					saveCalendarioRegistro(response.data);
					props.handlePostPatchHora(props.saveAndContinue);
				}
				props.handleLoading(false);
			},
			(error: any) => {
				toast.error(error);
				props.handleLoading(false);
			},
		);
	};

	const _patchHoras = (params: any, id: number) => {
		props.handleLoading(true);
		const horaService = new HoraService();
		horaService.patchHoras(
			params,
			id,
			(response: any) => {
				if (response) {
					toast.success(t('Registro de hora atualizado com sucesso!'));
					updateRegistro({
						registro: response.data,
						...props.filter,
					});
					updateCalendarioRegistro(response.data);
					props.modalGenericoShow(false);
					props.handlePostPatchHora(props.saveAndContinue);
				}
				props.handleLoading(false);
			},
			(error: any) => {
				toast.error(error);
				props.handleLoading(false);
			},
		);
	};

	const handleAddTask = (isSideOpen = true) => {
		props.addTask(isSideOpen);
	};

	const loadOptionsTask = (
		search: string,
		loadedOptions = [],
		{
			page = 1,
			placeholder = '',
			cliente = '',
			projeto = '',
			pessoa = '',
			isProjectSelected = false,
		},
	) => {
		return isTaskSearchable && search.length < 3
			? emptyLoadOptions(search, loadedOptions, {
				page,
			})
			: tarefaService.loadTarefasSelect(
				search,
				loadedOptions,
				{
					page,
					placeholder,
					cliente,
					projeto,
					pessoa,
					isProjectSelected,
				},
			);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={checkTempoOcioso ? HorasSchemaOcio : HorasSchema}
			enableReinitialize={true}
			onSubmit={values => {
				const horaConvertida = `${new Date(values.duracao).getHours()}:${new Date(values.duracao).getMinutes()}:00`;
				const form_data = new FormData();

				if (props.editing) {
					for (const key in values) {
						const tempKey = key as keyof FormValueProps;
						if (props.item_edit && values[tempKey] !== props.item_edit[tempKey]) {
							if (tempKey === 'duracao') {
								form_data.append(tempKey, horaConvertida);
							} else {
								form_data.append(tempKey, values[tempKey].toString());
							}
						}
					}
				} else {
					for (const key in values) {
						const tempKey = key as keyof FormValueProps;
						if (key === 'duracao') {
							form_data.append(key, horaConvertida);
						} else {
							form_data.append(key, values[tempKey].toString());
						}
					}
				}

				if (props.editing && !props.isFromTarefa && props.item_edit) {
					_patchHoras(form_data, Number(props.item_edit.id));
				} else {
					_postHoras(form_data);
				}
			}}
		>
			{formikProps => {
				const {
					handleSubmit,
					setFieldValue,
					errors,
					touched,
					resetForm,
				} = formikProps;
				return (
					<form onSubmit={handleSubmit}>
						<fieldset>
							<div className="modal-bg-center">
								<div className="form-container-style-tarefas modal-container">
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6">
											{!props?.editing ? (
												<div
													className="modal-botao modal-add-task"
													onClick={() => handleAddTask(true)}
												>
													<FontAwesomeIcon
														icon={faPlus}
														className="plus-modal-icon trash-modal-icon"
													/>
													{t("Adicionar Tarefa")}
												</div>
											) : null}

											<AsyncSelector
												title={t("Projeto")}
												FieldName="projeto"
												id="projeto"
												value={projetoSelectShow || initialOption}
												onChange={e => {
													const option = e as SelectOptions;
													setProjetoSelectShow(option);
													setTarefaSelectShow({label: t('Selecione uma Tarefa'), value: ""});
													setFieldValue('projeto', option !== null ? option.value : '');
												}}
												disabled={checkTempoOcioso}
												placeholder={t('Selecione um Projeto.')}
												error={errors.projeto ? errors.projeto : ''}
												touched={touched.projeto ? touched.projeto : false}
												loadOptions={projetoService.loadProjectsSelect as any}
												additional={{
													page: 1,
												}}
												errorMessage={true}
												isClearable={false}
											/>

											<AsyncSelector
												title={t("Tarefa*")}
												FieldName="tarefa"
												id="tarefa"
												isClearable={false}
												disabled={checkTempoOcioso}
												value={tarefaSelectShow || initialOption}
												formatOptionLabel={
													isTaskSearchable ? SearchOptionLabel : undefined
												}
												components={
													isTaskSearchable ? { DropdownIndicator } : {}
												}
												onChange={e => {
													const option = e as TaskOptions;
													setTarefaSelectShow(option);
													setFieldValue('tarefa', option !== null ? option.value : '');
													if (isTaskSearchable) {
														setProjetoSelectShow({
															value: option.valueAux ? option.valueAux : '',
															label: option.labelAux ? option.labelAux : '',
														});
														setFieldValue('projeto', option.valueAux);
													}
												}}
												name={'tarefa'}
												placeholder={
													isTaskSearchable
														? t('Pesquise uma Tarefa')
														: t('Selecione uma Tarefa')
												}
												noOptionsMessage={(search: {inputValue: string}) => {
													return isTaskSearchable &&
														search.inputValue.length < 3
														? t('Digite o nome da tarefa para pesquisar.')
														: t('Sem tarefa');
												}}
												error={errors.tarefa ? errors.tarefa : ''}
												touched={touched.tarefa ? touched.tarefa : false}
												loadOptions={loadOptionsTask as any}
												additional={{
													page: 1,
													projeto:
														projetoSelectShow !== null
														? projetoSelectShow?.value?.toString()
															: '',
													isProjectSelected: !isTaskSearchable,
												}}
												key={JSON.stringify(projetoSelectShow)}
												errorMessage={true}
											/>

											<Checkbox
												checked={checkTempoOcioso}
												onChange={() => {
													setCheckTempoOcioso(!checkTempoOcioso);
													// resetForm({ projeto: '', tarefa: '' });
													resetForm({
														values: {
															data: formikProps.values.data,
															descricao: formikProps.values.descricao,
															duracao: formikProps.values.duracao,
															projeto: '',
															tarefa: '',
															tipo: formikProps.values.tipo,
														}
													});
												}}
												text={t("Tempo Ocioso")}
												className="hour-modal"
											/>
											<div className="row">
												<div className="col-12 col-lg-7">
													<FieldFormik
														title={t("Data*")}
														max={verificaData()}
														type="date"
														nameField="data"
														autoComplete={'off'}
														errors={errors.data ? errors.data : ''}
														touched={touched.data ? touched.data : false}
														onChange={e => {
															const target = e.target as HTMLInputElement;
															setDate(target.value);
															setFieldValue('data', target.value);
														}}
														value={date}
													/>
												</div>

												<div className="col-12 col-lg-5">
													<FieldFormik
														title={t("Duração*")}
														autoComplete={'off'}
														nameField="duracao"
														errors={errors.duracao ? errors.duracao : ''}
														touched={touched.duracao ? touched.duracao : false}
														renderComponent={TimePicker}
														name="duracao"
														ampm={false}
														onError={() => {}}
														label="none"
														// handleSetDuration={setDuration}
														classNameId={'timePickerModal'}
													/>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-6 col-lg-6">
											<label
												className="modal-label"
												htmlFor="notas"
											>
												{t("Descrição")}
											</label>
											<Field
												title={t("Descrição")}
												autoComplete={'off'}
												type="text"
												className="form-default-style-textarea-projetos"
												component="textarea"
												name="descricao"
												errors={errors.duracao}
												touched={touched.duracao}
												value={description}
												onChange={(e: ChangeEvent) => {
													const target = e.target as HTMLInputElement;
													setDescription(target.value);
													setFieldValue('descricao', target.value);
												}}
											/>
										</div>
									</div>
								</div>
								<br />
								<input
									style={{ display: 'none' }}
									id="input-submit-horas"
									type="submit"
								/>
							</div>
						</fieldset>
					</form>
				);
			}}
		</Formik>
	);
};
