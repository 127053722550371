import * as React from "react"
import { SVGProps } from "react"
const GraphIncreaseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="26" height="20" viewBox="0 0 26 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M22.7874 1.66663H17.1974C16.1851 1.66663 15.678 3.01298 16.3939 3.80048L17.928 5.48798L14.4541 9.30985L10.9801 5.4885C10.3883 4.83746 9.42898 4.83746 8.83759 5.4885L5.58523 9.06611C5.2893 9.39163 5.2893 9.91923 5.58523 10.2448L6.65625 11.4229C6.95218 11.7484 7.43182 11.7484 7.72775 11.4229L9.90862 9.0234L13.3826 12.8448C13.9744 13.4958 14.9337 13.4958 15.5251 12.8448L20.0705 7.84475L21.6046 9.53225C22.3205 10.3198 23.5445 9.76194 23.5445 8.6484V2.49996C23.545 2.03954 23.206 1.66663 22.7874 1.66663Z"/>
    <path d="M24.3022 16.6667H3.84769V0.833333C3.84769 0.372917 3.50867 0 3.09011 0H1.57496C1.1564 0 0.817383 0.372917 0.817383 0.833333V18.3333C0.817383 19.2536 1.49589 20 2.33253 20H24.3022C24.7208 20 25.0598 19.6271 25.0598 19.1667V17.5C25.0598 17.0396 24.7208 16.6667 24.3022 16.6667Z"/>
    </svg>


)
export default GraphIncreaseIcon
