import styled from "styled-components";
import { theme } from "../../../../../styles/theme";


export const InputImage = styled.input`

    display: none;
`

export const Image = styled.img`
    font-weight: 500;
    color: rgb(128, 153, 167);
    border: none;
    background-color: transparent;
    width: 25px;
    height: 25px;
    overflow: hidden;
    border-radius: 50px;
    border: 1px solid ${theme.colors.input.borderBackgroundInput};

`

export const Td = styled.td`
    text-align: center;
    font-weight: 500;
    color: rgb(128, 153, 167);
    vertical-align: middle;
`