import React from 'react';
import './style.css';
import { CheckboxProps } from './types';
const Checkbox = ({ 
	checked, 
	text, 
	className,
	...rest 
}: CheckboxProps) => {
	return (
		<div className={`row col-12 check-tempo-ocioso ${className}`}>
			<input
				type="checkbox"
				id="scales"
				name="scales"
				checked={checked}
				{...rest}
			/>

			{text && (
				<label htmlFor={'scales'} style={{ margin: '-2px 2px 0 6px' }}>
					{text}
				</label>
			)}
		</div>
	);
};

export default Checkbox;
