import React, { useCallback } from 'react';

import {  GroupBase, OptionProps, SingleValueProps } from 'react-select';
import { OptionWrapper } from '../../../../containers/Filter/Option/styled';
import { SelectOptions } from '../../../../../models/Select';
import { ProjectOption } from '../ProjectOption';
import defaultImage from '../../../../../assets/images/company.png';
import {  ProjectSingleValueStyled } from './styled';

export const ProjectSelectOption = (props: OptionProps<SelectOptions>) => {
	const { data, setValue, isSelected, isMulti } = props;
	const handleChange = useCallback(() => {
		if(!data.isDisabled) {
			if (!isSelected) {
				setValue(data, 'select-option');
			}
		}
	}, [isSelected, isMulti, data]);
	
	return (
		<OptionWrapper onClick={handleChange} isDisabled={data.isDisabled}>
			<ProjectOption data={data} isSelected={isSelected} />
		</OptionWrapper>
	)
}

export const ProjectSingleValue = (
	props: SingleValueProps<
		SelectOptions,
		boolean,
		GroupBase<SelectOptions>
	>,
) => {

	const image = props.data.image ? props.data.image : defaultImage;

	return (
		<ProjectSingleValueStyled {...props as any}>
			{props.data.value !== "" && (
				<img src={image} alt="" />
			)}
			<div className='label-content'>
				<span>{props.data.label}</span>
			</div>	
		</ProjectSingleValueStyled>
	);
};