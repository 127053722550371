import HttpService from "../../config/http";

import { CreateSubtaskDto, Subtask } from "../../../models/Subtask";
import { AxiosResponse } from "axios";
import { DataType } from "../types";

export class SubtaskService {
    private api: HttpService;

    constructor(props?: any) {
        this.api = new HttpService(props);
    }

    public async getSubtasksByTask(taskId: number|string, page: number) {
        const response: AxiosResponse<DataType<Subtask[]>> = await this.api.get(`api/v1/subtarefas/?tarefa=${taskId}&page=${page}`);

        if (response.status === 200) { 
			return (response.data);
		} else {
			throw new Error();
		}
    }

    public async postSubtask(subtask: CreateSubtaskDto) {
        const response: AxiosResponse<Subtask> = await this.api.post('api/v1/subtarefas/', subtask);

        if (response.status === 201) { 
			return (response.data);
		} else {
			throw new Error();
		}
    }

    public async patchSubtask(subtask: Subtask) {
        const response: AxiosResponse<Subtask> = await this.api.patch(`api/v1/subtarefas/${subtask.id}/`, subtask);

        if (response.status === 200) { 
			return (response.data);
		} else {
			throw new Error();
		}
    }

    public async deleteSubtask(subtaskId: number) {
        const response: AxiosResponse<Subtask> = await this.api.delete(`api/v1/subtarefas/${subtaskId}/`);

        if (response.status === 204) { 
          return;
        } else {
          throw new Error();
        }
    }
}