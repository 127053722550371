import React, { createContext, useContext, useState } from "react";
import RelatoriosService from '../services/providers/report';
import moment from 'moment';
import HorasService from "../services/providers/hour";
import { toast } from "react-toastify";

const CalendarioRegistros = createContext({
    calendarioRegistros: [],
    refreshCalendarioRegistros: ({dataInicial, dataFinal, idPessoa}) => { console.log(dataInicial, dataFinal, idPessoa) },
    saveCalendarioRegistro: (registro) => {console.log(registro) },
    updateCalendarioRegistro: (registro) => {console.log(registro) },
    deleteCalendarioRegistroById: (registroId) => { console.log(registroId)},
    savedDataInicial: '',
    calendarioLoading: false,
    updateCalendarioRegistroTime: (registro) => {console.log(registro) }
});

export function CalendarioRegistrosProvider({ children }) {
    const [registros, setRegistros] = useState([]);
    const [loading, setLoading] = useState(false);
	const [savedDataInicial, setSavedDataInicial] = useState(moment());
    const savedDataFinal = moment(savedDataInicial).add(6, 'days').format('YYYY-MM-DD');
    const horaService = new HorasService();

    const saveCalendarioRegistro = (registro) => {
        const isBetween = moment(registro.data).isBetween(savedDataInicial, savedDataFinal, undefined, "[]");
        if(isBetween) {
            const tempRegistros = [registro, ...registros];
            setRegistros(tempRegistros)
        }
    }

    const updateCalendarioRegistroTime = (registro) => {
        const tempRegistro = registros.find(item => item.id === registro.id);
        if(tempRegistro) {
            const tempRegistros = registros;
            const registroToUpdateIndex = tempRegistros.findIndex(item => item.id === registro.id);
            const registroToUpdate = tempRegistros.splice(registroToUpdateIndex, 1)[0];

            const isBetween = moment(registro.data).isBetween(savedDataInicial, savedDataFinal, undefined, "[]");
            if(isBetween) {
                const tempRegistros = [{...registroToUpdate, ...registro}, ...registros];
                setRegistros(tempRegistros)
            }
        } else {
            horaService.getHorasById(
                registro.id,
                (result) => {
                    saveCalendarioRegistro(result.data);
                },
                (error) => {
                    toast.error(error);
                },
            );
        }
    }

    const deleteCalendarioRegistroById = (registroId) => {
        const tempRegistros = registros.filter(item => item.id !== registroId);

        setRegistros([...tempRegistros])
    }

    const refreshCalendarioRegistros = ({
        dataInicial = savedDataInicial, 
        dataFinal = savedDataFinal,
        idPessoa = ''
    }) => {
        setLoading(true)
        const relatorioService = new RelatoriosService();
        const clientesArray = '';
        const projetosArray = '';
        const tarefasArray = '';
        const pessoasArray = idPessoa;
        relatorioService.getHoras(            
            {
                cliente: clientesArray,
                projetos: projetosArray,
                tarefas: tarefasArray,
                pessoas: pessoasArray,
                dataInicial: dataInicial,
                dataFinal: dataFinal,
            },
            response => {
                if (response.data) {
                    handleSetRegistros(response.data);
                    setLoading(false);
                }
            },
            () => {
                setRegistros([]);
                setLoading(false);
                // toast.error(error);
            },
        );

        if(dataInicial !== savedDataInicial) {
            setSavedDataInicial(dataInicial);
        }
    }

    const updateCalendarioRegistro = (registro) => {
        const isBetween = moment(registro.data).isBetween(savedDataInicial, savedDataFinal, undefined, "[]")
        if(isBetween) {
            const tempRegistros = [...registros];
            const registroToUpdateIndex = tempRegistros.findIndex(item => item.id === registro.id);
            if(registroToUpdateIndex === -1) return;

            console.log(tempRegistros[registroToUpdateIndex])
            console.log(registro)

            tempRegistros.splice(registroToUpdateIndex, 1, registro);
            setRegistros([...tempRegistros]);
        }else{
            deleteCalendarioRegistroById(registro.id);
        }
    }
    
    const handleSetRegistros = (records) => {
        const tempRecords = records.filter(item => item.timestamp === null  && item.duracao !== "00:00:00");
        setRegistros(tempRecords);
    };

    return (
        <CalendarioRegistros.Provider value={{
            calendarioRegistros: registros,
            refreshCalendarioRegistros: refreshCalendarioRegistros,
            saveCalendarioRegistro: saveCalendarioRegistro,
            deleteCalendarioRegistroById: deleteCalendarioRegistroById,
            updateCalendarioRegistro: updateCalendarioRegistro,
            savedDataInicial: savedDataInicial,
            calendarioLoading: loading,
            updateCalendarioRegistroTime: updateCalendarioRegistroTime
        }}>
            {children}
        </CalendarioRegistros.Provider>
    );
}

export function useCalendarioRegistros() {
    const context = useContext(CalendarioRegistros);
    return context;
}
