import React, { useCallback, useState } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import { useKanban } from '../../../../../contexts/Kanban/Kanban';

import { IoPersonAddSharp } from 'react-icons/io5';
import { MdDelete } from 'react-icons/md';
import { KanbanSubtaskProps } from './types';
import { SelectAssign } from '../../SelectAssign';
import { Responsible } from '../../../../../models/Responsible';
import { SubtaskService } from '../../../../../services/providers/subtask';
import { Subtask } from '../../../../../models/Subtask';
import { toast } from 'react-toastify';
import profileImage from '../../../../../assets/images/profile.png';
import { BiCalendar } from 'react-icons/bi';
import {  RxDragHandleDots2 } from 'react-icons/rx';
import { Draggable } from 'react-beautiful-dnd';
import DefaultCheckbox from '../../../../../components/common/DefaultCheckbox';

export function KanbanSubtask({
	subtasks,
	taskId,
	columnId,
	subtask,
	editSubtaskId,
	setEditSubtaskId,
	openConfirmSubtarefaModal,
	index,
}: KanbanSubtaskProps) {
	const { t } = useTranslation();
	const { handleSetSubtasks, team} = useKanban();
	const [editingName, setEditingName] = useState(false);
	const [isEditingDate, setIsEditingDate] = useState(false);
	const [isAssigning, setIsAssigning] = useState(false);

	const sub: Subtask = {
		...subtask,
		pessoas_responsaveis: team.find((resp) => resp.id === subtask.responsavel),
	};

	const subtaskService = new SubtaskService();

	const switchMenu = useCallback(() => {
		setEditSubtaskId(editSubtaskId === sub.id ? null : sub.id);
		setIsAssigning(false);
	}, [setEditSubtaskId, setIsAssigning, editSubtaskId]);

	const handleAssigning = useCallback(() => {
		setIsAssigning(true);
	}, [setIsAssigning]);

	const patchSubtask = (newSub: Subtask, notCompleteToAdd = 0) => {
		const tempSubtasks = [...subtasks];
		const newSubtaskIndex = tempSubtasks.findIndex(
			(tempSub: any) => tempSub.id === newSub.id,
		);

		subtaskService.patchSubtask(newSub).catch((err) => {
			handleSetSubtasks(tempSubtasks, taskId, columnId, 0, notCompleteToAdd);
			console.log(err);
			toast.error(t('Erro ao tentar atualizar subtarefa!'));
		});

		tempSubtasks.splice(newSubtaskIndex, 1, newSub);
		const [removed] = tempSubtasks.splice(index, 1);
		tempSubtasks.splice(newSub.ordenacao - 1, 0, removed);

		const tempOrderSubtasks = tempSubtasks.map((item, index) => {
			return {
				...item,
				order: index,
			};
		});
		handleSetSubtasks(tempOrderSubtasks, taskId, columnId, 0, notCompleteToAdd);
	};

	const patchSubtaskName = (title: string) => {
		const newSub: Subtask = {
			...sub,
			nome: title,
		};

		patchSubtask(newSub);
	};

	const patchSubtaskDate = (date: string) => {
		const newSub: Subtask = {
			...sub,
			data_conclusao: moment(date, 'YYYY-MM-DD').format(),
		};

		patchSubtask(newSub);
	};

	const patchSubtaskResponsible = useCallback(
		(responsible?: Responsible) => {
			const newSub: Subtask = {
				...sub,
				pessoas_responsaveis: responsible,
				responsavel: responsible?.id,
			};

			patchSubtask(newSub);
			switchMenu();
		},
		[patchSubtask, switchMenu, sub],
	);

	const checkSubtask = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.checked;
			const newSub: Subtask = {
				...sub,
				completada: value,
			};

			patchSubtask(newSub, value ? -1 : 1);
		},
		[patchSubtask],
	);

	const handleSetIsEditingName = useCallback(
		() => setEditingName(true),
		[setEditingName],
	);

	const exitEditingName = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			setEditingName(false);
			e.target.value = '';
		},
		[setEditingName],
	);

	const handlefocus = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => e.target.select(),
		[],
	);

	const keyDownName = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const target = e.target as HTMLInputElement;
			if (e.key === 'Enter') {
				patchSubtaskName(target.value);
				setEditingName(false);
				target.value = '';
			}
		},
		[patchSubtaskName, setEditingName],
	);

	const handleSetIsEditingDate = useCallback(
		() => setIsEditingDate(true),
		[setIsEditingDate],
	);

	const exitEditingDate = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			const value = e.target.value;
			if (value !== sub.data_conclusao?.slice(0, 10) && value !== '') {
				patchSubtaskDate(value);
				e.target.value = '';
			}
			setIsEditingDate(false);
		},
		[setIsEditingDate, patchSubtaskDate],
	);

	const keyDownDate = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const target = e.target as HTMLInputElement;
			if (e.key === 'Enter' && target.value !== '') {
				patchSubtaskDate(target.value);
				setIsEditingDate(false);
				target.value = '';
			}
		},
		[patchSubtaskDate, setIsEditingDate],
	);

	const handleDeleteModal = useCallback(() => {
		openConfirmSubtarefaModal(sub);
		switchMenu();
	}, [openConfirmSubtarefaModal, switchMenu]);

	return (
			<Draggable draggableId={`${subtask.id}`} index={index}>
				{(provided) => {
					return <div {...provided.draggableProps} ref={provided.innerRef} className={`flex flex-col gap-2`}>
					<div className="flex content-center gap-2 justify-between">
						<div className={`flex gap-2 items-center max-w-[7.5rem] relative group/subtask`}>
							<div className='flex items-center justify-center absolute left-[-18px] invisible group-hover/subtask:visible'  {...provided.dragHandleProps}>
							<RxDragHandleDots2 size={18} className={` text-ivory-primary-light `} />

							</div>
							<DefaultCheckbox
								name={`sub-${sub.id}`}
								id={`sub-${sub.id}`}
								checked={sub.completada}
								onChange={checkSubtask}
							/>
							{editingName && (
								<input
									className="outline-none p-0 h-4 bg-transparent w-full text-ivory-primary border border-solid border-ivory-primary-light rounded-default text-xs"
									title="editar nome"
									defaultValue={sub.nome}
									type="text"
									onBlur={exitEditingName}
									autoFocus
									onFocus={handlefocus}
									onKeyDown={keyDownName}
								/>
							)}
							<button
								className={`overflow-hidden whitespace-nowrap cursor-text text-xs text-ellipsis max-h-4  ${
									sub.completada ? 'line-through' : ''
								} ${editingName ? 'hidden' : ''}`}
								title={sub.nome}
								onClick={handleSetIsEditingName}
							>
								{sub.nome}
							</button>
						</div>
		
						<div className="flex gap-2 items-center relative">
							{sub.pessoas_responsaveis && (
								<img
									className="w-4 h-4 rounded-full bg-black bg-opacity-20"
									key={sub.pessoas_responsaveis.id}
									src={
										sub.pessoas_responsaveis.foto_perfil
											? sub.pessoas_responsaveis.foto_perfil
											: profileImage
									}
									alt={''}
									title={sub.pessoas_responsaveis.nome}
								/>
							)}
		
							<button
								className={`${
									isEditingDate ? 'hidden' : 'flex'
								} items-center text-sm`}
								title={`${t('Editar data subtarefa')}`}
								onClick={handleSetIsEditingDate}
							>
								{sub.data_conclusao !== null && sub.data_conclusao !== '' ? (
									moment(sub.data_conclusao).format('DD/MM')
								) : (
									<BiCalendar className="cursor-pointer" size={16} />
								)}
							</button>
							<button
								onClick={handleAssigning}
								className="flex items-center justify-center"
							>
								<IoPersonAddSharp
									size={14}
									className="text-ivory-primary cursor-pointer"
								/>
							</button>
							<button
								title='Deletar subtarefa'
								onClick={handleDeleteModal}
								className="w-4 flex items-center justify-center"
							>
								<MdDelete  className="text-ivory-primary cursor-pointer" />
							</button>
						</div>
					</div>
					{isEditingDate && (
						<input
							title={`${t('Editar data subtarefa')} input`}
							defaultValue={moment(sub.data_conclusao).format('YYYY-MM-DD')}
							type="date"
							onBlur={exitEditingDate}
							autoFocus
							onFocus={handlefocus}
							onKeyDown={keyDownDate}
							className="flex items-center outline-none h-4 py-4 px-2 bg-transparent text-ivory-primary border border-solid border-black rounded-default before:text-ivory-primary before:top-[-2px] before:right-0 before:h-4"
						/>
					)}
					{isAssigning && (
						<div className=" ">
							<SelectAssign
								responsibles={team}
								saveChanges={patchSubtaskResponsible}
								setVisibility={setIsAssigning}
							/>
						</div>
					)}
				</div>
				}}
			</Draggable>
	);
}
