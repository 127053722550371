import styled from "styled-components";
import { AddButtonProps } from "./types";

export const AddInputContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin: 8px 0;

    input {
        border: 1px solid #e9eef1;
        border-radius: 4px;
        width: 100%;
        padding: 4px 8px;
        box-sizing: border-box;
    }

    input:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    input:focus {
        outline: none;
    }
`;

export const AddButtonContainer = styled.div`
    display: flex;
    gap: 8px;

`;

export const AddButton = styled.button<AddButtonProps>`
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    border: none;
    background: ${({isPrimary}) => isPrimary ? "#23D8B6" : "#98ACB8"};
    padding: 2px 0;
    border-radius: 30px;
    min-width: 80px;
    cursor: pointer;
`;
