import React, { useCallback, useEffect, useState } from 'react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import { KanbanSubtask } from '../KanbanSubtask';
import { KanbanAddSubtask } from '../KanbanAddSubtask';
import { KanbanChecklistProps } from './types';
import { CreateSubtaskDto, Subtask } from '../../../../../models/Subtask';
import { SubtaskService } from '../../../../../services/providers/subtask';
import { toast } from 'react-toastify';
import DinamicPagination from '../../../../../components/common/DinamicPagination';
import { useKanban } from '../../../../../contexts/Kanban/Kanban';
import { FaCirclePlus } from 'react-icons/fa6';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';

export function KanbanChecklist({
	columnId,
	task,
	openConfirmSubtarefaModal,
}: KanbanChecklistProps) {
	const { t } = useTranslation();
	const [isChecklistOpen, setIsChecklistOpen] = useState(false);
	const [editSubtaskId, setEditSubtaskId] = useState<number | null>(null);
	const [isAdding, setIsAdding] = useState(false);
	const {
		paginationInfoByTask,
		setPaginantionInfo,
		setLoadingPagination,
		handleSetSubtasks,
	} = useKanban();

	const { loading, currentPage, totalPages } = paginationInfoByTask(
		task.id,
		columnId,
	);

	const subtaskService = new SubtaskService();

	const handleGetSubtasks = useCallback(
		(page: number) => {
			setLoadingPagination({
				value: true,
				columnId: columnId,
				taskId: task.id,
			});

			subtaskService
				.getSubtasksByTask(task.id, page)
				.then((result) => {
					handleSetSubtasks(
						[...task.sub_tarefas, ...result.results],
						task.id,
						columnId,
					);
					setPaginantionInfo({
						value: {
							totalPages: result.total_pages,
							currentPage: page,
							loading: false,
						},
						columnId: columnId,
						taskId: task.id,
					});
				})
				.catch((err) => {
					console.log(err);
					toast.error(t('Erro ao carregar subtarefas!'));
					setLoadingPagination({
						value: false,
						columnId: columnId,
						taskId: task.id,
					});
				});
		},
		[
			handleSetSubtasks,
			setPaginantionInfo,
			setLoadingPagination,
			columnId,
			task,
		],
	);

	const hanldeSetAddingSubtask = useCallback(() => {
		setIsAdding(true);
	}, [setIsAdding]);

	const addSubtask = useCallback(
		(title: string) => {
			setLoadingPagination({
				value: true,
				columnId: columnId,
				taskId: task.id,
			});

			const subtask: CreateSubtaskDto = {
				nome: title,
				tarefa: task.id,
			};

			subtaskService
				.postSubtask(subtask)
				.then((result) => {
					handleSetSubtasks(
						[...task.sub_tarefas, result],
						task.id,
						columnId,
						1,
						1,
					);
					setIsAdding(true);
					setIsChecklistOpen(true);
				})
				.catch((err) => {
					console.log(err);
					toast.error(t('Erro ao tentar criar subtarefa!'));
				})
				.finally(() =>
					setLoadingPagination({
						value: false,
						columnId: columnId,
						taskId: task.id,
					}),
				);
		},
		[setLoadingPagination, setIsAdding, setIsChecklistOpen, handleSetSubtasks],
	);

	useEffect(() => {
		if (
			task.sub_tarefas.length === 0 &&
			task.total_subtarefas > 0 &&
			currentPage === 1
		) {
			handleGetSubtasks(1);
		}
	}, [currentPage]);

	const patchSubtask = async (
		newSub: Subtask,
		index: number,
		notCompleteToAdd = 0,
	) => {
		const tempSubtasks = [...task.sub_tarefas];
		subtaskService.patchSubtask(newSub).catch((err) => {
			handleSetSubtasks(tempSubtasks, task.id, columnId, 0, notCompleteToAdd);
			console.log(err);
			toast.error(t('Erro ao tentar atualizar subtarefa!'));
		});

		const [removed] = tempSubtasks.splice(index, 1);
		tempSubtasks.splice(newSub.ordenacao - 1, 0, removed);

		const tempOrderSubtasks = tempSubtasks.map((item, index) => {
			return {
				...item,
				order: index,
			};
		});
		handleSetSubtasks(
			tempOrderSubtasks,
			task.id,
			columnId,
			0,
			notCompleteToAdd,
		);
	};

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		const { source, destination, draggableId } = result;
		const draggedSubtask = task.sub_tarefas.find(
			(item) => item.id === parseInt(draggableId),
		);
		if (draggedSubtask) {
			const newSub: Subtask = {
				...draggedSubtask,
				ordenacao: destination.index + 1,
			};
			patchSubtask(newSub, source.index);
			console.log('chega aq');
		}
	};

	return (
		<div className="pt-0 group is-small">
			<div className="border-t border-solid border-border-gray pt-2">
				{task.total_subtarefas > 0 && (
					<div>
						<div className="flex items-center justify-between">
							<button
								type="button"
								className="flex justify-between w-full bg-transparent text-ivory-primary border-none p-0 cursor-pointer"
								onClick={() => setIsChecklistOpen(!isChecklistOpen)}
							>
								<span className="bg-ivory-primary px-3 py-1 font-black text-white text-xs rounded-default">
									{task.total_subtarefas - task.total_subtarefas_incompletas}/
									{task.total_subtarefas} {t('Subtarefas')}
								</span>

								{isChecklistOpen ? (
									<BsChevronDown className="text-ivory-primary" size={18} />
								) : (
									<BsChevronUp className="text-ivory-primary" size={18} />
								)}
							</button>
						</div>
						{isChecklistOpen && (
							<DragDropContext onDragEnd={handleDragEnd}>
								<Droppable
									direction="vertical"
									droppableId={task.id.toString()}
								>
									{(provided, snapshot) => {
										return (
											<DinamicPagination
												loading={loading}
												totalPages={totalPages}
												currentPage={currentPage}
												lengthData={task.sub_tarefas.length}
												returnPage={handleGetSubtasks}
												msgToEmptyData={t('Nenhuma subtarefa encontrada.')}
											>
												<div
													{...provided.droppableProps}
													ref={provided.innerRef}
													className={`flex flex-col gap-2 mt-2 mb-3 ${
														snapshot.isDraggingOver
															? 'bg-slate-300'
															: 'bg-transparent'
													}`}
												>
													{task.sub_tarefas.map((sub, index) => {
														return (
															<KanbanSubtask
																key={`sub-${sub.id}`}
																subtask={sub}
																editSubtaskId={editSubtaskId}
																setEditSubtaskId={setEditSubtaskId}
																openConfirmSubtarefaModal={
																	openConfirmSubtarefaModal
																}
																columnId={columnId}
																taskId={task.id}
																subtasks={task.sub_tarefas}
																index={index}
															/>
														);
													})}
													{provided.placeholder}
												</div>
											</DinamicPagination>
										);
									}}
								</Droppable>
							</DragDropContext>
						)}
					</div>
				)}

				{(isChecklistOpen || task.total_subtarefas === 0) && (
					<button
						className="w-fit text-white px-3 py-1 flex flex-row gap-1 items-center justify-center bg-ivory-primary text-xs font-black rounded-default"
						onClick={hanldeSetAddingSubtask}
					>
						<FaCirclePlus className="text-white" /> {t('Subtarefa')}
					</button>
				)}

				{isAdding && (task.sub_tarefas.length === 0 || isChecklistOpen) && (
					<KanbanAddSubtask
						handleAddSubtask={addSubtask}
						setIsAdding={setIsAdding}
					/>
				)}
			</div>
		</div>
	);
}
