import React, { useEffect, useState } from 'react';
import IntegracoesService from '../../../../services/providers/integration';

import ModalGenerico from '../../../../components/containers/Modais/ModalGenerico';

import '../../../../components/containers/Modais/modalGeral.css';
import './style.css';
import { useTranslation } from 'react-i18next';
import "../../../../i18n";

import { LoaderCircle } from '../../../../components/common/loaders/LoaderCircle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCopy
} from '@fortawesome/free-solid-svg-icons';

export default function ModalIntegracoes(props) {
	const {t} = useTranslation();
	// const integracoes = [{'value': 'azuredevops', 'label': 'Azure Devops'}]
	const [loading, setLoading] = useState(false);
  	const [chavesApiData, setChavesApiData] = useState([]);

	const initialValues = { tipo: 'azuredevops',};

	const userTokenService = new IntegracoesService();

	useEffect(() => {
		_carregarUserToken();

	}, []);
	const _getUserTokens = (errorHandler=null) =>{
		const integracoesService = new IntegracoesService();
		setLoading(true);
		integracoesService.getChavesDeUsuario(
			response => {
				if (response.data.results) {
					setChavesApiData(response.data.results)
				}
				setLoading(false);
			},
			error => {
				if(errorHandler != null){
					errorHandler()
				}else{
					setChavesApiData([]);
					setLoading(false);
					// toast.error(error);
				}
			},
		);

	}

	const _carregarUserToken = () =>{
		setLoading(true);
		//logicaoPOST
		userTokenService.postChaveUsuario(initialValues, (resp)=>{
				try{
					_getUserTokens()
				}catch(e){
					console.log(e)
				}
				setLoading(false);
			},
			(resp)=>{
				_getUserTokens()
			},
		)
		setLoading(false);
	}

	const _getDesc = (value) =>{
		if(value === 'azuredevops'){
			return 'Azure DevOps'
		}else{
			return ''
		}
	}

	const handleClickSave = () => {
		document.getElementById('input-submit-integracaoToken').click();
	};


	return (
		<>
			<ModalGenerico
				title={t("Integrações")}
				modalGenericoShow={props.modalInfoPessoaShow}
				modalIsShowing={props.modalIsShowing}
				btnSave={false}
				onClickSave={handleClickSave}
				showEllipsis={false}
				loadingBtn={loading}
			>
				<div className="modal-width-horas">
					{/*<IntegracaoTokenForm itemSelecionado={props.itemSelecionado}  recarregarFunc={_getUserTokens}/>*/}
				</div>
				<div className="modal-width-horas" style={{ marginTop: '1.5em', padding: '1em'}}>
				{ loading  ?
					<p style={{ textAlign: 'center', fontSize: 'large', }}>
					<LoaderCircle />
					</p> :
					<></>
				}
				<div style={{
					display: 'flex',
    			justifyContent: 'space-around'
				}}>
					{
						chavesApiData.map( chave => {
							return (
								<>	<span>{chave.uid} </span>  <span> {_getDesc(chave.tipo)}  </span> <span id="clickbutton" title="Copiar token" onClick={() =>  {
									var spanid = document.getElementById('clickbutton');
									spanid.classList.add("demo");
									navigator.clipboard.writeText(chave.uid);
									setTimeout(()=>{spanid.classList.remove("demo")}, 1000);
								}} > <FontAwesomeIcon icon={faCopy} /> </span> </>
								)
						})

					}
				</div>
				</div>
			</ModalGenerico>
		</>
	);
}
