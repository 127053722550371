import React, { useCallback, useState } from 'react';

import ModalGenerico from '../../../../components/containers/Modais/ModalGenerico';
import { InfoPessoaForm } from '../../../../form/InfoPessoa';

import '../../../../components/containers/Modais/modalGeral.css';
import './style.css';
import { useTranslation } from 'react-i18next';
import "../../../../i18n";

export default function ModalInfoPessoal(props) {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);

	const handleClickSave = () => {
		document.getElementById('input-submit-infoPessoa').click();
	};

	const handleGenericoShow = useCallback((value) => {
		props.modalInfoPessoaShow(value)

	}, [props.modalInfoPessoaShow]);

	return (
		<>
			<ModalGenerico
				title={t("Informações Pessoais")}
				modalGenericoShow={handleGenericoShow}
				modalIsShowing={props.modalIsShowing}
				btnSave={true}
				onClickSave={handleClickSave}
				showEllipsis={false}
				loadingBtn={loading}
			>
				<div className="modal-width-horas">
					<InfoPessoaForm
						modalGenericoShow={handleGenericoShow}
						handleLoading={setLoading}
						editing={true}
						item_edit={props.itemSelecionado}
						handlePostPatchInfoPessoa={props.handlePostPatchInfoPessoa}
					/>
				</div>
			</ModalGenerico>
		</>
	);
}
