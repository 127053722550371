import React from "react";
import { Task } from "../../../../../../models/Task";
import { InfoDescriptionTitle, InfoResponsaveisContainer, InfoResponsavelBody, InfoResponsavelPessoa, ResponsavelImg, ResponsavelImgContainer, ResponsavelName } from "./styled";
import pessoaImage from '../../../../../../assets/images/profile.png'
import { Responsible } from "../../../../../../models/Responsible";
import { t } from "i18next";

interface InfoTarefaResponsaveisProps {
    task: Task
}

export default function InfoTarefaResponsaveis({task}: InfoTarefaResponsaveisProps) {
    return (
        <InfoResponsaveisContainer  className="border border-solid border-ivory-task-detail-border-gray">
					<InfoDescriptionTitle>{t('Responsáveis')}:</InfoDescriptionTitle>
					<InfoResponsavelBody role={'list'}>
						{task?.pessoas_responsaveis.length !== 0
							? task?.pessoas_responsaveis.map((responsavel: Responsible) => (
									<InfoResponsavelPessoa
										role={'listitem'}
										key={responsavel.id}
									>
										<ResponsavelImgContainer>
											<ResponsavelImg
												title={responsavel.nome}
												src={
													responsavel.foto_perfil !== null
														? responsavel.foto_perfil
														: pessoaImage
												}
												alt="erro"
											/>
										</ResponsavelImgContainer>
										<ResponsavelName>
											{responsavel.nome}
										</ResponsavelName>
									</InfoResponsavelPessoa>
							))
							: <span>Sem responsáveis.</span>}
					</InfoResponsavelBody>
				</InfoResponsaveisContainer>
    )
}