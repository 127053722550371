import { t } from 'i18next';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import ToggleSwitch from '../../../components/common/ToggleSwitch';

import FilterContainer from '../../../components/containers/Filter';
import { Option } from '../../../components/containers/Filter/Option';
import { SelectFilterContainer } from '../../../components/containers/Filter/styled';
import { SelectOptions } from '../../../models/Select';
import { selectFilterStyle } from '../../../utils/extenal-libs';
import formatObjectToFilter from '../../../utils/funcoes/formatObjectToFilter';
export interface PersonFilterProps {
    getPeople: (page?: number, values?: {acesso: string, search: string, deleted: boolean}, additional?: string, signal?: AbortSignal) => void;
    loading: boolean;
    acessoOptions: SelectOptions[];
    search: string; 
    setSearch: Dispatch<string>;
    acesso: SelectOptions; 
    setAcesso: Dispatch<SelectOptions>;
    isActive: boolean; 
    setIsActive: Dispatch<SetStateAction<boolean>>;
}

export default function PersonFilter({getPeople, loading, acessoOptions, acesso, isActive, search, setAcesso, setIsActive, setSearch}:PersonFilterProps) {
    const filterOptions: SelectOptions[] = [
        {label: t('Perfil'), value: 'acesso'},
        {label: t('Ativos'), value: 'ativo'}
    ]

    const [openFilters, setOpenFilters] = useState(filterOptions);

    const resetFilters = useCallback(() => {
        setIsActive(true);
        setAcesso({label: t('Perfil'), value: ''});
    }, [])

    const elementsRef = {
        ativo: useRef<HTMLDivElement>(null),
        acesso: useRef<HTMLDivElement>(null)
    }

    const checkVisible = (value: keyof typeof elementsRef) => {
		const hasValue = (item: SelectOptions) => item.value === value; 
		const isVisible = openFilters.some(hasValue)
		return isVisible;
	}

    const handleChangeAcesso = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
        const selected = e as SelectOptions;
        if(selected.value === '') {
            setAcesso({value: '', label: t('Perfil')});
            return;
        }
        setAcesso(selected)
    }, [])

    // const handleChangeAtivo = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
    //     const selected = e as SelectOptions;
    //     if(selected.value === '') {
    //         setAtivo({value: '', label: t('Ativo')});
    //         return;
    //     }
    //     setAtivo(selected)
    // }, [])

  

    const handleSearch = useCallback(() => {
        getPeople(1, {acesso: formatObjectToFilter(acesso), search, deleted: !isActive}, 'all=true')
    }, [getPeople, formatObjectToFilter, acesso, isActive, search]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getPeople(1, {acesso: formatObjectToFilter(acesso), search, deleted: !isActive}, 'all=true',signal);
        return () => {
            controller.abort();
        }
    }, [acesso, isActive])

    const handleChangeActive = () => {
        setIsActive(prev => !prev);
    }

    return (
        <FilterContainer
        filterOptions={filterOptions}
        handleSearch={handleSearch}
        loadingFilters={loading}
        openFilters={openFilters}
        resetFilters={resetFilters}
        searchFilter={search}
        selectedFilters={{isActive, acesso, search}}
        setOpenFilters={setOpenFilters}
        setSearchFilter={setSearch}
        search={true}
    >
        {checkVisible('acesso') && <SelectFilterContainer ref={elementsRef.acesso}>
                <Select
                    options={[{value: '', label: t('Todos')}, ...acessoOptions]}
                    components={{Option}}
                    value={acesso}
                    isClearable={false}
                    onChange={handleChangeAcesso}
                    styles={selectFilterStyle}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    blurInputOnSelect={false}
                    menuPosition="fixed"
                    menuPlacement="auto"
                />
            </SelectFilterContainer>}
            {checkVisible('ativo') && 
            <ToggleSwitch 
                checked={isActive} 
                setChecked={handleChangeActive} 
                label={t("Ativos")} 
            />

            }
    </FilterContainer>
    )
}