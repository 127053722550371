import React, { useState, useEffect } from 'react';

import TarefasChart from './graficos/tarefasChart';
import ProjetosChart from './graficos/projetosChart';
import BarChart from './graficos/barChart';
import CalendarioHome from './calendarioHome';

import TurTutorial from '../../components/tourTutorial';

import { useCalendarioRegistros } from '../../contexts/CalendarioRegistros';

import moment from 'moment';
import ReactGA from 'react-ga';

import './style.css';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import { useUser } from '../../contexts/User';

export default function HomePage() {
	const {t} = useTranslation();
	const {savedDataInicial, refreshCalendarioRegistros, calendarioRegistros} = useCalendarioRegistros();
	const [diasDaSemanaArray, setDiasDaSemanaArray] = useState([]);
	// const [loadingHoras, setLoadingHoras] = useState(false);

	const [dataInicialFilter, setDataInicialFilter] = useState('');
	const [dataFinalFilter, setDataFinalFilter] = useState('');

	// const [layoutSemanaMes, setLayoutSemanaMes] = useState(false);
	const {userDetails} = useUser();
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		if(userDetails?.id_pessoa !== '') {
			manipulaPeriodoSelecionado('semana');
		}
	}, [userDetails]);




	const manipulaPeriodoSelecionado = (opcao, diaAux = savedDataInicial) => {
		const periodoDeDias = [];
		let diaAlvo = moment(diaAux).format('ddd');
		let dataInicial = moment(diaAux).format('YYYY-MM-DD');
		let dataFinal = moment(diaAux).format('YYYY-MM-DD');

		switch (opcao) {
			case 'semana':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}

				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});

					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			case 'next':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}
				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});
					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			case 'prev':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}
				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});
					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			default:
				break;
		}

		if(dataInicial !== savedDataInicial){
			refreshCalendarioRegistros({
				dataInicial: dataInicial,
				dataFinal: dataFinal,
				idPessoa: userDetails?.id_pessoa
			});
		};
		setDataInicialFilter(dataInicial);
		setDataFinalFilter(dataFinal);
		setDiasDaSemanaArray(periodoDeDias);
	};

	return (
		<div id="pagina-principal">
			{userDetails && !userDetails?.has_finished_tour && <TurTutorial nomeRota="home" usuario={userDetails} />}
			<div className="header-main-container">
				<p className="home-header-text">{t("Página Principal")}</p>
			</div>
			<div className="home-container">
				<div className="home-calendario-bar-container ">
					<div className="home-calendario-container">
						<CalendarioHome
							loadingHoras={false}
							registros={calendarioRegistros}
							diasDaSemana={diasDaSemanaArray}
							dataInicial={dataInicialFilter}
							dataFinal={dataFinalFilter}
							weekNavigation={manipulaPeriodoSelecionado}
						/>
					</div>

					<div className="home-barChart-container">
						<BarChart registros={calendarioRegistros} diasDaSemana={diasDaSemanaArray} />
					</div>
				</div>
				<div className="home-pieChart-container">
					<div id="grafico-pizza-tarefa">
						<ProjetosChart
							registros={calendarioRegistros}
							diasDaSemana={diasDaSemanaArray}
						/>
					</div>
					<div id="grafico-pizza-projeto">
						<TarefasChart
							registros={calendarioRegistros}
							diasDaSemana={diasDaSemanaArray}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
