import styled from "styled-components"

interface TdProps {
    width?: string;
}

export const Td = styled.td<TdProps>`
    text-align:start;
    font-weight: 500;
    color: rgb(128, 153, 167);
    vertical-align: middle;
    ${({width}) => width && `width:${width}px`}
    min-width: ${({width}) => width ? `${width}px` : '-webkit-fill-available'};
`