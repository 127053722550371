import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/common/Icons/icon';

import './style.css';

import { DropContainer, UploadMessage } from './styles';

const UploadFile = ({ onUpload }) => {
	const {t} = useTranslation();
	const renderDragMessage = (isDragActive, isDragReject) => {
		if (!isDragActive) {
			return (
				<UploadMessage>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<div className="msg-upload">
							{t("Solte o documento aqui ou clique para anexar")}
						</div>
						<div className="msg-upload-support">
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									padding: '10px 35px',
									color: '#29dff0',
									borderRight: '1px solid #29dff0',
									fontSize: '16px',
								}}
							>
								<Icon icon={faFileUpload} size="lg" pointer={true} />
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								{t("ANEXAR ARQUIVO")}
							</div>
						</div>
					</div>
				</UploadMessage>
			);
		}

		if (isDragReject) {
			return <UploadMessage type="error">{t("Arquivo não suportado")}</UploadMessage>;
		}

		return <UploadMessage type="success">{t("Solte os arquivos aqui")}</UploadMessage>;
	};

	return (
		<Dropzone
			accept="image/*,video/*,.pdf,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,	application/vnd.ms-excel [official], application/msexcel, application/x-msexcel, application/x-ms-excel, application/vnd.ms-excel, application/x-excel, application/x-dos_ms_excel, application/xls,application/vnd.ms-powerpoint,.ppt,.pptx,.doc,.docx,application/msword [official], application/doc, appl/text, application/vnd.msword, application/vnd.ms-word, application/winword, application/word, application/x-msw6, application/x-msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv,text/plain, application/txt, browser/internal, text/anytext, widetext/plain, widetext/paragraph,.rar,.zip,.7zip,application/x-rar-compressed,application/zip,application/x-zip-compressed,.dwg,.fig,.eml"
			//vai ser chamado toda vez que o user dropar arquivos
			onDropAccepted={onUpload}
			maxSize={50000000} //50Mb
		>
			{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
				<DropContainer
					{...getRootProps()}
					isDragActive={isDragActive}
					isDragReject={isDragReject}
				>
					<input {...getInputProps()} />
					{renderDragMessage(isDragActive, isDragReject)}
				</DropContainer>
			)}
		</Dropzone>
	);
};

export default UploadFile;
