import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import ColumnHeader from './ColumnHeader';
import { BodyContainer, Checkbox, CheckboxContainer, HeaderContainer, NoData, NoDataContainer, Table, TableBody, TableWrapper, Thead, TheadToolsContainer, TotalCell, TotalContainer } from './styled';
import { LoaderPagination } from '../../common/loaders/LoaderPagination';
import { SelectOptions } from '../../../models/Select';
import TableCellText from './components/TableCellText';
import TableCellSelect from './components/TableCellSelect';
import TableCellImg from './components/TableCellImg';
import { IconType } from 'react-icons/lib';
import { t } from 'i18next';
import TableCellToggle from './components/TableCellToggle';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { ColumnHeaderDrag } from './ColumnHeaderDrag';
import TableCellTextarea from './components/TableCellTextarea';

export type FieldsHeader = {
    title: string;
    width?: string;
    id?: string;
    deletable?: boolean;
    handleDelete?: (id:string) => void;
    editable?: boolean;
    handleBlur?: (value: string, id:  string) => void;
    tooltip?: string;
}

export interface TableCell {
    value: string | number | boolean;
    name: string;
    src?: string;
    width?: string;
    type: 'text' | 'select' | 'img' | 'toggle' | 'textarea';
    options?: SelectOptions[];
    id: number | string;
    disabled?: boolean;
    inputType?: React.HTMLInputTypeAttribute;
    color?: string;
    isCurreny?: boolean;
	onFocus?: (value: string | number, name: string, id?: number | string) => string;
	onChange?: (value: string | number, name: string, id?: number | string) => string;
    mask?: string;
}

export interface TableBody {
    id: number;
    bgColor?: string;
    row: TableCell[]
}

export interface THeadIcons {
    Icon: IconType,
    color: string;
    onClick: (ids: number[], callback?: () => void) => void;
    title?: string;
    borderWidth?: string;
}

export interface TableTotal {
    title: string;
    width?: number;
    colSpan?: number;
}

export interface CustomTableProps {
    tableHeader: FieldsHeader[], 
    onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    isPaginate?: boolean;
    loading?: boolean;
    showLoader?: boolean;
    handleLoaderPagination?: () => void;
    tableBody: TableBody[];
    onSubmit: (e: string | number | File | boolean, name: string, id?: number | string) => void;
    hasCheckbox?: boolean;
    headerIcons?: THeadIcons[];
    extraHeader?: ReactNode;
    customTableStyle?: string;
    tableId?: string;
    extraHeaderColumns?: FieldsHeader[],
    handleOnDragEnd?: (result: DropResult) => void;
    customTableWrapperStyle?: string;
    customRowStyle?: string;
    tableTotal?: TableTotal[];
    customHeaderStyle?: string;
    customTableBodyStyle?: string;
    tableFontSize?: string;
    customRowHeaderStyle?: string;
    customTotalStyle?: string;
    customCellStyle?: string;

}




export default function CustomTable(
    {tableHeader, 
        onScroll,
        isPaginate,
        loading = false,
        showLoader = true,
        handleLoaderPagination = () => {},
        tableBody,
        onSubmit,
        hasCheckbox,
        headerIcons,
        extraHeader,
        customTableStyle,
        tableId,
        extraHeaderColumns,
        handleOnDragEnd,
        customTableWrapperStyle,
        customRowStyle,
        tableTotal,
        customHeaderStyle,
        customTableBodyStyle,
        tableFontSize,
        customRowHeaderStyle,
        customTotalStyle,
        customCellStyle
    }: CustomTableProps) {

        const [checked, setChecked] = useState<number[]>([]);
        const [selectAll, setSelectAll] = useState<boolean>(false);

        const handleChangeSelect = useCallback(() => setSelectAll(prev => !prev), [selectAll])

        const selectAllRows = () => {
            if(selectAll) {
                const rowsIds = tableBody.map((row) => row.id);
                setChecked(rowsIds);
            }else {
                setChecked([]);
            }
        }
        useEffect(() => {
           selectAllRows();

        }, [selectAll])

        const resetChecked = () => setChecked([]);

        const getColSpan = () => {
            const headerLength = tableHeader.length + (extraHeaderColumns?.length || 0);
            const isCheckbox = hasCheckbox ? 1 : 0;
            return headerLength + isCheckbox + 1
        }

        const NoHandleFunction = useCallback(() => console.log("Nenhuma função recebida"), [])

    return (
        <TableWrapper id={tableId} onScroll={onScroll} customTableStyle={customTableWrapperStyle}>
            {headerIcons && hasCheckbox && 
                <TheadToolsContainer>
                    <Checkbox checked={selectAll} onChange={handleChangeSelect} />
                    {headerIcons.map((icon) => 
                        <icon.Icon 
                            key={icon.title} 
                            color={icon.color} 
                            title={t(icon.title || '')} 
                            cursor={'pointer'} 
                            onClick={() => icon.onClick(checked, resetChecked)}
                        />
                    )}
                </TheadToolsContainer>
            }
            <Table customTableStyle={customTableStyle}>
                <Thead customTableStyle={customHeaderStyle} >
                <DragDropContext onDragEnd={handleOnDragEnd ? handleOnDragEnd : NoHandleFunction}>
                    <Droppable
                        droppableId="header-table"
                        direction="horizontal"
                        type="column"
                    >
                    {provided => {
                        return (
                            <HeaderContainer
                                customTableStyle={customRowHeaderStyle}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {hasCheckbox && <th style={{minWidth: '50px'}}></th>}
                                {tableHeader.map((item) => 
                                        <ColumnHeader customStyle={customCellStyle}  fontSize={tableFontSize} key={item.title} {...item} />
                                )}
                                {extraHeaderColumns && (
                                        extraHeaderColumns.map((item, index) => 
                                            <ColumnHeaderDrag key={item.id} {...item} id={item.id} index={index} />
                                        )
                                )}
                               {extraHeader && <th style={{width: '100%', textAlign: "left"}}>
                                    {extraHeader}
                                </th>}
                            </HeaderContainer>
                            )
                        }}
                    </Droppable>
                </DragDropContext>
                </Thead>
                <TableBody tableTotal={!!tableTotal} customTableStyle={customTableBodyStyle}   >
                    {tableBody.map((row) => (
                        <BodyContainer bgColor={row.bgColor} className='page-break'  customTableStyle={customRowStyle} key={row.id}>
                            {hasCheckbox &&  (
                                <CheckboxContainer>
                                    <span>
                                        <div>
                                            <CheckboxItem 
                                                currentId={row.id}
                                                ids={checked}
                                                setIds={setChecked}
                                                />
                                        </div>
                                    </span>
                                </CheckboxContainer>
                            )}
                            {row.row.map((cell) => {
                                switch(cell.type) {
                                    case 'text':
                                        return (
                                            <TableCellText 
                                                customStyle={customCellStyle}
                                                fontSize={tableFontSize}
                                                key={cell.name}
                                                name={cell.name}
                                                value={cell.value?.toString()}
                                                onBlur={onSubmit}
                                                id={cell.id}
                                                disabled={cell.disabled}
                                                width={cell.width}
                                                type={cell.inputType}
                                                color={cell.color}
                                                isCurrency={cell.isCurreny}
                                                mask={cell.mask}
                                                onFocus={cell.onFocus}
                                                onChange={cell.onChange}
                                            />)
                                        case 'select':
                                            return (
                                                <TableCellSelect
                                                    key={cell.name}
                                                    name={cell.name}
                                                    onSubmit={onSubmit}
                                                    options={cell.options!}
                                                    value={cell.value?.toString()}
                                                    id={cell.id}
                                                    width={cell.width}
                                                />
                                            )
                                        case 'img':
                                            return (
                                                <TableCellImg 
                                                    key={cell.name}
                                                    name={cell.name}
                                                    src={cell.src!}
                                                    value={cell.value?.toString()}
                                                    onSubmit={onSubmit}
                                                    id={cell.id}
        
                                                />
                                            )
                                        case 'toggle':
                                            return (
                                                <TableCellToggle 
                                                
                                                key={cell.name}
                                                name={cell.name}
                                                onClick={onSubmit}
                                                value={!!cell.value}
                                                id={cell.id}
                                                width={cell.width}
                                            />
                                               )
                                        case 'textarea':
                                           return <TableCellTextarea 
                                                customStyle={customCellStyle}
                                                fontSize={tableFontSize}
                                                key={cell.name}
                                                name={cell.name}
                                                value={cell.value?.toString()}
                                                onBlur={onSubmit}
                                                id={cell.id}
                                                disabled={cell.disabled}
                                                width={cell.width}
                                                color={cell.color}

                                            />
                                }

                            })}
                        </BodyContainer>)
                    )
                    }
                {!loading && tableBody.length === 0 && <NoDataContainer><NoData colSpan={getColSpan()}>{t('Não há registros')}!</NoData></NoDataContainer>}
                {tableTotal && < TotalContainer className='page-break' customTableStyle={customTotalStyle}>
                    {tableTotal.map((total ) => {
                        return <TotalCell customStyle={customCellStyle} colSpan={total.colSpan} key={total.title}>{t(total.title)}</TotalCell>
                    })}
                    </TotalContainer>}
                </TableBody>  
            </Table>
            {isPaginate && 
                <LoaderPagination 
                    loading={loading} 
                    showLoader={showLoader} 
                    onClick={handleLoaderPagination} 
                />}
        </TableWrapper>

    )
}

interface CheckboxItemProps {
    currentId: number;
    ids: number[],
    setIds: Dispatch<SetStateAction<number[]>>
}

const CheckboxItem = ({currentId, ids, setIds}: CheckboxItemProps) => {
    const handleChange = useCallback(() => {
        if(ids.includes(currentId)) {
            setIds(prev => prev.filter((id) => currentId !== id));
            return;
        }
        setIds(prev => [...prev, currentId])
    }, [ids, setIds, currentId])

    return(
        <Checkbox
            type='checkbox'
            onChange={handleChange}
            checked={ids.includes(currentId)}
        />
    )
}