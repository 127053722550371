import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../../styles/theme';

export const FormGeral = styled.form`
	${props =>
		props.className !== 'kanban'
			? `
		border-bottom: 1px solid #c1d0d9;
		padding: 8px 0;
		width: 200px;
	`
			: `
		padding: 8px 8px 8px 24px;
		width: 100%;
	`}
	align-items: center;
	color: #496070;
	display: flex;
	gap: 16px;
`;
export const InputContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	height: 24px;
`;
export const Input = styled.input`
	font-size: 14px;
	width: 100%;
	background: transparent;
	border: none;
	outline: none;
	color: #355060;
	overflow: visible;
	margin: 0;
	&::placeholder {
		color: ${theme.colors.text.inputText};
	}
`;

export const SearchIconContainer = styled.div`
	display: flex;
	align-items: center;
	height: 18px;
	cursor: pointer;
	color: #496070;
	font-family: 'Helvetica', sans-serif !important;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
	font-size: 12px;
	color: #496070;
`;
