import styled from "styled-components";

interface FormSideProps {
    isSide: boolean;
    isActived: boolean;
    width?: number;
}

export const FormSide = styled.div<FormSideProps>`
    transition: all 0.4s;
    border-radius: ${({isSide}) => isSide ? "14px 0 0 14px" : "14px"};
    width:  ${({width}) => width ? `${width}px` : "300px"};
    z-index: 2;
    background-color: #ffffff;
    padding: ${({isSide}) => isSide ? "0 30px 0 15px" : "0 15px"};
    border: 1px solid rgb(177, 173, 173);
    max-height: 100vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetopGenerico;
    -webkit-animation-duration: 0.3s;
    animation-name: animatetopGenerico;
    animation-duration: 0.3s;

    ${({isActived}) => {
        if(isActived) {
            return `
                height: 100%;
                overflow: initial;
                margin-right: -22px;
            `
        } else {
            return `
                height: 0;
                overflow: hidden;
                margin-right: -320px;
            `
        }
        
    }}
        
    .form-footer-generico-side {
        border-top: solid 1px #dbe3e6;
        height: 32px;
        display: flex;
        align-items: flex-end;
    }

    @keyframes animatetopGenerico {
        from {
            margin-top: -200px;
            opacity: 1;
        }
        to {
            margin-top: 0;
            opacity: 1;
        }
    }
`;

interface BodySideProps {
    hour: boolean;
    side: boolean;
}

export const BodySide = styled.div<BodySideProps>`
    max-width: 85vw;
    max-height: 70vh;
    overflow-y: ${({side}) => side ? "auto" : "visible"};
    background-color: #fff;
    overflow-x: ${({side}) => side ? "hidden" : "initial"};

    @media (max-height: 660px) {
        max-height: 50vh;
    }
`;

export const FormHeader = styled.div`
    background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	border-bottom: solid 1px #dbe3e6;

    .modal-header-generico-side-text {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 600;
        color: #426172;
    }
    
    .close-generico-side-form {
        color: #426172;
        font-size: 28px;
        font-weight: bold;
        user-select: none;

        &:hover,
        &:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }
    }
`;
