import React, { useState, useEffect } from 'react';

import TarefasChart from '../graficosComponents/tarefasChart';
import ProjetosChart from '../graficosComponents/projetosChart';
import BarChart from '../graficosComponents/barChart';
import TurTutorial from '../../../components/tourTutorial';

import { useCalendarioRegistros } from '../../../contexts/CalendarioRegistros';

import moment from 'moment';
import ReactGA from 'react-ga';

import '../style.css';
import '../../../i18n';
import { useUser } from '../../../contexts/User';
import HeaderLink from '../../../components/common/HeaderLink';
import { WeekNavigation } from '../components/WeekNavigation';

interface DiasDaSemanaProps {
  diaSemana: string;
  data: string;
}

export function GraficosHomePage() {
	const {savedDataInicial, refreshCalendarioRegistros, calendarioRegistros} = useCalendarioRegistros();
	const [diasDaSemanaArray, setDiasDaSemanaArray] = useState<DiasDaSemanaProps[]>([]);
	const [dataInicialFilter, setDataInicialFilter] = useState('');
	const [dataFinalFilter, setDataFinalFilter] = useState('');

	const {userDetails} = useUser();
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		if(userDetails?.id_pessoa.toString() !== '') {
			manipulaPeriodoSelecionado('semana');
		}
	}, [userDetails]);

	const manipulaPeriodoSelecionado = (opcao: string, diaAux = savedDataInicial) => {
		const periodoDeDias = [];
		let diaAlvo = moment(diaAux).format('ddd');
		let dataInicial = moment(diaAux).format('YYYY-MM-DD');
		let dataFinal = moment(diaAux).format('YYYY-MM-DD');

		switch (opcao) {
			case 'semana':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}

				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});

					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			case 'next':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}
				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});
					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			case 'prev':
				while (diaAlvo !== 'dom') {
					diaAlvo = moment(dataInicial)
						.subtract(1, 'days')
						.format('ddd');
					dataInicial = moment(dataInicial)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
				}
				for (let i = 0; i <= 6; i++) {
					periodoDeDias.push({
						diaSemana: moment(dataInicial)
							.add(i, 'days')
							.format('ddd'),
						data: moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD'),
					});
					if (i === 6) {
						dataFinal = moment(dataInicial)
							.add(i, 'days')
							.format('YYYY-MM-DD');
					}
				}
				break;
			default:
				break;
		}

		if(dataInicial !== savedDataInicial){
			refreshCalendarioRegistros({
				dataInicial: dataInicial,
				dataFinal: dataFinal,
				idPessoa: userDetails?.id_pessoa
			});
		}
		setDataInicialFilter(dataInicial);
		setDataFinalFilter(dataFinal);
		setDiasDaSemanaArray(periodoDeDias);
	};

	return (
		<div id="pagina-principal" className='flex flex-col gap-5'>
			{userDetails && !userDetails?.has_finished_tour && <TurTutorial nomeRota="home" />}
			
      <HeaderLink type={'home'} />

      <WeekNavigation 
        dataInicial={dataInicialFilter}
        dataFinal={dataFinalFilter}
        weekNavigation={manipulaPeriodoSelecionado}
        diasDaSemana={diasDaSemanaArray}
      />

			<div className='grid grid-cols-3 gap-7'>
        <div >
          <BarChart registros={calendarioRegistros} diasDaSemana={diasDaSemanaArray} />
        </div>
        <div >
          <ProjetosChart
            registros={calendarioRegistros}
            diasDaSemana={diasDaSemanaArray}
          />
        </div>
        <div>
          <TarefasChart
            registros={calendarioRegistros}
            diasDaSemana={diasDaSemanaArray}
          />
        </div>
			</div>
		</div>
	);
}
