import React from 'react';
import './style.css';
import BannerLogin from '../components/BannerLogin/index.js';

import '../../../i18n';

export const PoliticasDeUsoENUS = () => {
	return (
			<div className="politica-limiter">
				<div>
					<div style={{ width: '25vw', margin: 'auto' }}>
						<BannerLogin
							titulo="USE AND PRIVACY POLICY"
							distanceBetweenLogo={'20'}
							logoWidth={'17vw'}
						/>
					</div>
					<h6 className="politica-section">SECTION 1 - GENERAL INFORMATION</h6>
					<p className="politica-text">
                        This Privacy Policy contains information about the collection,
                        use, storage, processing and protection of the personal data of
                        users and visitors of the Planus™ app or/and app.planus.io website
                        with the aim of demonstrating absolute transparency regarding the
                        subject and clarify to all interested parties about the types of data
                        that are collected, the reasons for the collection, and how users
                        can manage or delete your personal information.
					</p>
					<p className="politica-text">
                        This Privacy Policy applies to all users and
                        visitors to the Planus™ app or app.planus.io website and integrates the
                        General Terms and Conditions of Use for the Planus™ application or website
                        app.planus.io, duly registered with the CNPJ under no.
                        13.726.953/0001-83, located at RUA SERVIDOR ALFREDO DE OLIVEIRA
                        BRAGA, 240, BETIM, MINAS GERAIS, BRAZIL, hereinafter referred to as IVORY IT
                        EIRELI COMPUTER SERVICES.
					</p>
					<p className="politica-text">
                        This document was prepared in accordance with the Lei Geral
						de Proteção de Dados Pessoais (Law 13.709/18), the Marco Civil da
						Internet (Law 12.965/14) (and EU Regulation n. 2016/6790).
                        Furthermore, the document may be updated as a result of any
                        normative update, which is why the user is invited to
                        periodically consult this section.
					</p>
					<h6 className="politica-section">
                        SECTION 2 - HOW WE COLLECT YOUR PERSONAL DATA AND VISITOR?
					</h6>
					<p>
                        User and visitor personal data are collected by
                        platform as follows:
					</p>
					<ul>
						<li>
                            When the user creates an account/profile on the Planus™ platform:
                            this data is the basic identification data such as email,
                            full name, profession, cost-hour value. From them,
                            we can identify the user and the visitor, in addition to guaranteeing a
                            greater safety and well-being to your needs. Are aware of
                            users and visitors that your profile on the platform will be
                            accessible to all other users and visitors to the platform
                            Planus™.
						</li>
						<li>
                            When a user and visitor accesses the pages of the website
                            app.planus.io OR the Planus™ app: information about
                            interaction and access are collected by the company to guarantee a
                            better user and visitor experience. These data can
                            deal with the keywords used in a search, the
                            sharing a specific document, commenting,
                            pageviews, profiles, the URL from which the user and
                            visitor come from, the browser they use and their access IPs,
                            among others that can be stored and retained.
						</li>
					</ul>
					<h6 className="politica-section">
                        SECTION 3 - WHAT PERSONAL DATA DO WE COLLECT ABOUT YOU AND VISITOR?
					</h6>
					<p className="politica-text">
                        The personal data of the user and visitor collected are the following:
					</p>
					<ul>
						<li>
                            Data for creating the account/profile on the platform, such as: e-mail,
                            full name, profession, cost-hour value.
						</li>
						<li>
                            Data for browsing optimization: cache, keywords
                            used in the search, comments, interaction with other profiles and
                            users, timestamps, task, and projects.
						</li>
						<li>
                            The e-mail registered by the visitor and user will be stored until
                            that the user requests the unsubscribe.
						</li>
						<li>
                            Data related to contracts: before the formalization of the contract
                            purchase and sale or provision of services between the platform and
                            the user and visitor may be collected and stored data
                            relating to contractual performance, including communications
                            performed between the company and the user.
						</li>
					</ul>
					<h6 className="politica-section">
                        SECTION 4 - FOR WHAT PURPOSES DO WE USE YOUR PERSONAL DATA USER AND VISITOR?
					</h6>
					<p>
                        User and visitor personal data collected and stored
                        by the Planus™ platform aims to:
					</p>
					<ul>
						<li>
                            User and visitor well-being: improve the product and/or service
                            offered, facilitate, expedite and fulfill the commitments
                            established between the user and the company, improve the experience
                            of users and provide specific functionalities depending on the
                            basic user features.
						</li>
						<li>
                            Platform improvements: understanding how the user uses the
                            platform services, to help with business development
                            and techniques.
						</li>
						<li>
                            Commercial: data is used to personalize content
                            offered and generate subsidy to the platform to improve the
                            quality in the functioning of the services.
						</li>
						<li>
                            Registration data: to allow user access to
                            certain contents of the platform, exclusive to users
                            registered.
						</li>
						<li>
                            Contract data: provide the parties with legal certainty and
                            facilitate the completion of the deal.  
						</li>
					</ul>
					<p>
                        The processing of personal data for purposes not provided for in this
                        Privacy Policy will only occur upon prior notice
                        to the user, so that the rights and obligations set forth herein
                        remain applicable.
					</p>
					<h6 className="politica-section">
                        SECTION 5 - HOW LONG IS PERSONAL DATA STORED?
					</h6>
					<p>
                        User and visitor personal data are stored by
                        platform for the period necessary to provide the service
                        or the fulfillment of the purposes set forth in this document,
                        pursuant to item I of article 15 of Law 13,709/18.
					</p>
					<p>
                        Data can be removed or anonymized at the request of the user,
                        except in cases where the law provides for another treatment.
					</p>
					<p>
                        Furthermore, the personal data of users can only be kept
                        after the end of your treatment in the following hypotheses provided
                        in Article 16 of said law:
					</p>
					<ol style={{ listStyleType: 'upper-roman' }}>
						<li>
                            Compliance with a legal or regulatory obligation by the controller;
						</li>
						<li>
                            Study by research body, ensuring, whenever possible, the
                            anonymization of personal data;
						</li>
						<li>
                            Transfer to a third party, provided that the requirements of
                            processing of data provided for in this Law;
						</li>
						<li>
                            Exclusive use of the controller, access by a third party is prohibited, and
                            as long as the data is anonymised.
						</li>
					</ol>
					<h6 className="politica-section">
                        SECTION 6 - SECURITY OF STORED PERSONAL DATA
					</h6>
					<p>
                        The platform undertakes to apply the technical and
                        organizational measures able to protect personal data from unauthorized access
                        authorized authorities and situations of destruction, loss, alteration,
                        communication or dissemination of such data.
					</p>
					<p>
                        The platform does not exempt itself from responsibility for the sole fault of
                        third party, such as in the case of a hacker or cracker attack, or fault
                        exclusive to the user, as in the case where he himself transfers his
                        data to third parties. The website undertakes to communicate the user in
                        in case of any breach of security of your personal data.
					</p>
					<p>
                        The personal data stored are treated confidentially,
                        within legal limits. However, we may disclose your
                        personal information if we are required by law to do so.
					</p>
					<h6 className="politica-section">
                        SECTION 7 - DATA SHARING
					</h6>
					<p>
                        The sharing of user data only takes place with the data
                        referring to publications made by the user himself, such actions
                        are publicly shared with other users.
					</p>
					<p>
                        User profile data is shared publicly for
                        participants from your own organization in search engines within
                        from the platform.
					</p>
					<h6 className="politica-section">
                        SECTION 8 - THE STORED PERSONAL DATA WILL BE TRANSFERRED TO THE 3RD?
					</h6>
					<p>
                        The personal information of visitors and users is protected.
                        We do not share or otherwise transfer your
                        personal information to third parties other than ours, others
                        users and visitors, competent authorities, among others.
					</p>
					<p>
                        By accessing our services and providing your information, you are
                        consenting to the processing, transfer and storage of this
                        information in other countries.
					</p>
					<p>
                        By being redirected to a third-party app or website, you
                        will no longer be governed by this Privacy Policy or the
                        Terms of Service for our platform. We are not responsible for
                        privacy practices of other sites and we encourage you to read the
                        their privacy statements.
					</p>
					<h6 className="politica-section">
                        SECTION 9 - COOKIES OR BROWSING DATA
					</h6>
					<p>
                        Cookies refer to text files sent by the platform
                        to the user's and visitor's computer and which are stored therein,
                        with information related to navigation on the site. Such information
                        are related to access data such as location and time of access
                        and are stored by the user's and visitor's browser so that the
                        platform server can read them later in order to
                        customize platform services.
					</p>
					<p>
                        The user and visitor of the Planus™ platform declares to know and
                        accept that a data collection system may be used
                        browsing through the use of cookies.
					</p>
					<p>
                        The persistent cookie remains on the user's hard drive and
                        visitor after the browser is closed and will be used by
                        browser on subsequent visits to the website. persistent cookies
                        can be removed by following your browser's instructions. already the
                        session cookie is temporary and disappears after the browser is
                        closed. It is possible to reset your web browser to refuse
                        all cookies, however some features of the platform may not
                        work correctly if the ability to accept cookies is
                        disabled.
					</p>
					<h6 className="politica-section">SECTION 10 - CONSENT</h6>
					<p>
                        By using the services and providing personal information on the
                        platform, the user is consenting to this Privacy Policy
                        Privacy.
					</p>
					<p>
                        The user, when registering, declares to know and can exercise his
                        rights to cancel your registration, access and update your data
                        personal data and guarantees the veracity of the information provided by him
                        made available.
					</p>
					<h6 className="politica-section">
                        SECTION 11 - CHANGES TO THIS PRIVACY POLICY
					</h6>
					<p>
                        We reserve the right to modify this Privacy Policy at
                        any time, so it is recommended that the user and visitor
                        review it frequently.
					</p>
					<p>
                        Changes and clarifications will take effect immediately after
                        its publication on the platform. When changes are made, the
                        users will be notified. By using the service or providing
                        personal information after eventual modifications, the user and
                        visitor demonstrates his agreement with the new rules.
					</p>
					<p>
                        In view of the merger or sale of the platform to another company, the data of
                        users can be transferred to the new owners so that
                        the permanence of the services offered.
					</p>
					<h6 className="politica-section">
                        SECTION 12 - JURISDICTION FOR CONFLICT RESOLUTION
					</h6>
					<p>
                        For the settlement of disputes arising from this instrument
                        Brazilian law will be fully applied. The eventual
                        Disputes must be submitted to the jurisdiction of the district in which they are held.
                        find the company's headquarters.
					</p>
				</div>
			</div>
	);
};
