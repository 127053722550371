import React, { useCallback } from 'react';
import { OptionProps } from 'react-select';
import { SelectOptions } from '../../../../models/Select';
import { OptionWrapper } from './styled';
import { Checkbox, Radio } from '@mui/material';

export const Option = (props: OptionProps<SelectOptions>) => {
	const { children, getValue, data, setValue, isSelected, isMulti } = props;
	const value = getValue();
	const handleChange = useCallback(() => {
		if(!data.isDisabled) {
			if (isSelected && isMulti) {
				setValue(
					value.filter(item => item.value !== data.value),
					'deselect-option',
				);
			} else if (!isSelected) {
				if(isMulti) {
					if(data.value === "") {
						setValue([data], 'deselect-option')
					} else {
						setValue([...value.filter(item => item.value !== ""), data], 'deselect-option')
					}
				} else {
					setValue(data, 'select-option');
				}
			}
		}
	}, [isSelected, isMulti, value, data]);

	return (
		<OptionWrapper onClick={handleChange} isDisabled={data.isDisabled}>
			{isMulti ? (
				<Checkbox checked={isSelected} />
			) : (
				<Radio checked={isSelected} />
			)}
			<div>{children}</div>
		</OptionWrapper>
	);
};
