import React from 'react';
import {Helmet} from "react-helmet";
import { useStopwatch } from '../../../contexts/Stopwatch';


export function StopwatchTitle() {    
    const {title} = useStopwatch();
    return (
        <Helmet>
            {title.length > 0 ? <title>Planus - Em execução ({title})</title> : <title>Planus</title>}
        </Helmet>
    )
}