import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const Label = styled.label`
    text-decoration: blink;
    margin: 0;
`

export const LabelAux = styled.label`
    text-decoration: blink;
    width: 100%;
    opacity: 0.6;
    font-weight: 500;
    font-size: 12px;
    color: #5f5f5f;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0
`