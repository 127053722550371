import React from 'react';
import ErrorMsg from '../ErrorMsg';
import { Field as FormikField } from 'formik';
import { FieldFormikProps } from './types';

const FieldFormik = ({
	title,
	nameField,
	component,
	renderComponent,
	errors,
	touched,
	style,
	className,
	disabled,
	...rest
}: FieldFormikProps) => {
	const setFocus = (id: string) => {
		const element = document.getElementById(id);
		if(element){
			element.classList.add('field-formik-focus');
		}
	};

	const tempClassName = className ? className : 'form-default-style-input-projetos';
	const tempStyle = style ? style : {};

	return (
		<>
			{title && (
				<label className="modal-label" htmlFor={nameField}>
					{title}
				</label>
			)}
			<FormikField

				{...rest}
				id={`input-${nameField}-select`}
				name={nameField}
				onFocus={() => setFocus(`input-${nameField}-select`)}
				className={errors && touched && !className ? 'form-error-style-input-projetos' : tempClassName}
				style={component === 'textarea' && !style ? { minHeight: 'calc(100% - 37px)' } : tempStyle}
				component={renderComponent}
				disabled={disabled}
				
			/>
			<ErrorMsg name={nameField} />
		</>
	);
};

export default FieldFormik;
