import React, { InputHTMLAttributes, LegacyRef } from 'react';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { CalendarIcon, DatePickerContainer, InputDate, Label, CustomInputStyled } from './styled';

interface DefaultDatePickerProps {
	value?: Date;
	onChange: (date: Date) => void;
	label?: string;
	name?: string;
	placeholder?: string;
	selectsStart?: boolean;
	startDate?: Date;
	endDate?: Date;
	minDate?: Date;
	selectsEnd?: boolean;
	border?: string;
	width?: string;
}

export default function DefaultDatePicker({
	label,
	name,
	value,
	onChange,
	placeholder,
	border,
	width,
	...rest
}: DefaultDatePickerProps) {
	return (
		<DatePickerContainer border={border}>
			{label && <Label>{label}</Label>}
			<InputDate
				width={width}	
				name={name}
				selected={value}
				onChange={onChange}
				dateFormat="dd/MM/yyyy"
				placeholderText={placeholder}
				{...rest}
				customInput={<CustomInput />}
			/>
		</DatePickerContainer>
	);
}

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
	onClick?: () => void
	ref?: LegacyRef<HTMLInputElement>
}

const CustomInput = React.forwardRef((props: CustomInputProps, ref: LegacyRef<HTMLInputElement>) => {
	console.log(props.onClick)
	return (
		<CustomInputStyled>
			<input value={props.value} {...props} placeholder={props.placeholder} onChange={props.onChange} onClick={props.onClick} ref={ref} />
			<CalendarIcon icon={faCalendar} onClick={props.onClick} />
		</CustomInputStyled>
	);
});
  
CustomInput.displayName = "CustomInput"