import React, { InputHTMLAttributes, useState } from 'react';

interface TextCellProps extends InputHTMLAttributes<HTMLInputElement> {
	children?: string | number;
	onSave: (value: string) => void;
}

export default function TextCell({
	children,
	onSave,
	...rest
}: Readonly<TextCellProps>) {
	const [isEditing, setIsEditing] = useState(false);
	const [value, setValue] = useState(children);
	
	return (
		<td>
			<div className="text-ivory-primary h-full">
				{isEditing ? <input
					{...rest}
					value={value}
					className="w-full h-full text-xs p-2 border-none focus:ring-0 pl-0 focus:border focus:border-ivory-primary focus:border-solid outline-none focus:outline-none"
					onChange={(e) => setValue(e.target.value)}
					onBlur={(e) => {
                        onSave(e.target.value);
                        setIsEditing(false)
                    }}
					onKeyUp={(e) => {
						if(e.key === 'Enter') {
							onSave(e.currentTarget.value);
							setIsEditing(false)
						}
					}}
                    autoFocus
				/> : <button className='min-w-full min-h-[30px] text-start' onClick={() => setIsEditing(true)}>{value}</button>}
			</div>
		</td>
	);
}
