import styled from 'styled-components';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InfoTarefaComment = styled(Field)`
	resize: none;
	border-radius: 7px;
	display: block;
	width: 100%;
	height: 10%;
	min-height: 60px;
	font-size: 14px;
	padding: 7px;
	outline: none;
	box-shadow: none;
	border: 1px solid rgba(192, 207, 216, 0.8509803921568627);
	transition: 0.3s ease;
	color: #355060;
`;

export const ButtonContainer = styled.div`
	display: flex;
	padding: 10px 0 0;
`;

export const ButtonSave = styled.button`
	height: 35px;
	width: 90px;
	background: #5ad8e3;
	font-size: 16px;
	border: 1px solid #29dff0;
	border-radius: 10px;
	color: white;
	font-weight: 600;
	letter-spacing: 0px;
	outline: none;
`;

export const ButtonAddHour = styled.div`
	border: solid 1px #29dff0;
	background-color: #5ad8e3;
	width: 45px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	cursor: pointer;
`;

export const IconHour = styled(FontAwesomeIcon)`
	color: #fff;
	font-size: 20px;
`;
