import React from "react";

const ExpandIcon = () => {
  return (
    <svg version="1.1" id="Capa_1" x="20px" y="20px" viewBox="0 0 384 384">
      <g>
        <g>
          <g>
            <polygon points="234.667,0 234.667,42.667 311.147,42.667 101.44,252.373 131.627,282.56 341.333,72.853 341.333,149.333      384,149.333 384,0    " />
            <path d="M341.333,341.333H42.667V42.667H192V0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384     h298.667C364.907,384,384,364.907,384,341.333V192h-42.667V341.333z" />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default ExpandIcon;
