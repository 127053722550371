import styled from 'styled-components';
import { theme } from '../../../styles/theme';

export const NavlinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	@media (min-width: 425px) {
		align-items: center;
		flex-direction: row;
		height: 50px;
		text-decoration: none;
		box-shadow: 0 3px 20px rgb(0 0 0 / 16%);
		justify-content: space-between;
	}
`;

export const NavlinkLeft = styled.div`
	display: flex;
	flex-direction: row;
	box-shadow: 0 3px 20px rgb(0 0 0 / 16%);

	a {
		gap: 8px;
		padding: 0 4px;

		svg, img {
			font-size: 16px;
			width: 16px;
		}
	}

	@media (min-width: 425px) {
		box-shadow: none;
	}
`;

export const NavlinkRight = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	@media (min-width: 425px) {
		margin-right: 32px;
	}
`;

export const NavbarText = styled.p`
	margin-left: 5px;
	margin: 0;
    padding: 0;
    font-weight: 500;
    color: #406173;
    font-size: 1rem;
`;

export const NavlinkButton = styled.button`
	border: none;
	background-color: ${theme.colors.button.darkBtn};
	border-radius: 10px;
	color: white;
	padding: 5px 15px;
	font-weight: 600;
	font-size: 0.875rem;
	width: 120px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
	&:disabled {
		opacity: 0.8;
		cursor: auto;
	}
`;
