import React from 'react';
import { GroupBase, MultiValueGenericProps } from 'react-select';
import { SelectOptions } from '../../../../../models/Select';
import { TextContainer, ValueContainer } from './styled';
export const MultiValueContainer = (
	props: MultiValueGenericProps<
		SelectOptions,
		boolean,
		GroupBase<SelectOptions>
	> & {selectProps: any},
) => {
	const {
		selectProps: { value, additional, inputValue},
		data,

		
	} = props;
	const selectedValues = value as SelectOptions[];
	const plusValues =
		selectedValues.length > 1 ? `(+${selectedValues.length - 1})` : '';
	let filterLabel = additional?.filterName ?? '';
	if((data.label === "Eu" || data.label === additional.filterName) && selectedValues.length > 1) {
		filterLabel = selectedValues[1].label;
	}else if(data.label !== "Eu" && data.label !== additional.filterName) {
		filterLabel = data.label;
	}

	return selectedValues.length > 0 && selectedValues[0].value === data.value ? (
		<ValueContainer>	
			{inputValue.length === 0
				? <>
				<TextContainer>{filterLabel}</TextContainer>
				{plusValues}</>
				: null}
		</ValueContainer>
	) : null;
};
