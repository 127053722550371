import { t } from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select, { GroupBase, MultiValue, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import AsyncSelector from '../../../../components/common/AsyncSelector';
import FilterContainer from '../../../../components/containers/Filter';
import { Option } from '../../../../components/containers/Filter/Option';
import { SelectFilterContainer } from '../../../../components/containers/Filter/styled';
import {
	initialValues,
	useHourFilters,
} from '../../../../contexts/Filter/Hour';
import {
	LoadOptionsAdditional,
	SelectOptions,
} from '../../../../models/Select';
import PessoaService from '../../../../services/providers/responsible';
import TarefaService from '../../../../services/providers/task';
import { selectFilterStyle } from '../../../../utils/extenal-libs';
import DatePicker from '../../../../components/common/DatePicker';
import { pickTimeInterval } from '../../../../utils/funcoes/pickTimeInterval';
import moment from 'moment';
import { useUser } from '../../../../contexts/User';

interface FilterHoursProps {
	handlePagination: (
		page?: number,
		pessoa?: number,
		signal?: AbortSignal,
	) => void;
}

export default function FilterHours({ handlePagination }: FilterHoursProps) {
	const pessoaService = new PessoaService();
	const taskService = new TarefaService();
	const hoursFilter = useHourFilters();
	const {
		selectedPerson,
		setSelectedPerson,
		selectedTask,
		setSelectedTask,
		selectedTime,
		setSelectedTime,
		startDate,
		setStartDate,
		endDate,
		setEndDate,
		loadingFilters,
		resetFilters,
		search,
		setSearch,
	} = hoursFilter;
	const filterOptions: SelectOptions[] = [
		{ value: 'person', label: 'Usuário' },
		{ value: 'task', label: 'Tarefa' },
		{ value: 'time', label: 'Intervalo de Tempo' },
	];

	const timeOptions: SelectOptions[] = [
		{
			value: '',
			label: t('Todo Período'),
		},
		{
			value: 'today',
			label: t('Hoje'),
		},
		{
			value: 'yesterday',
			label: t('Ontem'),
		},
		{
			value: 'thisWeek',
			label: t('Esta Semana'),
		},
		{
			value: 'lastWeek',
			label: t('Semana Passada'),
		},
		{
			value: 'thisMonth',
			label: t('Este mês'),
		},
		{
			value: 'lastMonth',
			label: t('Mês Passado'),
		},
		{
			value: 'thisYear',
			label: t('Este ano'),
		},
		{
			value: 'lastYear',
			label: t('Ano Passado'),
		},
		{
			value: 'custom',
			label: t('Personalizado'),
		},
	];

	const [openFilters, setOpenFilters] = useState(filterOptions);

	const elementsRef = {
		person: useRef<HTMLDivElement>(null),
		task: useRef<HTMLDivElement>(null),
		time: useRef<HTMLDivElement>(null),
	};

	const checkVisible = (value: keyof typeof elementsRef) => {
		const hasValue = (item: SelectOptions) => item.value === value;
		const isVisible = openFilters.some(hasValue);
		return isVisible;
	};

	const handleChangePerson = useCallback(
		(e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
			setSelectedPerson(e as SelectOptions);
			setSelectedTask(initialValues.selectedTask);
		},
		[setSelectedPerson, setSelectedTask, initialValues],
	);

	const handleChangeTask = useCallback(
		(e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
			setSelectedTask(e as SelectOptions);
		},
		[setSelectedTask],
	);

	const handleChangeTime = useCallback(
		(e: SingleValue<SelectOptions> | MultiValue<SelectOptions>) => {
			const selected = e as SelectOptions;
			const value = selected.value.toString();

			if (selected.value !== 'custom') {
				setStartDate(
					new Date(
						moment(pickTimeInterval(selected.value === '' ? 'today' : value).startDate).toISOString(),
					),
				);
				setEndDate(
					new Date(
						moment(pickTimeInterval(selected.value === '' ? 'today' : value).endDate).toISOString(),
					),
				);
			} else {
				setStartDate(undefined);
				setEndDate(undefined);
			}
			setSelectedTime(selected);
		},
		[pickTimeInterval, setStartDate, setEndDate, setSelectedTime],
	);

	const handleStartDate = useCallback((date: Date) => setStartDate(date), [
		setStartDate,
	]);
	const handleEndDate = useCallback((date: Date) => setEndDate(date), [
		setEndDate,
	]);
	const { userDetails } = useUser();

	useEffect(() => {
		if (userDetails) {
			if (selectedTime.value !== 'custom' || (startDate && endDate)) {
				handlePagination(undefined, undefined);
			}
		}
	}, [selectedPerson, selectedTask, selectedTime, startDate, endDate, userDetails]);

	const getUserDetails = () => {
		if (userDetails !== null) {
			if (userDetails.acesso_cod !== 'e') {
				setSelectedPerson({
					value: userDetails.id_pessoa,
					label: userDetails.username,
				});
				handlePagination(1, userDetails.id_pessoa);
				return;
			}
		}
		handlePagination();
	};

	useEffect(() => {
		getUserDetails();
	}, [userDetails]);

	return (
		<FilterContainer
			filterOptions={filterOptions}
			handleSearch={handlePagination}
			loadingFilters={loadingFilters}
			openFilters={openFilters}
			resetFilters={resetFilters}
			searchFilter={search}
			setSearchFilter={setSearch}
			selectedFilters={hoursFilter}
			setOpenFilters={setOpenFilters}
			search={true}
		>
			{checkVisible('person') && (
				<SelectFilterContainer ref={elementsRef.person}>
					<AsyncSelector
						loadOptions={
							pessoaService.loadPessoasSelect as LoadOptions<
								any,
								GroupBase<any>,
								LoadOptionsAdditional
							>
						}
						additional={{
							page: 1,
							placeholder: t('Todos'),
							userDetails: userDetails
						}}
						onChange={handleChangePerson}
						placeholder={t('Usuário')}
						style={selectFilterStyle}
						value={selectedPerson}
						components={{ Option }}
						isClearable={false}
						closeMenuOnSelect={true}
						hideSelectedOptions={false}
						blurInputOnSelect={true}
					/>
				</SelectFilterContainer>
			)}
			
			{checkVisible('time') && (
				<div
					ref={elementsRef.time}
					style={{ display: 'flex', flexDirection: 'row' }}
				>
					<SelectFilterContainer>
						<Select
							placeholder={t('Intervalo de Tempo')}
							options={timeOptions}
							onChange={handleChangeTime}
							value={selectedTime}
							isClearable={false}
							isMulti={false}
							closeMenuOnSelect={true}
							styles={selectFilterStyle}
							hideSelectedOptions={false}
							components={{ Option }}
							menuPosition="fixed"
							menuPlacement="auto"
							blurInputOnSelect={false}
						/>
					</SelectFilterContainer>
					{selectedTime.value === 'custom' && (
						<>
							<SelectFilterContainer>
								<DatePicker
									width={'5rem'}
									placeholder={t('Data Inicial')}
									onChange={handleStartDate}
									value={startDate}
									selectsStart
									startDate={startDate}
									endDate={endDate}
								/>
							</SelectFilterContainer>
							<SelectFilterContainer>
								<DatePicker
									width={'5rem'}
									placeholder={t('Data Final')}
									onChange={handleEndDate}
									value={endDate}
									selectsEnd
									startDate={startDate}
									endDate={endDate}
									minDate={startDate}
								/>
							</SelectFilterContainer>
						</>
					)}
				
				</div>
				
			)}
				{checkVisible('task') && (
				<SelectFilterContainer ref={elementsRef.task}>
					<AsyncSelector
						loadOptions={
							taskService.loadTarefasSelect as LoadOptions<
								any,
								GroupBase<any>,
								LoadOptionsAdditional
							>
						}
						additional={{
							page: 1,
							placeholder: t('Todos'),
							pessoa: selectedPerson.value.toString(),
						}}
						onChange={handleChangeTask}
						placeholder={t('Tarefa')}
						style={selectFilterStyle}
						value={selectedTask}
						components={{ Option }}
						isClearable={false}
						closeMenuOnSelect={true}
						hideSelectedOptions={false}
						blurInputOnSelect={true}
					/>
				</SelectFilterContainer>
			)}
		</FilterContainer>
	);
}
