import { ColumnManaged, ColumnsForManager } from "../../atoms/Kanban/types";

export const handleInitialOrderColumns = (object: ColumnsForManager<any>) => {
    return Object.entries(object)
        .map(([id, column]) => ({ id, order: column.order }))
        .sort((a, b) =>
            {
            const order =  b.order === null ? 1 : a.order - b.order;
            return a.order === null ? -1 : order;
            }
        )
        .map(item => item.id);
};

export const filterAddedItems = (results: any[], column: ColumnManaged<any>) => {
    if (column.addedItemsIds.length > 0) {
      return results.filter((item) => column.addedItemsIds.every((el) => el != item.id));
    } else {
      return results;
    }
  }