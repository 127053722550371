import React, { useState, Dispatch, useRef, useCallback } from 'react';
import { selectFilterStyle } from '../../../../utils/extenal-libs';
import AsyncSelector from '../../../../components/common/AsyncSelector';
import Select, { GroupBase, MultiValue, SingleValue } from 'react-select';
import{ ProjectMultiValueContainer} from './ProjectMultiValueContainer';
import { MultiValueContainer } from './MultiValueContainer';
import { Option } from '../../../../components/containers/Filter/Option';

import { t } from 'i18next';
import '../../../../i18n';

import './style.css';

import {  initialValues, useFilters } from '../../../../contexts/Filter/Task/Filter';
import { useDataTask } from '../../../../contexts/InfoTarefa/DataTask';
import DatePicker from '../../../../components/common/DatePicker/index';
import { DivBar, SelectFilterContainer } from '../../../../components/containers/Filter/styled';
import { CheckboxContainer, CheckboxLabel, SelectKanbanProjectContainer} from './styled'
import { useKanban } from '../../../../contexts/Kanban/Kanban';
import { FilterOkrKanbanProps } from './types';
import { LoadOptionsAdditional, SelectOptions } from '../../../../models/Select';
import { LoadOptions } from 'react-select-async-paginate';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import FilterContainer from '../../../../components/containers/Filter';
import { DropIndicatorKanban, KanbanProjectOption, SingleContainerKanban } from './KanbanProjectFilter';
import { useUser } from '../../../../contexts/User';
import PessoaService from '../../../../services/providers/responsible';
import formatArrayToFilter from '../../../../utils/funcoes/formatArrayToFilter';
import formatObjectToFilter from '../../../../utils/funcoes/formatObjectToFilter';
import KeyResultService from '../../../../services/providers/okrs/keyresult';
import { filtrosTimesOkrsAtom } from '../../../../atoms/Okrs';
import { useAtom } from 'jotai';
import CicloService from '../../../../services/providers/okrs/ciclo';

const FilterOkrKanban = ({ kanban}: FilterOkrKanbanProps) => {
	const {
		selectedProject,
		checkClosedTask,
		setCheckClosedTask,
		searchFilter,
		setSearchFilter,
		selectedStatus,
		setSelectedStatus,
		startDate,
		setStartDate,
		endDate,
		setEndDate,
		selectedAuthor,
		setSelectedAuthor,
		selectedPerson,
		setSelectedPerson,
		selectedKeyResult,
		setSelectedKeyResult
	} = useFilters();
	const { getTasksPagination,
	} = useDataTask();
	
	const { userDetails } = useUser();

	const filterContext = useFilters();

	const { handleGetInitialValues, loadingFilters } = useKanban();
	const [timesFilters, setTimesFilters] = useAtom(filtrosTimesOkrsAtom)

	const filterOptions: SelectOptions[] = kanban ? [
			{ label: t('Ciclos'), value: 'ciclo' },
			{ label: t('Responsáveis'), value: 'person' },
			{ label: t('Autor'), value: 'author' },
			// { label: t('Tag'), value: 'tag' },
			{ label: t('Datas'), value: 'status' },
			{ label: t('Encerradas'), value: 'encerradas' },
	] : [
			{ label: t('Minhas tarefas'), value: 'myTasks' },
			{ label: t('Criadas por mim'), value: 'createdByMe' },
			// { label: t('Tag'), value: 'tag' },
			{ label: t('Datas'), value: 'status' },
			{ label: t('Encerradas'), value: 'encerradas' },

	];

	const [openFilters, setOpenFilters] = useState(filterOptions)
	
	const elementsRef = {
		ciclo: useRef<HTMLDivElement>(null),
		project: useRef<HTMLDivElement>(null),
		person: useRef<HTMLDivElement>(null),
		author: useRef<HTMLDivElement>(null),
		status: useRef<HTMLDivElement>(null),
		// tag: useRef<HTMLDivElement>(null),
		encerradas: useRef<HTMLDivElement>(null),
		myTasks: useRef<HTMLDivElement>(null),
		createdByMe: useRef<HTMLDivElement>(null)

	}	

	//services
	const keyresultService = new KeyResultService();
	const pessoaService = new PessoaService();
    const cicloService = new CicloService()

	const handleEndDate = useCallback((date: Date) => setEndDate(date), [])
	const handleStartDate = useCallback((date: Date) => setStartDate(date), [])

	const resetFilters = useCallback(() => {
		setSelectedPerson(initialValues.selectedPerson);
		setSelectedAuthor(initialValues.selectedAuthor)
		setStartDate(initialValues.startDate);
		setEndDate(initialValues.endDate);
		setSelectedStatus(initialValues.selectedStatus);
		setCheckClosedTask(false);
	}, []);

	const handleSet = (e: MultiValue<SelectOptions> | SingleValue<SelectOptions>, setState: Dispatch<SelectOptions[] | SelectOptions>, property: keyof typeof initialValues ) => {
		const selected = Array.isArray(e) ? e : [e];
		const initialValue = (Array.isArray(initialValues[property]) ? initialValues[property] : [initialValues[property]]) as SelectOptions[]
		if(selected.length > 0) {
			const isAllSelected = selected.some((item, index) => item.value === '' && index === selected.length -1);
			const kanbanValue = isAllSelected ? initialValue[0] : selected[0];
			const taskValue = isAllSelected ? initialValue : selected.filter((item) => item.value !== '');
			kanban ? setState(kanbanValue) :  setState(taskValue);
		}else {
			const selected = initialValues[property] as SelectOptions[];
			kanban ? setState(selected[0]) : setState(selected)
		}
		
	}

	const handleSetMulti = (e: SelectOptions[], setState: Dispatch<SelectOptions[]>, property: keyof typeof initialValues) => {
		const initialValue = initialValues[property] as SelectOptions[];
		const selectedLegth = e.length;
		if(selectedLegth > 0) {
			const isAllSelected = e.some((item, index) => {
			const isEmpty = item.value === '';
				const isLastSelected = e.length - 1 === index;
				return isEmpty && isLastSelected;
			});
			const selectedNoEmptyValue = e.filter((item) => item.value !== '');
			const tempState = isAllSelected ? initialValue : selectedNoEmptyValue;
			setState(tempState);
			return;
		}
		setState(initialValue)
		
	}

	const handleSetAuthor = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
		handleSetMulti(e as SelectOptions[], setSelectedAuthor, 'selectedAuthor');
	}, [handleSetMulti])


	const handleSetPerson = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
		handleSetMulti(e as SelectOptions[], setSelectedPerson, 'selectedPerson');
	}, [handleSetMulti])

	const handleChangeKeyResult = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
		handleSet(e, setSelectedKeyResult, 'selectedKeyResult')
	}, [handleSet, initialValues])

	const handleSetStatus = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
			const selected = e as SelectOptions;
			const isAllSelected = selected.value === '';
			const tempStatus = isAllSelected ? initialValues.selectedStatus : selected;
			setSelectedStatus(tempStatus);
	}, [initialValues])

	const handleChangeCiclo = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
		setSelectedKeyResult(initialValues.selectedKeyResult);
		
		setTimesFilters((prev) => ({
			...prev,
			ciclo: (e as SelectOptions)
		}))
	}, [setTimesFilters])

	const checkVisible = (value: keyof typeof elementsRef) => {
		const hasValue = (item: SelectOptions) => item.value === value; 
		const isVisible = openFilters.some(hasValue)
		return isVisible;
	}

	const formatAdditional = (selected: SelectOptions[] | SelectOptions) => {
		if(kanban) {
			return formatObjectToFilter(selected as SelectOptions)
		}
		return formatArrayToFilter(selected as SelectOptions[] | string)
		
	}

	const formatComponents = (): Partial<SelectComponents<SelectOptions, boolean, GroupBase<SelectOptions>>> => {
		if(kanban) {
			return {Option: KanbanProjectOption, ValueContainer: SingleContainerKanban, DropdownIndicator: DropIndicatorKanban}

		}
		return {MultiValueContainer: ProjectMultiValueContainer, Option: KanbanProjectOption, DropdownIndicator: DropIndicatorKanban}
	}

	const isMenuOpen = (): boolean => {
		const project = Array.isArray(selectedProject) ? selectedProject : [selectedProject]
		const keyresult = Array.isArray(selectedKeyResult) ? selectedKeyResult : [selectedKeyResult]
		const isProjectSelected = keyresult[0].value !== '' || project[0].value !== '';
		return kanban && !isProjectSelected;
	}

	const getKeyProject = () => {
		const selectedP = Array.isArray(selectedProject) ? selectedProject : [selectedProject];

		return `${selectedP.length}${selectedP[0].value}`
	}

	const handleSearch = useCallback(() => {
		if(kanban) {
			handleGetInitialValues();
			return;
		}
		getTasksPagination();
		
	}, [kanban, searchFilter, handleGetInitialValues, getTasksPagination])

	return(
		<FilterContainer 
			handleSearch={handleSearch} 
			searchFilter={searchFilter} 
			filterOptions={filterOptions} 
			loadingFilters={loadingFilters} 
			openFilters={openFilters} 
			setOpenFilters={setOpenFilters}
			resetFilters={resetFilters}
			setSearchFilter={setSearchFilter} 
			selectedFilters={filterContext} 
			search={true}
			kanbanProject={
				<SelectKanbanProjectContainer ref={elementsRef.project} id="kanban-select-keyresult"  title={(selectedKeyResult as SelectOptions)?.label?.toString()}>
					<AsyncSelector
						loadOptions={keyresultService.loadKeyResultsSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional>}
						components={formatComponents()}
						additional={{
							page: 1,
							cliente: '',
							userDetails: userDetails,
							ciclo: timesFilters?.ciclo?.value?.toString(),
							placeholder: ''
						}}
						onChange={handleChangeKeyResult}
						placeholder={t('Key Results')}
						style={selectFilterStyle}
						key={JSON.stringify(timesFilters?.ciclo?.value?.toString())+"keyresult"}
						value={selectedKeyResult}
						isClearable={false}
						autoFocus={true}
						isMulti={!kanban}
						defaultMenuIsOpen={isMenuOpen()}
						closeMenuOnSelect={kanban && userDetails?.has_finished_tour}
						hideSelectedOptions={false}
						blurInputOnSelect={false}
						id='react-tour_select-keyresult'
						classNamePrefix='keyresult-kanban'
					/>
					<DivBar className='divbar-kanban-project'  />
				</SelectKanbanProjectContainer>
			}
		>		
			{kanban && checkVisible('ciclo') && 
				(<SelectFilterContainer  ref={elementsRef.ciclo}>
					<AsyncSelector
						loadOptions={cicloService.loadCiclosSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional>}
						additional={{
							page: 1,
							filterName: "Ciclos",
							placeholder: t('Todos'),
						}}
						isMulti={false}
						components={{
							MultiValueContainer,
							Option
						}}
						value={timesFilters.ciclo}
						isClearable={false}
						onChange={handleChangeCiclo}
						placeholder={t('Ciclos')}
						style={selectFilterStyle}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						blurInputOnSelect={false}
					/>
				</SelectFilterContainer>)
			}		
			{kanban && checkVisible('person') && 
				(<SelectFilterContainer ref={elementsRef.person}>
					<AsyncSelector
						value={selectedPerson}
						components={{
							MultiValueContainer,
							Option
						}}
						loadOptions={pessoaService.loadPessoasSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional>}
						additional={{
							projeto: formatAdditional(selectedProject),
							page: 1,
							placeholder: t('Todos') ?? 'Todos',
							userDetails: userDetails,
							filterName: "Responsáveis"

						}}
						onChange={handleSetPerson}
						disabled={!userDetails || userDetails.acesso_cod === 'e'}
						isMulti
						placeholder={t('Responsáveis')}
						style={selectFilterStyle}
						key={JSON.stringify(`${getKeyProject()}`)}
						isClearable={false}
						closeMenuOnSelect={false}
						blurInputOnSelect={false}
						openMenuOnFocus={true}
						FieldName='person'
						hideSelectedOptions={false}

					/>
				</SelectFilterContainer>)}
				{kanban && checkVisible('author') && <SelectFilterContainer ref={elementsRef.author}>
					<AsyncSelector
						value={selectedAuthor}
						components={{  MultiValueContainer, Option }}
						loadOptions={pessoaService.loadPessoasSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional>}
						additional={{
							projeto: formatAdditional(selectedProject),
							page: 1,
							placeholder: t('Todos'),
							userDetails: userDetails,
							filterName: "Autor"
						}}
						onChange={handleSetAuthor}
						disabled={!userDetails || userDetails.acesso_cod === 'e'}
						placeholder={t('Autor')}
						style={selectFilterStyle}
						key={
							JSON.stringify(`${getKeyProject()}`) + '-autor'
						}
						isMulti={true}
						isClearable={false}
						closeMenuOnSelect={false}
						FieldName='author'
						openMenuOnFocus={true}
						hideSelectedOptions={false}
						blurInputOnSelect={false}
					/>
				</SelectFilterContainer>}
		
					{checkVisible('status') && 
				(<div ref={elementsRef.status} style={{display: 'flex', flexDirection: 'row'}}>
					<SelectFilterContainer>
						<Select
							placeholder={'Datas'}
							options={[
								{ value: '', label: t('Todos') },
								{ value: 'onTime', label: t('No prazo') },
								{ value: 'endsToday', label: t('Entrega hoje') },
								{ value: 'deadLine', label: t('Prazo excedido') },
								{ value: 'custom', label: t('Personalizado') },
							]}
							onChange={handleSetStatus}
							styles={selectFilterStyle}
							value={selectedStatus}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							blurInputOnSelect={false}
							components={{ Option }}
							menuPosition="fixed"
							menuPlacement="auto"
						/>
					</SelectFilterContainer>
					{selectedStatus.value === 'custom' && (
						<>
						<SelectFilterContainer>
							<DatePicker
								width={'5rem'}
								placeholder={t('Data Inicial')}
								onChange={handleStartDate}
								value={startDate}
								selectsStart
								startDate={startDate}
								endDate={endDate}
							/>
						</SelectFilterContainer>
						
						<SelectFilterContainer>
							<DatePicker
								width={'5rem'}
								placeholder={t('Data Final')}
								onChange={handleEndDate}
								value={endDate}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								minDate={startDate}
							/>
						</SelectFilterContainer>

						</>
					)}
					
					</div>)
				}
				{checkVisible('encerradas') && <CheckboxContainer ref={elementsRef.encerradas}>
					<input
						type="checkbox"
						id="scales"
						name="scales"
						checked={checkClosedTask}
						onChange={() => {
							setCheckClosedTask(!checkClosedTask);
						}}
					/>
					<CheckboxLabel htmlFor={'scales'}>{t('Encerradas')}</CheckboxLabel>
				</CheckboxContainer>}
					
		</FilterContainer>
	)
					
}

export default FilterOkrKanban;