import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function PlanusLogoDark(props: IconProps) {
  return ( 
  <svg width="128" height="33" viewBox="0 0 128 33" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_410_936)">
<path d="M39.8601 0L44.6946 3.24138L39.8601 6.48276H127.704V3.24138V0H39.8601Z" fill="#01DC94"/>
<path d="M116.559 23.1579C114.209 22.6486 112.32 21.8228 110.888 20.6838C109.46 19.5277 108.744 18.0171 108.744 16.1487C108.744 14.8411 109.109 13.6471 109.839 12.5598C110.582 11.4586 111.652 10.5881 113.056 9.94119C114.456 9.28053 116.122 8.9502 118.045 8.9502C119.969 8.9502 121.806 9.253 123.303 9.85861C124.814 10.4642 125.977 11.3245 126.789 12.4359C127.601 13.537 128.007 14.8205 128.007 16.2932H120.605C120.605 15.4398 120.413 14.8377 120.027 14.4798C119.656 14.1082 118.988 13.9224 118.028 13.9224C117.395 13.9224 116.848 14.0807 116.38 14.3972C115.926 14.7138 115.699 15.1542 115.699 15.7151C115.699 16.8024 116.875 17.5285 119.226 17.9001C120.901 18.1616 122.367 18.547 123.616 19.0563C124.879 19.5518 125.911 20.295 126.71 21.2826C127.522 22.2736 127.928 23.557 127.928 25.1399C127.928 26.4578 127.522 27.6483 126.71 28.7081C125.898 29.7542 124.745 30.5766 123.245 31.1822C121.748 31.7878 120.007 32.0906 118.028 32.0906C116.05 32.0906 114.181 31.719 112.667 30.9757C111.167 30.2187 110.021 29.2587 109.223 28.0888C108.438 26.9085 108.046 25.7042 108.046 24.4792H114.911C114.938 25.3463 115.241 25.9967 115.819 26.4371C116.397 26.8776 117.199 27.0978 118.231 27.0978C119.071 27.0978 119.711 26.9395 120.148 26.6229C120.602 26.2926 120.829 25.8522 120.829 25.3016C120.829 24.9713 120.698 24.696 120.437 24.4758C120.189 24.2418 119.762 24.0284 119.16 23.8358C118.568 23.6293 117.701 23.4022 116.562 23.1544L116.559 23.1579Z" fill="#426172"/>
<path d="M105.775 9.36328V31.6744H98.8688L98.6417 29.221C97.9398 30.1432 97.0899 30.8486 96.0851 31.3441C95.0941 31.8396 93.9896 32.0873 92.7646 32.0873C90.4419 32.0873 88.6148 31.4129 87.2797 30.0675C85.9446 28.7221 85.2805 26.63 85.2805 23.7981V9.36328H92.682V23.8394C92.682 24.5551 92.9022 25.116 93.3427 25.5289C93.7831 25.9418 94.4369 26.1482 95.3006 26.1482C96.7699 26.1482 97.7884 25.6665 98.3527 24.7065V9.36328H105.775Z" fill="#426172"/>
<path d="M74.8475 8.9502C77.0463 8.9502 78.7565 9.63839 79.9814 11.0113C81.203 12.3877 81.8155 14.6553 81.8155 17.8141V31.6708H74.3933V17.7934C74.3933 16.9951 74.2763 16.3861 74.0423 15.9594C73.8221 15.5327 73.4987 15.2506 73.072 15.1129C72.6591 14.9615 72.1085 14.8858 71.4238 14.8858C70.2126 14.8858 69.3214 15.2988 68.7433 16.1246V31.6708H61.3418V9.36311H68.2684L68.5162 11.9404C69.2732 10.9632 70.185 10.2233 71.2586 9.71409C72.3288 9.20483 73.5262 8.9502 74.8475 8.9502Z" fill="#426172"/>
<path d="M57.9387 25.8173C57.9387 27.3692 58.0144 28.5253 58.1658 29.2823C58.3172 30.0256 58.5581 30.7034 58.8884 31.3228V31.6738H51.5282C51.2942 31.1921 51.0877 30.552 50.9088 29.7572C50.317 30.5004 49.6081 31.0751 48.7858 31.488C47.9599 31.8871 46.9036 32.0867 45.6098 32.0867C44.316 32.0867 43.0118 31.8045 41.8557 31.2402C40.6995 30.6759 39.7808 29.8879 39.0926 28.8694C38.4044 27.8371 38.0603 26.6706 38.0603 25.3631C38.0603 23.0267 38.9205 21.2236 40.6376 19.9608C42.3684 18.6842 45.0213 18.0442 48.5965 18.0442H50.5131V17.0119C50.5131 16.1172 50.3204 15.4394 49.935 14.9714C49.5634 14.5034 48.9578 14.2694 48.1217 14.2694C47.3922 14.2694 46.8519 14.4415 46.4941 14.7856C46.15 15.1159 45.9779 15.6665 45.9779 16.4338H38.5971C38.5971 14.9645 39.0031 13.6638 39.8152 12.5352C40.6273 11.3928 41.78 10.5154 43.2802 9.89599C44.777 9.26286 46.525 8.94629 48.5174 8.94629C50.3307 8.94629 51.948 9.25598 53.3622 9.87535C54.7902 10.481 55.912 11.3962 56.724 12.6178C57.5361 13.829 57.9421 15.3052 57.9421 17.0497V25.8138L57.9387 25.8173ZM47.4438 26.7016C48.187 26.7016 48.8167 26.5709 49.3397 26.3093C49.8765 26.0341 50.2688 25.69 50.5131 25.2771V22.0185H48.4313C47.3578 22.0185 46.5904 22.3213 46.1225 22.9269C45.6682 23.5187 45.4411 24.2241 45.4411 25.0499C45.4411 25.5454 45.6338 25.9446 46.0192 26.2474C46.4046 26.5502 46.8795 26.7016 47.4438 26.7016Z" fill="#426172"/>
<path d="M27.7134 31.6705V0H35.1355V31.6705H27.7134Z" fill="#426172"/>
<path d="M12.1018 1.65137C14.4658 1.65137 16.5407 2.10557 18.33 3.01054C20.1158 3.90519 21.4922 5.14738 22.4522 6.74398C23.4157 8.32337 23.8974 10.1196 23.8974 12.1256C23.8974 14.1317 23.4157 15.7558 22.4522 17.2389C21.4888 18.7219 20.1158 19.8781 18.33 20.7039C16.5579 21.5298 14.483 21.9427 12.1018 21.9427H7.69053V31.6737H0V1.65137H12.1018ZM12.1018 15.6939C13.4507 15.6939 14.452 15.3773 15.1127 14.7442C15.7733 14.0973 16.1037 13.2405 16.1037 12.1669C16.1037 10.9282 15.7664 9.90621 15.092 9.09415C14.4176 8.28208 13.4232 7.87605 12.1018 7.87605H7.69053V15.6905H12.1018V15.6939Z" fill="#426172"/>
</g>
<defs>
<clipPath id="clip0_410_936">
<rect width="128" height="32.0834" fill="#426172"/>
</clipPath>
</defs>
</svg>

  
  )
}