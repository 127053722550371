import styled from "styled-components";
import { theme } from "../../../../../styles/theme";



export const Td = styled.td`
    text-align:start;
    font-weight: 500;
    color: rgb(128, 153, 167);
    vertical-align: middle;
`


interface InputProps {
    inputWidth?: string;
    fontSize?: string;
    customStyle?: string;
}

export const Input = styled.textarea<InputProps>`
    text-align: start;
    color: ${({color}) => color ?? 'rgb(128, 153, 167)'};
    font-weight: ${({color}) => color ? '700' : '400'};
    border: none;
    background-color: transparent;
    ${({fontSize}) => fontSize ? `${fontSize}` : 'font-size: 14px'};
    padding:  6px 0;
    width: ${({inputWidth}) => inputWidth ? `${inputWidth}px` : '-webkit-fill-available'};
    min-width: ${({inputWidth}) => inputWidth ? `${inputWidth}px` : '-webkit-fill-available'};
    text-overflow: ellipsis;
    ${({customStyle}) => customStyle}
    resize: none;
    &:focus{
        outline: 1px solid ${theme.colors.input.borderBackgroundInput};
    }
    &::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const TextInfo = styled.div<InputProps>`
    text-align: start;
    color: ${({color}) => color ?? 'rgb(128, 153, 167)'};
    font-weight: ${({color}) => color ? '700' : '400'};
    border: none;
    background-color: transparent;
    ${({fontSize}) => fontSize ? `${fontSize}` : 'font-size: 14px'};
    padding:  6px 0;
    width: ${({inputWidth}) => inputWidth ? `${inputWidth}px` : '-webkit-fill-available'};
    min-width: ${({inputWidth}) => inputWidth ? `${inputWidth}px` : '-webkit-fill-available'};
    text-overflow: ellipsis;
    ${({customStyle}) => customStyle}
    resize: none;
    &:focus{
        outline: 1px solid ${theme.colors.input.borderBackgroundInput};
    }
    &::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`
