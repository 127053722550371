import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function LogoutIcon(props: IconProps) {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path d="M2.32031 2.17969V13.8203H9V15.5H2.32031C1.8776 15.5 1.48698 15.3307 1.14844 14.9922C0.835938 14.6536 0.679688 14.263 0.679688 13.8203V2.17969C0.679688 1.73698 0.835938 1.34635 1.14844 1.00781C1.48698 0.669271 1.8776 0.5 2.32031 0.5H9V2.17969H2.32031ZM13.1797 3.82031L17.3203 8L13.1797 12.1797L12.0078 10.9688L14.1562 8.82031H5.67969V7.17969H14.1562L12.0078 4.99219L13.1797 3.82031Z" fill="white"/>
</svg>

  )
}