import React from 'react';
import './style.css';

const CheckBox = ({ checked }) => (
	<div className="esqueciSenha-container">
		{checked ? <span className="check-dot"></span> : ''}
	</div>
);

export default CheckBox;
