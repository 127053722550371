import styled from "styled-components";

export const ConfirmModalSection = styled.section`
    display: flex;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 13;

	.confirm-modal-align {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
	}
`;

export const ModalContent = styled.div`
	z-index: 2;
	min-width: 40%;
	border-radius: 20px;
	background-color: #f7f6f5;
	padding: 0 15px;
	padding-bottom: 12px;
	border: 1px solid rgb(177, 173, 173);
	max-height: 100vh;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-animation-name: animatetopGenerico;
	-webkit-animation-duration: 0.3s;
	animation-name: animatetopGenerico;
	animation-duration: 0.3s;

	.confirm-modal p {
		margin: 0;
		padding: 0;
		font-size: 20px;
		padding: 6px 10px;
		font-weight: 600;
		color: #426172;
		text-align: center;
	}

	@keyframes animatetopGenerico {
		from {
			margin-top: -200px;
			opacity: 0;
		}
		to {
			margin-top: 0;
			opacity: 1;
		}
	}
`;

export const ButtonContainer = styled.div`
	position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    top: 2.1em;
	width: 100%;
	
	.confirm-modal-button {
		background: #5ad8e3;
		border: 1px solid #29dff0;
		outline: none !important;
		border-radius: 20px;
		color: white;
		font-size: 13.5px;
		font-weight: 600;
		height: 40px;
		width: 140px;
		line-height: 16px;
		border: none;
		transition: all 0.2s;
	}

	.confirm-modal-button:hover {
		filter: brightness(0.9);
	}

	.confirm-modal-button:active {
		filter: brightness(0.7);
	}

	.confirm-button {
		background-color: #CA5B62;
		border: 1px solid #D94F57;
	}
`;