import React, { ButtonHTMLAttributes } from "react"
import { twMerge } from "tailwind-merge"
import { LoaderCircle } from "../../../common/loaders/LoaderCircle"

interface GenericButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
}

export function GenericButton({className, loading, children, ...props}: Readonly<GenericButtonProps>) {
    const classes = twMerge(
        `flex items-center justify-center gap-2 w-9 h-9 rounded-lg border-0 
        bg-ivory-secondary font-medium transition-all hover:brightness-95 disabled:opacity-70`,
        className
    )

    return (
        <button className={classes} {...props}>
          {loading ? (
            <LoaderCircle />
          ) : (
            children
          )}
        </button>
    )
}