import styled from "styled-components";

export const CloseGenerico = styled.span`
	color: #426172;
	font-size: 28px;
	font-weight: bold;
	user-select: none;
	margin-bottom: 5px;
    cursor: pointer;


    &:hover,
    &:focus {
        color: #000;
        text-decoration: none;
    }
`;

export const LoaderGenerico = styled.div`
	display: inline-block;
	width: 22px;
	height: 22px;
	vertical-align: text-bottom;
	border: 0.25em solid #fff;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
`;

export const ButtonSaveContinue = styled.button`
    background: #5ad8e3;
    border: 1px solid #29dff0;
    outline: none !important;
    border-radius: 20px;
    color: white;
    font-size: 13.5px;
    font-weight: 600;
    height: 40px;
    width: 140px;
    line-height: 16px;
    margin-left: 30px;
    border: none;

    &:focus {
        border: #0da388 solid 1px;
    }
`;

export const ButtonSave = styled.button`
	background: #23d9b7;
	border: none;
	outline: none !important;
	border-radius: 20px;
	height: 40px;
	width: 140px;
	color: white;
	font-size: 13.5px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 16px;
	margin-right: 0px;

    &:focus {
        border: #0da388 solid 1px;
    }
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
    gap: 16px;
    margin-bottom: 26px;
`;


export const ModalLoader = styled.div`
	display: inline-block;
	width: 22px;
	height: 22px;
	vertical-align: text-bottom;
	border: 0.25em solid #fff;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
`;