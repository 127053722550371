
import React, { useEffect, useState } from 'react';
import { RowWrapper } from '../../../components/tailwindCommon/Table/RowWrapper';
import { TimeRow } from './TimeRow';
import { LoadingRow } from '../../../components/tailwindCommon/Table/LoadingRow';
import { useAtom } from 'jotai';
import { empresaProgressoAtom, loadingTimesAtom, openTeamsAtom, timesOkrsAtom } from '../../../atoms/Okrs';
import { LoadMoreRow } from '../../../components/tailwindCommon/Table/LoadMoreRow';
import { NotFoundRow } from '../../../components/tailwindCommon/Table/NotFoundRow';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useQuery } from '../../../utils/hooks/useQuery';

interface TimeOKRTableProps {
  readonly getTimesPagination: (page: number) => Promise<void>
  readonly getObjetivosPagination: (page: number, timeId: number) => Promise<void>
  readonly getKeyResultsPagination: (page: number, objetivoId: number) => Promise<void>
  readonly refreshProgresso: ({ timeId, objetivoId }: { timeId: number, objetivoId?: number }) => void
}

export function TimeOKRTable({ getTimesPagination, getKeyResultsPagination, getObjetivosPagination, refreshProgresso }: TimeOKRTableProps) {
  const [loadingTimes, setLoadingTimes] = useAtom(loadingTimesAtom)
  const [timesOkrs,] = useAtom(timesOkrsAtom)
  const [empresaProgresso,] = useAtom(empresaProgressoAtom);

  const [isAllOpen, setIsAllOpen] = useState(false);
  const [openTeams, setOpenTeams] = useAtom(openTeamsAtom);

	const query = useQuery();
  const keyresultId = query.get('keyresultId')

  const handlePagination = (page: number) => {
    setLoadingTimes(true)
    getTimesPagination(page).finally(() => {
      setLoadingTimes(false)
    })
  }

  const handleExpandAll = () => {
    setIsAllOpen(prev => {
      if (!prev) {
        setOpenTeams(timesOkrs.timesOkrs.map(team => team.id));

      } else {
        setOpenTeams([]);
      }
      return !prev;
    })
  }

  useEffect(() => {
    if (openTeams.length < timesOkrs.timesOkrs.length) {
      setIsAllOpen(false);
    }
  }, [openTeams]);

  useEffect(() => {
    const prevKeyresult = document.getElementById(`keyresult-row-${keyresultId}`)

    if(prevKeyresult){
      prevKeyresult.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    }
  }, [keyresultId])

  return (
    <div>
      <div className='rounded-t-lg bg-ivory-primary text-'>
        <RowWrapper className='cursor-pointer text-white' onClick={handleExpandAll}>
          <div className='col-span-5 flex flex-row gap-2'>
            {isAllOpen ? <FaCaretDown className='h-5 w-5' />
              : (
                <FaCaretUp className='h-5 w-5' />
              )}
            <p>Times</p>
          </div>

          <div className='col-span-1'>
            <p>Responsáveis</p>
          </div>

          <div className='col-span-2 flex items-center gap-2'>
            {empresaProgresso && (
              <>
                <div className='relative w-3/5 flex items-center'>
                  <div className='h-3 w-full bg-slate-200 rounded-full'></div>
                  <div className={`absolute left-0 h-3 bg-ivory-green rounded-full`} style={{ width: `${empresaProgresso.progresso}%` }}></div>
                </div>
                <p className='min-w-[44px] text-right'>{empresaProgresso.progresso}%</p>
              </>
            )}
          </div>


          <div className='col-span-1'>
            <p>Tarefas</p>
          </div>


          <div className='col-span-'>
            <p>Ações</p>
          </div>
        </RowWrapper>
      </div>

      <div>
        {timesOkrs.timesOkrs.map(time => {
          return (
            <div key={time.id}>
              <TimeRow
                openTeams={openTeams}
                setOpenTeams={setOpenTeams}
                time={time}
                getObjetivosPagination={getObjetivosPagination}
                refreshProgresso={refreshProgresso}
                getKeyResultsPagination={getKeyResultsPagination}
              />
            </div>
          )
        })}

        {timesOkrs.timesOkrs?.length === 0 && !loadingTimes && (
          <NotFoundRow text="Nenhum time encontrado." />
        )}

        {loadingTimes && (
          <LoadingRow />
        )}

        {timesOkrs?.totalPages !== timesOkrs?.currentPage && (
          <LoadMoreRow
            handlePagination={() => handlePagination(timesOkrs?.currentPage + 1)}
            hideLoader={false}
            loading={loadingTimes}
          />
        )}
      </div>
    </div>
  )
}