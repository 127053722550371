import React, { useCallback, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { theme } from '../../../../styles/theme';
import { ConfirmModal } from '../../Modais/ConfirmModal';
import { ContentDiv, Th } from './styled';
import TableCellText from '../components/TableCellText';
import { t } from 'i18next';
import { Draggable } from 'react-beautiful-dnd';
import { FieldsHeader } from '..';

export interface ColumnHeaderDragProps extends FieldsHeader {
    index: number;
}

export function ColumnHeaderDrag({index, title, width, deletable, editable, id, handleDelete, handleBlur}: ColumnHeaderDragProps) {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    
    const handleConfirmDelete = useCallback(() => {
        if(handleDelete !== undefined && id) {
            handleDelete(id)
        }
    }, [id, handleDelete]);

    const onBlur = useCallback((value: string | number) => {
        if(handleBlur !== undefined && id) {
            handleBlur(value.toString(), id)
        }
    }, [id, handleBlur]);

    const handleOpenConfirModal = useCallback(() => {
        setIsConfirmModalOpen(true)
    }, []);
    
    return (
        <Draggable draggableId={id || ""} index={index}>
            {(provided) => {
                return (
                    <Th 
                        width={width}
                        editable={true}
						ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                            ...provided.draggableProps.style,
                        }}
                        {...provided.dragHandleProps}
                    >
                        <ContentDiv>
                            {editable ? (
                                <TableCellText 
                                    key={title}
                                    name={title}
                                    value={title?.toString()}
                                    onBlur={onBlur}
                                    id={id}
                                    width={width}
                                    type={'text'}
                                />
                            ) : (
                                title
                            )}

                            {deletable && (
                                <IoMdClose className='delete-header' cursor={'pointer'}  size={16} onClick={handleOpenConfirModal} color={theme.colors.button.darkBtn} />
                            )}
                        </ContentDiv>

                        <ConfirmModal loading={false} text={t("Excluir coluna selecionada?")} handleConfirm={handleConfirmDelete} isConfirmModalOpen={isConfirmModalOpen} setIsConfirmModalOpen={setIsConfirmModalOpen} />
                    </Th>
                    );
                }}
        </Draggable>
    )
}