import { AxiosResponse } from 'axios';
import HttpService from '../../../config/http';
import { CallbackType, DataType } from '../../types';
import { CicloProps, CicloResponse, GetCicloProps } from './types';
import { t } from 'i18next';
import { SelectOptions } from '../../../../models/Select';

export default class CicloService {
	private api: HttpService;
	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getCiclo = (
		id: number,
	) => {
		return this.api
			.get(`api/v1/okrs/ciclos/${id}/?minimal=true`)
	};

	getCicloAtual = async (
	) : Promise<CicloResponse | null> => {
		const response = (await this.api
			.get(`api/v1/okrs/cicloatual/`))

		if(response.data) {
			return response.data
		}
		return null
	};

	getCiclos = async ({page, search, signal}: GetCicloProps) => {
		const response: AxiosResponse<DataType<CicloResponse[]>> = await this.api
			.get(`api/v1/okrs/ciclos/?page=${page}&paginate=${page}&search=${search ?? ''}`, {signal})

		return response.data
	};

	postCiclo = (
		params: CicloProps,
		success: CallbackType<AxiosResponse<CicloResponse>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.post(`api/v1/okrs/ciclos/`, params)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else if (typeof result.data === typeof {}) {
					const objError = result.data;
					for (const key in objError) {
						throw new Error(t(`${objError[key]}`) + '!');
					}
				} else {
					throw new Error(t('Erro ao tentar salvar o ciclo!'));
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchCiclo = (
		id: number,
		params: CicloProps,
		success: CallbackType<AxiosResponse<CicloResponse>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.patch(`api/v1/okrs/ciclos/${id}/`, params)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + '!');
						}
					} else {
						throw new Error(t('Erro ao tentar editar o ciclo!'));
					}
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteCiclo = async (id: number) => {
		const response = await this.api
		.delete(`api/v1/okrs/ciclos/${id}/`).catch((error) =>{
			throw new Error(error)
		});
	
		if(response.status === 204) {
			return response.data
		}

		throw new Error(t('Erro ao tentar deletar o ciclo!'));
	}

	loadCiclosSelect = async (
		search: string,
		loadedOptions: any,
		{ page = 1 , placeholder}: {page?: number, placeholder?: string},
	) => {
		const response: AxiosResponse<
			DataType<CicloResponse[]>
		> = await this.api.get(`api/v1/okrs/ciclos/?search=${search ?? ""}&paginate=true&page=${page}`);

		if (response?.status !== 200) {
			if (typeof response?.data === typeof {}) {
				const objError = response?.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof typeof objError]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar buscar o ciclo!'));
			}
		}

		const arrayRenomeado: SelectOptions[] = response?.data.results.map(objeto => ({
			label: objeto.nome,
			value: objeto.id,
			extra: {
				data_inicial: objeto.data_inicial,
				data_final: objeto.data_final
			}
		}));	

		if(placeholder) {
			arrayRenomeado.splice(0, 0, { value: '', label: placeholder})
		}

		return {
			options: arrayRenomeado,
			hasMore: !!response?.data?.next,
			additional: {
				page: page + 1,
			},
		};
	};
}
