import React from 'react';
import { verifyCanEditProject } from '../../../../../../utils/funcoes/validarPerfil';
import { useUser } from '../../../../../../contexts/User';
import {  ProjectPage } from '../../../../../../models/Project';
import CompanyImg from '../../../../../../assets/images/company.png';
import { useProject } from '../../../../../../contexts/Project';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';

interface ProjectCardHeaderProps {
	data: ProjectPage;
}

export default function ProjectCardHeader({ data }: Readonly<ProjectCardHeaderProps>) {
	const { userDetails } = useUser();
	const { loadingPostPatch, loadingProjects } = useProject();
	const history = useHistory();

	const isLoading = loadingPostPatch || loadingProjects;

	return (
		<button
			className={`flex flex-row gap-2 px-3 py-5 text-start rounded-t-lg items-center flex-1 relative ${
				isLoading || !data.is_member ? 'bg-border-gray' : ' bg-ivory-primary'
			}  ${
				verifyCanEditProject(userDetails, data.is_member, data.admins)
					? 'cursor-pointer hover:opacity-90'
					: 'cursor-default'
			} `}
			onClick={() => {
				if (verifyCanEditProject(userDetails, data.is_member, data.admins)) {
					history.push(`/registros/projetos/${data.id}`, { data });
				}
			}}
		>
					<div className='absolute right-3 bottom-1'>
					{!data.item_ativo && (
						<div className={`text-xs ${isLoading || !data.is_member ? 'text-gray-400' : 'text-white'}`}>
							{t("Desativado")}
						</div>
					)}
				</div>

			<img
				src={data.cliente.logo_cliente ?? CompanyImg}
				className={`rounded-full  overflow-hidden w-12 h-12 min-w-[3rem] min-h-[3rem] max-w-[3rem] max-h-[3rem] ${
					isLoading || !data.is_member ? 'opacity-20' : 'opacity-100'
				} `}
				alt='Logo do cliente'
			/>
			<div
				className={`flex flex-col ${
					verifyCanEditProject(userDetails, data.is_member, data.admins) &&
					'hover:underline'
				} ${isLoading || !data.is_member ? 'text-[#AEAEAE]' : 'text-white'}`}
			>
				<p className="font-black  text-base">{data.nome}</p>
				<p className="text-base">{data.cliente.nome}</p>
			</div>
		</button>
	);
}
