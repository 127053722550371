import React, {  useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import HoraService from '../../../services/providers/hour';

import TurTutorial from '../../../components/tourTutorial';
import FormFilter from './FormFilter';
import Lista from './Lista';

import { useQuery } from '../../../utils/hooks/useQuery';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactGA from 'react-ga';

import { useRegistrosDeHoras } from '../../../contexts/RegistrosDeHoras';

import '../../../i18n';

import { useTranslation } from 'react-i18next';
import {
	
	useHourFilters,
} from '../../../contexts/Filter/Hour';

import formatObjectToFilter from '../../../utils/funcoes/formatObjectToFilter';
import moment from 'moment';
import HeaderLink from '../../../components/common/HeaderLink';
import { useUser } from '../../../contexts/User';
import HeaderButton from '../../../components/planus2/Buttons/HeaderButton';
import { FaCirclePlus } from 'react-icons/fa6';
import { useStopwatch } from '../../../contexts/Stopwatch';

let controller: AbortController | null = null; 

export default function Horas() {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { handleToggleModal} = useStopwatch();
	const {
		addRegistros,
		setRegistros,
		registros,
		loadingRegistros,
	} = useRegistrosDeHoras();

	const [totalPaginasHoras, setTotalPaginasHoras] = useState<number | null>(null);
	const [paginasHoras, setPaginasHoras] = useState(1);

	const history = useHistory();
	const { userDetails, loadingUser} = useUser();

	const query = useQuery();
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	const {
		search,
		selectedTask,
		selectedPerson,
		startDate,
		endDate,
		selectedTime,
	} = useHourFilters();

	const getFormatedDate = (date: Date) => {
		const selectedDate =
			selectedTime.value === '' ? '' : moment(date).format('YYYY-MM-DD');
		return selectedDate;
	};

	const _getHorasPaginacao = (
		page = 1,
		pessoa: number | string = formatObjectToFilter(selectedPerson)
	) => {
		setLoading(true);
 
		if (controller !== null) {
			controller.abort();
		}

		controller = new AbortController();

		const signal = controller.signal;

		const horaService = new HoraService();
		horaService.getHorasPaginacao(
			page,
			{
				search: search,
				tarefa: formatObjectToFilter(selectedTask),
				pessoa: pessoa.toString(),
			},
			getFormatedDate(startDate ?? new Date()),
			getFormatedDate(endDate  ?? new Date()),
			response => {
				setLoading(false);
				if (page === 1) {
					setRegistros(response.results);
					setTotalPaginasHoras(response.total_pages);
					setPaginasHoras(page);
				} else {
					addRegistros(response.results);
					setTotalPaginasHoras(response.total_pages);
					setPaginasHoras(page);
				}
				setLoading(false);
			},
			() => {
				setRegistros([]);
				setLoading(false);
			},
			{ signal },
		);
	};


	const handlePagination = (page?: number, pessoa?: number) => {
		_getHorasPaginacao(page, pessoa);
	};

	const handleNavClick = useCallback(() => {
		handleToggleModal(true);
	}, [handleToggleModal])

	return (
		<div className='flex flex-col gap-5 overflow-auto'>
			{userDetails && !userDetails.has_finished_tour && <TurTutorial nomeRota="hora"  />}
			<HeaderLink type='hour' />
				<div className='flex flex-row items-center justify-center gap-5'>
					{!!query.get('urlBack') && (
						<button
							className='flex flex-row gap-1  font-semibold bg-ivory-primary-lighter items-center justify-center h-10 px-3 rounded-default '
							onClick={history.goBack}
						>
							<FontAwesomeIcon icon={faArrowLeft} className="voltar-icon" />
							{t('Voltar')}
						</button>
					)}
					<FormFilter handlePagination={handlePagination} />
					{userDetails?.acesso_cod !== 'e' && <HeaderButton onClick={handleNavClick} className='react-tour__add-hora'>
						<FaCirclePlus /> {t("Hora")}
					</HeaderButton>}
				</div>

						<Lista
							data={registros}
							totalPages={totalPaginasHoras}
							handlePagination={handlePagination}
							currentPage={paginasHoras}
							loading={loading || loadingRegistros || loadingUser} 
							loadingPagination={loading}

						/>
		</div>
	);
}