import React, { useEffect, useState } from 'react';
import { CardContainer, ChartDataHeaderTitle, ChartDataHeaderTotal, ChartContainer, ChartDataContainer, ChartDataTotal, ChartHeader, ChartTitle, ChartWidth, ChartDataBody, ChartDataItem, ChartDataTitle, ChartDataValue, LoadingContainer, ChartDataRow, ArrowsContainer, ChartArrowsContainer } from './styled';
import {
    Chart
} from 'react-chartjs-2';

import {
    Chart as ChartJS,
    ChartData,
    ArcElement,
    Legend,
    Tooltip,
    ChartOptions,
    BarController
} from 'chart.js';
import { TeamPerson } from '../../../../../../../../models/TeamPerson';
import { HoursRecord } from '../../../../../../../../models/HoursRecord';
import moment, { Moment } from 'moment';
import { calculateCurrentValue, extractHour } from '../../../utils';
import { formatBRL, formatBRLNoSymbol } from '../../../../../../../../utils/currency';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LoaderCircle } from '../../../../../../../../components/common/loaders/LoaderCircle';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { t } from 'i18next';

ChartJS.register(ArcElement, BarController, Tooltip, Legend);

interface CostByPeriodProps {
    projectTeam: TeamPerson[];
    hoursRecord: HoursRecord[];
    startPeriod: Date;
    endPeriod: Date;
    loading: boolean;
}

interface CostResult {
    totalCost: number,
    costByWeek: WeekCost[]
}

interface WeekCost {
    cost: number;
    name: string;
    title: string;
}



export default function CostByPeriod({projectTeam, loading, hoursRecord, startPeriod, endPeriod}: CostByPeriodProps) {
    const [data, setData] = useState<ChartData>({
        labels:  [`${t('Semana')} 1`, `${t('Semana')} 2`, `${t('Semana')} 3`, `${t('Semana')} 4`],
        datasets: []
    });
    const [page, setPage] = useState(0);
    const [dataValue, setDataValue] = useState<number[]>([])
    const [labels, setLabels] = useState<string[]>([]);
    const [costResult, setCostResult] = useState<CostResult>();
    const options: ChartOptions = {
        aspectRatio: 1,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.raw as number;
                        return formatBRL(value);
    
                    }
                }
            },
            datalabels: {
                display: true,
                color: "white",
                formatter: (value: number) => {
                    return value > 0 ? formatBRLNoSymbol(value) : ''
                },
                anchor: "center",
                align: "center",
                font: {
                    size: 10
                },
                backgroundColor: (context) => context.dataset.data[context.dataIndex] as number > 0 ? 'rgba(0, 0, 0, .3)' : ''
              }
      
        },
        elements: {
            bar: {
                borderRadius: 7
            },
        
        },
        scales: {
            x: {
                grid: {
                    lineWidth: 0
                },
                ticks: {
                    font: {
                        size: 10
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        size: 10
                    }
                }
            }

        }
    };

    useEffect(() => {
        if(projectTeam && hoursRecord) {
            handleChartData();
        }
    }, [projectTeam]);

    const groupByWeek = (startWeek: Moment, endWeek: Moment) => {
        return hoursRecord.filter((item) => {
            const day = moment(item.data);
            return day.isBetween(startWeek, endWeek, null, '[]')  
        })
    }

    const getCostWeek = (weekRecords: HoursRecord[]) => {
        return projectTeam.reduce((accumulator, currentTeam) => {
            const horas_realizadas = extractHour(weekRecords.filter(data => data.pessoa === currentTeam.pessoa.id));
            const cost = calculateCurrentValue(horas_realizadas, currentTeam.preco);
            return accumulator + cost;
        }, 0);
    }

    const hasWeek = (week: moment.Moment) => {
        return week.isSameOrAfter(startPeriod) && week.isSameOrBefore(endPeriod);
    };

    interface Week {
        start: Date;
        end: Date;
        hoursRecord?: HoursRecord[];
        cost?: number;
    }

    const handleWeek = (weekStart: Date, weekEnd: Date, weeks: Week[] = []) => {
        const nextWeekStart = moment(weekEnd).add(1, 'week').startOf('week').toDate();
        const nextWeekEnd = moment(nextWeekStart).endOf('week').toDate();
        const initialWeek: Week[] = weeks;
        if(moment(weekEnd).isBefore(endPeriod)){
            initialWeek.push({start: weekStart, end: weekEnd});
            handleWeek(nextWeekStart, nextWeekEnd, initialWeek);
        }else if(moment(weekStart).isBefore(endPeriod)) {
            initialWeek.push({start: weekStart, end: endPeriod});
        }
        return weeks;
    }

    const handleChartData = () => {
        const week1Start = moment(startPeriod).endOf('week');
        const week1End = moment(week1Start).endOf('week');

        const weeksDates: Week[] = handleWeek(startPeriod, week1End.toDate());
        const weeksData = weeksDates.map((week) => {
            const weekRecords = groupByWeek(moment(week.start), moment(week.end));
            const totalCost = getCostWeek(weekRecords);
            return {
                ...week,
                cost: totalCost,
                hoursRecord: weekRecords
            }
        })
    
        const totalCost = weeksData.reduce((accumulator, week) => {
            return accumulator + week.cost
        }, 0);

        const costWeeks: WeekCost[] = [];
        const dataValue: number[] = [];

        const labels: string[] = [];

 
        weeksData.forEach((week, index) => {
            const monthHasWeek = hasWeek(moment(week.start));
            const weekName = `${t('Semana')} ${index + 1}`;
            const weekValue = week.cost;
            if(monthHasWeek) {
                costWeeks.push({cost: weekValue, name: weekName, title: `${moment(week.start).format('DD/MM/YYYY')} - ${moment(week.end).format('DD/MM/YYYY')}`});
                dataValue.push(weekValue);
                labels.push(weekName);
            }

        });



        setCostResult({
            totalCost: totalCost,
            costByWeek: costWeeks
        })
        
        const data: ChartData = {
            labels: labels.slice(0, 5),
            datasets: [{
                label: "",
                data: dataValue.slice(0, 5),
                backgroundColor: '#88CCEE'
            }]
        }
        setLabels(labels)
        setDataValue(dataValue)
        setData(data);
    }

    const isBackActive = () => {
        if(labels.length > 0 && data.labels && data.labels.length > 0) {
            return labels[0] !== data.labels[0]
        }
    }

    const isForwardActive = () => {
        if(labels.length > 0 && data.labels && data.labels.length > 0) {
            return labels[labels.length - 1] !== data.labels[data.labels.length - 1]
        }
    }

    const goForward = () => {
        if(isForwardActive()) {
            const currentPage = page + 1;
            
        setData(prev => {
            return {
                ...prev,
                labels: labels.slice(currentPage * 5, currentPage*5 + 5),
                datasets: [{
                    label: "",
                    data: dataValue.slice(currentPage * 5, currentPage*5 + 5),
                    backgroundColor: '#88CCEE'
                }]
            }
        });
        setPage(currentPage)
        }
    }

    const goBack = () => {
        if(isBackActive()) {
            const currentPage = page - 1;
            
            setData(prev => {
                return {
                    ...prev,
                    labels: labels.slice(currentPage * 5, currentPage*5 + 5),
                    datasets: [{
                        label: "",
                        data: dataValue.slice(currentPage * 5, currentPage*5 + 5),
                        backgroundColor: '#88CCEE'
                    }]
                }
            });
        setPage(currentPage)
        }
    }
    return(
        <CardContainer>
        <ChartHeader>
            <ChartTitle>
                {t('Tendência de gastos para o período')}
            </ChartTitle>
        </ChartHeader>
        <ChartContainer>
            { loading ? (
                <LoadingContainer>
                     <LoaderCircle />
                </LoadingContainer>
            ) : <>
           <ChartArrowsContainer>
           <ChartWidth>
            <Chart type='bar' plugins={[ChartDataLabels]} data={data} options={options}  />
            </ChartWidth>
           {dataValue.length > 6 && <ArrowsContainer>
                <FaChevronRight onClick={goForward} cursor={isForwardActive() ? 'pointer' : 'default'} color={isForwardActive() ? 'black' : '#D9D9D9'} width={28} />
                <FaChevronLeft onClick={goBack} cursor={isBackActive() ? 'pointer' : 'default'} color={isBackActive() ? 'black' : '#D9D9D9'} width={28} />
                </ArrowsContainer>
           }
           </ChartArrowsContainer>
            <ChartDataContainer>
              
                <ChartDataBody >
                
                    {costResult?.costByWeek  &&
                        <>
                        <ChartDataRow>
                        <ChartDataTotal>
                        <ChartDataHeaderTitle>
                            {t('Gasto total')}
                        </ChartDataHeaderTitle>
                        <ChartDataHeaderTotal>
                            {formatBRL(costResult?.totalCost ?? 0)}
                        </ChartDataHeaderTotal>
                    </ChartDataTotal>
                             {costResult?.costByWeek.map((data) => {
                                    return (
                                        <ChartDataItem title={data.title} key={data.name}>
                                                <ChartDataTitle>
                                                    {data.name}
                                                </ChartDataTitle>
                                                <ChartDataValue>
                                                    {formatBRL(data.cost)}
                                                </ChartDataValue>
                                        </ChartDataItem>
                                    )
                            })}
                        </ChartDataRow>
                        </>
                    }
                </ChartDataBody>
            </ChartDataContainer></>}
        </ChartContainer>
    </CardContainer>
    )
}