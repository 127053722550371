import { t } from 'i18next';
import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
	email: Yup.string()
		.email(t('Email inválido'))
		.required(t('Campo obrigatório.')),
	password: Yup.string().required(t('Campo obrigatório.')),
});

export const RecuperarSenhaSchema = Yup.object().shape({
	email: Yup.string()
		.email(t('Email inválido'))
		.required(t('Campo obrigatório.')),
});

export const CriarSenhaSchema = Yup.object().shape({
	password: Yup.string()
	.required(t('Campo obrigatório.')),
});

export const CadastroSchema = Yup.object().shape({
	first_name: Yup.string().required(t('Campo obrigatório.')),
	nome_empresa: Yup.string().required(t('Campo obrigatório.')),
	email: Yup.string()
		.email(t('Email inválido'))
		.required(t('Campo obrigatório.')),
	password: Yup.string().required(t('Campo obrigatório.')),
	password_confirm: Yup.string().required(t('Campo obrigatório.')).oneOf([Yup.ref('password'),], t('As senhas devem ser iguais')),
});
