import React, { createContext, useContext, useMemo, useState } from 'react';

import { t } from 'i18next';
import { FilterContextInitialType, FilterProviderProps } from './types';
import { SelectOptions } from '../../../models/Select';

export const initialValues: FilterContextInitialType = {
	startDate: new Date(),
	setStartDate: () => {},
	endDate: new Date(),
	setEndDate: () => {},
	selectedPerson: { value: '', label: t('Usuário') },
	setSelectedPerson: () => {},
	selectedTask: { value: '', label: t('Tarefa') },
	setSelectedTask: () => {},
	selectedTime: { value: '', label: t('Intervalo de Tempo') },
	setSelectedTime: () => {},
	loadingFilters: false,
	setLoadingFilters: () => {},
	resetFilters: () => {},
	search: '',
	setSearch: () => {},
};

export const FilterHourContext = createContext(initialValues);

const FilterHourProvider = ({ children }: FilterProviderProps) => {
	const [selectedPerson, setSelectedPerson] = useState<SelectOptions>({
		value: '',
		label: t('Usuário'),
	});
	const [selectedTask, setSelectedTask] = useState<SelectOptions>({
		value: '',
		label: t('Tarefa'),
	});
	const [selectedTime, setSelectedTime] = useState<SelectOptions>({
		value: '',
		label: t('Intervalo de Tempo'),
	});
	const [startDate, setStartDate] = useState<Date>();
	const [endDate, setEndDate] = useState<Date>();
	const [search, setSearch] = useState('');
	const [loadingFilters, setLoadingFilters] = useState(false);

	const resetFilters = () => {
		setEndDate(initialValues.endDate);
		setStartDate(initialValues.startDate);
		setSelectedPerson(initialValues.selectedPerson);
		setSelectedTask(initialValues.selectedTask);
		setSelectedTime(initialValues.selectedTime);
	};

	const filterValue = useMemo(
		() => ({
			selectedPerson,
			setSelectedPerson,
			selectedTask,
			setSelectedTask,
			selectedTime,
			setSelectedTime,
			startDate,
			setStartDate,
			endDate,
			setEndDate,
			loadingFilters,
			setLoadingFilters,
			resetFilters,
			search,
			setSearch,
		}),
		[
			selectedPerson,
			setSelectedPerson,
			selectedTask,
			setSelectedTask,
			selectedTime,
			setSelectedTime,
			startDate,
			setStartDate,
			endDate,
			setEndDate,
			loadingFilters,
			setLoadingFilters,
			resetFilters,
			search,
			setSearch,
		],
	);

	return (
		<FilterHourContext.Provider value={filterValue}>
			{children}
		</FilterHourContext.Provider>
	);
};

const useHourFilters = () => {
	const context = useContext(FilterHourContext);
	return context;
};

export { FilterHourProvider, useHourFilters };
