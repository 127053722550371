import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import {  ProjectBasic, ProjectDetail, ProjectPage } from '../../../../../../models/Project';
import ProjetoService from '../../../../../../services/providers/project';
import { useProject } from '../../../../../../contexts/Project';
import { useHistory } from 'react-router-dom';
import { useFilters } from '../../../../../../contexts/Filter/Task/Filter';
import { useTranslation } from 'react-i18next';
import { verifyCanEditProject } from '../../../../../../utils/funcoes/validarPerfil';
import { useUser } from '../../../../../../contexts/User';
import TRow from '../../../../../../components/common/Table/TRow';
import TitleSubtitleCell from '../../../../../../components/common/Table/Cells/TitleSubtitleCell';
import PeopleCell from '../../../../../../components/common/Table/Cells/PeopleCell';
import DefaultCell from '../../../../../../components/common/Table/Cells/DefaultCell';
import ActionsCell from '../../../../../../components/common/Table/ActionsCell';
import { Edit } from '@mui/icons-material';
import { LuKanbanSquare } from 'react-icons/lu';
import CompanyImage from '../../../../../../assets/images/company.png'

interface TableRowProps {
	project: ProjectPage;
	setEditingProjeto: Dispatch<SetStateAction<boolean>>;
	setModalProjectVisible: Dispatch<SetStateAction<boolean>>;
	setProjetoEditado: Dispatch<SetStateAction<ProjectBasic | undefined>>;
	projetoEditado: ProjectBasic | undefined;
}

export default function TableRow({
	project,
	projetoEditado,
	setEditingProjeto,
	setModalProjectVisible,
	setProjetoEditado,
}: Readonly<TableRowProps>) {
	const [isLoading, setIsLoading] = useState(true);
	const { userDetails, loadingUser } = useUser();
	const { setProjects, projectsDetails, setProjectsDetails, loadingPostPatch } =
		useProject();
	const { setSelectedProject } = useFilters();
	const history = useHistory();
	const { t } = useTranslation();
	const loading = isLoading || loadingUser || loadingPostPatch;

	const handleEditProject = useCallback(() => {
		if (verifyCanEditProject(userDetails, project.is_member, project.admins)) {
			setEditingProjeto(true);
			setProjetoEditado({...project, time: project.time ?? []});
			setModalProjectVisible(true);
		}
	}, [project]);

	useEffect(() => {
		if (
			projetoEditado?.id === project.id &&
			projetoEditado.time === undefined
		) {
			setProjetoEditado({...project, time: project.time ?? []});
		}
	}, [projetoEditado, project]);
	const handleProjectDetails = (data: ProjectPage, projectDetail: ProjectDetail) => {
		setProjects((prevData) => prevData.map(item => {
			if(item.id === data.id){
				return data;
			}
			return item;
		}));
		setProjectsDetails((prev) => prev.map(item => {
			if(item.id === data.id){
				return projectDetail;
			}
			return item;
		}));
	}
	const getProjetoDetails = async () => {
		setIsLoading(true);
		const projectService = new ProjetoService();
		projectService.getDetalheProjeto(
			project.id,
			(response) => {
				if (response !== null) {
					project.is_member = response.is_member;
					project.admins = response.admins;
					project.time = response.time;
					project.tags = response.tags;
					handleProjectDetails(project, response);
				}
				setIsLoading(false);
			},
			(e) => {
				console.log(e);
			},
		);
	};

	useEffect(() => {
		if (
			project.admins == undefined ||
			project.time == undefined ||
			project.tags == undefined ||
			project.is_member == undefined
		) {
			const tempDetails = projectsDetails?.[project.id];

			if (tempDetails) {
				project.is_member = tempDetails.is_member;
				project.admins = tempDetails.admins;
				project.time = tempDetails.time;
				project.tags = tempDetails.tags;
				setIsLoading(false);
			} else {
				getProjetoDetails();
			}
		} else {
			setIsLoading(false);
		}
	}, [project]);

	const handleGoKanban = useCallback(() => {
		setSelectedProject({ value: project.id, label: project.nome });
		history.push(`/registros/kanban`);
	}, [history, project]);

	const handleGoTask = useCallback(() => {
		setSelectedProject([{ value: project.id, label: project.nome }]);
		history.push(`/registros/tarefas`);
	}, [history, project]);

	const handleGoProject = useCallback(() => {
		if (verifyCanEditProject(userDetails, project.is_member, project.admins)) {
			history.push(`/registros/projetos/${project.id}/parametros`);
		}
	}, [project, history]);

	console.log(loading);
	return (
		<TRow className={`${project.is_member ? '' : 'opacity-50'}`} key={project.id}>
			<TitleSubtitleCell
				onClick={
					verifyCanEditProject(userDetails, project.is_member, project.admins)
						? handleGoProject
						: undefined
				}
				title={project.nome}
				imgSrc={project.cliente.logo_cliente ?? CompanyImage}
				subtitle={project.item_ativo ? project.cliente.nome : `${project.cliente.nome} [DESATIVADO]`}
			/>
			<PeopleCell
				people={
					project.time ?? [
						{ nome: 'Pessoa 01', foto_perfil: null },
						{ foto_perfil: null, nome: 'Pessoa 02' },
					]
				}
				max={9}
			/>
			<DefaultCell>
				{' '}
				<button className='hover:underline hover:opacity-70' onClick={handleGoTask}>
					{Array.isArray(project.tarefas)
						? project.tarefas.length
						: project.tarefas}{' '}
					{t('Tarefas')}
				</button>
			</DefaultCell>
			<ActionsCell
				icons={
					verifyCanEditProject(userDetails, project.is_member, project.admins)
						? [
								{
									icon: <Edit fontSize="small" />,
									onClick: handleEditProject,
								},
								{
									icon: <LuKanbanSquare size={16} />,
									onClick: handleGoKanban,
								},
							]
						: [
								{
									icon: <LuKanbanSquare />,
									onClick: handleGoKanban,
								},
							]
				}
			/>
		</TRow>
	);
}
