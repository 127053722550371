import React, { HTMLAttributes } from 'react'
import { FaX } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';
import { GenericButton } from '../../Buttons/GenericButton';
import { PanelOptions } from '../../../containers/Modais/ModalComSideGenerico';

interface ExtraMenuOptions {
  handleDeletar?: (id: number) => Promise<void>
  handleEncerrar?: (id: number) => Promise<void>
  handleReactivate?: (id: number) => Promise<void>
  urlToCopyButton?: string
  itemId: number;
}

interface ModalContainerProps extends HTMLAttributes<HTMLDivElement> {
  modalTitle: string;
  handleSave?: (close: boolean) => void;
  isSaveAndContinueButtonVisible?: boolean;
  closeModal: () => void;
  isModalOpen: boolean;
  loading?: boolean;
  extraMenu?: ExtraMenuOptions
}

export function ModalContainer({
  children, 
  modalTitle, 
  handleSave, 
  isModalOpen, 
  closeModal, 
  isSaveAndContinueButtonVisible, 
  extraMenu, 
  loading,
  className, 
  ...props
}: Readonly<ModalContainerProps>) {
  const classes = twMerge(`
    px-7 py-3
  `, className)

  return (
    <>
      {isModalOpen && (
        <div>
          <div className='fixed top-0 left-0 z-10 flex w-full h-full'>
            <button className='fixed top-0 left-0 w-full h-full bg-black/40 cursor-default' onClick={closeModal}></button>
          
            <div className='flex flex-col z-10 bg-white rounded-xl m-auto'>
              <div className='relative flex items-center justify-between px-7 h-11 border-b border-zinc-400 font-bold text-xl'>
                  {modalTitle}

                  {extraMenu && (
                    <PanelOptions
                      itemId={extraMenu.itemId}
                      handleDeletar={extraMenu.handleDeletar}
                      handleEncerrar={extraMenu.handleEncerrar}
                      copyText={extraMenu.urlToCopyButton ?? ''}
                      showCopy={!!extraMenu.urlToCopyButton}
                      handleReactivate={extraMenu.handleReactivate}
                      isFinished={!!extraMenu.handleReactivate}
                    />
                  )}

                  <button onClick={closeModal} className='absolute right-3'>
                    <FaX className='w-5 h-5' />
                  </button>
                </div>

                <div className={classes} {...props}>
                  {children}
                </div>

                {handleSave && (
                  <div className='px-7 pb-7'>
                    <GenericButton loading={loading} disabled={loading} className='w-full bg-ivory-primary text-white font-bold' onClick={() => {
                      handleSave(true)
                    }}>
                      Salvar e Fechar
                    </GenericButton>

                    {isSaveAndContinueButtonVisible && (
                      <GenericButton loading={loading} disabled={loading}  className='w-full bg-ivory-primary text-white font-bold' onClick={() => {
                        handleSave(false)
                      }}>
                        Salvar e Continuar
                      </GenericButton>
                    )}
                  </div>
                )}
              </div>
          </div>
        </div>
      )}
    </>
  )
}