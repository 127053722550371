import React from "react"

import { ActivesCountWrapper, CountDiv } from "./styled";
import { t } from "i18next";
import { Company } from "../../../models/Company";
import { useUser } from "../../../contexts/User";

export interface ActivesCountProps {
    companyInfo?: Company;
}

export function ActivesCount({ companyInfo }: Readonly<ActivesCountProps>) {
    const { loadingUser } = useUser();
    return (
        <ActivesCountWrapper>
            <CountDiv active>
                {t("Usuários ativos")}: {!loadingUser && companyInfo?.quantidade_usuarios_ativos}
            </CountDiv>

            <CountDiv >
                {t("Usuários inativos")}: {!loadingUser && companyInfo?.quantidade_usuarios_inativos}
            </CountDiv>
        </ActivesCountWrapper>
    )
}