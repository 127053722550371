import styled from "styled-components";
import { InfoTitle } from "../styled";

export const InfoTitleExtra = styled(InfoTitle)`
    display: flex;
    justify-content: space-between;
    border: none;
`;

export const ExpandButton = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    padding: 0;
    font-weight: 500;
	color: #8099a7;
	font-size: 16px;

    .subtask-title {
        border-radius: 4px;
        padding: 2px 8px;
        width: fit-content;
    }
`;

export const TableHeader = styled.div`
    background-color: #C1D0D9;
    color: ${(props)=>props.theme.colors.text.titleText};
    font-weight: 500;
    height: 40px;
    width: 100%;
	padding: 0 23px;

    div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 100%;

        .subtask-bottom {
            grid-template-columns: 1fr 200px;
            gap: 16px;
            text-align: center;

            @media (max-width: 1200px) {
                grid-template-columns: 1fr 100px;
            }
        }

        .subtask-info {
            grid-template-columns: 1fr 1fr;
            gap: 8px;

            @media (max-width: 700px) {
                display: none;
            }
        }

        @media (max-width: 1200px) {
            grid-template-columns: 0.75fr 1.25fr;
        }
    }
`;

export const NewButtonDiv = styled.div`
    margin-top: 20px;
`;

export const NewButton = styled.button`
    width: fit-content;
    color: #fff;
    font-weight: 500;
    background: #23D8B6;
    border: none;
    border-radius: 4px;
    padding: 2px 8px;
`;

export const SubtasksContainer = styled.div`
    display: flex;
    flex-direction: column;
`;