import { AxiosResponse } from 'axios';
import HttpService from '../../../config/http';
import {  GetObjetivoProps, ObjetivoFitroResponse, ObjetivoProps, ObjetivoResponse } from './types';
import { t } from 'i18next';
import { CallbackType, DataType } from '../../types';

export default class ObjetivoService {
	private api: HttpService;
	constructor(props?: any) {
		this.api = new HttpService(props);
	}
	
	getObjetivosByTime = async ({
		timeId,
		filters, 
		page, 
		search,
		signal
	}: GetObjetivoProps) => {
		const response: AxiosResponse<DataType<ObjetivoResponse[]>> = await this.api
			.get(`api/v1/okrs/objetivos/?time=${timeId}&page=${page}&paginate=${page}&search=${search ?? ""}&${filters}`, {signal})
			.catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t('Erro ao tentar carregar os objetivos!'));
	};

	loadObjetivosSelect = async (search: string) => {
		const response: AxiosResponse<
			ObjetivoFitroResponse[]
		> = await this.api.get(`api/v1/okrs/objetivos/?search=${search ?? ""}`);

		if (response?.status !== 200) {
			if (typeof response?.data === typeof {}) {
				const objError = response?.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof typeof objError]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar buscar o ciclo!'));
			}
		}

		const arrayRenomeado = response?.data.map(objeto => ({
			label: objeto.nome,
			value: objeto.id
		}));	

		return {
			options: arrayRenomeado,
		};
	};

	getObjetivo = async (
		id: string,
		filters: string
	) => {
		const response: AxiosResponse<ObjetivoResponse> = await this.api
			.get(`api/v1/okrs/objetivos/${id}/?${filters}`)

		if (response.status === 200) {
			return response
		} else {
			throw new Error(t('Erro ao tentar encontrar o objetivo!'));
		}
	};

    postObjetivo = (
		params: ObjetivoProps,
		success: CallbackType<AxiosResponse<ObjetivoResponse>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.post(`api/v1/okrs/objetivos/`, params)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + '!');
						}
					} else {
						throw new Error(t('Erro ao tentar salvar o objetivo!'));
					}
				
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchObjetivo = (
		id: number,
		params: ObjetivoProps,
		success: CallbackType<AxiosResponse<ObjetivoResponse>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.patch(`api/v1/okrs/objetivos/${id}/`, params)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + '!');
						}
					} else {
						throw new Error(t('Erro ao tentar editar o objetivo!'));
					}
				
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteObjetivo = async (id: number) => {
		const response = await this.api
		.delete(`api/v1/okrs/objetivos/${id}/`).catch((error) =>{
			throw new Error(error)
		});
	
		if(response.status === 204) {
			return response.data
		}

		throw new Error(t('Erro ao tentar deletar o objetivo!'));
	}

	getTime = (
		id: number,
	) => {
		return this.api
			.get(`api/v1/projetos/${id}/?e_time_okr=true&minimal=true`)
	};
}
