import React, {
	Dispatch,
	FocusEvent,
	KeyboardEvent,
	SetStateAction,
} from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ColumnManaged, ColumnsManager } from '../../../../atoms/Kanban/types';
import { useUser } from '../../../../contexts/User';
import { t } from 'i18next';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LoaderPagination } from '../../../common/loaders/LoaderPagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCirclePlus } from 'react-icons/fa6';

export interface KanbanColumnProps {
	react_tour_id?: string;
	index: number;
	column: ColumnManaged<any>;
	handleDeleteColumn: (columnId: string) => void;
	children: React.ReactNode;
	isAdd: boolean;
	addObject?: AddObject;
	setAddObject?: Dispatch<SetStateAction<AddObject>>;
	handleAdd?: (columnId: string, name: string) => void;
	loading: boolean;
	style: React.CSSProperties;
	onScroll: React.UIEventHandler<HTMLDivElement>;
	columnsManager: ColumnsManager<any>;
	setColumnsManager: Dispatch<SetStateAction<ColumnsManager<any>>>;
	onChangeColumnName: (columnId: string, title: string) => void;
	editingTitle: {
		isEditing: boolean;
		columnId: string;
	};
	setEditingTitle: Dispatch<
		SetStateAction<{
			isEditing: boolean;
			columnId: string;
		}>
	>;
	handlePagination: (page: number) => void;
}

export interface AddObject {
	isAdding: boolean;
	columnId: string;
}

export default function KanbanColumn({
	loading,
	handlePagination,
	editingTitle,
	setEditingTitle,
	columnsManager,
	onChangeColumnName,
	onScroll,
	style,
	handleAdd,
	react_tour_id,
	addObject,
	setAddObject,
	isAdd,
	index,
	column,
	handleDeleteColumn,
	children,
}: Readonly<KanbanColumnProps>) {
	const showLoader =
		(column?.totalPages === column.page ||
			!column.doneTotalPages ||
			column.doneTotalPages === column.page) &&
		!loading;

	const onBlurColumnName = (e: FocusEvent<HTMLInputElement, Element>) => {
		setEditingTitle({
			isEditing: false,
			columnId: '',
		});
		e.target.value = '';
	};

	const onKeyDownColumnName = (e: KeyboardEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;

		if (e.key === 'Enter') {
			onChangeColumnName(column.id, target.value);
			target.value = '';
		}
	};

	const onBlurAddTask = () => {
		if (setAddObject) {
			setAddObject({
				isAdding: false,
				columnId: '',
			});
		}
	};

	const onKeyDownAddTask = (e: KeyboardEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;

		if (e.key === 'Enter' && handleAdd) {
			handleAdd(column.id, target.value);
			target.value = '';
		}
	};

	const handleAddTask = () => {
		if (setAddObject) {
			setAddObject({
				isAdding: true,
				columnId: column.id,
			});
		}
	};

	const { userDetails } = useUser();
	const canEdit = userDetails?.acesso_cod === 'a';
	const countTasks = column.items.length;
	return (
			<div
				className="kanban-content box-border inline-block gap-4  h-full scroll-m-2 align-top whitespace-nowrap  "
				id={react_tour_id} 
				style={style}
				onScroll={onScroll}
			>
				<Draggable draggableId={column.id} index={index}>
					{(provided) => {
						return (
							<div
								className="flex max-h-full border-r border-solid pr-4  border-gray-200  whitespace-normal box-border flex-col w-full h-full max-w-[18rem] min-w-[18.75rem] gap-3"
								{...provided.draggableProps}
								ref={provided.innerRef}
							>
								<div
									className="flex justify-between items-center  gap-2  rounded-md bg-ivory-primary bg-opacity-20 text-ivory-primary text-base font-black px-4 py-3"
									{...provided.dragHandleProps}
								>
									<div className="gap-4 flex flex-row items-center">
										{editingTitle.columnId === column.id &&
										editingTitle.isEditing ? (
											<input
												defaultValue={column.nome}
												type="text"
												onBlur={onBlurColumnName}
												autoFocus
												onFocus={(e) => e.target.select()}
												onKeyDown={onKeyDownColumnName}
												className='px-1 py-0 ring-0 text-ivory-primary focus:ring-0 border-ivory-primary border-solid border'
											/>
										) : (
											<button
												style={{
													backgroundColor: 'transparent',
													border: 'none',
													padding: 0,
												}}
												type="button"
												onClick={() =>
													setEditingTitle({
														columnId: column.id,
														isEditing: true,
													})
												}
											>
												<h3>{column.nome}</h3>
											</button>
										)}
										<div className="px-1 h-5 min-w-[1.25rem] flex items-center justify-center rounded-md bg-ivory-primary font-black text-xs text-white">
											{countTasks}
										</div>
									</div>
										<button
											className={`${index > 0 && canEdit ? 'opacity-100' : 'opacity-0 cursor-grab pointer-events-none'}actions-menu-button w-min border-none bg-transparent p-0`}
											type="button"
											onClick={() => {
												if(index > 0 && canEdit) {
													handleDeleteColumn(column.id)
												}
											} }
											title={ index > 0 && canEdit ? t('Deletar').toString() : undefined}
											style={{
												cursor:
													columnsManager.columns[column.id].items.length > 0
														? 'no-drop'
														: 'pointer',
											}}
										>
											<FontAwesomeIcon
												className="text-ivory-primary text-sm transition-all duration-200"
												icon={faTrash}
											/>
										</button>
								</div>
								<Droppable direction="vertical" droppableId={column.id}>
									{(provided, snapshot) => {
										return (
											<div
												onScroll={onScroll}
												{...provided.droppableProps}
												ref={provided.innerRef}
												className={`w-full ${
													snapshot.isDraggingOver
														? 'bg-blue-300'
														: 'bg-transparent'
												} flex flex-auto flex-col overflow-x-hidden overflow-y-auto gap-3`}
											>
												{isAdd && (
													<div className="flex flex-col gap-2 w-full  text-base">
														{addObject?.isAdding &&
															addObject?.columnId === column.id && (
																<input
																	type="text"
																	autoComplete="off"
																	name="add-task"
																	title={t('Adicionar tarefa input').toString()}
																	onBlur={onBlurAddTask}
																	autoFocus
																	onKeyDown={onKeyDownAddTask}
																	className="focus:border focus:border-solid focus:border-ivory-primary focus:outline-none focus:rounded"
																/>
															)}
														<button
															className="w-full px-4 flex items-center justify-center gap-2  bg-ivory-primary text-white font-black h-10 rounded border-none cursor-pointer text-center"
															type="button"
															onClick={handleAddTask}
														>
															<FaCirclePlus size={16} />
															{t('Tarefa')}
														</button>
													</div>
												)}
												{children}

												<LoaderPagination
													onClick={() => {
														handlePagination(column.page + 1);
													}}
													loading={loading}
													showLoader={showLoader}
													type="button"
												/>
												{provided.placeholder}
											</div>
										);
									}}
								</Droppable>
							</div>
						);
					}}
				</Draggable>
			</div>
	);
}
