import HttpService from '../config/http';

export default class CadastroService {
	constructor(props) {
		this.api = new HttpService(props);
	}

	salvarInformacoes = data => {
		localStorage.setItem('planustk', data.access);
		this.api.loadToken(data.access);
		// this.getLoggedUserData();
		window.location.href = '/';
	};

	postCadastro = (passForm, success, errorCallback) => {
		this.api
			.post(`api/v1/contas-planus/cadastro/`, passForm)
			.then(result => {
				if (result.status === 201) {
					this.salvarInformacoes(result.data);
					success(result);
				} else {
					console.log(result);
					if (typeof result?.data === typeof {}) {
						const objError = result.data;
						for (var key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error('Erro ao tentar realizar cadastro!');
					}
				}
			})
			.catch(error => errorCallback(error));
	};
}
