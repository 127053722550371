import HttpService from '../config/http';

export default class AuthService {
	constructor(props) {
		this.api = new HttpService(props);
	}

	salvarInformacoes = (data) => {
		const access = data.access;
		const userImage = JSON.stringify(data.user_details.foto_perfil);
		localStorage.setItem('planustk', access);
		localStorage.setItem(
			'userFoto',
			userImage,
		);
		this.api.loadToken(data.access);

		// this.getLoggedUserData();
	};

	getPlanusToken = () => localStorage.getItem('planustk');

	login = (userForm, success, errorCallback) => {
		this.api
			.post('api/v1/token/', userForm)
			.then(result => {
				if (result.status === 200) {
					this.salvarInformacoes(result.data);
					success(result);
				} else {
					// if (result.response.status === 401) {
					// 	throw result.response.data.detail;
					// } else {
					// 	throw 'Erro ao tentar enviar email!';
					// }
					if (result.response.status) {
						switch (result.response.status) {
							case 401:
								throw new Error(result.response.data.detail);
							case 400:
								throw new Error('Email invalido ou usuário inexistente!');
							case 404:
								throw new Error('Erro ao tentar fazer o login, tente novamente mais tarde!');
							case 500:
								throw new Error('Erro no servidor, tente novamente mais tarde!');
							default:
								throw new Error('Erro ao tentar fazer o login, tente novamente mais tarde!');
						}
					}
				}
			})
			.catch(error => errorCallback(error));
	};

	esqueciSenha = (userForm, success, errorCallback) => {
		this.api
			.post('api/v1/contas-planus/esqueci-minha-senha/', userForm)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (result.response.status) {
						switch (result.response.status) {
							case 400:
								throw new Error('Email invalido ou usuário inexistente!');
							case 500:
								throw new Error('Erro no servidor!');
							default:
								throw new Error('Erro ao enviar email!');
						}
					}
				}
			})
			.catch(error => errorCallback(error));
	};

	redefinirSenha = (userForm, success, errorCallback) => {
		this.api
			.post('api/v1/contas-planus/esqueci-minha-senha/confirm/', userForm)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (result.response.status) {
						switch (result.response.status) {
							case 400:
								throw new Error('Senha inválida, não pode conter apenas números ou senha é muito simples!');
							case 401:
								throw new Error('O código de acesso esta errado');
							case 500:
								throw new Error('Erro no servidor!');
							default:
								throw new Error('Erro ao enviar email!');
						}
					}
				}
			})
			.catch(error => errorCallback(error));
	};

	invite = (form, query_invite, success, errorCallback) => {
		this.api
			.post(`api/v1/contas-planus/invitecomfirm/${query_invite}/`, form)
			.then(result => {
				if (result.status === 201) {
					this.salvarInformacoes(result.data);
					success(result);
				} else {
					switch (result.response.status) {
						case 400:
							throw new Error('Erro ao tentar criar o usuário!');
						case 404:
							if (typeof result.response.data.detail !== 'undefined') {
								throw new Error(result.response.data.detail);
							} else {
								throw new Error('Erro ao tentar criar o usuário!');
							}
						default:
							throw result.response.data.detail;
					}
				}
			})
			.catch(error => {
				errorCallback(error);
			});
	};

	logout = (resetUser) => {
		localStorage.removeItem('planustk');
		localStorage.removeItem('ac_cod');
		localStorage.removeItem('ac_text');
		localStorage.removeItem('userProfile');
		localStorage.removeItem('userDetails');
		resetUser();
	};

	getCurrentUser2 = async () => {
		try {
			const response = await this.api.get(`api/v1/contas-planus/get-profile`)
			return response.data;
		}catch(e) {
			console.log("entrou no catch")
			return null;
		}
	}	

	// verifyGoogleLogin = function() {
	// 	let api = new HttpService();
	// 	//Verificar login do googleId
	// 	api.get('crud/contas/google-login-status/').then(response => {
	// 		if (response.status == 200) {
	// 			switch (response.data.tipo) {
	// 				case 'convite':
	// 					window.location.href = response.data.convite;
	// 					break;
	// 				case 'empresa':
	// 					window.location.href =
	// 						'http://' + window.location.host + '/cadastro?googleauth=';
	// 					break;
	// 				case 'login':
	// 					break;
	// 				default:
	// 					this.logout();
	// 					break;
	// 			}
	// 		}
	// 	});
	// };
}
