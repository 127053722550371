import React from 'react';
import Icon from '../../../../../../components/common/Icons/icon';
import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './style.css';
import { Preview } from './style.js';

import { LoaderPagination } from '../../../../../../components/common/loaders/LoaderPagination';
import { IconFileType } from '../../../../../../components/common/IconFileType/IconFileType';
import { useParams } from 'react-router-dom';

const FileListGet = ({ files, onDelete, loadingFiles }) => {
	const verifyFileType = url => {
		return url
			.split(/[#?]/)[0]
			.split('.')
			.pop()
			.trim();
	};
	const verifyFileName = url => {
		return url
			.split(/[#?]/)[0]
			.split('/')
			.pop();
	};

	const { id } = useParams();
    const projectId = parseInt(id);

	return (
		<>
			{loadingFiles && files?.length === 0 ? (
				<LoaderPagination showLoader={false} loading={true} />
			) : (
				<ul className="get-list-containter">
					{files.map(uploadedFile => (
						uploadedFile.projeto === projectId && <>
						<Preview
							isImage={verifyFileType(uploadedFile.documento)}
							key={uploadedFile.id}
							uploadedFile={uploadedFile}
						>
							<span
								style={{
									zIndex: 10,
									background: '#00000073',
									padding: '10px 15px',
									borderRadius: '5px',
								}}
							>
								<div
									className="get-list-text"
									style={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											fontSize: '14px',
											color: '#fff',
											fontWeight: 'bold',
										}}
									>
										{verifyFileName(uploadedFile.documento)}
									</span>

									<div>
										<span
											style={{
												fontSize: '20px',
												color: '#cecfcfb9',
											}}
										>
											<IconFileType
												fileType={verifyFileType(uploadedFile.documento)}
											/>
										</span>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										fontSize: '13px',
										color: '#d7d7d7',
										fontWeight: '500',
									}}
								>
									{verifyFileName(uploadedFile.pessoa.nome)}
									{' - '}
									{new Date(uploadedFile.created_at).toLocaleDateString()}
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-end',
										marginTop: '40px',
									}}
								>
									<span
										style={{
											color: '#d7d7d7',
											fontSize: '14px',
											fontWeight: '600',
										}}
									>
										999 kb
									</span>

									<div>
										<span
											style={{
												cursor: 'pointer',
												background: '#fff',
												marginRight: '8px',
												borderRadius: '5px',
												padding: '3px 5px',
											}}
										>
											<a
												href={uploadedFile.documento}
												target="_blank"
												rel="noopener noreferrer"
												style={{
													color: '#536a77',
												}}
											>
												<Icon icon={faDownload} />
											</a>
										</span>
										<span
											onClick={() => onDelete(uploadedFile.id)}
											style={{
												cursor: 'pointer',
												background: '#fff',
												borderRadius: '5px',
												padding: '3px 5px',
											}}
										>
											<Icon icon={faTrashAlt} />
										</span>
									</div>
								</div>
							</span>
						</Preview>
					</>
					))}
				</ul>
			)}
		</>
	);
};

export default FileListGet;
