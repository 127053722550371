import React, { useEffect, useState } from 'react';
import HeaderLink from '../../components/common/HeaderLink';
import { PageInsideContainer } from '../../components/containers/PageInsideContainer';
import { TimeOKRTable } from './TimeOKRTable';
import { ModalCiclo } from './ModalCiclo';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/User';
import { ModalObjetivo } from './ModalObjetivo';
import { ModalTime } from './ModalTime';
import { useAtom, useSetAtom } from 'jotai';
import {
	empresaProgressoAtom,
	filtrosTimesOkrsAtom,
	keyresultsManagerAtom,
	objetivoModalAtom,
	objetivosManagerAtom,
	selectedHeaderOkrsAtom,
	timeModalAtom,
	timesOkrsAtom,
} from '../../atoms/Okrs';
import TimeOkrsService from '../../services/providers/okrs/times';
import ObjetivoService from '../../services/providers/okrs/objetivo';
import KeyResultService from '../../services/providers/okrs/keyresult';
import { TimesOkrsFilter } from './Filtro';
import { LoaderContainer } from '../../components/common/loaders/styled';
import { LoaderCircle } from '../../components/common/loaders/LoaderCircle';
import CicloService from '../../services/providers/okrs/ciclo';
import { toast } from 'react-toastify';
import HeaderButton from '../../components/planus2/Buttons/HeaderButton';
import { FaBullseye, FaUsers } from 'react-icons/fa';
import { FaRotate } from 'react-icons/fa6';

const OKRs = () => {
	const { t } = useTranslation();
	const { userDetails } = useUser();
	const [loadingCurrentCiclo, setLoadingCurrentCiclo] = useState(true);
	const [isCicloModalVisible, setIsCicloModalVisible] = useState(false);

	const [timeModalState, setTimeModalState] = useAtom(timeModalAtom);
	const [objetivoModalState, setObjetivoModalState] =
		useAtom(objetivoModalAtom);

	const [timesOkrsFilters, setTimesFilters] = useAtom(filtrosTimesOkrsAtom);

	const [, setEmpresaProgresso] = useAtom(empresaProgressoAtom);
	const [timesOkrs, setTimesOkrs] = useAtom(timesOkrsAtom);
	const [objetivosManager, setObjetivosManager] = useAtom(objetivosManagerAtom);
	const [keyResults, setKeyResults] = useAtom(keyresultsManagerAtom);

	const cicloService = new CicloService();
	const timesOkrsService = new TimeOkrsService();
	const objetivosService = new ObjetivoService();
	const keyResultService = new KeyResultService();

	const getCompanyProgress = async () => {
		const filters = `ciclo=${timesOkrsFilters.ciclo?.value ?? ''}`;

		await timesOkrsService
			.getProgressoEmpresa({
				filters,
			})
			.then((result) => {
				setEmpresaProgresso(result);
			});
	};

	const getTimesPagination = async (page: number) => {
		if (page > (timesOkrs.totalPages ?? 1)) {
			return;
		}

		const filters = `projeto_id__in=${timesOkrsFilters.times.map(
			(item) => item.value,
		)}&ciclo=${timesOkrsFilters.ciclo?.value ?? ''}`;
		const search = timesOkrsFilters.search;

		if (page === 1) {
			getCompanyProgress();
		}

		const result = await timesOkrsService
			.getTimesOkrs({
				page,
				filters,
				search,
			});

			if(result) {
				if (page === 1) {
					setTimesOkrs({
							currentPage: page,
							totalPages: result.total_pages,
							timesOkrs: [...result.results],
						});
				} else {
					setTimesOkrs((prev) => {
						const newResultsIds = result.results.map((item) => item.id);
						const prevResults =
							prev.timesOkrs?.filter(
								(item) => !newResultsIds.includes(item.id),
							) ?? [];

						return {
							currentPage: page,
							totalPages: result.total_pages,
							timesOkrs: [...prevResults, ...result.results],
						};
					});
				}

			}
	};

	const getObjetivosPagination = async (page: number, timeId: number) => {
		if (page > (objetivosManager.objetivosManager[timeId]?.totalPages ?? 1)) {
			return;
		}

		const filters = `ciclo=${timesOkrsFilters.ciclo?.value ?? ''}`;

		const result = await objetivosService
			.getObjetivosByTime({
				timeId: timeId,
				page,
				filters,
			});
			if(result) {
				if (page === 1) {
					setObjetivosManager((prev) => {
						return {
							objetivosManager: {
								...prev.objetivosManager,
								[timeId]: {
									objetivos: [...result.results],
									currentPage: page,
									totalPages: result.total_pages,
								},
							},
						};
					});
				} else {
					setObjetivosManager((prev) => {
						const newResultsIds = result.results.map((item) => item.id);
						const prevResults =
							prev.objetivosManager[timeId]?.objetivos?.filter(
								(item) => !newResultsIds.includes(item.id),
							) ?? [];

						return {
							objetivosManager: {
								...prev.objetivosManager,
								[timeId]: {
									objetivos: [...prevResults, ...result.results],
									currentPage: page,
									totalPages: result.total_pages,
								},
							},
						};
					});
				}

			}
	};

	const getKeyResultsPagination = async (page: number, objetivoId: number) => {
		if (page > (keyResults.keyresultsManager[objetivoId]?.totalPages ?? 1)) {
			return;
		}

		const result = await keyResultService
			.getKeyResultsByObjetivo({
				objetivoId: objetivoId,
				page,
			});
			if(result) {
				if (page === 1) {
					setKeyResults((prev) => {
						return {
							keyresultsManager: {
								...prev.keyresultsManager,
								[objetivoId]: {
									currentPage: page,
									keyresults: [...result.results],
									totalPages: result.total_pages,
								},
							},
						};
					});
				} else {
					setKeyResults((prev) => {
						const newResultsIds = result.results.map((item) => item.id);
						const prevResults =
							prev.keyresultsManager[objetivoId]?.keyresults?.filter(
								(item) => !newResultsIds.includes(item.id),
							) ?? [];

						return {
							keyresultsManager: {
								...prev.keyresultsManager,
								[objetivoId]: {
									currentPage: page,
									keyresults: [...prevResults, ...result.results],
									totalPages: result.total_pages,
								},
							},
						};
					});
				}

			}
	};

	const refreshProgresso = async ({
		timeId,
		objetivoId,
	}: {
		timeId: number;
		objetivoId?: number;
	}) => {
		const filters = `ciclo=${timesOkrsFilters.ciclo?.value ?? ''}`;

		const [resultTime, resultObjetivo] = await Promise.all([
			timesOkrsService.getTimeOkrsByID({ id: timeId, filters }),
			objetivoId
				? objetivosService.getObjetivo(objetivoId.toString(), filters)
				: undefined,
			getCompanyProgress(),
		]);

		setTimesOkrs((prev) => {
			const tempTimes = [...prev.timesOkrs];
			const timeIndex = tempTimes.findIndex((item) => item.id === timeId);

			if (timeIndex >= 0) {
				const [removed] = tempTimes.splice(timeIndex, 1);
				tempTimes.splice(timeIndex, 0, {
					...removed,
					...resultTime,
				});
			}

			return {
				...prev,
				timesOkrs: [...tempTimes],
			};
		});

		if (resultObjetivo) {
			setObjetivosManager((prev) => {
				const tempObjetivos = [
					...(prev.objetivosManager?.[timeId]?.objetivos ?? []),
				];
				const objetivoIndex = tempObjetivos.findIndex(
					(item) => item.id === objetivoId,
				);

				if (objetivoIndex >= 0) {
					const [removed] = tempObjetivos.splice(objetivoIndex, 1);
					tempObjetivos.splice(objetivoIndex, 0, {
						...removed,
						...resultObjetivo.data,
					});
				}

				return {
					objetivosManager: {
						...prev.objetivosManager,
						[timeId]: {
							...prev.objetivosManager[timeId],
							objetivos: tempObjetivos,
						},
					},
				};
			});
		}
	};

	const timeModal = () => {
		setTimeModalState({
			editing: false,
			selectedItem: undefined,
			isModalOpen: true,
		});
	};

	const closeTimeModal = () => {
		setTimeModalState({
			editing: false,
			selectedItem: undefined,
			isModalOpen: false,
		});
	};

	const cicloModal = () => {
		setIsCicloModalVisible(true);
	};

	const objetivoModal = () => {
		setObjetivoModalState({
			editing: false,
			selectedItem: undefined,
			isModalOpen: true,
		});
	};

	const closeObjetivoModal = () => {
		setObjetivoModalState({
			editing: false,
			selectedItem: undefined,
			isModalOpen: false,
		});
	};

	const verificarPerfilCiclo = () => {
		return userDetails?.acesso_cod === 'a';
	};

	const _getCurrentCiclo = async () => {
		try {
			const ciclosResponse = await cicloService.getCicloAtual();

			if (ciclosResponse) {
				const ciclo = {
					label: ciclosResponse.nome,
					value: ciclosResponse.id,
					extra: {
						data_inicial: ciclosResponse.data_inicial,
						data_final: ciclosResponse.data_final,
					},
				};

				setTimesFilters((prev) => ({
					...prev,
					ciclo,
				}));
			}
		} catch {
			toast.error('Erro ao tentar carregar ciclo atual');
		}

		setLoadingCurrentCiclo(false);
	};

	useEffect(() => {
		_getCurrentCiclo();
	}, []);

	const setSelectedHeader = useSetAtom(selectedHeaderOkrsAtom);

	useEffect(() => {
		setSelectedHeader('times');
	}, []);

	return (
		<>
			<HeaderLink type="okrs"></HeaderLink>

			<PageInsideContainer>
				<>
					<div className="flex flex-row gap-8">
						<TimesOkrsFilter getTimesPagination={getTimesPagination} />
						{verificarPerfilCiclo() && (
							<HeaderButton onClick={timeModal}>
								<FaUsers />
								{t('Time')}
							</HeaderButton>
						)}
						{verificarPerfilCiclo() && (
							<HeaderButton onClick={cicloModal}>
								<FaRotate />
								{t('Ciclo')}
							</HeaderButton>
						)}
						<HeaderButton onClick={objetivoModal}>
							<FaBullseye />
							{t('Objetivo')}
						</HeaderButton>
					</div>
					{loadingCurrentCiclo ? (
						<LoaderContainer>
							<LoaderCircle />
						</LoaderContainer>
					) : (
						<TimeOKRTable
							getTimesPagination={getTimesPagination}
							getObjetivosPagination={getObjetivosPagination}
							getKeyResultsPagination={getKeyResultsPagination}
							refreshProgresso={refreshProgresso}
						/>
					)}
				</>
			</PageInsideContainer>

			{isCicloModalVisible && (
				<ModalCiclo
					setModalShow={setIsCicloModalVisible}
					getCiclosPagination={async () => {}}
				/>
			)}
			{objetivoModalState.isModalOpen && (
				<ModalObjetivo
					setModalShow={closeObjetivoModal}
					editingObjetivo={objetivoModalState.editing}
					objetivo={objetivoModalState.selectedItem}
					getObjetivosPagination={getObjetivosPagination}
					refreshProgresso={refreshProgresso}
				/>
			)}
			{timeModalState.isModalOpen && (
				<ModalTime
					setModalShow={closeTimeModal}
					editingTime={timeModalState.editing}
					time={timeModalState.selectedItem}
					getTimesPagination={getTimesPagination}
				/>
			)}
		</>
	);
};

export default OKRs;
