import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCalendarioRegistros } from '../../../contexts/CalendarioRegistros';
import { useUser } from '../../../contexts/User';
import ReactGA from 'react-ga';
import { t } from 'i18next';
import { DiasDaSemanaProps } from '../types';

interface WeekNavigationProps {
  diasDaSemana: DiasDaSemanaProps[];
  dataInicial: string;
  dataFinal: string;
  weekNavigation: (opcao: string, diaAux?: string) => void;
}

export function WeekNavigation({
	diasDaSemana,
	dataInicial,
	dataFinal,
	weekNavigation
}: WeekNavigationProps) {
	const [isToday, setIsToday] = useState(false);
	const {savedDataInicial, calendarioLoading} = useCalendarioRegistros();
	const [diaAlvo, setDiaAlvo] = useState(moment(savedDataInicial));
	const { loadingUser } = useUser();
	const [periodoString, setPeriodoString] = useState('');

	const handleResetInitialDate = () => {
		weekNavigation("semana", moment().toString())
		setDiaAlvo(moment());
	};

  const handlePeriodoString = (dataInicial: string, dataFinal: string) => {
		if (moment().isBetween(dataInicial, dataFinal, undefined, '[]')) {
			setPeriodoString(t("Esta Semana"));
			setIsToday(true);
		} else {
			setIsToday(false);

			if (
				moment()
					.subtract(7, 'days')
					.isBetween(dataInicial, dataFinal, undefined, '[]')
			) {
				setPeriodoString(t("Semana Passada"));
			} else {
				setPeriodoString(
					`${moment(dataInicial).format('ll')} - ${moment(dataFinal).format(
						'll',
					)}`,
				);
			}
		}
	};

  useEffect(() => {
		handlePeriodoString(dataInicial, dataFinal);
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [diasDaSemana, dataInicial, dataFinal]);

  return (
    <div className="calendario-home-weekNavigation">
      { !isToday &&
        <button
          className="calendario-btn-today"
          onClick={handleResetInitialDate}
          disabled={isToday}
        >
          Hoje
        </button>
      }
      <div style={{ display: 'flex' }}>
        <FontAwesomeIcon
          onClick={() => {
            if (!calendarioLoading && !loadingUser) {
              weekNavigation('prev', moment(diaAlvo).subtract(7, 'days').toString());
              setDiaAlvo(moment(diaAlvo).subtract(7, 'days'));
            }
          }}
          icon={faAngleLeft}
          className="calendario-home-icon"
        />
        <p className="calendario-home-week-text"> {periodoString} </p>{' '}
        <FontAwesomeIcon
          onClick={() => {
            if (!calendarioLoading && !loadingUser) {
              weekNavigation('next', moment(diaAlvo).add(7, 'days').toString());
              setDiaAlvo(moment(diaAlvo).add(7, 'days'));
            }
          }}
          icon={faAngleRight}
          className="calendario-home-icon"
        />
      </div>{' '}
    </div>
  )
  
}