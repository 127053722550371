import { t } from 'i18next';
import * as Yup from 'yup';

export const StopwatchSchema = Yup.object().shape({
	projeto: Yup.string(t('Campo obrigatório.')).required(t('Campo obrigatório.')),
	tarefa: Yup.string(t('Campo obrigatório.')).required(t('Campo obrigatório.')),
});

export const HorasSchema = Yup.object().shape({
	projeto: Yup.string(t('Campo obrigatório.')).required(t('Campo obrigatório.')),
	tarefa: Yup.string(t('Campo obrigatório.')).required(t('Campo obrigatório.')),
	duracao: Yup.string().required(t('Campo obrigatório.')),
	data: Yup.string().required(t('Campo obrigatório.')),
});

export const OcioSchema = Yup.object().shape({
	duracao: Yup.string().required(t('Campo obrigatório.')),
	data: Yup.string().required(t('Campo obrigatório.')),
})