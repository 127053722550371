import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState} from 'react';
import { UserDetails } from '../../models/UserDetails';
import ProfileDefault from '../../assets/images/profile.png'
import AuthService from '../../services/security/auth';
import { changeLang } from '../../i18n';

export interface UserContextInitialType {
    userDetails: UserDetails | null;
    getUserDetails: () => void;
    setUserDetails: Dispatch<SetStateAction<UserDetails | null>>;
    loadingUser: boolean;
    setLoadingUser: Dispatch<SetStateAction<boolean>>;
}

interface UserProviderProps {
    children: ReactNode;
}

 const initialValues: UserContextInitialType = {
    userDetails: null,
    getUserDetails: () => {},
    setUserDetails: () => {},
    loadingUser: true,
    setLoadingUser: () => {}
}

 const UserContext = createContext(initialValues);

const UserProvider = ({ children }: UserProviderProps ) => {
    const [userDetails, setUserDetails] = useState<UserDetails | null>(initialValues.userDetails);
    const [loadingUser, setLoadingUser] = useState(initialValues.loadingUser);

    const resetUser = () => {
        setUserDetails(null);
    }
 
    const getUserDetails = () => {
        const authService = new AuthService();
        setLoadingUser(true);
        authService.getCurrentUser2().then((response: UserDetails) => {
            if(response?.id_pessoa) {
                changeLang(response?.idioma);

                setUserDetails({
                    id_pessoa: response.id_pessoa,
                    username: response.username,
                    apelido: response.apelido,
                    funcao: response.funcao,
                    foto_perfil:
                        response.foto_perfil === null
                            ? ProfileDefault
                            : response.foto_perfil,
                    acesso_cod: response.acesso_cod,
                    has_finished_tour: response.has_finished_tour,
                    idioma: response?.idioma
                });
            }
            setLoadingUser(false);
        }).catch(() => {
            authService.logout(resetUser);
        })
    }

    const userValue = useMemo(
        () => ({
            userDetails,
            getUserDetails,
            setUserDetails,
            loadingUser,
            setLoadingUser
        }),
        [
            userDetails,
            getUserDetails,
            setUserDetails,
            loadingUser,
            setLoadingUser
        ],
    )

    return (
        <UserContext.Provider value={userValue}>
            {children}
        </UserContext.Provider>
    )
}


const useUser = () => {
    const context = useContext(UserContext);
    return context;
}

export {initialValues, UserContext, UserProvider, useUser}