import React, { ReactElement, ButtonHTMLAttributes } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Container } from './styled';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	text: string;
	icon?: IconProp;
	btnId?: string;
}

const Button = ({
	text,
	icon,
	btnId,
	...rest
}: ButtonProps): ReactElement => (
	<Container
		id={btnId}
		{...rest}
	>
		{icon && <FontAwesomeIcon style={{ marginRight: '10px' }} icon={icon} />}
		{text}
	</Container>
);

export default Button;
