import React, { useEffect, useState, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Circle from '../../../assets/svgs/circle';
import ExpandIcon from '../../../assets/svgs/expand';

import { Chart } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	BarController
  } from 'chart.js';
import './style.css';
import { useCalendarioRegistros } from '../../../contexts/CalendarioRegistros';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	BarController,
	Tooltip
  );
const BarChart = ({ diasDaSemana, ...rest }) => {
	const {t} = useTranslation();
	const { calendarioRegistros } = useCalendarioRegistros();
	const [data, setData] = useState({
		labels: [t("Dom"), t("Seg"), t("Ter"), t("Qua"), t("Qui"), t("Sex"), t("Sáb")],
		datasets: [
			{
				data: [],
				backgroundColor: [
					'#55d8fe80',
					'#23d9b780',
					'#f26d8580',
					'#a3a0fb80',
					'#d7f20580',
					'#42617280',
					'#5ee21a80',
				],
				borderColor: 'rgb(255, 99, 132)',
			},
		],
	});


	useEffect(() => {
		criaObjGrafico();
	}, [calendarioRegistros, diasDaSemana]);

	const criaObjGrafico = () => {
		const tarefas = [];
		const dataValue = {
			labels: [t("Dom"), t("Seg"), t("Ter"), t("Qua"), t("Qui"), t("Sex"), t("Sáb")],
			datasets: [
				{
					data: [0, 0, 0, 0, 0, 0, 0],
					backgroundColor: [
						'#55d8fe80',
						'#23d9b780',
						'#f26d8580',
						'#a3a0fb80',
						'#d7f20580',
						'#42617280',
						'#5ee21a80',
					],
					borderWidth: 1,
					borderColor: '#9e9e9e',
				},
			],
		};

		calendarioRegistros.forEach(dado => {
			if (!tarefas.find(item => item.data === dado.data)) {
				tarefas.push({
					data: dado.data,
					tarefaRegistros: 0,
				});
			}
		});

		calendarioRegistros.forEach(dado => {
			tarefas.forEach(item => {
				if (item.data === dado.data) {
					let a = dado.duracao.split(':');
					let minutes = +a[0] * 60 + +a[1];
					item.tarefaRegistros += minutes;
				}
			});
		});
		diasDaSemana.forEach((dia, index) => {
			tarefas.forEach(item => {
				if (dia.data === item.data) {
					dataValue.datasets[0].data[index] = item.tarefaRegistros;
				}
			});
		});

		console.log(dataValue)
		setData(dataValue);
	};

	const options = {
		data: data,
		scales: {
			y: 
				{

					ticks: {
						maxTicksLimit: 8,
						beginAtZero: true,

						callback: function(value, index, values) {
							return parseFloat((value / 60).toFixed(1));
						},
					},
				},
			
		},
		plugins: {
			tooltip: {
			callbacks: {
				label: function(context) {
					let label = context.raw;
					let horas = `00:00`;
					if (label !== 0) {
						let h = Math.floor(label / 60);
						let m = label % 60;
						h = h < 10 ? '0' + h : h;
						m = m < 10 ? '0' + m : m;
						horas = `${h}:${m}`;
					}
					return horas + t(" horas").toString();
				},
			},
		}, 
		legend: {
			display: false
		}
	},
		maintainAspectRatio: false,
		responsive: true
	};

	// function convertMinsToHrsMins(mins) {
	// 	let h = Math.floor(mins / 60);
	// 	let m = mins % 60;
	// 	h = h < 10 ? '0' + h : h;
	// 	m = m < 10 ? '0' + m : m;
	// 	return `${h}:${m}`;
	// }

	return (
		<div className="grafico-bg">
			<div className="grafico-header-container">
				<span className="grafico-title">{t("Estimativa de horas por dia")}</span>
				<span className="grafico-link-horas">
					<Link to="/registros/horas?urlBack=/home" className="flex items-center gap-0">
						{t("Ir para horas")}
						<span className="grafico-link-icon">
							{/* <FontAwesomeIcon
								icon={faExpand}
								className="seletor-responsavel-pessoa-icon-remove"
							/> */}
							<ExpandIcon />
						</span>
					</Link>
				</span>
			</div>
			<div className="grafico-horas-extras" style={{ display: 'none' }}>
				<span>+2,30</span>
			</div>
			<div style={{ height: '320px', padding: '10px' }}>
				<Chart type='bar' data={data} width={'auto'} options={options} />
			</div>
			<div className="bar-legend-container">
				{data.datasets[0].data.map((item, index) => (
					<>
						{item !== 0 && (
							<div style={{ margin: '5px' }} key={index}>
								<span
									className="grafico-circle-legend"
									style={{ fill: data.datasets[0].backgroundColor[index] }}
								>
									<Circle />
								</span>

								<span className="grafico-legend-text">
									{data.labels[index]}:
								</span>
								<span
									style={{ marginLeft: '2px' }}
									className="grafico-legend-text"
								>
									{item}
								</span>
							</div>
						)}
					</>
				))}
			</div>
		</div>
	);
};

export default memo(BarChart);
