import React from 'react';

import './style.css';
import BannerLogin from '../components/BannerLogin/index.js';

import '../../../i18n';

export const PoliticasDeUsoPTBR = () => {
	return (
			<div className="politica-limiter">
				<div>
					<div style={{ width: '25vw', margin: 'auto' }}>
						<BannerLogin
							titulo="POLÍTICA DE USO E PRIVACIDADE"
							distanceBetweenLogo={'20'}
							logoWidth={'17vw'}
						/>
					</div>
					<h6 className="politica-section">SEÇÃO 1 - INFORMAÇÕES GERAIS</h6>
					<p className="politica-text">
						A presente Política de Privacidade contém informações sobre coleta,
						uso, armazenamento, tratamento e proteção dos dados pessoais dos
						usuários e visitantes do aplicativo Planus™ ou/e site app.planus.io
						com a finalidade de demonstrar absoluta transparência quanto ao
						assunto e esclarecer a todos interessados sobre os tipos de dados
						que são coletados, os motivos da coleta e a forma como os usuários
						podem gerenciar ou excluir as suas informações pessoais.
					</p>
					<p className="politica-text">
						Esta Política de Privacidade aplica-se a todos os usuários e
						visitantes do aplicativo Planus™ ou site app.planus.io e integra os
						Termos e Condições Gerais de Uso do aplicativo Planus™ ou site
						app.planus.io, devidamente inscrita no CNPJ sob o nº
						13.726.953/0001-83, situado em RUA SERVIDOR ALFREDO DE OLIVEIRA
						BRAGA, 240, BETIM, MINAS GERAIS, BRASIL, doravante nominada IVORY IT
						SERVICOS DE INFORMATICA EIRELI.
					</p>
					<p className="politica-text">
						O presente documento foi elaborado em conformidade com a Lei Geral
						de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da
						Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790).
						Ainda, o documento poderá ser atualizado em decorrência de eventual
						atualização normativa, razão pela qual se convida o usuário a
						consultar periodicamente esta seção.
					</p>
					<p className="politica-text">
						O presente documento foi elaborado em conformidade com a Lei Geral
						de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da
						Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790).
						Ainda, o documento poderá ser atualizado em decorrência de eventual
						atualização normativa, razão pela qual se convida o usuário a
						consultar periodicamente esta seção.
					</p>
					<h6 className="politica-section">
						SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO
						VISITANTE?
					</h6>
					<p>
						Os dados pessoais do usuário e visitante são recolhidos pela
						plataforma da seguinte forma:
					</p>
					<ul>
						<li>
							Quando o usuário cria uma conta/perfil na plataforma Planus™ :
							esses dados são os dados de identificação básicos, como e-mail,
							nome completo, profissão, valor de custo-hora. A partir deles,
							podemos identificar o usuário e o visitante, além de garantir uma
							maior segurança e bem-estar às suas necessidades. Ficam cientes os
							usuários e visitantes de que seu perfil na plataforma estará
							acessível a todos demais usuários e visitantes da plataforma
							Planus™.
						</li>
						<li>
							Quando um usuário e visitante acessa as páginas do site
							app.planus.io OU o aplicativo Planus™: as informações sobre
							interação e acesso são coletadas pela empresa para garantir uma
							melhor experiência ao usuário e visitante. Estes dados podem
							tratar sobre as palavras-chaves utilizadas em uma busca, o
							compartilhamento de um documento específico, comentários,
							visualizações de páginas, perfis, a URL de onde o usuário e
							visitante provêm, o navegador que utilizam e seus IPs de acesso,
							dentre outras que poderão ser armazenadas e retidas.
						</li>
					</ul>
					<h6 className="politica-section">
						SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E
						VISITANTE?
					</h6>
					<p className="politica-text">
						Os dados pessoais do usuário e visitante recolhidos são os
						seguintes:
					</p>
					<ul>
						<li>
							Dados para a criação da conta/perfil na plataforma, como: e-mail,
							nome completo, profissão, valor de custo-hora.
						</li>
						<li>
							Dados para otimização da navegação: cache, palavras-chave
							utilizadas na busca, comentários, interação com outros perfis e
							usuários, registros de hora, tarefa e projetos.
						</li>
						<li>
							O e-mail cadastrado pelo visitante e usuário será armazenado até
							que o usuário solicite o descadastro.
						</li>
						<li>
							Dados relacionados a contratos: diante da formalização do contrato
							de compra e venda ou de prestação de serviços entre a plataforma e
							o usuário e visitante poderão ser coletados e armazenados dados
							relativos a execução contratual, inclusive as comunicações
							realizada entre a empresa e o usuário.
						</li>
					</ul>
					<h6 className="politica-section">
						SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO
						USUÁRIO E VISITANTE?
					</h6>
					<p>
						Os dados pessoais do usuário e do visitante coletados e armazenados
						pela plataforma Planus™ tem por finalidade:
					</p>
					<ul>
						<li>
							Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço
							oferecido, facilitar, agilizar e cumprir os compromissos
							estabelecidos entre o usuário e a empresa, melhorar a experiência
							dos usuários e fornecer funcionalidades específicas a depender das
							características básicas do usuário.
						</li>
						<li>
							Melhorias da plataforma: compreender como o usuário utiliza os
							serviços da plataforma, para ajudar no desenvolvimento de negócios
							e técnicas.
						</li>
						<li>
							Comercial: os dados são usados para personalizar o conteúdo
							oferecido e gerar subsídio à plataforma para a melhora da
							qualidade no funcionamento dos serviços.
						</li>
						<li>
							Dados de cadastro: para permitir o acesso do usuário a
							determinados conteúdos da plataforma, exclusivo para usuários
							cadastrados.
						</li>
						<li>
							Dados de contrato: conferir às partes segurança jurídica e
							facilitar a conclusão do negócio.
						</li>
					</ul>
					<p>
						O tratamento de dados pessoais para finalidades não previstas nesta
						Política de Privacidade somente ocorrerá mediante comunicação prévia
						ao usuário, de modo que os direitos e obrigações aqui previstos
						permanecem aplicáveis.
					</p>
					<h6 className="politica-section">
						SEÇÃO 5 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
					</h6>
					<p>
						Os dados pessoais do usuário e visitante são armazenados pela
						plataforma durante o período necessário para a prestação do serviço
						ou o cumprimento das finalidades previstas no presente documento,
						conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.
					</p>
					<p>
						Os dados podem ser removidos ou anonimizados a pedido do usuário,
						excetuando os casos em que a lei oferecer outro tratamento.
					</p>
					<p>
						Ainda, os dados pessoais dos usuários apenas podem ser conservados
						após o término de seu tratamento nas seguintes hipóteses previstas
						no artigo 16 da referida lei:
					</p>
					<ol style={{ listStyleType: 'upper-roman' }}>
						<li>
							Cumprimento de obrigação legal ou regolatória pelo controlador;
						</li>
						<li>
							Estudo por órgão de pesquisa, garantida, sempre que possível, a
							anonimização dos dados pessoais;
						</li>
						<li>
							Transferência a terceiro, desde que respeitados os requisitos de
							tratamento de dados dispostos nesta Lei;
						</li>
						<li>
							Uso exclusivo do controlador, vedado seu acesso por terceiro, e
							desde que anonimizados os dados.
						</li>
					</ol>
					<h6 className="politica-section">
						SEÇÃO 6 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS
					</h6>
					<p>
						A plataforma se compromete a aplicar as medidas técnicas e
						organizativas aptas a proteger os dados pessoais de acessos não
						autorizados e de situações de destruição, perda, alteração,
						comunicação ou difusão de tais dados.
					</p>
					<p>
						A plataforma não se exime de responsabilidade por culpa exclusiva de
						terceiro, como em caso de ataque de hackers ou crackers, ou culpa
						exclusiva do usuário, como no caso em que ele mesmo transfere seus
						dados a terceiros. O site se compromete a comunicar o usuário em
						caso de alguma violação de segurança dos seus dados pessoais.
					</p>
					<p>
						Os dados pessoais armazenados são tratados com confidencialidade,
						dentro dos limites legais. No entanto, podemos divulgar suas
						informações pessoais caso sejamos obrigados pela lei para fazê-lo.
					</p>
					<h6 className="politica-section">
						SEÇÃO 7 - COMPARTILHAMENTO DOS DADOS
					</h6>
					<p>
						O compartilhamento de dados do usuário ocorre apenas com os dados
						referentes a publicações realizadas pelo próprio usuário, tais ações
						são compartilhadas publicamente com os outros usuários.
					</p>
					<p>
						Os dados do perfil do usuário são compartilhados publicamente para
						participantes de sua própria organização em sistemas de busca dentro
						da plataforma.
					</p>
					<h6 className="politica-section">
						SEÇÃO 8 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A
						TERCEIROS?
					</h6>
					<p>
						As informações pessoais de visitantes e usuários estão protegidas.
						Não compartilhamos ou transferimos de nenhuma outra forma suas
						informações pessoais para terceiros, com exceção de nossos, outros
						usuários e visitantes, autoridades competentes, entre outros.
					</p>
					<p>
						Ao acessar nossos serviços e prover suas informações, você está
						consentindo o processamento, transferência e armazenamento desta
						informação em outros países.
					</p>
					<p>
						Ao ser redirecionado para um aplicativo ou site de terceiros, você
						não será mais regido por essa Política de Privacidade ou pelos
						Termos de Serviço da nossa plataforma. Não somos responsáveis pelas
						práticas de privacidade de outros sites e lhe incentivamos a ler as
						declarações de privacidade deles.
					</p>
					<h6 className="politica-section">
						SEÇÃO 9 – COOKIES OU DADOS DE NAVEGAÇÃO
					</h6>
					<p>
						Os cookies referem-se a arquivos de texto enviados pela plataforma
						ao computador do usuário e visitante e que nele ficam armazenados,
						com informações relacionadas à navegação no site. Tais informações
						são relacionadas aos dados de acesso como local e horário de acesso
						e são armazenadas pelo navegador do usuário e visitante para que o
						servidor da plataforma possa lê-las posteriormente a fim de
						personalizar os serviços da plataforma.
					</p>
					<p>
						O usuário e o visitante da plataforma Planus™ manifesta conhecer e
						aceitar que pode ser utilizado um sistema de coleta de dados de
						navegação mediante à utilização de cookies.
					</p>
					<p>
						O cookie persistente permanece no disco rígido do usuário e
						visitante depois que o navegador é fechado e será usado pelo
						navegador em visitas subsequentes ao site. Os cookies persistentes
						podem ser removidos seguindo as instruções do seu navegador. Já o
						cookie de sessão é temporário e desaparece depois que o navegador é
						fechado. É possível redefinir seu navegador da web para recusar
						todos os cookies, porém alguns recursos da plataforma podem não
						funcionar corretamente se a capacidade de aceitar cookies estiver
						desabilitada.
					</p>
					<h6 className="politica-section">SEÇÃO 10 - CONSENTIMENTO</h6>
					<p>
						Ao utilizar os serviços e fornecer as informações pessoais na
						plataforma, o usuário está consentindo com a presente Política de
						Privacidade.
					</p>
					<p>
						O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus
						direitos de cancelar seu cadastro, acessar e atualizar seus dados
						pessoais e garante a veracidade das informações por ele
						disponibilizadas.
					</p>
					<h6 className="politica-section">
						SEÇÃO 11 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
					</h6>
					<p>
						Reservamos o direito de modificar essa Política de Privacidade a
						qualquer momento, então, é recomendável que o usuário e visitante
						revise-a com frequência.
					</p>
					<p>
						As alterações e esclarecimentos vão surtir efeito imediatamente após
						sua publicação na plataforma. Quando realizadas alterações os
						usuários serão notificados. Ao utilizar o serviço ou fornecer
						informações pessoais após eventuais modificações, o usuário e
						visitante demonstra sua concordância com as novas normas.
					</p>
					<p>
						Diante da fusão ou venda da plataforma à outra empresa os dados dos
						usuários podem ser transferidas para os novos proprietários para que
						a permanência dos serviços oferecidos.
					</p>
					<h6 className="politica-section">
						SEÇÃO 12 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS
					</h6>
					<p>
						Para a solução de controvérsias decorrentes do presente instrumento
						será aplicado integralmente o Direito brasileiro. Os eventuais
						litígios deverão ser apresentados no foro da comarca em que se
						encontra a sede da empresa.
					</p>
				</div>
			</div>
	);
};
