import moment from 'moment';
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { StopwatchService } from '../../services/providers/stopwatch';

import { StopwatchInitialType, StopwatchProviderProps } from './types';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import HorasService from '../../services/providers/hour';
import {  HoursRecordSimpleSelected, StopwatchRecord } from '../../models/HoursRecord';
import { useRegistrosDeHoras } from '../RegistrosDeHoras';
import { useCalendarioRegistros } from '../CalendarioRegistros';

const initialValues: StopwatchInitialType = {
	currentStopwatch: '',
	stopwatchSeconds: 0,
	loadingStopwatch: true,
	getStopwatch: () => {},
	setCurrentStopwatch: () => {},
	setStopwatchSeconds: () => {},
	handleStopwatch: () => {},
	requestingStopwatch: false,
	title: '',
	setTitle: () => {},
	handleConfirmModal: () => {},
	workload: null,
	confirmModal: {
		buttons: [],
		isOpen: false,
		title: '',
		text: '',
	},
	setConfirmModal: () => {},
	handleDeleteStopwatch: () => {},
	isModalVisible: false,
	preSelectedRecord: null,
	setPreSelectedRecord: () => {},
	selectedMenuIndex: 0,
	setSelectedMenuIndex: () => {},
	handleToggleModal: () => {},
	handleAfterPostPatch: () => () => {},
	setHandleAfterPostPatch: () => {}
};

const StopwatchContext = createContext(initialValues);

const StopwatchProvider = ({ children }: StopwatchProviderProps) => {
    const [preSelectedRecord, setPreSelectedRecord] = useState<HoursRecordSimpleSelected | null>(null);
	const [currentStopwatch, setCurrentStopwatch] = useState(
		initialValues.currentStopwatch,
	);
	const [selectedMenuIndex, setSelectedMenuIndex] = useState(initialValues.selectedMenuIndex);

	const [stopwatchSeconds, setStopwatchSeconds] = useState(
		initialValues.stopwatchSeconds,
	);
	const [loadingStopwatch, setLoadingStopwatch] = useState(
		initialValues.loadingStopwatch,
	);
	const [requestingStopwatch, setRequestingStopwatch] = useState(
		initialValues.requestingStopwatch,
	);

	const [workload, setWorkload] = useState(initialValues.workload);
	

	const [title, setTitle] = useState('');


	const [confirmModal, setConfirmModal] = useState(initialValues.confirmModal);
	const [isModalVisible, setIsModalVisible] = useState(initialValues.isModalVisible);
	const [handleAfterPostPatch, setHandleAfterPostPatch] = useState<() => void>(initialValues.handleAfterPostPatch);

	const {updateRegistroTime} = useRegistrosDeHoras();
	const {updateCalendarioRegistroTime} = useCalendarioRegistros();
	
	const handleConfirmModal = (isOpen: boolean, workload?: number) => {
		setConfirmModal(prev => ({ ...prev, isOpen }));
		if (workload) {
			setWorkload(workload / 5);
		}
	};


	const onStopModalNoExtra = (stopwatch: StopwatchRecord | '') => {
		if (stopwatch !== '') {
			const { projeto, projeto_nome, tarefa, tarefa_nome } = stopwatch;
			handleStopwatch({
				projectId: projeto,
				projectName: projeto_nome,
				taskId: tarefa,
				taskName: tarefa_nome,
				horas_extras: false,
				data: stopwatch.data
			});
		}
	};
	const handleDeleteStopwatch = (stopwatch: StopwatchRecord) => {
			const hourService = new HorasService();
			hourService.deleteHoras(
				stopwatch.id,
				() => {
					setCurrentStopwatch('');
					setStopwatchSeconds(0);
					setTitle('');
					setConfirmModal(prev => ({...prev, isOpen: false}))
				},
				() => {
					toast.error('Erro ao encerrar cronômetro!');
					setConfirmModal(prev => ({...prev, isOpen: false}));
				},
			);
	}

	const handleOvertime = (stopwatch: StopwatchRecord) => {
		setConfirmModal({
			isOpen: true,
			title: t('Você deixou o cronômetro aberto!'),
			text: <span>{t(
				`O cronômetro da tarefa`)}<strong> {stopwatch.tarefa_nome} </strong>
				{t(`está rodando desde o dia `)}{moment(stopwatch.data).format(
					'DD/MM/YYYY',
				)}. {t(`Como você deseja salvar essas horas?`)}
			</span>,
			buttons: [
				{
					title: 'Completar carga horária diária',
					onClick: () => onStopModalNoExtra(stopwatch),
				},
				{
					title: 'Descartar cronômetro',
					onClick: () => handleDeleteStopwatch(stopwatch),
				},
			],
			loading: requestingStopwatch,
		});
	};

	const getStopwatch = () => {
		setLoadingStopwatch(true);
		const stopwatchService = new StopwatchService();
		stopwatchService.getStopwatch({}).then(result => {
			if (result !== '') {
				setCurrentStopwatch(result);
				if (result.data !== moment().format('YYYY-MM-DD')) {
					
					handleOvertime(result);
				}
			}
			setLoadingStopwatch(false);
		});
	};

	useEffect(() => {
		if (currentStopwatch !== '') {
			handleStartOffset();
		}
	}, [currentStopwatch]);

	const handleStartOffset = () => {
		if (currentStopwatch !== '') {
			const startTime = moment(currentStopwatch.timestamp);
			const now = moment();
			const seconds = now.diff(startTime, 'seconds');
			setStopwatchSeconds(seconds);
		}
	};
	const handleStopwatch = ({
		taskId = '',
		horas_extras = false,
		data = undefined,
		descricao = undefined
	}: {
		taskId: number | string;
		taskName: string;
		projectId: number | string;
		projectName: string;
		horas_extras?: boolean;
		data?: string;
		descricao?: string;
	}) => {
		const stopwatchService = new StopwatchService();
		const hourService = new HorasService();
		const tempStopwatch = currentStopwatch;

		setRequestingStopwatch(true);
		stopwatchService
			.postStopwatch({ id_tarefa: taskId.toString(), horas_extras, data })
			.then(result => {
				if (result.data.timestamp !== null) {
					setCurrentStopwatch(result.data);
					setIsModalVisible(false);
				} else {
					setCurrentStopwatch('');
					updateRegistroTime(result.data);
					updateCalendarioRegistroTime(result.data);
					document.getElementById('atualiza-horas-atalho-btn')?.click();
					toast.success(t("Registro de hora salvo com sucesso!"))
				}
				setTitle('');
				setStopwatchSeconds(0);
				setRequestingStopwatch(false);
				if(descricao) {
					const formData = new FormData();
				for (const key in result.data){
					if(key === 'descricao') {
						formData.append(key, descricao)
					}
					
				}
				hourService.patchHoras(formData, result.data.id, (response) => {
					setCurrentStopwatch(prev => prev === '' ? '' : {...prev, descricao: response.data.descricao})
				}, () => {})
				}
			})
			.catch(() => {
				setCurrentStopwatch(tempStopwatch);
				setRequestingStopwatch(false);
				toast.error(
					t(
						`Ocorreu um erro ao ${
							tempStopwatch === '' ? 'iniciar' : 'encerrar'
						} o cronômetro!`,
					),
				);
			}).finally(() => {
				setConfirmModal(prev => ({...prev, isOpen: false}))
			});
	};

	const handleToggleModal = (value: boolean) => {
		if(value) {
			setIsModalVisible(true);

		}else {
			setPreSelectedRecord(null);
			setIsModalVisible(false);
		}
		
	}

	const stopwatchValue = useMemo(
		() => ({
			currentStopwatch,
			stopwatchSeconds,
			loadingStopwatch,
			getStopwatch,
			setCurrentStopwatch,
			setStopwatchSeconds,
			handleStopwatch,
			requestingStopwatch,
			title,
			setTitle,
			handleConfirmModal,
			workload,
			confirmModal,
			setConfirmModal,
			handleDeleteStopwatch,
			isModalVisible,
			preSelectedRecord, 
			setPreSelectedRecord,
			selectedMenuIndex,
			setSelectedMenuIndex,
			handleToggleModal,
			handleAfterPostPatch,
			setHandleAfterPostPatch
		}),
		[
			currentStopwatch,
			handleStopwatch,
			requestingStopwatch,
			setStopwatchSeconds,
			stopwatchSeconds,
			loadingStopwatch,
			getStopwatch,
			setCurrentStopwatch,
			title,
			setTitle,
			handleConfirmModal,
			workload,
			confirmModal,
			setConfirmModal,
			handleDeleteStopwatch,
			isModalVisible,
			preSelectedRecord, 
			setPreSelectedRecord,
			selectedMenuIndex,
			setSelectedMenuIndex,
			handleToggleModal,
			handleAfterPostPatch,
			setHandleAfterPostPatch
		],
	);

	return (
		<StopwatchContext.Provider value={stopwatchValue}>
			{children}
		</StopwatchContext.Provider>
	);
};

const useStopwatch = () => {
	const context = useContext(StopwatchContext);
	return context;
};

export { StopwatchProvider, useStopwatch, initialValues, StopwatchContext };
