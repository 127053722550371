import React, {InputHTMLAttributes} from 'react';

interface DefaultCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {

}

export default function DefaultCheckbox(props: DefaultCheckboxProps) {
    return <input
    type="checkbox"
    className="w-5 h-5 min-h-[1rem]  min-w-[1rem] flex justify-center content-center items-center text-ivory-primary before:border-2 before:border-solid before:border-ivory-primary appearance-none before:content-[''] before:w-4 before:h-4 before:bg-transparent"
    {...props}
/>
}