import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function PlanusMiniLogo(props: IconProps) {
  return ( 
<svg width="32" height="31" viewBox="0 0 32 31" fill="none" {...props}  xmlns="http://www.w3.org/2000/svg">
<path d="M20.1018 0C22.4658 0 24.5407 0.454206 26.33 1.35918C28.1158 2.25382 29.4922 3.49601 30.4522 5.09261C31.4157 6.67201 31.8974 8.46819 31.8974 10.4743C31.8974 12.4803 31.4157 14.1045 30.4522 15.5875C29.4888 17.0706 28.1158 18.2267 26.33 19.0526C24.5579 19.8784 22.483 20.2913 20.1018 20.2913H15.6905V30.0223H8V0H20.1018ZM20.1018 14.0425C21.4507 14.0425 22.452 13.726 23.1127 13.0928C23.7733 12.4459 24.1037 11.5891 24.1037 10.5156C24.1037 9.27681 23.7664 8.25485 23.092 7.44278C22.4176 6.63072 21.4232 6.22468 20.1018 6.22468H15.6905V14.0391H20.1018V14.0425Z" fill="white"/>
<path d="M0 30L3.5 24.7911L7 30L7 -3.0598e-07L3.5 -1.5299e-07L-1.31134e-06 0L0 30Z" fill="#01DC94"/>
</svg>


  
  )
}