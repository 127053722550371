import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Circle from '../../../assets/svgs/circle';
import ExpandIcon from '../../../assets/svgs/expand';

import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, DoughnutController } from 'chart.js';

import './style.css';

import '../../../i18n';
import { useTranslation } from 'react-i18next';
ChartJS.register(ArcElement, DoughnutController, Tooltip, Legend);

const TarefasChart = ({ registros, diasDaSemana }) => {
	const {t} = useTranslation();
	const [data, setData] = useState({
		labels: [],
		datasets: [
			{
				data: [],
				backgroundColor: [],
			},
		],
	});

	useEffect(() => {
		criaObjGrafico(registros);
	}, [registros]);

	const criaObjGrafico = dados => {
		const tarefas = [];
		const dataValue = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [
						'#55d8fe',
						'#23d9b7',
						'#f26d85',
						'#a3a0fb',
						'#d7f205',
						'#426172',
						'#5ee21a',
					],
				},
			],
		};

		if (dados.length > 0) {
			dados.forEach(dado => {
				if (!tarefas.find(item => item.tarefa === dado.tarefa)) {
					tarefas.push({
						tarefa: dado.tarefa,
						tarefa_nome: dado.tarefa_nome,
						tarefaRegistros: 0,
					});
				}
			});

			dados.forEach(dado => {
				tarefas.forEach(item => {
					if (item.tarefa === dado.tarefa) {
						let a = dado.duracao.split(':');
						let minutes = +a[0] * 60 + +a[1];
						item.tarefaRegistros += minutes;
					}
				});
			});

			tarefas.forEach((item, index) => {
				dataValue.labels.push(item.tarefa_nome);
				dataValue.datasets[0].data.push(item.tarefaRegistros);
				if (dataValue.datasets[0].backgroundColor.length < index + 1) {
					dataValue.datasets[0].backgroundColor.push(geraCorRandomica());
				}
			});
			setData(dataValue);
		} else {
			const semRegistro = {
				labels: [t("Sem registros")],
				datasets: [
					{
						data: ['01'],
						backgroundColor: ['#eaeaea'],
					},
				],
			};
			setData(semRegistro);
		}
	};

	const options = {
		aspectRatio: 1.5,
		plugins: {
			tooltip: {
			callbacks: {
				label: function(context) {
					const valores = context.dataset.data;
					const total = valores.reduce((a, b) => a + b, 0);
					const percentFloat =
						(context.raw *
							100) /
						total;
					const percent = percentFloat.toFixed(1);
					const label = context.raw;
					let labelFinal = ''
					if (label) {
						let h = Math.floor(label / 60);
						let m = label % 60;
						h = h < 10 ? '0' + h : h;
						m = m < 10 ? '0' + m : m;
						const horas = `${h}:${m}`;
						labelFinal =
							registros.length > 0
								? ` ${context.dataIndex + 1}. ${percent}% / ${horas}${t(" horas")}`
								: '1. 100% 00:00';
					}
					return labelFinal;
				},
			},
		},
		legend: {
			display: false,
		},
	}
	};
	const geraCorRandomica = () => {
		let r = function() {
			return Math.floor(Math.random() * 256);
		};
		const cor = `rgb(${r()},${r()},${r()},0.8)`;
		return cor;
	};
	return (
		<div className="grafico-bg">
			<div className="grafico-header-container">
				<span className="grafico-title">{t("Suas tarefas da semana")}</span>
				<span className="grafico-link-horas">
					<Link to="/registros/tarefas" className='flex items-center '>
						{t("Ir para tarefas")}
						<span className="grafico-link-icon">
							<ExpandIcon />
						</span>
					</Link>
				</span>
			</div>
			<div className="grafico-pie-container">
				<div style={{width: 260}}>
					<Chart type='doughnut' data={data}  options={options} />
				</div>
				<div>
					{data.labels.map((item, index) => (
						<div className="grafico-legend-container flex items-center ">
							<span
								className="grafico-circle-legend"
								style={{ fill: data.datasets[0].backgroundColor[index] }}
							>
								<Circle />
							</span>

							<span className="grafico-legend-text">
								<span style={{ color: '#426172', marginRight: '2px' }}>
									{index + 1 > 10 ? index + 1 : `0${index + 1}`}
									{'. '}
								</span>
								{item}
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default memo(TarefasChart);
