import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faTable } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import './style.css';
import { useTranslation } from 'react-i18next';

export default function PlanilhaDeTempo({
	registros,
	loadingGetHoras,
	getRelatorioCSV,
	loadingRelatorioCSV,
	getRelatorioXLSX,
	handlePrint
}) {
	const {t} = useTranslation();
	const relatorioTitle = t("Planilha de Tempo");
	const calculaTotalHorasGeral = () => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalSegundos = 0;
		let totalAbsoluto = 0;
		registros.forEach(registro => {
			totalHoras = totalHoras + parseInt(registro.duracao.slice(0, 2));
			totalMinutos = totalMinutos + parseInt(registro.duracao.slice(3, 5));
			totalSegundos = totalSegundos + parseFloat(registro.duracao.slice(6, registro.duracao.length));

		});
		const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60);
		const minutes = ((tempTotalMinutos) % 60);
		let finalTotalMinutos = minutes < 10 ? `0${minutes}` : `${minutes}`;
		totalAbsoluto = `${totalHoras + parseInt(tempTotalMinutos / 60)}:${finalTotalMinutos}`;

		return totalAbsoluto;
	};
	const upperCaseDiaSemana = data => {
		var diaSemana = moment(data).format('ddd');

		return diaSemana[0].toUpperCase() + diaSemana.slice(1);
	};

	const handlePrintData = () => {
		const dataInfo = []

		let dataValues = registros.map(tarefa => {
			const tempRow = {
				[t("Período")]: tarefa.data,
				[t("Projeto")]: tarefa.projeto_nome,
				[t("Tarefa")]: tarefa.tarefa_nome,
				[t("Usuário")]: tarefa.pessoa_nome,
				[t("Total")]: tarefa.pessoa_nome,
			}

			return tempRow
		})
	
		const totalRow = {
			[t("Período")]: "",
			[t("Projeto")]: "",
			[t("Tarefa")]: "",
			[t("Usuário")]: "",
			[t("Total")]: calculaTotalHorasGeral(),
		}

		dataValues = [...dataValues, totalRow]

		const periodoString = ""
		
		handlePrint({
			dataInfo, 
			dataValues, 
			periodoString, 
			title: relatorioTitle
		})
	}

	return (
		<div>
			<div className="relatorio-header-container">
				<p className="relatorio-title">{relatorioTitle}</p>
				<div style={{ display: 'flex' }}>
					<div
						onClick={() => getRelatorioCSV()}
						className="relatorio-print-container"
						disabled={true}
						style={{
							pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
							opacity: loadingRelatorioCSV ? '0.5' : '1',
						}}
					>
						<FontAwesomeIcon icon={faTable} className="print-icon-relatorio" />
						<p className="print-text-relatorio">{t("Gerar CSV")}</p>
						{loadingRelatorioCSV ? (
							<span style={{ marginLeft: '4px' }}>
								<CarregandoDados />{' '}
							</span>
						) : (
							<></>
						)}
					</div>
					<div
						onClick={() => getRelatorioXLSX()}
						className="relatorio-print-container"
						disabled={true}
						style={{
							pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
							opacity: loadingRelatorioCSV ? '0.5' : '1',
						}}
					>
						<FontAwesomeIcon icon={faTable} className="print-icon-relatorio" />
						<p className="print-text-relatorio">{t("Gerar Excel")}</p>
						{loadingRelatorioCSV ? (
							<span style={{ marginLeft: '4px' }}>
								<CarregandoDados />{' '}
							</span>
						) : (
							<></>
						)}
					</div>

					<div
						onClick={() => handlePrintData()}
						className="relatorio-print-container"
					>
						<FontAwesomeIcon icon={faPrint} className="print-icon-relatorio" />
						<p className="print-text-relatorio">{t("Imprimir")}</p>
					</div>
				</div>
			</div>
			<div id="printable">
				{loadingGetHoras ? (
					<CarregandoDados />
				) : (
					<table className="relatorio-table">
						<thead>
							<tr>
								<th className="cabecalho-table-projeto">{t("Período")}</th>
								<th style={{ textAlign: 'left' }} className="cabecalho-table">
									{t("Projeto")}
								</th>
								<th style={{ textAlign: 'left' }} className="cabecalho-table">
									{t("Tarefa")}
								</th>
								<th style={{ textAlign: 'left' }} className="cabecalho-table">
									{t("Usuário")}
								</th>
								<th className="cabecalho-total">{t("Total")}</th>
							</tr>
						</thead>
						<tbody>
							{registros.map(registro => (
								<tr>
									<th className="periodo-th">
										<span style={{ fontWeight: '700' }}>
											{upperCaseDiaSemana(registro.data)}
										</span>
										<br />
										{moment(registro.data).format('L')}
									</th>
									<td className="projeto-td">{registro.projeto_nome}</td>
									<td className="tarefa-td">{registro.tarefa_nome}</td>
									<td className="usuario-td">{registro.pessoa_nome}</td>
									<td className="total-td">{registro.duracao.slice(0, 5)}</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<th className="span-table-botton" colSpan="4"></th>
								<th id="totalHoras">{calculaTotalHorasGeral()}</th>
							</tr>
						</tfoot>
					</table>
				)}
			</div>
		</div>
	);
}

const CarregandoDados = () => (
	<>
		<div className="loader-alinhamento">
			<div className="loader-conteudo"></div>
		</div>
	</>
);
