import styled from "styled-components";
import { PAGETOPMARGEM, theme } from "../../../styles/theme";

export const HeaderContainer = styled.tr<TableWrapperProps>`
    height: 40px;
    text-align: center;
    position: relative;
    border-bottom: 2px solid rgb(233, 238, 241);
    font-size: 14px;
    line-height: normal;
    background-color: rgb(255, 255, 255);
    color: rgb(64, 97, 115);
    font-weight: 700;
    ${({customTableStyle}) => customTableStyle}

`

export const ExtraHeaderContainer = styled.div`

`;

interface TableBodyProps {
    tableTotal: boolean;
}

export const TableBody = styled.tbody<TableWrapperProps & TableBodyProps>`
        ${({customTableStyle}) => customTableStyle}
        @media print {
            height: 100%;
            overflow: visible;
            padding-bottom: 0;
        }
        ${({tableTotal}) => tableTotal && 'padding-bottom: 40px;'}
     
`


export const BodyContainer = styled.tr<TableWrapperProps>`
    border-bottom: 1px solid rgb(218, 226, 230);
    height: 40px;
    font-size: 14px;
    color: rgb(128, 153, 167);
    background: rgb(247, 246, 245);
    ${({customTableStyle}) => customTableStyle}
    ${({bgColor}) => bgColor && `background-color: ${bgColor}`}

`

interface TableWrapperProps {
    customTableStyle?: string;
    bgColor?: string;
}

export const TableWrapper = styled.div<TableWrapperProps>`
    height: calc(100vh - (${(PAGETOPMARGEM) + "vw"} + 185px));
    overflow-y: auto;

    ${({customTableStyle}) => customTableStyle}

`

export const Table = styled.table<TableWrapperProps>`
    table-layout: auto;
    border-collapse: collapse;
    margin-bottom: 14px;
    position: relative;
    ${({customTableStyle}) => customTableStyle}
`

export const Thead = styled.thead<TableWrapperProps>`
    position: sticky;
    top: 0;
    z-index: 1;
    ${({customTableStyle}) => customTableStyle}
`;

export const Checkbox =styled.input.attrs({type: 'checkbox'})`
   &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #9cabb3;
    border-radius: 3px;
    background-color: #fff;
   }
`

export const CheckboxContainer = styled.td`
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    min-width: 10%;
    padding: 10px;
    color: rgb(128, 153, 167);
`;

export const TheadToolsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 7px;
    margin-left: 20px;
`

export const NoData = styled.td`

`

export const NoDataContainer = styled.tr`
    color: #8099a7;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    padding: 0 10px;

`

export const TotalContainer = styled.tr<TableWrapperProps>`
    font-size: 0.75rem;
    background-color: ${theme.colors.table.background};
    color: ${theme.colors.table.total};
    font-weight: bold;
    position: absolute;
    bottom: 0;
    ${({customTableStyle}) => customTableStyle}
    @media print {
        position: unset;
    }
`;

interface TotalCellProps {
    customStyle?: string;
}

export const TotalCell = styled.td<TotalCellProps>`
    height: 40px;
    ${({customStyle}) => customStyle}
`;