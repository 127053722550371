import styled from 'styled-components';

export const InfoDetailsContainer = styled.div`
	background: #fff;
	border-radius: 10px;
`;

export const InfoDetailsTable = styled.table`
	@media (max-width: 850px) {
		display: none;
	}
`;

export const InfoDetailsTh = styled.th`
	padding: 10px 15px 0 15px;
	font-weight: 500;
	color: #8099a7;
	font-size: 16px;
	align-self: center;
	border-right: 2px solid #e9eef1;
	width: 8%;
`;

export const InfoDetailsTd = styled.th`
	padding: 0px 15px 10px 15px;
	color: #406173;
	font-size: 16px;
	font-weight: normal;
	border-right: 2px solid #e9eef1;
`;

export const InfoDetailsTableMobile = styled.table`
	display: none;
	@media (max-width: 850px) {
		display: block;
	}
`;

export const InfoDetailsTrMobile = styled.tr`
	border-bottom: 2px solid #e9eef1;
`;
