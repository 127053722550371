import React, { useCallback } from 'react';
import Select, {
	OptionProps,
	MultiValue,
	SingleValue,
	MultiValueGenericProps,
	GroupBase,
} from 'react-select';
import { SelectOptions } from '../../../../models/Select';
import {
	FilterSelectContainer,
	OptionContainer,
	OptionLabel,
	ValueContainerWrapper,
	OptionsWrapper,
	Label,
} from './styled';
import { BiFilterAlt } from 'react-icons/bi';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { theme } from '../../../../styles/theme';
import { selectFiltrosStyle } from '../../../../utils/extenal-libs';
import { t } from 'i18next';
import '../../../../i18n';

interface FilterSelectProps {
	filterOptions: SelectOptions[];
	openFilters: SelectOptions[];
	setOpenFilters: React.Dispatch<React.SetStateAction<SelectOptions[]>>;
}

export default function FilterSelect({
	filterOptions,
	openFilters,
	setOpenFilters,
}: FilterSelectProps) {
	const handleChange = useCallback(
		(e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
			const selected = e as SelectOptions[];
			setOpenFilters(
				selected.length > 0 ? selected : [{ label: t('Filtros'), value: '' }],
			);
		},
		[],
	);

	return (
		<FilterSelectContainer>
			<Select
				components={{
					Option,
					MultiValueContainer,
				}}
				placeholder={t('Filtros')}
				options={filterOptions}
				value={openFilters}
				onChange={handleChange}
				isMulti={true}
				styles={selectFiltrosStyle}
				hideSelectedOptions={false}
				isClearable={false}
				closeMenuOnSelect={false}
				menuPosition="fixed"
				menuPlacement="bottom"
			/>
		</FilterSelectContainer>
	);
}

const MultiValueContainer = (
	props: MultiValueGenericProps<
		SelectOptions,
		boolean,
		GroupBase<SelectOptions>
	>,
) => {
	const {
		selectProps: { value },
		data,
	} = props;
	const selectedValues = value as SelectOptions[];
	return (selectedValues.length > 0 &&
		selectedValues[0].value === data.value) ||
		selectedValues.length === 0 ? (
		<ValueContainerWrapper>
			<BiFilterAlt size={20} color={theme.colors.text.inputText} />
			<Label>{t('Filtros')}</Label>
		</ValueContainerWrapper>
	) : null;
};

const Option = (props: OptionProps<SelectOptions>) => {
	const { children, getValue, data, setValue, isSelected } = props;
	const value = getValue();
	const handleOnClick = useCallback(() => {
		if (isSelected) {
			setValue(
				value.filter(item => item.value !== data.value),
				'deselect-option',
			);
		} else if (!isSelected) {
			setValue([...value, data], 'select-option');
		}
	}, [isSelected, value, data]);

	return (
		<OptionContainer onClick={handleOnClick}>
			<OptionsWrapper>
				<OptionLabel>{children}</OptionLabel>
				{isSelected ? (
					<FaEye size={20} color={theme.colors.background.mainBg} />
				) : (
					<FaEyeSlash size={20} color="grey" />
				)}
			</OptionsWrapper>
		</OptionContainer>
	);
};
