import React, { ReactElement } from 'react';
import Button from './button';

import '../../../i18n';
import { useTranslation } from 'react-i18next';

interface FilterBtnsProps {
	onClearFields: () => void;
	onFilter: () => void;
}

const FilterBtns = ({
	onClearFields,
	onFilter,
}: FilterBtnsProps): ReactElement => {
	const { t } = useTranslation();

	return (
			<div className="text-right flex lg:mt-8 md:mt-4 mt-1 lg:justify-end justify-between items-center pb-5">
				<button onClick={onClearFields} className="text-filter-clearButton cursor-pointer font-bold text-sm">
					{t('limpar tudo').toString()}
				</button>
				<Button text={t('filtrar')} onClick={onFilter} />
			</div>
	);
};

export default FilterBtns;
