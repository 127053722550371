import React, { useState } from 'react';

import { ClienteForm } from '../Form';

import { ModalContainer } from '../../../components/planus2/Modals/ModalContainer';
import { Client } from '../../../models/Client';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import ClienteService from '../../../services/providers/client';
import { useSetAtom } from 'jotai';
import { clientsPagesItemsAtom } from '../../../atoms/Client';

interface ClientModalProps {
  client?: Client
  isModalOpen: boolean
  closeModal: () => void
}

export function ClientModal({closeModal, isModalOpen, client}: Readonly<ClientModalProps>) {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [closeAfter, setCloseAfter] = useState<boolean>(false)
  const setClients = useSetAtom(clientsPagesItemsAtom);
  const clienteService = new ClienteService();

	const handleClick = (close: boolean) => {
    setSubmitting(true)
    setCloseAfter(close);
		if (!submitting) {
			document.getElementById('modal_pagina_cliente_button')?.click();
		}
	};

  const handleAddClient = (client: Client) => {
    setClients(prev => ({
      ...prev,
      clients: [...prev.clients, client]
    }))
  }
  
	const postCliente = async (params: FormData) => {
		setSubmitting(true);
		clienteService.postCliente(
			params,
			response => {
				toast.success(t('Cliente salvo com sucesso!'));
        handleAddClient(response.data)
				setSubmitting(false);
        if (closeAfter) {
          closeModal()
        }
			},
			error => {
				toast.error(error);
				setSubmitting(false);
			},
		);
	};

  const handleEditClient = (client: Client, id: number) => {
    setClients(prev => {
      const itemIndex = prev.clients.findIndex(item => item.id === id)
      const tempItems = prev.clients
      tempItems.splice(itemIndex, 1, client)

      return {
        ...prev,
        clients: tempItems
      }
    })
  }

	const patchCliente = async (params: FormData, id?: number) => {
		setSubmitting(true);

		if(id) {
      clienteService.patchCliente(
        params,
        id,
        response => {
          toast.success(t('Cliente atualizado com sucesso!'));
          handleEditClient(response.data, id)
          setSubmitting(false);
  
          if (closeAfter) {
            closeModal()
          }
        },
        error => {
          toast.error(error);
          setSubmitting(false);
        },
      );
    }
	};

  const handleDeleteClient = (id: number) => {
    setClients(prev => ({
      ...prev,
      clients: prev.clients.filter(item => item.id !== id)
    }))
  }

	const deleteCliente = async (id: number) => {
		setSubmitting(true);

		clienteService.deleteCliente(
			id,
			() => {
        toast.success(t('Cliente removido com sucesso!'));
        handleDeleteClient(id)
        setSubmitting(false);
        closeModal()
			},
			error => {
				toast.error(error);
				setSubmitting(false);
			},
		);
	};

	return (
    <ModalContainer 
      handleSave={handleClick} 
      modalTitle='Novo Cliente' 
      className='w-96' 
      isModalOpen={isModalOpen} 
      closeModal={closeModal}
      loading={submitting}
      extraMenu={client ? {
        itemId: client.id,
        handleDeletar: deleteCliente,
      } : undefined}
    >
        {client ? (
          <ClienteForm
            handleSubmit={patchCliente}
            item_edit={client}
            editing={true}
          />
        ) : (
          <ClienteForm item_edit={undefined} handleSubmit={postCliente} editing={false} />
        )}
    </ModalContainer>
	);
}
