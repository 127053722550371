import { t } from 'i18next';
import * as Yup from 'yup';

export const HorasSchema = Yup.object().shape({
	projeto: Yup.string().required(t('Campo obrigatório.')),
	tarefa: Yup.string().required(t('Campo obrigatório.')),
	data: Yup.string().required(t('Campo obrigatório.')),
	duracao: Yup.string().required(t('Campo obrigatório.')),
});
export const HorasSchemaOcio = Yup.object().shape({
	// projeto: Yup.string().required(t('Campo obrigatório.')),
	// tarefa: Yup.string().required(t('Campo obrigatório.')),
	data: Yup.string().required(t('Campo obrigatório.')),
	duracao: Yup.string().required(t('Campo obrigatório.')),
});
