import styled from "styled-components";

interface ToggleProps {
    checked: boolean;
}

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    color: #426172;
    font-size: 0.875rem;
    font-weight: 400;
`;
export const ToggleButton = styled.button`
    border:none;
    outline: none;
    padding: 0;
    background-color: transparent;
    font-size: 0.875rem;
`;
export const ToggleSpan = styled.span<ToggleProps>`
   position: relative;
	width: 32px;
	height: 20px;
	padding: 2px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
    background: ${({checked}) => checked ? '#496070' : '#CED8DF'};
`;
export const ToggleCheck = styled.span<ToggleProps>`
    position: absolute;
	background: #e6ebef;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	transition: right 0.2s;
    right: ${({checked}) => checked ? '1px' : 'calc(32px - 16px - 2px)'};
`;