import React, { ButtonHTMLAttributes } from "react";
import { GenericButton } from "../GenericButton";

interface HeaderButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

export default function HeaderButton({children, ...rest}: Readonly<HeaderButtonProps>) {
    return <GenericButton {...rest} className="flex h-10 text-base rounded-md px-6 py-2 bg-ivory-primary text-white font-black flex-row items-center justify-center gap-2 w-auto">
        {children}
    </GenericButton>
}