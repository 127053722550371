import styled from "styled-components";
import { theme } from "../../../../../styles/theme";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ModalBgCenter = styled.div`
    background: ${theme.colors.background.modalBg};
`

export const ModalAddButton = styled.div`
    border-radius: 8px;
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    background: rgba(192,207,216,.14901960784313725);
    outline: none!important;
    cursor: pointer;
    width: fit-content;
    margin: 2.5vh 0 0;
    color: #637d8a;
`

export const LabelModal = styled.label`
    margin: 16px 0 2px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: var(--medium-text-size);
    font-weight: 500;
    color: var(--subtitle-text-color);
`

export const IconDetail = styled(FontAwesomeIcon)`
	font-size: 15px;
	margin-left: 6px;
`;

interface FieldModalProps {
    errors?: string;
    touched?: boolean;
    validProfile: boolean;
}

export const FieldModal = styled(Field)<FieldModalProps>`
    ${({errors, touched}) => errors && touched ?
        `
        border-radius: 7px;
        display: block;
        width: 100%;
        height: 37px;
        min-height: 37px;
        font-size: 14px;
        padding: 7px;
        margin-bottom: 18px;
        outline: none;
        box-shadow: none;
        border: 1px solid rgb(241, 109, 132);
        transition: all 0.3s ease 0s;
        color: rgb(53, 80, 96);
        ` :
        `
        border-radius: 7px;
        display: block;
        width: 100%;
        font-size: 14px;
        padding: 7px;
        outline: none;
        box-shadow: none;
        border: 1px solid rgba(192, 207, 216, 0.85);
        transition: all 0.3s ease 0s;
        color: rgb(128, 128, 128);
        ` 
    }
    ${({validProfile}) => !validProfile && `
        opacity: 0.5;
        pointer-events: none;` }
`

export const TextAreaModal = styled(Field)<FieldModalProps>`
        ${({errors, touched}) => errors && touched ?
        `
        border-radius: 7px;
        display: block;
        width: 100%;
        height: 37px;
        font-size: 14px;
        padding: 7px;
        margin-bottom: 18px;
        outline: none;
        min-height: calc(100% - 37px);
        box-shadow: none;
        border: 1px solid rgb(241, 109, 132);
        transition: all 0.3s ease 0s;
        color: rgb(53, 80, 96);
        resize: none;
        ` :
        `
        border-radius: 7px;
        display: block;
        width: 100%;
        font-size: 14px;
        padding: 7px;
        outline: none;
        min-height: calc(100% - 37px);
        box-shadow: none;
        border: 1px solid rgba(192, 207, 216, 0.85);
        transition: all 0.3s ease 0s;
        color: rgb(128, 128, 128);
        resize: none;
        ` 
    }
    ${({validProfile}) => !validProfile && `
        opacity: 0.5;
        pointer-events: none;` }
`

interface TagsContainerProps {
    validProfile: boolean;
}

export const TagsContainer = styled.div<TagsContainerProps>`
    top: -10px;
    padding: 0;
    ${({validProfile}) => !validProfile && `
        opacity: 0.5;
        pointer-events: none;` 
    }
`

export const ErrorContainer = styled.div`
    height: 24px;
    margin-top: -15px;
    color: #f16d84;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
`

