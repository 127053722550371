import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { HoursRecord } from '../../../models/HoursRecord';
import HttpService from '../../config/http';
import { CallbackType, DataType } from '../types';

interface ReqFilterProps {
	search: string;
	tarefa: string;
	pessoa: string;
	projeto?: string;
	startDate?: string;
	endDate?: string;
}

export default class HorasService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	atualizaHorasNoBotaoDeAtalho = () => {
		document.getElementById('atualiza-horas-atalho-btn')?.click();
	};
	getHorasPaginacao = (
		page: number,
		{
			search = '',
			tarefa = '',
			pessoa = ''
		}: ReqFilterProps,
		dataInicial: string,
		dataFinal: string,
		success: CallbackType<DataType<HoursRecord[]>>,
		errorCallback: CallbackType<string>,
		config?: AxiosRequestConfig
	) => {
		this.api
			.get(
				`api/v1/registra-hora/?page=${page}&paginate=${page}&ordering=-data&search=${search}&tarefa=${tarefa}&pessoa=${pessoa}&data__gte=${dataInicial}&data__lte=${dataFinal}&all=ocio`,
				config)
			.then(result => {
				if(result.status !== 499) {
					if (result.status === 200) {
						success(result.data);
					} else {
						if (typeof result.data === typeof {}) {
							for (const [, value] of Object.entries(result.data)) {
								throw new Error(`${value}`);
							}
						} else {
							throw new Error(t('Erro ao tentar carregar os registros!'));
						}
					}
				}
			})
			.catch(error => {
				if (error.name === 'AbortError') {
					console.log('Requisição cancelada');
				} else {
					errorCallback(error.toString())
				}
			});
	};

	getHorasById = (
		id: number,
		success: CallbackType<AxiosResponse<HoursRecord>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.get(
				`api/v1/registra-hora/${id}/?all=true`,
			)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t('Erro ao tentar carregar os registros!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getHoras = (
		{
			search = '',
			tarefa = '',
			pessoa = '',
			projeto ='',
			startDate = '',
			endDate = ''
		}: ReqFilterProps,
		success: CallbackType<AxiosResponse<HoursRecord[]>>,
		errorCallback: CallbackType<string>,
		config?: AxiosRequestConfig
	) => {
		this.api
			.get(
				`api/v1/registra-hora/?ordering=-data&search=${search}&tarefa=${tarefa}&pessoa=${pessoa}&projeto__in=${projeto}&data__gte=${startDate}&data__lte=${endDate}`, config
			)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t('Erro ao tentar carregar os registros!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	postHoras = (
		params: FormData, 
		success:CallbackType<AxiosResponse<HoursRecord>>, 
		errorCallback: CallbackType<string>
		) => {
		this.api
			.post('api/v1/registra-hora/', params)
			.then(result => {
				if (result.status === 201) {
					this.getHorasById(result.data.id, success, errorCallback,);
					this.atualizaHorasNoBotaoDeAtalho();
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t('Erro ao tentar salvar o registro!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchHoras = (
		params: FormData, 
		id: number, 
		success: CallbackType<AxiosResponse<HoursRecord>>, 
		errorCallback: CallbackType<string>
		) => {
		this.api
			.patchFile(`api/v1/registra-hora/${id}/?all=ocio`, params)
			.then(result => {
				if (result.status === 200) {
					this.getHorasById(result.data.id, success, errorCallback,);
					this.atualizaHorasNoBotaoDeAtalho();
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t('Erro ao tentar atualizar o registro!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteHoras = (
		id: number, 
		success: CallbackType<AxiosResponse<DataType<string>>>, 
		errorCallback: CallbackType<string>
		) => {
		this.api
			.delete(`api/v1/registra-hora/${id}/?all=ocio`)
			.then(result => {
				console.log(result)
				if (result.status === 204 || result.status === 200) {
					success(result);
					this.atualizaHorasNoBotaoDeAtalho();
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t('Erro ao tentar remover o registro!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
}
