import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import '../../../i18n';
import HttpService from '../../config/http';
import { CallbackType } from '../types';

interface FeedbackResponse {
	descricao: string;
	feedbackpessoafile_set: any[]
}

export default class FeedbackService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	postFeedbackComentario = (
		fileData: FormData, 
		success: CallbackType<AxiosResponse<FeedbackResponse>>, 
		errorCallback: CallbackType<string>
	) => {
		this.api
			.post(`api/v1/feedback/`, fileData, {
				headers: {
					"Content-Type": "multipart/form-data",
				}
			})
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t('Erro ao tentar salvar o feedback!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
}
