import styled from 'styled-components';

export const ModalContainerTime = styled.div`
	.modal-bg-center {
		padding-bottom: 32px;
	}
	.modal-width-time {
		width: 55vw;
		padding-right: 6px;
	}
`;
