import {
	faChevronDown,
	faChevronUp,
	faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import ProfileImg from '../../../assets/images/profile.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CalendarioGeralTable({
	pessoasHoras,
	diasDaSemana,
	classeImpaPar,
	setAtalhoPessoaHorasMensais,
	setListTarefasPessoas,
	setListRegistrosDataTarefaPessoas,
	setListDatasPessoas,
	calculaTotalHorasGeral,
	calculaTotalHorasEspecifico
}) {
	const {t} = useTranslation();
	const [listDatas, setListDatas] = useState([]);
	const [listTarefas, setListTarefas] = useState([]);
	const [listRegistrosDataTarefa, setListRegistrosDataTarefa] = useState([]);
	const [showRow, setShowRow] = useState(true);

	useEffect(() => {
		atribuiObjDataTarefa();
	}, [pessoasHoras]);


	const atribuiObjDataTarefa = () => {
		const listDatasAux = [];
		const listTarefasAux = [];
		pessoasHoras.registros.forEach(registro => {
			let datasDuplicadas =
				listDatasAux.findIndex(data => {
					return registro.data === data.data;
				}) !== -1;
			if (!datasDuplicadas) {
				listDatasAux.push({
					data: registro.data,
					registros: [],
				});
			}
			let tarefaDuplicada =
				listTarefasAux.findIndex(tarefa => {
					return registro.tarefa === tarefa.id;
				}) !== -1;
			if (!tarefaDuplicada) {
				listTarefasAux.push({
					id: registro.tarefa,
					nome: registro.tarefa_nome,
					cliente_nome: registro.cliente_nome,
					projeto_nome: registro.projeto_nome,
					pessoa_nome: registro.pessoa_nome,
					pessoa_foto: registro.pessoa_foto,
					registros: [],
				});
			}
		});
		montaObjPorTarefa(listTarefasAux, listDatasAux);
	};
	const montaObjPorTarefa = (listTarefasAux, listDatasAux) => {
		const listRegistrosDataTarefaAux = [];
		pessoasHoras.registros.forEach(registro => {
			listDatasAux.forEach(data => {
				if (registro.data === data.data) {
					data.registros.push(registro.duracao);
				}
			});

			listTarefasAux.forEach(tarefa => {
				if (registro.tarefa === tarefa.id) {
					tarefa.registros.push(registro.duracao);
				}
			});
		});
		listDatasAux.forEach(data => {
			listTarefasAux.forEach(tarefa => {
				listRegistrosDataTarefaAux.push({
					data: data.data,
					tarefa: tarefa.id,
					registros: [],
				});
			});
		});
		pessoasHoras.registros.forEach(registro => {
			listRegistrosDataTarefaAux.forEach(dataTarefa => {
				if (
					registro.data === dataTarefa.data &&
					registro.tarefa === dataTarefa.tarefa
				) {
					dataTarefa.registros.push(registro.duracao);
				}
			});
		});
		setListDatas(listDatasAux);
		setListTarefas(listTarefasAux);
		setListRegistrosDataTarefa(listRegistrosDataTarefaAux);

		setListDatasPessoas(prev => [...prev, listDatasAux])
		setListTarefasPessoas(prev => [...prev, listTarefasAux])
		setListRegistrosDataTarefaPessoas(prev => [...prev, listRegistrosDataTarefaAux])
	};

	return (
		<>
			<tbody
				style={{
					borderTop: showRow ? '' : '4px solid #c1c1c1',
					borderBottom: showRow ? '' : '4px solid #c1c1c1',
				}}
			>
				{listTarefas.map(tarefa => (
					<tr
						style={{
							display: showRow ? 'none' : '',
							userSelect: 'none',
							cursor: 'pointer',
						}}
						onClick={() => setShowRow(true)}
					>
						<th className="tarefa-cliente-projeto-th-calendario-visual">
							<span className="tarefa-th-calendario">{tarefa.nome}</span>
							<br />

							{tarefa.projeto_nome !== '' &&
								`${tarefa.cliente_nome} - ${tarefa.projeto_nome}`}
						</th>
						{diasDaSemana.map((data, index) => (
							<td
								className="calendario-td-hora"
								style={{
									backgroundColor:
										index === 0 || index === 6 ? '#f1f1f1' : '#fff',
								}}
							>
								{listRegistrosDataTarefa.map(dataTarefa => {
									if (
										dataTarefa.tarefa === tarefa.id &&
										dataTarefa.data === data.data &&
										dataTarefa.registros.length !== 0
									) {
										return calculaTotalHorasEspecifico(dataTarefa.registros);
									}
								})}
							</td>
						))}

						<td
							style={{
								fontWeight: 'bold',
								textAlign: 'center',
								background: '#fff',
								borderTop: '1px solid #c1c1c1',
							}}
						>
							{calculaTotalHorasEspecifico(tarefa.registros)}
						</td>
					</tr>
				))}
				<tr className="relatorio-visual-linha">
					<td
						className={
							classeImpaPar
								? 'relatorio-visual-nome'
								: 'relatorio-visual-nome-odd'
						}
					>
						<div className="relatorio-visual-user-container">
							<span style={{ display: 'flex' }}>
								{pessoasHoras.pessoa_nome}
								<FontAwesomeIcon
									icon={showRow ? faChevronUp : faChevronDown}
									className="icon-relatorio-mensal"
									style={{
										userSelect: 'none',
										cursor: 'pointer',
									}}
									title={t('Mais detalhes')}
									onClick={() => setShowRow(!showRow)}
								/>
								<Link
									to={{
										pathname: `/relatorios/total_horas`,
										state: {
											value: pessoasHoras.pessoa_id,
											label: pessoasHoras.pessoa_nome,
										},
									}}
									onClick={() =>
										localStorage.setItem(
											'state relatorio atalho',
											JSON.stringify({
												value: pessoasHoras.pessoa_id,
												label: pessoasHoras.pessoa_nome,
											}),
										)
									}
									className="projetos-link-tarefa"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon
										icon={faExternalLinkAlt}
										className="icon-relatorio-mensal"
										style={{
											userSelect: 'none',
											cursor: 'pointer',
										}}
										title={t('Ver horas mensais')}
									/>
								</Link>
							</span>
							<span>
								<img
									className="relatorio-visual-foto"
									alt={pessoasHoras.pessoa_nome}
									src={
										pessoasHoras.pessoa_foto !== null
											? pessoasHoras.pessoa_foto
											: ProfileImg
									}
								></img>
							</span>
						</div>
					</td>
					{diasDaSemana.map((dia, index) => (
						<td className="total-col-calendario-visual">
							{listDatas.map(data => {
								if (dia.data === data.data) {
									return calculaTotalHorasEspecifico(data.registros);
								}
							})}
						</td>
					))}
					<th
						id={
							classeImpaPar
								? 'relatorio-visual-total-table-odd'
								: 'relatorio-visual-total-table'
						}
					>
						{calculaTotalHorasGeral(pessoasHoras)}
					</th>
				</tr>
			</tbody>
		</>
	);
}
