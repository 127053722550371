import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RowWrapper } from "../../../../components/tailwindCommon/Table/RowWrapper";
import { FaCaretDown, FaCaretUp, FaUsers } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { ObjetivosRow } from "../ObjetivosRow";
import { NotFoundRow } from "../../../../components/tailwindCommon/Table/NotFoundRow";
import { LoadingRow } from "../../../../components/tailwindCommon/Table/LoadingRow";

import { TimeResponse } from "../../../../services/providers/okrs/times/types";
import { useAtom, useSetAtom } from "jotai";
import { LoadMoreRow } from "../../../../components/tailwindCommon/Table/LoadMoreRow";
import { filtrosTimesOkrsAtom, objetivosManagerAtom, timeModalAtom } from "../../../../atoms/Okrs";
import { useUser } from "../../../../contexts/User";
import PeopleCell from "../../../../components/common/Table/Cells/PeopleCell";

interface TimeRowProps {
    readonly time: TimeResponse
    readonly getObjetivosPagination: (page:number, timeId: number) => Promise<void>
    readonly getKeyResultsPagination: (page:number, objetivoId: number) => Promise<void>
    readonly refreshProgresso: ({timeId, objetivoId}: {timeId: number, objetivoId?: number}) => void;
    readonly openTeams: number[];
    readonly setOpenTeams: Dispatch<SetStateAction<number[]>>;
}

export function TimeRow({time, getObjetivosPagination, openTeams, setOpenTeams, getKeyResultsPagination, refreshProgresso}: TimeRowProps) {
    const user = useUser().userDetails;
    const isMember = time.time.find(item => item.id === user?.id_pessoa);
    const isObjetivoOpen = openTeams.includes(time.id)
    const [loadingObjetivos, setLoadingObjetivos] = useState(false)
    const [objetivosManager,] = useAtom(objetivosManagerAtom)
	const [timesFilters, ] = useAtom(filtrosTimesOkrsAtom)

    const setTimeModalState = useSetAtom(timeModalAtom)

    const timeModalEdit = () => {
        setTimeModalState({
			editing: true,
			selectedItem: time,
			isModalOpen: true
        })
	};

    const handlePagination = (page: number) => {
        setLoadingObjetivos(true)
        getObjetivosPagination(page, time.id).finally(() => {
            setLoadingObjetivos(false)
        })
    }
    
    useEffect(() => {
        if(isObjetivoOpen) {
            handlePagination(objetivosManager.objetivosManager[time.id]?.currentPage ?? 1)
        }
    }, [isObjetivoOpen])

    useEffect(() => {
        if(isObjetivoOpen) {
            handlePagination(1)
        }
    }, [timesFilters.ciclo]);

    const handleOpenTeam = () => {
        setOpenTeams(prev => {
            if(prev.includes(time.id)) {
                return prev.filter(item => item !== time.id)
            }else {
                return [...prev, time.id]
            }
        })
    }

    return (
        <div>
            <RowWrapper className='relative bg-ivory-okr-bg-1 text-white'>
                {isMember && (
                    <div className="absolute left-0 bg-ivory-green w-3 h-full" />
                )}

                <button type="button" className='col-span-5 flex items-center gap-2' onClick={handleOpenTeam}>
                    <div className="flex gap-2">
                        {isObjetivoOpen ? (
                            <FaCaretDown className='h-5 w-5 '  />
                        ) : (
                            <FaCaretUp className='h-5 w-5 '  />
                        )}

                        <FaUsers className='h-5 w-5 ' />
                    </div>

                    <div>
                        <p className="text-left">{time.nome}</p>
                    </div>
                </button>
                
                <div className='col-span-1'>
                    <PeopleCell
                        people={time.time}
                        max={4}
                        />
                </div>
                
                <div className='col-span-2 flex items-center gap-2'>
                    <div className='relative w-3/5 flex items-center'>	
                        <div className='h-3 w-full bg-slate-300 rounded-full'></div>
                        <div className={`absolute left-0 h-3 bg-ivory-green rounded-full`} style={{width: `${time.progresso}%`}}></div>
                    </div>
                    <p className={`min-w-[44px] text-right ${time.progresso >= 100 ? "text-ivory-green font-bold" : ""}`}>{time.progresso}%</p>
                </div>
                
                <div className='col-span-1'>
                    <p>{time.total_tarefas_okr} {time.total_tarefas_okr === 1 ? "tarefa" : "tarefas"}</p>
                </div>
                
                <div className='col-span-1 flex items-center'>
                    <button type="button" className='flex items-center justify-center p-0 m-0 w-7 h-7 bg-ivory-green rounded-md' onClick={timeModalEdit}>
                        <MdEdit className='w-5 h-5 text-white'  />
                    </button>
                </div>
            </RowWrapper>

            {isObjetivoOpen && (
                <>
                    {objetivosManager.objetivosManager[time.id]?.objetivos?.map(objetivo => {
                        return(
                            <ObjetivosRow 
                                key={objetivo.id} 
                                objetivo={objetivo} 
                                refreshProgresso={refreshProgresso}
                                getKeyResultsPagination={getKeyResultsPagination}
                            />
                        )
                    })}

                    {objetivosManager.objetivosManager[time.id]?.objetivos?.length === 0 && !loadingObjetivos && (
                        <NotFoundRow text="Nenhum objetivo encontrado." />
                    )}

                    {loadingObjetivos && (
                        <LoadingRow />
                    )}

                    {objetivosManager.objetivosManager[time.id]?.totalPages !== objetivosManager.objetivosManager[time.id]?.currentPage && (
                        <LoadMoreRow 
                            handlePagination={() => handlePagination((objetivosManager.objetivosManager[time.id]?.currentPage ?? 0) + 1)} 
                            hideLoader={false }
                            loading={loadingObjetivos}
                        />
                    )}
                </>
            )}        
        </div>
    )
}