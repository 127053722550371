import { faClock, faComment } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import pessoaImage from '../../../../../../../assets/images/profile.png';
import { ActionsMenu } from '../../../../../../../components/common/ActionsMenu';
import { useUser } from '../../../../../../../contexts/User';
import { History } from '../../../../../../../models/History';
import HoraService from '../../../../../../../services/providers/hour';
import {
	ActionsContainer,
	ActionsMenuContainer,
	CommentInput,
	HistoryBoxComment,
	HistoryBoxHour,
	HistoryContainer,
	HourDescription,
	HourDescriptionContainer,
	HourDetail,
	HourHistoryDetails,
	InfoHistory,
	InfoHistoryIcon,
	PessoaContainer,
	PessoaContent,
	PessoaImage,
	PessoaInfoContainer,
	PessoaNome,
	SubmitButton,
	TimeContainer,
	TimeLabel,
} from './styled';

interface TooltipHistoricoProps {
	data: History;
	handleDeletar: (id: number) => void;
	handleEditarComentario: (values: { descricao: string }, id: number) => void;
	_getTarefaHistorico: () => void;
	handleEdit: (data: History) => void;
}

export const TooltipHistorico = ({
	_getTarefaHistorico,
	data,
	handleDeletar,
	handleEdit,
	handleEditarComentario,
}: TooltipHistoricoProps) => {
	const [handleEditComentario, setHandleEditComentario] = useState<boolean>(
		false,
	);
	const [ComentarioTarefa, setComentarioTarefa] = useState('');
	const { userDetails} = useUser();

	useEffect(() => {
		setComentarioTarefa(data.descricao);
	}, []);


	const converterDuracaoHoras = (horaDuracao: string) => {
		const horasSlice = horaDuracao.split(':');
		const horas = horasSlice[0];
		const minutos = horasSlice[1];
		return `${horas}:${minutos}`;
	};

	const _deleteHoras = (id: number) => {
		const horaService = new HoraService();
		horaService.deleteHoras(
			id,
			(response: any) => {
				if (response) {
					toast.success(`${t('Registro de hora removido com sucesso')}!`);
					_getTarefaHistorico();
				}
			},
			(error: any) => {
				toast.error(error);
			},
		);
	};

	const handleDeleteHour = useCallback(() => {
		_deleteHoras(data.id_objeto);
	}, [data, _deleteHoras]);

	const handleEditHour = useCallback(() => {
		handleEdit(data);
	}, [handleEdit, data]);

	const handleDeleteComment = useCallback(() => {
		handleDeletar(data.id_objeto);
		_getTarefaHistorico();
	}, [handleDeletar, _getTarefaHistorico, data]);

	const handleEditComment = useCallback(() => {
		setHandleEditComentario(true);
		const txtComment = document.getElementById(
			`txtComment-input-${data.id}`,
		) as HTMLInputElement;
		txtComment.disabled = false;
		txtComment.focus();
	}, [setHandleEditComentario, document]);

	const handleCommentChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setComentarioTarefa(e.target.value);
		},
		[setComentarioTarefa],
	);

	const handleBlurComment = useCallback(
		() => {
			const txtComment = document.getElementById(
				`txtComment-${data.id}`,
			) as HTMLInputElement;
			txtComment.click();
		},
		[document, data],
	);

	const handleFocusComment = useCallback(() => {
		setHandleEditComentario(true);
	}, [setHandleEditComentario]);

	const handleKeyUpComment = useCallback(() => {
		setHandleEditComentario(false);
		_getTarefaHistorico();
	}, [setHandleEditComentario, _getTarefaHistorico]);

	return (
		<>
			{data.tipo === 'm' ? (
				// Comentario
				<HistoryContainer >
					<InfoHistory>
						<InfoHistoryIcon icon={faComment} /> {t('Comentário')}
						<TimeContainer>
							{moment(data.created_at).format('L')}{' '}
							{moment(data.created_at).format('LT')}
						</TimeContainer>
					</InfoHistory>
					<HistoryBoxComment>
						<PessoaContainer>
							<PessoaContent>
								<PessoaInfoContainer>
									<PessoaImage
										src={
											data.pessoa.foto_perfil !== null
												? data.pessoa.foto_perfil
												: pessoaImage
										}
										alt="erro"
									/>
									<PessoaNome>{data.pessoa.nome}</PessoaNome>
								</PessoaInfoContainer>
								{data.pessoa.id === userDetails?.id_pessoa ? (
									<ActionsMenuContainer>
										<ActionsMenu
											finishOption={false}
											handleDelete={handleDeleteComment}
											handleEdit={handleEditComment}
											hasSeparator={false}
										/>
									</ActionsMenuContainer>
								) : null}
							</PessoaContent>
						</PessoaContainer>

						<form
							role={'form'}
							onSubmit={event => {
								event.preventDefault();
								handleEditarComentario(
									{ descricao: ComentarioTarefa },
									data.id_objeto,
								);
								setHandleEditComentario(false);
								_getTarefaHistorico();
							}}
						>
							<SubmitButton id={`txtComment-${data.id}`} />
							<CommentInput
								id={`txtComment-input-${data.id}`}
								autoComplete={'off'}
								type="text"
								name="search"
								value={ComentarioTarefa}
								onChange={handleCommentChange}
								disabled={true}
								onBlur={handleBlurComment}
								onFocus={handleFocusComment}
								onKeyUp={handleKeyUpComment}
								editing={handleEditComentario}
							/>
						</form>
					</HistoryBoxComment>
				</HistoryContainer>
			) : (
				// Horas
				<HistoryContainer>
					<InfoHistory>
						<InfoHistoryIcon icon={faClock} /> {t('Horas')}
						<TimeContainer>
							<TimeLabel>{t('Registrado em:')} </TimeLabel>
							{moment(data.created_at).format('L')}{' '}
							{moment(data.created_at).format('LT')}
						</TimeContainer>
					</InfoHistory>
					<HistoryBoxHour>
						<PessoaContainer>
							<PessoaContent>
								<div>
									<HourDescriptionContainer
										hasDescription={Object.prototype.hasOwnProperty.call(data, "descricao")}
									>
										<PessoaInfoContainer>
											<PessoaImage
												src={
													data.pessoa.foto_perfil !== null
														? data.pessoa.foto_perfil
														: pessoaImage
												}
												alt="erro"
											/>
											<PessoaNome>{data.pessoa.nome}</PessoaNome>
										</PessoaInfoContainer>

										<HourHistoryDetails>
											{t('Id')}:<HourDetail>{data.id_objeto}</HourDetail>
											{'     '}
											{t('Horas apontadas')}:
											<HourDetail>
												{converterDuracaoHoras(data.duracao)}
											</HourDetail>
											{'      '}
											{t('Data apontada')}:
											<HourDetail>{moment(data.data).format('L')}</HourDetail>
										</HourHistoryDetails>
									</HourDescriptionContainer>
									<HourDescription>{data.descricao}</HourDescription>
								</div>
								{data.pessoa.id === userDetails?.id_pessoa ? (
									<ActionsContainer>
										<ActionsMenu
											finishOption={false}
											handleDelete={handleDeleteHour}
											handleEdit={handleEditHour}
											hasSeparator={false}
										/>
									</ActionsContainer>
								) : null}
							</PessoaContent>
						</PessoaContainer>
					</HistoryBoxHour>
				</HistoryContainer>
			)}
		</>
	);
};
