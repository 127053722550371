import styled from 'styled-components';

export const LoaderContainer = styled.div`
	margin: 30px 0;
	width: 100%;
	display: flex;
	justify-content: center;
`;
export const LoaderButton = styled.button`
	display: flex;
	justify-content: space-between;
	border: none;
	outline: none;
	background: white;
	color: #99adb9;
	border-radius: 3px;
	font-size: 14px;
	width: 140px;
	padding: 5px 10px;
	box-shadow: 0px 3px 6px #0000000f;
`;

export const LoaderLoading = styled.span`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
`;

export const LoaderAlign = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	color: black;
`;

interface LoaderContentProps {
	color?: string;
}

export const LoaderContent = styled.div<LoaderContentProps>`
	border: 5px solid #e0dfdf;
	border-radius: 50%;
	border-top: 5px solid #5ad8e3;
	width: 22px;
	height: 22px;
	animation: spin 0.6s linear infinite;
	/* background: ${({color}) => color ? color : "initial"}; */
`;
