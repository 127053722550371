import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import AuthService from '../../../services/security/auth';
// import targetIcon from '../../../assets/svgs/target.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBars,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
	faUser,
} from '@fortawesome/free-regular-svg-icons';

import { useKanban } from '../../../contexts/Kanban/Kanban';
import { 
	ControlMe, 
	HeaderSmall, 
	InputCheck, 
	LogoutContainer, 
	MenuLabel, 
	ProfileContainer, 
	ProfileDefaultImage, 
	ProfileTextContainer, 
	TopBarContainer, 
} from './styled';

import { t } from 'i18next';
import '../../../i18n';
import { useUser } from '../../../contexts/User';
import { PlanusLogo } from '../../../assets/svgs/iconsComponents/PlanusLogo';
import { HomeIcon } from '../../../assets/svgs/iconsComponents/HomeIcon';
import { ClientsIcon } from '../../../assets/svgs/iconsComponents/ClientsIcon';
import { ProjectsIcon } from '../../../assets/svgs/iconsComponents/ProjectsIcon';
import { ManageAccountIcon } from '../../../assets/svgs/iconsComponents/ManageAccountIcon';
import { ReportsIcon } from '../../../assets/svgs/iconsComponents/ReportsIcon';
import { OkrsIcon } from '../../../assets/svgs/iconsComponents/OkrsIcon';
import { RecordsIcon } from '../../../assets/svgs/iconsComponents/RecordsIcon';
import { TasksIcon } from '../../../assets/svgs/iconsComponents/TasksIcon';
import { useProject } from '../../../contexts/Project';
import { useAtomValue } from 'jotai';
import { selectedHeaderOkrsAtom } from '../../../atoms/Okrs';
import { LogoutIcon } from '../../../assets/svgs/iconsComponents/LogoutIcon';


export default function HeaderContainer() {
	const service = new AuthService();
	const { selectedHeaderLink} = useKanban();
	const [checked, setChecked] = useState(false);
	const { userDetails, setUserDetails } = useUser();

	const { selectedProjectLink } = useProject();
  const selectedHeaderOkrs  = useAtomValue(selectedHeaderOkrsAtom)

	useEffect(() => {
			window.addEventListener('resize', checkMenu);
	});

	const checkMenu = useCallback(() => {
		const elem = document.getElementById('check-small') as HTMLInputElement;
		elem.checked = false;
		setChecked(false);
	}, [setChecked])

	const handleToggleMenu = useCallback((e: React.ChangeEvent) => {
		const target = e.target as HTMLInputElement;
		setChecked(target.checked);
	}, [setChecked])

	const handleShowInfoPessoa = useCallback(() => {
		document.getElementById('show-infoPessoa-modal')?.click();
	}, []);

  const resetUser = () => {
		setUserDetails(null);
	}

  const getStyle = () => {
		const PlanusVisualizacao = localStorage.getItem('PlanusVisualizacao');
		if(PlanusVisualizacao === 'g') {
			return 'registros/projetos';
		}else if(PlanusVisualizacao === 'l') {
			return 'registros/projetos-list';
		}else if(PlanusVisualizacao === 'k') {
			return 'registros/projetos-kanban';
		}
			
	}
	
	return (
		<>
			<HeaderSmall>
				<div>
					<PlanusLogo className='absolute left-9 top-4 h-9' />
				</div>
				<div />
				<div>
					<InputCheck type="checkbox" id="check-small" onChange={handleToggleMenu} />

					<MenuLabel htmlFor={'check-small'} isCheked={checked} title={t('Abrir/Fechar menu').toString()}>
						<div id="open-close-small">
							<FontAwesomeIcon icon={faBars} className="bars-icon" />
							<FontAwesomeIcon icon={faTimes} className="close-icon" />
						</div>
					</MenuLabel>
					<ControlMe isCheked={checked}/>
				</div>
			</HeaderSmall>
			<TopBarContainer className="top-bar-container" isCheked={checked} >
				<div className='top-bar bg-ivory-primary-light' >
          <SiderBarLink 
            Icon={HomeIcon} 
            mini={false}
            title='Início' 
            to='/home/calendar' 
            onClick={checkMenu}
          />
            <SiderBarLink 
              Icon={ClientsIcon} 
              mini={false}
              title='Clientes'  
              to={'/clientes'} 
              onClick={checkMenu}
            />
            <SiderBarLink 
              Icon={ProjectsIcon} 
              mini={false}
              title='Projetos'  
              to={`${selectedProjectLink === 'registros/projetos' ? getStyle() : selectedProjectLink}`} 
              onClick={checkMenu}
            />
            <SiderBarLink 
              Icon={TasksIcon} 
              mini={false}
              title='Tarefas'  
              to={{
                pathname: `/registros/${selectedHeaderLink}`,
                state: { fromHome: true },
              }} 
              onClick={checkMenu}
            />
            <SiderBarLink 
              Icon={RecordsIcon} 
              mini={false}
              title='Horas'  
              to={'/registros/horas'} 
              onClick={checkMenu}
            />
            <SiderBarLink 
              Icon={OkrsIcon} 
              mini={false}
              title='OKRs'  
              to={`/okrs/${selectedHeaderOkrs}`} 
              onClick={checkMenu}
            />   
            <SiderBarLink 
              Icon={ReportsIcon} 
              mini={false}
              title='Relatórios'  
              to={'/relatorios'} 
              onClick={checkMenu}
            />  
          {userDetails?.acesso_cod === 'a' && 
              <SiderBarLink 
                Icon={ManageAccountIcon
                } 
                mini={false}
                title='Pessoas'  
                to={'/pessoas'} 
                onClick={checkMenu}
              /> 
          }
					<SiderBarButton mini={false} onClick={() => service.logout(resetUser)} Icon={LogoutIcon} title='Sair' />

					<ProfileContainer>
						<div></div>
						<div
							className="top-profile-bottom"
							onClick={handleShowInfoPessoa}
						>
							<ProfileDefaultImage
								alt={userDetails?.username}
								src={userDetails?.foto_perfil}
							/>
							<ProfileTextContainer >
								<p className="top-profile-text">{userDetails?.username}</p>
								<LogoutContainer>
									<FontAwesomeIcon icon={faUser} className="top-faUser-icon" />
									<p className="top-profile-text-description">{t("Perfil")}</p>
								</LogoutContainer>
							</ProfileTextContainer>
						</div>
					</ProfileContainer>
				</div>
			</TopBarContainer>
		</>
	);
}

interface SiderBarLinkProps {
  title: string;
  to: any;
  mini: boolean;
  onClick?: () => void;
  Icon: (props: HTMLAttributes<HTMLOrSVGElement>) => JSX.Element
}

function SiderBarLink({Icon, title, to, mini, onClick}: Readonly<SiderBarLinkProps>) {

  return(
    <NavLink
      to={to}
      activeClassName="active-sidebar-button"
      className={'hover:no-underline'}
      title={t(title).toString()}
      onClick={onClick}
    >
        <div className={`nav-container relative flex items-center p-5 pl-8 hover:bg-ivory-primary ease-linear duration-100 ${!mini && 'gap-4'}`} >
          <div className='separator-style absolute left-0 w-2 h-full bg-ivory-green hidden'></div>
          <div className='h-5 w-5'>
            <Icon className={'svg-icon-style h-5 w-5 fill-white'}   />
          </div>
          <p className={`svg-icon-style font-medium text-xl text-white overflow-hidden ease-linear delay-100 duration-200  ${mini ? 'w-0 opacity-0' : 'w-auto opacity-100'}`}>
            {t(title)}
          </p>
        </div> 
    </NavLink>
  )
}

interface SiderBarButtonProps {
  title: string;
  mini: boolean;
  onClick: () => void;
  Icon: (props: HTMLAttributes<HTMLOrSVGElement>) => JSX.Element
}


function SiderBarButton({Icon, title, onClick, mini}: Readonly<SiderBarButtonProps>) {
  return(
    <button
      onClick={onClick}
      title={t(title).toString()}
    >
        <div className={`nav-container flex items-center p-5 pl-8 hover:bg-ivory-primary overflow-hidden ease-linear duration-100  ${!mini && 'gap-4'}`} >
          <div className='h-5 w-5'>
            <Icon className={'svg-icon-style h-5 w-5  fill-white'}   />
          </div>
          <p className={`svg-icon-style font-medium text-xl text-white overflow-hidden ease-linear delay-100 duration-200  ${mini ? 'w-0 opacity-0' : 'w-auto opacity-100'}`}>
            {t(title)}
          </p>
        </div> 
    </button>
  )
}