
import React, { HtmlHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface PageInsideContainerProps extends HtmlHTMLAttributes<HTMLDivElement>{}

export function PageInsideContainer({className,...props}: PageInsideContainerProps) {
    const classes = twMerge(
        "gap-5 overflow-y-auto h-full flex flex-col pt-5",
        className
    )

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}