import React, {  Dispatch, useCallback } from 'react';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

import '../../../i18n';

import './style.css';
import {
	FormGeral,
	Input,
	InputContainer,
	SearchIcon,
	SearchIconContainer,
} from './styled';
import { useTranslation } from 'react-i18next';

export interface SearchProps {
	getFunction: () => void;
	search: string;
	setSearch: Dispatch<string>;
	kanban?: boolean;
}

const Search = ({ getFunction, search, setSearch, kanban }: SearchProps) => {
	const {t} = useTranslation();
	const clickSearchIcon = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.preventDefault();
			getFunction();
		},
		[search],
	);

	const handleKeyUp = useCallback(() => {
		if (search === '') {
			getFunction();
		}
	}, [search]);

	const handleSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (search !== '') {
				getFunction();
			}
		},
		[search],
	);
	return (
		<FormGeral className={kanban ? 'kanban' : ''} onSubmit={handleSubmit}>
			<SearchIconContainer
				data-testid={"searchButton"}
				onClick={clickSearchIcon}
			>
				<SearchIcon icon={faSearch} />
			</SearchIconContainer>
			<InputContainer>
				<Input
					value={search}
					autoComplete={'off'}
					type="text"
					name="search"
					className="form-input-registros"
					placeholder={t("Pesquisar")}
					onChange={e => setSearch(e.target.value)}
					onKeyUp={handleKeyUp}
				/>
			</InputContainer>
			
		</FormGeral>
	);
};

export default Search;
