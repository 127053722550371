import React from 'react';

import { GroupBase, SelectInstance, StylesConfig } from 'react-select';
import { AsyncPaginate, AsyncPaginateProps } from 'react-select-async-paginate';
import {
	customStylesModal,
	customStylesSideModal,
} from '../../../utils/extenal-libs';
import ErrorMsg from '../ErrorMsg';

import { LoadOptionsAdditional, SelectOptions } from '../../../models/Select';
import { Label } from './styled';

export interface AsyncSelectorProps extends AsyncPaginateProps<
SelectOptions, GroupBase<SelectOptions>, LoadOptionsAdditional, boolean
> {
	title?: string;
	FieldName?: string;
	disabled?: boolean;
	style?: StylesConfig<SelectOptions, boolean>;
	touched?: boolean;
	error?: string;
	errorMessage?: boolean;
	labelClassName?: string;
	ref?: React.Ref<
		SelectInstance<SelectOptions, boolean, GroupBase<SelectOptions>>
	>;
}

const AsyncSelector = ({
	title,
	FieldName,
	disabled,
	loadOptions,
	additional,
	style,
	labelClassName,
	errorMessage,
	error,
	touched,
	onChange,
	value,
	menuIsOpen,
	isClearable = true,
	isMulti,
	components,
	blurInputOnSelect,
	openMenuOnFocus,
	ref,
	onFocus,
	hideSelectedOptions,
	defaultMenuIsOpen,
	autoFocus,
	onBlur,
	...rest
}: AsyncSelectorProps) => {
	return (
		<>
			{title && (
				<Label
					className={labelClassName || 'select-modal-label'}
					htmlFor={FieldName}
				>
					{title}
				</Label>
			)}

			<AsyncPaginate
				menuIsOpen={menuIsOpen}
				loadOptions={loadOptions}
				additional={{
					page: 1,
					paginate: true,
					...additional,
				}}
				name={FieldName}
				styles={
					style ||
					(error && touched ? customStylesModal : customStylesSideModal)
				}
				isDisabled={disabled}
				isClearable={isClearable}
				menuPosition="fixed"
				menuPlacement="auto"
				onChange={onChange}
				value={value}
				onBlur={onBlur}
				isMulti={isMulti}
				components={components}
				selectRef={ref}
				openMenuOnFocus={openMenuOnFocus}
				onFocus={onFocus}
				autoFocus={autoFocus}
				hideSelectedOptions={hideSelectedOptions}
				blurInputOnSelect={blurInputOnSelect}
				defaultMenuIsOpen={defaultMenuIsOpen}
				{...rest}
			></AsyncPaginate>
			{errorMessage && <ErrorMsg name={FieldName as string} />}
		</>
	);
};

export default AsyncSelector;
