import * as React from "react"
import { SVGProps } from "react"
const GraphDecreaseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="26" height="20" viewBox="0 0 26 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M25.8333 16.6667H3.33333V0.833333C3.33333 0.372917 2.96042 0 2.5 0H0.833333C0.372917 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.746354 20 1.66667 20H25.8333C26.2937 20 26.6667 19.6271 26.6667 19.1667V17.5C26.6667 17.0396 26.2937 16.6667 25.8333 16.6667Z"/>
    <path d="M24.9993 12.5001V6.35163C24.9993 5.23809 23.653 4.68027 22.8655 5.46777L21.178 7.15527L16.178 2.15527C15.5275 1.50423 14.4723 1.50423 13.8212 2.15527L9.99987 5.97663L7.60091 3.57715C7.27539 3.25163 6.74779 3.25163 6.42227 3.57715L5.24414 4.75527C4.91862 5.08079 4.91862 5.6084 5.24414 5.93392L8.82174 9.51152C9.47227 10.1626 10.5275 10.1626 11.1785 9.51152L14.9999 5.69017L18.8212 9.51204L17.1337 11.1995C16.3462 11.987 16.904 13.3334 18.0176 13.3334H24.1665C24.627 13.3334 24.9999 12.9605 24.9993 12.5001Z"/>
    </svg>


)
export default GraphDecreaseIcon
