import styled from 'styled-components';

export const Preview = styled.li`
	width: 100%;
	height: 100%;
	z-index: 1;
	border-radius: 5px;
	background-image: url(${props => props.uploadedFile.documento});
	background-color: #99adb9;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	/* margin-bottom: 20px; */
	border-radius: 8px;
	flex-direction: column;
	/* margin-right: 10px; */
`;
