import styled from "styled-components";

export const AddModalWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    border-radius: 8px;

    > div {
        margin-right: 0;
    }
`;

export const BackBox = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
`;

export const AddForm = styled.div`
    margin-bottom: 16px;
`;