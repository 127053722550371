import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import { KanbanAddSubtaskProps } from './types';

export function KanbanAddSubtask({setIsAdding, handleAddSubtask}: KanbanAddSubtaskProps) {
	const { t } = useTranslation();
    const [title, setTitle] = useState("");

    const addSubtask = () => {
        if(title.trim() === "") {
            return;
        }

        handleAddSubtask(title);
        setTitle("");
    };

    return (
        <div className='flex flex-col gap-3 my-2'>
            <div >
                <input 
                    className='p-1 rounded-sm w-full focus:ring-0 ring-0 border border-solid text-sm border-ivory-primary'
                    type='text' 
                    name='subtask-title' 
                    id='subtask-title' 
                    placeholder={t('Nome da Subtarefa').toString()}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    autoFocus
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            addSubtask();
                        }
                    }}
                />
            </div>
            
            <div className='flex flex-row gap-3'>
                <button 
                    type='button'
                    className='flex text-center px-2 py-1 bg-[#98ACB8] rounded-default font-semibold text-sm text-white'
                    onClick={() => setIsAdding(false)}
                >
                    {t('Cancelar')}
                </button>
                <button 
                    className='flex text-center px-2 py-1 bg-ivory-primary rounded-default font-semibold text-sm text-white'
                    type='button' 
                    onClick={addSubtask}
                >
                    {t('Salvar')}
                </button>
            </div>
        </div>
    )
}