import { SelectOptions } from "../../models/Select"
import { t } from "i18next";
import { HTMLInputTypeAttribute } from "react";
import { CellData } from "../../components/common/Table/types";



export const acessoOptions: SelectOptions[] = [
    {label: t("Membro"), value: 'm'},
    {label: t("Gerente de Projeto"), value: 'g'},
    {label: t('Administrador'), value: "a"},
    {label: t("Visitante"), value: "e"}
]



interface ExtraFieldTypeReturn {
    form: HTMLInputTypeAttribute;
    table:  'text' | 'select' | 'img' | 'toggle';
}

export const getExtraFieldType = (type: string): ExtraFieldTypeReturn => {
    switch(type) {
        case "boolean":
            return {form: "checkbox", table: "toggle"}

        case "number":
            return {form: "number", table: "text"}

        case "date":
            return {form: "date", table: "text"}

        default:
            return {form: "text", table: "text"}
    }
}

export const initialHeaderData: CellData[] = [
    { title: '', isDragDisabled: true },
		{ title: 'E-mail', isDragDisabled: true },
		{ title: 'Perfil do usuário', isDragDisabled: true },
		{ title: 'Carga Horária', isDragDisabled: true },
		{ title: 'Custo Hora', isDragDisabled: true },
		{ title: 'Ativo', isDragDisabled: true },
		{ title: 'Último login', isDragDisabled: true },
]