import React from 'react';

interface PeopleCellProps {
	people: { nome: string; foto_perfil: string | null }[];
	noPeopleMessage?: string;
	max?: number;
}

export default function PeopleCell({ people, noPeopleMessage, max = 4}: Readonly<PeopleCellProps>) {
	if(people.length === 0) {
		return <td className='px-2'>
			{noPeopleMessage ?? 'Sem responsáveis'}
		</td>
	}
	return (
		<td className='px-2'>
			{people.length === 1 ? (
				<div className="flex flex-row gap-3 items-center">
					<img
						alt={`profile-${people[0].nome}`}
						className="w-7 h-7 rounded-full"
						src={
							people[0].foto_perfil ??
							require('../../../../../assets/images/profile.png')
						}
					></img>
					<span>{people[0].nome}</span>
				</div>
			) : (
				<div className="flex flex-row">
					{people.slice(0, max).map((person) => {
						return (
							<img
								key={person.nome}
								alt={person.nome}
								title={person.nome}
								className="w-7 h-7 mr-[-8px] rounded-full"
								src={
									person.foto_perfil ??
									require('../../../../../assets/images/profile.png')
								}
							></img>
						);
					})}
					{people.length > max && (
						<div
							className="flex w-7 h-7 rounded-full items-center cursor-default justify-center mr-[-8px] bg-ivory-primary text-white font-semibold"
							title={people
								.map((item) => item.nome)
								.slice(3, people.length - 1)
								.join(', ')}
						>
							+{people.length - max}
						</div>
					)}
				</div>
			)}
		</td>
	);
}
