import * as React from "react"
import { SVGProps } from "react"
const GraphCheckIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="26" height="20" viewBox="0 0 26 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.83075 18.6268L0.380745 10.3462C-0.126915 9.84867 -0.126915 9.04205 0.380745 8.54451L2.21918 6.74287C2.72684 6.24533 3.55001 6.24533 4.05767 6.74287L9.75 12.3211L21.9423 0.373116C22.45 -0.124372 23.2732 -0.124372 23.7808 0.373116L25.6193 2.17476C26.1269 2.67225 26.1269 3.47887 25.6193 3.97641L10.6692 18.6269C10.1615 19.1244 9.33841 19.1244 8.83075 18.6268Z"/>
    </svg>


)
export default GraphCheckIcon
