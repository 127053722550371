import React, { useCallback, useEffect, useRef, useState } from 'react';

import ProjetoService from '../../../../../services/providers/project';
import TarefaService from '../../../../../services/providers/task';
import { TarefaSchema } from './validation';

import {
	faAngleRight,
	faExclamationTriangle,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import './style.css'

import AsyncSelector from '../../../../../components/common/AsyncSelector';
import { useKanban } from '../../../../../contexts/Kanban/Kanban';
import { SubmitValues, TarefaFormProps } from './types';
import { LoadOptionsAdditional, SelectOptions } from '../../../../../models/Select';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue, SingleValue } from 'react-select';
import { useFilters } from '../../../../../contexts/Filter/Task/Filter';
import { PostPatchParams, PostPatchResponse } from '../../../../../services/providers/task/types';
import { ButtonDetailTask , EmailAlert, EmailLabel, ExtraAddButtons, FormikField, IconDetailTask, InputError, LinkDetailTask, ModalBgCenter, ModalLabel, TextAreaField } from './styled';
import { t } from 'i18next';
import { useDataTask } from '../../../../../contexts/InfoTarefa/DataTask';
import { useUser } from '../../../../../contexts/User';

export const TarefaForm = (
	{editing,
	fromKanban,
	getProjeto,
	handleLoading,
	handlePostPatchTarefa,
	selectedTeam,
	setIsVisible,
	saveAndContinue,
	task,
	handleAdicionar,
	selectedNewProject 
	}: TarefaFormProps) => {
	const [projetoSelectShow, setProjetoSelectShow] = useState<SelectOptions>();
	const {userDetails} = useUser();
	const { handleAddTaskModal } = useKanban();
	const {handleAfterPost} = useDataTask();
	//services
	const projectService = new ProjetoService();
	const history = useHistory();
	const { selectedProject } = useFilters();


	const verificaData = () => {
		const dateTeste = moment(new Date());
		dateTeste.add(1, 'days');
		return dateTeste.format('YYYY-MM-DD');
	};

	let initialValues: any = null;
	if (typeof task !== 'undefined') {
		task.data_conclusao = task?.data_conclusao?.slice(
			0,
			10,
		);
		initialValues = {
			projeto: task.projeto,
			nome: task.nome,
			data_conclusao: task.data_conclusao,
			descricao: task.descricao,
			responsaveis: task.pessoas_responsaveis,
			alertar_responsaveis: false,
			teste: '',
		};
	} else {
		initialValues = {
			projeto: selectedNewProject?.value || "",
			nome: '',
			data_conclusao: verificaData(),
			descricao: '',
			responsaveis: [],
			alertar_responsaveis: false,
			teste: '',
		};
	}

	const _postTarefa = (params: PostPatchParams) => {
		handleLoading(true);
		const tarefaService = new TarefaService();
		tarefaService.postTarefa(
			params,
			(response: PostPatchResponse) => {
				if (response) {
					toast.success('Tarefa salva com sucesso!');
					setIsVisible(!!saveAndContinue);
					handleAfterPost(response);

					if(fromKanban) {
						handleAddTaskModal(response);
					}
				}
				handleLoading(false);
			},
			(error: any) => {
				toast.error(error);
				handleLoading(false);
			},
		);
	};

	const _patchTarefa = (params: PostPatchParams, id: number | string) => {
		handleLoading(true);
		const tarefaService = new TarefaService();
		tarefaService.patchTarefa(
			params,
			id,
			(response: PostPatchResponse) => {
				if (response) {
					toast.success('Tarefa atualizada com sucesso!');
					setIsVisible(false);
					handlePostPatchTarefa(response, false);
				}
				handleLoading(false);
			},
			(error: any) => {
				toast.error(error);
				handleLoading(false);
			},
		);
	};

	const _getProjetosPorPessoasID = (e:  number | string) => {
		getProjeto(e);
	};

	const handleSubmit = useCallback((values: SubmitValues) => {
		const dataConvertida = moment(values.data_conclusao, 'YYYY-MM-DD').format();
		const params: PostPatchParams = {
			alertar_responsaveis: values.alertar_responsaveis,
			data_conclusao: dataConvertida,
			descricao: values.descricao,
			nome: values.nome, 
			projeto: values.projeto,
			responsaveis: selectedTeam.toString(),
		}
		if (editing && task) {
			_patchTarefa(params, task.id);
		} else {
			_postTarefa(params);
		}
		values['nome'] = '';
		values['descricao'] = '';
	}, [_patchTarefa, _postTarefa, selectedTeam, editing, task, moment]);

	const formikRef = useRef<FormikProps<SubmitValues>>(null);

	const handleChangeProject = useCallback(
		(event:  MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
			const e = event as SelectOptions;
			setProjetoSelectShow(e);
			formikRef?.current?.setFieldValue('projeto', e !== null ? e.value : '');
			_getProjetosPorPessoasID(e !== null ? e.value : '');
	}, [setProjetoSelectShow, _getProjetosPorPessoasID])

	useEffect(() => {
		if (editing && task) {
			setProjetoSelectShow({
				value: task.projeto,
				label: task.projeto_nome as string,
			});
		}
	}, []);

	useEffect(() => {
		const selected = Array.isArray(selectedProject) ? selectedProject : [selectedProject]
		if (selected.length > 0 && selected[0].value !== '' ) {
			initialValues.projeto = selected[0].value;
			setProjetoSelectShow(selected[0]);
			_getProjetosPorPessoasID(selected[0].value);
		}
	}, [selectedProject]);
	
	useEffect(() => {
		if (selectedNewProject) {
			setProjetoSelectShow(selectedNewProject);
			formikRef?.current?.setFieldValue('projeto', selectedNewProject.value);
		}
	}, [selectedNewProject]);
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={TarefaSchema}
			onSubmit={handleSubmit}
			innerRef={formikRef}
		>
			{props => {
				const { handleSubmit, errors, touched } = props;
				return (
					<form onSubmit={handleSubmit}>
						<fieldset>
							<ModalBgCenter>
								<div className="form-container-style-tarefas container">
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6">
											<ExtraAddButtons>
												{!editing && (userDetails?.acesso_cod === 'a' || userDetails?.acesso_cod === 'g')  ? (
													<div
														className="modal-botao modal-add-task"
														onClick={() => handleAdicionar()}
													>
														<FontAwesomeIcon
															icon={faPlus}
															className="plus-modal-icon trash-modal-icon"
														/>
														{t("Adicionar Projeto")}
													</div>
												) : null}

												{task &&
													history.location.pathname !==
														`/registros/tarefas/${
															task.id
														}/${task.nome
															.replace(/\s/g, '')
															.replace(/[/\\]/g,'')}` && (
													<ButtonDetailTask>
														<LinkDetailTask
															to={{
																pathname: `/registros/tarefas/${
																	task.id
																}/${task.nome
																	.replace(/\s/g, '')
																	.replace(/[/\\]/g,'')}`,
																state: fromKanban
																	? {
																			urlBack: history.location.pathname,
																			fromKanban: true,
																	}
																	: {
																			task: task,
																			urlBack: history.location.pathname,
																	},
															}}
														>
															{t('Detalhes da Tarefa')}
															<IconDetailTask
																icon={faAngleRight}
															/>
														</LinkDetailTask>
													</ButtonDetailTask>
												)}
											</ExtraAddButtons>

											<AsyncSelector
												loadOptions={projectService.loadProjectsSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional> }
												additional={{
													page: 1,
												}}
												title={`${t('Projeto')}*`}
												id={'projeto'}
												FieldName={'projeto'}
												value={projetoSelectShow}
												onChange={handleChangeProject}
												placeholder={t('Selecione um Projeto') ?? undefined}
												error={errors.projeto as string}
												touched={touched.projeto as boolean}
												errorMessage
											/>

											<ModalLabel  htmlFor={'nome'}>
												{t('Nome da Tarefa')}*
											</ModalLabel>
											<FormikField
												id="nome"
												autoComplete={'off'}
												type="text"
												name="nome"
												error={errors.nome}
												touched={touched.nome}
												
											/>
											<ErrorMessage name="nome">
												{msg => (
													<div>
														<InputError role="alert">
															<FontAwesomeIcon
																icon={faExclamationTriangle}
																className="mr-1"
															/>
															{msg}
														</InputError>
													</div>
												)}
											</ErrorMessage>

											<ModalLabel htmlFor={'data_conclusao'}>
												{t('Data de Entrega')}*
											</ModalLabel>
											<FormikField
												type="date"
												name="data_conclusao"
												id='data_conclusao'
												autoComplete={'off'}
												error={errors.data_conclusao}
												touched={touched.data_conclusao}
		
											/>

											<ErrorMessage name="data_conclusao">
												{msg => (
													<div>
														<InputError role="alert">
															<FontAwesomeIcon
																icon={faExclamationTriangle}
																className="mr-1"
															/>
															{msg}
														</InputError>
													</div>
												)}
											</ErrorMessage>
										</div>

										<div className="col-12 col-md-6 col-lg-6">
											<ModalLabel  htmlFor={'descricao'}>
												{t('Descrição')}
											</ModalLabel>
											<TextAreaField
												// placeholder={'teste'}
												autoComplete={'off'}
												type="text"
												component="textarea"
												name="descricao"
												error={errors.descricao}
												touched={touched.descricao}
												id="descricao"
								
											/>
											<ErrorMessage name="descricao">
												{msg => (
													<div>
														<InputError role="alert">
															<FontAwesomeIcon
																icon={faExclamationTriangle}
																className="mr-1"
															/>
															{msg}
														</InputError>
													</div>
												)}
											</ErrorMessage>
										</div>
										<EmailAlert className="col-12">
											<Field
												// value={enviarEmail}
												type="checkbox"
												name="alertar_responsaveis"
												id='alertar_responsaveis'
												// onChange={() => setEnviarEmail(!enviarEmail)}
											/>
											<EmailLabel htmlFor='alertar_responsaveis'>
												{t('Notificar por email os responsáveis')}
											</EmailLabel>
										</EmailAlert>
									</div>
								</div>
							</ModalBgCenter>
							<br />
							<input
								style={{ display: 'none' }}
								id="input-submit-tarefa"
								type="submit"
								role={'button'}
							/>
						</fieldset>
					</form>
				);
			}}
		</Formik>
	);
};
