import React from 'react';

interface HourAddIconProps {
    color?: string;
    width?: string;
}

export default function HourAddIcon({color = 'black', width = '24'}: HourAddIconProps) {
	return (
		<svg
			fill="none"
			height={width}
			viewBox="0 0 24 24"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				d="M12 7V10.7639C12 11.5215 12.428 12.214 13.1056 12.5528L16 14"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				d="M19 16V19M19 22V19M19 19H16M19 19H22"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
		</svg>
	);
}
