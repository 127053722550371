import React, { useCallback } from 'react';

import FilterContainer from '../../../components/containers/Filter';
import { useAtom, useSetAtom } from 'jotai';
import { clientPageFiltersAtom, clientsPagesItemsAtom } from '../../../atoms/Client';

export interface ClientFilterProps {
  handleSearch: () => void;
}

export function ClientFilter({handleSearch}: Readonly<ClientFilterProps>) {
  const [clientFilter, setClientFilter] = useAtom(clientPageFiltersAtom)
  const setClients =  useSetAtom(clientsPagesItemsAtom);

  const handleChangeSearch = useCallback((value: string) => {
    setClientFilter(prev => {
      return {
        ...prev,
        search: value
      }
    })
  }, [setClientFilter])

  return (
    <FilterContainer
      filterOptions={[]}
      handleSearch={() => {
        setClients({clients: []})
        handleSearch()
      }}
      loadingFilters={clientFilter.loading}
      openFilters={[]}
      searchFilter={clientFilter.search}
      selectedFilters={{}}
      setSearchFilter={handleChangeSearch}
      setOpenFilters={() => {}}
      search={true}
    />
  )
}