import React, { createContext, useContext, useState } from 'react';

const initialValues = {
	isFilterOpen: true,
	setIsFilterOpen: (value) => {console.log(value)}
}

const FilterCommonStateContext = createContext(initialValues);

const FilterCommonStateProvider = ({ children }) => {
	const [isFilterOpen, setIsFilterOpen] = useState(true);

	return (
		<FilterCommonStateContext.Provider
			value={{
				isFilterOpen,
				setIsFilterOpen,
			}}
		>
			{children}
		</FilterCommonStateContext.Provider>
	);
};

function withFilterCommonStatew (Child){
	return function FilterComponent (props) {
		return (
			<FilterCommonStateContext.Consumer>
				{context => <Child {...props} {...context} />}
			</FilterCommonStateContext.Consumer>
		);
	}
} 

const useFilterCommonState = () => {
	const context = useContext(FilterCommonStateContext);
	const { isFilterOpen, setIsFilterOpen } = context;
	return {
		isFilterOpen,
		setIsFilterOpen,
	};
};

export {
	FilterCommonStateProvider,
	withFilterCommonStatew,
	useFilterCommonState,
};
