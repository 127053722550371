import React, { ReactNode } from 'react';

interface TRowPros {
	id?: string;
	children: ReactNode;
	className?: string;
}

export default function TRow({ id, children, className }: Readonly<TRowPros>) {
	return (
		<tr
			id={id}
			className={`border-b h-table-row border-solid border-b-border-gray text-ivory-primary bg-white text-xs [&>*:first-child]:pl-5 [&>*:last-child]:pr-5 ${className}`}
		>
			{children}
		</tr>
	);
}
