import React, { Dispatch, SetStateAction } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { t } from 'i18next';
import KanbanSkeleton from '../../../../pages/Registros/Tarefas/Kanban/KanbanSkeleton';
import { FaCirclePlus } from 'react-icons/fa6';

interface KanbanContainerProps {
	react_tour_id: string;
	onDragEnd: (result: DropResult) => void;
	children: React.ReactNode;
	isAddingColumn: boolean;
	setIsAddingColumn: Dispatch<SetStateAction<boolean>>;
	handleAddColumn: (title: string) => void;
	canEdit: boolean;
	loadingKanban: boolean;
	loadingFilters: boolean;
}

export default function KanbanContainer({
	react_tour_id,
	onDragEnd,
	children,
	isAddingColumn,
	setIsAddingColumn,
	handleAddColumn,
	canEdit,
	loadingKanban,
	loadingFilters
}: KanbanContainerProps) {

	return (
		loadingKanban ? <KanbanSkeleton />
		:
		(
			<div
				className={`flex-grow relative ${loadingFilters && 'cursor-progress'}`}
				id={react_tour_id}
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable
					droppableId="single-column"
					direction="horizontal"
					type="column"
				>
					{provided => (
						<div className='gap-4 overflow-x-auto flex overflow-y-hidden whitespace-nowrap inset-0  absolute '
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{children}
							<div className='flex h-min items-center justify-center ml-2'>
								{isAddingColumn && (
									<input
										className='focus:border focus:border-solid focus:border-ivory-primary focus:outline-none rounded px-1'
										type="text"
										name="add-column"
										title="Adicionar coluna"
										autoComplete="off"
										onBlur={() => setIsAddingColumn(false)}
										onKeyDown={e => {
											const target = e.target as HTMLInputElement;
											if (e.key === 'Enter') {
												handleAddColumn(target.value);
												target.value = '';
											}
										}}
										autoFocus
									/>
								)}
								{canEdit && (
									<button
										className="bg-ivory-primary text-white flex flex-row gap-1 text-base font-black w-72 h-10 items-center justify-center rounded-default "
										type="button"
										onClick={() => setIsAddingColumn(true)}
										title={t('Adicionar coluna').toString()}
									>
										<FaCirclePlus /> {t("Outra lista")}
									</button>
								)}
							</div>

							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
		)
	);
}
