import { atom } from "jotai";
import { Client } from "../../models/Client";

interface ClientPageFiltersProps {
  loading: boolean
  search: string
}

export const clientPageFiltersAtom = atom<ClientPageFiltersProps>({
  loading: false,
  search: ''
})

interface ClientsPagesItemsmProps {
  clients: Client[]
}

export const clientsPagesItemsAtom = atom<ClientsPagesItemsmProps>({
  clients: []
})