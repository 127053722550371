import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import ErrorMsg from '../ErrorMsg';

interface BasicTimePickerProps extends TimePickerProps<any> {
    readonly nameField: string
}

export default function BasicTimePicker({className, nameField, ...props}: BasicTimePickerProps) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker className={`timePickerModal ${className}`} {...props} />
        <ErrorMsg name={nameField} />
      </LocalizationProvider>
    );
  }