import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const CardContainer = styled.div`
    background-color: white;
    box-shadow: 0px 0px 11.216670989990234px 0px #00000040;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 18px;
    gap: 15px;
    width: 500px;
    @media (max-width: 1500px) {
        width: 400px;
    }
    @media (max-width: 1100px) {
        width: 100%;
    }
    @media print {
        background-color: ${theme.colors.background.modalBg};
        width: 100%;
        break-inside: avoid;
    }
`

export const ChartHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ChartTitle = styled.p`
    font-size: 1.375rem;
    color: ${theme.colors.text.titleText};
    font-weight: 600;
`;

export const ChartPeriodContainer = styled.div`
    font-size: 0.875rem;
    color: ${theme.colors.text.titleText};

`

export const ChartPeriod = styled.span`
    color: black;
    font-weight: 600;

`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-height: 240px;
    @media print {
            height: 100%;
            max-height: none;
        }
`;

export const ChartDataContainer = styled.div`
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const ChartDataHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
`;

interface ChartDataTotalProps {
    withLabel: boolean;
}

export const ChartDataTotal = styled.div<ChartDataTotalProps>`
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    ${({withLabel}) => !withLabel && `
        flex-direction: row;
        justify-content: space-between;
        width: 100%
    `}
`;

export const ChartDataHeaderTitle = styled.span`
`;

export const ChartDataHeaderTotal = styled.span`
        font-weight: 600;
`;

export const ChartDataHourTotal = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ChartDataHourTitle = styled.span`
    font-weight: 400;
    color: ${theme.colors.text.titleText};
    font-size: 0.625rem;
    text-align: end;
`;

export const ChartWidth = styled.div`
    width: 180px;
    height: 180px;
    border-radius: 1000px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;    
    position: relative;
`

interface ChartIconProps {
    size: number;
}

export const ChartIcon = styled.div<ChartIconProps>`
    position: absolute;
    top: calc(50% - ${({size}) => size/2 }px);
    left: calc(50% - ${({size}) => size/2 }px);
`;
export const ChartDataBody = styled.div`

`;


export const ChartDataItem = styled.div`
    height: 26px;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;


interface ChartDataInfoContainerProps {
    withLabel: boolean;
}


export const ChartDataInfoContainer = styled.div<ChartDataInfoContainerProps>`
    display: flex;
    flex-direction: column;
    ${({withLabel}) => !withLabel && `
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    `}
`;

export const ChartDatLabelContainer = styled.div`
    font-size: 0.625rem;
    color: ${theme.colors.text.titleText};
`;

export const ChartDataTitle =styled.span`
    font-size: 0.563rem;
    color: ${theme.colors.text.titleText};
`;

export const ChartDataValue = styled.span`
    font-size: 0.625rem;
    color: black;
    font-weight: 600;
`;

interface ColorDivProps {
    color: string;
}

export const ColorDiv = styled.div<ColorDivProps>`
    height: 100%;
    width: 5px;
    border-radius: 5px;
    background-color: ${({color}) => color};
`
interface ColorContainerProps {
    withLabel: boolean;
}



export const ColorContainer = styled.div<ColorContainerProps>`
    display: flex;
    flex-direction: row;
    gap: 10px;
    ${({withLabel}) => !withLabel && `
        width: 100%;
    `}
`

export const NoDataContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    color: ${theme.colors.text.subtitleText};
    font-weight: 600;
    align-items: center;
    justify-content: center;
`

export const LoadingContainer = styled.div`
    height: 240px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;