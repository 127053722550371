import { useAtom } from 'jotai';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { columnsManagerAtom } from '../../../../../atoms/Kanban/KanbanProject';
import KanbanColumn from '../../../../../components/containers/Kanban/KanbanColumn';
import { dinamicPagination } from '../../../../../utils/funcoes/dinamicPagination';
import KanbanItem from '../KanbanItem';
import { ColumnManaged } from '../../../../../atoms/Kanban/types';
import { KanbanProjectService } from '../../../../../services/providers/kanbanProject';
import { useProject } from '../../../../../contexts/Project';
import { filterAddedItems } from '../../../../../utils/funcoes/kanban';
import { useUser } from '../../../../../contexts/User';
import { toast } from 'react-toastify';
import { updateColumnsValues } from '../../../Tarefas/Kanban/functions';
import {  ProjectPage, ProjectBasic } from '../../../../../models/Project';
import { t } from 'i18next';

interface KanbanBodyProps {
	readonly id: string;
	readonly index: number;
	readonly setEditingProject: Dispatch<SetStateAction<boolean>>;
	readonly setSelectedProject: Dispatch<SetStateAction<ProjectBasic | undefined>>

}

export default function KanbanBody({ id, index, ...rest }: KanbanBodyProps) {
	const [loading, setLoading] = useState(false);
	const [columnsManager, setColumnsManager] = useAtom(columnsManagerAtom);
	const [editingTitle, setEditingTitle] = useState({
		isEditing: false,
		columnId: ''
	})
	const column = columnsManager.columns[id];
	const items = column?.items;
	const kanbanService = new KanbanProjectService();
	const { clienteValorSelecionado, myProjects, searchProject } = useProject();
	const { userDetails } = useUser();

	const handlePaginateInsideColumn = async (page: number) => {
		setLoading(true);
		const {
			paginateItems,
			totalPages,
			doneTotalPages,
		} = await getPaginateProjects(page, column, index);
		const newItems = [...items, ...paginateItems];
		handleUpdateStateItems(newItems, page, totalPages, doneTotalPages);
	};

	const handleUpdateStateItems = (
		newItems: ProjectPage[],
		page: number,
		totalPages: number | null,
		doneTotalPages: number,
	) => {
		setColumnsManager(prev => ({
			...prev,
			columns: {
				...prev.columns,
				[id]: {
					...prev.columns[id],
					page: page,
					totalPages: totalPages ?? prev.columns[id].totalPages,
					doneTotalPages: doneTotalPages,
					removedQnt: 0,
					items: newItems,
				
				},
			},
		}));
		setLoading(false);
	};

	const getPaginateProjects = async (
		page: number,
		column: ColumnManaged<ProjectPage>,
		index: number,
	) => {
		if (index === 0) {
			return await handleGetFirstColumnPaginate(page);
		} else if (index === columnsManager.columnsOrder.length - 1) {
			return await handleGetLastColumnPaginate(page, column);
		} else {
			return await handleGetColumnsPaginate(page, column);
		}
	};


	const handleGetLastColumnPaginate = async (page: number, column: any) => {
		if (userDetails) {
			const columnsTaskResponse = column.totalPages < page
				? { results: [], total_pages: 0 }
				: await kanbanService.getProjectsByColumn(
					column.id,
					{ clienteValorSelecionado, myProjects, searchProject, userId: userDetails.id_pessoa },
					page
				)

			const doneTasksResponse = column.doneTasksPages < page
				? { results: [], total_pages: 0 }
				: await kanbanService.getProjectsWithNoColumn(
					{ clienteValorSelecionado, myProjects, searchProject, userId: userDetails.id_pessoa },
					page,
					'closed'
				)

			const paginateItems = [...filterAddedItems(columnsTaskResponse.results, column), ...filterAddedItems(doneTasksResponse.results, column)];
			return ({
				paginateItems: paginateItems,
				totalPages: columnsTaskResponse?.total_pages,
				doneTotalPages: doneTasksResponse.total_pages
			})
		}
		return ({
			paginateItems: [],
			totalPages: null,
			doneTotalPages: 0
		})

	};

	const handleGetColumnsPaginate = async (page: number, column: ColumnManaged<ProjectPage>) => {
		if (userDetails) {
			const prevPaginates: any[] = column.removedQnt > 0 && page > 1
				? (await handleGetColumnsPaginate(
					page - 1,
					{ ...column, removedQnt: column.removedQnt - (column.items.length / page) * -1 }
				)).paginateItems
				: [];

			const columnsTaskResponse = await kanbanService.getProjectsByColumn(
				column.id,
				{ clienteValorSelecionado, myProjects, searchProject, userId: userDetails.id_pessoa },
				page
			);

			return ({
				paginateItems: [...prevPaginates.slice(column.removedQnt * -1), ...filterAddedItems(columnsTaskResponse.results, column)],
				totalPages: columnsTaskResponse?.total_pages,
				doneTotalPages: 0
			})
		}
		return ({
			paginateItems: [],
			totalPages: null,
			doneTotalPages: 0
		})
	};

	const handleGetFirstColumnPaginate = async (page: number) => {
		if (userDetails) {
			const columnPropjectResponse = await kanbanService.getProjectsWithNoColumn(
				{ clienteValorSelecionado, myProjects, searchProject, userId: userDetails.id_pessoa },
				page,
				''
			);
			return ({
				paginateItems: [...filterAddedItems(columnPropjectResponse.results, columnsManager.columns[columnsManager.columnsOrder[0]])],
				totalPages: columnPropjectResponse?.total_pages,
				doneTotalPages: 0
			})
		}
		return ({
			paginateItems: [],
			totalPages: null,
			doneTotalPages: 0
		})
	};

	const handleChangeColumnName = (columnId: string, title: string) => {
		const columnData = {
			id: columnId,
			ordenacao: columnsManager.columns[columnId].ordenacao,
			nome: title,
		};

		if (index > 0) {
			kanbanService.patchColumn(columnData).catch(() => {
				setColumnsManager({ ...columnsManager });

				toast.error(t('Erro ao atualizar nome da coluna!'));
			});
		} else {
			kanbanService.updateColumnCompanyKanban({
				primeira_coluna: columnData.nome
			}).catch(() => {
				setColumnsManager({ ...columnsManager });

				toast.error(t('Erro ao atualizar nome da coluna!'));
			});
		}

		setColumnsManager(prev => {
			const tempColumns = { ...prev.columns };

			tempColumns[columnId] = {
				...tempColumns[columnId],
				nome: title,
			};

			return ({
			...prev,
			columns: tempColumns,
		})});

		setEditingTitle({
			columnId: '',
			isEditing: false,
		});
	};

	const handleDeleteColumn = (columnId: string) => {
		if (columnsManager.columns[columnId].items.length === 0) {
			kanbanService
				.deleteColumn({
					columnId: columnId,
				})
				.catch(err => {
					toast.error(err);
				});

			setColumnsManager(prev => {
				const newColumnsOrder = [...prev.columnsOrder].filter(
					id => id !== columnId,
				);

				return {
					...prev,
					columns: updateColumnsValues(prev.columns, newColumnsOrder),
					columnsOrder: newColumnsOrder,
			}});
		}
	};

	const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		dinamicPagination({
			element: e.target,
			callBack: handlePaginateInsideColumn,
			loading: loading,
			page: column.page,
			totalPages:
				column.doneTotalPages && column.doneTotalPages > column.totalPages
					? column.doneTotalPages
					: column.totalPages,
		})
	}


	return columnsManager.columns[id] ? <KanbanColumn
		editingTitle={editingTitle}
		setEditingTitle={setEditingTitle}
		onChangeColumnName={handleChangeColumnName}
		columnsManager={columnsManager}
		setColumnsManager={setColumnsManager}
		isAdd={false}
		loading={loading}
		onScroll={handleScroll}
		style={{}}
		column={columnsManager.columns[id]}
		handleDeleteColumn={handleDeleteColumn}
		index={index}
		key={id}
		handlePagination={handlePaginateInsideColumn}
	>
		{columnsManager.columns[id]?.items?.map((item, index) => {
			return <KanbanItem {...rest} index={index} key={item.id} project={item} />;
		})}
	</KanbanColumn> : <></>
}