import React, { HtmlHTMLAttributes } from "react"
import { twMerge } from "tailwind-merge"
import { LoaderPagination } from "../../../common/loaders/LoaderPagination"

interface LoadMoreRowProps extends HtmlHTMLAttributes<HTMLDivElement> {
    readonly loading: boolean
    readonly hideLoader: boolean
    readonly handlePagination: () => void
}

export function LoadMoreRow({className, loading, hideLoader, handlePagination, ...props}: LoadMoreRowProps) {
    const classes = twMerge(
        `flex items-center justify-center gap-4 h-12 border-y border-y-white px-4 items-center text-ivory-primary font-medium bg-[#F7F6F5] `,
        className
    )

    return (
        <div className={classes} {...props}>
            <LoaderPagination
                onClick={handlePagination}
                loading={loading}
                showLoader={hideLoader}
                
            />
        </div>
    )
}