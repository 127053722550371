import styled from 'styled-components';
import { theme } from '../../../../styles/theme';

export const ModalContent = styled.div`
	width: 55vw;
	background: ${theme.colors.background.modalBg};
	@media (max-width: 500px) {
    width: 90vw;
	}
`;

export const ModalContainer = styled.div`
`;

interface ModalProps {
    validProfile: boolean;
}

export const ModalProjetoContainer = styled.div<ModalProps>`
	background-color: #f7f6f5;
	overflow: auto;
	max-height: 28vh;
	margin-top: 20px;
	border: 1px solid #c0cfd8d9;
	border-radius: 5px;
	padding-right: 0px;
	padding-left: 0px;
    flex: 0 0 100%;
	max-width: 100%;
	position: relative;
	width: 100%;
	@media (max-width: 992px) {
		flex: 0 0 100%;
		max-width: 100%;
	}
	@media (max-width: 768px) {
		flex: 0 0 100%;
		max-width: 100%;
	}
	${({validProfile}) => !validProfile && `
        opacity: 0.5;
        pointer-events: none;
    `}
`;
