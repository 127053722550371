export const validarPerfil = (
	valueAcessCode,
	isMember = false,
	isEditing = false,
	admins = [],
) => {
	switch (valueAcessCode) {
		case 'm':
			return false;
		case 'g':
			if (isMember === false && isEditing === true) {
				return false;
			} else {
				return true;
			}
		case 'e':
			return false;
		case 'a':
			return true;

		default:
			return false;
	}
};

export const validateCanAccessAdvancedConfigProjeto = (acessCode, is_member) =>
	acessCode === 'a' || (acessCode === 'g' && is_member === true);

export const verifyPeopleManagment = (accessCode) => accessCode === 'a';


export const verifyCanEditProject = (
	userInfo,
	isMember = false,
	admins = [],
) => {
	switch (userInfo.acesso_cod) {
		case 'm':
			return isMember && verifyIsAdmin(admins, userInfo);
		case 'g':
			return isMember;
		case 'e':
			return false;
		case 'a':
			return true;
		default:
			return false;
	}
};

const verifyIsAdmin = (admins, userInfo) => {
	const element = admins.find(item => item.id === userInfo.id_pessoa);
	return typeof element === 'object';
};

export const validateCanSeeProjetoCard = (
	acessCode,
	is_member,
) =>
	(acessCode === 'e' && is_member === false);

export const validateIsNotMember = is_member => !is_member;
