import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { Integration } from '../../../models/Integration';
import HttpService from '../../config/http';
import { CallbackType, DataType } from '../types';

export default class IntegracoesService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

  getChavesDeUsuario = (success: CallbackType<AxiosResponse<DataType<Integration>>>, errorCallback: CallbackType<string>) => {
    this.api.get('api/v1/integracoes/user-token/')
    .then(result => {
      if (result.status === 200) {
        success(result);
      } else {
        if (typeof result.data === typeof {}) {
          const objError = result.data;
          for (const key in objError) {
            throw new Error(`${objError[key]}`);
          }
        } else {
          throw new Error(t('Erro ao tentar carregar os tokens de usuário!'));
        }
      }
    })
    .catch(error => errorCallback(error.toString()));
  }

	postChaveUsuario = (passForm: FormData, success: CallbackType<AxiosResponse<DataType<Integration>>>, errorCallback: CallbackType<string>) => {
		this.api
			.post(`api/v1/integracoes/user-token/`, passForm)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t('Erro ao tentar salvar os tokens de usuário!'));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

}
