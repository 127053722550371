import React, { useEffect, useState } from 'react'
import { TeamPerson } from '../../../../../../../../models/TeamPerson'
import { calculateCurrentValue } from '../../../utils';
import DoughnutCard, { ChartDisplay } from '../../../../../../../../components/common/DoughnutCard';
import { ChartData, ChartOptions } from 'chart.js';
import { formatBRL } from '../../../../../../../../utils/currency';
import { PeopleColor } from '../../..';
import { HoursRecord } from '../../../../../../../../models/HoursRecord';
import { generateRandomColor } from '../../../../../../../../utils/colors';
import { t } from 'i18next';

interface CostChartProps {
    projectTeam: TeamPerson[];
    peopleColor?: PeopleColor[];
    hoursRecord: HoursRecord[];
    loading: boolean;
}

interface CostResult {
    totalCost: number,
    totalCostPerHour: number,
    costByPerson: CostByPerson[]
}

interface CostByPerson {
    id: number;
    cost: number;
    name: string;
}

const options: ChartOptions = {
    
    aspectRatio: 1,
    plugins: {

        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    const value = context.raw as number;
                    return formatBRL(value);

                }
            }
        }
    },
    

}
export default function CostChart({projectTeam, loading, peopleColor, hoursRecord}: CostChartProps) {
    const [data, setData] = useState<ChartData>({labels: [""],datasets: []});
    const [costResult, setCostResult] = useState<CostResult>();
    const [chartData, setChartData] = useState<ChartDisplay[]>([]);

    useEffect(() => {
        if(peopleColor && peopleColor.length > 0 && hoursRecord.length > 0) {
            handleChartData();
            handleCostByProfessional();    
        }
    }, [projectTeam, peopleColor, hoursRecord]);

    const plugins = [{
        id: '2',
        beforeDraw: function(chart: any) {
            const width = chart.width;
            const height = chart.height;
            const ctx = chart.ctx;
            ctx.restore();
            const image = new Image();      
            image.src = require('../../../../../../../../assets/svgs/moneyChart.svg');      
            const imageWidth = 20;
            const imageHeight = 40;
            ctx.drawImage(image, width / 2 - imageWidth / 2, height / 2 - imageHeight / 2, imageWidth, imageHeight);
            ctx.save();
        } 
      }]
   
    const handleChartData = () => {
        const labels = projectTeam.map((team) => team.pessoa.nome);
        const data = projectTeam.map((team) => {
            const value = calculateCurrentValue(team.horas_realizadas, team.preco);
           return {
            value,
            color: peopleColor!.filter(item => item.name === team.pessoa.nome)[0]?.color ?? generateRandomColor(),
            title: team.pessoa.nome,
            label: `${formatBRL(team.preco)}/hr`
           }
        });
        setChartData([...data.map(item => ({...item, valueString: formatBRL(item.value)}))].sort((a, b) => b.value - a.value));

        
        setData({
            labels: labels,
            datasets: [
                {
                    label: "Gasto por profissional",
                    data: data.map((item) => item.value),
                    backgroundColor: data.map((item) => item.color),
                    borderWidth: 5,
                    borderRadius: 10,
                    fill: 'white',
                    
                    
            }
            ]
        })
    }

    const handleCostByProfessional = () => {
        const costByPerson = projectTeam.map((team) => {
            return (
                {
                    id: team.id,
                    name: team.pessoa.nome,
                    cost: calculateCurrentValue(team.horas_realizadas, team.preco ),
                    costPerHour: team.preco 
                }
            )
        });
        const totalCost = projectTeam.reduce((accumulator, currentTeam) => {
            return accumulator + calculateCurrentValue(currentTeam.horas_realizadas, currentTeam.preco);
        }, 0);
        const totalHours = projectTeam.reduce((accumulator, currentTeam) => {
            const splitHour = currentTeam.horas_realizadas.split(":");
            return accumulator + parseInt(splitHour[0]);
        }, 0);
        const totalMinutes = projectTeam.reduce((accumulator, currentTeam) => {
            const splitHour = currentTeam.horas_realizadas.split(":");
            return accumulator + parseInt(splitHour[1]);
        }, 0);
        const totalSeconds = projectTeam.reduce((accumulator, currentTeam) => {
            const splitHour = currentTeam.horas_realizadas.split(":");
            return accumulator + parseInt(splitHour[2]);
        }, 0);

        const minutesInHour = totalMinutes/60;
        const secondsInHour = totalSeconds/3600;
        const finalHours = totalHours + minutesInHour + secondsInHour;


        const costPerHour = totalCost/finalHours;
        
        setCostResult({
            totalCost,
            totalCostPerHour: isNaN(costPerHour) ? 0 : costPerHour,
            costByPerson
        })


    }

    return(
        <DoughnutCard plugins={plugins} loading={loading} iconSize={50}  chartData={chartData.filter((data) => data.value > 0)} data={data}   options={options} title={t('Gasto por profissional')} total={formatBRL(costResult?.totalCost ?? 0)} totalTitle={t('Gasto do período')} />
    )
}
