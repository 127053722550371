import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ProjectBasic, ProjectPage } from '../../../../../models/Project';
import { t } from 'i18next';
import TableRow from './TableRow';
import { useProject } from '../../../../../contexts/Project';
import Table from '../../../../../components/common/Table';
import { CellData } from '../../../../../components/common/Table/types';
import { sortAlphabetically } from '../../../../../utils/funcoes/sort.ts';

interface ProjectTableProps {
	setEditingProjeto: Dispatch<SetStateAction<boolean>>;
	setModalProjectVisible: Dispatch<SetStateAction<boolean>>;
	setProjetoEditado: Dispatch<SetStateAction<ProjectBasic | undefined>>;
	projetoEditado?: ProjectBasic;
}


export default function ProjectTable({
	setEditingProjeto,
	setModalProjectVisible,
	setProjetoEditado,
	projetoEditado,
}: ProjectTableProps) {
	const { selectProjectList, projects } = useProject();
	useEffect(() => {
		selectProjectList();
	}, []);
	const headerData: CellData[] = [
		{ title: t('Cliente e projeto'), width: '38%' },
		{ title: t('Time'), width: '32%' },
		{ title: t('Tarefas'), width: '22%' },
		{ title: t('Ações'), width: '8%' },
	];

	return (
		<Table headerData={headerData} className="xl:table-fixed">
			{sortAlphabetically<ProjectPage>(projects, 'nome').map((project) => {
				return (
					<TableRow
						project={project}
						key={project.id}
						projetoEditado={projetoEditado}
						setEditingProjeto={setEditingProjeto}
						setModalProjectVisible={setModalProjectVisible}
						setProjetoEditado={setProjetoEditado}
					/>
				);
			})}
		</Table>
	);
}
