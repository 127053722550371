import styled from "styled-components";

export const ModalPrincipal = styled.div`
    display: flex;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 20;

    .modal-align-generico-side {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }
    
    .modal-align-principal-side {
        display: flex;
        flex-direction: row;
    }
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 2;
	border-radius: 20px;
	background-color: #f7f6f5;
	padding: 0 15px;
	border: 1px solid rgb(177, 173, 173);
	max-height: 100vh;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-animation-name: animatetopGenerico;
	-webkit-animation-duration: 0.3s;
	animation-name: animatetopGenerico;
	animation-duration: 0.3s;

    .modal-body-generico {
        max-width: 85vw;
        max-height: 70vh;
        overflow-y: visible;
        background-color: #fff;
    }
    
    @keyframes animatetopGenerico {
        from {
            margin-top: -200px;
            opacity: 1;
        }
        to {
            margin-top: 0;
            opacity: 1;
        }
    }
`;


export const ModalHeader = styled.div`
    background-color: #f7f6f5;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 50px;
	border-bottom: solid 1px #dbe3e6;

    .modal-header-generico-text {
        margin: 0;
        padding: 0;
        font-size: 20px;
        padding: 6px 10px;
        font-weight: 600;
        color: #426172;
    }
`;


export const ModalFooter = styled.div`
    border-top: solid 1px #dbe3e6;
	display: flex;
	align-items: flex-end;
`;

export const ModalLoader = styled.div`
	display: inline-block;
	width: 22px;
	height: 22px;
	vertical-align: text-bottom;
	border: 0.25em solid #fff;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
`;

export const FormSide = styled.div`
    transition: all 0.4s;
	width: 300px;
	height: 0;
	overflow: hidden;
	margin-right: -320px;
	border-radius: 14px 0 0 14px;
	z-index: 2;
	background-color: #ffffff;
	padding: 0 30px 0 15px;
	border: 1px solid rgb(177, 173, 173);
	max-height: 100vh;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-animation-name: animatetopGenerico;
	-webkit-animation-duration: 0.3s;
	animation-name: animatetopGenerico;
	animation-duration: 0.3s;
        
    .form-footer-generico-side {
        border-top: solid 1px #dbe3e6;
        height: 32px;
        display: flex;
        align-items: flex-end;
    }

    @keyframes animatetopGenerico {
        from {
            margin-top: -200px;
            opacity: 1;
        }
        to {
            margin-top: 0;
            opacity: 1;
        }
    }
`;

interface BodySideProps {
    hour: boolean;
    side: boolean;
}

export const BodySide = styled.div<BodySideProps>`
    max-width: 85vw;
    max-height: 70vh;
    overflow-y: ${({side}) => side ? "auto" : "visible"};
    background-color: #fff;
    overflow-x: ${({side}) => side ? "hidden" : "initial"};
    height:  ${({hour}) => hour ? "initial" : "55vh"};
`;

export const FormHeader = styled.div`
    background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	border-bottom: solid 1px #dbe3e6;

    .modal-header-generico-side-text {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 600;
        color: #426172;
    }
    
    .close-generico-side-form {
        color: #426172;
        font-size: 28px;
        font-weight: bold;
        user-select: none;

        &:hover,
        &:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }
    }
`;

export const ButtonContainer = styled.div`
    // top: 1.1em;
	width: 100%;
	display: flex;
	position: relative;
    gap: 16px;
	justify-content: center;
	align-items: center;
    margin-bottom: 26px;
    margin-top: 26px;
`;