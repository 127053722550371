import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function HelpIcon(props: IconProps) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M15.92 25.6C16.48 25.6 16.9536 25.4064 17.3408 25.0192C17.7269 24.6331 17.92 24.16 17.92 23.6C17.92 23.04 17.7269 22.5669 17.3408 22.1808C16.9536 21.7936 16.48 21.6 15.92 21.6C15.36 21.6 14.8864 21.7936 14.4992 22.1808C14.1131 22.5669 13.92 23.04 13.92 23.6C13.92 24.16 14.1131 24.6331 14.4992 25.0192C14.8864 25.4064 15.36 25.6 15.92 25.6ZM14.48 19.44H17.44C17.44 18.56 17.5403 17.8667 17.7408 17.36C17.9403 16.8533 18.5067 16.16 19.44 15.28C20.1333 14.5867 20.68 13.9264 21.08 13.2992C21.48 12.6731 21.68 11.92 21.68 11.04C21.68 9.54667 21.1333 8.4 20.04 7.6C18.9467 6.8 17.6533 6.4 16.16 6.4C14.64 6.4 13.4069 6.8 12.4608 7.6C11.5136 8.4 10.8533 9.36 10.48 10.48L13.12 11.52C13.2533 11.04 13.5536 10.52 14.0208 9.96C14.4869 9.4 15.2 9.12 16.16 9.12C17.0133 9.12 17.6533 9.35307 18.08 9.8192C18.5067 10.2864 18.72 10.8 18.72 11.36C18.72 11.8933 18.56 12.3931 18.24 12.8592C17.92 13.3264 17.52 13.76 17.04 14.16C15.8667 15.2 15.1467 15.9867 14.88 16.52C14.6133 17.0533 14.48 18.0267 14.48 19.44ZM16 32C13.7867 32 11.7067 31.5797 9.76 30.7392C7.81333 29.8997 6.12 28.76 4.68 27.32C3.24 25.88 2.10027 24.1867 1.2608 22.24C0.420267 20.2933 0 18.2133 0 16C0 13.7867 0.420267 11.7067 1.2608 9.76C2.10027 7.81333 3.24 6.12 4.68 4.68C6.12 3.24 7.81333 2.09973 9.76 1.2592C11.7067 0.419733 13.7867 0 16 0C18.2133 0 20.2933 0.419733 22.24 1.2592C24.1867 2.09973 25.88 3.24 27.32 4.68C28.76 6.12 29.8997 7.81333 30.7392 9.76C31.5797 11.7067 32 13.7867 32 16C32 18.2133 31.5797 20.2933 30.7392 22.24C29.8997 24.1867 28.76 25.88 27.32 27.32C25.88 28.76 24.1867 29.8997 22.24 30.7392C20.2933 31.5797 18.2133 32 16 32ZM16 28.8C19.5733 28.8 22.6 27.56 25.08 25.08C27.56 22.6 28.8 19.5733 28.8 16C28.8 12.4267 27.56 9.4 25.08 6.92C22.6 4.44 19.5733 3.2 16 3.2C12.4267 3.2 9.4 4.44 6.92 6.92C4.44 9.4 3.2 12.4267 3.2 16C3.2 19.5733 4.44 22.6 6.92 25.08C9.4 27.56 12.4267 28.8 16 28.8Z" />
    </svg>
    
  )
}