import React from 'react';
// import { MdCheckCircle, MdError, MdLink } from 'react-icons/md';

import ProgressBar from 'react-bootstrap/ProgressBar';

import './style.css';
import Icon from '../../../../../../components/common/Icons/icon';
import {
	faCheck,
	faTimes,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { IconFileType } from '../../../../../../components/common/IconFileType/IconFileType';
import { useTranslation } from 'react-i18next';

const FileList = ({ files, onDelete }) => {
	const {t} = useTranslation();
	const verifyFileType = url => {
		return url.split('.').pop();
	};

	return (
		<>
			<ul className="list-containter">
				{files.map(uploadedFile => (
					<li key={uploadedFile.id}>
						{verifyFileType(uploadedFile.name) === ('png' || 'jpg') ? (
							<>
								<img src={uploadedFile.preview} alt={`${uploadedFile.name}`} />
							</>
						) : (
							<div style={{ margin: '0 15px 0 40px', fontSize: '30px' }}>
								<IconFileType fileType={verifyFileType(uploadedFile.name)} />
							</div>
						)}
						<span>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<div
									className="list-text"
									style={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<a
										href={uploadedFile.url}
										target="_blank"
										rel="noopener noreferrer"
										style={{ textDecoration: 'none' }}
									>
										{`${uploadedFile.name} (${uploadedFile.readableSize})`}
									</a>
									{uploadedFile.uploaded && (
										<span style={{ fontSize: '16px', paddingLeft: '10px' }}>
											<Icon
												icon={faCheck}
												colorIcon="red"
												title={t("Erro ao carregar arquivo")}
											/>
										</span>
									)}
									{uploadedFile.error && (
										<span
											style={{
												fontSize: '16px',
												paddingLeft: '10px',
												color: 'red',
											}}
										>
											<Icon
												icon={faTimes}
												colorIcon="##ff0000"
												title={t("Carregado com sucesso!")}
											/>
										</span>
									)}
								</div>
								<div style={{ paddingRight: '10px' }}>
									{uploadedFile.error && <span>{t("Erro ao carregar arquivo")}</span>}
									{uploadedFile.url && !uploadedFile.error && (
										<Icon
											icon={faTrashAlt}
											pointer={true}
											onClick={() => onDelete(uploadedFile.id)}
										/>
									)}
								</div>
							</div>
							<div>
								{!uploadedFile.uploaded && !uploadedFile.error && (
									<ProgressBar now={uploadedFile.progress} />
								)}
							</div>
						</span>
					</li>
				))}
			</ul>
		</>
	);
};

export default FileList;
