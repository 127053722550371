import React, {  useEffect, useState } from 'react';
import ProjetoService from '../../../../../services/providers/project';

import { Checklist } from '../Checklist';
import { Task } from '../../../../../models/Task';
import { Responsible } from '../../../../../models/Responsible';
import { 
		InfoDescriptionContainer, 
		InfoDescriptionText, 
		InfoTarefaContent, 
		PageContainer,
		InfoDescriptionTitle
	} 
	from './styled';
import { t } from 'i18next';
import InfoTarefaHeader from './InfoTarefaHeader';
import InfoTarefaTable from './InfoTarefaTable';
import InfoTarefaResponsaveis from './InfoTarefaResponsaveis';
import InfoTarefaHistorico from './InfoTarefaHistorico';

interface InfoTarefaBodyProps {
	task: Task,
	deleteTask: (id: number | string) => void;
	closeTask: (id: number | string) => void;
	accessCode: string;
	onClickEdit: () => void;
	urlBack: string;

}

export default function InfoTarefaBody({
	accessCode,
	closeTask,
	deleteTask,
	onClickEdit,
	task,
	urlBack
}: InfoTarefaBodyProps) {
	const [team, setTeam] = useState<Responsible[]>([]);

	useEffect(() => {
		_getProjetos(task.projeto);
	}, [task, deleteTask, closeTask, accessCode]);



	const _getProjetos = (filtroProjeto: string| number) => {
		const filtroProjetoParsed = parseInt(filtroProjeto.toString());
		const projetoService = new ProjetoService();
		projetoService.getDetalheProjeto(
			filtroProjetoParsed,
			(response: any) => {
				if (response.time) {
					setTeam(response.time);
				}
			},
			() => {
				setTeam([]);
			},
		);
	};

	return task !== undefined ? (
		<PageContainer>
			<InfoTarefaContent>
				
				<InfoTarefaHeader 
					closeTask={closeTask} 
					deleteTask={deleteTask} 
					onClickEdit={onClickEdit} 
					task={task} 
					urlBack={urlBack} 
				/>
				<InfoTarefaTable task={task} />
				<Checklist team={team} task={task} />

				{task.descricao && <InfoDescriptionContainer className='className="border border-solid border-ivory-task-detail-border-gray"'>
					<InfoDescriptionTitle>
						{t('Descrição')}:
					</InfoDescriptionTitle>
					<InfoDescriptionText>
						{task.descricao}
					</InfoDescriptionText>
				</InfoDescriptionContainer>}
				<InfoTarefaResponsaveis task={task} />
				<InfoTarefaHistorico   task={task} />
			</InfoTarefaContent>
		</PageContainer>
	) : null;
}
