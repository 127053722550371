/**
 * Configuração das rotas do APP para comunicação com a API
 */

export default {
    currentEnvironment: process.env.REACT_APP_ENVIROMENT,
    // currentEnvironment: 'homolog',
    baseUrl: {
        dev: {
            urlApi: 'https://app-planus-api-hml.azurewebsites.net/',
        },
        homolog: {
            urlApi: 'https://app-planus-api-hml.azurewebsites.net/',
        },
        prod: {
            urlApi: 'https://apiv2.planus.io/',
        },
        local: {
            urlApi: 'http://localhost:8010/'
        }
    },
};