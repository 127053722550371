import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const SearchIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
	${props =>
		props.className === 'search-modal-open'
			? `
         font-size: 12px;
         color: hsl(0,0%,40%);
         margin-right: 5px;
         &:hover {
            color: hsl(0,0%,20%)
         }
    `
			: `
        font-size: 12px;
        color: hsl(0,0%,80%);
        margin-right: 5px;
        &:hover {
            color: hsl(0,0%,60%);
        }
    `}
`;

