import React from 'react';
import { subtractHours } from '../../../utils/funcoes/manipulateTime';
import { formatDate } from '../../../utils/funcoes/momentDates';
import { Link } from 'react-router-dom';
import { formatterCurrency } from '../../../utils/funcoes/currencyMask';

import '../../../i18n';

import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ListRelatorio = ({
	projetoData,
	projetoEncerrados,
	isRelatorioProjeto,
	toState,
}) => {
	const {t} = useTranslation();
	const calculateHours = (horasPrevistas, horasConsumidas) => {
		return subtractHours(horasPrevistas, horasConsumidas);
	};

	// const isHourPositive = (horasPrevistas, horasConsumidas) => {
	// 	const valueHour = horasConsumidas.split(':');
	// 	if (horasPrevistas === 0 || parseInt(valueHour[0]) > horasPrevistas) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// };

	const converterDuracaoTotal = horaDuracao => {
		const horasSlice = horaDuracao.split(':');
		var horas = horasSlice[0];
		var minutos = horasSlice[1];
		return `${horas}h ${minutos}m`;
	};

	const whenFilterFinish = () => {
		return projetoEncerrados
			? { textDecoration: 'line-through' }
			: { textDecoration: 'none' };
	};

	return (
			<section
				id="list-relatorio"
				className='mr-6'
			>
				<div
					className="header-thead"
					// style={{ gridTemplateColumns: `repeat(${8}, 1fr)` }}
				>
					<div style={{ display: 'flex', padding: '5px', textAlign: 'left' }}>
						{t("Projeto")}
					</div>
					<div style={{ display: 'flex', padding: '5px', textAlign: 'left' }}>
						{t("Cliente")}
					</div>
					<div className="projeto-hidden-on-mobile">{t("Administradores")}</div>
					<div className="projeto-hidden-on-mobile">{t("Início Previsto")}</div>
					<div className="projeto-hidden-on-mobile">{t("Término Previsto")}</div>
					<div className="projeto-hidden-on-mobile">{t("Horas Previstas")}</div>
					<div className="projeto-hidden-on-mobile">{t("Horas Consumidas")}</div>
					<div>{t("Horas Disponíveis")}</div>
					<div>{t("Custo Atual")}</div>
					<div>{t("Saldo")}</div>
				</div>
				<div>
					{projetoData.map(item => {
						const value = calculateHours(
							item.horas_previstas,
							item.horas_consumidas,
						);

						const firstValue = value.charAt(0) === '-';
						return (
							<div
								className="row-list"
								key={item.id}
								// style={{ gridTemplateColumns: `repeat(${8}, 1fr)` }}
							>
								{isRelatorioProjeto ? (
									<>
										<div
											style={{
												display: 'flex',
												padding: '5px',
												textAlign: 'left',

												...whenFilterFinish(),
											}}
										>
											<Link
												to={{
													pathname: `/registros/projetos/${item.id}/parametros`,
													state: {
														data: item,
														relatorioProjetos: toState
													},
												}}
											>
												<FontAwesomeIcon
													icon={faExternalLinkAlt}
													className="icon-relatorio-mensal"
													style={{
														userSelect: 'none',
														cursor: 'pointer',
														color: '#99adb9',
														float: 'left',
														margin: '5px 7px 0 0',
													}}
													title={t('Ver projeto')}
												/>
											</Link>
											<span
												style={{
													display: 'flex',
													flexDirection: 'row',
													padding: '0 2px',
												}}
												className="projetos-link-projeto"
											>
												{/* {data.tarefas !== null ? data.tarefas.length : '0'} */}

												{item.nome}
											</span>
										</div>
									</>
								) : (
									<div
										style={{
											display: 'flex',
											padding: '5px',
											textAlign: 'left',
											...whenFilterFinish(),
										}}
									>
										{item.nome}
									</div>
								)}

								<div
									style={{
										display: 'flex',
										padding: '5px',
										textAlign: 'left',
										...whenFilterFinish(),
									}}
								>
									{item.cliente.nome}
								</div>
								<div
									style={whenFilterFinish()}
									className="projeto-hidden-on-mobile"
								>
									{item.admins.map((admin, index) => (
										<div key={admin.id}>
											{admin.nome}
											{index + 1 !== item.admins.length && ','}
										</div>
									))}
								</div>
								<div
									style={whenFilterFinish()}
									className="projeto-hidden-on-mobile"
								>
									{formatDate(item.inicio_previsto)}
								</div>
								<div
									style={whenFilterFinish()}
									className="projeto-hidden-on-mobile"
								>
									{formatDate(item.termino_previsto)}
								</div>
								<div className="projeto-hidden-on-mobile">
									{`${item.horas_previstas}h 00m`}
								</div>
								<div className="projeto-hidden-on-mobile">
									{converterDuracaoTotal(item.horas_consumidas)}
								</div>
								<div
									style={{
										color: firstValue ? '#d84b58' : 'none',
									}}
								>
									{converterDuracaoTotal(value)}
								</div>
								<div>{formatterCurrency.format(item.custo_atual)}</div>
								<div
									style={{
										color: item.saldo > 0 ? 'none' : '#d84b58',
									}}
								>
									{formatterCurrency.format(item.saldo)}
								</div>
							</div>
						);
					})}
				</div>
			</section>
	);
};

export default ListRelatorio;
