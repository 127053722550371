import styled from "styled-components";

export const Card = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-right: 4px;

    &:hover {
        background-color: rgba(200,200,200,0.2);
    }

    > div {
        display: flex;
        align-items: flex-start;
        gap: 28px;
    }

    .subtask-bottom {
        display: grid;
        grid-template-columns: 1fr 200px;
        align-items: center;
        gap: 16px;

        @media (max-width: 1200px) {
            grid-template-columns: 1fr 100px;
        }
    }

    .subtask-modal-container {
        position: relative;
    }

    .subtask-menuButtons {
        position: relative;
        display: flex;
        gap: 16px;
        justify-content: flex-end;

        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;
        }
    }

    @media (max-width: 1200px) {
        grid-template-columns: 0.75fr 1.25fr;
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
    }
`;

export const CheckInput = styled.input`
    ::before {
        background-color: #fff !important;
        border-color: #496070 !important;
        border-radius: 0  !important;
    }

    ::after {
        border-color: #496070 !important;
    }
`;

export const NameInput = styled.input`
    outline: none;
    height: 16px;
    width: 90%;
    padding: 2px;
    border: none;
    border-radius: 5px;
    background-color: #C1D0D9;
`;

interface NameTextProps {
    completed: boolean;
    isEditingName: boolean;
}

export const NameText = styled.p<NameTextProps>`
    margin: 0;
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;    
    max-height: 18px;      
    height: 18px;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    text-decoration: ${({completed}) => completed ? 'line-through' : "initial"}; 
    display: ${({isEditingName}) => isEditingName ? "none" : '-webkit-box'};
`;

export const DateDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
        gap: 8px;

        > div {
            justify-content: center;
        }
    }
`;

interface DateTextProps {
    isEditingDate: boolean;
}

export const DateText = styled.span<DateTextProps>`
    display: ${({isEditingDate}) => isEditingDate ? "none" : '-webkit-box'};

    svg {
        cursor: pointer;
    }
`;

export const DateInput = styled.input`
    display: flex;
    align-items: center;
    outline: none;
    height: 16px;
    padding: 2px;
    border: none;
    border-radius: 5px;
    background-color: #C1D0D9;

    ::before {
        top: initial !important;
        height: 25px !important;
    }
`;

export const ProfileImage = styled.img`
    width: 25px;
    height: 25px;
    border-radius: 50%;
`;

export const MenuButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    min-height: 28px;
    color: ${(props) => props.theme.colors.text.titleText};
    font-size: ${(props) => props.theme.font.sizes.text.medium};
    border: none;
    border-radius: 5px;
    background: #C1D0D9;
    padding: 0;
    gap: 4px;
`;

export const AssignContainer = styled.div`
    position: absolute;
    width: 100%;
`;