import {  AxiosRequestConfig, AxiosResponse } from 'axios';
import { t } from 'i18next';
import {  CommonModelWithClient } from '../../../models/CommonModel';
import { Project, ProjectDetail, ProjectReport, ProjectPostResponse, ProjectPage } from '../../../models/Project';
import { Responsible } from '../../../models/Responsible';
import { Tag } from '../../../models/Tag';
import { TeamPerson } from '../../../models/TeamPerson';
import { UserDetails } from '../../../models/UserDetails';
import { formatValuesToSelectAsync } from '../../../utils/funcoes/reactSelect';
import HttpService from '../../config/http';
import { CallbackType, DataType } from '../types';
import { PostProjectParams, ProjetoRelatorioProps } from './types';

export default class ProjetoService {
	private api: HttpService;
	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getProjectDetail = async ({id, serializer}: {id: number, serializer: "kanban" | "none"}) => {
		const result: AxiosResponse<ProjectDetail> = await this.api.get(
			`api/v1/projetos/detail/${id}/?timestamp=${new Date().getTime()}&all=true&serializer=${serializer}`,
		);

		return result.data;
	};


	getDetalheProjeto = (id: number, success: CallbackType<ProjectDetail>, errorCallback: CallbackType<string>, config?: AxiosRequestConfig) => {
		this.api
			.get(`api/v1/projetos/detail/${id}/?timestamp=${new Date().getTime()}`, config)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar os projetos!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getProjetosPaginacao = (page: number, search: string, id: number, success: CallbackType<Project>, errorCallback: CallbackType<string>) => {
		// SE O USUARIO TIVER LIMPADO O CACHE ELE USARA O SERIALIZER NOVO, SENÃO O ANTIGO
		this.api
			.get(
				`api/v1/projetos/?page=${page}&paginate=${page}&search=${search}&timestamp=${new Date().getTime()}&serializer=new`,
			)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar os projetos!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getProjetosPaginacaoFiltered = (
		page: number,
		search: string,
		filters: string,
		success: CallbackType<DataType<ProjectPage[]>>,
		errorCallback: CallbackType<string>,
		signal: AbortSignal
	) => {
		// SE O USUARIO TIVER LIMPADO O CACHE ELE USARA O SERIALIZER NOVO, SENÃO O ANTIGO
		this.api
			.get(
				`api/v1/projetos/?page=${page}&paginate=${page}&e_time_okr=false&search=${search ? search : ''}&timestamp=${new Date().getTime()}&serializer=new&${filters}`,
				{
					signal
				}
			)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar os projetos!"));
					}
				}
			})
			.catch(error => {
				if (error.name === 'AbortError') {
					console.log('Requisição cancelada');
				} else {
					errorCallback(error.toString())
				}
			});
	};

	getProjetosRelatorio = (
		{
			page,
			id,
			client,
			tags,
			encerrados,
			admin,
			search,
		}: ProjetoRelatorioProps,
		success: CallbackType<DataType<ProjectReport[]>>,
		errorCallback: CallbackType<string>,
		config?: AxiosRequestConfig
	) => {
		this.api
			.get(
				`api/v1/projetos/?relatoriohoras=sd&admin_projeto__id=${admin}&search=${search}&page=${page}&paginate=${page}&id=${id}&cliente=${client}&tags=${tags}&ativo=${encerrados}&all=true&timestamp=${new Date().getTime()}`,
				config
			)
			.then(result => {
				if(result.status !== 499) {
					if (result.status === 200) {
						success(result.data);
					} else{
						if (typeof result.data === typeof {}) {
							const objError = result.data;
							for (const key in objError) {
								throw new Error(t(`${objError[key]}`) + "!");
							}
						} else {
							throw new Error(t("Erro ao tentar carregar os projetos!"));
						}
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getProject = async (id: number, search = '') => {
		const result = await this.api.get(
			`api/v1/projetos/${id}/?search=${search}&timestamp=${new Date().getTime()}&serializer=new&all=true`,
		);

		return result.data;
	};

	getProjetos = (id: number, success: CallbackType<AxiosResponse<Project>>, errorCallback: CallbackType<string>, search:string = '') => {
		this.api
			.get(
				`api/v1/projetos/${id}/?search=${search}&timestamp=${new Date().getTime()}&serializer=new`,
			)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar os projetos!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	loadProjectsSelect = async (
		search: string,
		loadedOptions: any,
		{ page = 1, cliente = '', placeholder = '' },
		kanban=false,
		userDetails: UserDetails | null = null
	) => {
		let filterUser = '';
		if(userDetails) {
			const filterUserAcc =
			userDetails.acesso_cod === 'a'
				? ''
				: `time__in=${userDetails.id_pessoa}&`;
			filterUser =  filterUserAcc;
		}

		const response: AxiosResponse<DataType<CommonModelWithClient[]>> = await this.api.get(
			`api/v1/projetos/?${filterUser}cliente__in=${cliente}&${kanban ? "kanbanminimal" : "minimal"}=true&paginate=true&page=${page}&search=${search ? search : ''}&timestamp=${new Date().getTime()}`,
		);

		if (response.status !== 200) {
			if (typeof response?.data?.results === typeof {}) {
				const objError = response?.data?.results;
				for (const key in objError) {
					throw new Error(t(`${objError[key as keyof typeof objError]}`) + "!");
				}
			} else {
				throw new Error(t("Erro ao tentar carregar os projetos!"));
			}
		}

		return {
			options: formatValuesToSelectAsync(response?.data?.results, placeholder, kanban),
			hasMore: !!response?.data?.next,
			additional: {
				page: page + 1,
				cliente,
			},
		};
	};

	loadKanbanProjecsSelect = (
		search: string,
		loadedOptions: any,
		{ page = 1, cliente = '', placeholder = '', userDetails = null},
	) => {
		return this.loadProjectsSelect(
			search,
			loadedOptions,
			{ page, cliente, placeholder},
			true,
			userDetails
		)
	}

	getProjetosTags = (success: CallbackType<AxiosResponse<Tag[]>>, errorCallback: CallbackType<string>) => {
		this.api
			.get(`api/v1/tags/`)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar os projetos!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getProjetosSelect = (cliente: number, success: CallbackType<AxiosResponse<DataType<Project[]>>>, errorCallback: CallbackType<string>) => {
		this.api
			.get(`api/v1/projetos?cliente=${cliente}&minimal=true`)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar os projetos!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	loadProjetosAllSelect = async (
		search: string,
		loadedOptions: any,
		{ page = 1, cliente = ''},
	) => {
		const response = await this.api.get(
			`api/v1/projetos/?cliente=${cliente}&minimal=true&all=true&paginate=true&page=${page}&search=${search}`,
		);
		if (response?.status !== 200) {
			if (typeof response?.data === typeof {}) {
				const objError = response?.data;
				for (const key in objError) {
					throw new Error(t(`${objError[key]}`) + "!");
				}
			} else {
				throw new Error(t("Erro ao tentar carregar os projetos!"));
			}
		}

		return {
			options: formatValuesToSelectAsync(response?.data?.results, ''),
			hasMore: !!response?.data?.next,
			additional: {
				page: page + 1,
				cliente,
				placholder: '',
			},
		};
	};

	getProjetosAllSelect = (cliente: number, success: CallbackType<AxiosResponse<DataType<Project[]>>>, errorCallback: CallbackType<string>) => {
		// Para atender necessidade do administrativo ASAP, foi criado essa funcao separada.
		this.api
			.get(`api/v1/projetos?cliente=${cliente}&minimal=true&all=true`)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar os projetos!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	postProjeto = (params: PostProjectParams, success: CallbackType<AxiosResponse<ProjectPostResponse>>, errorCallback: CallbackType<string>) => {
		this.api
			.post(`api/v1/projetos/`, params)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar salvar o projeto!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	postAdmin = (projeto: number, success: CallbackType<AxiosResponse<Responsible>>, errorCallback: CallbackType<string>) => {
		this.api
			.post(`api/v1/membro-time/?time=${projeto}`, { IS_ADMIN_PROJECT: true })
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar salvar o projeto!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchProjeto = (params: PostProjectParams, id: number, success: CallbackType<AxiosResponse<Project>>, errorCallback: CallbackType<string>) => {
		this.api
			.patch(`api/v1/projetos/${id}/`, params)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar o projeto!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchProjetoObjectParams = (params: object, id: number, success: CallbackType<AxiosResponse<Project>>, errorCallback: CallbackType<string>) => {
		this.api
			.patch(`api/v1/projetos/${id}/`, params)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar o projeto!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteProjeto = (id: number, success: CallbackType<AxiosResponse<string>>, errorCallback: CallbackType<string>) => {
		this.api
			.delete(`api/v1/projetos/${id}/`)
			.then(result => {
				if (result.status === 204 || result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar remover o projeto!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	encerrarProjeto = (id: number, success: CallbackType<AxiosResponse<Project>>, errorCallback: CallbackType<string>) => {
		this.api
			.patch(`api/v1/projetos/${id}/`, { item_ativo: false })
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar o projeto!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	reativarProjeto = (id: number, success: CallbackType<AxiosResponse<Project>>, errorCallback: CallbackType<string>) => {
		this.api
			.patch(`api/v1/projetos/${id}/?all=true`, { item_ativo: true })
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(t(`${objError[key]}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar o projeto!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	// FAVORITOS //

	getFavoritos = (success: CallbackType<AxiosResponse<DataType<Project>>>, errorCallback: CallbackType<string>) => {
		this.api
			.get('api/v1/projeto-favorito/')
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(t(`${value}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar favoritos!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getProjetoTime = (projetoID: number, success: CallbackType<DataType<TeamPerson[]>>, errorCallback: CallbackType<string>) => {
		this.api
			.get(`api/v1/membro-time/?time=${projetoID}`)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(t(`${value}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar time!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getAllProjetoTime = async (projectId: number, page = 1) => {
		try {
			const response = await this.api.get(`api/v1/membro-time/?time=${projectId}&page=${page}&all=true`);
			let teamPerson: TeamPerson[] = response.data.results;
			if(response.data.next) {
				teamPerson = [...teamPerson, ...await this.getAllProjetoTime(projectId, page + 1)];
			}
			return teamPerson;
		}catch {
			return []
		}
	}


	deleteMembroTime = (membroID: number, success: CallbackType<AxiosResponse<string>>, errorCallback: CallbackType<string>) => {
		this.api
			.delete(`api/v1/membro-time/${membroID}`)
			.then(result => {
				if (result.status === 204) {
					success(result);
				} else {
					throw new Error(t("Erro ao tentar deletar membro do time!"));
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchMembroTime = (membroID: number, form: FormData, success: CallbackType<Responsible>, errorCallback: CallbackType<string>) => {
		this.api
			.patch(`api/v1/membro-time/${membroID}/`, form)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else {
					throw new Error(t("Erro ao tentar atualizar membro do time!"));
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	postFavoritos = (id: number, success: CallbackType<AxiosResponse<Project>>, errorCallback: CallbackType<string>) => {
		this.api
			.post('api/v1/projeto-favorito/', id)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(t(`${value}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar adicionar favorito!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getProjetoFile = (projetoID: number, success: CallbackType<any[]>, errorCallback: CallbackType<string>) => {
		this.api
			.get(`api/v1/documentos/?projeto=${projetoID}`)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(t(`${value}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar carregar arquivo do projeto!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	postProjetoFile = (
		fileData: FormData, 
		config: {onUploadProgress: (e: any) => void}, 
		success: CallbackType<AxiosResponse<any[]>>, 
		errorCallback: CallbackType<string>
	) => {
		this.api
			.post(`api/v1/documentos/`, fileData, {
				...config,
				headers: {
					"Content-Type": "multipart/form-data",
				}
			})
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(t(`${value}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar adicionar arquivo!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteProjetoFile = (fileID: number, projetoID: number, success: CallbackType<AxiosResponse<string>>, errorCallback: CallbackType<string>) => {
		this.api
			.delete(`api/v1/documentos/${fileID}/?projeto=${projetoID}`)
			.then(result => {
				if (result.status === 204 || result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(t(`${value}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar excluir arquivo!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteFavoritos = (id: number, success: CallbackType<AxiosResponse<string>>, errorCallback: CallbackType<string>) => {
		this.api
			.delete(`api/v1/projeto-favorito/${id}/`)
			.then(result => {
				if (result.status === 204 || result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(t(`${value}`) + "!");
						}
					} else {
						throw new Error(t("Erro ao tentar remover o favorito!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	postPrecoHora = (formData: FormData, success: CallbackType<any>, errorCallback: CallbackType<string>) => {
		this.api
			.post(`api/v1/pessoas-planus/preco-hora/`, formData)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(t(`${value}`) + "!");
						}
					} else {
						throw new Error(t("Erro atualizar preço hora!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchPrecoHora = (id: number, form: FormData, success: CallbackType<any>, errorCallback: CallbackType<string>) => {
		this.api
			.patch(`api/v1/pessoas-planus/preco-hora/${id}/`, form)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else {
					throw new Error(t("Erro atualizar preço hora!"));
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
}
