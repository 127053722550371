import { AxiosResponse } from 'axios';
import HttpService from '../../../config/http';
import { EmpresaProgressoResponse, GetTimeByIdProps, GetTimeProps, TimeResponse } from './types';
import { t } from 'i18next';
import { DataType } from '../../types';
import { SelectOptions } from '../../../../models/Select';

export default class TimeOkrsService {
	private api: HttpService;
	constructor(props?: any) {
		this.api = new HttpService(props);
	}
	
	getProgressoEmpresa = async (
		{
			filters,
			signal
		}: {
			filters: string
			signal?: AbortSignal
		}
	) => {
		const response: AxiosResponse<EmpresaProgressoResponse> = await this.api
			.get(
				`api/v1/contas-planus/empresa-okr/?${filters}`,
				{
					signal
				}
			).catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t("Erro ao tentar carregar os projetos!"));
	};

	getTimeOkrsByID = async (
		{
			id,
			filters,
			signal
		}: GetTimeByIdProps
	) => {
		const response: AxiosResponse<TimeResponse> = await this.api
			.get(
				`api/v1/projetos/${id}/?serializer=okr&${filters}`,
				{
					signal
				}
			).catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t("Erro ao tentar carregar os projetos!"));
	};

	getTimesOkrs = async (
		{
			filters, 
			page, 
			search,
			signal
		}: GetTimeProps
	) => {
		const response: AxiosResponse<DataType<TimeResponse[]>> = await this.api
			.get(
				`api/v1/projetos/?serializer=okr&e_time_okr=true&page=${page}&paginate=true&page=${page}&search=${search ?? ""}&timestamp=${new Date().getTime()}&${filters}`,
				{
					signal
				}
			).catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t("Erro ao tentar carregar os projetos!"));
	};

	loadTimesSelect = async (
		search: string,
		loadedOptions: any,
		{ page = 1, placeholder}: {page?: number, placeholder?: string}
	) => {
		const response: AxiosResponse<
			DataType<TimeResponse[]>
		> = await this.api.get(`api/v1/projetos/?serializer=okr&e_time_okr=true&search=${search ?? ""}&paginate=true&page=${page}`);

		if (response?.status !== 200) {
			if (typeof response?.data === typeof {}) {
				const objError = response?.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof typeof objError]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar buscar o ciclo!'));
			}
		}

		const arrayRenomeado: SelectOptions[] = response?.data.results.map(objeto => (
			{
				label: objeto.nome,
				value: objeto.id
			}
		));	

		if(placeholder) {
			arrayRenomeado.splice(0, 0, { value: '', label: placeholder})
		}
		
		return {
			options: arrayRenomeado,
			hasMore: !!response?.data?.next,
			additional: {
				page: page + 1,
			},
		};
	};
}
