import React, { useEffect } from 'react';

import { t } from 'i18next';
import { IconType } from 'react-icons/lib';
import Moment from 'react-moment';
import { useRecordHistory } from '../../../contexts/RecordHistoryContext';
import i18n from '../../../i18n';
import { HoursRecord } from '../../../models/HoursRecord';
import HourService from "../../../services/providers/hour";
import { verifyHasHeader } from '../../../utils/funcoes/historyHeader';
import { sumHoursWithMinutesString } from '../../../utils/funcoes/manipulateTime';
import DinamicPagination from '../DinamicPagination';
import { DateTitle, IconButton, RecordDateContainer, RecordHistoryContainer, RecordHistoryContent, RecordItem, RecordTitle } from './styled';
import { useStopwatch } from '../../../contexts/Stopwatch';
import { useUser } from '../../../contexts/User';

export interface RecordHistoryProps {
    CallbackIcon?: IconType;
    iconTitle: string;
    callbackFunction?: (record: HoursRecord) => void;
    disabled?: boolean; 
    isOpen: boolean;
}

export function RecordHistory({CallbackIcon, callbackFunction, iconTitle, disabled, isOpen}: RecordHistoryProps) {
    const {
        recordsHistory, 
        setRecordsHistory, 
        currentPageHistory,
        loadingHistory,
        totalPagesHistory,
        setLoadingHistory,
        setTotalPagesHistory,
        setCurrentPageHistory
    } = useRecordHistory();

    const { userDetails } = useUser();

    const {currentStopwatch} = useStopwatch();

    let dateHeader = "";

    const calendarStrings = {
		sameDay: `[${t('Hoje')}]`,
		lastDay: `[${t('Ontem')}]`,
		lastWeek: 'LL',
		sameElse: 'LL',
	};

    const _getHorasPaginacao = (
		page = 1,
		signal = undefined,
	) => {
		setLoadingHistory(true);
		const horaService = new HourService();
		if(userDetails) {
            horaService.getHorasPaginacao(
                page,
                {
                    search: "",
                    tarefa: "",
                    pessoa: userDetails?.id_pessoa.toString(),
                },
                "",
                "",
                response => {
                    setLoadingHistory(false);
                    if (page === 1) {
                        setTotalPagesHistory(response.total_pages);
                        setCurrentPageHistory(page);
                        setRecordsHistory(response.results);
                    } else {
                        setTotalPagesHistory(response.total_pages);
                        setCurrentPageHistory(page);
                        setRecordsHistory([...recordsHistory, ...response.results]);
                    }
                },
                () => {
                    setRecordsHistory([]);
                    setLoadingHistory(false);
                },
                { signal },
            );
        }
	};

    const handleChangeDateHeader = (newDate: string) => {
        dateHeader = newDate;
    }

    const handleSumTotalDay = (dateValue: string) => {
        return sumHoursWithMinutesString(recordsHistory.filter(item => item.data === dateValue).map(item => item.duracao))
    }

    const handlePagination = (page: number) => {
		_getHorasPaginacao(page);
	};


    useEffect(() => {
        _getHorasPaginacao();
       
    }, [currentStopwatch]);


    return (
        <RecordHistoryContainer>
            <DinamicPagination
					loading={loadingHistory}
					totalPages={totalPagesHistory}
					currentPage={currentPageHistory}
					lengthData={recordsHistory.length}
					returnPage={handlePagination}
                    scrollDisabled={!isOpen}
					msgToEmptyData={t('Sem registros de horas nesse período')}
				>
					<RecordHistoryContent>
                    <RecordTitle>Histórico de tarefas</RecordTitle>

                    {recordsHistory.map(record => {
                        return (
                            <div key={`historyItem-${record.id}`}>
                                {verifyHasHeader({dateValue: record.data, dateHeader: dateHeader, handleChangeDateHeader: handleChangeDateHeader}) && (
                                    <DateTitle>
                                        <Moment locale={i18n.language} calendar={calendarStrings}>
                                            {record.data}
                                        </Moment>
                                        <p>{handleSumTotalDay(record.data)}</p>
                                    </DateTitle>
                                )}

                                <RecordItem>
                                    <div>
                                        <p title={record.tarefa_nome} className={"task-name"}>
                                            {record.tarefa_nome}
                                        </p>
                                        <div className='history-client'>
                                            <div></div>
                                            {record.cliente_nome ? record.cliente_nome : record.tarefa_nome}
                                        </div>
                                        
                                    </div>

                                    <RecordDateContainer>
                                        {callbackFunction !== undefined && CallbackIcon !== undefined && (
                                            <IconButton title={t(iconTitle)}  onClick={() => callbackFunction(record)} disabled={disabled}  className='history-callback-play'>
                                                <CallbackIcon 
                                                    color={"#426172"} 
                                                    size={16}
                                                    
                                                />
                                            </IconButton>
                                        )}
                                        <p>{record.duracao.slice(0, 5)}</p>
                                    </RecordDateContainer>
                                </RecordItem>
                            </div>
                        )
                    })}
                    </RecordHistoryContent>
			</DinamicPagination>
        </RecordHistoryContainer>
    )
}