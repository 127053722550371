import React, { useEffect, useState } from 'react';

import clienteImage from '../../../../../assets/images/company.png';
import { ClienteSchema } from './validation';

import { COLORS_OPTIONS } from '../../../../../utils/constants/client-colors';

import {
	faExclamationTriangle,
	faPen
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik';

import '../../../../../form/formGeral.css';
import './style.css';
import { useTranslation } from 'react-i18next';

const { useRef } = React;

export const ClienteForm = props => {
	const {t} = useTranslation();
	const buttonRef = useRef();
	const [selectedColor, setSelectedColor] = useState();

	useEffect(() => {
		if (props.item_edit !== undefined && props.item_edit.cor_tema) {
			setSelectedColor({
				label: "",
				value: props.item_edit.cor_tema,
				color: props.item_edit.cor_tema
			})
		} else {
			setSelectedColor(COLORS_OPTIONS[8]);
		}
	}, [props.item_edit]);

	let initialValues = null;
	let logo_cliente = clienteImage;
	if (typeof props.item_edit !== 'undefined') {
		initialValues = {
			id: props.item_edit.id,
			nome: props.item_edit.nome,
			atividade: props.item_edit.atividade,
			contato: props.item_edit.contato,
			observacao: props.item_edit.observacao,
			cor_tema: props.item_edit.cor_tema,
		};

		logo_cliente = props.item_edit.logo_cliente
			? props.item_edit.logo_cliente
			: clienteImage;
	} else {
		initialValues = {
			nome: '',
			atividade: 'teste',
			contato: '',
			observacao: '',
			cor_tema: COLORS_OPTIONS[8].value
		};
		logo_cliente = clienteImage;
	}

	const fileSelect = event => {
		const file = event.target.files[0];
		const reader = new FileReader();
		var imgInfo = document.getElementById('img-form-edit-cliente');
		reader.onloadend = function () {
			imgInfo.src = reader.result;
		};

		if (file) {
			reader.readAsDataURL(file);
		} else {
			logo_cliente = props.item_edit.logo_cliente
				? props.item_edit.logo_cliente
				: clienteImage;
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={ClienteSchema}
			enableReinitialize={true}
			onSubmit={(values, { setFieldValue, resetForm }) => {
				var image_field = document.getElementsByClassName('form-file-cliente');
				
				if (image_field.length > 0 && image_field[0].files.length > 0) {
					values['logo_cliente'] = image_field[0].files[0];
				}

				const form_data = new FormData();

				if (props.editing) {
					for (let key in values) {
						if (values[key] !== props.item_edit[key]) {
							form_data.append(key, values[key]);
						} else {
						}
					}
				} else {
					for (let key in values) {
						form_data.append(key, values[key]);
					}
				}

				const image_profile = document.getElementById('img-form-edit-cliente');

				if (props.editing) {
					props.handleSubmit(form_data, props.item_edit.id);
				} else {
					props.handleSubmit(form_data);
				}

				setFieldValue('logo_cliente', '');
				image_field[0].value = '';
				image_profile.src = clienteImage;
				resetForm(initialValues);
			}}
		>
			{({ errors, touched, setFieldValue }) => (
				<FormikForm>
					<fieldset>
						<div className="col-12 col-md-12 col-lg-12">
							<div className="form-container-style">
								<div className="row">
									<div className="profile-header-container">
										<div className="profile-header-img">
											<img
												id="img-form-edit-cliente"
												className="img-circle"
												src={logo_cliente}
												alt="Foto de Perfil"
											/>
											<input
												className="form-file-cliente"
												style={{ display: 'none' }}
												type="file"
												onChange={fileSelect}
												ref={buttonRef}
											></input>
											<div
												className="edit-icon-container"
												onClick={() => {
													buttonRef.current.click();
												}}
											>
												<FontAwesomeIcon
													icon={faPen}
													className="item-form-edit-icon"
												/>
											</div>
										</div>
									</div>
								</div>
								<label className="modal-label-side" htmlFor="nome">
									{t("Nome do cliente")}*
								</label>
								<Field
									autoComplete={'off'}
									type="text"
									name="nome"
									className={
										errors.nome && touched.nome
											? 'form-error-style-input'
											: 'form-default-style-input'
									}
								/>
								<ErrorMessage name="nome">
									{msg => (
										<div>
											<div className="input-error-style" role="alert">
												<FontAwesomeIcon
													icon={faExclamationTriangle}
													className="mr-1"
												/>
												{msg}
											</div>
										</div>
									)}
								</ErrorMessage>
								<label
									className="modal-label-side"
									type="text"
									htmlFor="contato"
								>
									{t("Contato do cliente")}
								</label>
								<Field
									autoComplete={'off'}
									type="text"
									component="textarea"
									name="contato"
									className={
										errors.contato && touched.contato
											? 'form-error-style-input'
											: 'form-textArea-style-input'
									}
								/>
								<ErrorMessage name="contato">
									{msg => (
										<div>
											<div className="input-error-style" role="alert">
												<FontAwesomeIcon
													icon={faExclamationTriangle}
													className="mr-1"
												/>
												{msg}
											</div>
										</div>
									)}
								</ErrorMessage>
								<label
									className="modal-label-side"
									type="text"
									htmlFor="observacao"
								>
									{t("Observações")}
								</label>
								<Field
									autoComplete={'off'}
									type="text"
									component="textarea"
									name="observacao"
									className={
										errors.observacao && touched.observacao
											? 'form-error-style-input'
											: 'form-textArea-style-input'
									}
								/>
								<ErrorMessage name="observacao">
									{msg => (
										<div>
											<div className="input-error-style" role="alert">
												<FontAwesomeIcon
													icon={faExclamationTriangle}
													className="mr-1"
												/>
												{msg}
											</div>
										</div>
									)}
								</ErrorMessage>
								<br />
								<input
									style={{ display: 'none' }}
									id="formSideModal-cliente"
									type="submit"
									// value="Enviar"
									className="btn btn-primary mt-3"
									// disabled={store.loading}
									onClick={() => {
										// setModalSideShow(false);
									}}
								/>
							</div>
						</div>
					</fieldset>
				</FormikForm>
			)}
		</Formik>
	);
};
