import styled from "styled-components";


interface ThProps {
    width?: string;
    fontSize?: string;
}

export const Th = styled.th<ThProps>`
    height: 40px;
    ${({fontSize}) => fontSize ? `${fontSize}` : 'font-size: 14px'};
    text-align: start;
    white-space: nowrap;
    min-width: ${({width}) => width}px;
    input {
        font-weight: 700;
        color: rgb(64, 97, 115);
    }

`

interface ContentDivProps {
    customStyle?: string;
}

export const ContentDiv = styled.div<ContentDivProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding-right: 20px;
    ${({customStyle}) => customStyle}

    svg {
        position: absolute;
        right: 8px;
        display: none;
        margin-top: 1px;
    }

    &:hover {
        svg {
            display: initial;
        }
    }
`;