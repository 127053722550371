import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { Column } from '../../../models/Column';
import { ProjectPage } from '../../../models/Project';
import { Task } from '../../../models/Task';
import HttpService from '../../config/http';
import { DataType } from '../types';
import {  CompanyKanban } from '../../../models/Company';
import { SelectOptions } from '../../../models/Select';

interface ProjectFilterType {
	myProjects: boolean;
	clienteValorSelecionado: SelectOptions;
	searchProject: string;
	userId: number;
}

export class KanbanProjectService {
	private api: HttpService;
	
	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getCompanyKanban = async (config: AxiosRequestConfig) => {
		const result: AxiosResponse<CompanyKanban> = await this.api
			.get(`api/v1/contas-planus/empresa-kanban/`, config);

		if (result.status === 200) {
			return (result.data);
		} else if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof CompanyKanban]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar carregar os projetos') + "!");
			}
	};

	updateColumnCompanyKanban = async ({primeira_coluna}: {primeira_coluna: string}) => {
		const result: AxiosResponse<CompanyKanban> = await this.api
			.put(`api/v1/contas-planus/empresa-kanban/`, {
				primeira_coluna: primeira_coluna
			});

		if (result.status === 200) {
			return (result.data);
		} else if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof CompanyKanban]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar carregar os projetos') + "!");
			}
	};

	getColumnsByCompany = async (filterContext: ProjectFilterType, config: AxiosRequestConfig) => {
		const result: AxiosResponse<DataType<Column[]>> = await this.api
			.get(`/api/v1/kanban/colunas-kanban-projeto/`, config);

		const tempArray = await Promise.all(result.data.results.map(async (item: any) => {
			const projectsResponse = await this.getProjectsByColumn(
				item.id,
				filterContext,
				1,
				config
			);

			return {
				...item,
				total_pages: projectsResponse.total_pages,
				tarefas: projectsResponse.results
			}
		}));

		if (result.status === 200) {
			return ({ ...result.data, results: tempArray });
		} else {
			throw new Error(t('Erro ao recuperar colunas') + "!");
		}
	};

	patchColumn = async (columnData: {id: string, ordenacao: number, nome?: string}) => {
		const result: AxiosResponse<Column> = await this.api
			.patch(`/api/v1/kanban/colunas-kanban-projeto/${columnData.id}/`, columnData)

		if (result.status === 200) {
			return (result.data);
		} else if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof Column]}`);
				}
			} else {
				throw new Error(t('Erro ao editar coluna') + "!");
			}
	};

	deleteColumn = async ({columnId}: {columnId: string}) => {
		const result: AxiosResponse<null> = await this.api
			.delete(`/api/v1/kanban/colunas-kanban-projeto/${columnId}/`)

		if (result.status === 204) {
			return;
		} else {
			throw new Error(t('Erro ao deletar') + "!");
		}
	};

	getProjectsByColumn = async ( columnId: string,  filterContext: ProjectFilterType, page: number = 1, config?: AxiosRequestConfig) => {
		
		const {
			clienteValorSelecionado,
			myProjects,
			searchProject,
			userId
		} = filterContext;

		const teamFilter = myProjects ? `&time__in=${userId}` : ''

		const result: AxiosResponse<DataType<ProjectPage[]>> = await this.api
			.get(`/api/v1/projetos/?serializer=kanban&paginate=true&all=true&coluna_kanban=${columnId}&page=${page}&search=${searchProject}&cliente=${clienteValorSelecionado.value}${teamFilter}`, config);
		if (result.status === 200) {
			return ({
				...result.data, 
				results: result.data.results.map((project: ProjectPage) => (
				{
					...project,
				}
				))
			});
		} else {
			throw new Error(t('Erro ao recuperar tarefas') + "!");
		}
	};

	getProjectsWithNoColumn = async (filterContext: ProjectFilterType, page = 1, status = '', config?: AxiosRequestConfig): Promise<DataType<ProjectPage[]>>=> {
		const {
			clienteValorSelecionado,
			myProjects,
			searchProject,
			userId
		} =  filterContext;

		const active = status === '';
		const teamFilter = myProjects ? `&time__in=${userId}` : ''
		
		const result: AxiosResponse<DataType<ProjectPage[]>> = await this.api
			.get(`/api/v1/projetos/?serializer=kanban&paginate=true&all=true&coluna_kanban_null=true&page=${page}&search=${searchProject}&cliente=${clienteValorSelecionado.value}&ativo=${active}${teamFilter}`, config);

		if (result.status === 200) {
			return ({
				...result.data, 
				results: result.data.results.map((project: ProjectPage) => (
				{
					...project
				}
				))
			});
		}else if(result.status === 499) {
			throw new Error("Cancelled")
		} else {
			console.log('result no erro', result)
			throw new Error(t('Erro ao recuperar tarefas') + "!");
		}
	};

	postColumn = async (columnData: {nome: string, empresa: number, ordenacao: number}) => {
		const result: AxiosResponse<Column> = await this.api
			.post(`/api/v1/kanban/colunas-kanban-projeto/`, columnData)

		if (result.status === 201) {
			return (result.data);
		} else if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof Column]}`);
				}
			} else {
				throw new Error(t('Erro criar coluna') + "!");
			}
	};

	patchProjectInColumn = async (projectData: any) => {
		const result: AxiosResponse<Task> = await this.api
			.patch(`/api/v1/projetos/${projectData.id}/?all=true&serializer=kanban`, projectData);

		if (result.status === 200) {
			return (result.data);
		} else if (typeof result.data === typeof {}) {
			const objError = result.data;
			for (const key in objError) {
				throw new Error(`${objError[key as keyof Task]}`);
			}
		} else {
			throw new Error(t('Erro ao reposicionar tarefa') + "!");
		}
	}

}
