import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback } from 'react';
import {  GroupBase, OptionProps, ValueContainerProps } from 'react-select';
import { SearchIcon } from '../../../../../components/common/SelectDropdowmIndicator/styled';
import { OptionWrapper } from '../../../../../components/containers/Filter/Option/styled';
import { SelectOptions } from '../../../../../models/Select';
import { ProjectOption } from '../ProjectOption';
import { ContainerDefault, KanbanValueContainer } from './styled';

export const KanbanProjectOption = (props: OptionProps<SelectOptions>) => {
	const { data, setValue, isSelected, isMulti, getValue } = props;
	const value = getValue();
	const handleChange = useCallback(() => {
		if(!data.isDisabled) {
			if (isSelected && isMulti) {
				setValue(
					value.filter(item => item.value !== data.value),
					'deselect-option',
				);
			} else if (!isSelected) {
				isMulti
					? setValue([...value, data], 'deselect-option')
					: setValue(data, 'select-option');
			}
		}
	}, [isSelected, isMulti, value, data]);
	return (
		<OptionWrapper onClick={handleChange} isDisabled={data.isDisabled} isSelected={isSelected}>
			<ProjectOption data={data} isSelected={isSelected} />
		</OptionWrapper>
	)
}


export const SingleContainerKanban = (
	props: ValueContainerProps<
		SelectOptions,
		boolean,
		GroupBase<SelectOptions>
	>,
) => {

	return (
		<KanbanValueContainer {...props as any}>
			<SearchIcon icon={faSearch} className="kanban-search-icon"/>
			<ContainerDefault>
				{props.children}
			</ContainerDefault>
		</KanbanValueContainer>
	);
};

export const DropIndicatorKanban = (
) => {

	return (
		<></>
	);
};