import styled from 'styled-components';

export const ModalWidthTarefas = styled.div`
	max-height: calc(100vh - 214px);
	background: #f7f6f5;
`;

export const SeletorTimeContainer = styled.div`
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	@media (min-width: 576px) {
		max-width: 540px;
	}
	@media (min-width: 768px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
`;

export const SeletorTimeRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
`;

export const SeletorTimeItem = styled.div`
	flex: 0 0 100%;
	max-width: 100%;
	background: rgb(247, 246, 245);
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
`;
