import React from "react";
import { Container, Label, LabelAux } from "./styled";

export interface SearchOptionLabelProps {
    label: string;
    labelAux?: string;
}

const SearchOptionLabel = ({label, labelAux}: SearchOptionLabelProps) => {

    return(
        <Container>
            <Label>{label}</Label>
            {labelAux && <LabelAux>{labelAux}</LabelAux>}
        </Container>
        
    )
}

export default SearchOptionLabel;
