import { atom } from 'jotai'
import { atomWithStorage} from 'jotai/utils'
import { Project } from '../../models/Project'
import { TeamPerson } from '../../models/TeamPerson';
import moment from 'moment';

export const projectInfoAtom = atom<Project | undefined>(undefined);

export const ProjectTeamTempAtom = atom<TeamPerson[]>([]);

export const ProjectTeamAtom = atom<TeamPerson[]>([]);

export const ProjectUploadedFilesAtom = atom<File[]>([]);

export const ProjectFileDataGetAtom = atom<File[]>([]);

export const ProjectTeamLoading = atom<boolean>(false);

export const StartPeriodAtom = atomWithStorage<string>('project-start-period',moment().startOf('month').format('YYYY-MM-DD'))

export const NumOfDaysAtom = atomWithStorage<string>('project-numofdays', '30');
