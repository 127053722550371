import React, { useCallback, useState } from 'react';

import PessoaService from '../../services/providers/responsible';
import defaultProfile from '../../assets/images/profile.png';
import { InfoPessoaSchema } from './validation';

import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

import '../../form/formGeral.css';
import './style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faExclamationTriangle,
	faPen,
} from '@fortawesome/free-solid-svg-icons';
import LanguageSelector from '../../i18n/components/LanguageSelector';
import { useTranslation } from 'react-i18next';

const { useRef } = React;

export const InfoPessoaForm = (props) => {
	const {t} = useTranslation();
	const infoPessoaRef = useRef();

	const [langChange, setLangChange] = useState("");

	let initialValues = null;
	let foto_perfil = defaultProfile;
	if (typeof props.item_edit !== 'undefined') {
		initialValues = {
			acesso_cod: props.item_edit.acesso_cod,
			id: props.item_edit.id_pessoa,
			nome: props.item_edit.username,
			funcao: props.item_edit.funcao,
		};
		foto_perfil = props.item_edit.foto_perfil
			? props.item_edit.foto_perfil
			: defaultProfile;
	} else {
		initialValues = {
			id: '',
			nome: '',
			funcao: '',
		};
		foto_perfil = defaultProfile;
	}

	const fileSelect = event => {
		const file = event.target.files[0];
		const reader = new FileReader();
		var imgInfo = document.getElementById('img-form-edit-infoPessoa');
		reader.onloadend = function() {
			imgInfo.src = reader.result;
		};
		if (file) {
			reader.readAsDataURL(file);
		} else {
			foto_perfil = props.item_edit.foto_perfil
				? props.item_edit.foto_perfil
				: defaultProfile;
		}
	};

	const _patchPessoa = (params, id) => {
		props.handleLoading(true);
		const pessoaService = new PessoaService();
		pessoaService.patchPessoa(
			params,
			id,
			response => {
				if (response.data) {
					toast.success(t("Registro atualizado com sucesso!"));
					props.modalGenericoShow(false);
					props.handlePostPatchInfoPessoa(response.data);
				}
				props.handleLoading(false);
			},
			error => {
				toast.error(error);
				props.handleLoading(false);
			},
		);
	};

	const handleAfterChangeLanguage = useCallback((lang) => {
		setLangChange(lang);
	}, []);


	return (
		<Formik
			initialValues={initialValues}
			validationSchema={InfoPessoaSchema}
			enableReinitialize={true}
			onSubmit={(values, { setFieldValue, resetForm }) => {
				const image_field = document.getElementsByClassName(
					'form-img-infoPessoa',
				);
				if (image_field.length > 0 && image_field[0].files.length > 0) {
					values['foto_perfil'] = image_field[0].files[0];
				}
				const form_data = new FormData();
				for (var key in values) {
					form_data.append(key, values[key]);
				}

				if(langChange !== "") {
					form_data.append("idioma", langChange);
				}

				if (props.editing) {
					_patchPessoa(form_data, props.item_edit.id_pessoa);
				}
			}}
			render={props => (
				<FormikForm>
					<fieldset>
						<div className="modal-bg-center">
							<div className="form-container-style-tarefas container">
								<div className="row">
									<div className="col-12">
										<div className="infoPessoa-container">
											<img
												id="img-form-edit-infoPessoa"
												className="infoPessoa-img"
												src={foto_perfil}
												alt="Foto de Perfil"
											/>

											<input
												className="form-img-infoPessoa"
												style={{ display: 'none' }}
												type="file"
												onChange={fileSelect}
												accept="image/*"
												ref={infoPessoaRef}
											></input>
											<div className="name-infoPessoa-container">
												<p className="infoPessoa-text-name">
													{props.values.nome}
												</p>
												<div
													className="editFoto-infoPessoa"
													onClick={() => {
														infoPessoaRef.current.click();
													}}
												>
													<p className="infoPessoa-text-edit">
														<FontAwesomeIcon
															icon={faPen}
															className="infoPessoa-icon-edit"
														/>
														{t("Escolher outra foto")}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-12 col-md-6 col-lg-6">
										<label className="modal-label" htmlFor="nome">
											{t("Nome")}*
										</label>
										<Field
											autoComplete={'off'}
											type="text"
											name="nome"
											className={
												props.errors.nome && props.touched.nome
													? 'form-error-style-input-projetos'
													: 'form-default-style-input-projetos'
											}
										/>
										<ErrorMessage name="nome">
											{msg => (
												<div>
													<div className="input-error-style" role="alert">
														<FontAwesomeIcon
															icon={faExclamationTriangle}
															className="mr-1"
														/>
														{msg}
													</div>
												</div>
											)}
										</ErrorMessage>
										<label className="modal-label" htmlFor="funcao">
											{t("Função")}
										</label>
										<Field
											autoComplete={'off'}
											type="text"
											name="funcao"
											className={
												props.errors.funcao && props.touched.funcao
													? 'form-error-style-input-projetos'
													: 'form-default-style-input-projetos'
											}
										/>
										<ErrorMessage name="funcao">
											{msg => (
												<div>
													<div className="input-error-style" role="alert">
														<FontAwesomeIcon
															icon={faExclamationTriangle}
															className="mr-1"
														/>
														{msg}
													</div>
												</div>
											)}
										</ErrorMessage>
									</div>
									<div className="col-12 col-md-6 col-lg-6">
										<label className="modal-label" htmlFor="email">
											E-mail*
										</label>
										<Field
											defaultValue=""
											autoComplete={'off'}
											type="email"
											disabled={true}
											name="email"
											className={
												props.errors.email && props.touched.email
													? 'form-error-style-input-projetos'
													: 'form-default-style-input-projetos'
											}
										/>
										<ErrorMessage name="email">
											{msg => (
												<div>
													<div className="input-error-style" role="alert">
														<FontAwesomeIcon
															icon={faExclamationTriangle}
															className="mr-1"
														/>
														{msg}
													</div>
												</div>
											)}
										</ErrorMessage>
										<input
											style={{ display: 'none' }}
											id="input-submit-infoPessoa"
											type="submit"
										/>

										<label className="modal-label" htmlFor="language">
											{t("Escolha um idioma")}
										</label>
										<LanguageSelector handleAfterChange={handleAfterChangeLanguage} />
										<br />
									</div>

								</div>
							</div>
						</div>
					</fieldset>
				</FormikForm>
			)}
		/>
	);
};
