import React, { InputHTMLAttributes } from "react"
import { twMerge } from 'tailwind-merge'

interface CustomRadioOptionProps extends InputHTMLAttributes<HTMLInputElement> {}

export function CustomRadioOption({className, children, ...props}: CustomRadioOptionProps) {
    const classes = twMerge(
        `flex gap-2 items-center h-12 px-3 min-w-[140px] text-xs bg-white text-zinc-500 shadow-md 
        border border-solid border-zinc-300 rounded-lg cursor-pointer
        hover:brightness-95 fill-zinc-300 
        peer-checked:text-ivory-secondary peer-checked:border-ivory-secondary peer-checked:fill-ivory-secondary`,
        className
    )

    return (
        <label htmlFor={props.id}>
            <input type="radio" className="hidden peer" {...props}  />
            <div className={classes}>
                {children}
            </div>
        </label>
    )
}