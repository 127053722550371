import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import { customStyles } from '../../../utils/extenal-libs';

import '../../../i18n';

import './style.css';
import { useTranslation } from 'react-i18next';

const FieldFilter = ({
	label,
	getSelected,
	clearFields,
	type,
	style,
	placeholder,
	...rest
}) => {
	const {t} = useTranslation();
	const [selectedValue, setSelectedValue] = useState('');

	const handleSelected = useCallback(
		e => {
			getSelected(e, type);
			setSelectedValue(e);
		},
		[type],
	);

	useEffect(() => {
		if (clearFields) {
			setSelectedValue({ value: '', label: t('Todos') });
		}
	}, [clearFields]);

	return (
		<>
			{label && <div className="label-field-filter">{label}</div>}
			<Select
				{...rest}
				onChange={handleSelected}
				value={selectedValue}
				placeholder={placeholder || t('Todos')}
				styles={style || customStyles}
			/>
		</>
	);
};

export default FieldFilter;
