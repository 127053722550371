import { Link } from "react-router-dom";
import styled from "styled-components";
import { PAGETOPMARGEM, SCALEMULT, theme } from "../../../styles/theme";

export const MainContainerWrapper = styled.div`
    background: #f7f6f5 0% 0% no-repeat padding-box;
	border-radius: 15px;
	padding: 0 !important;
	height: calc(100vh - (${(PAGETOPMARGEM) + "vw"} + 33px));
	max-height: calc(100vh - (108px));
	overflow: hidden;
	transition: all 0.5s ease;
	margin: ${PAGETOPMARGEM}vw 32px 32px 0;

    > div {
        height: 100%;
    }

	@media (min-width: 767.99px) and (max-width: 960px) {
		margin: 75px 32px 32px 0;
    }

	@media (max-height: 600px) {
		height: calc(100vh - (${(PAGETOPMARGEM) + "vw"} + 93px));
		margin: 75px 10px 10px 10px;
    }

    @media (max-width: 575.98px) {
		height: calc(100vh - 80px);
		margin: 0 10px 10px 10px;
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
		height: calc(100vh - 78px);
		margin: 0 10px 10px 10px;
    }
`;

export const MainContent = styled.div`
    position: absolute;
	right: 32px;
	height: ${PAGETOPMARGEM}vw;
	min-height: 75px;
	border-radius: 8px;
	display: flex;
	justify-content: flex-end;
	align-items: center;

    @media (max-width: 575.98px) {
		flex-direction: column;
		align-items: start;
		gap: 16px;
		top: 65px;
		justify-content: flex-start;
		height: min-content;
		left: 16px;
    }

    @media ((min-width: 576px) and (max-width: 767.98px)) {
		top: 65px;
		justify-content: flex-start;
		height: min-content;
		left: 16px;
    }
`;

export const TodayContainer = styled.div`
	padding: 5px 15px;
	background-color: ${theme.colors.background.modalBg};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: ${SCALEMULT * 3.7}vw;
    min-height: 46px;
    min-width: 6.5rem;

    a {
        height: 100%;
        overflow: hidden
    }

`;

export const HoursButton = styled.button`
    border: solid 1px #29dff0;
	background-color: #5ad8e3;
	width: 45px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	cursor: pointer;
`;

export const CounterContent = styled.div`
    display: flex;
	gap: 10px;
	height: 100%;
	align-items: center;

    .counter-separator {
        display: flex;
        height: 90%;
        border-right: 1px solid ${theme.colors.text.inputText} ;
    }
`;

export const HorasText = styled.p`
    align-self: flex-start;
	font-size: 0.875rem;
	margin: 0;
	color: ${theme.colors.text.inputText};

    .text-horas-externo {
        font-size: 0.875rem;
        margin: 0;
		color: ${theme.colors.text.inputText};
        font-weight: 700;
    }
`;

export const TodayContent = styled.div`
    display: flex;
	gap: 8px;
	align-items: center;

    img {
        width: 25px;
        height: 25px;
    }
`;

export const WeekContent = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;

    .week-inside {
        display: flex;
        align-items: baseline;
        gap: 4px
    }
`;

export const IconContainer = styled(Link)`
	border-radius: 10px;
	background-color: ${theme.colors.button.saveBtn};
	height: ${SCALEMULT * 2.5}vw !important;
	width: ${SCALEMULT * 2.5}vw;
	min-height: 34px !important;
	min-width: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	&:hover {
        background-color: #4aceda;
    }

	svg {
		font-size: calc(18px + ${SCALEMULT * 0.3125}vw);
	}
`

export const Separator = styled.div`
	display: flex;
    height: 70%;
    border-right: 2px solid #426172;
`;