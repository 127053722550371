import React, { useState } from 'react';
import graficos from '../../assets/giffs/graficos.gif';
import ativarAjuda from '../../assets/giffs/ativarajuda.gif';
import clientesGif from '../../assets/giffs/clientes.gif';
import PlanusLogo from '../../assets/svgs/planus';
import ProjetosImg from '../../assets/images/newProjetos.png';
import KanbanImg from '../../assets/images/tour-dicas/kanban.jpg';
import PessoaService from '../../services/providers/responsible';
import { LoaderCircle } from '../../components/common/loaders/LoaderCircle';

import '../../i18n';

import Tour from 'reactour';
import './style.css';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/User';

const TurTutorial = ({ nomeRota }) => {
	const {t} = useTranslation();
	const [dicasAbrir, setDicasAbrir] = useState(true);
	const [loading, setLoading] = useState(false);
	const {  userDetails ,setUserDetails } = useUser();
	
	const _patchPessoaDicas = valor => {
		const ativarDica = new FormData();
		ativarDica.append('has_finished_tour', valor);
		setLoading(true);
		setUserDetails(prev => {
			if(prev) {
				return {
					...prev,
					has_finished_tour: valor
				}
			}
			return null;
		})
		const pessoaService = new PessoaService();
		pessoaService.patchPessoa(
			ativarDica,
			userDetails?.id_pessoa,
			() => {
				setUserDetails(prev => {
					if(prev) {
						return {
							...prev,
							has_finished_tour: valor
						}
					}
					return null;
				})
				window.location.href = window.location.pathname;
				setLoading(false);
			},
			() => {
				setLoading(false);
			},
		);
	};

	const CheckboxMostrar = () => (
		<div className="tour-checkbox-container">
			<strong style={{ color: '#426172' }} value={userDetails?.has_finished_tour}>
				{t("Não mostrar novamente")}
			</strong>
			<input
				type="checkbox"
				onChange={() => _patchPessoaDicas(true)}
				style={{ marginLeft: '12px', marginTop: '4px' }}
			/>
			{loading ? (
				<span style={{ marginLeft: '1.5em' }}>
					<LoaderCircle />{' '}
				</span>
			) : (
				<></>
			)}
		</div>
	);

	const projetoAdm = [
		{
			selector: '#react-tour__cliente',
			content: () => (
				<div>
					<span>
						{t("Para podermos criar um projeto primeiro temos que adicionar um")}{' '}
						<strong style={{ color: '#426172' }}>{t("Cliente")}.</strong>
						<br />
						{t("Clique neste botão para começar a adicionar clientes")}.
					</span>
					<img
						src={clientesGif}
						style={{
							width: '100%',
							border: '1px solid #dcd7d7',
							borderRadius: '10px',
							marginTop: '12px',
						}}
						alt=""
					/>
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '#react-tour__add-pessoa',
			content: () => (
				<div>
					<span>
						{t("Agora vamos adicionar uma pessoa na sua empresa")}!{' '} <br />
						<strong style={{ color: '#426172' }}>{t("Clique")}</strong>{' '}
						{t("neste botão para começar")}.
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '#react-tour__add-projeto',
			content: () => (
				<div>
					<span>
						{t("Depois de adicionar seus funcionários e clientes é hora de criar um")}{' '}
						<strong style={{ color: '#426172' }}>{t("Projeto")}.</strong>
						<br />
						{t("Após o projeto criado, é possível edita-lo")}.
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '.react-tour__projects-report',
			content: () => (
				<div>
					<span>
						{t("Agora que já criamos Projeto(s)")}{', '}
						<strong style={{ color: '#426172' }}>{t("Clique")}{' '}</strong> 
						{t("nesta aba para irmos para tela de")}
						<strong style={{ color: '#426172' }}> {t("Relatórios de Projeto")}.</strong>					
					</span>
					<CheckboxMostrar />
				</div>
			),
		}
	];

	const projetoMembro = [
		{
			selector: '.container-fluid',
			content: () => (
				<div>
					<span>
						{t("Esta é a tela de")} <strong style={{ color: '#426172' }}>{t("Projetos")}</strong>.
						<br />
						{t("Aqui você vizualiza os projetos da sua empresa")}. <br />
						{t("Os projetos que você não participa aparecerão em cinza")}.
					</span>
					<img
						src={ProjetosImg}
						style={{
							width: '100%',
							border: '1px solid #dcd7d7',
							borderRadius: '10px',
							marginTop: '12px',
						}}
						alt=""
					/>
					<CheckboxMostrar />
				</div>
			),
		},

		{
			selector: '.react-tour__tarefa',
			content: () => (
				<div>
					<span>
						<strong style={{ color: '#426172' }}>{t("Clique")}</strong> {' '}
						{t("nesta aba para irmos para tela de")}
						<strong style={{ color: '#426172' }}> {t("Tarefas")}</strong>.
						<br />
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
	];

	const projetoGP = [
		{
			selector: '.container-fluid',
			content: () => (
				<div>
					<span>
						{t("Esta é a tela de")} <strong style={{ color: '#426172' }}>{t("Projetos")}</strong>
						<br />
						{t("Aqui você poderá ver os projetos da empresa")}. <br />
						{t("Você poderá editar apenas os projetos que participa")}. <br />
						{t("Os projetos que você não participa aparecerão em cinza")}.
					</span>
					<img
						src={ProjetosImg}
						style={{
							width: '100%',
							border: '1px solid #dcd7d7',
							borderRadius: '10px',
							marginTop: '12px',
						}}
						alt=""
					/>
					<CheckboxMostrar />
				</div>
			),
		},

		{
			selector: '.react-tour__tarefa',
			content: () => (
				<div>
					<span>
						<strong style={{ color: '#426172' }}>{t("Clique")}</strong> {' '}
						{t("nesta aba para irmos para tela de")}
						<strong style={{ color: '#426172' }}> {t("Tarefas")}</strong>.
						<br />
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
	];

	const tarefaAdm = [
		{
			selector: '.react-tour__tarefa-add',
			content: () => (
				<div>
					<span>
						{t("Clique neste campo para adicionar")}
						<strong style={{ color: '#426172' }}> {t("Tarefas")}.</strong> <br />
					</span>
					<CheckboxMostrar />
				</div>
			),
		},

		{
			selector: '.react-tour_kanban-header',
			content: () => (
				<div>
					<span>
						{t("Agora que já temos tarefas criadas")}{' '}
						<strong style={{ color: '#426172' }}>{t("Clique")}</strong> {' '}
						{t("nesta aba para acessar o")}{' '}
						<strong style={{ color: '#426172' }}> {t("Kanban")}</strong>. <br />
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
	];

	const tarefaMembro = [
		{
			selector: '.react-tour__tarefa-add',
			content: () => (
				<div>
					<span>
						{t("Clique neste campo para adicionar")}
						<strong style={{ color: '#426172' }}> {t("Tarefas")}</strong> <br />
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '.react-tour_kanban-header',
			content: () => (
				<div>
					<span>
						{t("Agora que já temos tarefas criadas")}{', '}
						<strong style={{ color: '#426172' }}>{t("Clique")}</strong> {' '}
						{t("nesta aba para acessar o")}{' '}
						<strong style={{ color: '#426172' }}> {t("Kanban")}</strong>. <br />
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		
	];

	
	const verificarPerfilProjeto = () => {
		switch (userDetails?.acesso_cod) {
			case 'm':
				return projetoMembro;
			case 'g':
				return projetoGP;
			case 'a':
				return projetoAdm;
			case 'e':
				return projetoMembro;

			default:
				break;
		}
	};

	const homeAdmin =  [
		{
			selector: '#pagina-principal',
			content: () => (
				<div>
					<span>
						{t("Bem vindo ao")}{' '}
						<span className="tour-planus">
							<PlanusLogo />
						</span>
						<br /> {t("Vamos te ajudar nos primeiros passos:")}
						<br />
						{t("esta é a tela")}{' '}
						<strong style={{ color: '#426172' }}>{t("Página Principal")}{' '}</strong> 
						{t("que contém os gráficos de seus registros semanais")}.
					</span>
					<img
						src={graficos}
						style={{
							width: '100%',
							border: '1px solid #dcd7d7',
							borderRadius: '10px',
							marginTop: '12px',
						}}
						alt=""
					/>
					<CheckboxMostrar />
				</div>
			),
		},
		
		{
			selector: '.react-tour__hora-atalho',
			content: () => (
				<div>
					<span>
						{t("Estes são os campos de")}
						<br />{' '}
						<strong style={{ color: '#426172' }}>
							{t("Atalhos para registro de Horas")}
						</strong>{' '}
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '#react-tour__person-managment',
			content: () => (
				<div>
					<span>
						{t("Vamos começar a gerar os nossos primeiros registros")}.{' '}
						<br />
						<strong style={{ color: '#426172' }}>{t("Clique")}{' '}</strong> 
						{t("neste ícone para ser redirecionado para")} {t("a tela de")}{' '}
						<strong style={{ color: '#426172' }}>{t("Gestão de Pessoas")}</strong>.
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
	]

	const homeDefault = [
		{
			selector: '#pagina-principal',
			content: () => (
				<div>
					<span>
						{t("Bem vindo ao")}{' '}
						<span className="tour-planus">
							<PlanusLogo />
						</span>
						<br /> {t("Vamos te ajudar nos primeiros passos:")}
						<br />
						{t("esta é a tela")}{' '}
						<strong style={{ color: '#426172' }}>{t("Página Principal")}{' '}</strong> 
						{t("que contém os gráficos de seus registros semanais")}.
					</span>
					<img
						src={graficos}
						style={{
							width: '100%',
							border: '1px solid #dcd7d7',
							borderRadius: '10px',
							marginTop: '12px',
						}}
						alt=""
					/>
					<CheckboxMostrar />
				</div>
			),
		},
		
		{
			selector: '.react-tour__hora-atalho',
			content: () => (
				<div>
					<span>
						{t("Estes são os campos de")}
						<br />{' '}
						<strong style={{ color: '#426172' }}>
							{t("Atalhos para registro de Horas")}
						</strong>{' '}
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '#react-tour__registros',
			content: () => (
				<div>
					<span>
						{t("Vamos começar a gerar os nossos primeiros registros")}.{' '}
						<strong style={{ color: '#426172' }}>{t("Clique")}{' '}</strong> 
						{t("neste ícone para ser redirecionado para")} {t("a")} <br />{' '}
						<strong style={{ color: '#426172' }}>{t("Página de Projetos")}</strong>.
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
	]

	const tarefaVisitante = [
		{
			selector: '.container-fluid',
			content: () => (
				<div>
					<span>
						{t("Esta é a tela de")}{' '}
						<strong style={{ color: '#426172' }}>{t("Tarefas")}.{' '}</strong> 
						{t("Aqui você pode visulizar todas as tarefas e filtrar por projeto")}.
						
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '.react-tour_kanban-header',
			content: () => (
				<div>
					<span>
						{t("Agora que já vimos as tarefas")}{', '}
						{t("podemos ir para a tela de kanban")}.{' '}
						<strong style={{ color: '#426172' }}>{t("Clique")}</strong> {' '}
						{t("nesta aba para acessar o")}{' '}
						<strong style={{ color: '#426172' }}> {t("Kanban")}</strong>. <br />
					</span>
					<CheckboxMostrar />
				</div>
			),
		}
	]

	const reportMembro =  [
		{
			selector: '.relatorio-filtrar',
			content: () => (
				<div>
					<span>
						{t("Clique em")}{' '}
						<strong style={{ color: '#426172' }}> {t("Selecione o tipo")}</strong> <br />
						{t("para selecionar o tipo de relatório")}.

					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '.relatorio-filtrar',
			content: () => (
				<div>
					<span>
						{t("Após selecionar o relatório, você pode clicar em")}{' '}
						<strong style={{ color: '#426172' }}>
							{t("Gerar Relatório")}
						</strong>{' '}
						{t("para acessá-lo")}.
					</span>
					
					<CheckboxMostrar />
				</div>
			),
		},
		{
			selector: '#react-tour__report-print',
			content: () => (
			<div>
				<span>
					{t("Você pode exportar os relatórios gerados como")}{' '}
					<strong style={{ color: '#426172' }}>{t("CSV, Excel ou imprimir como PDF")}</strong> {' '}
				</span>
				<CheckboxMostrar />
			</div>
		),
		},
			{
				selector: '#react-tour__img-user',
				content: () => (
					<div>
						<span>
							{t("Você poderá retomar as dicas a qualquer momento, clicando no botão")}{' '}
							<strong style={{ color: '#426172' }}>
								{t("Ativar Ajuda")}
							</strong>.
						</span>
						<div>
							<img src={ativarAjuda} style={{ height: '220px' }} alt="" />
						</div>
						<CheckboxMostrar />
					</div>
				),
			},
	];

	const verifyPerfilTarefa = () => {
		switch(userDetails?.acesso_cod) {
			case 'a':
				return tarefaAdm;
			case 'm':
				return tarefaMembro;
			case 'g':
				return tarefaMembro;
			case 'e':
				return tarefaVisitante;
		}
	}

	const passos = {
		home: userDetails && userDetails.acesso_cod === 'a' ? homeAdmin : homeDefault,
		projeto: verificarPerfilProjeto(),
		tarefa: verifyPerfilTarefa(),
		hora: [
			{
				selector: '.react-tour__add-hora',
				content: () => (
					<div>
						<span>
							{t("Agora, para completar o ciclo, vamos começar a registrar horas, clicando neste botão")}{' '}
							<strong style={{ color: '#426172' }}> {t("Horas")}</strong>. <br />
						</span>
						<CheckboxMostrar />
					</div>
				),
			},
			{
				selector: '#react-tour__reports',
				content: () => (
					<div>
						<span>
							{t("Para finalizar, vamos acessar a página de")}{' '}
							<strong style={{ color: '#426172' }}>
								{t("Relatórios")}
							</strong>.
						</span>
						<CheckboxMostrar />
					</div>
				),
			},
		],
		pessoas: userDetails && userDetails?.acesso_cod === 'a' && [
			{
				selector: '.container-fluid',
				content: () => (
					<div>
						<span>
							{t("Na tela de")}{' '}
							<strong style={{ color: '#426172' }}>{t("Gestão de Pessoas")}{' '}</strong> 
							<br />
							{t("você pode gerenciar todas as informações sobre as pessoas da sua empresa")}.
						</span>
						<CheckboxMostrar />
					</div>
				),
			},
			{
				selector: '#react-tour_add-person',
				content: () => (
					<div>
						<span>
							{t("Para criar uma pessoa")}{' '}
							<br />
							<strong style={{ color: '#426172' }}>{t("Clique")}{' '}</strong> 
							{t("neste botão")} 
							<strong style={{ color: '#426172' }}> &quot;+ {t("Pessoa")}&quot;{' '}</strong> 
						</span>
						<CheckboxMostrar />
					</div>
				),
			},
			{
				selector: '#react-tour_pessoas-table',
				content: () => (
					<div>
						<span>
							{t("Para começar a editar")}{', '}
							<strong style={{ color: '#426172' }}>{t("Clique")}{' '}</strong> 
							{t("em qualquer campo na tabela")}.
							<br />
							{t("Você também pode criar campos customizáveis clicando no botão")}{' '}
							<strong style={{ color: '#426172' }}>{t("Novo Campo")}</strong>.
		
						</span>
						<CheckboxMostrar />
					</div>
				),
			},
			{
				selector: '#react-tour__registros',
				content: () => (
					<div>
						<span>
							{t("Vamos começar a gerenciar os projetos")}.{' '}
							<strong style={{ color: '#426172' }}>{t("Clique")}{' '}</strong> 
							{t("neste ícone para ser redirecionado para")} {t("a")} <br />{' '}
							<strong style={{ color: '#426172' }}>{t("Página de Projetos")}</strong>.
						</span>
						<CheckboxMostrar />
					</div>
				),
			},
		],
		kanban: [
			{
				selector: '.project-kanban__menu',
				content: () => (
					<div>
						<span>
							{t("Para carregar o")}{' '}
							<strong style={{ color: '#426172' }}>{t("kanban")}</strong>,{' '}
							{t("primeiro é preciso selecionar um projeto")}.
						</span>
						<CheckboxMostrar />
					</div>
				)
			},
			{
				selector: '#react-tour_kanban',
				content: () => (
					<div>
						<span>
							{t("Depois de carregado, o kanban aparecerá aqui")}.<br />
							<strong style={{ color: '#426172' }}>{t("Segure e arraste")}{' '}</strong> 
							{t("uma tarefa para começar a utlizá-lo")}.
							<br />
							<img
								src={KanbanImg}
								style={{
									width: '100%',
									border: '1px solid #dcd7d7',
									borderRadius: '10px',
									marginTop: '12px',
								}}
								alt=""
							/>
		
						</span>
						<CheckboxMostrar />
					</div>
				),
			},
			{
			selector: '.react-tour__hora',
			content: () => (
				<div>
					<span>
						{t("Agora que já temos tarefas criadas")}{' '}
						<strong style={{ color: '#426172' }}>{t("Clique")}</strong> {' '}
						{t("nesta aba para começar a registrar")}
						<strong style={{ color: '#426172' }}> {t("Horas")}</strong>. <br />
					</span>
					<CheckboxMostrar />
				</div>
			),
		},
		],
		projectWithReport: [
			{
				selector: '.registros-container',
				content: () => (
				<div>
					<span>
						{t("Nesta tela você poderá visualizar informações dos seus projetos")}.{' '}
					</span>
					<CheckboxMostrar />
				</div>
			),
			},
			{
				selector: '#react-tour__report-print',
				content: () => (
				<div>
					<span>
						{t("Você pode exportá-las como")}{' '}
						<strong style={{ color: '#426172' }}>{t("CSV, Excel ou imprimir como PDF")}</strong> {' '}
					</span>
					<CheckboxMostrar />
				</div>
			),
			},
			{
				selector: '.horas-filtrar',
				content: () => (
				<div>
					<span>
						{t("Os")}{' '}
						<strong style={{ color: '#426172' }}>{t("filtros")}</strong> {' '}
						{t("vão permitir que você selecione apenas o que lhe interessa")}.
					</span>
					<CheckboxMostrar />
				</div>
			),
			},
			{
				selector: '.react-tour__tarefa',
				content: () => (
					<div>
						<span>
							<strong style={{ color: '#426172' }}>{t("Clique")}</strong> {' '}
							{t("nesta aba para irmos para tela de")}
							<strong style={{ color: '#426172' }}> {t("Tarefas")}</strong>.
							<br />
						</span>
						<CheckboxMostrar />
					</div>
				),
			},
		],
		report: reportMembro
	};

	return (
		<>
			{!userDetails?.has_finished_tour && (
				<Tour
					disableFocusLock={true} 
					onRequestClose={() => setDicasAbrir(false)}
					steps={passos[nomeRota]}
					isOpen={dicasAbrir}
					rounded={10}
				/>
			)}
		</>
	);
};

export default TurTutorial;
