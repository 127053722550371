import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ProjetoService from '../../services/providers/project';
import { useAtom } from 'jotai';
import { columnsManagerAtom } from '../../atoms/Kanban/KanbanProject';
import { useUser } from '../User';
import { t } from "i18next";
import { ProjectDetail, ProjectPage } from '../../models/Project';
import { SelectOptions } from '../../models/Select';
import { sortAlphabetically } from '../../utils/funcoes/sort.ts';
import { ProjectContextInitialType } from './type';


let controller: AbortController | null = null; 

export const initialValues: ProjectContextInitialType = {
	clienteValorSelecionado: { value: '', label: t('Clientes') },
	activeOnly: true,
	myProjects: false,
	setClienteValorSelecionado: () => {},
	setActiveOnly: () => {},
	setMyProjects: () => {},
	loadingPostPatch: false,
	loadingProjects: true,
	setSearchProject: () => {},
	setTotalPages: () => {},
	setPages: () => {},
	setProjects: () => {},
	setProjectsDetails: () => {},
	setLoadingProjects: () => {},
	setModalProjectVisible: () => {},
	setLoadingPostPatch: () => {},
	setSelectedProjectLink: () => {},
	modalProjectVisible: false,
	pages: 1,
	projectsDetails: [],
	projects: [],
	totalPages: null,
	searchProject: '',
	selectedProjectLink: '/registros/projetos',
	getProjects: () => {},
	closeProject: () => {},
	deleteProject: () => {},
	handlePostPatchProjeto: () => {},
	selectProjectGrid: () => {},
	selectReport: () => {},
	selectProjectList: () => {},
	reactivateProject: () => {},
	selectProjectKanban: () => {},
};
export const ProjectContext = createContext(initialValues);
const ProjectProvider = ({ children }: {children: ReactNode}) => {
	const [loadingProjects, setLoadingProjects] = useState<boolean>(initialValues.loadingProjects);
	const [projects, setProjects] = useState<ProjectPage[]>([]);
	const [projectsDetails, setProjectsDetails] = useState<ProjectDetail[]>([]);
	const [searchProject, setSearchProject] = useState<string>('');
	const [totalPages, setTotalPages] = useState<number | null>(null);
	const [pages, setPages] = useState<number>(1);
	const [loadingPostPatch, setLoadingPostPatch] = useState<boolean>(false);
	const [modalProjectVisible, setModalProjectVisible] = useState<boolean>(false);
	const [myProjects, setMyProjects] = useState<boolean>(initialValues.myProjects);
	const [activeOnly, setActiveOnly] = useState<boolean>(initialValues.activeOnly);
	const [clienteValorSelecionado, setClienteValorSelecionado] = useState<SelectOptions>(initialValues.clienteValorSelecionado);
	const [selectedProjectLink, setSelectedProjectLink] = useState('/registros/projetos');
	const [manager, setManager] = useAtom(columnsManagerAtom)
	const { userDetails } = useUser();

	const selectProjectGrid = () => setSelectedProjectLink('/registros/projetos');
	const selectReport = () => setSelectedProjectLink('/relatorios_projeto');
	const selectProjectList = () => setSelectedProjectLink('/registros/projetos-list');
	const selectProjectKanban = () => setSelectedProjectLink('/registros/projetos-kanban');


	const getProjects = (page = 1, loading = true) => {
		if (page === 1) {
			setTotalPages(null);
		}
		setLoadingProjects(loading);

		
		if (controller !== null) {
			controller.abort();
		}

		controller = new AbortController();

		const projetoService = new ProjetoService();
		let filters = myProjects || userDetails?.acesso_cod !== 'a' ? `time__in=${userDetails?.id_pessoa}&` : '';
		filters = !activeOnly && userDetails?.acesso_cod === 'a' ? filters.concat(`all=true&`) : filters;
		filters = filters.concat(
			clienteValorSelecionado
				? `cliente=${clienteValorSelecionado.value}`
				: '',
		);

		projetoService.getProjetosPaginacaoFiltered(
			page,
			searchProject,
			filters,
			response => {
				if (response) {
					if (page === 1) {
						setProjects(response.results);
						setPages(page);
						setTotalPages(response.total_pages);
						setLoadingPostPatch(false);
					} else {
						setProjects(prevData => ([ ...prevData, ...response.results ]));
						setPages(page);
						setTotalPages(response.total_pages);
						setLoadingPostPatch(false);
					}
				}
				setLoadingProjects(false);
			},
			() => {
				setProjects([]);
				setLoadingProjects(false);
			},
			controller.signal
		);
	};

	const closeProject = (id: number) => {
		const projectService = new ProjetoService();
		projectService.encerrarProjeto(
			id,
			response => {
				if (response) {
					handleRemoveProject(id, 'Projeto encerrado com sucesso!')
				}
			},
			error => toast.error(error),
		);
	};

	const handleRemoveProject = (id: number , message: string) => {
		setModalProjectVisible(false);
		toast.success(t(message));
		setProjects(prev => prev.filter(item => item.id !== id));
	}; 
	const reactivateProject = (id: number) => {
		const projectService = new ProjetoService();
		projectService.reativarProjeto(
			id,
			response => {
				if (response) {
					setModalProjectVisible(false);
					toast.success(t('Projeto reativado com sucesso!'));
					setProjects(prev => {
						const newProjects: ProjectPage[] = [...prev, {...response.data, cliente: {...response.data.cliente, logo_cliente: null}, coluna_kanban: '', e_time_okr: false, ordenacao: 1, tarefas: response.data.tarefas}];
						return sortAlphabetically<ProjectPage>(newProjects, 'nome');
					});
				}
			},
			error => toast.error(error),
		);
	};

	const removeProjetoFromKanban = (id: number) => {
		const columns = manager.columnsOrder.map(columnId => {
			const tempColumn = {...manager.columns[columnId]}
			tempColumn.items = tempColumn.items.filter(item => item.id !== id)

			if(tempColumn.items.length < manager.columns[columnId].items.length){
				return {
					column: tempColumn,
					id: columnId
				}
			} else {
				return null
			}
		})

		const tempColumns = columns.filter(item => item !== null)

		if(tempColumns.length>0) {
			setManager(prev => {
				const tempManager = {...prev}
				if(tempColumns[0]) {
					tempManager.columns[tempColumns[0].id].items = tempColumns[0].column.items
				}

				return tempManager
			})
		}
	}

	const deleteProject = (id: number) => {
		const projetoService = new ProjetoService();
		
		projetoService.deleteProjeto(
			id,
			response => {
				if (response) {
					handleRemoveProject(id, 'Projeto removido com sucesso!');
					removeProjetoFromKanban(id);
				}
			},
			error => {
				toast.error(error);
			},
		);
	};

	

	const handlePostPatchProjeto = (saveAndContinue: boolean) => {
			setModalProjectVisible(saveAndContinue);
	};

	const projectValue = useMemo(() => ({
				clienteValorSelecionado,
				setClienteValorSelecionado,
				myProjects,
				setMyProjects,
				loadingProjects,
				projects,
				getProjects,
				searchProject,
				setSearchProject,
				totalPages,
				setTotalPages,
				pages,
				setPages,
				loadingPostPatch,
				setLoadingPostPatch,
				modalProjectVisible,
				setModalProjectVisible,
				closeProject,
				deleteProject,
				handlePostPatchProjeto,
				setProjects,
				setActiveOnly,
				activeOnly,
				reactivateProject,
				selectedProjectLink,
				setSelectedProjectLink,
				selectProjectGrid,
				selectProjectKanban,
				selectProjectList,
				selectReport,
				projectsDetails, 
				setProjectsDetails,
				setLoadingProjects
	}), [
		clienteValorSelecionado,
		setClienteValorSelecionado,
		myProjects,
		setMyProjects,
		loadingProjects,
		projects,
		getProjects,
		searchProject,
		setSearchProject,
		totalPages,
		setTotalPages,
		pages,
		setPages,
		loadingPostPatch,
		setLoadingPostPatch,
		modalProjectVisible,
		setModalProjectVisible,
		closeProject,
		deleteProject,
		handlePostPatchProjeto,
		setProjects,
		setActiveOnly,
		activeOnly,
		reactivateProject,
		selectedProjectLink,
		setSelectedProjectLink,
		selectProjectGrid,
		selectProjectKanban,
		selectProjectList,		
		selectReport,
		projectsDetails, 
		setProjectsDetails,
		setLoadingProjects
	])

	return (
		<ProjectContext.Provider
			value={projectValue}
		>
			{children}
		</ProjectContext.Provider>
	);
};

function withProject (Child: any){
	return function ProjectComponent (props: any) {
		return (
			<ProjectContext.Consumer>
				{context => <Child {...props} {...context} />}
			</ProjectContext.Consumer>
		);
	}
} 

const useProject = () => {
	const context = useContext(ProjectContext);
	const {
		clienteValorSelecionado,
		setClienteValorSelecionado,
		myProjects,
		setMyProjects,
		loadingProjects,
		projects,
		getProjects,
		searchProject,
		setSearchProject,
		totalPages,
		setTotalPages,
		pages,
		setPages,
		loadingPostPatch,
		setLoadingPostPatch,
		modalProjectVisible,
		setModalProjectVisible,
		closeProject,
		deleteProject,
		handlePostPatchProjeto,
		setProjects,
		setActiveOnly,
		activeOnly,
		reactivateProject,
		selectedProjectLink,
		setSelectedProjectLink,
		selectProjectGrid,
		selectProjectKanban,
		selectProjectList,		
		selectReport,
		projectsDetails, 
		setProjectsDetails
	} = context;

	return {
		clienteValorSelecionado,
		setClienteValorSelecionado,
		myProjects,
		setMyProjects,
		loadingProjects,
		projects,
		getProjects,
		searchProject,
		setSearchProject,
		totalPages,
		setTotalPages,
		pages,
		setPages,
		loadingPostPatch,
		setLoadingPostPatch,
		modalProjectVisible,
		setModalProjectVisible,
		closeProject,
		deleteProject,
		handlePostPatchProjeto,
		setProjects,
		setActiveOnly,
		activeOnly,
		reactivateProject,
		selectedProjectLink,
		setSelectedProjectLink,
		selectProjectGrid,
		selectProjectKanban,
		selectProjectList,		
		selectReport,
		projectsDetails, 
		setProjectsDetails
	};
};

export { ProjectProvider, withProject, useProject };
