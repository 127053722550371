import React, { ReactNode } from "react";
import { ButtonContainer, ConfirmModalSection, ModalContent, ModalText, ModalTitle } from "./styled";
import { useTranslation } from "react-i18next";
import { LoaderGenerico } from "../modalStyled";

export interface GenericConfirmModalProps {
    title?: string;
    text?: ReactNode;
    loading?: boolean;
    buttons?: ButtonProps[];
}

export interface ButtonProps  {
    title: string;
    onClick:  () => void;
}


export function GenericConfirmModal({
    text = "",
    title = "Confirmar Exclusão?",
    loading,
    buttons
}: GenericConfirmModalProps) {
    const { t } = useTranslation();

    return (
        <ConfirmModalSection
        id="swipe-panel"
    >
        <div className="confirm-modal-align">
            <ModalContent>
                <div className='confirm-modal'>
                    <ModalTitle>{t(title)}</ModalTitle>
                    <ModalText>{text}</ModalText>
                    <ButtonContainer>
                        {buttons?.map((button) => {
                            return (
                                <button 
                                key={button.title}
                                className='confirm-modal-button cancel-button' 
                                type='button'
                                onClick={button.onClick}
                            >
                                {loading ? <LoaderGenerico /> : t(button.title)}
                            </button>
                            )
                        })}
                    </ButtonContainer>
                </div>
            </ModalContent>
        </div>
    </ConfirmModalSection>
    )
}