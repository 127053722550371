import React, { useState, useEffect } from 'react';

import AuthService from '../../../services/security/auth';
import Login from '../index';
import { CriarSenhaSchema } from './validation.js';
import BannerLogin from '../components/BannerLogin/index.js';
import ButtonForm from '../components/ButtonForm/index.js';
import FormInput from '../components/FormInput/index.js';

import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import './style.css';

import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';

export const PrimeiroAcesso = ({ match }) => {
	const {t} = useTranslation();
	const query_param = match ? match.params.invite : null;
	const { setUserDetails} = useUser();
	useEffect(() => {
		localStorage.setItem('primeiro_acesso', query_param);

		localStorage.removeItem('planustk');
		localStorage.removeItem('ac_cod');
		localStorage.removeItem('ac_text');
		localStorage.removeItem('userProfile');
		setUserDetails(null);
	});

	const initialValues = { password: '' };

	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const service = new AuthService();

	const doInvite = (values, query_invite) => {
		setLoading(true);
		const form = new FormData();
		for (var key in values) {
			form.append(key, values[key]);
		}
		service.invite(
			form,
			query_invite,
			response => {
				if (response) {
					window.location.href = '/';
				}
			},
			error => {
				toast.error(error.toString());
				setLoading(false);
			},
		);
	};

	return (
		<Login>
			<div className="login-limiter">
				<Formik
					initialValues={initialValues}
					validationSchema={CriarSenhaSchema}
					enableReinitialize={false}
					onSubmit={(values, actions) => {
						actions.setSubmitting(true);
						doInvite(values, query_param);
					}}
				>
					{({ errors, touched }) => (
						<Form className="login-form-container">
							<BannerLogin
								titulo={t("Crie uma senha")}
								subTitulo={t("Crie uma senha para acessar ao planus.")}
							/>
							<FormInput
								id="input-password"
								nome="Password"
								campo="password"
								errors={errors.password}
								touched={touched.password}
								showPassword={showPassword}
								setShowPassword={setShowPassword}
								type={showPassword ? 'text' : 'password'}
							/>
							<ButtonForm loading={loading} />
						</Form>
					)}
				</Formik>
			</div>
		</Login>
	);
};
