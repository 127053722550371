import React from "react";
import { SelectOptions } from "../../../../../models/Select";

interface SelectCellProps {
    value: string;
    options: SelectOptions[];
    onSave: (value: string) => void; 
}

export default function SelectCell({onSave, options, value}: Readonly<SelectCellProps>) {
    return <td>
        <select className="text-xs border-none pl-0" onChange={(e) => onSave(e.target.value)}>
            {options.map(option => <option className="p-2" selected={value === option.value} value={option.value} key={option.value} >{option.label}</option>)}
        </select>
    </td>
}