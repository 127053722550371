import React, { useState, useEffect, Dispatch } from 'react';

import PessoaService from '../../../services/providers/responsible';
import pessoaImage from '../../../assets/images/profile.png';

import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUserCog } from '@fortawesome/free-solid-svg-icons';

import '../../../i18n';

import './style.css';
import { useTranslation } from 'react-i18next';
import { Responsible } from '../../../models/Responsible';
import { SetStateAction } from 'jotai';
import {  ProjectBasic } from '../../../models/Project';
import { TeamSelectorContainer, TeamSelectorHeader, TeamSelectorHeaderTitle, TeamSelectorBody, PersonContainer, PersonImage, PersonName, IconContainer, TeamSelectorHeaderBody, FormModal, SearchIconContainer, ModalInput, Loader, EmptyList} from './styled';

export interface SeletorTimeProps {
	projetoTime?: Responsible[];
	isEditing: boolean;
	editandoTime?: ProjectBasic;
	adicionaPessoaTime: (pessoa: Responsible, type: string) => void;
	removePessoaTime: (pessoa: Responsible) => void;
	isFormSide?: boolean;
	setAdmins: Dispatch<SetStateAction<number[]>>
}

export default function SeletorTime({adicionaPessoaTime, isEditing, removePessoaTime, setAdmins, editandoTime, isFormSide, projetoTime}: SeletorTimeProps) {
	const {t} = useTranslation();
	const [time, setTime] = useState( projetoTime ?? []);
	const [dadosPesquisaProjeto, setDadosPesquisaProjeto] = useState('');
	const [pessoas, setPessoas] = useState<Responsible[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		__getPessoasSelect();
	}, []);



	useEffect(() => {
		if(isEditing) {
			setTime(editandoTime?.time ? editandoTime.time : [] )
		}
	}, [editandoTime, isEditing, projetoTime])


	const __getPessoasSelect = () => {
		setPessoas([]);
		setLoading(true);
		const pessoaService = new PessoaService();

		pessoaService.getPessoasSelect(
			[],
			[],
			[],
			response => {
				if (response.data) {
					filtroTimeExistente(response.data);
				}
				setLoading(false);
			},
			() => {
				setPessoas([]);
				setLoading(false);
			},
		);
	};

	const adicionarPessoaTime = (pessoaAdicionada: Responsible, index: number) => {
		time.push(pessoaAdicionada);
		pessoas.splice(index, 1);
		setTime([...time]);
		setPessoas([...pessoas]);
		adicionaPessoaTime(pessoaAdicionada, 'obj');
	};

	const removerPessoaTime = (pessoaRemovida: Responsible) => {
		pessoas.push(pessoaRemovida);
		const index = time.findIndex(pessoa => pessoa.id === pessoaRemovida.id);
		time.splice(index, 1);
		setTime([...time]);
		setPessoas([...pessoas]);
		removePessoaTime(pessoaRemovida);
	};

	const filtroTimeExistente = (pessoasRecebidas: Responsible[]) => {
		const pessoasFiltradas = pessoasRecebidas;
		time.forEach(pessoaNoTime => {
			const index = pessoasRecebidas.findIndex(
				pessoaRecebida => pessoaRecebida.id === pessoaNoTime.id,
			);
			if (index !== -1) {
				pessoasFiltradas.splice(index, 1);
			}
		});

		setPessoas(pessoasFiltradas);
	};

	const loadedContent = pessoas.length !== 0 ? (
		pessoas.map((pessoa, index) => (
			<PersonContainer
				key={pessoa.id}
				title={pessoa.nome}
				onClick={() => {
					adicionarPessoaTime(pessoa, index);
				}}
				style={{
					display: pessoa.nome
						.toLowerCase()
						.startsWith(dadosPesquisaProjeto.toLowerCase())
						? 'flex'
						: 'none',
				}}
			>
				<PersonImage
					src={
						pessoa.foto_perfil && pessoa.foto_perfil !== null
							? pessoa.foto_perfil
							: pessoaImage
					}
					alt="erro"
				/>
				<PersonName>{pessoa.nome}</PersonName>
				<IconContainer>
					<FontAwesomeIcon
						transform={{ rotate: 45 }}
						icon={faTimesCircle}
						className="seletor-time-pessoa-icon-add "
					/>
				</IconContainer>
			</PersonContainer>
		))
	) : (
		<EmptyList>{t("Lista vazia")}.</EmptyList>
		// 'lista vazia'
	)

	return (
		<TeamSelectorContainer clickDisabled={projetoTime === undefined && isEditing}>
			<TeamSelectorHeader>
				<TeamSelectorHeaderTitle>{t("Time")}</TeamSelectorHeaderTitle>
			</TeamSelectorHeader>
			<TeamSelectorBody isFormSide={isFormSide}>
				{time
					? time.map(pessoa => (
						<PersonContainer key={pessoa.id}>
							<PersonImage
								onClick={() => {
									removerPessoaTime(pessoa);
								}}
								src={
									pessoa.foto_perfil && pessoa.foto_perfil !== null
										? pessoa.foto_perfil
										: pessoaImage
								}
								alt="erro"
							/>
							<PersonName
								onClick={() => {
									removerPessoaTime(pessoa);
								}}
							>
								{pessoa.nome}
							</PersonName>
							<IconContainer>
								<AdminDoProjeto
									pessoa={pessoa.id}
									setAdmins={setAdmins}
									admins={editandoTime?.admins ?? []}
								/>
								<FontAwesomeIcon
									onClick={() => {
										removerPessoaTime(pessoa);
									}}
									icon={faTimesCircle}
									color='#868f95d9'
									fontSize={20}
								/>
							</IconContainer>
						</PersonContainer>
					))
				: null}
			</TeamSelectorBody>
			<TeamSelectorHeaderBody isFormSide={isFormSide}>
				<TeamSelectorHeaderTitle>{t("Selecionar Pessoas")}</TeamSelectorHeaderTitle>
				<FormModal>
					<SearchIconContainer title="Pesquisar">
						<FontAwesomeIcon
							icon={faSearch}
							className="search-modal-icon-projeto"
						/>
					</SearchIconContainer>
					<ModalInput
						title={t("Pressione 'ENTER' ou clique no ícone de 'lupa' para pesquisar")}
						autoComplete={'off'}
						value={dadosPesquisaProjeto}
						type="text"
						name="search"
						placeholder="Pesquisar"
						onChange={e => {
							setDadosPesquisaProjeto(e.target.value);
						}}
					/>
				</FormModal>
			</TeamSelectorHeaderBody>
			<TeamSelectorBody
				isLoading={loading || pessoas.length === 0}
				
			>
				{loading && pessoas.length === 0 ? (
					<Loader />
				) : loadedContent}
			</TeamSelectorBody>
		</TeamSelectorContainer>
	);
}

interface AdminDoProjetoProps {
	pessoa: number;
	setAdmins: Dispatch<SetStateAction<number[]>>;
	admins: Responsible[];
}


const AdminDoProjeto = ({ pessoa, setAdmins, admins }: AdminDoProjetoProps) => {

	const [admin, setAdmin] = useState(false);
	useEffect(() => {
		if (admin) {
			setAdmins(prevAdmin => [...prevAdmin, pessoa]);
		} else {
			setAdmins(prevAdmin => prevAdmin.filter(item => item !== pessoa));
		}
	}, [admin]);

	useEffect(() => {
		if (admins) {
			verificaSeAdmin();
		}
	}, [admins]);

	const verificaSeAdmin = () => {
		const adminsID = admins.map(item => item.id);
		if (adminsID.includes(pessoa)) {
			setAdmin(true);
		}
	};
	return (
		<>
			<div onClick={() => setAdmin(!admin)}>
				<FontAwesomeIcon
					style={{ color: admin ? '#8599a4' : '#e6e6e6' }}
					title={
						admin
							? 'Remover como administrador do projeto'
							: 'Adicionar como administrador do projeto'
					}
					icon={faUserCog}
					className="seletor-time-pessoa-icon-admin"
				/>
			</div>
		</>
	);
};
