export function sortByRecentDate(array: any[], param: string) {
    return array.sort((a, b) => +new Date(b[param]) - +new Date(a[param]));
}

export function sortAlphabetically<T>(array: T[], param: string): T[] {
    return array?.toSorted((a: any, b: any) => { 
        const nameA = a[param]?.toLowerCase(),
        nameB = b[param]?.toLowerCase();
        const secondCheck = nameA > nameB ? 1 : 0;
        return nameA < nameB ? -1 : secondCheck;
    });
}