import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Field } from 'formik';

import './style.css';

const FormInput = ({
	nome,
	campo,
	errors,
	touched,
	showPassword,
	setShowPassword,
	...props
}) => {
	return (
		<div className="wrap-input100 validate-input">
			{(campo === 'password' || campo === 'password_confirm') && (
				<span className="btn-show-pass">
					<FontAwesomeIcon
						icon={faEye}
						style={{ color: showPassword ? '#426172' : '#C0CFD8' }}
						onClick={() => setShowPassword(!showPassword)}
					/>
				</span>
			)}
			<Field
				className={errors && touched ? 'input100 ' : 'input100 has-val'}
				type={campo}
				name={campo}
				{...props}
			/>
			<span className="focus-input100" data-placeholder={nome}></span>
			{errors && touched ? (
				<span className="validacao-mensagem">{errors}</span>
			) : null}
		</div>
	);
};

export default FormInput;
