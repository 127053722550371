import { t } from 'i18next';
import * as Yup from 'yup';

export const CicloSchema = Yup.object().shape({
	nome: Yup.string().required(t('Campo obrigatório.')),
	data_inicial: Yup.string().required(t('Campo obrigatório.')),
	data_final: Yup.string()
		.required('Campo obrigatório.')
		.test(
			'data-final-maior',
			t('A data final deve ser maior que a data inicial.'),
			function(value) {
				const { data_inicial } = this.parent;
				if (data_inicial && value) {
					const dataInicialObj = new Date(data_inicial);
					const dataFinalObj = new Date(value);

					if (dataFinalObj <= dataInicialObj) {
						return false;
					}
				}
				return true;
			},
		),
});
