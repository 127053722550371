import React, { Dispatch, SetStateAction } from 'react';
import { ProjectBasic, ProjectPage } from '../../../../../models/Project';
import { useProject } from '../../../../../contexts/Project';
import KanbanItem from '../../../../../components/containers/Kanban/KanbanItem';

import clientImage from '../../../../../assets/images/company.png';
import TeamImages from '../../../../../components/planus2/TeamImages';
import { useHistory } from 'react-router-dom';

interface ProjectKanbanItemProps {
	readonly project: ProjectPage;
	readonly index: number;
	readonly setEditingProject: Dispatch<SetStateAction<boolean>>;
	readonly setSelectedProject: Dispatch<
		SetStateAction<ProjectBasic | undefined>
	>;
}

export default function ProjectKanbanItem({
	project,
	index,
	setEditingProject,
	setSelectedProject,
}: Readonly<ProjectKanbanItemProps>) {
	const { setModalProjectVisible } = useProject();
	const history = useHistory();

	const handleEditProject = () => {
		if (project.time) {
			setSelectedProject({ ...project, time: project.time ?? [] });
			setEditingProject(true);
			setModalProjectVisible(true);
		}
	};
	return (
		<KanbanItem
			id={project.id?.toString()}
			requestingAdd={{ componentId: null }}
			index={index}
		>
			<div className="bg-kanban-bgCard flex flex-col p-3 flex-1">
				<div className="flex flex-row gap-3 border-b border-solid border-ivory-primary-lighter pb-3 flex-1">
					<img
						className="w-10 h-10 rounded-full min-h-min"
						src={project.cliente?.logo_cliente ?? clientImage}
						alt='Logo do cliente'
					></img>
					<div className="flex flex-col gap-2 ">
						<div className="flex flex-col">
							<button
								className={`text-black font-semibold text-base text-start ${
									project.time
										? 'hover:underline cursor-pointer'
										: 'cursor-progress'
								}`}
								onClick={() => {
										history.push(`/registros/projetos/${project.id}`, { data: project });
								}}
							>
								{project.nome}
							</button>
							<p className="text-black text-xs">{project.cliente?.nome}</p>
						</div>
					</div>
				</div>
				<div className="flex mt-3">
					<TeamImages
						className="justify-between w-full"
						onAdd={handleEditProject}
						team={project.time}
						imageSize="h-6 w-6"
						max={10}
					/>
				</div>
			</div>
		</KanbanItem>
	);
}
