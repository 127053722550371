import styled from "styled-components";


export const RecordHistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    max-height: 402px;

    background: #EBEBEB;
    border-radius: 8px;
    p {
        margin: 0;
    }
`;

export const RecordTitle = styled.div`
    color: black;
    font-weight: bold;
    font-size: 20px;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    margin: 12px 0;
`;

export const OpenCard =  styled.div`
    position: absolute;
    background: #EBEBEB;
    width: 35px;
    height: 100px;
    right: -35px;
    top: 32px;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const RecordHistoryContent = styled.div`
    padding: 20px 20px 20px 40px;
`

export const IconButton = styled.button`
    color: transparent;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: ${({disabled}) => disabled ? 'default' : 'initial'};

`

export const DateTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 6px 12px;
    margin: 4px 0;
    font-weight: 700;

    background: white;
`;

export const RecordItem = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    justify-content: space-between;
    gap: 16px;
    padding: 6px 12px;
    line-height: 20px;
    font-weight: 500;

    .task-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical; 
    }

    .history-client {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #1E9579;
        font-size: 0.75rem;
        font-weight: 700;

        div {
            background: #31DBAA;
            border-radius: 100%;
            min-width: 4px;
            height: 4px;
            margin-top: 2px;
        }
    }
`;


export const RecordDateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    .history-callback-play {
        display: flex;
        margin-top: 1px;
    }

    p {
        width: 40px;
    }
`;