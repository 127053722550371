import React, { useEffect } from 'react';
import HeaderLink from '../../../components/common/HeaderLink';
import {
	faRotate,
} from '@fortawesome/free-solid-svg-icons';
import { HeaderButton } from '../../../components/common/HeaderLink/types';
import { PageInsideContainer } from '../../../components/containers/PageInsideContainer';
import { ModalCiclo } from '../ModalCiclo';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';
import { CiclosTable } from './CiclosTable';
import { useAtom, useSetAtom } from 'jotai';
import { cicloModalAtom, ciclosOkrsAtom, selectedHeaderOkrsAtom } from '../../../atoms/Okrs';
import CicloService from '../../../services/providers/okrs/ciclo';
import { useHistory } from 'react-router-dom';

const OKRs = () => {
	const { t } = useTranslation();
	const navigate = useHistory()
	const { userDetails } = useUser();
	const [isCicloModalVisible, setIsCicloModalVisible] = useAtom(cicloModalAtom)

  const setSelectedHeader = useSetAtom(selectedHeaderOkrsAtom);
    
	const [ciclosOkrs, setCiclosOkrs] = useAtom(ciclosOkrsAtom)

    const cicloService = new CicloService()

	const getCiclosPagination = async (page: number) => {
        if(page > (ciclosOkrs.totalPages ?? 1)) {
            return
        }

        await cicloService.getCiclos({
            page
        }).then((result) => {
            if(page === 1) {
				setCiclosOkrs(() => {
					return {
						currentPage: page,
						totalPages: result.total_pages,
						ciclosOkrs: [...result.results]
					}})
			} else {
				setCiclosOkrs(prev => {
					const newResultsIds = result.results.map(item => item.id)
					const prevResults = prev.ciclosOkrs?.filter(item => !newResultsIds.includes(item.id)) ?? []
	
					return {
						currentPage: page,
						totalPages: result.total_pages,
						ciclosOkrs: [...prevResults, ...result.results]
					}})
			}
		})
    }

	const cicloModal = () => {
		setIsCicloModalVisible({
			editing: false,
			selectedItem: undefined,
			isModalOpen: true
		});
	};

	const closeCicloModal = () => {
		setIsCicloModalVisible({
			editing: false,
			selectedItem: undefined,
			isModalOpen: false
		})
	};

	const verificarPerfilCiclo = () => {
		return userDetails?.acesso_cod === 'a';
	};

	const headerButtons: HeaderButton[] = [
		{
			handleClick: cicloModal,
			icon: faRotate,
			isHidden: !verificarPerfilCiclo(),
			text: t('Ciclo'),
		},
	];

	useEffect(() => {
		if(userDetails?.acesso_cod !== 'a') {
			navigate.push('/okrs/times')
		}
	}, [])

  useEffect(() => {
		setSelectedHeader("ciclos");
	}, []);

	return (
		<>
			<HeaderLink type="okrs" headerButtons={headerButtons}></HeaderLink>

			<PageInsideContainer>
				<CiclosTable getCiclosPagination={getCiclosPagination} />
			</PageInsideContainer>
			
			{isCicloModalVisible.isModalOpen && (
				<ModalCiclo 
					setModalShow={closeCicloModal} 
					getCiclosPagination={getCiclosPagination}
					ciclo={isCicloModalVisible.selectedItem}	
					editingCiclo={isCicloModalVisible.editing}
				/>
			)}
		</>
	);
};

export default OKRs;
