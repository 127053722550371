import { components } from "react-select";
import styled from "styled-components";

export const SelectedWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 8px;
`;

export const ProjectSingleValueStyled = styled(components.SingleValue)`
	display: flex;
	align-items: center;
	gap: 16px;
	font-weight: 400;
	width: 100%;

	img {
		width: 100%;
		max-width: 20px;
		max-height: 20px;
		object-fit: contain;
	}
`;