import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faTable } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import './style.css';
import './styleCusto.css';
import { useTranslation } from 'react-i18next';

export default function Custo({
	horaOcioData,
	loadingGetHoras,
	tipoDeRelarorio,
	loadingRelatorioCSV,
	getRelatorioCSV,
	getRelatorioXLSX,
	dataInicial,
	dataFinal,
	custoData,
	handlePrint
}) {
	const {t} = useTranslation();
	const formatter = new Intl.NumberFormat('pt-br', {
		style: 'currency',
		currency: 'BRL',
	});
	const relatorioTitle = ""
	const [dataInicialFinal, setDataInicialFinal] = useState('');
	useEffect(() => {
		setDataInicialFinal(
			`${moment(dataInicial).format('L')} - ${moment(dataFinal).format('L')}`,
		);
	}, [custoData]);


	const subtractValues = (valor1, valor2) => {
		let total = valor1 - valor2;
		return total;
	};

	const totalSumProject = values => {
		let totalValuesSaldo = 0;
		let arrayValues = 0;
		values.forEach(item => {
			arrayValues += subtractValues(
				item.custo_total_negociado,
				item.custo_total_pessoa,
				false,
			);
		});
		totalValuesSaldo += arrayValues;
		return arrayValues;
	};

	const finalTotalSum = (value, value_ocio=[], fieldMapName) => {
		let person = [];
		value.forEach(item =>
			item[fieldMapName].length > 0
				? person.push(...item[fieldMapName])
				: [],
		);
		if (person) {
			let final = sumReduce(person);
			// Tempo ocioso
			final += value_ocio.reduce( (acc, curr) => acc + parseFloat(curr.custo_total_negociado) - parseFloat(curr.custo_total_pessoa), 0.0 );
			return(final);
		}
	};

	const sumReduce = person => {
		let x_sum = person.reduce(
			(acc, curr) =>
				acc +
				subtractValues(
					curr.custo_total_negociado,
					curr.custo_total_pessoa,
					false,
				),
			0,
		);
		return x_sum;
	};

	const handlePrintData = () => {
		const isPersonReport = tipoDeRelarorio === 'pessoa'
		const fieldMapName = isPersonReport ? "custos_pessoa_projeto" : "pessoas_projeto" 

		const dataInfo = custoData.map(custo => {
			return custo?.[fieldMapName].map((item) => {
				const tempRow = {
				}
	
				if(isPersonReport) {
					tempRow[t("Usuário")] = custo.nome
					tempRow[t("Projetos")] = item.projeto.nome
				} else {
					tempRow[t("Projetos")] = custo.nome
					tempRow[t("Usuário")] = item.pessoa.nome
				}
	
				return tempRow
			})
		}).flat()

		let dataValues = custoData.map(custo => {
			return custo?.[fieldMapName].map((item) => {
				const tempRow = {
					[t("Horas Trabalhadas")]: item.horas_trabalhadas,
					[t("Custo por Hora")]: formatter.format(item.valor_pessoaporhora),
					[t("Custo Total")]: formatter.format(item.custo_total_pessoa),
					[t("Valor Hora Negociado")]: formatter.format(item.valor_pessoanegociado),
					[t("Valor Total Negociado")]: formatter.format(item.custo_total_negociado),
					[t("Saldo")]: formatter.format(
						subtractValues(
							item.custo_total_negociado,
							item.custo_total_pessoa,
						)),
					[`${isPersonReport ? t("Saldo Pessoa") : t("Saldo Projeto")}`]: formatter.format(
						totalSumProject(custo[fieldMapName]),
					),
				}
	
				return tempRow
			})
		}).flat()

		const periodoString = dataInicialFinal
		
		handlePrint({
			dataInfo, 
			dataValues, 
			periodoString, 
			title: relatorioTitle
		})
	}


	return (
		<div className='print-background print-background-test' >
			<div className="relatorio-header-container">
				<p className="relatorio-title">{t("Planilha de Custos")}</p>

				<div style={{ display: 'flex' }}>
					<div
						onClick={() => getRelatorioCSV()}
						className="relatorio-print-container"
						disabled={true}
						style={{
							pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
							opacity: loadingRelatorioCSV ? '0.5' : '1',
						}}
					>
						<FontAwesomeIcon icon={faTable} className="print-icon-relatorio" />
						<p className="print-text-relatorio">{t("Gerar CSV")}</p>
						{loadingRelatorioCSV ? (
							<span style={{ marginLeft: '4px' }}>
								<CarregandoDados />
							</span>
						) : (
							<></>
						)}
					</div>
					<div
						onClick={() => getRelatorioXLSX()}
						className="relatorio-print-container"
						disabled={true}
						style={{
							pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
							opacity: loadingRelatorioCSV ? '0.5' : '1',
						}}
					>
						<FontAwesomeIcon icon={faTable} className="print-icon-relatorio" />
						<p className="print-text-relatorio">{t("Gerar Excel")}</p>
						{loadingRelatorioCSV ? (
							<span style={{ marginLeft: '4px' }}>
								<CarregandoDados />
							</span>
						) : (
							<></>
						)}
					</div>
					<div
						onClick={() => handlePrintData()}
						className="relatorio-print-container"
					>
						<FontAwesomeIcon icon={faPrint} className="print-icon-relatorio" />
						<p className="print-text-relatorio">{t("Imprimir")}</p>
					</div>
				</div>
			</div>
			{loadingGetHoras === false && (
				<div>
					{tipoDeRelarorio === 'projeto' ? (
						<CustoListProjeto
							horaOcioData={horaOcioData}
							custoData={custoData}
							dataInicialFinal={dataInicialFinal}
							tipoDeRelarorio={tipoDeRelarorio}
							totalSumProject={totalSumProject}
							subtractValues={subtractValues}
							finalTotalSum={finalTotalSum}
						/>
					) : (
						<CustoListPessoa
							horaOcioData={horaOcioData}
							custoData={custoData}
							dataInicialFinal={dataInicialFinal}
							tipoDeRelarorio={tipoDeRelarorio}
							
							totalSumProject={totalSumProject}
							subtractValues={subtractValues}
							finalTotalSum={finalTotalSum}
						/>
					)}
				</div>
			)}
			<div id="printable"> 
				{loadingGetHoras ? (
					<CarregandoDados />
				) : (
					<table className="relatorio-table">
						<thead></thead>
						<tbody></tbody>
					</table>
				)}
			</div>
		</div>
	);
}

const CustoListProjeto = ({ custoData, dataInicialFinal, tipoDeRelarorio, horaOcioData, subtractValues, finalTotalSum, totalSumProject }) => {
	const {t} = useTranslation();
	const [finalSum, setFinalSum] = useState(0);
	const formatter = new Intl.NumberFormat('pt-br', {
		style: 'currency',
		currency: 'BRL',
	});

	

	useEffect(() => {
		setFinalSum(finalTotalSum(custoData, horaOcioData, "pessoas_projeto"));
	}, [custoData, horaOcioData]);

	const colorStyle = (valor1, valor2) => {
		const total = valor1 - valor2;
		return total > 0 ? 'none' : '#d84b58';
	};
	
	return (
		<table className="relatorio-table-custo">
			<thead>
				<tr>
					<th className="cabecalho-table-financeiro" colSpan="4">
						{dataInicialFinal}
					</th>
				</tr>
				<tr>
					{tipoDeRelarorio === 'pessoa' ? (
						<>
							<th className="cabecalho-financeiro">{t("Usuário")}</th>
							<th className="cabecalho-financeiro">{t("Projeto")}</th>
						</>
					) : (
						<>
							<th className="cabecalho-financeiro">{t("Projeto")}</th>
							<th className="cabecalho-financeiro">{t("Usuário")}</th>
						</>
					)}
					<th className="cabecalho-financeiro">{t("Horas Trabalhadas")}</th>
					<th className="cabecalho-financeiro">{t("Custo por Hora")}</th>
					<th className="cabecalho-financeiro">{t("Custo Total")}</th>
					{/* <th className="cabecalho-financeiro">{t("Horas Negociadas</th")}> */}
					<th className="cabecalho-financeiro">{t("Valor Hora Negociado")}</th>
					<th className="cabecalho-financeiro">{t("Valor Total Negociado")}</th>
					<th className="cabecalho-financeiro-saldo">{t("Saldo")}</th>
					<th className="cabecalho-financeiro-saldo">{t("Saldo Projeto")}</th>
				</tr>
			</thead>
			<tbody>
				{custoData !== undefined && custoData.map((item, index) => (
					<>
					<div className='page-break' />
						{item.pessoas_projeto.map((pessoa, index) => (
							<>
								{index === 0 ? (
									<tr>
										<td
											className="projeto-td-financeiro"
											rowSpan={item.pessoas_projeto.length}
											style={{
												borderBottom: '6px solid #d6d6d6',
												padding: '10px 5px',
											}}
										>
											{item.nome}
										</td>
										<td
											className={`projeto-td-financeiro`}
											style={{
												borderBottom:
													item.pessoas_projeto.length === index + 1
														? '6px solid #d6d6d6'
														: '',
												padding: '10px 5px',
											}}
										>
											{pessoa.pessoa.nome}
										</td>
										<td
											className="projeto-td-financeiro"
											style={{
												borderBottom:
													item.pessoas_projeto.length === index + 1
														? '6px solid #d6d6d6'
														: '',
												textAlign: 'center',
												padding: '10px 5px',
											}}
										>
											{pessoa.horas_trabalhadas}
										</td>
										<td
											className="projeto-td-financeiro"
											style={{
												borderBottom:
													item.pessoas_projeto.length === index + 1
														? '6px solid #d6d6d6'
														: '',
												padding: '10px 5px',
											}}
										>
											{formatter.format(pessoa.valor_pessoaporhora)}
										</td>
										<td
											className="projeto-td-financeiro"
											style={{
												borderBottom:
													item.pessoas_projeto.length === index + 1
														? '6px solid #d6d6d6'
														: '',
												padding: '10px 5px',
											}}
										>
											{formatter.format(pessoa.custo_total_pessoa)}
										</td>

										<td
											className="projeto-td-financeiro"
											style={{
												borderBottom:
													item.pessoas_projeto.length === index + 1
														? '6px solid #d6d6d6'
														: '',
												padding: '10px 5px',
											}}
										>
											{formatter.format(pessoa.valor_pessoanegociado)}
										</td>

										<td
											className="projeto-td-financeiro"
											style={{
												borderBottom:
													item.pessoas_projeto.length === index + 1
														? '6px solid #d6d6d6'
														: '',
												padding: '10px 5px',
											}}
										>
											{formatter.format(pessoa.custo_total_negociado)}
										</td>
										<td
											className="projeto-td-financeiro"
											style={{
												borderBottom:
													item.pessoas_projeto.length === index + 1
														? '6px solid #d6d6d6'
														: '',
												padding: '10px 5px',
												color: colorStyle(
													pessoa.custo_total_negociado,
													pessoa.custo_total_pessoa,
												),
											}}
										>
											{formatter.format(
												subtractValues(
													pessoa.custo_total_negociado,
													pessoa.custo_total_pessoa,
												),
											)}
										</td>

										<td
											className="projeto-td-financeiro"
											rowSpan={item.pessoas_projeto.length}
											style={{
												borderBottom: '6px solid #d6d6d6',
												color:
													totalSumProject(item.pessoas_projeto) > 0
														? 'none'
														: '#d84b58',
												padding: '10px 5px',
											}}
										>
											{formatter.format(
												totalSumProject(item.pessoas_projeto),
											)}
										</td>
									</tr>
								) : (
									<tr>
										<>
											<td
												className="projeto-td-financeiro"
												style={{
													borderBottom:
														item.pessoas_projeto.length === index + 1
															? '6px solid #d6d6d6'
															: '',
													padding: '10px 5px',
												}}
											>
												{pessoa.pessoa.nome}
											</td>
											<td
												className="projeto-td-financeiro"
												style={{
													borderBottom:
														item.pessoas_projeto.length === index + 1
															? '6px solid #d6d6d6'
															: '',
													textAlign: 'center',
													padding: '10px 5px',
												}}
											>
												{pessoa.horas_trabalhadas}
											</td>
											<td
												className="projeto-td-financeiro"
												style={{
													borderBottom:
														item.pessoas_projeto.length === index + 1
															? '6px solid #d6d6d6'
															: '',
													padding: '10px 5px',
												}}
											>
												{formatter.format(pessoa.valor_pessoaporhora)}
											</td>
											<td
												className="projeto-td-financeiro"
												style={{
													borderBottom:
														item.pessoas_projeto.length === index + 1
															? '6px solid #d6d6d6'
															: '',
													padding: '10px 5px',
												}}
											>
												{formatter.format(pessoa.custo_total_pessoa)}
											</td>
											<td
												className="projeto-td-financeiro"
												style={{
													borderBottom:
														item.pessoas_projeto.length === index + 1
															? '6px solid #d6d6d6'
															: '',
													padding: '10px 5px',
												}}
											>
												{formatter.format(pessoa.valor_pessoanegociado)}
											</td>

											<td
												className="projeto-td-financeiro"
												style={{
													borderBottom:
														item.pessoas_projeto.length === index + 1
															? '6px solid #d6d6d6'
															: '',
													padding: '10px 5px',
												}}
											>
												{formatter.format(pessoa.custo_total_negociado)}
											</td>

											<td
												className="projeto-td-financeiro"
												style={{
													borderBottom:
														item.pessoas_projeto.length === index + 1
															? '6px solid #d6d6d6'
															: '',
													color: colorStyle(
														pessoa.custo_total_negociado,
														pessoa.custo_total_pessoa,
													),
													padding: '10px 5px',
												}}
											>
												{formatter.format(
													subtractValues(
														pessoa.custo_total_negociado,
														pessoa.custo_total_pessoa,
													),
												)}
											</td>
										</>
									</tr>
								)}
							</>
						))}
					</>
				))}
				{  (
					<>
					{horaOcioData != undefined && horaOcioData.map((item) =>{
						return (
							<tr key={`${item.total_hora_pessoatarefa}-${item.id}`}>
								<td class="projeto-td-financeiro" rowspan="1" >Tempo Ocioso</td>
								<td class="projeto-td-financeiro" >{item.pessoa_nome}</td>
								<td class="projeto-td-financeiro"
									style={{textAlign: 'center'}} >
										{item.total_hora_pessoatarefa}
								</td>
								<td class="projeto-td-financeiro" >{formatter.format(item.valor_pessoaporhora)}</td>
							<td class="projeto-td-financeiro" >{formatter.format(item.custo_total_pessoa)}</td>
								<td class="projeto-td-financeiro" >{formatter.format(item.valor_pessoaporhora)}</td>
							<td class="projeto-td-financeiro" >{formatter.format(item.custo_total_negociado)}</td>
								<td class="projeto-td-financeiro" >
								{
									formatter.format(
										subtractValues(
											item.custo_total_negociado,
											item.custo_total_pessoa,
										)
								)}
							</td>
								<td class="projeto-td-financeiro" rowspan="1" >
										{
											formatter.format(
												subtractValues(
													item.custo_total_negociado,
													item.custo_total_pessoa,
												)
										)}
									</td>
							</tr>
						)
					})

					}
						</>
				)}

			</tbody>
			<tfoot>
				<tr>
					<th className="span-table-botton" colSpan="8"></th>

					<th id="totalValor-financeiro">
						{formatter.format(finalSum.toFixed(2))}
					</th>
				</tr>
			</tfoot>
		</table>
	);
};

const CustoListPessoa = ({ custoData, dataInicialFinal, tipoDeRelarorio, horaOcioData, subtractValues, finalTotalSum, totalSumProject }) => {
	const {t} = useTranslation();
	const [finalSum, setFinalSum] = useState(0);
	const formatter = new Intl.NumberFormat('pt-br', {
		style: 'currency',
		currency: 'BRL',
	});

	useEffect(() => {
		setFinalSum(finalTotalSum(custoData, horaOcioData, "custos_pessoa_projeto"));
	}, [custoData]);

	const colorStyle = (valor1, valor2) => {
		const total = valor1 - valor2 <= 0 ? '#d84b58' : 'none';
		return total;
	};

	return (
		<table className="relatorio-table-custo">
			<thead>
				<tr>
					<th className="cabecalho-table-financeiro" colSpan="4">
						{dataInicialFinal}
					</th>
				</tr>
				<tr>
					{tipoDeRelarorio === 'pessoa' ? (
						<>
							<th className="cabecalho-financeiro">{t("Usuário")}</th>
							<th className="cabecalho-financeiro">{t("Projeto")}</th>
						</>
					) : (
						<>
							<th className="cabecalho-financeiro">{t("Projeto")}</th>
							<th className="cabecalho-financeiro">{t("Usuário")}</th>
						</>
					)}
					<th className="cabecalho-financeiro">{t("Horas Trabalhadas")}</th>
					<th className="cabecalho-financeiro">{t("Custo por Hora")}</th>
					<th className="cabecalho-financeiro">{t("Custo Total")}</th>
					<th className="cabecalho-financeiro">{t("Custo Hora Negociado")}</th>
					<th className="cabecalho-financeiro">{t("Valor Hora Negociado")}</th>
					<th className="cabecalho-financeiro-saldo">{t("Saldo")}</th>
					{tipoDeRelarorio === 'pessoa' ? (
						<th className="cabecalho-financeiro-saldo">{t("Saldo Pessoa")}</th>
					) : (
						<th className="cabecalho-financeiro-saldo">{t("Saldo Projeto")}</th>
					)}
				</tr>
			</thead>
			<tbody>
						{custoData !== undefined && custoData.map((item, index) => (
							<>
								{item.custos_pessoa_projeto.map((pessoa, index) => (
									<>
										{index === 0 ? (
											<tr>
												<td
													className="projeto-td-financeiro"
													rowSpan={item.custos_pessoa_projeto.length}
													style={{
														borderBottom: '6px solid #d6d6d6',
														padding: '10px 5px',
													}}
												>
													{item.nome}
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{pessoa.projeto.nome}
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														textAlign: 'center',
														padding: '10px 5px',
													}}
												>
													{pessoa.horas_trabalhadas}
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{formatter.format(pessoa.valor_pessoaporhora)}
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{formatter.format(pessoa.custo_total_pessoa)}
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{formatter.format(pessoa.valor_pessoanegociado)}
												</td>

												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{formatter.format(pessoa.custo_total_negociado)}
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
														color: colorStyle(
															pessoa.custo_total_negociado,
															pessoa.custo_total_pessoa,
														),
													}}
												>
													{formatter.format(
														subtractValues(
															pessoa.custo_total_negociado,
															pessoa.custo_total_pessoa,
														),
													)}
												</td>

												<td
													className="projeto-td-financeiro"
													rowSpan={item.custos_pessoa_projeto.length}
													style={{
														borderBottom: '6px solid #d6d6d6',
														color:
															totalSumProject(item.custos_pessoa_projeto) >= 0
																? 'none'
																: '#d84b58',
														padding: '10px 5px',
													}}
												>
													{formatter.format(
														totalSumProject(item.custos_pessoa_projeto),
													)}
												</td>
											</tr>
										) : (
											<tr>
												<>
													<td
														className="projeto-td-financeiro"
														style={{
															borderBottom:
																item.custos_pessoa_projeto.length === index + 1
																	? '6px solid #d6d6d6'
																	: '',
															padding: '10px 5px',
														}}
													>
														{pessoa.projeto.nome}
													</td>
													<td
														className="projeto-td-financeiro"
														style={{
															borderBottom:
																item.custos_pessoa_projeto.length === index + 1
																	? '6px solid #d6d6d6'
																	: '',
															textAlign: 'center',
															padding: '10px 5px',
														}}
													>
														{pessoa.horas_trabalhadas}
													</td>
													<td
														className="projeto-td-financeiro"
														style={{
															borderBottom:
																item.custos_pessoa_projeto.length === index + 1
																	? '6px solid #d6d6d6'
																	: '',
															padding: '10px 5px',
														}}
													>
														{formatter.format(pessoa.valor_pessoaporhora)}
													</td>
													<td
														className="projeto-td-financeiro"
														style={{
															borderBottom:
																item.custos_pessoa_projeto.length === index + 1
																	? '6px solid #d6d6d6'
																	: '',
															padding: '10px 5px',
														}}
													>
														{formatter.format(pessoa.custo_total_pessoa)}
													</td>

													<td
														className="projeto-td-financeiro"
														style={{
															borderBottom:
																item.custos_pessoa_projeto.length === index + 1
																	? '6px solid #d6d6d6'
																	: '',
															padding: '10px 5px',
														}}
													>
														{formatter.format(pessoa.valor_pessoanegociado)}
													</td>

													<td
														className="projeto-td-financeiro"
														style={{
															borderBottom:
																item.custos_pessoa_projeto.length === index + 1
																	? '6px solid #d6d6d6'
																	: '',
															padding: '10px 5px',
														}}
													>
														{formatter.format(pessoa.custo_total_negociado)}
													</td>

													<td
														className="projeto-td-financeiro"
														style={{
															borderBottom:
																item.custos_pessoa_projeto.length === index + 1
																	? '6px solid #d6d6d6'
																	: '',
															color: colorStyle(
																pessoa.custo_total_negociado,
																pessoa.custo_total_pessoa,
															),
															padding: '10px 5px',
														}}
													>
														{formatter.format(
															subtractValues(
																pessoa.custo_total_negociado,
																pessoa.custo_total_pessoa,
															),
														)}
													</td>
												</>
											</tr>
										)}
									</>
								))}
								{
									horaOcioData.filter(ocio => item.id ===ocio.pessoa).map((pessoa, index) => (
										<>
										<tr>
											<>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{item.nome}
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													Tempo Ocioso
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
														textAlign: 'center'
													}}
												>
													{pessoa.total_hora_pessoatarefa}
												</td>
												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px'
													}}
												>
													{formatter.format(pessoa.valor_pessoaporhora)}
												</td>

												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{formatter.format(pessoa.custo_total_pessoa)}
												</td>

												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{formatter.format(pessoa.valor_pessoaporhora)}
												</td>

												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
													{formatter.format(pessoa.custo_total_negociado)}
												</td>

												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														padding: '10px 5px',
													}}
												>
												{
													formatter.format(
														subtractValues(
															pessoa.custo_total_negociado,
															pessoa.custo_total_pessoa,
														)
												)}
												</td>

												<td
													className="projeto-td-financeiro"
													style={{
														borderBottom:
															item.custos_pessoa_projeto.length === index + 1
																? '6px solid #d6d6d6'
																: '',
														color: colorStyle(
															pessoa.custo_total_negociado,
															pessoa.custo_total_pessoa,
														),
														padding: '10px 5px',
													}}
												>
													{formatter.format(
														subtractValues(
															pessoa.custo_total_negociado,
															pessoa.custo_total_pessoa,
														)
												)}
												</td>
											</>
										</tr>
										</>
									))
								}
							</>
						))}
			</tbody>
			<tfoot>
				<tr>
					<th className="span-table-botton" colSpan="8"></th>

					<th id="totalValor-financeiro">
						{formatter.format(finalSum.toFixed(2))}
					</th>
				</tr>
			</tfoot>
		</table>
	);
};

const CarregandoDados = () => (
	<>
		<div className="loader-alinhamento">
			<div className="loader-conteudo"></div>
		</div>
	</>
);
