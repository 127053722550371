import styled from "styled-components";

interface TeamSelectorContainerProps {
    clickDisabled: boolean;
}

export const TeamSelectorContainer = styled.div<TeamSelectorContainerProps>`
    width: 100%;
	display: block;
	border: 1px solid #c0cfd8d9;
	border-radius: 5px;
	background: white;
    ${({clickDisabled}) => clickDisabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

export const TeamSelectorHeader = styled.div`
    top: 0;
    border-bottom: 10px solid white;
    border-right: 1px solid #c0cfd8d9;
    justify-content: space-between;
    display: flex;
    padding: 3px 0 3px 0;
    background-color: var(--modal-bg-color);
    border-radius: 5px;
    padding-left: 10px;
    display: flex;
    overflow-x: hidden;
    align-items: center;
    white-space: nowrap;
    position: sticky;
    grid-gap: 20px;
    gap: 20px;
`

interface TeamSelectorBodyProps {
    isFormSide?: boolean;
    isLoading?: boolean;
}


export const TeamSelectorHeaderBody = styled.div<TeamSelectorBodyProps>`
    top: 35px;
    border-top: 1px solid #c0cfd8d9;
    border-right: 1px solid #c0cfd8d9;
    padding: 3px 0 3px 0;
	background-color: var(--modal-bg-color);
	border-radius: 5px;
	padding-left: 10px;
	display: flex;
	overflow-x: hidden;
	align-items: center;
	white-space: nowrap;
	position: sticky;
	gap: 20px;
    ${({isFormSide}) => isFormSide && `
    align-items: start;
	flex-direction: column; 
    `}

`

export const TeamSelectorHeaderTitle = styled.p`
    color: #99adb9;
    font-size: 14px;
    font-weight: 500;
    margin: 0 20px 0 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`



export const TeamSelectorBody = styled.div<TeamSelectorBodyProps>`
    ${({isFormSide}) => isFormSide ? `
        display: flex !important;
        flex-direction: column;
        padding: 10px;
        resize: none;
    ` : `
        display: grid;
        grid-template-columns: 20% 20% 20% 20%;
        grid-gap: 10px 50px;
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        resize: none;
    `}
    ${({isLoading}) => isLoading && `
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 5px;
    `}

    @media (max-width: 1530px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`
export const PersonContainer = styled.div`
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

export const PersonImage = styled.img`
    border-radius: 50%;
	height: 25px;
	width: 25px;
`

export const PersonName = styled.p`
    max-width: 12ch;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: 0 5px;
	color: #75787a;
	font-weight: 400;
`

export const IconContainer = styled.div`
    display: flex;
	align-items: center;
	justify-content: center;
`

export const FormModal = styled.form`
    align-items: center;
	display: flex;
	width: 130px;
`

export const SearchIconContainer = styled.div`
    cursor: pointer;
    padding-bottom: 3px;
`

export const ModalInput = styled.input`
    font-size: 14px;
    width: 110px;
    background: none;
    border: none;
    outline: none;
    color: #355060;
    padding-bottom: 3px;
    padding-left: 5px;
`

export const Loader = styled.div`
    border: 6px solid #f3f3f3;
	border-radius: 50%;
	border-top: 6px solid #355060;
	width: 35px;
	height: 35px;
	-webkit-animation: spin 1s linear infinite; /* Safari */
	animation: spin 1s linear infinite;
`
export const EmptyList = styled.p`
    color: #99adb9;
    font-size: 14px;
    font-weight: 500;
    margin: 0 20px 0 0;
`