import { Field } from "formik";
import styled from "styled-components";

export const Label = styled.label`
    color: #9EB1BC;
`;

interface FieldWrapperProps {
    money?: boolean;
}

export const FieldWrapper = styled.div<FieldWrapperProps>`
    position: relative;
   ${({money}) => money && `&::before {
        content: 'R$';
        position: absolute;
        top: 10px;
        left: 18px;
        font-size: 13px;
        color: #426172;
    }`}
`;

export const FormikField = styled(Field)`
    width: 100%;
    background-color: white;
    border: 1px solid #C9D6DE;
    outline: none;
    padding: ${({money}) => money ? `9px 40px` : `9px 18px`};
    border-radius: 6px;
    color: #426172;
    &:disabled{
        background-color: #F5F5F5;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    };
    -moz-appearance: textfield;
    ${({type}) => type === 'date' && `
     &::before {
        opacity: 0;
     }

    `}
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 13px;
`

export const IconContainer = styled.div`
    position: absolute;
    right: 18px;
    top: 9px;
`