import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm} from 'formik';

import ProjetoService from '../../../../../services/providers/project';

import { toast } from 'react-toastify';
import {
	currencyMaskProjeto,
	currencyMaskReturnProjeto,
	currencyMaskReturnProjetoGet,
} from '../../../../../utils/funcoes/currencyMask';
import { useTranslation } from 'react-i18next';
import { HeaderPageDetail } from './styled';
import FieldProjectDetail from '../../../../../components/common/Fields/ProjectDetail';
import moment from 'moment';
import { BiCalendarAlt } from 'react-icons/bi';
import { MdOutlineModeEdit } from 'react-icons/md';
import { projectInfoAtom } from '../../../../../atoms/ProjectDetails';
import { useAtom } from 'jotai';

const FormConfig = () => {
	const {t} = useTranslation();
	const [projectInfo, setProjectInfo] = useAtom(projectInfoAtom);
	const [initialValues, setInitialValues] = useState({
		investimento: projectInfo?.investimento || null,
		horas_previstas: projectInfo?.horas_previstas || 0,
		inicio_previsto: projectInfo?.inicio_previsto || null,
		termino_previsto: projectInfo?.termino_previsto || null,
	});
	const [investimento, setInvestimento] = useState(projectInfo?.investimento || null);
	const [horasPrevistas, setHorasPrevistas] = useState(projectInfo?.horas_previstas || 0);
	const {id} = projectInfo;
	useEffect(() => {
		if (typeof projectInfo !== 'undefined' && Object.keys(projectInfo).length) {
			_getProjetos(projectInfo.id);
		}
	}, [id]);

	useEffect(() => {
		setInvestimento(initialValues.investimento);
		setHorasPrevistas(initialValues.horas_previstas);
	}, [initialValues]);

	const nameFields = {
		investimento: 'Investimento',
		horas_previstas: 'Horas negociadas',
		inicio_previsto: 'Início previsto',
		termino_previsto: 'Término previsto',
	};

	const _patchProjeto = (params, names) => {
		const realName = names.map(name => (name = nameFields[name]));
		realName.join(', ');
		const editedParam = Object.keys(Object.entries(params)
		.filter(([key, value]) => value !== undefined)
		.reduce((obj, [key, value]) => {
		  obj[key] = value;
		  return obj;
		}, {}))[0]
	  
		const projetoService = new ProjetoService();
		projetoService.patchProjetoObjectParams(
			params,
			projectInfo.id,
			response => {
				if (response) {
					toast.success(`${realName} ${t("atualizado com sucesso!")}`);
					setProjectInfo(oldValue => ({...oldValue, [editedParam]: response.data[editedParam]}))
				}
			},
			error => {
				toast.error(`${realName}`);
				// toast.error(error);
			},
		);
	};

	const _getProjetos = projetoID => {
		const projetoService = new ProjetoService();
		projetoService.getProject(
			projetoID,
		).then(response => {
			if (response) {
				const {
					investimento,
					horas_previstas,
					inicio_previsto,
					termino_previsto,
				} = response;
				setInitialValues(
					prevValue =>
						({
							...prevValue,
							investimento: currencyMaskReturnProjetoGet(investimento),
							horas_previstas,
							inicio_previsto,
							termino_previsto,
							
						}),
				);
			}
		}).catch(error => {
			// toast.error(error);
		});
	};
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={values => {
				const names = [];
				const invest = investimento ? currencyMaskReturnProjeto(investimento) : null;
				const isSameInvestimento = invest === projectInfo?.investimento;
				const isSameHour = horasPrevistas === projectInfo?.horas_previstas;
				const isSameBegin = values?.inicio_previsto === projectInfo?.inicio_previsto;
				const isSameEnd = values?.termino_previsto === projectInfo?.termino_previsto;
				if(!isSameInvestimento || !isSameHour || !isSameBegin || !isSameEnd) {
					_patchProjeto({
						horas_previstas: !isSameHour ? horasPrevistas : undefined,
						inicio_previsto: !isSameBegin ? values.inicio_previsto : undefined,
						termino_previsto: !isSameEnd ? values.termino_previsto : undefined,
						investimento: !isSameInvestimento ? currencyMaskReturnProjeto(investimento) : undefined,
					}, names);
				}
			
			}}
		>
			{({ errors, touched, handleSubmit, values, setValues}) => (
				
				<FormikForm>
					<HeaderPageDetail>
							<FieldProjectDetail 
								title={t("Projeto")}
								id="projeto"
								name="projeto"
								type="text"
								disabled={true}
								value={projectInfo?.nome}
	
							/>
							<FieldProjectDetail
								title={t("Cliente")}
								id="cliente"
								name="cliente"
								disabled={true}
								value={projectInfo?.hasOwnProperty('cliente') &&
								projectInfo?.cliente.hasOwnProperty('nome')
									? projectInfo?.cliente.nome
									: t('Cliente')}
							/>
							<FieldProjectDetail
								title={t("Data da criação")}
								name="created_at"
								disabled={true}
								type='text'
								value={moment(projectInfo?.created_at).format('L')}
							/>
							<FieldProjectDetail
								title={t("Status")}
								name="status"
								disabled={true}
								value={projectInfo?.item_ativo ? (
									t("Ativo")
								) : (
									t("Desativado")
								)
								}
								
							/>
							<FieldProjectDetail 
								title={t("Investimento")}
								id="investimento"
								name="investimento"
								type="text"
								fixedTxt="fixedTxt"
								typeField="advancedConfig"
								onBlur={handleSubmit}
								money={true}
								onChange={e => {
									currencyMaskProjeto(e, setInvestimento);
								}}
								value={investimento}
								errors={errors}
								touched={touched}
								icon={<MdOutlineModeEdit color='black' size={18}  />}
							/>
							<FieldProjectDetail
								title={t("Horas negociadas")}
								id="horas_previstas"
								name="horas_previstas"
								type="number"
								onBlur={handleSubmit}
								typeField="advancedConfig"
								// valueField={initialValues.horas_previstas}
								// setFieldValue={setInvestimento}
								onChange={e => {
									setHorasPrevistas(e.target.value)

								}}
								value={horasPrevistas}
								errors={errors}
								touched={touched}
								icon={<MdOutlineModeEdit color='black' size={18}  />}

							/>
							<FieldProjectDetail
								title={t("Início previsto")}
								name="inicio_previsto"
								typeField="advancedConfig"
								type="date"
								onBlur={handleSubmit}
								errors={errors}
								touched={touched}
								icon={<BiCalendarAlt color='#426172' size={18}  />}
								value={values.inicio_previsto}
								onChange={(e) => {
									setValues({inicio_previsto: e.target.value})
								}}

							/>
							<FieldProjectDetail
								title={t("Término previsto")}
								name="termino_previsto"
								typeField="advancedConfig"
								type="date"
								onBlur={handleSubmit}
								errors={errors}
								touched={touched}
								icon={<BiCalendarAlt color='#426172' size={18}  />}
								value={values.termino_previsto}
								onChange={(e) => {
									setValues({termino_previsto: e.target.value})
								}}

							/>
					</HeaderPageDetail>
				</FormikForm>
			)}
		</Formik>
	);
};

export default FormConfig;
