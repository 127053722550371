import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ModalConteudoGenerico,
	ModalPrincipalGenerico,
} from '../../../components/containers/Modais/ModalComSideGenerico';
import { ModalContainerTime } from './style';
import TimeForm from './form';
import { TimeResponse } from '../../../services/providers/okrs/times/types';
import ProjetoService from '../../../services/providers/project';
import { toast } from 'react-toastify';
import { timesOkrsAtom } from '../../../atoms/Okrs';
import { useAtom } from 'jotai';

export interface ModalTimeProps {
	setModalShow: Dispatch<SetStateAction<boolean>>;
	time?: TimeResponse;
	editingTime?: boolean;
    getTimesPagination: (page:number) => Promise<void>
}

export const ModalTime = ({
	setModalShow,
	time,
	editingTime = false,
	getTimesPagination
}: ModalTimeProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
    const [timeOkrs, setTimesOkrs] = useAtom(timesOkrsAtom)

	const deleteProject = (id: number) => {
		const projetoService = new ProjetoService();
		setLoading(true)
		projetoService.deleteProjeto(
			id,
			() => {
					toast.success(t('Time removido com sucesso!'));
					setTimesOkrs(prev => {
						const tempTimes = [...prev.timesOkrs].filter(item => item.id !== id);

						return {
							...prev,
							timesOkrs: tempTimes
						}
					});

					getTimesPagination(timeOkrs.currentPage).finally(() => {
						setLoading(false)
						setModalShow(false);
					})
			},
			error => {
				toast.error(error);
				setLoading(false)
			},
		);
	};

	return (
		<ModalPrincipalGenerico modalGenericoShow={setModalShow}>
			<ModalContainerTime>
				<ModalConteudoGenerico
					copyText=""
					title={editingTime ? t('Edição de Time') : t('Novo Time')}
					modalGenericoShow={setModalShow}
					btnSave={true}
					loadingBtn={loading}
					modalIsShowing={true}
					saveAndContinue={false}
					itemId={time?.id}
					handleDeletar={editingTime ? deleteProject : undefined}
					onClickSave={() => {
						document.getElementById('formSideModal-time')?.click();
					}}
					onClickSaveAndContinue={() => {}}
					showEllipsis={true}
				>
					<TimeForm
						time={time}
						editing={editingTime}
						loading={loading}
						setLoading={setLoading}
						setModalShow={setModalShow}
					/>
				</ModalConteudoGenerico>
			</ModalContainerTime>
		</ModalPrincipalGenerico>
	);
};
