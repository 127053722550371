import { t } from 'i18next';
import * as Yup from 'yup';

export const ClienteSchema = Yup.object().shape({
	nome: Yup.string().required(t('Campo obrigatório.')),
});

export interface FormClient {
  id?: number;
  nome: string;
  atividade: string;
  contato: string;
  logo_cliente: string;
  observacao: string;
}