import { t } from 'i18next';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { FilterContextInitalType, FilterProviderProps } from './types';

export const initialValues: FilterContextInitalType = {
	selectedClient: { label: t('Cliente'), value: '' },
	setSelectedClient: () => {},
	selectedProject: { label: t('Projeto'), value: '' },
	setSelectedProject: () => {},
	selectedPerson: { label: t('Gerente'), value: '' },
	setSelectedPerson: () => {},
	checkClosed: false,
	setCheckClosed: () => {},
	selectedTag: [{ label: t('Tags'), value: '' }],
	setSelectedTag: () => {},
	resetFilters: () => {},
	search: '',
	setSearch: () => {},
};

export const FilterContext = createContext(initialValues);

const FilterProjectRProvider = ({ children }: FilterProviderProps) => {
	const [selectedClient, setSelectedClient] = useState(
		initialValues.selectedClient,
	);
	const [selectedProject, setSelectedProject] = useState(
		initialValues.selectedProject,
	);
	const [selectedPerson, setSelectedPerson] = useState(
		initialValues.selectedPerson,
	);
	const [checkClosed, setCheckClosed] = useState(initialValues.checkClosed);
	const [selectedTag, setSelectedTag] = useState(initialValues.selectedTag);
	const [search, setSearch] = useState(initialValues.search);

	const resetFilters = () => {
		setSelectedClient(initialValues.selectedClient);
		setSelectedProject(initialValues.selectedProject);
		setSelectedPerson(initialValues.selectedPerson);
		setCheckClosed(initialValues.checkClosed);
		setSelectedTag(initialValues.selectedTag);
	};

	const filterValue = useMemo(
		() => ({
			selectedClient,
			selectedProject,
			selectedPerson,
			selectedTag,
			checkClosed,
			resetFilters,
			setSelectedClient,
			setSelectedProject,
			setSelectedPerson,
			setSelectedTag,
			setCheckClosed,
			search,
			setSearch,
		}),
		[
			selectedClient,
			selectedProject,
			selectedPerson,
			selectedTag,
			checkClosed,
			resetFilters,
			setSelectedClient,
			setSelectedProject,
			setSelectedPerson,
			setSelectedTag,
			setCheckClosed,
			search,
			setSearch,
		],
	);

	return (
		<FilterContext.Provider value={filterValue}>
			{children}
		</FilterContext.Provider>
	);
};

const useProjectRFilters = () => {
	const context = useContext(FilterContext);
	return context;
};

export { FilterProjectRProvider, useProjectRFilters };
