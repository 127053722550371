import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoTarefaBody from './InfoTarefaBody';
import { useDataTask } from '../../../../contexts/InfoTarefa/DataTask';
import TarefaService from '../../../../services/providers/task';
import ModalTarefa from '../ModalTarefa';
import { LocationData } from './types';
import { Task } from '../../../../models/Task';
import { PostPatchResponse } from '../../../../services/providers/task/types';
import { InfoBodyContainer, Loader, LoaderContainer, PageContainer } from './styled';
import { t } from 'i18next';
import { useUser } from '../../../../contexts/User';
import { useFilters } from '../../../../contexts/Filter/Task/Filter';

function InfoTarefa() {
	const [accessCode, setAccessCode] = useState<string>('m');
	const [task, setTask] = useState<Task>();
	const [loading, setLoading] = useState<boolean>(true);
	const [isModalTarefaVisible, setIsModalTarefaVisible] = useState<boolean>(false);
	const {closedId, setClosedId, deletedId, setDeletedId, getTasksPagination, setDataTask, dataTask, setPatchResponse} = useDataTask();
	const {	
		selectedClient, 
		selectedProject,
		checkClosedTask,
		tagsFilter,
		selectedStatus,
		startDate,
		endDate,
		checkCreatedByMe,
		checkMyTasks,
	} = useFilters();
	const history = useHistory();
	const { id } = useParams<any>();
	const data = useLocation<LocationData>();

	const { userDetails } = useUser();
	useEffect(() => {
		getTasksPagination();
	}, [
		selectedClient,
		selectedProject,
		checkClosedTask,
		tagsFilter,
		selectedStatus,
		startDate,
		endDate,
		checkCreatedByMe,
		checkMyTasks
	]);
	const getUserdetails = () => {
		if (userDetails !== null) {
			setAccessCode(userDetails.acesso_cod);
		}
	};

	const goBack = () => {
		if (data?.state?.urlBack) {
			history.replace(data?.state?.urlBack);

		} else {
			history.replace('/registros/tarefas');

		}
	}


	const getTask = () => {
		setLoading(true);
		const tarefaService = new TarefaService();
		tarefaService.getTarefa(
			id,
			response => {
				if (response) {
					setTask(response.data);
				}
				setLoading(false);
			},
			error => {
				console.log(error);
				toast.error(error);
				goBack();
				setTask(undefined);
				setLoading(false);
			},
		);

	};
	useEffect(() => {
		if (data?.state?.task) {
			setTask(data.state.task);
			setLoading(false);
		} else {
			getTask();
		}

		if (!dataTask || dataTask?.length <= 0) {
			getTasksPagination();
		}
		getUserdetails();
	}, []);

	useEffect(() => {
		if (!!deletedId || !!closedId) {
			goBack();
		}
	}, [closedId, deletedId])

	const endTask = useCallback((id: number) => {
		const tarefaService = new TarefaService();
		tarefaService.encerrarTarefa(
			{id: id,
			success: response => {
				if (response) {
					toast.success(`${t('Tarefa encerrada com sucesso')}!`);
					setClosedId(id);
				}
				setIsModalTarefaVisible(false);
			},
			errorCallback:  error => {
				toast.error(error);
				setIsModalTarefaVisible(false);
			},}
		);
	}, [TarefaService, setClosedId, setIsModalTarefaVisible]);

	const handlePatchTask = useCallback((response: PostPatchResponse) => {
		setIsModalTarefaVisible(false);
		setPatchResponse(response);
		setTask(response);
		setDataTask([])
		getTasksPagination();
	}, [setIsModalTarefaVisible, setPatchResponse, setTask, setDataTask, getTasksPagination]);

	const handleDeleteTask = useCallback((id: number | string) => {
		const taskId = parseInt(id.toString());
		setDeletedId(taskId)
	}, [setDeletedId])

	const handleCloseTask = useCallback((id: number | string) => {
		const taskId = parseInt(id.toString());
		setClosedId(taskId)
	}, [setClosedId])

	const handleClickEdit = useCallback(() => setIsModalTarefaVisible(true), [setIsModalTarefaVisible]);

	return (
		<PageContainer>
			<InfoBodyContainer>
				{loading ? (
					<LoaderContainer >
						<Loader role={'progressbar'} />
					</LoaderContainer>
				) : (
					<InfoTarefaBody
						task={task!}
						deleteTask={handleDeleteTask}
						closeTask={handleCloseTask}
						accessCode={accessCode}
						onClickEdit={handleClickEdit}
						urlBack={data?.state?.urlBack || '/registros/tarefas'}

					/>
				)}
			</InfoBodyContainer>
			{isModalTarefaVisible && (
				<ModalTarefa
					setIsVisible={setIsModalTarefaVisible}
					isVisible={isModalTarefaVisible}
					editingTask={true}
					selectedTask={task}
					handleAfterPatch={handlePatchTask}
					handleEncerrar={endTask}
				/>
			)}
		</PageContainer>
	);
}

export default InfoTarefa;