import React, { useCallback, useEffect, useState } from 'react';
import '../../../../../components/containers/Modais/modalGeral.css';
import ModalGenerico from '../../../../../components/containers/Modais/ModalGenerico';
import './style.css';
import SeletorTime from '../../../../../components/common/SeletorTime';
import { ProjetoForm } from '../../ModalProjeto/form';
import { validarPerfil } from '../../../../../utils/funcoes/validarPerfil';
import { useProject } from '../../../../../contexts/Project';
import { useTranslation } from 'react-i18next';

const ModalConfig = ({
	editingProjeto,
	itemSelecionado,
	handleDeletar,
	handleEncerrar,
	projetoTime,
	handlePostPatchProjeto
}) => {
	const {t} = useTranslation();
	const [timeSelecionado, setTimeSelecionado] = useState([]);
	const acessCode = 'a';
	const [isMember, setIsMember] = useState(false);
	const [admins, setAdmins] = useState([]);
	const [perfilValido, setPerfilValido] = useState(false);

	const {loadingPostPatch, modalProjectVisible, setModalProjectVisible} = useProject();


	useEffect(() => {
		setIsMember(itemSelecionado.is_member);
		setPerfilValido(
			validarPerfil(acessCode, itemSelecionado.is_member, editingProjeto),
		);
	}, [acessCode, editingProjeto, itemSelecionado.is_member]);

	const handleClickSave = () => {
		document.getElementById('input-submit-projeto').click();
	};

	useEffect(() => {
		if (projetoTime && projetoTime.length > 0 && editingProjeto) {
			adicionaPessoaTime(projetoTime);
		}
	}, [projetoTime]);

	const handleClickSaveAndContinue = () => {
		document.getElementById('input-submit-projeto').click();
	};

	const adicionaPessoaTime = useCallback(
		(pessoaRecebida) => {
			if (Array.isArray(pessoaRecebida)) {
				const pessoas = pessoaRecebida.map(item => item.id);
				setTimeSelecionado(pessoas);
			} else {
				setTimeSelecionado([...timeSelecionado, pessoaRecebida.id]);
			}
		},
		[timeSelecionado],
	);

	const handleRemovePessoaTime = useCallback(
		(pessoaRecebida) => {
			const index = timeSelecionado.findIndex(id => id === pessoaRecebida.id);
			timeSelecionado.splice(index, 1);
		},
		[timeSelecionado],
	);



	return (
		<>
			<ModalGenerico
				title={editingProjeto ? itemSelecionado.nome : t('Criar Projeto')}
				modalGenericoShow={setModalProjectVisible}
				saveAndContinue={!editingProjeto}
				modalIsShowing={modalProjectVisible}
				btnSave={perfilValido}
				showEllipsis={perfilValido === true}
				handleEncerrar={handleEncerrar}
				handleDeletar={handleDeletar}
				itemId={itemSelecionado.id}
				loadingBtn={loadingPostPatch}
				onClickSaveAndContinue={handleClickSaveAndContinue}
				onClickSave={handleClickSave}
			>
				<div className="modal-width-projetos">
					{editingProjeto ? (
						<ProjetoForm
							editing={true}
							item_edit={itemSelecionado}
							timeSelecionado={timeSelecionado}
							editingProjeto={editingProjeto}
							admins={admins}
							perfilValido={perfilValido}
							saveAndContinue={false}
							handlePatchProjeto={handlePostPatchProjeto}
						/>
					) : (
						<ProjetoForm
							timeSelecionado={timeSelecionado}
							editingProjeto={editingProjeto}
							admins={admins}
							perfilValido={perfilValido}
							saveAndContinue={false}
							isMember={isMember}
							acessCode={acessCode}
							editing={false}
							handlePatchProjeto={handlePostPatchProjeto}
						/>
					)}

					<div className="container">
						<div>
							<div
								className={`col-12 col-md-12 col-lg-12 modal-projeto-container ${perfilValido ===
									false && 'campo-desabilitado'}`}
							>
								{editingProjeto ? (
									<>
										<SeletorTime
											editandoTime={itemSelecionado}
											projetoTime={projetoTime}
											setAdmins={setAdmins}
											adicionaPessoaTime={adicionaPessoaTime}
											removePessoaTime={handleRemovePessoaTime}
										/>
									</>
								) : (
									<SeletorTime
										editandoTime={[]}
										setAdmins={setAdmins}
										adicionaPessoaTime={adicionaPessoaTime}
										removePessoaTime={handleRemovePessoaTime}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</ModalGenerico>
		</>
	);
};

export default ModalConfig;
