import React, { useState, useEffect } from 'react';

import pessoaImage from '../../../assets/images/profile.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import '../../../i18n';

import './style.css';
import { useTranslation } from 'react-i18next';

export default function SeletorTimeTarefa(props) {
	const {t} = useTranslation();
	const [dadosPesquisa, setDadosPesquisa] = useState('');
	const [responsaveisDaTarefa, setResponsaveisDaTarefa] = useState(
		props.ResponsaveisDaTarefaData,
	);
	const [pessoasDoProjeto, setPessoasDoProjeto] = useState([]);
	// const [loading, setLoading] = useState(false);
	const loading = false;

	useEffect(() => {
		props.ResponsaveisDaTarefaData.forEach(pessoa => {
			props.adicionaPessoaResponsavel(pessoa);
		});
	}, []);

	useEffect(() => {
		if (props.pessoasDoProjetoData && props.isEditing === false) {
			setPessoasDoProjeto(props.pessoasDoProjetoData);
		} else if (props.pessoasDoProjetoData && props.isEditing === true) {
			filtroResponsaveisDaTarefaExistente(props.pessoasDoProjetoData);
		}
	}, [props.pessoasDoProjetoData]);

	const adicionarResponsavel = responsavelAdicionado => {
		setResponsaveisDaTarefa(prevResp => [...prevResp, responsavelAdicionado]);
		setPessoasDoProjeto(prevPessoa =>
			prevPessoa.filter(pessoa => pessoa.id !== responsavelAdicionado.id),
		);
		props.adicionaPessoaResponsavel(responsavelAdicionado);
	};

	const removerPessoaResponsavel = responsavelRemovido => {
		pessoasDoProjeto.push(responsavelRemovido);
		const index = responsaveisDaTarefa.findIndex(
			pessoa => pessoa.id === responsavelRemovido.id,
		);
		responsaveisDaTarefa.splice(index, 1);
		setResponsaveisDaTarefa([...responsaveisDaTarefa]);
		setPessoasDoProjeto([...pessoasDoProjeto]);
		props.removePessoaResponsavel(responsavelRemovido);
	};

	const filtroResponsaveisDaTarefaExistente = pessoasRecebidas => {
		const pessoasFiltradas = pessoasRecebidas;
		responsaveisDaTarefa.forEach(pessoaNoTime => {
			const index = pessoasRecebidas.findIndex(
				pessoaRecebida => pessoaRecebida.id === pessoaNoTime.id,
			);
			if (index !== -1) {
				pessoasFiltradas.splice(index, 1);
			}
		});
		setPessoasDoProjeto(pessoasFiltradas);
	};

	return (
		<div className={`seletor-responsavel-container ${props.isFormSide ? 'seletor-responsavel-container-side' : ''}`}>
			<div className="seletor-responsavel-header d-flex justify-content-between">
				<p className="seletor-responsavel-header-text">{t("Responsável")}</p>
			</div>
			<div className={`${props.isFormSide ? 'seletor-responsavel-body-side' : 'seletor-responsavel-body'}`}>
				{responsaveisDaTarefa.length !== 0
					? responsaveisDaTarefa.map(pessoa => (
						<div
							key={pessoa.id}
							title={pessoa.nome}
							onClick={() => {
								removerPessoaResponsavel(pessoa);
							}}
							className="seletor-responsavel-pessoa-container"
						>
							<img
								className="seletor-responsavel-imagem-item "
								src={
									pessoa.foto_perfil && pessoa.foto_perfil !== null
										? pessoa.foto_perfil
										: pessoaImage
								}
								alt="erro"
							/>
							<p className="seletor-responsavel-pessoa-text">{pessoa.nome}</p>
							<div className="seletor-responsavel-pessoa-icon-container">
								<FontAwesomeIcon
									icon={faTimesCircle}
									className="seletor-responsavel-pessoa-icon-remove"
								/>
							</div>
						</div>
					))
				: null}
			</div>
			<div
				className="seletor-responsavel-header"
				style={{ borderTop: '1px solid #c0cfd8d9' }}
			>
				<p className="seletor-responsavel-header-text">{t("Time do Projeto")}</p>
				<form
					className="form-modal-projetos"
					onSubmit={event => {
						event.preventDefault();
					}}
				>
					<div title={t("Pesquisar")} className="search-modal-button">
						<FontAwesomeIcon
							icon={faSearch}
							className="search-modal-icon-projeto"
						/>
					</div>
					<input
						title={t("Pressione 'ENTER' ou clique no ícone de 'lupa' para pesquisar")}
						autoComplete={'off'}
						value={dadosPesquisa}
						type="text"
						name="search"
						className="form-modal-input"
						placeholder={t("Pesquisar")}
						onChange={e => {
							setDadosPesquisa(e.target.value);
						}}
					/>
				</form>
			</div>
			<div
				className={
					loading || pessoasDoProjeto.length === 0
						? 'seletor-responsavel-center-loading'
						: props.isFormSide ? 'seletor-responsavel-body-side' : 'seletor-responsavel-body'
				}
			>
				{pessoasDoProjeto.length === 0 && props.isEditing ? (
					<div className="seletor-responsavel-loader" />
				) : pessoasDoProjeto.length !== 0 ? (
					pessoasDoProjeto.map(pessoa => (
						<div
							key={pessoa.id}
							title={pessoa.nome}
							onClick={() => {
								adicionarResponsavel(pessoa);
							}}
							className="seletor-responsavel-pessoa-container"
							style={{
								display: pessoa.nome
									.toLowerCase()
									.startsWith(dadosPesquisa.toLowerCase())
									? 'flex'
									: 'none',
							}}
						>
							<img
								className="seletor-responsavel-imagem-item "
								// name={`cliente${item.id}`}
								src={
									pessoa.foto_perfil && pessoa.foto_perfil !== null
										? pessoa.foto_perfil
										: pessoaImage
								}
								alt="erro"
							/>
							<p className="seletor-responsavel-pessoa-text">{pessoa.nome}</p>
							<div className="seletor-responsavel-pessoa-icon-container">
								<FontAwesomeIcon
									transform={{ rotate: 45 }}
									icon={faTimesCircle}
									className="seletor-responsavel-pessoa-icon-add "
								/>
							</div>
						</div>
					))
				) : (
					<p className="seletor-responsavel-header-text">{t("Lista vazia")}.</p>
				)}
			</div>
		</div>
	);
}
