import styled from "styled-components";
import { theme } from "../../../../../../../../styles/theme";

export const CardContainer = styled.div`
    background-color: white;
    box-shadow: 0px 0px 11.216670989990234px 0px #00000040;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 18px;
    gap: 15px;
    @media print {
        background-color: ${theme.colors.background.modalBg};
        break-inside: avoid;
    }
`

export const ChartHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ChartTitle = styled.p`
    font-size: 1.375rem;
    color: ${theme.colors.text.titleText};
    font-weight: 600;
`;

export const ChartPeriodContainer = styled.div`
    font-size: 0.875rem;
    color: ${theme.colors.text.titleText};

`

export const ChartPeriod = styled.span`
    color: black;
    font-weight: 600;

`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

export const ChartDataContainer = styled.div`
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding-right: 5px;
    gap: 10px;
    display: flex;
    flex-direction: column;
`

export const ChartDataHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    line-height: 20px;
`;

export const ChartDataTotal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    border-bottom: 0.85px solid #D9D9D9;
    padding-bottom: 4px;
    font-size: 16px;

`;

export const ChartDataHeaderTitle = styled.span`
`;

export const ChartDataHeaderTotal = styled.span`
`;

export const ChartDataHourTotal = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ChartDataHourTitle = styled.span`
    font-weight: 400;
    color: ${theme.colors.text.titleText};
    font-size: 0.625rem;
    text-align: end;
`;

export const ChartWidth = styled.div`
    width: 90%;
`
export const ChartArrowsContainer = styled.div`
display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
`

export const ArrowsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
`


export const ChartDataBody = styled.div`
    display: flex; 
    flex-direction: column;
`;

export const ChartDataItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    border-bottom: 0.85px solid #D9D9D9;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const ChartDataRow = styled.div`
    display: flex;
    flex-direction: column;
    height: 230px;
    overflow: auto;
    padding-right: 10px;
`

export const ChartDataRowTwo = styled.div`
    display: flex;
    flex-direction: row;
`



export const ChartDatLabelContainer = styled.div`
    font-size: 0.625rem;
    color: ${theme.colors.text.titleText};
`;

export const ChartDataTitle =styled.span`
    font-size: 16px;
`;

export const ChartDataValue = styled.span`
    font-size: 16px;
`;

interface ColorDivProps {
    color: string;
}

export const ColorDiv = styled.div<ColorDivProps>`
    height: 100%;
    width: 5px;
    border-radius: 5px;
    background-color: ${({color}) => color};
`

export const ColorContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`

export const LoadingContainer = styled.div`
    height: 240px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;