import React, { useState } from 'react';

import { ReactComponent as LoginLineCenter } from "../../../../assets/svgs/loginLinesCenter.svg";
import { ReactComponent as LoginLineLeft } from "../../../../assets/svgs/loginLinesLeft.svg";
import { ReactComponent as LoginLineRight } from "../../../../assets/svgs/loginLinesRight.svg";
import { PlanusLogoDark } from '../../../../assets/svgs/iconsComponents/PlanusLogoDark';
import { GenericButton } from '../../../../components/planus2/Buttons/GenericButton';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { ErrorInputMessage } from '../../../../components/planus2/ErrorInputMessage';
import CadastroService from '../../../../services/security/cadastro';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const schema = Yup.object({
  first_name: Yup.string().required('Campo obrigatório.'),
  nome_empresa: Yup.string().required('Campo obrigatório.'),
  email: Yup.string()
    .email('Email inválido')
    .required('Campo obrigatório.'),
  password: Yup.string().required('Campo obrigatório.').min(6, 'Min. 6 caracteres'),
  password_confirm: Yup.string().required('Campo obrigatório.').oneOf([Yup.ref('password'),], 'As senhas devem ser iguais'),
  assinou_politica_uso: Yup.boolean().test('assinou_politica_uso', 'É necessário aceitar os termos', (value) => value === true),
});

type FormDataProps = {
  first_name: string;
  nome_empresa: string;
  email: string;
  password: string;
  password_confirm: string;
  assinou_politica_uso: boolean | undefined;
};

export function RegisterPage() {
  const navigate = useHistory();

	const [loading, setLoading] = useState(false);

  const defaultValues: FormDataProps = {
    first_name: '',
    nome_empresa: '',
    email: '',
    password: '',
    password_confirm: '',
    assinou_politica_uso: false,
  }

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const submit = (data: FormDataProps) => {
		setLoading(true);

    const newData = {
      ...data,
      assinou_politica_uso: (data.assinou_politica_uso || '').toString(),
    } 

		const cadastroService = new CadastroService();
		cadastroService.postCadastro(
			newData,
			() => {
				setLoading(false);
			},
			(error: any) => {
				setLoading(false);
				toast.error(error.toString());
			},
		);
	};

  const goToLogin = () => {
    navigate.push('login')
  }

  return (
    <div className='flex items-center justify-center w-full h-screen'>
      <LoginLineLeft className='absolute left-0 bottom-0 max-w-[30%]' />
      <LoginLineCenter className='absolute max-w-[60%]' />
      <LoginLineRight className='absolute right-0 top-0  max-w-[35%]' />

      <form onSubmit={handleSubmit(submit)} className='w-[57.188rem] max-w-[80%] max-h-[90%] rounded-xl bg-white z-10 overflow-y-auto'>
        <div className='flex items-center justify-center h-32 border-b border-ivory-primary'>
            <PlanusLogoDark className='w-44 h-11' />
        </div>

        <div className='flex flex-col items-center gap-4 py-10 px-10 sm:px-20 md:px-36'>
          <div className='flex flex-col gap-10 text-center'>
            <p className='font-black text-xl'>{t("Cadastro de empresa")}</p>
            <p className='font-medium text-xl leading-6'>
              Cadastre a conta da sua empresa e comece a gerenciar sua equipe.
            </p>
          </div>

          <div className='flex flex-col gap-4 w-full border-y border-ivory-primary py-5'>
            <div className='grid lg:grid-cols-2 gap-x-7 gap-y-4'>
              <div className='flex flex-col w-full'>
                <label className='mb-1' htmlFor="company">{t("Nome Empresa")}:</label>
                <input
                  className='w-full h-10 px-4 rounded-md border border-ivory-primary-lighter'
                  type="text"
                  id="nome_empresa"
                  placeholder={t("Empresa")}
                  {...register("nome_empresa")}
                />
                <ErrorInputMessage message={errors.nome_empresa?.message} />
              </div>

              <div className='flex flex-col w-full'>
                <label className='mb-1' htmlFor="name">{t("Nome Administrador")}:</label>
                <input
                  className='w-full h-10 px-4 rounded-md border border-ivory-primary-lighter'
                  type="text"
                  id="first_name"
                  placeholder={t("Administrador")}
                  {...register("first_name")}
                />
                <ErrorInputMessage message={errors.first_name?.message} />
              </div>

              <div className='flex flex-col w-full col-span-full'>
                <label className='mb-1' htmlFor="email">E-mail:</label>
                <input
                  className='w-full h-10 px-4 rounded-md border border-ivory-primary-lighter'
                  type="email"
                  id="email"
                  placeholder="Insira seu e-mail"
                  {...register("email")}
                />
                <ErrorInputMessage message={errors.email?.message} />
              </div>

              <div className='flex flex-col w-full'>
                <label className='mb-1' htmlFor="password">{t("Senha")}:</label>
                <input
                  className='w-full h-10 px-4 rounded-md border border-ivory-primary-lighter'
                  type="password"
                  id="password"
                  placeholder={t("Senha")}
                  {...register("password")}
                />
                <ErrorInputMessage message={errors.password?.message} />
              </div>
              
              <div className='flex flex-col w-full'>
                <label className='mb-1' htmlFor="re-password">{t("Confirmar senha")}:</label>
                <input
                  className='w-full h-10 px-4 rounded-md border border-ivory-primary-lighter'
                  type="password"
                  id="password_confirm"
                  placeholder={t("Confirmar senha")}
                  {...register("password_confirm")}
                />
                <ErrorInputMessage message={errors.password_confirm?.message} />
              </div>
            </div>

            <div className='flex flex-col'>
              <div>
                <input type="checkbox" id='assinou_politica_uso' {...register("assinou_politica_uso")} />
                <label htmlFor="assinou_politica_uso" className='pl-2 mb-0'>
                {t("Eu li e concordo com os")} <Link to="politicas_de_uso" target='_blank' className='underline'>{t("Termos de uso e Privacidade do site")}.</Link>
                </label>
              </div>

              <ErrorInputMessage message={errors.assinou_politica_uso?.message} />
            </div>

            <GenericButton loading={loading} disabled={loading}  className='w-full h-10 text-white bg-ivory-green'>
              {t("Cadastrar")}
            </GenericButton>
          </div>

          <div className='flex flex-col w-full text-center gap-3'>
            <p className='font-black'>Já possui uma conta?</p>

            <GenericButton loading={loading} disabled={loading} className='w-full h-10 text-white bg-ivory-primary' type='button' onClick={goToLogin}>
              {t("Efetuar Login")}
            </GenericButton>
          </div>
        </div>
      </form>
    </div>
  )
}