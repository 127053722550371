import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ProjetoService from '../../../../services/providers/project';
import TaskService from '../../../../services/providers/task';

import SeletorTimeTarefa from '../../../../components/common/SeletorTimeTarefa';
import { useKanban } from '../../../../contexts/Kanban/Kanban';
import { newItemsByColumn } from '../Kanban/functions';
import { TarefaForm } from './form';

import { ModalTarefaProps } from './types';
import { Responsible } from '../../../../models/Responsible';
import { PostPatchResponse } from '../../../../services/providers/task/types';
import { ModalWidthTarefas, SeletorTimeContainer, SeletorTimeItem, SeletorTimeRow } from './styled';
import { FormSideGenerico, ModalConteudoGenerico, ModalPrincipalGenerico } from '../../../../components/containers/Modais/ModalComSideGenerico';
import { t } from 'i18next';
import { ProjectSideForm } from '../../Projetos/ModalProjeto/form/sideForm';
import { SelectOptions } from '../../../../models/Select';

export default function ModalTarefa({
	editingTask,
	handleAfterDeleting,
	handleAfterPatch,
	handleDeletar,
	handleEncerrar,
	isVisible,
	setIsVisible,
	fromKanban,
	selectedTask,
	stateToDetail
}: ModalTarefaProps) {

	const tarefaService = new TaskService();
	const copyText = editingTask
		? `${window.location.origin}/registros/tarefas/${
				selectedTask?.id
		}/${selectedTask?.nome.replace(/\s/g, '').replace(/[/\\]/g,'')}`
		: '';

	const [loading, setLoading] = useState<boolean>(false);
	const [saveAndContinue, setSaveAndContinue] = useState<boolean>(false);
	const [newProjectSelect, setNewProjectSelect] = useState<SelectOptions>();
	const [timeSelecionado, setTimeSelecionado] = useState<number[]>([]);
	const [dataProjeto, setDataProjeto] = useState<Responsible[]>([]);
	const { columnsManager, setColumnsManager } = useKanban();

	useEffect(() => {
		if (editingTask && selectedTask) {
			_getProjetos(selectedTask?.projeto);
		}
	}, [editingTask, selectedTask]);

	const handleClickSave = useCallback(() => {
		const submitButton = document.getElementById('input-submit-tarefa');
		if(submitButton) {
			submitButton.click();
			setSaveAndContinue(false);
		}
	}, [setSaveAndContinue, document]);

	const handleClickSaveAndContinue = useCallback(() => {
		const submitButton = document.getElementById('input-submit-tarefa');
		const inputNome = document.getElementById('nome');
		if(submitButton) submitButton.click();
		if(inputNome) inputNome.focus();
		setSaveAndContinue(true);
	}, [setSaveAndContinue, document]);

	const _getProjetos = useCallback((filtroProjeto: string | number) => {
		const projetoService = new ProjetoService();
		projetoService.getDetalheProjeto(
			parseInt(filtroProjeto.toString()),
			(response) => {
				if (response.time) {
					setDataProjeto(response.time);
				}
			},
			() => {
				setDataProjeto([]);
			},
		);
	}, [setDataProjeto, ProjetoService]);

	const adicionaPessoaTime = useCallback((pessoaRecebida: Responsible) => {
		setTimeSelecionado(prevTime => [...prevTime, pessoaRecebida.id]);
	}, [setTimeSelecionado, timeSelecionado]);

	const removePessoaTime = useCallback((pessoaRecebida: Responsible) => {
		setTimeSelecionado(prevTime =>
			prevTime.filter(id => id !== pessoaRecebida.id),
		);
	}, [setTimeSelecionado, timeSelecionado]);

	const handlePatchTask = useCallback((response: PostPatchResponse, saveAndContinue: boolean | undefined = false) => {

		if (handleAfterPatch) {
			handleAfterPatch(response);
		}
		
		if((selectedTask?.projeto === columnsManager.project?.id) && response) {
			const columnId =
				selectedTask && selectedTask.coluna_kanban
				? selectedTask.coluna_kanban
				: columnsManager.columnsOrder[0];

			const tempItems = [...columnsManager.columns[columnId].items];
			const currentTaskIndex = tempItems.findIndex(
				item => item.id === selectedTask?.id,
			);
			if (response.projeto === selectedTask?.projeto) {
				tempItems[currentTaskIndex] = {...tempItems[currentTaskIndex], ...response};
			} else {
				tempItems.splice(currentTaskIndex, 1);
			}
			setColumnsManager({
				...columnsManager,
				columns: newItemsByColumn(
					columnId,
					{ ...columnsManager.columns },
					tempItems,
				),
			});
			
				
		}
		
		setIsVisible(saveAndContinue);
	}, [setIsVisible, setColumnsManager, columnsManager, newItemsByColumn, selectedTask, handleAfterPatch, editingTask, handleAfterDeleting, isVisible, setIsVisible, handleEncerrar, handleDeletar, fromKanban, stateToDetail]);

	const deleteTask = useCallback(() => {
		const columnId = selectedTask?.coluna_kanban
			? selectedTask?.coluna_kanban
			: columnsManager.columnsOrder[0];
		const id = selectedTask?.id || "";

		if(handleDeletar) {
			handleDeletar(Number(id));
		} else {

			tarefaService.deleteTarefa(
				{id: id,
				success: (response) => {
						if (response) {
							if (columnsManager.columns) {
								const tempItems = [
									...columnsManager.columns[columnId].items,
								].filter(item => item.id !== id);

								setColumnsManager({
									...columnsManager,
									columns: newItemsByColumn(
										columnId,
										{ ...columnsManager.columns },
										tempItems,
										true,
									),
								});
								toast.success('Tarefa removida com sucesso!');
							}
						}
						if (!!handleAfterDeleting && selectedTask) {
							handleAfterDeleting(selectedTask);
						}
						setIsVisible(false);
					},
				errorCallback: (error: any) => {
						toast.error(error);
						setIsVisible(false);
					},}
			);
		}
	}, [selectedTask, setColumnsManager, columnsManager,handleAfterDeleting, setIsVisible]);

	const handleAdicionar = (isSideOpen = true) => {
		const formSide = document.getElementById('side-content-generico');

		if(formSide)
			if (!isSideOpen) {
				formSide.className = 'form-content-generico-side';
			} else {
				formSide.className = 'form-content-generico-side--ACTIVED';
			}
	};

	const handleFillProject = (projectOption: SelectOptions) => {
		setNewProjectSelect(projectOption);
		_getProjetos(projectOption.value);
	}

	const handleClick = () => {
		document.getElementById('input-submit-projeto-side')?.click();
	};

	return (
		<ModalPrincipalGenerico modalGenericoShow={setIsVisible}>
			<FormSideGenerico
				title={t('Adicionar Projeto')}
				handleAdicionar={handleAdicionar}
				btnSave={true}
				onClickSave={handleClick}
				loadingBtn={loading}
			>
				<ProjectSideForm  
					fillProject={handleFillProject}
					handleLoading={setLoading}
					toogleSideModal={handleAdicionar}
				/>
			</FormSideGenerico>

			<ModalConteudoGenerico
				title={
					editingTask && selectedTask?.nome ? selectedTask?.nome : t('Criar Tarefa')
				}
				modalGenericoShow={setIsVisible}
				modalIsShowing={isVisible}
				btnSave={true}
				saveAndContinue={!editingTask}
				onClickSave={handleClickSave}
				onClickSaveAndContinue={handleClickSaveAndContinue}
				showEllipsis={editingTask}
				handleEncerrar={handleEncerrar}
				handleDeletar={deleteTask}
				itemId={editingTask ? parseInt(selectedTask?.id.toString() ?? "") : undefined}
				loadingBtn={loading}
				showCopy={true}
				copyText={copyText}
				isFinished={selectedTask?.status === 'done'}
				hour={false}
				onScroll={() => {}}
			>
				<ModalWidthTarefas>
					{editingTask ? (
						<TarefaForm
							setIsVisible={setIsVisible}
							handleLoading={setLoading}
							editing={true}
							getProjeto={_getProjetos}
							task={selectedTask}
							selectedTeam={timeSelecionado}
							handlePostPatchTarefa={handlePatchTask}
							fromKanban={!!fromKanban}
							handleAdicionar={handleAdicionar}
							selectedNewProject={newProjectSelect}
						/>
					) : (
						<TarefaForm
							setIsVisible={setIsVisible}
							handleLoading={setLoading}
							getProjeto={_getProjetos}
							// projetos filter
							saveAndContinue={saveAndContinue}
							selectedTeam={timeSelecionado}
							editing={false}
							handlePostPatchTarefa={handlePatchTask}
							fromKanban={!!fromKanban}
							handleAdicionar={handleAdicionar}
							selectedNewProject={newProjectSelect}
						/>
					)}

					<SeletorTimeContainer>
						<SeletorTimeRow>
							<SeletorTimeItem>
							{editingTask ? (
									<>
										<SeletorTimeTarefa
											ResponsaveisDaTarefaData={
												selectedTask?.pessoas_responsaveis
											}
											isEditing={true}
											pessoasDoProjetoData={dataProjeto}
											adicionaPessoaResponsavel={adicionaPessoaTime}
											removePessoaResponsavel={removePessoaTime}
										/>
									</>
								) : (
									<SeletorTimeTarefa
										ResponsaveisDaTarefaData={[]}
										pessoasDoProjetoData={dataProjeto}
										isEditing={false}
										adicionaPessoaResponsavel={adicionaPessoaTime}
										removePessoaResponsavel={removePessoaTime}
									/>
								)}
							</SeletorTimeItem>
						</SeletorTimeRow>
					</SeletorTimeContainer>
				</ModalWidthTarefas>
			</ModalConteudoGenerico>
		</ModalPrincipalGenerico>
	);
}
