import React, { useCallback, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { theme } from '../../../../styles/theme';
import { ConfirmModal } from '../../Modais/ConfirmModal';
import { ContentDiv, Th } from './styled';
import TableCellText from '../components/TableCellText';
import { t } from 'i18next';
import { FieldsHeader } from '..';

export interface ColumnHeaderProps extends FieldsHeader {
    fontSize?: string;
    customStyle?: string;
}

export default function ColumnHeader({title, tooltip, fontSize, width, deletable, customStyle, editable, id, handleDelete, handleBlur}: ColumnHeaderProps) {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const handleConfirmDelete = useCallback(() => {
        if(handleDelete !== undefined && id) {
            handleDelete(id)
        }
    }, [id, handleDelete]);

    const onBlur = useCallback((value: string | number) => {
        if(handleBlur !== undefined && id) {
            handleBlur(value.toString(), id)
        }
    }, [id, handleBlur]);

    const handleOpenConfirModal = useCallback(() => {
        setIsConfirmModalOpen(true)
    }, []);
    
    return (
        <Th width={width} fontSize={fontSize} >
            <ContentDiv customStyle={customStyle} title={tooltip}>
                {editable ? (
                    <TableCellText 
                        fontSize={fontSize}
                        key={title}
                        name={title}
                        value={title?.toString()}
                        onBlur={onBlur}
                        id={id}
                        width={width}
                        type={'text'}
                    />
                ) : (
                    t(title)
                )}

                {deletable && (
                    <IoMdClose cursor={'pointer'}  size={16} onClick={handleOpenConfirModal} color={theme.colors.button.darkBtn} />
                )}
            </ContentDiv>

			<ConfirmModal loading={false} text={t("Excluir coluna selecionada?")} handleConfirm={handleConfirmDelete} isConfirmModalOpen={isConfirmModalOpen} setIsConfirmModalOpen={setIsConfirmModalOpen} />
        </Th>
    )
}