import React, { Dispatch, ReactNode, useCallback } from 'react';

import { t } from 'i18next';
import '../../../../i18n';

import { ActionsMenu } from '../../../common/ActionsMenu';
import { Body, Footer, Header, ModalWrapper } from './styled';
import {
	ButtonSave,
	ButtonSaveContinue,
	CloseGenerico,
	LoaderGenerico,
} from '../modalStyled';

export interface ModalGenericoProps {
	btnSave: boolean;
	copyText: string;
	handleDeletar?: (itemId: number) => void;
	handleEncerrar?: (itemId: number) => void;
	handleReactivate?: (itemId: number) => void;
	itemId?: number;
	isFinished?: boolean;
	loadingBtn: boolean;
	modalGenericoShow: Dispatch<boolean>;
	modalIsShowing: boolean;
	onClickSave: () => void;
	onClickSaveAndContinue: () => void;
	saveAndContinue: boolean;
	showCopy: boolean;
	showEllipsis: boolean;
	title: string;
	children: ReactNode;
}

export default function ModalGenerico(props: ModalGenericoProps) {
	document.addEventListener('keyup', event => {
		if (event.defaultPrevented) {
			return;
		}
		const key = event.key;

		if (key === 'Escape' || key === 'Esc') {
			if (props) {
				props.modalGenericoShow(false);
			}
		}
	});

	const onFocusModal = useCallback(
		(event: React.MouseEvent) => {
			const target = event.target as HTMLInputElement;
			if (target.className === 'modal-align-generico') {
				props.modalGenericoShow(false);
			}
		},
		[props.modalGenericoShow],
	);
	const handleDelete = useCallback(() => {
		if (props.handleDeletar && props.itemId) {
			props.handleDeletar(props.itemId);
		}
	}, [props.handleDeletar]);

	const handleFinish = useCallback(() => {
		if (props.handleEncerrar && props.itemId) {
			props.handleEncerrar(props.itemId);
		}
	}, [props.handleEncerrar]);

	const handleReactivate = useCallback(() => {
		if (props.handleReactivate && props.itemId) {
			props.handleReactivate(props.itemId);
		}
	}, [props.handleReactivate]);

	const handleCloseModal = useCallback(() => {
		props.modalGenericoShow(false);
	}, [props.modalGenericoShow]);

	return (
		<>
			<ModalWrapper id="swipe-panel" onMouseDown={onFocusModal}>
				<div className="modal-align-generico">
					<div
						className="modal-content-generico"
						role={'dialog'}
						id="modal-content"
					>
						<Header>
							<p className="modal-header-generico-text">{props.title}</p>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									position: 'relative',
								}}
							>
								{props.showEllipsis && (
									<ActionsMenu
										editOption={false}
										handleDelete={handleDelete}
										handleFinish={handleFinish}
										copyOption={!!props.showCopy}
										copyText={props.copyText || ''}
										finishOption={!props.isFinished}
										handleReactivate={handleReactivate}
										reactivateOption={props.isFinished && !!props.handleReactivate}
									/>
								)}

								<CloseGenerico
									title={t("Clique ou pressione 'Esc' para fechar").toString()}
									onClick={handleCloseModal}
								>
									&times;
								</CloseGenerico>
							</div>
						</Header>

						<Body className=" modal-bg-center">{props.children}</Body>
						<Footer>
							<div
								className="modal-container-button-generico"
								style={{ visibility: props.btnSave ? 'visible' : 'hidden' }}
							>
								<ButtonSave
									title={
										props.loadingBtn
											? t('Salvando...').toString()
											: t('Salvar e Fechar').toString()
									}
									disabled={props.loadingBtn}
									onClick={props.onClickSave}
								>
									{props.loadingBtn ? (
										<LoaderGenerico></LoaderGenerico>
									) : (
										t('Salvar e Fechar')
									)}
								</ButtonSave>

								{props.saveAndContinue ? (
									<ButtonSaveContinue
										title={
											props.loadingBtn
												? t('Salvando...').toString()
												: t('Salvar e Continuar').toString()
										}
										disabled={props.loadingBtn}
										onClick={props.onClickSaveAndContinue}
									>
										{props.loadingBtn ? (
											<LoaderGenerico></LoaderGenerico>
										) : (
											t('Salvar e Continuar')
										)}
									</ButtonSaveContinue>
								) : null}
							</div>
						</Footer>
					</div>
				</div>
			</ModalWrapper>
		</>
	);
}
