import styled from "styled-components";
import { theme } from "../../../../../styles/theme";

interface TableContainerProps {
    expanded: boolean;
}

export const TableContainer = styled.div<TableContainerProps>`
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 3.235142230987549px 3.235142230987549px 0px #00000040;
    background-color: white;
    color: ${theme.colors.text.titleText};
  
    ${({expanded}) => expanded && `
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        z-index: 1;
    `}

`;

export const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    height: 56px;
    background-color: ${theme.colors.table.header};
    font-weight: normal;
    align-items: center;
    justify-content: space-between;

    /* border-radius: 6px 6px 0 0; */


`;


export const TableRowHeader = styled.tr`
    height: 70px;
`;

export const TableHeaderTitle = styled.div`
    font-weight: bold;
    padding: 10px;

`;

export const TableHeaderSwap = styled.div`
    font-weight: normal;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    `;

export const TableHeaderContent = styled.div`
    font-weight: normal;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`;

export const TableBody = styled.tbody``;


