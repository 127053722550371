import { t } from "i18next";
import { SelectOptions } from "../../models/Select"
import { UserDetails } from "../../models/UserDetails";

export function removeDuplicates(myArr: any[], prop: string) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
    })
}

export function highlightCurrentUserFilter(array: SelectOptions[], userDetails: UserDetails) {
    if(array.length === 0) {
        return array;
    }

    const tempArray = array.filter(item => item.value !== userDetails.id_pessoa);
    
    if(tempArray.length === 0) {
        return [{
            label: t("Eu"),
            value: userDetails.id_pessoa
        }]
    }

    tempArray.splice(
        tempArray?.[0].value === "" ? 1 : 0, 
        0, 
        {label: t("Eu"), value: userDetails.id_pessoa}
    )
    return tempArray;
}