import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';
import FieldFormik from '../../../../components/common/Fields/FieldFormik';
import { CicloSchema } from './validation';
import { CicloProps, CicloResponse } from '../../../../services/providers/okrs/ciclo/types';
import CicloService from '../../../../services/providers/okrs/ciclo';
import { toast } from 'react-toastify';
import { ciclosOkrsAtom } from '../../../../atoms/Okrs';
import { useSetAtom } from 'jotai';

interface CicloFormProps {
	editing: boolean;
	loading: boolean;
	ciclo?: CicloResponse;
	setLoading: (loading: boolean) => void;
	setModalShow: (loading: boolean) => void;
}

const CicloForm = ({
	editing = false,
	loading = false,
	setLoading,
	setModalShow,
	ciclo
}: CicloFormProps) => {
	const cicloService = new CicloService();
	const { t } = useTranslation();

	const tempEditing = editing && ciclo !== undefined
    const setCiclosOkrs = useSetAtom(ciclosOkrsAtom)

	const initialValues: CicloProps = tempEditing ? {
		nome: ciclo.nome,
		data_inicial: ciclo.data_inicial,
		data_final: ciclo.data_final,
	} : {
		nome: '',
		data_inicial: '',
		data_final: '',
	};

	const handleAfterPostCiclo = (cicloResponse: CicloResponse) => {
		setCiclosOkrs(prev => {
			const prevCiclos = prev.ciclosOkrs ?? []
			const tempCiclos = [cicloResponse, ...prevCiclos]

			return {
				...prev,
				ciclosOkrs: tempCiclos
			}
		})
	}

	const handleAfterPutCiclo = (cicloResponse: CicloResponse) => {
		setCiclosOkrs(prev => {
			const prevCiclos = prev.ciclosOkrs ?? []
			const tempCiclos = [...prevCiclos]
			const findIndex = tempCiclos.findIndex(item => item.id === cicloResponse.id)

			if(findIndex > -1) {
				const [removed] = tempCiclos.splice(findIndex, 1)
				tempCiclos.splice(findIndex, 0, {...removed, ...cicloResponse})
			}

			return {
				...prev,
				ciclosOkrs: tempCiclos
			}
		})
	}

	const _postCiclo = (params: CicloProps) => {
		setLoading(true);
		cicloService.postCiclo(
			params,
			response => {
				if (response) {
					toast.success(t('Ciclo salvo com sucesso!'));
					setLoading(false);
					setModalShow(false);
					handleAfterPostCiclo(response.data)
				}
			},
			() => {
				toast.error(t('Erro ao tentar salvar o ciclo!'));
				setLoading(false);
			},
		);
	};

	const _patchCiclo = (params: CicloProps, ciclo: CicloResponse) => {
		setLoading(true);
		cicloService.patchCiclo(
			ciclo.id,
			params,
			response => {
				if (response) {
					toast.success(t('Ciclo salvo com sucesso!'));
					setLoading(false);
					setModalShow(false);
					handleAfterPutCiclo(response.data)
				}
			},
			() => {
				toast.error(t('Erro ao tentar salvar o ciclo!'));
				setLoading(false);
			},
		);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={CicloSchema}
			onSubmit={values => {
				tempEditing ? _patchCiclo(values, ciclo) : _postCiclo(values);
			}}
		>
			{({ errors, touched, setFieldValue }) => {
				return (
					<FormikForm>
						<div className="row">
							<div className="col-sm-12 col-md-12">
								<FieldFormik
									title={`${t('Nome do Ciclo')}*`}
									autoComplete={'off'}
									nameField="nome"
									errors={errors.nome ? errors.nome : ''}
									touched={touched.nome ? touched.nome : false}
									name="nome"
									disabled={loading}
								/>
							</div>
							<div className="col-sm-12 col-md-6">
								<FieldFormik
									title={`${t('Data início')}*`}
									autoComplete={'off'}
									type="date"
									nameField="data_inicial"
									errors={errors.data_inicial ? errors.data_inicial : ''}
									touched={touched.data_inicial ? touched.data_inicial : false}
									name="data_inicial"
									onChange={e => {
										const target = e.target as HTMLInputElement;
										setFieldValue('data_inicial', target.value);
									}}
									disabled={loading}
								/>
							</div>
							<div className="col-sm-12 col-md-6">
								<FieldFormik
									title={`${t('Data fim')}*`}
									autoComplete={'off'}
									type="date"
									nameField="data_final"
									errors={errors.data_final ? errors.data_final : ''}
									touched={touched.data_final ? touched.data_final : false}
									name="data_final"
									onChange={e => {
										const target = e.target as HTMLInputElement;
										setFieldValue('data_final', target.value);
									}}
									disabled={loading}
								/>
							</div>
						</div>
						<input
							style={{ display: 'none' }}
							id="formSideModal-ciclo"
							type="submit"
						/>
					</FormikForm>
				);
			}}
		</Formik>
	);
};

export default CicloForm;
