import styled from 'styled-components';
import { theme } from '../../../styles/theme';

export const Label = styled.label`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	font-size: ${theme.font.sizes.text.medium};
	font-weight: 500;
	color: ${theme.colors.text.subtitleText};
	margin: 15px 0 2px 0;
`;
