import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HeaderLinkOptions, HeaderLinkProps } from './types';

import '../../../i18n';
import { useUser } from '../../../contexts/User';
import { validarPerfil } from '../../../utils/funcoes/validarPerfil';
import { ListIcon } from '../../../assets/svgs/iconsComponents/ListIcon';
import { KanbanIcon } from '../../../assets/svgs/iconsComponents/KanbanIcon';
import { ModuleIcon } from '../../../assets/svgs/iconsComponents/ModuleIcon';
import { ReportsIcon } from '../../../assets/svgs/iconsComponents/ReportsIcon';
import { ManageAccountIcon } from '../../../assets/svgs/iconsComponents/ManageAccountIcon';
import { RecordsIcon } from '../../../assets/svgs/iconsComponents/RecordsIcon';
import { FaArrowsRotate } from 'react-icons/fa6';
import { RxFileText } from 'react-icons/rx';
import { BsLink45Deg } from 'react-icons/bs';
import { TeamsIcon } from '../../../assets/svgs/iconsComponents/TeamsIcon';
import { HomeIcon } from '../../../assets/svgs/iconsComponents/HomeIcon';
import { GraphIcon } from '../../../assets/svgs/iconsComponents/GraphIcon';

const HeaderLink = ({ type = 'registro', projectId }: HeaderLinkProps) => {
	const { t } = useTranslation();
	const [userAccessCode, setUserAccessCode] = useState('');
	const { userDetails } = useUser();
	useEffect(() => {
		getUserdetails();
	}, [userDetails]);

	const getUserdetails = () => {
		if (userDetails !== null) {
			setUserAccessCode(userDetails.acesso_cod);
		}
	};

	const userValidation = () => {
		return userAccessCode === 'g' || userAccessCode === 'a';
	};

	let headerLink: HeaderLinkOptions = {
    home: [
			{
				tour: '',
				link: '/home/calendar',
        icon: HomeIcon,
				text: t('Início'),
			},
			// {
			// 	tour: 'react-tour__tarefa',
			// 	link: '/home/reports',
      //   icon: ReportsIcon,
			// 	text: t('Relatórios'),
			// },
			{
				tour: 'react-tour__hora',
				link: '/home/graphs',
        icon: GraphIcon,
				text: t('Gráficos'),
			},
		],
		registro: [
			{
				tour: '',
				link: '/registros/projetos',
				text: t('Gráficos'),
			},
			{
				tour: 'react-tour__tarefa',
				link: '/registros/tarefas',
				text: t('Tarefas'),
			},
			{
				tour: 'react-tour__hora',
				link: '/registros/horas',
				text: t('Horas'),
			},
		],
		relatorio: [
			{
				tour: '',
				link: '/relatorios',
				text: t('Relatório geral'),
				icon: ReportsIcon,
			},
		],
		task: [
			{
				tour: 'react-tour__tarefa',
				link: '/registros/tarefas',
				text: t('Lista'),
				icon: ListIcon,
			},
			{
				tour: 'react-tour_kanban-header',
				link: '/registros/kanban',
				text: t('Kanban'),
				icon: KanbanIcon,
			},
		],
		hour: [
			{
				link: '/registros/horas',
				text: t('Horas'),
				tour: '',
				icon: RecordsIcon,
			},
		],
		person: [
			{
				link: '/pessoas',
				text: t('Pessoas'),
				tour: '',
				icon: ManageAccountIcon,
			},
		],
		project: [
			{
				link: '/registros/projetos',
				text: t('Modular'),
				tour: '',
				icon: ModuleIcon,
			},
			{
				link: '/registros/projetos-list',
				text: t('Lista'),
				tour: '',
				icon: ListIcon,
			},
		],
		projectWithReport: [
			{
				link: '/registros/projetos',
				text: t('Modular'),
				tour: '',
				icon: ModuleIcon,
			},
			{
				link: '/registros/projetos-list',
				text: t('Lista'),
				tour: '',
				icon: ListIcon,
			},
			{
				link: '/registros/projetos-kanban',
				text: t('Kanban'),
				tour: '',
				icon: KanbanIcon,
			},
			{
				link: '/relatorios_projeto',
				text: t('Relatório de projetos'),
				tour: 'react-tour__projects-report',
				icon: ReportsIcon,
			},
		],
		projectDetails: [
			{
				link: `/registros/projetos/${projectId}/parametros`,
				text: t('Parâmetros'),
				tour: '',
				icon: RxFileText,
			},
			{
				link: `/registros/projetos/${projectId}/time`,
				text: t('Time'),
				tour: '',
				icon: TeamsIcon,
			},
			{
				link: `/registros/projetos/${projectId}/anexos`,
				text: t('Anexos'),
				tour: '',
				icon: BsLink45Deg,
			},
		],
		okrs: [
			{
				link: '/okrs/times',
				text: t('Lista'),
				tour: '',
				icon: ListIcon,
			},
			{
				link: '/okrs/kanban',
				text: t('Kanban'),
				tour: '',
				icon: KanbanIcon,
			},
		],
		client: [
			{
				tour: '',
				link: '/clientes',
				text: t('Clientes'),
			},
		],
	};

	if (validarPerfil(userDetails?.acesso_cod)) {
		headerLink = {
			...headerLink,
			okrs: [
				...headerLink.okrs,
				{
					link: '/okrs/ciclos',
					text: t('Ciclos'),
					tour: '',
					icon: FaArrowsRotate,
				},
			],
		};
	}

	const buildLink = () => {
		if (type === 'project' && userValidation()) {
			return headerLink.projectWithReport;
		} else {
			return headerLink[type];
		}
	};

	const link = buildLink();
	return (
		<div className="flex border-b border-ivory-primary">
			<div className="flex w-full translate-y-[1px]">
				{link.map((item) => {
					const Icon = item.icon;
					return (
						<NavLink
							className={`${item.tour} flex min-w-[10rem] h-12 px-4 gap-2 items-center justify-center rounded-t-md text-ivory-primary `}
							to={item.link}
							key={item.link}
							activeClassName="active-sidebar-button border border-b-2 border-x-ivory-primary border-t-ivory-primary border-b-white hover:no-underline"
						>
							{Icon && (
								<Icon className={'svg-icon-style h-5 w-5 fill-ivory-primary'} />
							)}
							<p className="svg-icon-style text-xl font-medium">{item.text}</p>
						</NavLink>
					);
				})}
			</div>
		</div>
	);
};

export default HeaderLink;
