import styled from "styled-components";

interface BodyContainerProps {
    expandTable: boolean;
}

export const BodyContainer = styled.div<BodyContainerProps>`
    display: grid;
    position: relative;
    grid-template-columns: auto 2fr;
    gap: 20px;
    ${({expandTable}) => expandTable && 'height: 100%;'}
    @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
    }
    @media print {
        display: flex;
        flex-direction: column;
    }
`

export const DisplayChartMobile = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    min-height: 220px;
    
`

