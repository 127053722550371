import React, { useEffect, useState } from "react";
import { RowWrapper } from "../../../../components/tailwindCommon/Table/RowWrapper";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { GrTarget } from "react-icons/gr";
import { MdEdit } from "react-icons/md";
import { KeyResultsRow } from "../KeyResultsRow";
import { ObjetivoResponse } from "../../../../services/providers/okrs/objetivo/types";
import { KeyResultResponse } from "../../../../services/providers/okrs/keyresult/types";
import { NotFoundRow } from "../../../../components/tailwindCommon/Table/NotFoundRow";
import { ModalKeyResult } from "../../ModalKeyResult";
import { LoadingRow } from "../../../../components/tailwindCommon/Table/LoadingRow";
import { keyresultsManagerAtom, objetivoModalAtom, openObjectsAtom } from "../../../../atoms/Okrs";
import { useAtom, useSetAtom } from "jotai";
import { LoadMoreRow } from "../../../../components/tailwindCommon/Table/LoadMoreRow";
import moment from "moment";

interface ObjetivosRowProps {
  readonly objetivo: ObjetivoResponse
  readonly getKeyResultsPagination: (page: number, objetivoId: number) => Promise<void>
  readonly refreshProgresso: ({ timeId, objetivoId }: { timeId: number, objetivoId?: number }) => void
}

export function ObjetivosRow({ objetivo, getKeyResultsPagination, refreshProgresso }: ObjetivosRowProps) {
  const [openObjects, setOpenObjects] = useAtom(openObjectsAtom)
  const isKeyResultsOpen = openObjects.includes(objetivo.id)
  const [loadingKeyResults, setLoadingKeyResults] = useState(false)
  const [keyResults,] = useAtom(keyresultsManagerAtom)

  const [editingKeyResult, setEditingKeyResult] = useState(false);
  const [selectedKeyResult, setSelectedKeyResult] = useState<KeyResultResponse>();
  const [isKeyResultModalVisible, setIsKeyResultModalVisible] = useState(false);

  const objetivoWidthLimitMax = objetivo.progresso <= 100 ? objetivo.progresso : 100
  const objetivoProgresso = objetivoWidthLimitMax < 0 ? 0 : objetivoWidthLimitMax

  const setObjetivoModalState = useSetAtom(objetivoModalAtom)

  const objetivoModalEdit = () => {
    setObjetivoModalState({
      editing: true,
      selectedItem: objetivo,
      isModalOpen: true
    })
  };

  const keyresultModal = () => {
    setSelectedKeyResult(undefined)
    setEditingKeyResult(false)
    setIsKeyResultModalVisible(true)
  };

  const keyresultModalEdit = (keyResult: KeyResultResponse) => {
    setSelectedKeyResult(keyResult)
    setEditingKeyResult(true)
    setIsKeyResultModalVisible(true)
  };

  const handlePagination = (page: number) => {
    setLoadingKeyResults(true)
    getKeyResultsPagination(page, objetivo.id).finally(() => {
      setLoadingKeyResults(false)
    })
  }

  const handleOpenObject = () => {
    setOpenObjects(prev => {
        if(prev.includes(objetivo.id)) {
            return prev.filter(item => item !== objetivo.id)
        }else {
            return [...prev, objetivo.id]
        }
    })
}

  useEffect(() => {
    if (isKeyResultsOpen) {
      handlePagination(keyResults.keyresultsManager[objetivo.id]?.currentPage ?? 1)
    }
  }, [isKeyResultsOpen])

  return (
    <div>
      <RowWrapper className={`${isKeyResultsOpen ? 'bg-ivory-okr-bg-2' : 'bg-white'} border-y border-y-border-gray border-solid  text-ivory-primary font-medium h-auto min-h-[3.5rem]`}>
        <button className='col-span-6 flex items-center gap-2 pl-7' onClick={() => handleOpenObject()}>
          <div className="flex gap-2">
            {isKeyResultsOpen ? (
              <FaCaretDown className='h-5 w-5 ' />
            ) : (
              <FaCaretUp className='h-5 w-5 ' />
            )}

            <GrTarget className='h-5 w-5 ' />
          </div>

          <div>
            <p className="text-left">{objetivo.nome}</p>
          </div>
        </button>

        <div className='col-span-2 flex items-center gap-2'>
          <div className='relative w-3/5 flex items-center'>
            <div className='h-3 w-full bg-slate-300 rounded-full'></div>
            <div className={`absolute left-0 h-3 bg-ivory-green rounded-full`} style={{ width: `${objetivoProgresso}%` }}></div>
          </div>
          <p className={`min-w-[44px] text-right ${objetivoProgresso >= 100 ? "text-ivory-green font-bold" : ""}`}>{objetivoProgresso}%</p>
        </div>

        <div className='col-span-1'>
          {objetivoProgresso >= 100 && objetivo.finalizado_em && (
            <p className="text-left">Finalizado: {moment(objetivo.finalizado_em).format("DD/MM/YYYY")}</p>
          )}
        </div>

        <div className='col-span-1 flex items-center'>
          <button type="button" className='flex items-center justify-center p-0 m-0 w-7 h-7 bg-ivory-green rounded-md' onClick={objetivoModalEdit}>
            <MdEdit className='w-5 h-5 text-white' />
          </button>
        </div>
      </RowWrapper>

      {isKeyResultsOpen && (
        <div>
          <KeyResultsRow
            keyresults={keyResults.keyresultsManager[objetivo.id]?.keyresults ?? []}
            keyresultModal={keyresultModal}
            keyresultModalEdit={keyresultModalEdit}
          />

          {keyResults.keyresultsManager[objetivo.id]?.keyresults?.length === 0 && !loadingKeyResults && (
            <NotFoundRow text="Nenhum key result encontrado." />
          )}

          {loadingKeyResults && (
            <LoadingRow />
          )}

          {keyResults.keyresultsManager[objetivo.id]?.totalPages !== keyResults.keyresultsManager[objetivo.id]?.currentPage && (
            <LoadMoreRow
              handlePagination={() => handlePagination((keyResults.keyresultsManager[objetivo.id]?.currentPage ?? 0) + 1)}
              hideLoader={false}
              loading={loadingKeyResults}
            />
          )}
        </div>
      )}

      {isKeyResultModalVisible && (
        <ModalKeyResult
          timeId={objetivo.time}
          setModalShow={setIsKeyResultModalVisible}
          objetivoId={objetivo.id}
          editingKeyResult={editingKeyResult}
          keyresult={selectedKeyResult}
          getKeyresultPagination={getKeyResultsPagination}
          refreshProgresso={refreshProgresso}
        />
      )}
    </div>
  )
}