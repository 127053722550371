import React, { ChangeEvent, FocusEvent, InputHTMLAttributes, useEffect, useState} from 'react';
import { Input, InputProps, Span } from './styled';

export interface BordedInput extends InputHTMLAttributes<HTMLInputElement>, InputProps {
  mask?: string;
}

export function CustomInput({ mask="", isCurrency, onBlur, onChange, onFocus, value, type, ...props}: BordedInput) {
  const [tempValue, setTempValue] = useState<string | number | readonly string[] | undefined>("");

  const handleCurrencyOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const formatedValue = formatLocaleNumberStrings(value?.toString() ?? "")

    setTempValue(formatedValue);
    
    if(onBlur) {
      onBlur({
        ...e,
        target: {...e.target, value: value as string},
      });
    }
  }

  const handleCurrencyOnFocus = () => {
    setTempValue(value);
  }

  const handleCurrencyOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let formatedValue = inputValue.replace(/[^0-9,.]/g, '');
    formatedValue = formatedValue.replace(",", '.');

    if(formatedValue.slice(0, -1).includes(".") && formatedValue.endsWith(".")) {
      formatedValue = formatedValue.slice(0, -1)
    }

    if(onChange) {
      onChange({
        ...e,
        target: {...e.target, value: formatedValue}
      });
    }

    setTempValue(formatedValue)
  }

  useEffect(() => {
    if(value) {
      if(isCurrency){
        if(document.activeElement?.id === props.id){
          setTempValue(value)
        }else {
          setTempValue(formatLocaleNumberStrings(value?.toString() || ""))
        }
      }
    }
  }, [value, isCurrency, props.id])

  return (
    <div  style={{position: "relative"}}>
      <Input 
        mask={mask}
        type={isCurrency ? "text" : type} 
        value={isCurrency ? tempValue : value}
        onBlur={isCurrency ? handleCurrencyOnBlur : onBlur} 
        onFocus={isCurrency ? handleCurrencyOnFocus : onFocus} 
        onChange={isCurrency ? handleCurrencyOnChange : onChange} 
        isCurrency={isCurrency}
        {...props} 
      />
      {isCurrency && (
        <Span >R$ </Span>
      )}
    </div>
  )
}

function formatLocaleNumberStrings(value: string){
    return formatToPtBr(value)
}

function formatToPtBr(value: string){
    return Number(value)?.toLocaleString('pt-br', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})
}