import React, {
	useContext,
	useMemo,
	useState,
	createContext
} from 'react';
import { ProjectReport } from '../../../models/Project';
import ProjetoService from '../../../services/providers/project';
import { DataType } from '../../../services/providers/types';
import formatArrayToFilter from '../../../utils/funcoes/formatArrayToFilter';
import formatObjectToFilter from '../../../utils/funcoes/formatObjectToFilter';
import { useProjectRFilters } from '../../Filter/ProjectReport';
import { ProjectReportInitialType, ProjectReportProviderProps } from './types';

export const initialValues: ProjectReportInitialType = {
	projectData: [],
	setProjectData: () => {},
	getProjectData: () => {},
	loading: false,
	setLoading: () => {},
	currentPage: 1,
	setCurrentPage: () => {},
	totalPages: null,
	setTotalPages: () => {},
};

const ProjectReportContext = createContext(initialValues);

const ProjectReportProvider = ({ children }: ProjectReportProviderProps) => {
	const [projectData, setProjectData] = useState(initialValues.projectData);
	const [loading, setLoading] = useState(initialValues.loading);
	const [currentPage, setCurrentPage] = useState(initialValues.currentPage);
	const [totalPages, setTotalPages] = useState(initialValues.totalPages);

	const {
		checkClosed,
		search,
		selectedClient,
		selectedPerson,
		selectedProject,
		selectedTag,
	} = useProjectRFilters();

	const getProjectData = (page = 1, signal?: AbortSignal) => {
		const projectService = new ProjetoService();
		setLoading(true);
		const tags = checkClosed ? '' : formatArrayToFilter(selectedTag);
		projectService.getProjetosRelatorio(
			{
			page: page,
			id:formatObjectToFilter(selectedProject),
			client: formatObjectToFilter(selectedClient),
			tags: tags,
			encerrados: !checkClosed,
			admin: formatObjectToFilter(selectedPerson),
			search: search
			},
			(response: DataType<ProjectReport[]>) => {
				if (response) {
						if (page === 1) {
							setProjectData(response.results);
						} else {
							setProjectData(prevData => [...prevData, ...response.results]);
						}
						setCurrentPage(page);
						setTotalPages(response.total_pages);
				}
				if(!response?.cancelled){
					setLoading(false);
				}
			},
			() => {
				setLoading(false);
			},
			{ signal },
		);
	};



	const reportValue = useMemo(
		() => ({
			projectData,
			setProjectData,
			loading,
			setLoading,
			currentPage,
			setCurrentPage,
			totalPages,
			setTotalPages,
			getProjectData,
		}),
		[
			projectData,
			setProjectData,
			loading,
			setLoading,
			currentPage,
			setCurrentPage,
			totalPages,
			setTotalPages,
			getProjectData,
		],
	);
	return (
		<ProjectReportContext.Provider value={reportValue}>
			{children}
		</ProjectReportContext.Provider>
	);
};

const useProjectReport = () => {
	const context = useContext(ProjectReportContext);
	return context;
};

export { ProjectReportProvider, useProjectReport };
