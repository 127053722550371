import styled from 'styled-components';

export const Container = styled.label`
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: #426172;
	opacity: 1;
	margin: 12px 0;
`;
