import React, { HtmlHTMLAttributes } from "react"
import { twMerge } from "tailwind-merge"

interface RowWrapperProps extends HtmlHTMLAttributes<HTMLDivElement> {

}

export function RowWrapper({className, ...props}: RowWrapperProps) {
    const classes = twMerge(
        `grid grid-cols-10 gap-4 h-14 border-y border-y-white px-4 items-center text-ivory-primary font-medium `,
        className
    )

    return (
        <div className={classes} {...props}></div>
    )
}