import React, { useEffect, useState, ReactNode, useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';
import Tooltip from '@atlaskit/tooltip';

import { t } from 'i18next';
import '../../../i18n';

import Search from '../../../components/common/Searchs/Search';
import {
	CenterFilterContainer,
	DivBar,
	FilterPickerContainer,
	FixedFilterContainer,
	OpenFiltersContainer,
	OpenFiltersContent,
	PageContainer,
	SearchContainer,
} from './styled';
import { SelectOptions } from '../../../models/Select';
import FilterSelect from './FilterSelect';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

interface FilterContainerProps {
	handleSearch: () => void;
	searchFilter: string;
	setSearchFilter: React.Dispatch<string>;
	children?: ReactNode;
	selectedFilters: any;
	loadingFilters: boolean;
	filterOptions: SelectOptions[];
	openFilters: SelectOptions[];
	setOpenFilters: React.Dispatch<React.SetStateAction<SelectOptions[]>>;
	resetFilters?: () => void;
	kanbanProject?: ReactNode;
	search?: boolean;
}

const FilterContainer = ({
	handleSearch,
	setSearchFilter,
	searchFilter,
	children,
	selectedFilters,
	loadingFilters,
	filterOptions,
	openFilters,
	setOpenFilters,
	kanbanProject,
	resetFilters,
	search = true,
}: FilterContainerProps) => {
	const isOverflown = (scrollWidth: number = 0, clientWidth: number = 0) => {
		return scrollWidth > clientWidth;
	};
	const checkOverflow = () => {
		const filtersContainer = document.querySelector('.scroll-filters');
		const overflow = isOverflown(
			filtersContainer?.scrollWidth,
			filtersContainer?.clientWidth,
		);
		return overflow;
	};

	const [overflow, setOverflow] = useState<boolean>(checkOverflow());

	useEffect(() => {
		handleScreenResize();
		window.addEventListener('resize', handleScreenResize);

		return () => {
			window.removeEventListener('resize', handleScreenResize);
		};
	}, []);

	const handleScreenResize = () => {
		setOverflow(checkOverflow());
	};

	useEffect(() => {
		setOverflow(checkOverflow());
	}, [selectedFilters, openFilters]);

	const [start, setStart] = useState(100);
	const [time, setTime] = useState<any>(undefined);

	const leftScroll = () => {
		const left = document.querySelector('.scroll-filters');
		left?.scrollBy(-50, 0);
	};
	const rightScroll = () => {
		const right = document.querySelector('.scroll-filters');
		right?.scrollBy(50, 0);
	};

	const repeatRight = () => {
		rightScroll();
		setTime(setTimeout(repeatRight, start));
		setStart((prev) => prev / 2);
	};

	const onMouseDownRight = useCallback(() => {
		repeatRight();
	}, [repeatRight, rightScroll, time, start]);

	const repeatLeft = () => {
		leftScroll();
		setTime(setTimeout(repeatLeft, start));
		setStart((prev) => prev / 2);
	};

	const onMouseDownLeft = useCallback(() => {
		repeatLeft();
	}, [repeatLeft, leftScroll, time, start]);

	const onMouseUp = useCallback(() => {
		clearTimeout(time);
		setStart(100);
	}, [time]);

	return (
		<PageContainer
			search={search}
			loadingFilters={loadingFilters}
			kanban={!!kanbanProject}
		>
			{search && (
				<SearchContainer kanban={!!kanbanProject}>
					<Search
						getFunction={handleSearch}
						setSearch={setSearchFilter}
						search={searchFilter}
						kanban={true}
					/>
					{filterOptions.length > 0 && (
						<DivBar />
					)}
				</SearchContainer>
			)}
			{filterOptions.length > 0 && (
			<>
			<CenterFilterContainer kanban={!!kanbanProject}>
				{!!kanbanProject && kanbanProject}

				<OpenFiltersContainer kanban={!!kanbanProject}>
					{overflow && (
						<FontAwesomeIcon
							cursor={'pointer'}
							icon={faCaretLeft}
							color={theme.colors.text.titleText}
							onMouseDown={onMouseDownLeft}
							onMouseUp={onMouseUp}
						/>
					)}
					<OpenFiltersContent
						className="scroll-filters"
						kanban={!!kanbanProject}
					>
						{children}
					</OpenFiltersContent>

					{overflow && (
						<FontAwesomeIcon
							cursor={'pointer'}
							icon={faCaretRight}
							color={theme.colors.text.titleText}
							onMouseUp={onMouseUp}
							onMouseDown={onMouseDownRight}
						/>
					)}

					{resetFilters && (
						<FixedFilterContainer>
							<Tooltip content={t('Limpar filtros')}>
								<IoMdClose
									cursor={'pointer'}
									size={25}
									onClick={resetFilters}
									color={theme.colors.button.darkBtn}
								/>
							</Tooltip>
						</FixedFilterContainer>
					)}
				</OpenFiltersContainer>
			</CenterFilterContainer>
			
				<FilterPickerContainer kanban={!!kanbanProject}>
					<DivBar />
					<FilterSelect
						filterOptions={filterOptions}
						openFilters={openFilters}
						setOpenFilters={setOpenFilters}
					/>
				</FilterPickerContainer>
			</>
			)}
		</PageContainer>
	);
};

export default FilterContainer;
