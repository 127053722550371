import React, { ForwardedRef, ReactNode, forwardRef, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import HeaderLink from '../../common/HeaderLink';

import animationData from '../../../assets/animacao/planus-animation-primeiroacesso-2.json';
import Lottie from 'react-lottie';
import { useFilterCommonState } from '../../../contexts/Filter/FilterCommonState';
import { AnimacaoRelatorio, Filter, FilterContainer } from './styled';
import { t } from 'i18next';

export interface PageContainerRelatorioProps {
	children: ReactNode;
	filter: ReactNode;
	filtroShadow: boolean;
}

const PageContainerRelatorio = forwardRef(({ children, filter, filtroShadow = true}: PageContainerRelatorioProps, ref: ForwardedRef<HTMLDivElement>) => {
	const { isFilterOpen, setIsFilterOpen } = useFilterCommonState();
	const handleToggleFilter = useCallback(() => setIsFilterOpen(!isFilterOpen), [setIsFilterOpen, isFilterOpen]);
	
	return (
		<>
			<HeaderLink type="relatorio" />
			<div
				className="registros-container-geral"
				style={{
					gridTemplateColumns: isFilterOpen ? '75% 25%' : '99.5% 0.5%',
					transition: 'all 2s',
				}}
			>
				<div className="registros-container-relatorios print-background" ref={ref}>
					<div className={`mt-5 lg:w-[calc(100%-44px)] w-full`}>
						<FilterMobile filter={filter} handleToggleFilter={handleToggleFilter} isFilterOpen={isFilterOpen} />
						{children}
						{!children ? (
							<AnimacaoRelatorio >
								<Lottie
									options={{
										animationData: animationData,
										loop: true,
									}}
								/>
							</AnimacaoRelatorio>
						) : null}
					</div>
				</div>
				<FilterContainer
					hasShadow={filtroShadow}
					className={`relatorio-filtrar shadow-md`}
				>
					<button
						onClick={handleToggleFilter}
						className="btn-expandir-filter"
						style={{
							right: isFilterOpen ? '25%' : '0.5%',
						}}
					>
						<FontAwesomeIcon
							style={{ marginTop: '5px' }}
							icon={faSearch}
							className="expandir-icon"
						/>
					</button>
					<div className={`relatorio-filtrar-title ${isFilterOpen ? '' : 'invisible'}`}>{t("Filtrar")}</div>
					<Filter filterOpen={isFilterOpen}>{filter}</Filter>
				</FilterContainer>
			</div>
		</>
	);
})

interface FilterMobileProps {
	handleToggleFilter: () => void;
	isFilterOpen: boolean;
	filter: ReactNode;
}

const FilterMobile = ({handleToggleFilter, isFilterOpen, filter}: FilterMobileProps) => (
	<div className="registros-btn-filtrar bg-ivory-lighter">
		<div className="registros-btn-mobile lg:hidden ">
			<button
				className="registros-btn-open"
				onClick={handleToggleFilter}
			>
				Filtros
				{isFilterOpen ? (
					<FontAwesomeIcon icon={faTimes} className="horas-icon-filter" />
				) : (
					<FontAwesomeIcon icon={faFilter} className="horas-icon-filter" />
				)}
			</button>
			<div
				className="registros-filter-mobile"
				style={{
					height: !isFilterOpen ? 'auto' : '0px',
					overflow: !isFilterOpen ? 'unset' : 'hidden',
				}}
			>
				{filter}
			</div>
		</div>
	</div>
);

PageContainerRelatorio.displayName = "PageContainerRelatorio"

export default PageContainerRelatorio;
