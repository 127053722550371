import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const HistoryContainer = styled.div`
	width: 100%;
	margin: 17px 0 0;
	display: grid;
	grid-template-columns: 13% 82%;
	grid-gap: 18px 50px;
`;

export const InfoHistory = styled.div`
	color: #a0b0b8;
	font-weight: 500;
	font-size: 16px;
`;

export const InfoHistoryIcon = styled(FontAwesomeIcon)``;

export const TimeLabel = styled.span`
	display: flex;
	font-weight: 500;
	opacity: 0.6;
	font-size: 12px;
`;

export const TimeContainer = styled.div`
	font-weight: normal;
`;

export const HistoryBoxComment = styled.div`
	width: 100%;
`;

export const HistoryBoxHour = styled.div`
	align-self: center;
	width: 100%;
`;

export const PessoaContainer = styled.div`
	border-radius: 4px;
	display: flex;
	align-items: center;
	width: 100%;
`;

export const PessoaContent = styled.div`
	display: flex;
	flex: 1;
	justify-content: space-between;
`;

export const PessoaInfoContainer = styled.div`
	display: flex;
`;

export const PessoaImage = styled.img`
	border-radius: 50%;
	height: 23px;
	width: 23px;
`;

export const PessoaNome = styled.p`
	white-space: nowrap;
	margin: 0 5px 0 10px;
	color: #426172;
	font-weight: 700;
`;

export const ActionsMenuContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const SubmitButton = styled.button`
	display: none;
`;

interface EditInputProps {
	editing?: boolean;
}

export const CommentInput = styled.input<EditInputProps>`
	border: ${({ editing }) => (editing ? `1px solid #c0cfd8d9` : `none`)};
	background: #ffff;
	resize: none;
	border-radius: 7px;
	display: block;
	width: 95%;
	height: 10%;
	min-height: 44px;
	font-size: 14px;
	padding: 7px;
	outline: none;
	box-shadow: none;
	transition: 0.3s ease;
	color: #355060;
`;

interface HourDescriptionContainerProps {
	hasDescription?: boolean;
}

export const HourDescriptionContainer = styled.div<
	HourDescriptionContainerProps
>`
	display: ${({ hasDescription }) => (hasDescription ? 'flex' : 'box')};
`;

export const HourHistoryDetails = styled.span`
	font-size: 13px;
	font-weight: 500;
	color: #a0b0b8;
	align-self: center;
`;

export const HourDetail = styled.span`
	margin-left: 2px;
	font-weight: normal;
	padding-left: 3px;
`;

export const HourDescription = styled.div`
	color: #426172;
	font-weight: 400;
	display: flex;
`;

export const ActionsContainer = styled.div`
	display: flex;
	align-items: flex-start;
`;
