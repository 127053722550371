import moment from 'moment';

interface PickTimeIntervalReturn {
	startDate: string;
	endDate: string;
}

export const pickTimeInterval = (
	option: string,
	diaAux?: string,
): PickTimeIntervalReturn => {
	switch (option) {
		case 'today':
			return handleToday();
		case 'yesterday':
			return handleYestarday();
		case 'thisWeek':
			return handleThisWeek();
		case 'lastWeek':
			return handleLastWeek();
		case 'thisMonth':
			return handleThisMonth();
		case 'lastMonth':
			return handleLastMonth();
		case 'thisYear':
			return handleThisYear();
		case 'lastYear':
			return handleLastYear();
		case 'weekNavigation':
			return handleWeekNavigation(diaAux || '');
		case 'nextWeek':
		default:
			return handleToday();
	}
};

export const getWeekDays = (day: string) => {
	const dayDate = moment(day);
	const weekLength = [0, 1, 2, 3, 4, 5, 6];
	const startDate = dayDate
		.clone()
		.weekday(0)
		.format("YYYY-MM-DD");
	const endDate = dayDate
		.clone()
		.weekday(6)
		.format("YYYY-MM-DD");
	const weekDays = weekLength.map(number => {
		return {
			diaSemana: dayDate
				.clone()
				.weekday(number)
				.format('ddd'),
			data: dayDate
				.clone()
				.weekday(number)
				.format("YYYY-MM-DD"),
		};
	});
	return { weekDays, initialDate: startDate, finalDate: endDate };
};

const handleWeekNavigation = (diaAux: string) => {
	const day = moment(diaAux);
	const weekStart = day
		.clone()
		.weekday(1)
		.format("YYYY-MM-DD");
	return {
		startDate: weekStart,
		endDate: day.format("YYYY-MM-DD"),
	};
};

const handleToday = () => {
	const today = `${moment().format("YYYY-MM-DD")}T00:00`;
	return {
		startDate: today,
		endDate: today,
	};
};

const handleYestarday = () => {
	const yestarday = `${moment()
		.subtract(1, 'days')
		.format("YYYY-MM-DD")}T00:00`;
	return {
		startDate: yestarday,
		endDate: yestarday,
	};
};

const handleThisWeek = () => {
	const today = moment();
	const weekStart = `${today
		.clone()
		.weekday(0)
		.format("YYYY-MM-DD")}T00:00`;

	const weekEnd = `${today
		.clone()
		.weekday(6)
		.format("YYYY-MM-DD")}T00:00`;
	return {
		startDate: weekStart,
		endDate: weekEnd,
	};
};

const handleLastWeek = () => {
	const lastWeek = moment().subtract(7, 'days');
	const weekStart = `${lastWeek
		.clone()
		.weekday(0)
		.format("YYYY-MM-DD")}T00:00`;
	const weekEnd = `${lastWeek
		.clone()
		.weekday(6)
		.format("YYYY-MM-DD")}T00:00`;
	return {
		startDate: weekStart,
		endDate: weekEnd,
	};
};

const handleThisMonth = () => {
	const today = moment();
	const monthStart = `${moment(today)
		.startOf('month')
		.format("YYYY-MM-DD")}T00:00`;
	const monthEnd = `${moment(today)
		.endOf('month')
		.format("YYYY-MM-DD")}T00:00`;
	return {
		startDate: monthStart,
		endDate: monthEnd,
	};
};

const handleLastMonth = () => {
	const lastMonth = moment().subtract(1, 'months');
	const monthStart = `${moment(lastMonth)
		.startOf('month')
		.format("YYYY-MM-DD")}T00:00`;
	const monthEnd = `${moment(lastMonth)
		.endOf('month')
		.format("YYYY-MM-DD")}T00:00`;
	return {
		startDate: monthStart,
		endDate: monthEnd,
	};
};

const handleThisYear = () => {
	const yearStart = `${moment()
		.startOf('year')
		.format("YYYY-MM-DD")}T00:00`;
	const yearEnd = `${moment()
		.endOf('year')
		.format("YYYY-MM-DD")}T00:00`;

	return {
		startDate: yearStart,
		endDate: yearEnd,
	};
};

const handleLastYear = () => {
	const lastYear = moment().subtract(1, 'years');
	const yearStart = `${moment(lastYear)
		.startOf('year')
		.format("YYYY-MM-DD")}T00:00`;
	const yearEnd = `${moment(lastYear)
		.endOf('year')
		.format("YYYY-MM-DD")}T00:00`;

	return {
		startDate: yearStart,
		endDate: yearEnd,
	};
};
