import React from 'react';
import styled from 'styled-components';
import { SelectOptions } from '../../../../../models/Select';
import defaultImage from '../../../../../assets/images/company.png';
import { Radio } from '@mui/material';

const ProjectOptionWrapper  = styled.div`
	display: flex;
	align-items: center;
	padding: 12px 0;
	margin: 0 24px;
	gap: 24px;
	border-bottom: 1px solid rgba(66, 97, 114, 0.3);
	font-weight: 400;
	width: 100%;

	img {
		width: 100%;
		max-width: 36px;
		max-height: 36px;
		object-fit: contain;
	}
	
	.label-content {
		display: flex;
		flex-direction: column;

		.client-span {
			font-size: 0.688rem;
			font-weight: 700;
		}	
	}
	
`;

const ProjectRequired = styled.div`
	display: grid;
	grid-template-columns: 30px 1fr;
	gap: 16px;
	align-items: center;
	width: 100%;
	margin: 0 24px;
	border-bottom: 1px solid rgba(66, 97, 114, 0.3);
`;

export interface ProjectOptionProps {
	data: SelectOptions;
	isSelected: boolean;
}

export const ProjectOption = (props: ProjectOptionProps) => {
	const {data, isSelected} = props;
	const image = data.image ? data.image : defaultImage;
	return (
		<>
			{data.isDisabled ? (
				<ProjectRequired>
					<Radio checked={isSelected} disabled/>
					<span>{data.label}</span>
				</ProjectRequired>
			) : (
				<ProjectOptionWrapper>
					<img src={image} alt="" />
					<div className='label-content'>
						<span>{data.label}</span>
						<span className='client-span'>{data.labelAux}</span>
					</div>	
				</ProjectOptionWrapper>
			)}
		</>
	);
};
