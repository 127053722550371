import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import '../../../i18n';
import HttpService from '../../config/http';
import { Company } from '../../../models/Company';

export default class CompanyService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getCompany = async () => {
		const response: AxiosResponse<Company> = await this.api
			.get(`api/v1/contas-planus/get-empresa/`)

        if (response.status === 200) {
            return (response.data);
        } else {
            throw new Error(t('Erro ao tentar recuperar dados da empresa!'));
        }
	};
}
