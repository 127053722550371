import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { PAGETOPMARGEM, SCALEMULT } from '../../../styles/theme';

export const SidebarContainer = styled.div`
	position: relative;
	padding: 0 ${SCALEMULT * 0.7}vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	user-select: none;
	font-family: 'Inter', sans-serif;
`;

export const ItemsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const LogoDiv = styled.div`
	display: flex;
	align-items: center;
	min-height: 75px;
	height: ${PAGETOPMARGEM}vw;
	position: absolute;
	right: 0;
	transform: translate(100%, 0);
`;

export const LogoPlanus = styled.img`
	width: ${SCALEMULT * 8.3}vw;
	min-width: 90px;
`;

export const ProfileBottom = styled(ItemsContainer)`
	display: flex;
	height: ${PAGETOPMARGEM}vw;
	min-height: 75px;
	align-items: center;

	> div {
		position: relative;
	}
`;

export const ProfileDefault = styled.img`
	border-radius: 50%;
	width: ${SCALEMULT * 3.2}vw;
	height: ${SCALEMULT * 3.2}vw;
	min-width: 40px;
	min-height: 40px;
	cursor: pointer;
	border: 2px solid var(--degradeR-btn-color);
`;

export const ToolTip = styled.div<ToolTipProps>`
	background-color: #fff;
	color: #426172;
	font-weight: 500;
	position: absolute;
	bottom: -8px;
	left: 0;
	transform: translate(0, 100%);
	opacity: 1;
	cursor: pointer;
	box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.4);
	border-radius: 6px;
	outline: none !important;
	overflow: hidden;
	transition: all 0.05s ease-in;
	height: 0px;
	height: ${({ openUserOption, usuarioPerfil }) => {
		if (openUserOption) {
			return usuarioPerfil === 'a' || usuarioPerfil === 'g' ? '190px' : '153px';
		} else {
			return '0px';
		}
	}};
	z-index: 99;

	.tooltip-card {
		white-space: nowrap;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 5px;
		border-bottom: 1px solid #c0cfd8;
		margin: 5px 10px;
		font-size: 14px;

		.faSidebarTooltip-icon {
			font-size: 12px;
			display: inline-block;
			color: #426172;
			margin-right: 10px;
		}
	}
`;

export const SideBar = styled(ItemsContainer)`
	transition: all 0.5s ease;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	margin-top: 12px;

	ul li a {
		text-decoration: none;
	}

	ul {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
`;

export const NavLinkStyled = styled(NavLink)`
	.nav-border {
		border-radius: 16px;
		padding: 3px;
		width: ${SCALEMULT * 4.7}vw;
		height: ${SCALEMULT * 4.2}vw;
		min-width: 64px;
		min-height: 55px;

		&:hover {
			box-shadow: inset 0 0 0 1px rgba(215, 242, 5, 0.26);
		}
	}

	.nav-container {
		display: flex;
		background: #426172;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		color: white;
		border-radius: 14px;
		height: 100%;
		gap: 1px;

		.icons-container {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
		}

		.svg-icon-style {
			width: ${SCALEMULT * 1.5}vw;
			height: ${SCALEMULT * 1.5}vw;
			min-width: 20px;
			min-height: 20px;
		}

		.person-style {
			width: ${SCALEMULT * 1.5}vw;
			min-width: 20px;
			min-height: 20px;
		}

		.okrs-style {
			width: ${SCALEMULT * 1.5}vw;
			min-width: 26px;
			min-height: 26px;
		}
	}
`;

export const NavText = styled.p`
	display: inline;
	vertical-align: middle;
	text-align: center;
	vertical-align: middle;
	padding: 0;
	margin: 0;
	font-size: 0.75rem;
	font-weight: bold;
	transition: all 0.3s ease;
	font-family: 'Inter', sans-serif;
`;

export const LanguageBottom = styled.div`
	display: flex;
	align-items: flex-end;
	margin-bottom: 160px;
`;

export const LanguageSelectContainer = styled.div`
	margin-left: 20px;
	cursor: pointer;
	position: absolute;
`;

export const FeedBackBottom = styled(ItemsContainer)`
	margin-bottom: 40px;
`;

export const FeedBackIcon = styled.img`
	border-radius: 50%;
	width: ${SCALEMULT * 1.7}vw;
	height: ${SCALEMULT * 1.7}vw;
	border: 1px solid white;
`;

export const FeedBackContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #fff;
	font-weight: 600;
	font-size: 0.75rem;
	cursor: pointer;
	gap: 4px;
`;

export interface ToolTipProps {
	openUserOption: boolean;
	usuarioPerfil?: string;
}
