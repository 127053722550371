import React, { useState } from 'react';

import ContaService from '../../services/providers/account';
import { InfoConta } from './validation';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faExclamationTriangle,
	faEye,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import '../../form/formGeral.css';
import './style.css';
import { useTranslation } from 'react-i18next';
import '../../i18n';

export const InfoContaForm = props => {
	const {t} = useTranslation();
	const initialValues = { old_password: '', new_password: '' };
	// const [loading, setLoading] = useState(false);
	const [showInput, setShowInput] = useState(false);
	const [showInputNew, setShowInputNew] = useState(false);
	const [loading, setLoading] = useState(false);
	const contaService = new ContaService();

	const _patchSenha = values => {
		setLoading(true);
		props.handleLoading(true);
		const form_data = new FormData();
		for (var key in values) {
			form_data.append(key, values[key]);
		}
		contaService.postConta(
			form_data,
			response => {
				if (response.data) {
					toast.success(t("Senha atualizada com sucesso!"));
					props.modalGenericoShow(false);
				}
				props.handleLoading(false);
				setLoading(false);
			},
			error => {
				toast.error(error.toString());
				props.handleLoading(false);
				setLoading(false);
			},
		);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={InfoConta}
			enableReinitialize={true}
			onSubmit={(values, actions) => {
				actions.setSubmitting(true);

				_patchSenha(values);
			}}
		>
			{({ errors, touched }) => (
				<Form>
					<fieldset>
						<div className="modal-bg-center">
							<div className="container">
								<div className="row">
									<div className="col-12">
										<label
											className="conta-label modal-label"
											htmlFor="old_password"
										>
											{t("Senha Atual")}
											<FontAwesomeIcon
												icon={faEye}
												className="conta-eye-icon"
												style={{ color: showInput ? '#426172' : '#C0CFD8' }}
												onClick={() => setShowInput(!showInput)}
											/>
										</label>
										<Field
											autoComplete={'off'}
											type={showInput ? 'text' : 'password'}
											name="old_password"
											disabled={loading}
											className={
												errors.old_password && touched.old_password
													? 'form-error-style-input-projetos'
													: 'form-default-style-input-projetos'
											}
										/>
										<ErrorMessage name="old_password">
											{msg => (
												<div>
													<div className="input-error-style" role="alert">
														<FontAwesomeIcon
															icon={faExclamationTriangle}
															className="mr-1"
														/>
														{msg}
													</div>
												</div>
											)}
										</ErrorMessage>
										<label
											className="conta-label modal-label"
											htmlFor="new_password"
										>
											{t("Nova Senha*")}
											<FontAwesomeIcon
												icon={faEye}
												className="conta-eye-icon"
												style={{ color: showInputNew ? '#426172' : '#C0CFD8' }}
												onClick={() => setShowInputNew(!showInputNew)}
											/>
										</label>
										<Field
											autoComplete={'off'}
											type={showInputNew ? 'text' : 'password'}
											name="new_password"
											disabled={loading}
											className={
												errors.new_password && touched.new_password
													? 'form-error-style-input-projetos'
													: 'form-default-style-input-projetos'
											}
										/>
										<ErrorMessage name="new_password">
											{msg => (
												<div>
													<div className="input-error-style" role="alert">
														<FontAwesomeIcon
															icon={faExclamationTriangle}
															className="mr-1"
														/>
														{msg}
													</div>
												</div>
											)}
										</ErrorMessage>
										<br />
										<button
											type="submit"
											id="input-submit-conta"
											style={{ display: 'none' }}
										></button>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
				</Form>
			)}
		</Formik>
	);
};
