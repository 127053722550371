import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function ModuleIcon(props: IconProps) {
  return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path d="M13.8203 13.8203V10.5H10.5V13.8203H13.8203ZM8.82031 8.82031H15.5V15.5H8.82031V8.82031ZM13.8203 5.5V2.17969H10.5V5.5H13.8203ZM8.82031 0.5H15.5V7.17969H8.82031V0.5ZM5.5 13.8203V10.5H2.17969V13.8203H5.5ZM0.5 8.82031H7.17969V15.5H0.5V8.82031ZM5.5 5.5V2.17969H2.17969V5.5H5.5ZM0.5 0.5H7.17969V7.17969H0.5V0.5Z" />
</svg>

  )
}