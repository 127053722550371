import React, { useEffect, useState } from 'react';

import PessoaService from '../../../services/providers/responsible';
import ProjetoService from '../../../services/providers/project';
import FieldFilter from '../../../components/common/FieldFilter/FieldFilter';
import ClienteService from '../../../services/providers/client';
import { formatValuesToSelect } from '../../../utils/funcoes/reactSelect';
import FilterBtns from '../../../components/common/Buttons/filterBtns';

import './style.css';

import { customStyles } from '../../../utils/extenal-libs';
import AsyncSelector from '../../../components/common/AsyncSelector';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';

const FilterRelatorioProjeto = ({ sendFilter, selectShow, filter, onFilterEncerrados }) => {
	const {t} = useTranslation();
	const [tagSelectOptions, setTagSelectOptions] = useState([]);
	const {userDetails} = useUser();

	const [valueFilter, setValueFilter] = useState({
		cliente: { value: '', label: '' },
		projeto: { value: '', label: '' },
		gerente: { value: '', label: '' },
		tag: [],
	});

	const [checkEncerradas, setCheckEncerradas] = useState(true);
	useEffect(() => {
		setCheckEncerradas(onFilterEncerrados);
	}, [onFilterEncerrados])
	useEffect(() => {
		_getProjetosTag();
	}, []);

	const limparFiltros = () => {
		setClienteSelectShow('');
		setProjetoSelectShow('');
		setPessoaSelectShow('');
		setProjetosFilterArray([]);
		setPessoasFilterArray([]);
		setClientesFilterArray([]);
		var copy = valueFilter;
		copy = {
			cliente: { value: '', label: '' },
			projeto: { value: '', label: '' },
			gerente: { value: '', label: '' },
			tag: valueFilter.tag ? valueFilter.tag : [],
		};
		setValueFilter(copy);
	};

	const _getProjetosTag = () => {
		// setLoadingSelectProjetos(true);
		// setProjetoSelectOptions([]);
		const projetoService = new ProjetoService();
		projetoService.getProjetosTags(
			response => {
				if (response.data) {
					formatValuesToSelect(response.data, setTagSelectOptions);
				}
				// setLoadingSelectProjetos(false);
			},
			error => {
				// setProjetoSelectOptions([]);
				// setLoadingSelectProjetos(false);
				// toast.error(error);
			},
		);
	};

	const getSelected = (event, type) => {
		const currentSelect = {};
		currentSelect[type] = event;
		setValueFilter(prev => ({ ...prev, ...currentSelect }));
	};

	//Services
	const clienteService = new ClienteService();
	const projetoService = new ProjetoService();
	const pessoaService = new PessoaService();

	const [clienteSelectShow, setClienteSelectShow] = useState('');
	const [projetoSelectShow, setProjetoSelectShow] = useState('');
	const [pessoasSelectShow, setPessoaSelectShow] = useState('');

	const [projetosFilterArray, setProjetosFilterArray] = useState([]);
	const [pessoasFilterArray, setPessoasFilterArray] = useState([]);
	const [clientesFilterArray, setClientesFilterArray] = useState([]);

	useEffect(() => {
		if (selectShow.clienteSelectShow !== clienteSelectShow) {
			setClienteSelectShow(selectShow.clienteSelectShow);
		}
		if (selectShow.projetoSelectShow !== projetoSelectShow) {
			setProjetoSelectShow(selectShow.projetoSelectShow);
		}
		if (selectShow.pessoasSelectShow !== pessoasSelectShow) {
			setPessoaSelectShow(selectShow.pessoasSelectShow);
		}
	}, [selectShow]);

	useEffect(() => {
		if(valueFilter !== filter) {
			setValueFilter(filter);
			setProjetosFilterArray(filter.projeto.value);
			setPessoasFilterArray(filter.gerente.value);
			setClientesFilterArray(filter.cliente.value);
			
		}
		
	}, [filter])
	const location = useLocation();
	return (
			<form
				onSubmit={e => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
			<div className="label-field-filter">{t("Clientes")}</div>
				<AsyncSelector
					value={clienteSelectShow}
					onChange={e => {
						setClienteSelectShow(e);
						setProjetoSelectShow('');
						setProjetosFilterArray([]);
						setPessoaSelectShow('');
						setPessoasFilterArray([]);
						setClientesFilterArray(e !== null ? e.value : '');
					}}
					isMulti={false}
					// closeMenuOnSelect={true}
					placeholder={t('Todos')}
					noOptionsMessage={() => t('Vazio')}
					styles={customStyles}
					loadOptions={clienteService.loadClientesSelect}
					additional={{
						page: 1,
						placeholder: t('Todos'),
					}}
				/>
			<div className="label-field-filter">{t("Projetos")}</div>
				<AsyncSelector
					value={projetoSelectShow}
					onChange={e => {
						setProjetoSelectShow(e);
						setPessoaSelectShow('');
						setProjetosFilterArray(e !== null ? e.value : '');
					}}
					isMulti={false}
					// closeMenuOnSelect={false}
					placeholder={t('Todos')}
					// noOptionsMessage={() => t('Vazio')}
					styles={customStyles}
					loadOptions={projetoService.loadProjectsSelect}
					additional={{
						page: 1,
						cliente: clientesFilterArray,
						placeholder: t('Todos'),
					}}
					key={JSON.stringify(`${clientesFilterArray}${filter}`)}
				/>
			<div className="label-field-filter">{t("Gerente")}</div>
				<AsyncSelector
					value={pessoasSelectShow}
					onChange={e => {
						setPessoaSelectShow(e);
						setPessoasFilterArray(e !== null ? e.value : '');
					}}
					isMulti={false}
					// closeMenuOnSelect={false}
					placeholder={'Todas'}
					// noOptionsMessage={() => t('Vazio')}
					styles={customStyles}
					loadOptions={pessoaService.loadPessoasSelect}
					additional={{
						page: 1,
						placeholder: t('Todos'),
						cliente: clientesFilterArray,
						projeto: projetosFilterArray,
						adminProjeto: 'true',
						userDetails: userDetails
					}}
					key={JSON.stringify(`${clientesFilterArray}${projetosFilterArray}${filter}${location}`)}
				/>
				{checkEncerradas && (
					<FieldFilter
						label="Tag"
						type="tag"
						options={tagSelectOptions}
						isMulti={true}
						getSelected={getSelected}
					/>
				)}
				<div className="projetos-encerrados">
					<input
						type="checkbox"
						id="scales"
						name="scales"
						checked={checkEncerradas === false}
						onChange={() => setCheckEncerradas(!checkEncerradas)}
					/>

					<label htmlFor={'scales'} style={{ marginLeft: '14px' }}>
						{t("Filtrar por projetos encerrados")}
					</label>
				</div>
				<FilterBtns
					onClearFields={() => limparFiltros()}
					onFilter={() => {
						var sendValueFilter = valueFilter;
						try {
							sendValueFilter.cliente.value = clientesFilterArray;
							sendValueFilter.projeto.value = projetosFilterArray;
							sendValueFilter.gerente.value = pessoasFilterArray;
						} catch (exception) {
							console.log(exception);
						}
						sendFilter(
							sendValueFilter,
							checkEncerradas,
							pessoasSelectShow,
							projetoSelectShow,
							clienteSelectShow,
						);
					}}
				/>
			</form>
	);
};

export default FilterRelatorioProjeto;
