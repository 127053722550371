//backup

import React, { useState, useEffect } from 'react';

import {
	faAngleRight,
	faAngleLeft,
	faClock,
} from '@fortawesome/free-solid-svg-icons';
import { useCalendarioRegistros } from '../../contexts/CalendarioRegistros';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';

import '../../i18n';

import './style.css';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/User';
import { useStopwatch } from '../../contexts/Stopwatch';

const Calendario = ({
	diasDaSemana,
	dataInicial,
	dataFinal,
	weekNavigation
}) => {
	const {t} = useTranslation();
	const {savedDataInicial, calendarioRegistros, calendarioLoading} = useCalendarioRegistros();
	const { loadingUser } = useUser();

	const [listDatas, setListDatas] = useState([]);
	const [listTarefas, setListTarefas] = useState([]);
	const [listRegistrosDataTarefa, setListRegistrosDataTarefa] = useState([]);
	const [diaAlvo, setDiaAlvo] = useState(moment(savedDataInicial));
	const [isToday, setIsToday] = useState(false);
	const [periodoDias, setPeriodoDias] = useState([]);
	const [periodoString, setPeriodoString] = useState('');
	const {setPreSelectedRecord, handleToggleModal} = useStopwatch();
	//ModalHoras

	useEffect(() => {
		handlePeriodoString(dataInicial, dataFinal);
		setPeriodoDias(diasDaSemana);
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [diasDaSemana, dataInicial, dataFinal]);

	useEffect(() => {
		GenerateDataReport(calendarioRegistros);
	}, [calendarioRegistros]);

	const handleResetInitialDate = () => {
		weekNavigation("semana", moment())
		setDiaAlvo(moment());
	};

	const GenerateDataReport = registrosValue => {
		const listDatasAux = [];
		const listTarefasAux = [];
		const listRegistrosDataTarefaAux = [];

		if (registrosValue.length === 0) {
			setListDatas([]);
			setListTarefas([]);
			setListRegistrosDataTarefa([]);

		} else {
			registrosValue.forEach(registro => {
				let datasDuplicadas =
					listDatasAux.findIndex(data => {
						return registro.data === data.data;
					}) !== -1;

				if (!datasDuplicadas) {
					listDatasAux.push({
						data: registro.data,
						registros: [],
					});
				}
				/* TAREFAS DO TIPO OCIOSO VIRAM UMA ENTRADA ÚNICA NA LISTA DE TAREFAS*/
				let tarefaDuplicada =
					listTarefasAux.findIndex(tarefa => {
						return registro.tarefa === tarefa.id;
					}) !== -1;

				if (!tarefaDuplicada) {
					listTarefasAux.push({
						id: registro.tarefa,
						nome: registro.tarefa_nome,
						cliente_nome: registro.cliente_nome,
						projeto_nome: registro.projeto_nome,
						pessoa_nome: registro.pessoa_nome,
						projeto: registro.projeto,
						tipo: registro.tipo,
						registros: [],
					});
				}
			});
			calendarioRegistros.forEach(registro => {
				listDatasAux.forEach(data => {
					if (registro.data === data.data) {
						data.registros.push(registro.duracao.slice(0, 5));
					}
				});
				listTarefasAux.forEach(tarefa => {
					if (registro.tarefa === tarefa.id && registro.tipo === 'trab') {
						tarefa.registros.push(registro.duracao.slice(0, 5));
					} else if (
						registro.tipo === 'ocio' &&
						registro.tipo === tarefa.tipo
					) {
						tarefa.registros.push(registro.duracao.slice(0, 5));
					}
				});
			});
			//
			listDatasAux.forEach(data => {
				listTarefasAux.forEach(tarefa => {
					listRegistrosDataTarefaAux.push({
						data: data.data,
						tarefa: tarefa.id,
						registros: [],
					});
				});
			});
			calendarioRegistros.forEach(registro => {
				listRegistrosDataTarefaAux.forEach(dataTarefa => {
					// Se um item foi alterado para o tipo ocioso ele deve ser contado com os ociosos

					if (
						registro.tipo === 'trab' &&
						registro.data === dataTarefa.data &&
						registro.tarefa === dataTarefa.tarefa
					) {
						dataTarefa.registros.push(registro.duracao);
					} else if (
						registro.tipo === 'ocio' &&
						registro.data === dataTarefa.data &&
						dataTarefa.tarefa == null
					) {
						dataTarefa.registros.push(registro.duracao);
					}
				});
			});

			setListDatas(listDatasAux);
			setListTarefas(listTarefasAux);
			setListRegistrosDataTarefa(listRegistrosDataTarefaAux);

		}
	};

	const calculaTotalHorasGeral = () => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;

		calendarioRegistros.forEach(registro => {
			let extraiHoraMinuto = registro.duracao.split(':');
			totalHoras = totalHoras + parseInt(extraiHoraMinuto[0]);
			totalMinutos = totalMinutos + parseInt(extraiHoraMinuto[1]);
		});

		let final_hora = totalHoras + Math.floor(totalMinutos / 60);
		let final_minuto = totalMinutos % 60;
		final_minuto = final_minuto < 10 ? '0' + final_minuto : final_minuto;
		totalAbsoluto = final_hora + ':' + final_minuto;
		return totalAbsoluto;
	};

	const calculaTotalHorasEspecifico = registros => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;
		registros.forEach(dado => {
			let extraiHoraMinuto = dado.split(':');
			totalHoras = totalHoras + parseInt(extraiHoraMinuto[0]);
			totalMinutos = totalMinutos + parseInt(extraiHoraMinuto[1]);
		});
		let final_hora = totalHoras + Math.floor(totalMinutos / 60);
		let final_minuto = totalMinutos % 60;
		final_minuto = final_minuto < 10 ? '0' + final_minuto : final_minuto;
		totalAbsoluto = final_hora + ':' + final_minuto;
		return totalAbsoluto;
	};

	const upperCaseDiaSemana = data => {
		return data[0].toUpperCase() + data.slice(1);
	};

	const handlePeriodoString = (dataInicial, dataFinal) => {
		if (moment().isBetween(dataInicial, dataFinal, undefined, '[]')) {
			setPeriodoString(t("Esta Semana"));
			setIsToday(true);
		} else {
			setIsToday(false);

			if (
				moment()
					.subtract(7, 'days')
					.isBetween(dataInicial, dataFinal, undefined, '[]')
			) {
				setPeriodoString(t("Semana Passada"));
			} else {
				setPeriodoString(
					`${moment(dataInicial).format('ll')} - ${moment(dataFinal).format(
						'll',
					)}`,
				);
			}
		}
	};

	var valorMes = '';
	const separadorMes = (mes, index) => {
		if (index === 0) {
			valorMes = mes;
			return true;
		} else if (valorMes === mes) {
			return true;
		} else if (valorMes !== mes) {
			return false;
		}
	};


	return (
		<div>
			<div className="calendario-home-header"> </div>
			<div className="calendario-home-weekNavigation">
		{ !isToday ?
			<>
				<button
					className="calendario-btn-today"
					onClick={handleResetInitialDate}
					disabled={isToday}
				>
					Hoje
				</button>
				</> :  <></>
			}
				<div style={{ display: 'flex' }}>
					<FontAwesomeIcon
						onClick={() => {
							if (!calendarioLoading && !loadingUser) {
								weekNavigation('prev', moment(diaAlvo).subtract(7, 'days'));
								setDiaAlvo(moment(diaAlvo).subtract(7, 'days'));
							}
						}}
						icon={faAngleLeft}
						className="calendario-home-icon"
					/>
					<p className="calendario-home-week-text"> {periodoString} </p>{' '}
					<FontAwesomeIcon
						onClick={() => {
							if (!calendarioLoading && !loadingUser) {
								weekNavigation('next', moment(diaAlvo).add(7, 'days'));
								setDiaAlvo(moment(diaAlvo).add(7, 'days'));
							}
						}}
						icon={faAngleRight}
						className="calendario-home-icon"
					/>
				</div>{' '}
			</div>{' '}
			<div className="calendario-container-table">
				{' '}
				{calendarioLoading || loadingUser ? (
					<CarregandoDados />
				) : (
					<>
						<div>
							<table className="calendario-home-table">
								<thead>
									<tr className="cabecalho-calendario-home">
										<th colSpan="2" className="cabecalho-titulo-tarefa">
											<span> {t("Tarefa")} </span>{' '}
										</th>{' '}
										<th>
												<span></span>
											</th>{' '}
										{periodoDias.map((dia, index) => (
											<th
												className="calendario-dias-semana"
												style={{
													opacity: index === 0 || index === 6 ? '0.6' : '1',
													background:
														moment().format('YYYY-MM-DD') === dia.data
															? '#f0ffe2'
															: 'none',
												}}
											>
												<span
													className="calendario-dia-mes-bg"
													style={{
														background: separadorMes(
															moment(dia.data).format('MM'),
															index,
														)
															? '#c4c4dc'
															: '#23d9b7',
														color: separadorMes(
															moment(dia.data).format('MM'),
															index,
														)
															? '#7878a2'
															: '#23d9b7',
													}}
												>
													<span className="calendario-dia-mes">
														{' '}
														{index === 0 ||
														moment(dia.data).format('DD') === '01' ? (
															<>{t(moment(dia.data).format('MMM'))} </>
														) : (
															<></>
														)}{' '}
													</span>{' '}
												</span>{' '}
												<span className="calendario-header-table-diaSemana">
													{' '}
													{t(upperCaseDiaSemana(dia.diaSemana))}{' '}
												</span>{' '}
												<br />
												<span className="calendario-header-data">
													{' '}
													{moment(dia.data).format('DD')}{' '}
												</span>{' '}
											</th>
										))}{' '}
										<th className="calendario-header-total"> {t("Total")} </th>{' '}
									</tr>{' '}
								</thead>{' '}
							</table>
							<div
								style={{
									maxHeight: '270px',
									overflow: 'auto',
									overflowX: 'hidden',
								}}
							>
								<table className="calendario-home-table">
									<thead
									style={{ visibility: 'collapse' }}
									>
										<tr className="cabecalho-calendario-home">
											<th colSpan="2" className="cabecalho-titulo-tarefa">
												<span> {t("Tarefa")} </span>{' '}
											</th>{' '}

											{periodoDias.map((dia, index) => {
												return(
												<th
													className="calendario-dias-semana"
													style={{
														opacity: index === 0 || index === 6 ? '0.6' : '1',
														background:
															moment().format('YYYY-MM-DD') === dia.data
																? '#f0ffe2'
																: 'none',
													}}
												>
													<span
														className="calendario-dia-mes-bg"
														style={{
															background: separadorMes(
																moment(dia.data).format('MM'),
																index,
															)
																? '#c4c4dc'
																: '#23d9b7',
															color: separadorMes(
																moment(dia.data).format('MM'),
																index,
															)
																? '#7878a2'
																: '#23d9b7',
														}}
													>
														<span className="calendario-dia-mes">
															{' '}
															{index === 0 ||
															moment(dia.data).format('DD') === '01' ? (
																<>{t(moment(dia.data).format('MMM'))} </>
															) : (
																<></>
															)}{' '}
														</span>{' '}
													</span>{' '}
													<span className="calendario-header-table-diaSemana">
														{' '}
														{t(upperCaseDiaSemana(dia.diaSemana))}{' '}
													</span>{' '}
													<br />
													<span className="calendario-header-data">
														{' '}
														{moment(dia.data).format('DD')}{' '}
													</span>{' '}
												</th>
											)})}{' '}
											<th className="calendario-header-total"> {t("Total")} </th>{' '}
										</tr>{' '}
									</thead>{' '}
									<tbody>
										{' '}
										{listTarefas.length > 0 ? (
											listTarefas.map(tarefa => (
												<tr className="calendario-row-tarefas-dias">
													<th
														colSpan="2"
														className="calendario-descricao-tarefa"
													>
														<div className="calendario-tarefa">
															{tarefa.nome}{' '}
														</div>
														<div className="calendario-tarefa-subtitulo">
															{' '}
															{`${tarefa.cliente_nome} - ${tarefa.projeto_nome}`}{' '}
														</div>{' '}
													</th>{' '}
													<td>
													<div
															onClick={() => {
																setPreSelectedRecord({projeto: tarefa.projeto, projeto_nome: tarefa.projeto_nome, tarefa: tarefa.id, tarefa_nome: tarefa.nome, tipo: tarefa.tipo })
																handleToggleModal(true);
															}}
														>
															<FontAwesomeIcon
																icon={faClock}
																title={t("Adicionar registro de hora")}
																className="tarefas-icon-faclock"
															/>
														</div>
													</td>

													{diasDaSemana.map((data, index) => (
														<td
															className="calendario-hora"
															style={{
																backgroundColor:
																	index === 0 || index === 6
																		? '#f1f1f1'
																		: 'none',
																opacity:
																	index === 0 || index === 6 ? '0.6' : '1',
															}}
														>
															{' '}
															{listRegistrosDataTarefa.map(dataTarefa => {
																if (
																	dataTarefa.tarefa === tarefa.id &&
																	dataTarefa.data === data.data &&
																	dataTarefa.registros.length !== 0
																) {
																	return calculaTotalHorasEspecifico(
																		dataTarefa.registros,
																	);
																}

																return null;

															})}{' '}
														</td>
													))}
													<td className="calendario-total-tarefas">
														{calculaTotalHorasEspecifico(tarefa.registros)}{' '}

													</td>
												</tr>
											))
										) : (
											<>
												<tr>
													<th
														colSpan="2"
														className="calendario-descricao-tarefa"
													>
														{t("Sem apontamentos esta semana")}{' '}
													</th>{' '}
													{diasDaSemana.map(dia => (
														<td className="calendario-hora"> </td>
													))}{' '}
													<td> </td>{' '}
												</tr>{' '}
											</>
										)}{' '}
									</tbody>{' '}
								</table>{' '}
							</div>{' '}
						</div>{' '}
						<table className="calendario-home-table">
							<thead style={{ visibility: 'collapse' }}>
								<tr className="cabecalho-calendario-home">
									<th colSpan="2" className="cabecalho-titulo-tarefa">
										<span> {t("Tarefa")} </span>{' '}
									</th>{' '}
									{periodoDias.map((dia, index) => (
										<th
											className="calendario-dias-semana"
											style={{
												opacity: index === 0 || index === 6 ? '0.6' : '1',
												background:
													moment().format('YYYY-MM-DD') === dia.data
														? '#f0ffe2'
														: 'none',
											}}
										>
											<span
												className="calendario-dia-mes-bg"
												style={{
													background: separadorMes(
														moment(dia.data).format('MM'),
														index,
													)
														? '#c4c4dc'
														: '#23d9b7',
													color: separadorMes(
														moment(dia.data).format('MM'),
														index,
													)
														? '#7878a2'
														: '#23d9b7',
												}}
											>
												<span className="calendario-dia-mes">
													{' '}
													{index === 0 ||
													moment(dia.data).format('DD') === '01' ? (
														<>{t(moment(dia.data).format('MMM'))} </>
													) : (
														<></>
													)}{' '}
												</span>{' '}
											</span>{' '}
											<span className="calendario-header-table-diaSemana">
												{' '}
												{t(upperCaseDiaSemana(dia.diaSemana))}{' '}
											</span>{' '}
											<br />
											<span className="calendario-header-data">
												{' '}
												{moment(dia.data).format('DD')}{' '}
											</span>{' '}
										</th>
									))}{' '}
									<th className="calendario-header-total"> {t("Total")} </th>{' '}
								</tr>{' '}
							</thead>
							<tfoot>
								<tr className="calendario-footer-row">
									<td colSpan="2" className="calendario-btn-totalhorasdiarias">
										{t("Total")}
									</td>{' '}
									<td>
									
									</td>
									{diasDaSemana.map((dia, index) => (
										<td
											style={{
												opacity: index === 0 || index === 6 ? '0.6' : '1',
											}}
											className="calendario-col-total"
										>
											{' '}
											{listDatas.map(data => {
												if (dia.data === data.data) {
													return calculaTotalHorasEspecifico(data.registros);
												}

												return null;
											})}{' '}
										</td>
									))}{' '}
									<th style={{ color: '#23d9b7' }} id="total-table">
										{' '}
										{calculaTotalHorasGeral()}{' '}
									</th>{' '}
								</tr>{' '}
							</tfoot>{' '}
						</table>{' '}
					</>
				)}{' '}
			</div>{' '}
		</div>
	);
};
export default Calendario;

const CarregandoDados = () => (
	<>
		<div className="loader-alinhamento">
			<div className="loader-conteudo"> </div>{' '}
		</div>{' '}
	</>
);
