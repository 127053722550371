import React, { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import ProjetoService from '../../../../../services/providers/project';
import {
	validateCanSeeProjetoCard,
} from '../../../../../utils/funcoes/validarPerfil';

import { useFilters } from '../../../../../contexts/Filter/Task/Filter';
import { useProject } from '../../../../../contexts/Project';

import './style.css';
import { AccessCode } from '../../../../../models/UserDetails';
import { ProjectBasic, ProjectDetail, ProjectPage } from '../../../../../models/Project';
import { SetStateAction } from 'jotai';
import ProjectCardHeader from './ProjectCardHeader';
import ProjectCardBody from './ProjectCardBody';
import TeamImages from '../../../../../components/planus2/TeamImages';

interface ProjectCardProps {
	reloadID?: number;
	accessCode?: AccessCode,
	data: ProjectPage,
	setEditingProjeto: Dispatch<SetStateAction<boolean>>,
	setEditedProject: Dispatch<SetStateAction<ProjectBasic | undefined>>,
	id: number | string,
}

export default function ProjectCard({
	reloadID,
	accessCode,
	data,
	setEditedProject, 
	setEditingProjeto,
	id,
}: Readonly<ProjectCardProps>) {
	const [cardWidth, setCardWidth] = useState<number>();
	const {
		loadingPostPatch,
		setModalProjectVisible,
		setProjects,
		projectsDetails,
		setProjectsDetails,
		loadingProjects
	} = useProject();
	const { setSelectedProject } = useFilters();
	const cardRef = useRef<HTMLDivElement>(null);

useEffect(() => {
	const observer = new ResizeObserver(entries => {
		for (const entry of entries) {
			setCardWidth(entry.contentRect.width);
		}
	});

	if (cardRef.current) {
		observer.observe(cardRef.current);
	}

	return () => {
		observer.disconnect();
	};
	}, []);


	// DETALHES DO PROJETO
	const projetoService = new ProjetoService();
	const [loading, setLoading] = useState(true);
	const isLoading = loadingProjects || loadingPostPatch || loading;


	useEffect(() => {
        if (
            data.admins == undefined ||
            data.time == undefined ||
            data.tags == undefined ||
            data.is_member == undefined
        ) {
            const tempDetails = projectsDetails?.[data.id];

            if(tempDetails) {
                data.is_member = tempDetails.is_member;
                data.admins = tempDetails.admins;
                data.time = tempDetails.time;
                data.tags = tempDetails.tags;
				setLoading(false);
            } else {
                getProjetodetails();
            }
        } else {
            setLoading(false);
        }
    }, [data]);

	useEffect(() => {
		if (reloadID === data.id) {
			getProjetodetails();
		}
	}, [loadingPostPatch]);

	const handleSetProject = useCallback(
		() =>
			setSelectedProject({
				value: data.id,
				label: data.nome,
			}),
		[data],
	);

	const getProjetodetails = async () => {
		setLoading(true);
		projetoService.getDetalheProjeto(
			data.id,
			response => {
				if (response !== null) {
					data.is_member = response.is_member;
					data.admins = response.admins;
					data.time = response.time;
					data.tags = response.tags;
					setProjects((prevData) => {
						return handleAddProject(prevData, data);
					});
					setProjectsDetails((prev) => {
						return handleAddProjectDetails(prev, response)
					})
						
				}
				setLoading(false);
			},
			e => {
				console.log(e);
			},
		);
	};

	const handleAddProjectDetails = (projectDetails: ProjectDetail[], response: ProjectDetail) => {
		return projectDetails.map((item) => {
			if(item.id === response.id) {
				return response;
			}
			return item;
		})
	}

	const handleAddProject = (projects: ProjectPage[], data: ProjectPage) => {
		return projects.map((item) => {
			if(item.id === id) {
				return data;
			}
			return item;
		})
	}

	const handleClickEdit = () => {
		if (!loading) {
            setEditingProjeto(true);
            setEditedProject({...data, time: data.time ?? []});
            setModalProjectVisible(true);
        }
	}
	

	const showHiddenCard = () =>
		validateCanSeeProjetoCard(
			accessCode,
			data.is_member,
		)
			? 'none'
			: 'initial';
	const notMember = isLoading || !data.is_member;

	return (
			<div
				ref={cardRef}
				style={{
					display: showHiddenCard(),
				}}
				className={`${isLoading ? 'pointer-events-none' : 'pointer-events-auto'}  flex flex-col border border-solid border-border-gray rounded-[10px]`}
			>
				<ProjectCardHeader data={data} />
				<ProjectCardBody  onClickEdit={handleClickEdit} data={data} loading={isLoading} onSetProject={handleSetProject} />
				<div className={`p-3 min-h-[3.5rem] ${notMember ? 'bg-border-gray opacity-50' : 'bg-transparent'}`}>
					<TeamImages team={data.time} max={cardWidth ? Math.floor(cardWidth/32) : 5} imageSize='w-8 h-8 min-h-[2rem] min-w-[2rem]'/>
				</div>
			</div>
	);
}