import styled from 'styled-components';

export const InfoHistoricoContainer = styled.div`
	background: #fff;
	border-radius: 10px;
	margin-top: 20px;
`;

export const InfoHistoricoTitle = styled.div`
	font-weight: 500;
	color: #8099a7;
	font-size: 16px;
	padding: 10px 15px 10px 15px;
`;

export const InfoHistoricoBody = styled.div`
	padding: 0px 15px 10px 15px;
`;
