import { HttpStatus } from '../../utils/constants/http-status';
import axios, {
	AxiosRequestConfig,
	AxiosResponse
} from 'axios';
import environment from './environment';

export default class HttpService {
	private service;
	private parsedEnviroment;
	private props;

	constructor(props?: any) {
		const token = localStorage.getItem('planustk');
		this.parsedEnviroment =
			environment.currentEnvironment === undefined
				? 'homolog'
				: (environment.currentEnvironment as keyof typeof environment.baseUrl);
		const service = axios.create({
			baseURL: environment.baseUrl[this.parsedEnviroment].urlApi,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (token) {
			service.defaults.headers.common.Authorization = `Bearer ${token}`;
		}
		service.interceptors.response.use(this.handleSuccess, this.handleError);
		this.service = service;
		this.props = props;
	}

	loadToken(newToken: string) {
		this.service.defaults.headers.common.Authorization = `Bearer ${newToken}`;
	}

	handleSuccess(response: AxiosResponse) {
		return response;
	}

	handleError = (error: any) => {
		if (error?.response) {
			const token = localStorage.getItem('planustk');

			switch (error.response.status) {
				case HttpStatus.UNAUTHORIZED:
					if (Object.prototype.hasOwnProperty.call(error.response.data, 'code')) {
						if (error.response.data.code === 'token_not_valid') {
							localStorage.removeItem('planustk');
							localStorage.removeItem('plusr');
							window.location.href = '/login';
						}
					}

					if (token) {
						localStorage.removeItem('planustk');
						localStorage.removeItem('plusr');
					}
					
					return error;
				case HttpStatus.FORBIDDEN:
					return error.response;
				case HttpStatus.BAD_REQUEST:
					return error.response;
				default:
					return error;
				}
		}
		if(error.code === 'ERR_CANCELED') {
			const erro: AxiosResponse<any, any> = {
				config: error?.config,
				data: [],
				status: 499,
				statusText: 'Client Closed Request',
				headers: {},
				request: error?.request
			}
			return erro;
		}
		Promise.reject(error);
	};

	redirectTo = (path: string) => {
		this.props.history.push(path);
	};

	get(path: string, config: AxiosRequestConfig = {}) {
		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;
		return this.service.get(url, config);
	}

	getWithCustomHeader(path: string, options = {}) {
		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;
		return this.service.get(url, options);
	}

	put(path: string, payload: any) {
		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;
		return this.service.put(url, payload);
	}

	putFile(path: string, payload: any) {
		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;
		return this.service.put(url, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	patch(path: string, payload: any, config = {}) {
		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;
		return this.service.patch(url, payload, config);
	}

	patchFile(path: string, payload: any) {
		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;
		return this.service.patch(url, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	post(path: string, payload: any, config = {}) {

		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;

		return this.service.post(url, payload, config);
	}

	postFile(path: string, payload: any) {
		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;
		return this.service.post(url, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	delete(path: string) {
		const url = environment.baseUrl[this.parsedEnviroment].urlApi + path;
		return this.service.delete(url);
	}
}
