import React from 'react';
import { 
    CardContainer, 
    ChartContainer, 
    ChartDatLabelContainer, 
    ChartDataBody, 
    ChartDataContainer, 
    ChartDataHeader, 
    ChartDataHeaderTitle, 
    ChartDataHeaderTotal, 
    ChartDataHourTitle, 
    ChartDataHourTotal, 
    ChartDataInfoContainer, 
    ChartDataItem, 
    ChartDataTitle, 
    ChartDataTotal, 
    ChartDataValue, 
    ChartHeader, 
    ChartIcon, 
    ChartTitle, 
    ChartWidth, 
    ColorContainer, 
    ColorDiv, 
    LoadingContainer, 
    NoDataContainer 
} from './styled';
import {
    Chart
} from 'react-chartjs-2';

import {
    Chart as ChartJS,
    ArcElement,
    Legend,
    Tooltip,
    DoughnutController,
    Plugin,
    ChartTypeRegistry,
    ChartData,
    ChartOptions
} from 'chart.js';
import { LoaderCircle } from '../loaders/LoaderCircle';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'chart.js/types/basic';

ChartJS.register(ArcElement, DoughnutController, Tooltip, Legend);

export interface ChartDisplay {
    value: number;
    valueString: string;
    color: string;
    title: string;
    label: string;
}

export interface DoughnutCardProps {
    readonly title: string;
    readonly total: string;
    readonly totalTitle: string;
    readonly labelTitle?: string;
    readonly label?: string;
    readonly chartData: ChartDisplay[];
    readonly iconSize?: number;
    readonly loading?: boolean;
    readonly plugins: Plugin<keyof ChartTypeRegistry, AnyObject>[];
    readonly data: ChartData;
    readonly options: ChartOptions;
    readonly children?: React.ReactNode;
}

export default function DoughnutCard({title, loading, ...props}: DoughnutCardProps) {

    return (
        <CardContainer>
            <ChartHeader>
                <ChartTitle>
                    {title}
                </ChartTitle>
            </ChartHeader>
            <ChartContainer>
                {loading ?
                    <LoadingContainer>
                        <LoaderCircle />
                    </LoadingContainer> : <NotLoading title={title} {...props}  />
                }
            </ChartContainer>
        </CardContainer>
    )
}


const NotLoading = ({chartData, data, label, labelTitle, options, total, totalTitle, children, iconSize, plugins}: DoughnutCardProps) => {
           const { t } = useTranslation();
           
        return chartData.length > 0 ? <>
            <ChartWidth>
                <Chart plugins={plugins} type='doughnut' data={data} options={options} />
                {children && <ChartIcon size={iconSize ?? 0}>
                    {children}
                </ChartIcon>}
            </ChartWidth>
            <ChartDataContainer>
                <ChartDataHeader>
                    <ChartDataTotal withLabel={!!(label && labelTitle)}>
                        <ChartDataHeaderTitle>
                            {totalTitle}
                        </ChartDataHeaderTitle>
                        <ChartDataHeaderTotal>
                            {total}
                        </ChartDataHeaderTotal>
                    </ChartDataTotal>
                    <ChartDataHourTotal>
                      {(label && labelTitle) && <>
                        <ChartDataHourTitle>
                            {labelTitle}
                        </ChartDataHourTitle>
                        <ChartDataHourTitle>
                            {label}
                        </ChartDataHourTitle>
                      </> }
                    </ChartDataHourTotal>
                </ChartDataHeader>
                <ChartDataBody>
                    {chartData.map((data) => {
                        return (
                            <ChartDataItem  key={data.color}>
                                <ColorContainer withLabel={!!(label && labelTitle)}> 
                                    <ColorDiv color={data.color} />
                                    <ChartDataInfoContainer withLabel={!!(label && labelTitle)}>
                                        <ChartDataTitle>
                                            {data.title}
                                        </ChartDataTitle>
                                        <ChartDataValue>
                                            {data.valueString}
                                        </ChartDataValue>
                                    </ChartDataInfoContainer>
                                </ColorContainer>
                                {label && labelTitle && <ChartDatLabelContainer>
                                    {data.label}
                                </ChartDatLabelContainer>}
                            </ChartDataItem>
                        )
                    })}
                </ChartDataBody>
            </ChartDataContainer></> : <NoDataContainer>
            {t("Sem registros")}.
        </NoDataContainer>}