export function horasParaTempo(horas: number) {
    // Extrair a parte inteira das horas
    const horasInteiras = Math.floor(horas);
  
    // Calcular os minutos e segundos
    const minutos = Math.floor((horas - horasInteiras) * 60);
    const segundos = Math.floor(((horas - horasInteiras) * 60 - minutos) * 60);
  
    // Formatar a string de tempo
    const tempoFormatado = `${horasInteiras.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
  
    return tempoFormatado;
  }

export function tempoParaHoras(tempo: string) {
    const partes = tempo.split(":");
    const horas = parseInt(partes[0], 10);
    const minutos = parseInt(partes[1], 10);
    const segundos = parseInt(partes[2], 10);
  
    // Calcular o total de horas
    const totalHoras = horas + minutos / 60 + segundos / 3600;
  
    return totalHoras;
  }