import styled from 'styled-components';

export const Container = styled.button`
	padding: 0 20px;
	border: 1px solid #29dff0;
	height: 42px;
	margin-left: 30px;
	align-items: center;
	justify-content: center;
	letter-spacing: 0px;
	border-radius: 10px;
	border: none;
	outline: none !important;
	font-size: 16px;
	font-weight: 600;
	background: #5ad8e3;
	color: white;

	&:disabled,
	&[disabled] {
		opacity: 0.5;
	}

	@media (max-width: 1000px) {
		& {
			padding: 4px 20px;
			width: 50%;
			margin: 5px;
		}
	}
`;

export const ButtonAdd = styled.button`
	background: #ffffff;
	color: #426172;
	user-select: none;
	height: auto;
	margin-left: 0;

	@media (max-width: 1000px) {
		& {
			width: 50%;
			height: 32px;
			margin: 0;
			padding: 1px 6px;
			margin-top: 10px;
			border: 1px;
		}
	}
`;

export const MainButtonStyled = styled.button`
	width: 130px;
	height: 47px;
	border: none;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;
	color: #426172;
	-webkit-user-select: none;
	user-select: none;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0px;
	outline: none;

	&:focus {
		outline: none;
	}
`;
