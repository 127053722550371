import React, { useEffect, useState } from "react";
import PageContainerProjectDetail, { LocationProjectDetails } from "../../../../../components/containers/PageContainer/ProjectDetails";
import { useLocation, useParams } from "react-router-dom";
import Label from "../../../../../components/common/Labels/label";
import { uniqueId } from 'lodash';
import fileSize from 'filesize';
import ProjetoService from "../../../../../services/providers/project";
import { toast } from "react-toastify";
import { t } from "i18next";
import FileList from "../UploudFile/FileList";
import UploadFile from "../UploudFile";
import FileListGet from "../UploudFile/FileListGet";
import { useAtom } from "jotai";
import { ProjectUploadedFilesAtom, ProjectFileDataGetAtom } from "../../../../../atoms/ProjectDetails";

export default function ProjectsAttachments() {
	const [uploadedFiles, setUploadedFiles] = useAtom(ProjectUploadedFilesAtom);
	const [loadingFiles, setLoadingFiles] = useState(false);
	const [fileDataGet, setFileDataGet] = useAtom(ProjectFileDataGetAtom);

    const location = useLocation<LocationProjectDetails>();
	const { id } = useParams<{id: string}>();
    const projectId = parseInt(id);

    const handleUpload = (files: any) => {
		const currentUploadedFiles = files.map((file: any) => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: fileSize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}));
		setUploadedFiles((prev: any) => [...prev, ...currentUploadedFiles]);
		currentUploadedFiles.forEach((item: any) => postProjetoFile(item));
	};

	useEffect(() => {
		if (projectId) {
			getProjetoFile();
		}
	}, [projectId]);

    const postProjetoFile = (uploadedFile: any) => {
		const data = new FormData();
		data.append('projeto', projectId.toString());
		data.append('documento', uploadedFile.file);
		const config = {
			onUploadProgress: (e: any) => {
				const progress = parseInt(Math.round((e.loaded * 100) / e.total).toFixed());
				updateFile(uploadedFile.id, { progress });
			},
		};
		const projetoService = new ProjetoService();
		projetoService.postProjetoFile(
			data,
			config,
			response => {
				if (response) {
					setUploadedFiles(
						(prevFile:any) =>
							(prevFile = prevFile.map((uploaded: any) =>
								uploadedFile.id === uploaded.id
									? {
											...uploaded,
											uploaded: true,
											id: response.data[0].id,
											url: response.data[0].documento,
									} : uploaded,
							)),
					);
					getProjetoFile();
					toast.success(t('Arquivo salvo com sucesso!'));
				}
			},
			error => {
				setUploadedFiles(
					(prevFile: any) =>
						(prevFile = prevFile.map((uploaded: any) =>
							uploadedFile.id === uploaded.id
								? {
										...uploaded,
										error: true,
								} : uploaded,
						)),
				);
				console.log(error);
				toast.error(error);
			},
		);
	};

    const updateFile = (id: number, data: any) => {
		setUploadedFiles(
			(prevFile: any) =>
				(prevFile = prevFile.map((uploaded: any) =>
					id === uploaded.id ? { ...uploaded, ...data } : uploaded,
				)),
		);
	};

    const getProjetoFile = () => {
		setLoadingFiles(true)
		const projetoService = new ProjetoService();
		projetoService.getProjetoFile(
			projectId,
			response => {
				if (response) {
					setFileDataGet(response);
				}
				setLoadingFiles(false);
			},
			error => {
				setLoadingFiles(false);
				toast.error(error.toString());
			},
		);
	};

    const deleteProjetoFile = (id: any) => {
		setLoadingFiles(true);
		const projetoService = new ProjetoService();
		projetoService.deleteProjetoFile(
			id,
			projectId,
			response => {
				if (response) {
					setUploadedFiles(
						(prevFile: any) =>
							(prevFile = prevFile.filter((uploaded: any) => id !== uploaded.id)),
					);
					getProjetoFile();
					setLoadingFiles(false);
					toast.success(t('Arquivo deletado com sucesso!'));
				}
			},
			error => {
				toast.error(error);
			},
		);
	};
    return(
        <PageContainerProjectDetail
            location={location}
            projectId={projectId}
        >
                <div className="">
						<Label text={t("Anexar arquivo")} />
						<div
							className="p-6 rounded-xl bg-ivory-lighter"
						>
							{!!uploadedFiles.length && (
								<FileList files={uploadedFiles} onDelete={deleteProjetoFile} />
							)}
							<UploadFile onUpload={handleUpload} />
						</div>

						{!!fileDataGet.length && (
							<>
								<Label text={t("Arquivos do Projeto")} />
								<FileListGet
									loadingFiles={loadingFiles}
									files={fileDataGet.reverse()}
									onDelete={deleteProjetoFile}
								/>
							</>
						)}
					</div>
        </PageContainerProjectDetail>
    )
}