import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Label = styled.div``;

interface InputDateProps {
	width?: string;
}

export const InputDate = styled(DatePicker)<InputDateProps>`
	border-width: 0;
	&:focus {
		outline: none;
	}
	background-color: transparent;
	color: #426172;
	&::placeholder {
		color: #426172;
	}
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	${({width}) => width && `width: ${width};`}
`;

export const CalendarIcon = styled(FontAwesomeIcon)`
	margin-right: 5px;
`;

interface DatePickerContainerProps {
	border?: string;
}

export const DatePickerContainer = styled.div<DatePickerContainerProps>`
	border-width: 0;
	background-color: transparent;
	padding: 8px 0;
	margin: 0 5px;
	&:focus {
		border-width: 0;
	}
	background-color: transparent;
	box-shadow: 0;

	cursor: pointer;
	color: #496070;
	font-weight: 400;
	line-height: 18px;
	font-size: 0.875rem;
	letter-spacing: 0.25px;
	font-style: normal;
	display: flex;
	flex-direction: row;
	align-items: center;
	${({border}) => border && `border: ${border}`}
`;


export const CustomInputStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	height: 24px;
	width: fit-content;
	justify-content: space-between;
	width: 100%;
	label {
		margin: 0;
		white-space: nowrap;
	}
`;