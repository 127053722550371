import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Login from '../index';
import AuthService from '../../../services/security/auth';
import BannerLogin from '../components/BannerLogin/index.js';
import FormInput from '../components/FormInput/index.js';
import { CriarSenhaSchema } from './validation.js';

import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import ButtonForm from '../components/ButtonForm/index.js';

import './style.css';

import '../../../i18n';
import { useTranslation } from 'react-i18next';

export const RedefinirSenha = ({ match }) => {
	const {t} = useTranslation();
	const query_param = match ? match.params.token : null;

	const [loading, setLoading] = useState(false);
	const [senhaRedefinida, setSenhaRedefinida] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const initialValues = { token: query_param, password: '' };

	const _redefinirSenha = values => {
		const service = new AuthService();
		setLoading(true);
		const form = new FormData();
		for (var key in values) {
			form.append(key, values[key]);
		}
		service.redefinirSenha(
			form,
			response => {
				if (response.data) {
					setLoading(false);
					setSenhaRedefinida(true);
				}
			},
			error => {
				toast.error(error);
				setLoading(false);
			},
		);
	};

	return (
		<Login>
			<div className="login-limiter">
				<Formik
					initialValues={initialValues}
					validationSchema={CriarSenhaSchema}
					enableReinitialize={false}
					onSubmit={(values, actions) => {
						actions.setSubmitting(true);
						_redefinirSenha(values);
					}}
				>
					{({ errors, touched }) => (
						<Form className="login-form-container">
							{!senhaRedefinida ? (
								<BannerLogin
									titulo={t("Digite sua nova senha")}
									subTitulo={t("Digite uma senha que a partir de agora será sua nova senha")}
								/>
							) : (
								<BannerLogin
									titulo={t("Senha redefinida com sucesso!")}
									subTitulo={<Link to="/">Logar</Link>}
								/>
							)}
							<FormInput
								id="input-password"
								nome="Password"
								campo="password"
								errors={errors.password}
								touched={touched.password}
								showPassword={showPassword}
								setShowPassword={setShowPassword}
								type={showPassword ? 'text' : 'password'}
							/>
							<ButtonForm loading={loading} btnType="redefinir" />
						</Form>
					)}
				</Formik>
			</div>
		</Login>
	);
};
