import React, { useEffect, useState } from 'react';

import { ReactComponent as LoginLineCenter } from "../../../../assets/svgs/loginLinesCenter.svg";
import { ReactComponent as LoginLineLeft } from "../../../../assets/svgs/loginLinesLeft.svg";
import { ReactComponent as LoginLineRight } from "../../../../assets/svgs/loginLinesRight.svg";
import { PlanusLogoDark } from '../../../../assets/svgs/iconsComponents/PlanusLogoDark';
import { GenericButton } from '../../../../components/planus2/Buttons/GenericButton';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import AuthService from '../../../../services/security/auth';
import { useUser } from '../../../../contexts/User';
import { toast } from 'react-toastify';

const schema = Yup.object({
  email: Yup.string()
    .email('Email inválido')
    .required('Campo obrigatório.'),
  password: Yup.string().required('Campo obrigatório.'),
  remember: Yup.boolean(),
});

type FormDataProps = {
  email: string;
  password: string;
  remember: boolean | undefined;
};

export function LoginPage() {
  const authService = new AuthService();
	const { setUserDetails, setLoadingUser} = useUser();

  const navigate = useHistory();

	const [loading, setLoading] = useState(false);

  const defaultValues: FormDataProps = {
    email: '',
    password: '',
    remember: window.localStorage.getItem('Lembrar_Senha') === 'checked',
  }

  const { watch, register, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const remember = watch('remember');

  const goToRegister = () => {
    navigate.push('cadastro')
  }

	const submit = (data: FormDataProps) => {
		setLoading(true);

		authService.login(
			data,
			(response: any) => {
				if (response.data) {
          console.log(response.data)
					setUserDetails(response.data.user_details);
					setLoadingUser(false);
				}
			},
			(error: any) => {
				toast.error(error.toString());
				setLoading(false);
			}		
		);
	};

  useEffect(() => {
		if (remember === true) {
			window.localStorage.setItem('Lembrar_Senha', 'checked');
		} else if (remember === false) {
			window.localStorage.setItem('Lembrar_Senha', 'noChecked');
		}
	}, [remember]);

  return (
    <div className='flex items-center justify-center w-full h-screen'>
      <LoginLineLeft className='absolute left-0 bottom-0 max-w-[30%]' />
      <LoginLineCenter className='absolute max-w-[60%]' />
      <LoginLineRight className='absolute right-0 top-0  max-w-[35%]' />

      <form onSubmit={handleSubmit(submit)} className='w-[37.5rem] max-w-[80%] max-h-[80%] rounded-xl bg-white z-10 overflow-y-auto'>
        <div className='flex items-center justify-center h-32 border-b border-ivory-primary'>
            <PlanusLogoDark className='w-44 h-11' />
        </div>

        <div className='flex flex-col items-center gap-4 py-10 px-10 sm:px-20 md:px-36'>
          <div className='flex flex-col gap-10 text-center'>
            <p className='font-black text-xl'>Bem-vindo(a)</p>
            <p className='font-medium text-xl leading-6'>
              Por favor, insira suas credencias para continuar.
            </p>
          </div>

          <div className='flex flex-col gap-4 w-full border-y border-ivory-primary py-5'>
            <div className='flex flex-col w-full'>
              <label className='mb-1' htmlFor="email">E-mail:</label>
              <input
                className='w-full h-10 px-4 rounded-md border border-ivory-primary-lighter'
                type="email"
                id="email"
                placeholder="Insira seu e-mail"
                {...register('email')}
              />
            </div>

            <div className='flex flex-col w-full'>
              <label className='mb-1' htmlFor="password">Senha</label>
              <input
                className='w-full h-10 px-4 rounded-md border border-ivory-primary-lighter'
                type="password"
                id="password"
                placeholder="Senha"
                {...register('password')}
              />
            </div>

            <div className='flex justify-between'>
              <div>
                <input type="checkbox" id='remember' {...register('remember')}  />
                <label htmlFor="remember" className='pl-2 mb-0'>
                  Lembrar
                </label>
              </div>

              <a href="" className='underline'>Esqueci a senha</a>
            </div>

            <GenericButton loading={loading} disabled={loading} className='w-full h-10 text-white bg-ivory-green'>
              Entrar
            </GenericButton>
          </div>

          <div className='flex flex-col w-full text-center gap-3'>
            <p className='font-black'>Não possui uma conta?</p>

            <GenericButton loading={loading} disabled={loading} type='button' className='w-full h-10 text-white bg-ivory-primary' onClick={goToRegister}>
              Cadastrar
            </GenericButton>
          </div>
        </div>
      </form>
    </div>
  )
}