import moment from 'moment';

export const getDeliveryDateFilters = (
	status: string,
	startDate: Date | undefined,
	endDate: Date | undefined,
) => {
	let data_conclusao = '';
	let data_conclusao__gte = '';
	let data_conclusao__lte = '';
	const today = new Date();

	if (status === 'custom' && startDate && endDate) {
		data_conclusao__lte = moment(endDate).format('YYYY-MM-DD');
		data_conclusao__gte = moment(startDate).format('YYYY-MM-DD');
	} else if (status === 'onTime') {
		data_conclusao__gte = moment(today.setDate(today.getDate() + 1)).format(
			'YYYY-MM-DD',
		);
	} else if (status === 'deadLine') {
		data_conclusao__lte = moment(today.setDate(today.getDate() - 1)).format(
			'YYYY-MM-DD',
		);
	} else if (status === 'endsToday') {
		data_conclusao = moment(today).format('YYYY-MM-DD');
	}

	return { data_conclusao, data_conclusao__gte, data_conclusao__lte };
};
