import React, { useEffect, useState } from 'react';

import delivery from '../../../assets/svgs/delivery.svg';
import clock from '../../../assets/svgs/clock.svg';
import delivery2 from '../../../assets/svgs/delivery2.svg';

import '../../../i18n';

import './style.css';
import { useTranslation } from 'react-i18next';

const DisplayCounterDelivery = ({ dataTarefa = [], onFilterEncerrados }) => {
	const { t } = useTranslation();
	const [datesByType, setDatesByType] = useState({
		onTime: { ids: [], dates: [] },
		endsToday: { ids: [], dates: [] },
		deadLine: { ids: [], dates: [] },
	});

	useEffect(() => {
		conclusionDates(dataTarefa, getDataAtual());
	}, [dataTarefa]);

	const getDataAtual = () => {
		const date = new Date();
		date.setHours(0, 0, 0, 0);
		const data = date.getTime();
		return data;
	};

	const checkDeliveryCondition = (dateConclusion, dataAtual) => {
		const deliveryDate = new Date(dateConclusion).getTime();

		if (deliveryDate >= dataAtual) {
			return 'onTime';
		} else if (deliveryDate < dataAtual) {
			return 'deadLine';
		}
	};

	const conclusionDates = (dates, dataAtual) => {
		const deliveryTypes = {
			onTime: { ids: [], dates: [] },
			deadLine: { ids: [], dates: [] },
			total: { ids: [], dates: [] },
		};
		let conclusionDatesArray = dates.map(date => ({
			ids: date.id,
			dates: date.termino_previsto,
		}));
		conclusionDatesArray.forEach(obj => {
			if (obj.dates) {
				deliveryTypes[checkDeliveryCondition(obj.dates, dataAtual)].ids.push(
					obj.ids,
				);
				deliveryTypes[checkDeliveryCondition(obj.dates, dataAtual)].dates.push(
					obj.dates,
				);
			} else {
				deliveryTypes.onTime.ids.push(obj.ids);
			}
			deliveryTypes.total.ids.push(obj.ids);
		});
		setDatesByType(() => deliveryTypes);
	};

	const checkQuantity = type =>
		datesByType[type] && datesByType[type].ids.length;

	return (
		<span className="display-counter-container">
			<ButtonCount
				type="total"
				quantify={checkQuantity('total')}
				// showHiddenByDateType={showHiddenByDateType}
				// selectedType={selectedType}
				text={t('Total de Projetos')}
				icon={delivery2}
			/>
			<ButtonCount
				type="onTime"
				quantify={onFilterEncerrados ? 0 : checkQuantity('onTime')}
				// showHiddenByDateType={showHiddenByDateType}
				// selectedType={selectedType}
				disabledBtn={onFilterEncerrados}
				text={t('No prazo Previsto')}
				icon={clock}
			/>
			<ButtonCount
				type="deadLine"
				quantify={onFilterEncerrados ? 0 : checkQuantity('deadLine')}
				// showHiddenByDateType={showHiddenByDateType}
				// selectedType={selectedType}
				disabledBtn={onFilterEncerrados}
				text={t('Prazo Excedido')}
				icon={delivery}
			/>
		</span>
	);
};

export default DisplayCounterDelivery;

const ButtonCount = ({ type, quantify, disabledBtn, text, icon, ...rest }) => {
	const activeFilter = false;
	const TYPES = {
		onTime: {
			button: {
				background:
					'radial-gradient(62.2% 62.2% at 50% 50%, #23D9B7 0%, #1BC8A8 100%)',
				boxShadow: activeFilter && '#20d3b25e 0px 0px 3pt 2pt',
				opacity: disabledBtn ? '0.3' : '1',
			},
			icon: {
				opacity: '0.4',
			},
		},

		deadLine: {
			button: {
				background:
					'radial-gradient(62.2% 62.2% at 50% 50%, #DA5461 0%, #D84B58 100%)',
				boxShadow: activeFilter && '#da515e4f 0px 0px 3pt 2pt',
				opacity: disabledBtn ? '0.3' : '1',
			},
			icon: {
				opacity: '0.3',
			},
		},
		total: {
			button: {
				background:
					'radial-gradient(62.2% 62.2% at 50% 50%, #29DFF0 0%, #4BCAD5 100%)',
				boxShadow: activeFilter && '#20d3b25e 0px 0px 3pt 2pt',
			},
			icon: {
				opacity: '0.9',
			},
		},
	};
	const Text = ({ text, ...rest }) => (
		<div>
			<div {...rest} style={{ paddingBottom: '2px' }}>
				{text}
			</div>
		</div>
	);
	
	return (
		<button className="btn-quantify" style={TYPES[type].button} {...rest}>
			<div style={{ padding: '1px', position: 'relative', width: '100%' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div className="txt-quatify">{quantify}</div>
					<Text text={text} className="btn-display-mobile" />
					<img
						className="btn-display-img"
						style={{
							opacity: TYPES[type].icon.opacity,
						}}
						src={icon}
						alt={quantify}
					></img>
				</div>
				<Text text={text} className="btn-display-default" />
			</div>
		</button>
	);
};
