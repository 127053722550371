import styled from "styled-components";

export const ValueContainer = styled.div`
    display: flex !important;
	gap: 16px;
	align-items: center;
	overflow: hidden;
	padding-left: 12px !important;
	gap: 16px;
	align-items: center;
	
	max-height: 100%;
	overflow: hidden;
	.icons {
		display: flex;
		align-items: center;
	}
	.kanban-search-icon {
		display: none;

		@media (min-width: 1200px) {
			display: initial;
		}
	}
`

export const TextContainer = styled.div`
	color: rgb(73, 96, 112);
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    margin: 0px;
`