import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Formik, Form as FormikForm } from 'formik';
import FieldFormik from '../../../../components/common/Fields/FieldFormik';
import { useTranslation } from 'react-i18next';
import { ObjetivoSchema } from './validation';
import { SelectOptions } from '../../../../models/Select';
import AsyncSelector from '../../../../components/common/AsyncSelector';
import CicloService from '../../../../services/providers/okrs/ciclo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ObjetivoService from '../../../../services/providers/okrs/objetivo';
import { toast } from 'react-toastify';
import { ObjetivoProps, ObjetivoResponse } from '../../../../services/providers/okrs/objetivo/types';
import { useSetAtom } from 'jotai';
import { objetivosManagerAtom } from '../../../../atoms/Okrs';
import TimeOkrsService from '../../../../services/providers/okrs/times';

interface ObjetivoFormProps {
	objetivo?: ObjetivoResponse;
	editing: boolean;
	loading: boolean;
	setLoading: (loading: boolean) => void;
	setModalShow: (loading: boolean) => void;
}

const ObjetivoForm = ({
	objetivo,
	editing = false,
	loading = false,
	setLoading,
	setModalShow,
}: ObjetivoFormProps) => {
	const { t } = useTranslation();
	const cicloService = new CicloService();
	const timeService = new TimeOkrsService();
	const objetivoService = new ObjetivoService();

	const tempEditing = editing && objetivo !== undefined

	const initialValues: ObjetivoProps = tempEditing ? {
		time: objetivo.time,
		nome: objetivo.nome,
		ciclo: objetivo.ciclo,
		descricao: objetivo.descricao,
	} : {
		time: '',
		nome: '',
		ciclo: '',
		descricao: '',
	};

	const [timeValorSelecionado, setTimeValorSelecionado] = useState<
		SelectOptions
	>({ value: '', label: t('Selecione um time') });
	const [cicloValorSelecionado, setCicloValorSelecionado] = useState<
		SelectOptions
	>({ value: '', label: t('Selecione um ciclo') });

    const setObjetivosManager = useSetAtom(objetivosManagerAtom)

	const handleAfterPostObjetivo = (objetivoResponse: ObjetivoResponse) => {
		setObjetivosManager(prev => {
			const prevObjetivos = prev.objetivosManager[objetivoResponse.time]?.objetivos ?? []
			const tempObjetivos = [...prevObjetivos, objetivoResponse]

			return {
				objetivosManager: {
					...prev.objetivosManager,
					[objetivoResponse.time]: {
						...prev.objetivosManager[objetivoResponse.time],
						objetivos: tempObjetivos,
					}
				}
			}
		})
	}

	const handleAfterPutObjetivo = (objetivoResponse: ObjetivoResponse) => {
		setObjetivosManager(prev => {
			const prevObjetivos = prev.objetivosManager[objetivoResponse.time]?.objetivos ?? []
			const tempObjetivos = [...prevObjetivos]
			const findIndex = tempObjetivos.findIndex(item => item.id === objetivoResponse.id)

			if(findIndex > -1) {
				const [removed] = tempObjetivos.splice(findIndex, 1)
				tempObjetivos.splice(findIndex, 0, {...removed, ...objetivoResponse})
			}

			return {
				objetivosManager: {
					...prev.objetivosManager,
					[objetivoResponse.time]: {
						...prev.objetivosManager[objetivoResponse.time],
						objetivos: tempObjetivos,
					}
				}
			}
		})
	}

	const _postObjetivo = (params: ObjetivoProps) => {
		setLoading(true);
		objetivoService.postObjetivo(
			params,
			response => {
				if (response) {
					toast.success(t('Objetivo salvo com sucesso!'));
					setLoading(false);
					setModalShow(false);
					handleAfterPostObjetivo(response.data)
				}
			},
			() => {
				toast.error(t('Erro ao tentar salvar o objetivo!'));
				setLoading(false);
			},
		);
	};

	const _patchObjetivo = (params: ObjetivoProps, objetivo: ObjetivoResponse) => {
		setLoading(true);
		objetivoService.patchObjetivo(
			objetivo.id,
			params,
			response => {
				if (response) {
					toast.success(t('Objetivo editado com sucesso!'));
					setLoading(false);
					setModalShow(false);
					handleAfterPutObjetivo(response.data)
				}
			},
			() => {
				toast.error(t('Erro ao tentar editar o objetivo!'));
				setLoading(false);
			},
		);
	};

	const _getObjetivo = (objetivo: ObjetivoResponse) => {
		setLoading(true);
		const promises = [
			cicloService.getCiclo(objetivo.ciclo),
			objetivoService.getTime(objetivo.time),
		];

		Promise.all(promises)
			.then(([cicloResultado, timeResultado]) => {
				if (cicloResultado) {
					const { id, nome } = cicloResultado.data;
					initialValues.ciclo = id;
					setCicloValorSelecionado({
						value: id,
						label: nome,
					});
				}

				if (timeResultado) {
					const { id, nome } = timeResultado.data;
					initialValues.time = id;
					setTimeValorSelecionado({
						value: id,
						label: nome,
					});
				}
			})
			.catch(() => {
				toast.error(t('Erro ao tentar encontrar para edição!'));
			})
			.finally(() => setLoading(false));
	};

	const _getCurrentCiclo = async () => {
		setLoading(true);
		const ciclosResponse = await cicloService.getCicloAtual()

		if(ciclosResponse){
			initialValues.ciclo = ciclosResponse.id
			setCicloValorSelecionado({
				label: ciclosResponse.nome,
				value: ciclosResponse.id
			})
		}

		setLoading(false);
	};

	useEffect(() => {
		if (tempEditing) {
			_getObjetivo(objetivo)
		} else {
			_getCurrentCiclo()
		}
		
	}, []);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={ObjetivoSchema}
			onSubmit={values => {
				tempEditing ? _patchObjetivo(values, objetivo) : _postObjetivo(values);
			}}
		>
			{({ errors, touched, setFieldValue }) => {
				return (
					<FormikForm className="modal-width-objetivos">
						<div className="row">
							<div className="col-sm-12 col-md-6">
								<label className="modal-label" htmlFor={'time'}>
									{`${t('Time')}*`}
								</label>
								<AsyncSelector
									id={'time'}
									value={timeValorSelecionado}
									onChange={e => {
										const selected = e as SelectOptions;

										setTimeValorSelecionado(selected);
										setFieldValue(
											'time',
											selected !== null ? selected.value : '',
										);
									}}
									FieldName={'time'}
									placeholder={t('Selecione um ciclo')}
									error={errors.time ? errors.time : ''}
									touched={touched.time ? touched.time : false}
									loadOptions={timeService.loadTimesSelect as any}
									name="time"
									errorMessage={true}
									disabled={loading}
								/>
								<FieldFormik
									disabled={loading}
									title={`${t('Título do Objetivo')}*`}
									autoComplete={'off'}
									nameField="nome"
									errors={errors.nome ? errors.nome : ''}
									touched={touched.nome ? touched.nome : false}
									name="nome"
								/>
								<label className="modal-label" htmlFor={'ciclo'}>
									{`${t('Ciclo')}*`}
								</label>
								<AsyncSelector
									id={'ciclo'}
									value={cicloValorSelecionado}
									onChange={e => {
										const selected = e as SelectOptions;
										setCicloValorSelecionado(selected);
										setFieldValue(
											'ciclo',
											selected !== null ? selected.value : '',
										);
									}}
									FieldName={'ciclo'}
									placeholder={t('Selecione um ciclo')}
									error={errors.ciclo ? errors.ciclo : ''}
									touched={touched.ciclo ? touched.ciclo : false}
									loadOptions={cicloService.loadCiclosSelect as any}
									name="ciclo"
									errorMessage={true}
									disabled={loading}
								/>
							</div>
							<div className="col-sm-12 col-md-6">
								<label className="modal-label" htmlFor="descricao">
									{`${t('Descrição geral')}`}
								</label>

								<Field
									style={{
										background: '#fff',
									}}
									disabled={loading}
									autoComplete={'off'}
									type="text"
									component="textarea"
									name="descricao"
									className={`text-area-side ${
										errors.descricao && touched.descricao
											? 'form-error-style-textarea-projetos'
											: 'form-default-style-textarea-projetos'
									}
                                                    `}
								/>
								<ErrorMessage name="descricao">
									{msg => (
										<div>
											<div className="input-error-style" role="alert">
												<FontAwesomeIcon
													icon={faExclamationTriangle}
													className="mr-1"
												/>
												{msg}
											</div>
										</div>
									)}
								</ErrorMessage>
							</div>
						</div>
						<input
							style={{ display: 'none' }}
							id="formSideModal-objetivo"
							type="submit"
						/>
					</FormikForm>
				);
			}}
		</Formik>
	);
};

export default ObjetivoForm;
