import styled from "styled-components";


interface ThProps {
    width?: string;
    editable?: boolean;
}

export const Th = styled.th<ThProps>`
    height: 100%;
    font-size: 14px;
    text-align: start;
    white-space: nowrap;
    border-top: 2px solid transparent;
    min-width: ${({width}) => width}px;
    pointer-events: ${({editable}) => editable ? "initial" : "none"};

    input {
        font-weight: 500;
        color: rgb(64, 97, 115);
        padding: 0;
    }

`

export const ContentDiv = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding-right: 20px;

    .handle-drag-header {
        position: absolute;
        left: 2px;
        display: none;
    }

    .delete-header {
        position: absolute;
        right: 8px;
        display: none;
        margin-top: 1px;
    }

    &:hover {
        .delete-header, .handle-drag-header {
            display: initial;
        }
    }
`;