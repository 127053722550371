import React, { ReactNode } from "react";
import ActionButton from "../../ActionButton";

interface ActionsCellProps {
    icons: IconProps[];
}

interface IconProps {
    icon: ReactNode;
    onClick?: () => void;
}

export default function ActionsCell({icons}: Readonly<ActionsCellProps>) {
    return <td className="px-2">
        <div className="flex flex-row gap-5">
            {icons.map(icon => {
                return <ActionButton onClick={icon.onClick} key={icon.icon?.toString()}>
                    {icon.icon}
                </ActionButton>
            })}
        </div>
    </td>
}