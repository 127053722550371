import styled from "styled-components";

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
`;

export const Textarea = styled.textarea`
	height: 100%;
	min-height: max-content;
	outline: none;
	border: 1px solid black;
	border-radius: 8px;
	resize: none;
	width: 100%;

`

export const FieldContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const SelectTaskContainer = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;

    > div {
        width: 100%;
    }

    .search-modal, .search-modal-open {
        color: #426172;
    }
`;

export const SelectDateContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 16px;

    .select-data-input {
        background-color: #fff !important;
        border-radius: 7px !important;
        display: block !important;
        width: 100% !important;
        padding: 7px !important;
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid #000 !important;
        height: 42px !important;
        color: #000 !important;
        font-size: 1rem !important;
        margin-bottom: 0 !important;

        input {
            font-size: 1rem !important;
            color: #000 !important;
        }
    }

    .select-error-active {
        margin-bottom: 18px !important;
    }
`;

export const LabelCheckbox = styled.label`
    margin: 0;
    cursor: pointer;
    
`;


export const Label = styled.label`
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto, 'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji', 'Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';

`

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -15px;
`;