import React from 'react';

import { ErrorMessage } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { InputError } from './styled';

interface ErrorMsgProps {
	name: string;
}

const ErrorMsg = ({ name }: ErrorMsgProps) => {
	return (
		<ErrorMessage name={name}>
			{msg => {

				return (
					<div>
						<InputError role="alert">
							<FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
							{msg}
						</InputError>
					</div>
				);
			}}
		</ErrorMessage>
	);
};

export default ErrorMsg;
