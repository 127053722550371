import styled from 'styled-components';

export const PageContainer = styled.div`
	transition: all 2s ease 0s;
	display: flex;
	overflow: auto;
`;

export const InfoBodyContainer = styled.div`
	width: 100%;
`;

export const InfoContainer = styled.div`
	background: #fff;
	border-radius: 10px;
	margin-top: 20px;
`;

export const InfoTitle = styled.div`
	font-weight: 500;
	color: #8099a7;
	font-size: 16px;
	padding: 10px 15px 10px 15px;
	border-bottom: 2px solid #e9eef1;
`;

export const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: calc(100vh - 200px);
`;

export const Loader = styled.div`
	border: 6px solid #f3f3f3;
	border-radius: 50%;
	border-top: 6px solid #355060;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
`;

interface InfoContentProps {
	isAssigning?: boolean;
}

export const InfoContent = styled.div<InfoContentProps>`
	font-size: 16px;
	color: #406173;
	font-weight: normal;
	min-height: 60px;

	.paginacao {
		max-height: 200px;
		height: initial !important;
		transition: all 0.2s;
	}
`;
