import React from 'react';
import { GroupBase, MultiValueGenericProps } from 'react-select';
import { SelectOptions } from '../../../../../models/Select';
import { SearchIcon } from '../../../../../components/common/SelectDropdowmIndicator/styled';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TextContainer, ValueContainer } from './styled';
import { BiX } from 'react-icons/bi';
import { t } from 'i18next';
export const ProjectMultiValueContainer = (
	props: MultiValueGenericProps<
		SelectOptions,
		boolean,
		GroupBase<SelectOptions>
	> & {selectProps: any},
) => {
	const {
		selectProps: { value, additional, onChange, inputValue},
		data,

		
	} = props;
	const selectedValues = value as SelectOptions[];
	const plusValues =
		selectedValues.length > 1 ? `(+${selectedValues.length - 1})` : '';
	let filterLabel = additional?.filterName ?? '';
	if((data.label === "Eu" || data.label === additional.filterName) && selectedValues.length > 1) {
		filterLabel = selectedValues[1].label;
	}else if(data.label !== "Eu" && data.label !== additional.filterName) {
		filterLabel = data.label;
	}

	const resetFilter = () => {
		onChange([{label: t("Selecionar Projeto"), value: ""}])
	};
	return selectedValues.length > 0 && selectedValues[0].value === data.value ? (
		<ValueContainer>
			<div className='icons'>
				<SearchIcon  icon={faSearch} className="kanban-search-icon"/>
				<BiX size={20}  color='#426172' title='Limpar filtro' onClick={resetFilter} />
			</div>			
			{inputValue.length === 0
				? <>
				<TextContainer>{filterLabel}</TextContainer>
				{plusValues}</>
				: null}
			
		</ValueContainer>
	) : null;
};
