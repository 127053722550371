export const COLORS_OPTIONS = [
    {
        label: '',
        value: '#874D31',
        color: '#874D31',
    },
    {
        label: '',
        value: '#876A31',
        color: '#876A31',
    },
    {
        label: '',
        value: '#878731',
        color: '#878731',
    },
    {
        label: '',
        value: '#6A8731',
        color: '#6A8731',
    },
    {
        label: '',
        value: '#4D8731',
        color: '#4D8731',
    },
    {
        label: '',
        value: '#318731',
        color: '#318731',
    },
    {
        label: '',
        value: '#31874D',
        color: '#31874D',
    },
    {
        label: '',
        value: '#31876A',
        color: '#31876A',
    },
    {
        label: '',
        value: '#318787',
        color: '#318787',
    },
    {
        label: '',
        value: '#316A87',
        color: '#316A87',
    },
    {
        label: '',
        value: '#314D87',
        color: '#314D87',
    },
    {
        label: '',
        value: '#313187',
        color: '#313187',
    },
    {
        label: '',
        value: '#4D3187',
        color: '#4D3187',
    },
    {
        label: '',
        value: '#6A3187',
        color: '#6A3187',
    },
    {
        label: '',
        value: '#873187',
        color: '#873187',
    },
    {
        label: '',
        value: '#87316A',
        color: '#87316A',
    },
    {
        label: '',
        value: '#87314D',
        color: '#87314D',
    },
    {
        label: '',
        value: '#873131',
        color: '#873131',
    },

]