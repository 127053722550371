import React, { HtmlHTMLAttributes } from "react"
import { twMerge } from "tailwind-merge"

interface NotFoundRowProps extends HtmlHTMLAttributes<HTMLDivElement> {
    readonly text: string
}

export function NotFoundRow({className, text, ...props}: NotFoundRowProps) {
    const classes = twMerge(
        `flex items-center justify-center gap-4 h-12 border-y border-y-white px-4 items-center text-ivory-primary font-medium bg-[#F7F6F5] `,
        className
    )

    return (
        <div className={classes} {...props}>
            {text}
        </div>
    )
}