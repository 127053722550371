import React, { useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { Resizable } from 're-resizable';

import {
	TitleContainer,
	ColumnContent,
	TaskContent,
	Title,
	TextDescription,
	HourContainer,
} from './styled';

function ResizableKanban() {
	const date = new Date();
	const items = [
		{
			id: uuid(),
			name: 'Implantação Drops',
			content: 'Reunião com a Equipe',
			height: 75,
			color: '#31885e',
			hours: date.setHours(0, 30),
		},
		{
			id: uuid(),
			name: 'Entrevista com Usuários',
			content: 'Festinha de sexta',
			height: 75,
			color: '#315e88',
			hours: date.setHours(0, 30),
		},
		{
			id: uuid(),
			name: 'Atividades Internas',
			content: 'Comemoração',
			height: 75,
			color: '#887031',
			hours: date.setHours(0, 30),
		},
	];

	const initialColumns = {
		[uuid()]: {
			name: 'Segunda-feira',
			date: '29 de Dezembro',
			items: items,
		},
		[uuid()]: {
			name: 'Terça-feira',
			date: '30 de Dezembro',
			items: [],
		},
		[uuid()]: {
			name: 'Quarta-feira',
			date: '31 de Dezembro',
			items: [],
		},
	};

	const [columns, setColumns] = useState(initialColumns);

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}

		const { source, destination } = result;

		if (source.droppableId !== destination.droppableId) {
			const sourceColumn = columns[source.droppableId];
			const destColumn = columns[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);

			destItems.splice(destination.index, 0, removed);

			setColumns({
				...columns,
				[destination.droppableId]: {
					...destColumn,
					items: destItems,
				},
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems,
				},
			});
		} else {
			const column = columns[source.droppableId];
			const copiedItems = [...column.items];
			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);

			setColumns({
				...columns,
				[source.droppableId]: {
					...column,
					items: copiedItems,
				},
			});
		}
	};

	const getItemStyle = (
		isDragging: boolean,
		draggableStyle: any,
		item: any,
	) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',

		// change background colour if dragging
		background: isDragging ? 'rgb(232, 232, 232)' : 'transparent',
		flex: item.dataIndex === 'name' ? 1 : undefined,
		fontWeight: 500,
		color: 'rgba(0, 0, 0, 0.85)',

		// styles we need to apply on draggables
		...draggableStyle,
	});

	return (
		<>
			<DragDropContext onDragEnd={(result: any) => onDragEnd(result)}>
				{Object.entries(columns).map(([id, column]) => {
					return (
						<div style={{ marginRight: 8 }} key={id}>
							<TitleContainer>
								<h3>{column.name}</h3>
								<h3>{column.date}</h3>
							</TitleContainer>

							<Droppable droppableId={id} direction="vertical">
								{(provided, snapshot) => (
									<ColumnContent
										{...provided.droppableProps}
										ref={provided.innerRef}
										isDragging={snapshot.isDraggingOver}
									>
										{column.items.map((item, index) => {
											return (
												<div key={item.id}>
													<Draggable index={index} draggableId={item.id}>
														{(provided, snapshot) => {
															return (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style,
																		item,
																	)}
																>
																	<Resizable
																		defaultSize={{
																			width: 'auto',
																			height: item.height,
																		}}
																		minHeight={75}
																		grid={[0, 25]}
																		onResizeStop={(e, direction, ref, d) => {
																			if (d.height !== 0) {
																				const distance = d.height;
																				const operation = distance > 0 ? 1 : -1;
																				const minutesToIncrease =
																					30 * (Math.abs(distance) / 25);

																				const newItems = column.items.map(
																					current =>
																						current.id == item.id
																							? {
																									...current,
																									height:
																										item.height + distance,
																									hours: new Date(
																										item.hours,
																									).setMinutes(
																										new Date(
																											item.hours,
																										).getMinutes() +
																											minutesToIncrease *
																												operation,
																									),
																							} : current,
																				);

																				setColumns({
																					...columns,
																					[id]: {
																						...column,
																						items: newItems,
																					},
																				});
																			}
																		}}
																		enable={{
																			top: true,
																			bottom: true,
																			right: false,
																			left: false,
																			topRight: false,
																			bottomRight: false,
																			bottomLeft: false,
																			topLeft: false,
																		}}
																		style={{
																			padding: '8px 8px 8px 4px',
																			borderLeft:
																				'solid 1px rgb(232, 232, 232)',
																			backgroundColor: item.color,
																			marginBottom: '14px',
																			borderRadius: '4px',
																		}}
																	>
																		<TaskContent {...provided.dragHandleProps}>
																			<div>
																				<Title>{item.name}</Title>
																				<TextDescription>
																					{item.content}
																				</TextDescription>
																			</div>
																			<HourContainer>
																				<p>
																					{String(
																						new Date(
																							item.hours,
																						).toLocaleTimeString([], {
																							hour: '2-digit',
																							minute: '2-digit',
																						}),
																					)}
																				</p>
																			</HourContainer>
																		</TaskContent>
																	</Resizable>
																</div>
															);
														}}
													</Draggable>
												</div>
											);
										})}
										{provided.placeholder}
									</ColumnContent>
								)}
							</Droppable>
						</div>
					);
				})}
			</DragDropContext>
		</>
	);
}

export default ResizableKanban;
