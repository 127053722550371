import React from "react";
import { GroupBase } from "react-select";
import { LoadOptions } from "react-select-async-paginate";
import { CommonModel, CommonModelWithClient } from "../../models/CommonModel";
import { LoadOptionsAdditional, SelectOptions, SelectOptionsAPI } from "../../models/Select";
import { Task } from "../../models/Task";

export const formatValuesToSelect = (arrayValue: SelectOptionsAPI[], setValue: React.Dispatch<SelectOptions[]>, all: boolean = true) => {
	const options: SelectOptions[] = [];
	arrayValue.forEach((item, index) => {
		if (index === 0 && all) {
			options.push(
				{ value: '', label: 'Todos' },
				{ value: item.id, label: item.nome },
			);
		} else {
			options.push({ value: item.id, label: item.nome });
		}
	});
	setValue(options);
};

export const formatValuesToSelectAsync = (arrayValue: CommonModelWithClient[], placeholder: string, isKanban=false) => {
	const options: SelectOptions[] = [];
	arrayValue?.forEach((item, index) => {
		if (placeholder?.length > 0 && index === 0) {
			options.push(
				{ value: '', label: placeholder, labelAux: '', valueAux: '', isKanban: isKanban},
				{ value: item.id, label: item.nome, labelAux: item?.cliente?.nome, valueAux: item?.cliente?.logo_cliente, isKanban: isKanban, image: item?.cliente?.logo_cliente },
			);
		} else {
			options.push({ value: item.id, label: item.nome, labelAux: item?.cliente?.nome, valueAux: item?.cliente?.logo_cliente, isKanban: isKanban, image: item?.cliente?.logo_cliente });
		}
	});
	return options;
};

export const formatTaskWithProjectToSelect = (
	arrayValue: Task[],
	placeholder: string,
	isProjectSelected: boolean,
) => {
	let options: SelectOptions[] = [];
	if (isProjectSelected) {
		options = formatValuesToSelectAsync(arrayValue as CommonModel[], placeholder);
	} else {
		arrayValue.forEach((item, index) => {
			if (placeholder?.length > 0 && index === 0) {
				options.push(
					{ value: '', label: placeholder, labelAux: '', valueAux: '' },
					{
						value: item.id,
						label: item.nome,
						labelAux: item.projeto_nome || '',
						valueAux: item.projeto || '',
					},
				);
			} else {
				options.push({
					value: item.id,
					label: item.nome,
					labelAux: item.projeto_nome || '',
					valueAux: item.projeto || '',
				});
			}
		});
	}
	return options;
};

export const emptyLoadOptions: LoadOptions<any, GroupBase<any>, LoadOptionsAdditional> = (
) => {
	return {
		options: [],
		hasMore: false,
	};
};

