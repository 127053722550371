import styled from "styled-components";
import { SCALEMULT, theme } from "../../../styles/theme";
import { FaRegStopCircle } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";

export const HeaderContainer = styled.div`
    position: relative;
    background-color: ${theme.colors.background.modalBg};
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    margin-right: 20px;
    gap: 10px;
    height: ${SCALEMULT * 3.7}vw;
    min-height: 46px;    
    max-width: ${SCALEMULT * 35}vw;

    @media (max-width: 575.98px) {
        max-width: initial;
    }
`

export const IconContainer = styled.button`
    background-color: ${theme.colors.button.saveBtn};
    border-radius: 10px;
    aspect-ratio: 1 / 1;
	height: ${SCALEMULT * 2.5}vw !important;
	width: ${SCALEMULT * 2.5}vw;
	min-height: 34px !important;
	min-width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
    &:hover {
        background-color: #4aceda
    }

    svg, img {
		font-size: calc(18px + ${SCALEMULT * 0.3125}vw) !important;
		width: calc(18px + ${SCALEMULT * 0.3125}vw) !important;
	}
`;

export const HourIcon = styled.img`
    width: 20px;
`;

export const StopIcon = styled(FaRegStopCircle)`
    font-size: 20px;
`;

export const TaskNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${theme.colors.text.inputText};
    max-width: 53%;
    overflow: hidden;
`;

export const TaskName = styled.span`
    font-size: 1.25rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    line-height: 20px;
    overflow: hidden;
    max-width: 100%;
    overflow: hidden;
`;

export const ProjectName = styled.span`
    font-size: 0.625rem;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
`;

export const ThreeDotsIcon = styled(BsThreeDotsVertical)`
    color: ${theme.colors.button.saveBtn};
    cursor: pointer;
`;

export const DotsContainer = styled.div`
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MenuContainer = styled.div`
    position: absolute;
    top: 0;
    left: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    gap: 2px;
    background: #fff;
    padding: 6px 8px;
    width: unset;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

`;

export const MenuOptions = styled.div`
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    &:not(:first-child) {
        border-top: 1px solid rgba(66, 97, 114, 0.3) ;
        padding-top: 4px;

    }
    display: flex;
    flex-direction: row;
    gap: 4px;
    text-align: center;
    color: ${theme.colors.background.mainBg};
`;

