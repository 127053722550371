import styled from "styled-components";

export const InputError = styled.div`
    height: 24px;
	margin-top: -18px;
	color: #f16d84;
	font-size: 12px;
	font-weight: 500;
	border-radius: 6px;
	justify-content: center;
	align-items: center;
`