import React, { Dispatch, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import { LoaderGenerico } from '../modalStyled';

import { ButtonContainer, ConfirmModalSection, ModalContent } from './styled';

export interface ConfirmModalProps {
    isConfirmModalOpen: boolean;
    setIsConfirmModalOpen: Dispatch<boolean>;
    handleConfirm: () => void;
    text?: string;
    loading?: boolean;
}

export function ConfirmModal({isConfirmModalOpen, loading, text, setIsConfirmModalOpen, handleConfirm}: ConfirmModalProps) {
	const { t } = useTranslation();

    const handleCloseModal = useCallback(() => setIsConfirmModalOpen(false), [setIsConfirmModalOpen]);

    const handleClickConfirm = useCallback(() => {
        handleConfirm();
        handleCloseModal();
    }, [handleConfirm, handleCloseModal]);    

    const onFocusModal = useCallback((event: React.MouseEvent) => {
		const target = event.target as HTMLInputElement;
		if (target.className === 'confirm-modal-align') {
			handleCloseModal();
		}
	}, [handleCloseModal]);
    
    return(
        isConfirmModalOpen || loading === true ? (
        <ConfirmModalSection
            id="swipe-panel"
            onMouseDown={onFocusModal}
        >
            <div className="confirm-modal-align">
                <ModalContent>
                    <div className='confirm-modal'>
                        <p>{t(text || 'Confirmar exclusão?')}</p>
                        <ButtonContainer>
                            <button 
                                className='confirm-modal-button confirm-button' 
                                type='button'
                                onClick={handleClickConfirm}
                            >
                                {loading ? <LoaderGenerico /> : t('Confirmar')}
                            </button>
                            <button 
                                className='confirm-modal-button cancel-button'
                                type='button'
                                onClick={handleCloseModal}
                                disabled={loading}
                            >
                               {loading ? <LoaderGenerico /> : t('Cancelar')}
                            </button>
                        </ButtonContainer>
                    </div>
                </ModalContent>
            </div>
        </ConfirmModalSection>
        ) : <></>
    )
}