import React, { useCallback, useState } from 'react';
import i18n from '..';

import imgBR from '../../assets/images/selectLanguage/ptBR.png';
import imgUS from '../../assets/images/selectLanguage/enUS.png';
import styled from 'styled-components';
import { t } from 'i18next';

const LanguageWrapper = styled.div`
    position: relative;
	display: flex;
	gap: 8px;
`;

const LanguageOption = styled.div`
	display: flex;
    align-items: flex-end;
    background-color: #fff;
    padding: 4px;
    transition: all 0.2s;

    cursor: pointer;

	img {
		width: 20px;
		height: 20px;
	}

    &:hover {
        background-color: #ddd; 
    }
`;

const LanguageOptionsDiv = styled.div`
    background-color: #fff;
	display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
`;

const LanguageChange = () => {
	const options = [
		{ value: 'pt-BR', label: 'Português BR', image: imgBR },
		{ value: 'en-UK', label: 'English UK', image: imgUS },
		
	]

	const getCurrentLanguage = () => {
		return options.filter((option) => option.value === i18n.language)[0]
	}
	const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguage())
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	
    const toggleMenu = useCallback(() => {
        setIsMenuOpen(!isMenuOpen);
    }, [isMenuOpen, setIsMenuOpen]);
    
	function changeLang(language: string | undefined) {
		i18n.changeLanguage(language, (err: any) => {
			if (err) return console.log('something went wrong loading', err);
		}).then(() => {
			if(language) {
				localStorage.setItem("PlanusLanguage", language);
			}
			setSelectedLanguage(getCurrentLanguage());
            setIsMenuOpen(false);

            window.location.reload();
		});
	}	

	return (
        <LanguageWrapper>
            <LanguageOption onClick={toggleMenu} title={t("Trocar idioma")} style={{background: "transparent"}}>
                <img src={options.find(option => option.value === selectedLanguage?.value)?.image} alt="" />
            </LanguageOption>

            {isMenuOpen && (
                <LanguageOptionsDiv>
                {options.map(option => {
                    const handleChangeLang = () => {
                        changeLang(option.value.toString())
                    };

                    return (
                        <LanguageOption key={option.value} onClick={handleChangeLang}>
                            <img src={option.image} alt="" />
                        </LanguageOption>
                    )
                })}    
            </LanguageOptionsDiv>    
            )}
        </LanguageWrapper>
	);
};

export default LanguageChange;