import * as React from "react"
import { SVGProps } from "react"
const GraphMaintainIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="26" height="20" viewBox="0 0 26 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M25.8333 16.6667H3.33333V0.833333C3.33333 0.372917 2.96042 0 2.5 0H0.833333C0.372917 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.746354 20 1.66667 20H25.8333C26.2937 20 26.6667 19.6271 26.6667 19.1667V17.5C26.6667 17.0396 26.2937 16.6667 25.8333 16.6667Z" />
    <path d="M24.749 6.88861L20.3563 2.58652C19.561 1.80736 18.2204 2.37871 18.2318 3.49277L18.2568 5.87923V5.88809H5.79642C5.3084 5.88809 4.91309 6.2834 4.91309 6.77142V8.29382C4.91309 8.78184 5.3084 9.17715 5.79642 9.17715H18.2912V9.21204L18.3162 11.5985C18.3277 12.712 19.6798 13.2558 20.4589 12.46L24.7615 8.06673C25.0839 7.73757 25.0782 7.21048 24.749 6.88861Z" />
    </svg>


)
export default GraphMaintainIcon
