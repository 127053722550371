import styled from 'styled-components';

export const ModalContainerObjetivo = styled.div`
	.modal-bg-center {
		height: inherit !important;
		padding-bottom: 32px;
	}
	.modal-width-objetivos {
		width: 55vw;
	}
`;
