import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface ActionsContainerProps {
	hasSeparator: boolean;
}

export const ActionsMenuContainer = styled.div<ActionsContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	${({ hasSeparator }) =>
		hasSeparator &&
		`
            margin-right: 15px;
            padding-right: 15px;
            border-right: 1px solid #426172;
        `}
`;

export const ButtonContainer = styled.div`
	cursor: pointer;
	padding: 0 5px;
	width: 100%;
`;

export const IconActions = styled(FontAwesomeIcon)`
	color: #406173;
	font-size: 15px;
	transition: all 0.2s;
	&:hover {
		filter: brightness(0.8);
	}
	&:active {
		filter: brightness(0.6);
	}
`;
