import styled from 'styled-components';

export const PageContainerWrapper = styled.div`
	width: 100%;
	margin: auto;
	margin-top: 20px;

	.page-header {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		width: 90%;
		margin: auto;
		margin-bottom: 30px;
	}

	.page-component {
		display: flex;
		flex-direction: row;
	}
`;

export const RegistrosContainer = styled.div`
	margin: 2rem;
	cursor: initial;
	height: calc(100vh - 183px);
`;

export const RegistrosReportHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin: auto;
	margin-bottom: 15px;
`;

export const ContainerBody = styled.div`
	height: calc(100vh - 265px);
	overflow: auto;
	overflow-x: hidden;
`;

export const ContainerWide = styled.div`
	display: grid;
	grid-template-columns: 99.5% 0.5%;
	display: grid;
	transition: all 2s;
`;

export const AnimacaoRelatorio = styled.div`
	width: calc(100vh - 265px);
	margin: 0 auto;

	@media (max-width: 1000px) {
		display: none;
	}
`;

interface FilterContainerProps {
	hasShadow?: boolean;
}

export const FilterContainer = styled.div<FilterContainerProps>`
	background: white no-repeat;
	padding: 20px;
	transition: all 0.3s ease-out;
`;

interface FilterProps {
	filterOpen: boolean;
}

export const Filter = styled.div<FilterProps>`
	display: ${({ filterOpen }) => (filterOpen ? 'block' : 'none')};
`;
