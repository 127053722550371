import { ColumnManaged, ColumnsManager } from '../atoms/Kanban/types';
import { Client } from '../models/Client';
import { Column } from '../models/Column';
import { History } from '../models/History';
import { HoursRecord } from '../models/HoursRecord';
import { MainProject, Project } from '../models/Project';
import { Responsible } from '../models/Responsible';
import { SelectOptions } from '../models/Select';
import { Subtask } from '../models/Subtask';
import { Task } from '../models/Task';
import { status } from '../pages/Registros/Tarefas/Kanban/Kanban';

export const selectOption0: SelectOptions = {
	label: '',
	value: '',
};

export const responsavel0: Responsible = {
	foto_perfil: null,
	funcao: 'CTO Master Plus',
	id: 0,
	nome: 'Bruno Testes',
	tipo_visualizacao: 'g',
	acesso: 'g',
	carga_horaria: 40,
	custo_hora: 10,
	deleted: false,
	email: '',
	datahora_ultimo_login: null,
	valor_colunas_preenchidas: []
};

export const subtarefa0: Subtask = {
	completada: false,
	data_conclusao: '2022-11-29',
	id: 0,
	nome: 'Teste',
	tarefa: 0,
	pessoas_responsaveis: responsavel0,
	responsavel: responsavel0.id,
	ordenacao: 0
};

export const tarefa0: Task = {
	coluna_kanban: '0',
	data_conclusao: '2022-11-20',
	id: '0',
	nome: 'Tarefa 0',
	ordenacao: 0,
	projeto: 0,
	pessoas_responsaveis: [],
	status: 'done',
	total_subtarefas: 2,
	total_subtarefas_incompletas: 1,
	sub_tarefas: [subtarefa0],
	cliente: 0,
	cliente_nome: 'Cliente 0',
	projeto_nome: 'Projeto 0',
	duracao_total: '00:00:00',
	created_at: '',
	deleted: false,
	deleted_at: null,
	descricao: '',
	id_azure: null,
	id_azure_collection: null,
	responsavel: 0,
	updated_at: '',
};

//tarefa com pessoas responsáveis
export const tarefa1: Task = {
	coluna_kanban: '0',
	data_conclusao: '2022-11-20',
	id: '0',
	nome: 'Tarefa 0',
	ordenacao: 0,
	projeto: 0,
	pessoas_responsaveis: [
		{
			foto_perfil: '',
			funcao: '',
			id: 0,
			nome: 'pessoa 01',
			tipo_visualizacao: 'm',
			acesso: 'm',
			carga_horaria: 30,
			custo_hora: 10,
			deleted: false,
			email: 'emai@ivoryit.com.br',
			datahora_ultimo_login: null,
	valor_colunas_preenchidas: []

		},
	],
	status: 'done',
	total_subtarefas: 2,
	total_subtarefas_incompletas: 2,
	sub_tarefas: [subtarefa0],
	cliente: 0,
	cliente_nome: 'Cliente 0',
	projeto_nome: 'Projeto 0',
	duracao_total: '00:00:00',
	created_at: '',
	deleted: false,
	deleted_at: null,
	descricao: '',
	id_azure: null,
	id_azure_collection: null,
	responsavel: 0,
	updated_at: '',
};

export const cliente0: Client = {
	id: 0,
	nome: 'Cliente Teste',
	atividade: "",
	contato: "",
	created_at: "",
	deleted: false,
	deleted_at: null,
	logo_cliente: "",
	observacao: "",
	updated_at: "",
	user: 0
};

export const projeto0: Project = {
	admins: [],
	colunas_kanban_criadas: true,
	created_at: '',
	cliente: cliente0,
	deleted: false,
	deleted_at: '',
	empresa: 0,
	horas_previstas: 550,
	id: 0,
	id_azure: '0',
	inicio_previsto: '',
	investimento: '',
	is_member: true,
	item_ativo: true,
	nome: 'Projeto Teste',
	notas: '',
	primeira_coluna: 'Primeira coluna',
	tags: [],
	tarefas: 1,
	termino_previsto: '',
	time: [responsavel0],
	updated_at: '',
};

export const coluna0: Column = {
	id: '1',
	nome: 'coluna0',
	ordenacao: 1,
	projeto: projeto0.id,
	deleted: false,
	created_at: '',
	deleted_at: '',
	updated_at: '',
};

export const colunaGerenciada0: ColumnManaged<object> = {
	...coluna0,
	addedItemsIds: [],
	doneTotalPages: 0,
	items: [tarefa0],
	nome: coluna0.nome,
	order: coluna0.ordenacao,
	page: 1,
	removedQnt: 0,
	status: status[1],
	totalPages: 1,
};

export const gerenciador0: ColumnsManager<object> = {
	columns: { [colunaGerenciada0.id]: colunaGerenciada0 },
	columnsOrder: [colunaGerenciada0.id],
	project: projeto0,
};

export const history0: History = {
	created_at: '',
	data: '',
	deleted: false,
	descricao: 'descricao0',
	duracao: '',
	id: 0,
	id_objeto: 1,
	pessoa: {
		foto_perfil: '',
		funcao: '',
		id: 3,
		nome: 'pessoa3',
		tipo_visualizacao: 'm',
		acesso: 'm',
		carga_horaria: 30,
		custo_hora: 10,
		deleted: false,
		email: 'emai@ivoryit.com.br',
		datahora_ultimo_login: null,
	valor_colunas_preenchidas: []

	},
	tarefa: 0,
	tipo: 'm',
};

export const record0: HoursRecord = {
	data: '',
	deleted: false,
	descricao: 'descricao0',
	duracao: '',
	id: 0,
	cliente_nome: "",
	deleted_at: "",
	pessoa_custo: 0,
	pessoa_foto: "",
	pessoa_nome: "",
	projeto: 0,
	projeto_nome: "",
	tags: [],
	tarefa_nome: "",
	timestamp: "",
	pessoa: 0,
	tarefa: 0,
	tipo: 'trab',
};

export const pessoa0: Responsible = {
	foto_perfil: '',
	funcao: '',
	id: 3,
	nome: 'pessoa0',
	tipo_visualizacao: 'm',
	acesso: 'm',
	carga_horaria: 30,
	custo_hora: 10,
	deleted: false,
	email: 'emai@ivoryit.com.br',
	datahora_ultimo_login: null,
	valor_colunas_preenchidas: []
}

export const pessoa1: Responsible = {
	foto_perfil: '',
	funcao: '',
	id: 3,
	nome: 'pessoa1',
	tipo_visualizacao: 'm',
	acesso: 'm',
	carga_horaria: 30,
	custo_hora: 10,
	deleted: false,
	email: 'emai@ivoryit.com.br',
	datahora_ultimo_login: null,
	valor_colunas_preenchidas: []
}

export const project0: MainProject = {
	admins: [],
	cliente: {id:0, logo_cliente: '', nome: ''},
	colunas_kanban_criadas: false,
	created_at: '',
	deleted: false,
	deleted_at: '',
	empresa: 0,
	horas_previstas: 0,
	id: 0,
	id_azure: '0',
	inicio_previsto: '',
	investimento: '',
	is_member: true,
	item_ativo: true,
	nome: 'Projeto 01',
	notas: '',
	primeira_coluna: '',
	tags: [],
	tarefas: [],
	termino_previsto:'',
	time: [pessoa0, pessoa1],
	updated_at: '',
	coluna_kanban: ''

}

