import { AxiosResponse } from 'axios';
import HttpService from '../../../config/http';
import { PatchKeyResultProps, KeyResultProps, KeyResultResponse, GetKeyResultProps, KeyResultFitroResponse } from './types';
import { t } from 'i18next';
import { DataType } from '../../types';
import { LoadOptionsAdditional } from '../../../../models/Select';

export default class KeyResultService {
	private api: HttpService;
	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getKeyResultsById = async ({
		id
	}: {id:string}) => {
		const response: AxiosResponse<KeyResultResponse> = await this.api
			.get(`api/v1/okrs/keyresults/${id}/`)
			.catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t('Erro ao tentar salvar o key result!'));
	};

	getKeyResultsByObjetivo = async ({
			objetivoId,
			page
		}: GetKeyResultProps) => {
		const response: AxiosResponse<DataType<KeyResultResponse[]>> = await this.api
			.get(`api/v1/okrs/keyresults/?objetivo=${objetivoId}&paginate=true&page=${page}`)
			.catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t('Erro ao tentar salvar o key result!'));
	};

	postKeyResult = async (
		params: KeyResultProps,
	) => {
		const response: AxiosResponse<KeyResultResponse> = await this.api
			.post(`api/v1/okrs/keyresults/`, params)
			.catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 201) {
			return response.data
		}

		throw new Error(t('Erro ao tentar salvar o key result!'));
	};
	
	patchKeyResult = async (
		params: PatchKeyResultProps,
	) => {
		const response: AxiosResponse<KeyResultResponse> = await this.api
			.patch(`api/v1/okrs/keyresults/${params.id}/`, params)
			.catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t('Erro ao tentar salvar o key result!'));
	};

	deleteKeyResult = async (
		id: number,
	) => {
		const response: AxiosResponse<KeyResultResponse> = await this.api
			.delete(`api/v1/okrs/keyresults/${id}/`)
			.catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 204) {
			return response.data
		}

		throw new Error(t('Erro ao tentar deletar o key result!'));
	};

	finalizarKeyResult = async (
		id: number,
	) => {
		const response: AxiosResponse<KeyResultResponse> = await this.api
			.put(`api/v1/okrs/keyresults/finalizar/${id}/`, {})
			.catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t('Erro ao tentar deletar o key result!'));
	};

	resetarKeyResult = async (
		id: number,
	) => {
		const response: AxiosResponse<KeyResultResponse> = await this.api
			.put(`api/v1/okrs/keyresults/resetar/${id}/`, {})
			.catch((error) =>{
				throw new Error(error)
			});
		
		if(response.status === 200) {
			return response.data
		}

		throw new Error(t('Erro ao tentar deletar o key result!'));
	};

	getMedidas = 
	() => {
		return {
			options: [
				{value: "P", label: "Porcentagem"},
				{value: "R", label: "Reais"},
				{value: "N", label: "NPS"},
				{value: "T", label: "Transações"},
				{value: "M", label: "Minutos"},
				{value: "D", label: "Dias"},
			]
		}
	}

	loadKeyResultsSelect = async (
		search: string,
		loadedOptions: any,
		{ page = 1, ciclo }: LoadOptionsAdditional,
	) => {
		console.log(ciclo)
		const response: AxiosResponse<
			DataType<KeyResultFitroResponse[]>
		> = await this.api.get(`api/v1/okrs/keyresults/?search=${search}&paginate=true&page=${page}&objetivo__ciclo=${ciclo}`);

		if (response?.status !== 200) {
			if (typeof response?.data === typeof {}) {
				const objError = response?.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof typeof objError]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar buscar o ciclo!'));
			}
		}

		const arrayRenomeado = response?.data.results.map(objeto => ({
			label: objeto.nome,
			value: objeto.id
		}));	

		return {
			options: arrayRenomeado,
			hasMore: !!response?.data?.next,
			additional: {
				page: page + 1,
			},
		};
	};
}
