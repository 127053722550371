import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import KanbanContainer from '../../../../components/containers/Kanban/KanbanContainer';
import { useUser } from '../../../../contexts/User';
import { useAtom } from 'jotai';
import { columnsManagerAtom, initialValues } from '../../../../atoms/Kanban/KanbanProject';
import { ColumnManaged, ColumnsForManager } from '../../../../atoms/Kanban/types';
import { ProjectPage, ProjectBasic } from '../../../../models/Project';
import { useProject } from '../../../../contexts/Project';
import { DropResult } from 'react-beautiful-dnd';
import { KanbanProjectService } from '../../../../services/providers/kanbanProject';
import { v4 as uuid } from 'uuid';
import { t } from 'i18next';
import { status } from '../../Tarefas/Kanban/Kanban';
import { handleInitialOrderColumns } from '../../../../utils/funcoes/kanban';
import { dragColumn, dragTaskDifferentColumns, dragTaskSameColumn, updateColumnsValues } from '../../Tarefas/Kanban/functions';
import { toast } from 'react-toastify';
import KanbanBody from './KanbanBody';
import { RequestingAddColumn } from '../../../../contexts/Kanban/types';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';


interface KanbanProjectsProps {
	setEditingProject: Dispatch<SetStateAction<boolean>>;
	setSelectedProject: Dispatch<SetStateAction<ProjectBasic | undefined>>
}

export default function KanbanProject(props: Readonly<KanbanProjectsProps>) {
	const kanbanService = new KanbanProjectService();

	const { userDetails } = useUser();
	const [loadingKanban, setLoadingKanban] = useState(false);
	const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
	const [columnsManager, setColumnsManager] = useAtom(columnsManagerAtom);
	const [requestingAddColumn, setRequestingAddColumn] = useState<RequestingAddColumn>({ componentId: null });
	const [isAddingColumn, setIsAddingColumn] = useState<boolean>(false);

	const { searchProject, myProjects, clienteValorSelecionado, selectProjectKanban } = useProject();
	let cancelTokenSource: CancelTokenSource | null = null;

	useEffect(() => {
		selectProjectKanban();
	}, []);

	const handleGetInitialValues = async () => {
		setLoadingFilters(true);

		if (userDetails) {
			try {
				if(columnsManager === initialValues.columnsManager) {
					setLoadingKanban(true);
				}
				if (cancelTokenSource) {
					cancelTokenSource.cancel('Nova requisição feita');
				}
				cancelTokenSource = axios.CancelToken.source();
				const config: AxiosRequestConfig = {cancelToken: cancelTokenSource.token}
				const [
					companyColumnsResponse,
					todoProjectsResponse,
					doneProjectsResponse,
					companyResponse,
				] = await Promise.all([
					kanbanService.getColumnsByCompany({
						searchProject, myProjects, clienteValorSelecionado, userId: userDetails.id_pessoa, 
					}, config),
					kanbanService.getProjectsWithNoColumn({
						searchProject, myProjects, clienteValorSelecionado, userId: userDetails.id_pessoa
					}, 1, '', config),
					kanbanService.getProjectsWithNoColumn({ searchProject, myProjects, clienteValorSelecionado, userId: userDetails.id_pessoa }, 1, 'closed',config),
					kanbanService.getCompanyKanban(config),
				]);

				if (companyResponse) {
					const initialColumnId = uuid();
					let tempColumns = {
						[initialColumnId]: {
							nome: t('Novo'),
					status: status[0],
					totalPages: 1,
					order: 0,
					addedItemsIds: [],
					doneTotalPages: 0,
					id: '',
					items: [],
					ordenacao: 0,
					page: 0,
					removedQnt: 0,
						} 
					} as ColumnsForManager<ProjectPage>


					
					tempColumns[initialColumnId] = {
						...tempColumns[initialColumnId],
						id: initialColumnId,
						nome: companyResponse.primeira_coluna || tempColumns[initialColumnId].nome,
						totalPages: todoProjectsResponse.total_pages,
						page: 1,
						addedItemsIds: [],
						removedQnt: 0,
						items: todoProjectsResponse.results,
					};
					companyColumnsResponse.results.forEach(item => {
						tempColumns = {
							...tempColumns,
							[item.id]: {
								id: item.id,
								nome: item.nome,
								status: status[0],
								order: item.ordenacao,
								totalPages: item.total_pages,
								page: 1,
								removedQnt: 0,
								addedItemsIds: [],
								items: item.tarefas,
							},
						};
					});

					const newColumnOrder = handleInitialOrderColumns(tempColumns);
					const items = tempColumns[newColumnOrder[newColumnOrder.length - 1]]?.items
					tempColumns = updateColumnsValues(tempColumns, newColumnOrder);

					tempColumns[newColumnOrder[newColumnOrder.length - 1]] = {
						...tempColumns[newColumnOrder[newColumnOrder.length - 1]],
						doneTotalPages: doneProjectsResponse.total_pages,
						items: [
							...items,
							...doneProjectsResponse.results,
						],
					};

					setColumnsManager({
						columns: tempColumns,
						columnsOrder: newColumnOrder,
						empresa: companyResponse
					});

				}

				setLoadingKanban(false);
				setLoadingFilters(false);
			} catch (err: any) {			
					console.log(err);				
				if(err.message !== "Cancelled") {
					setLoadingKanban(false);
					setLoadingFilters(false);
					toast.error(t('Erro ao carregar o Kanban!'));				
				}
				
			}
		}
	};

	const handleAddColumn = (title: string) => {
		if (title.trim() !== '') {
			const newColumnId = uuid();
			const column: ColumnManaged<any> = {
				id: newColumnId,
				nome: title,
				order:
					columnsManager.columns[
						columnsManager.columnsOrder[columnsManager.columnsOrder.length - 1]
					].order + 1,
				status: status[2],
				totalPages: 1,
				page: 1,
				removedQnt: 0,
				addedItemsIds: [],
				items: [],
				doneTotalPages: 0,
				ordenacao:
					columnsManager.columns[
						columnsManager.columnsOrder[columnsManager.columnsOrder.length - 1]
					].order + 1,
			};

			handlePostAddColumn(column);
			setRequestingAddColumn({
				componentId: newColumnId,
			});
			setColumnsManager(prev => {
				const newColumnOrder = [...prev.columnsOrder, newColumnId];
				const tempColumns = { ...prev.columns, [newColumnId]: column };

				return {
				...prev,
				columns: updateColumnsValues(tempColumns, newColumnOrder),
				columnsOrder: newColumnOrder,
			}});
		}

		setIsAddingColumn(false);
	};


	function handlePostAddColumn(column: ColumnManaged<any>) {
		if(columnsManager?.empresa){
			kanbanService
			.postColumn({
				nome: column.nome,
				empresa: columnsManager.empresa.id,
				ordenacao: column.ordenacao
			})
			.then(response => {
				if (response) {
					const newColumnId = response.id;

					setColumnsManager(prev => {
						const newColumnOrder = [...prev.columnsOrder, newColumnId];
						const tempColumns = {
							...prev.columns,
							[newColumnId]: { ...column, id: newColumnId },
						};

						return {
						...prev,
						columns: updateColumnsValues(tempColumns, newColumnOrder),
						columnsOrder: newColumnOrder,
					}});
				}
			})
			.catch(() => {
				setColumnsManager({ ...columnsManager });

				toast.error(t('Erro ao adicionar coluna!'));
			});
		}		
	}

	const handlePatchDragColumn = (result: DropResult) => {
		if (!result.destination && !columnsManager.project) {
			return;
		}

		const columnData = {
			id: result.draggableId,
			ordenacao: result.destination!.index,
		};

		kanbanService.patchColumn(columnData).catch(() => {
			setColumnsManager({ ...columnsManager });

			toast.error(t('Erro ao reposicionar coluna!'));
		});
	};

	const handleOnDragEnd = useCallback(
		(result: DropResult) => {
			if (
				!result.destination ||
				requestingAddColumn.componentId === result.destination.droppableId
			)
				return;

			const { source, destination, type } = result;

			if (type === 'column' && !!result.draggableId) {
				if (
					source.index === 0 ||
					destination.index === 0 ||
					source.index === destination.index
				)
					return;
				handlePatchDragColumn(result);

				setColumnsManager(prev => {
					const tempColumnsManager = dragColumn(prev, result);
					return tempColumnsManager
				});
				return;
			}

			if (source.droppableId !== destination.droppableId) {
				setColumnsManager(prev => {
					const tempColumnsManager = dragTaskDifferentColumns(
						prev,
						result,
					);

					return tempColumnsManager
				});
			} else {
				setColumnsManager(prev => {
					const tempColumnsManager = dragTaskSameColumn(prev, result);

					return tempColumnsManager
				});
			}

			handlePatchDragTask(
				result.draggableId,
				destination.index,
				destination.droppableId === columnsManager.columnsOrder[0]
					? null
					: destination.droppableId,
			);
		},
		[
			requestingAddColumn,
			handlePatchDragColumn,
			dragTaskDifferentColumns,
			dragColumn,
			columnsManager,
		],
	);

	useEffect(() => {
		handleGetInitialValues();

		return () => {
			if(cancelTokenSource) {
				cancelTokenSource.cancel("Requisição cancelada")
			}
		}
	}, [myProjects, clienteValorSelecionado])

	const handlePatchDragTask = async (
		id: string,
		ordenacao: number,
		coluna_kanban: string | null,
	) => {
		const projectData = {
			id,
			ordenacao,
			coluna_kanban,
			status: coluna_kanban
				? columnsManager.columns[coluna_kanban].status.title
				: 'started',
		};
		await kanbanService.patchProjectInColumn(projectData).catch(() => {
			setColumnsManager({ ...columnsManager });

			toast.error(t('Erro ao reposicionar tarefa!'));
		});
	};


	return <KanbanContainer
		loadingFilters={loadingFilters}
		loadingKanban={loadingKanban}
		canEdit={userDetails?.acesso_cod === 'a'}
		handleAddColumn={handleAddColumn}
		isAddingColumn={isAddingColumn}
		setIsAddingColumn={setIsAddingColumn}
		onDragEnd={handleOnDragEnd}
		react_tour_id='' >
		{columnsManager.columnsOrder.map((id, index) => {
				return (
					<KanbanBody {...props} id={id} index={index} key={id} />
				);
		})}
	</KanbanContainer>

}