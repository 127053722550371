import React, { HTMLAttributes } from "react";
interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function ProjectsIcon(props: IconProps) {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path d="M1.81818 17.636C1.31818 17.636 0.890303 17.4581 0.534546 17.1024C0.178182 16.746 0 16.3178 0 15.8178V4.90874C0 4.65116 0.0872727 4.4351 0.261818 4.26055C0.435758 4.08661 0.651515 3.99964 0.909091 3.99964C1.16667 3.99964 1.38273 4.08661 1.55727 4.26055C1.73121 4.4351 1.81818 4.65116 1.81818 4.90874V15.8178H16.3636C16.6212 15.8178 16.837 15.9051 17.0109 16.0796C17.1855 16.2536 17.2727 16.4693 17.2727 16.7269C17.2727 16.9845 17.1855 17.2003 17.0109 17.3742C16.837 17.5487 16.6212 17.636 16.3636 17.636H1.81818ZM5.45455 13.9996C4.95455 13.9996 4.52667 13.8218 4.17091 13.466C3.81455 13.1096 3.63636 12.6815 3.63636 12.1815V2.18146C3.63636 1.68146 3.81455 1.25328 4.17091 0.896918C4.52667 0.54116 4.95455 0.363281 5.45455 0.363281H9.25C9.49242 0.363281 9.72364 0.408736 9.94364 0.499645C10.163 0.590554 10.3561 0.719342 10.5227 0.886009L11.8182 2.18146H18.1818C18.6818 2.18146 19.11 2.35964 19.4664 2.71601C19.8221 3.07177 20 3.49964 20 3.99964V12.1815C20 12.6815 19.8221 13.1096 19.4664 13.466C19.11 13.8218 18.6818 13.9996 18.1818 13.9996H5.45455ZM5.45455 12.1815H18.1818V3.99964H11.0682L9.25 2.18146H5.45455V12.1815ZM5.45455 12.1815V2.18146V12.1815Z" fill="white"/>
</svg>

  )
}