import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import SeletorTime from '../../../../components/common/SeletorTime';
import {
	FormSideGenerico,
	ModalConteudoGenerico,
	ModalPrincipalGenerico
} from '../../../../components/containers/Modais/ModalComSideGenerico';
import { ProjetoForm } from './form';
import { ClienteForm } from '../../RegistroNavBar/Clientes/form';

import { verifyCanEditProject } from '../../../../utils/funcoes/validarPerfil';
import { useProject } from '../../../../contexts/Project';
import { useTranslation } from 'react-i18next';

import ClienteService from '../../../../services/providers/client';

import '../../../../components/containers/Modais/modalGeral.css';
import './style.css';
import {  ProjectBasic } from '../../../../models/Project';
import { useUser } from '../../../../contexts/User';
import { SetStateAction } from 'jotai';
import { Responsible } from '../../../../models/Responsible';
import { SelectOptions } from '../../../../models/Select';
import { ModalContainer, ModalContent, ModalProjetoContainer } from './styled';
import ProjetoService from '../../../../services/providers/project';
export interface ModalProjetoProps {
	itemSelecionado?: ProjectBasic;
	editingProjeto: boolean;
	setModalShow: Dispatch<SetStateAction<boolean>>;
	needToLoadDetails: boolean;
}

export default function ModalProjeto({editingProjeto, itemSelecionado, setModalShow, needToLoadDetails}: ModalProjetoProps) {
	const projectService = new ProjetoService()
	const {t} = useTranslation();
	const [timeSelecionado, setTimeSelecionado] = useState<number[]>([]);
	const [saveAndContinue, setSaveAndContinue] = useState(false);
	const [admins, setAdmins] = useState<number[]>([]);
	const [perfilValido, setPerfilValido] = useState(false);
	const [clienteValorSelecionado, setClienteValorSelecionado] = useState<SelectOptions>();
	const [loading, setLoading] = useState(false);
	const [loadingDetails, setLoadingDetails] = useState(needToLoadDetails && itemSelecionado);
	const { userDetails} = useUser();

	const [tempItemSelecionado, setTempItemSelecionado] = useState<ProjectBasic | undefined>(itemSelecionado);

	const {
		closeProject,
		deleteProject,
		loadingPostPatch,
		modalProjectVisible,
		setModalProjectVisible,
		reactivateProject
	} = useProject();

	useEffect(() => {
		if(needToLoadDetails && itemSelecionado) {
			projectService.getProjectDetail({id: itemSelecionado.id, serializer: "kanban"}).then(result => {
				setTempItemSelecionado({
					...itemSelecionado,
					...result
				})

				setLoadingDetails(false)
			})
		}
	}, [itemSelecionado, needToLoadDetails])
	
	useEffect(() => {
		if(tempItemSelecionado) {
			setPerfilValido(
				verifyCanEditProject(userDetails, tempItemSelecionado.is_member, tempItemSelecionado.admins)
			);
		} else {
			setPerfilValido(
				verifyCanEditProject(userDetails)
			);
		}
	}, [userDetails, editingProjeto, tempItemSelecionado]);
	
	const handleClickSave = useCallback(() => {
		document.getElementById('input-submit-projeto')?.click();
	}, []);

	useEffect(() => {
		if (tempItemSelecionado?.time &&
			editingProjeto &&
			tempItemSelecionado.time.length > 0
		) {
			adicionaPessoaTime(tempItemSelecionado.time);
		}
	}, [tempItemSelecionado]);

	const handleClickSaveAndContinue = useCallback(() => {
		setSaveAndContinue(true);
		document.getElementById('input-submit-projeto')?.click();
	}, []);

	const adicionaPessoaTime = useCallback(
		(pessoaRecebida: Responsible |Responsible[]) => {
			if (Array.isArray(pessoaRecebida)) {
				const pessoas = pessoaRecebida.map(item => item.id);
				setTimeSelecionado(pessoas);
			} else {
				setTimeSelecionado([...timeSelecionado, pessoaRecebida.id]);
			}
		},
		[timeSelecionado],
	);

	const handleAdicionar = (isSideOpen = true) => {
		const formSide = document.getElementById('side-content-generico');

		if (!isSideOpen && formSide) {
			formSide.className = 'form-content-generico-side';
		} else if (formSide){
			formSide.className = 'form-content-generico-side--ACTIVED';
		}
	};

	const _postCliente = (params: any) => {
		setLoading(true);
		const clienteService = new ClienteService();
		clienteService.postCliente(
			params,
			response => {
				if (response) {
					toast.success(t('Cliente salvo com sucesso!'));
					setClienteValorSelecionado({
						value: response.data.id, 
						label: response.data.nome
					});
					handleAdicionar(false);
				}
				setLoading(false);
			},
			error => {
				toast.error(error);
				setLoading(false);
			},
		);
	};


	const handleClick = () => {
		document.getElementById('formSideModal-cliente')?.click();
	};

	const handleRemovePessoaTime = useCallback(
		(pessoaRecebida: Responsible) => {
			const index = timeSelecionado.findIndex(id => id === pessoaRecebida.id);
			timeSelecionado.splice(index, 1);
		},
		[timeSelecionado],
	);

	return (
		<ModalPrincipalGenerico modalGenericoShow={setModalShow}>
			<FormSideGenerico
				title={t('Adicionar Cliente')}
				handleAdicionar={handleAdicionar}
				btnSave={true}
				onClickSave={handleClick}
				loadingBtn={loading}
			>
				<ClienteForm handleSubmit={_postCliente} editing={false} />
			</FormSideGenerico>
			<ModalConteudoGenerico
				copyText=''
				title={
					editingProjeto && tempItemSelecionado ? tempItemSelecionado?.nome : t('Criar Projeto')
				}
				modalGenericoShow={setModalProjectVisible}
				saveAndContinue={!editingProjeto}
				modalIsShowing={modalProjectVisible}
				btnSave={perfilValido}
				showEllipsis={perfilValido === true && editingProjeto}
				handleEncerrar={tempItemSelecionado?.item_ativo ? closeProject : undefined}
				handleDeletar={deleteProject}
				itemId={tempItemSelecionado?.id}
				loadingBtn={loadingPostPatch || (tempItemSelecionado?.time === undefined && editingProjeto) || !!loadingDetails}
				onClickSaveAndContinue={handleClickSaveAndContinue}
				onClickSave={handleClickSave}
				isFinished={!tempItemSelecionado?.item_ativo}
				handleReactivate={reactivateProject}
			>
				<ModalContent>
					{editingProjeto ? (
						<ProjetoForm
							editing={true}
							item_edit={tempItemSelecionado}
							timeSelecionado={timeSelecionado}
							admins={admins}
							perfilValido={perfilValido}
							handleAdicionar={handleAdicionar}
							selectedNewClient={clienteValorSelecionado}
							saveAndContinue={saveAndContinue}

						/>
					) : (
						<ProjetoForm
							editing={false}
							saveAndContinue={saveAndContinue}
							timeSelecionado={timeSelecionado}
							admins={admins}
							handleAdicionar={handleAdicionar}
							perfilValido={perfilValido}
							selectedNewClient={clienteValorSelecionado}
						/>
					)}

					<ModalContainer className='container'>
						<div>
							<ModalProjetoContainer validProfile={perfilValido && !loadingDetails}>
								{editingProjeto ? (
										<SeletorTime
											projetoTime={tempItemSelecionado?.time}
											editandoTime={tempItemSelecionado}
											setAdmins={setAdmins}
											adicionaPessoaTime={adicionaPessoaTime}
											removePessoaTime={handleRemovePessoaTime}
											isEditing={editingProjeto}

										/>
								) : (
									<SeletorTime
										setAdmins={setAdmins}
										adicionaPessoaTime={adicionaPessoaTime}
										removePessoaTime={handleRemovePessoaTime}
										isEditing={editingProjeto}
									/>
								)}
							</ModalProjetoContainer>
						</div>
					</ModalContainer>
				</ModalContent>
			</ModalConteudoGenerico>			
		</ModalPrincipalGenerico>
	);
}
