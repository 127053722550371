export const theme = {
	colors: {
		background: {
			mainBg: '#426172',
			modalBg: '#f7f6f5',
		},
		text: {
			sidebarText: '#c0cfd8',
			titleText: '#426172',
			subtitleText: '#99adb9',
			inputText: '#426172',
		},
		input: {
			borderBackgroundInput: '#c1d0d9',
		},
		button: {
			degradeLBtn: '#23d9b7',
			degradeRBtn: '#d7f205',
			acessBtn: '#99adb9',
			saveBtn: '#22e0f0',
			darkBtn: '#426172',
		},
		table: {
			header: '#EBEAE9',
			background: '#F5F5F5',
			total: '#404040'
		}
	},
	font: {
		family: {},
		sizes: {
			text: {
				small: '12px',
				medium: '14px',
				normal: '16px',
				large: '20px',
				Xlarge: '22px',
			},
		},
		fontWeight: {},
	},
};

export const SCALEMULT = 0.8;
export const PAGETOPMARGEM = 6.5 * SCALEMULT;