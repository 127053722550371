import React, { useState } from 'react';

import { HorasForm } from './form';

import '../../../../components/containers/Modais/modalGeral.css';
import './style.css';
import {
	FormSideGenerico,
	ModalConteudoGenerico,
	ModalPrincipalGenerico,
} from '../../../../components/containers/Modais/ModalComSideGenerico';
import TarefaFormContainer from './Tarefa/form';
import SeletorTimeTarefa from '../../../../components/common/SeletorTimeTarefa';
import ProjetoService from '../../../../services/providers/project';
import { useTranslation } from 'react-i18next';

export default function ModalHoras(props) {
	const {t} = useTranslation();
	const [loadingTask, setLoadingTask] = useState(false);
	const [loadingHour, setLoadingHour] = useState(false);
	const [saveAndContinue, setSaveAndContinue] = useState(false);
	const [selectedProjectHourModal, setSelectedProjectHourModal] = useState({});
	const [selectedTaskHourModal, setSelectedTaskHourModal] = useState({});
	const [selectedProjectTask, setSelectedProjectTask] = useState({});
	const [projectTeam, setProjectTeam] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState([]);
	// const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

	const [projectAux, setProjectAux] = useState();

	const fillHourModal = (project, task) => {
		setSelectedProjectHourModal(project);
		setSelectedTaskHourModal(task);
	};

	const handleClickSaveAndContinue = () => {
		setSaveAndContinue(true);
		document.getElementById('input-submit-horas').click();
	};

	const addPersonInTeam = person => {
		setSelectedTeam(prevTeam => [...prevTeam, person.id]);
	};

	const removePersonFromTeam = person => {
		setSelectedTeam(prevTeam => prevTeam.filter(id => id !== person.id));
	};

	const handleAdicionar = (isSideOpen = true) => {
		const formSide = document.getElementById('side-content-generico');

		if (!isSideOpen) {
			formSide.className = 'form-content-generico-side';
			// setIsTaskModalOpen(false);
		} else {
			formSide.className = 'form-content-generico-side--ACTIVED';
			// setIsTaskModalOpen(true);
			setSelectedProjectTask(projectAux);
		}
	};

	const _getProjetoById = id => {
		setProjectTeam([]);
		const projectService = new ProjetoService();
		projectService.getDetalheProjeto(
			id,
			response => {
				if (response.time) {
					setProjectTeam(response.time);
				}
			},
			() => {
				setProjectTeam([]);
			},
		);
	};

	const handleClickSaveSide = () => {
		document.getElementById('input-submit-tarefa-side').click();
		setSaveAndContinue(false);
	};

	const handleClickSave = () => {
		document.getElementById('input-submit-horas').click();
		setSaveAndContinue(false);
	};

	const fillProject = project => setProjectAux(project);

	return (
		<>
			<ModalPrincipalGenerico modalGenericoShow={props.modalHorasShow}>
				<FormSideGenerico
					title={t("Adicionar Tarefa")}
					handleAdicionar={handleAdicionar}
					btnSave={true}
					onClickSave={handleClickSaveSide}
					loadingBtn={loadingTask}
					hour={true}
				>
					<>
						<TarefaFormContainer
							hourSelectedProject={selectedProjectTask}
							getTimeSelecionado={selectedTeam}
							fillHourModal={fillHourModal}
							handleLoading={setLoadingTask}
							getProjetoID={_getProjetoById}
							toogleSideModal={handleAdicionar}
						/>
						<div className="container">
							<SeletorTimeTarefa
								isFormSide={true}
								ResponsaveisDaTarefaData={[]}
								pessoasDoProjetoData={projectTeam}
								isEditing={false}
								adicionaPessoaResponsavel={addPersonInTeam}
								removePessoaResponsavel={removePersonFromTeam}
							/>
						</div>
					</>
				</FormSideGenerico>
				<ModalConteudoGenerico
					// header
					title={
						props.editingHoras && !props.isFromTarefa
							? t('Editar Registro de Horas')
							: t('Novo Registro de Horas')
					}
					showEllipsis={
						props.editingHoras && !props.isFromTarefa ? true : false
					}
					modalGenericoShow={props.modalHorasShow}
					// panel option
					itemId={props.itemSelecionado ? props.itemSelecionado.id : null}
					handleDeletar={props.handleDeletar}
					btnSave={true}
					modalIsShowing={props.modalIsShowing}
					// footer buttons
					saveAndContinue={
						props.editingHoras && !props.isFromTarefa ? false : true
					}
					onClickSave={handleClickSave}
					onClickSaveAndContinue={handleClickSaveAndContinue}
					loadingBtn={loadingHour}
					hour={true}
				>
					<div className="modal-width-horas">
						{props.editingHoras ? (
							<HorasForm
								modalGenericoShow={props.modalHorasShow}
								handleLoading={setLoadingHour}
								saveAndContinue={saveAndContinue}
								editing={true}
								item_edit={props.itemSelecionado}
								handlePostPatchHora={props.handlePostPatchHora}
								isFromTarefa={props.isFromTarefa}
								fillProject={fillProject}
								selectedProject={selectedProjectHourModal}
								selectedTask={selectedTaskHourModal}
								addTask={handleAdicionar}
								filter={props.filter}
							/>
						) : (
							<HorasForm
								modalGenericoShow={props.modalHorasShow}
								handleLoading={setLoadingHour}
								saveAndContinue={saveAndContinue}
								editing={false}
								handlePostPatchHora={props.handlePostPatchHora}
								isFromTarefa={props.isFromTarefa}
								fillProject={fillProject}
								selectedProject={selectedProjectHourModal}
								selectedTask={selectedTaskHourModal}
								addTask={handleAdicionar}
								filter={props.filter}
							/>
						)}
					</div>
				</ModalConteudoGenerico>
			</ModalPrincipalGenerico>
		</>
	);
}
