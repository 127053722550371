import React, { useCallback, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../models/Select';
import { Input, Td, TextInfo } from './styled';

interface TableCellTextareaProps {
	name: string;
	value: string | number;
	width?: string;
	options?: SelectOptions[];
	id?: number | string;
	disabled?: boolean;
	fontSize?: string;
	// handleChange: ((value: string | number) => void);
	onBlur: (value: string | number, name: string, id?: number | string) => void;
	color?: string;
	customStyle?: string;
}

export default function TableCellTextarea({
	name,
	value,
	width,
	onBlur,
	id,
	disabled,
	fontSize,
	color,
	customStyle
}: TableCellTextareaProps) {
	const [defaultValue, setDefaultValue] = useState(value);
	const [editVisible, setEditVisible] = useState(false);
	useEffect(() => {
		setDefaultValue(value);
	}, [value]);

	const handleBlur = useCallback(
		(e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
			if (e.target.value !== value?.toString()) {
				onBlur(e.target.value, name, id);
			}
			setEditVisible(false);
		},
		[onBlur, name, id, value],
	);

	const handleKeyUp = useCallback(
		(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if(e.key === 'Enter') {
			e.currentTarget.blur();
			setEditVisible(false);
		}
	}, [onBlur, name, id, value])

	const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setDefaultValue(e.target.value);
	}, []);


	return (
		<Td>
			{!editVisible ? <TextInfo
				customStyle={customStyle}
				color={color}
				fontSize={fontSize}
				onClick={() => setEditVisible(true)}
				inputWidth={width}
				title={defaultValue?.toString()}
			>{defaultValue}</TextInfo> : 
			<Input
				customStyle={customStyle}
				color={color}
				fontSize={fontSize}
				disabled={disabled}
				onBlur={handleBlur}
				onChange={handleChange}
				inputWidth={width}
				name={name}
				value={defaultValue}
				onKeyUp={handleKeyUp}
				title={defaultValue?.toString()}

			/>}
		</Td>
	);
}
