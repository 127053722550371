import { DropResult } from 'react-beautiful-dnd';
import { TaskManaged } from '../../../../contexts/Kanban/types';
import { status } from './Kanban';
import { ColumnManaged, ColumnsForManager, ColumnsManager } from '../../../../atoms/Kanban/types';
import { MainProject } from '../../../../models/Project';

export function dragColumn(columnsManager: ColumnsManager<any>, result: DropResult) {
  const { source, destination } = result;

  if(destination) {
    const newColumnOrder = Array.from(columnsManager.columnsOrder);
    newColumnOrder.splice(source.index, 1);
    newColumnOrder.splice(destination.index, 0, result.draggableId);

    const tempColumns = updateColumnsValues(columnsManager.columns, newColumnOrder);

    return ({
      ...columnsManager,
      columns: tempColumns,
      columnsOrder: newColumnOrder
    })
  }

  return columnsManager;
}

export function updateColumnsValues(columns: ColumnsForManager<any>, newColumnOrder: string[]) {
  const tempColumns = { ...columns };

  newColumnOrder.forEach((id, index) => {
    const selectStatus = index === newColumnOrder.length - 1 ? status[2] : status[1];
    const selectStatusTitle = index === newColumnOrder.length - 1 ? status[2].title : status[1].title;
    tempColumns[id] = {
      ...columns[id],
      ordenacao: index,
      status: index === 0 ? status[0] : selectStatus,
      items: columns[id].items.map(item => ({
        ...item,
        status: index === 0 ? status[0].title : selectStatusTitle
      }))
    }
  })

  return tempColumns;
}

export function dragTaskSameColumn(columnsManager: ColumnsManager<any>, result: DropResult) {
  const { source, destination } = result;

  if(destination) {
    const column = columnsManager.columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    const tempDestItems = copiedItems.map((item, index) => {
      return {
        ...item,
        order: index
      }
    })

    return ({
      ...columnsManager,
      columns: {
        ...columnsManager.columns,
        [source.droppableId]: {
          ...column,
          items: tempDestItems
        }
      },
      columnsOrder: columnsManager.columnsOrder
    })
  }

  return columnsManager;
}

export function dragTaskDifferentColumns(columnsManager: ColumnsManager<any>, result: DropResult) {
  const { source, destination } = result;
  if(destination) {
    const sourceColumn = columnsManager.columns[source.droppableId];
    const destColumn = columnsManager.columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, { ...removed, status: destColumn.status.title, coluna_kanban: destination.droppableId });

    const tempDestItems = destItems.map((item, index) => {
      return {
        ...item,
        order: index
      }
    })

    return ({
      ...columnsManager,
      columns: {
        ...columnsManager.columns,
        [destination.droppableId]: {
          ...destColumn,
          addedItemsIds: setAddedItems(destColumn, tempDestItems),
          items: tempDestItems
        },
        [source.droppableId]: {
          ...sourceColumn,
          removedQnt: sourceColumn.removedQnt + 1,
          items: sourceItems
        }
      },
      columnsOrder: columnsManager.columnsOrder

    })
  }

  return columnsManager;
}

export function setAddedItems(column: ColumnManaged<TaskManaged | MainProject>, tempItems: TaskManaged[] | MainProject[]) {
  return [...column.addedItemsIds, tempItems[tempItems.length - 1].id];
}

export function newItemsByColumn(columnId: string, prevColumns: ColumnsForManager<any>, tempItems: any[], deleted = false) {
  return {
    ...prevColumns,
    [columnId]: {
      ...prevColumns[columnId],
      removedQnt: deleted ? prevColumns[columnId].removedQnt + 1 : prevColumns[columnId].removedQnt,
      items: tempItems
    }
  }
}