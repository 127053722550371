import React, { Dispatch, FormEventHandler, useCallback, useEffect, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import { t } from 'i18next';
import { SelectOptions } from '../../../../models/Select';
import { TaskOptions } from '../../../../pages/Registros/Horas/ModalHoras/form/types';
import ProjectService from '../../../../services/providers/project';
import HourService from '../../../../services/providers/hour';
import TaskService from '../../../../services/providers/task';
import AsyncSelector from '../../AsyncSelector';
import { HorasSchema, StopwatchSchema, OcioSchema} from './validation';
import { CheckboxContainer, FieldContainer, FormContainer, Label, LabelCheckbox, SelectDateContainer, SelectTaskContainer, Textarea } from './styled';
import SearchOptionLabel from '../../AsyncSelector/SearchOptionLabel';
import { FormValueProps } from './types';
import DropdownIndicator from '../../SelectDropdowmIndicator';
import { emptyLoadOptions } from '../../../../utils/funcoes/reactSelect';
import { AddTaskButton, StartButton } from '../../../containers/Modais/StopwatchModal/styled';
import { IconType } from 'react-icons/lib';
import { AiOutlinePlus } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { LoaderCircle } from '../../loaders/LoaderCircle';
import { ProjectSelectOption, ProjectSingleValue } from './ProjectSelect';
import { timerSelectErrorStyle, timerSelectStyle } from '../../../../utils/extenal-libs';
import FieldFormik from '../../Fields/FieldFormik';
import { verificaData } from '../../../../utils/funcoes/manipulateTime';
import {  HoursRecordSimpleSelected } from '../../../../models/HoursRecord';
import { useRecordHistory } from '../../../../contexts/RecordHistoryContext';
import { useStopwatch } from '../../../../contexts/Stopwatch';
import { useDataTask } from '../../../../contexts/InfoTarefa/DataTask';
import { SetStateAction } from 'jotai';
import { useKanban } from '../../../../contexts/Kanban/Kanban';
import { useUser } from '../../../../contexts/User';
import BasicTimePicker from '../../BasicTimePicker';
import dayjs, { Dayjs } from 'dayjs';

export interface StopwatchFormProps {
    StartIcon?: IconType;
    isTimer: boolean;
    preSelectedRecord: HoursRecordSimpleSelected | null;
    setOpenSide: Dispatch<SetStateAction<boolean>>;
    isOpenSide: boolean;
    formRef: React.RefObject<FormikProps<FormValueProps>>
}

export function StopwatchForm({StartIcon, formRef, isTimer, preSelectedRecord}: StopwatchFormProps) {
	const projectService = new ProjectService();
	const taskService = new TaskService();
	const { handleAddTaskModal } = useKanban();

    const {currentStopwatch, handleStopwatch, handleToggleModal, loadingStopwatch, requestingStopwatch, selectedMenuIndex, handleAfterPostPatch } = useStopwatch();
	const {getTasksPagination, totalPages} = useDataTask();
    const hasStopwatch =  currentStopwatch !== "";
	const hourService = new HourService();
    const { userDetails } = useUser();

    const initialOptionProject: SelectOptions = {
		value: "",
		label: t("Selecione um projeto")
	}

    const [taskTitle, setTaskTitle] = useState("");
    const [loading, setLoading] = useState(false);

    const initialOptionTask: SelectOptions = {
		value: "",
		label: t("Selecione uma tarefa")
	}

    const {handleAddRecordToHistory, handleUpdateRecordToHistory} = useRecordHistory();
    
	const [duration, setDuration] = React.useState<Dayjs | null>(null);
    const formatedTime = dayjs(duration).format("HH:mm:ss")

	const [date, setDate] = useState(verificaData());
	const [selectedProject, setSelectedProject] = useState<SelectOptions>(initialOptionProject);
	const [selectedTask, setSelectedTask] = useState<TaskOptions>(initialOptionTask);
    const [hourType, setHourType] = useState<'ocio'|'trab'>('trab');
    const [description, setDescription] = useState('');
    const [isTaskSearchable, setIsTaskSearchable] = useState(
		!selectedProject || selectedProject?.value?.toString()?.length <= 0,
	);

	let initialValues: FormValueProps;

    useEffect(() => {
        if(hasStopwatch && isTimer) {
            getTask();
        }else if(preSelectedRecord){
                handlePreSelectedProject();
        }else {
            setDescription('');
        }
    }, [hasStopwatch, isTimer, preSelectedRecord, selectedMenuIndex]);
    
    const getTask = () => {
        if(currentStopwatch !== "") {
            setSelectedProject({label: currentStopwatch.projeto_nome, value: currentStopwatch.projeto});
            setSelectedTask({label: currentStopwatch.tarefa_nome, value: currentStopwatch.tarefa});
            setDescription(currentStopwatch.descricao || '')
        }
    }

    if (isTimer && preSelectedRecord?.tipo !== 'ocio') {
        initialValues = {
            projeto: selectedProject?.value,
            tarefa: selectedTask?.value,
            data: "",
            duracao: "",
            descricao: currentStopwatch !== "" ? currentStopwatch.descricao || '' : '',
            tipo: 'trab',
        };
	} else if(isTimer){
        initialValues = {
            projeto: '',
            tarefa: '',
            data: '',
            duracao: '',
            descricao: '',
            tipo: 'trab',
        };
    }else {
        initialValues = {
            projeto: selectedProject?.value,
            tarefa: selectedTask?.value,
            data: date,
            duracao: duration && formatedTime !== "Invalid Date" 
                ? formatedTime
                : '',
            descricao: description,
            tipo: hourType,
        };
	}

    const loadOptionsTask = (
        search: string,
        loadedOptions: any[],
        {
            page = 1,
            placeholder = '',
            cliente = '',
            projeto = '',
            pessoa = '',
            isProjectSelected = false,
        },
    ) => {
        const tempLoadedOptions = loadedOptions || [];

        return isTaskSearchable && search.length < 3
            ? emptyLoadOptions(search, tempLoadedOptions, {
                    page,
                })
            : taskService.loadTarefasSelect(
                    search,
                    tempLoadedOptions,
                    {
                        page,
                        placeholder,
                        cliente,
                        projeto,
                        pessoa,
                        isProjectSelected,
                    },
                );
    };

    const handleInputChange = (title: string) => {
        if(title !== "") {
            setTaskTitle(title);
        }
    }

    const handleOnBlur = () => {
        setTimeout(() => {
            setTaskTitle("");
        }, 1000);
    }

    const handleAddTask = (title: string) => {
        if(title.trim() !== "") {
            setLoading(true);
            const params = {
                nome: title,
                projeto: selectedProject.value
            }
            taskService.postTarefa( 
                params,
                response => {
                    if (response) {
                        const task = { 
                            value: response.id, 
                            label: response.nome 
                        };
                        
                        setSelectedTask(task);
                        handleAddTaskModal(response);
                        toast.success('Tarefa salva com sucesso!');
                    }
                    setLoading(false);
                },
                error => {
                    toast.error(error);
                    setLoading(false);

                },
            );
        }
    };

    const _postHoras = (params: any) => {
        setLoading(true);
		hourService.postHoras(
			params,
			(response) => {
				if (response) {
					toast.success(t('Registro de hora salvo com sucesso!'));
					handleAddRecordToHistory(response.data);
				}
                setLoading(false);
			},
			(error: any) => {
				toast.error(error);
                setLoading(false);
			},
		);
	};

    const handlePostPatchHora = useCallback(() => {
		if(totalPages) {
            getTasksPagination();
        }
        handleAfterPostPatch();

	}, [getTasksPagination]);

    useEffect(() => {
		setIsTaskSearchable(
			!selectedProject || selectedProject?.value?.toString()?.length <= 0,
		);
	}, [selectedProject]);

    const handlePreSelectedProject = () => {
        if(preSelectedRecord !== null) {
            setSelectedProject({
                value: preSelectedRecord.projeto,
                label: preSelectedRecord.projeto_nome
            });
            setSelectedTask({
                value: preSelectedRecord.tarefa,
                label: preSelectedRecord.tarefa_nome
            });
            setHourType(preSelectedRecord.tipo ?? 'trab');
            if(preSelectedRecord.hourId && preSelectedRecord.data && preSelectedRecord.duracao && preSelectedRecord.tipo) {
                setDate(preSelectedRecord.data);
                const today = new Date();
                const splitDuration = preSelectedRecord.duracao.split(':');
                const hour = parseInt(splitDuration[0]);
                const minute = parseInt(splitDuration[1]);
                const second = parseInt(splitDuration[2]);
                today.setHours(hour, minute, second)
                setDuration(dayjs(today));
                setHourType(preSelectedRecord.tipo);
                setDescription(!isTimer ? (preSelectedRecord.descricao || '') : '');
            }
        }
    }


    const isFieldDisabled = (isTimer && (loadingStopwatch || hasStopwatch)) || loading;


    const isTimerText = t(`${hasStopwatch ? "Parar" : "Iniciar"} cronômetro`);

    const _patchHoras = (params: any, id: number) => {
		const horaService = new HourService();
        setLoading(true);

		horaService.patchHoras(
			params,
			id,
			(response: any) => {
				if (response) {
					toast.success(t('Registro de hora atualizado com sucesso!'));
                    handleUpdateRecordToHistory(response.data);
					handleToggleModal(false)
				}
                setLoading(false);

			},
			(error: any) => {
				toast.error(error);
                setLoading(false);

			},
		);
	};
    const HourTypeSchema = hourType === 'trab' ? HorasSchema : OcioSchema;
    return (
    <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={isTimer ? StopwatchSchema : HourTypeSchema}
        enableReinitialize={true}
        onSubmit={values => {
            const form_data = new FormData();
            for (const key in values) {
                const tempKey = key as keyof FormValueProps;
                const value = values[tempKey]

                if(value){
                    form_data.append(key, value.toString());
                }
            }

            if(isTimer && values?.tarefa && values?.projeto) {
                handleStopwatch(
                    {
                        taskId: parseInt(values.tarefa.toString()), 
                        projectId: parseInt(values.projeto.toString()), 
                        projectName: selectedProject.label, 
                        taskName: selectedTask.label,
                        descricao: values.descricao
                    });
            } else if(!preSelectedRecord?.hourId) {
                _postHoras(form_data);
            }else {
                _patchHoras(form_data, preSelectedRecord.hourId)
            }

            handlePostPatchHora();
        }}
    >
        {formikProps => {
            const {
                handleSubmit,
                setFieldValue,
                errors,
                touched,
                values
            } = formikProps;
            return (
                <FormContainer onSubmit={handleSubmit as FormEventHandler}>
                    <div>
                        <AsyncSelector
                            FieldName="projeto"
                            id="projeto"
                            value={selectedProject || initialOptionProject}
                            onChange={e => {
                                const option = e as SelectOptions;
                                setSelectedProject(option);
                                setSelectedTask(initialOptionTask);
                                setTaskTitle("");
                                setFieldValue('projeto', option !== null ? option.value : '');
                            }}
                            error={errors.projeto ? errors.projeto : ''}
                            touched={touched.projeto ? touched.projeto : false}
                            loadOptions={projectService.loadKanbanProjecsSelect as any}
                            additional={{
                                page: 1,
                                userDetails
                            }}
                            components={{Option: ProjectSelectOption, SingleValue: ProjectSingleValue}}
                            disabled={isFieldDisabled || values.tipo === 'ocio'}
                            errorMessage={true}
                            isClearable={false}
                            data-testid={"Projeto"}
                            style={errors.tarefa && touched.tarefa ? timerSelectErrorStyle : timerSelectStyle}
                        />
                    </div>
                    <SelectTaskContainer>
                        <div style={{overflow: "hidden"}}>
                            <AsyncSelector
                                FieldName="tarefa"
                                id="tarefa"
                                disabled={isFieldDisabled || values.tipo === 'ocio'}
                                isClearable={false}
                                value={selectedTask || initialOptionTask}
                                formatOptionLabel={
                                    isTaskSearchable ? SearchOptionLabel : undefined
                                }
                                components={
                                    isTaskSearchable ? { DropdownIndicator } : {}
                                }
                                onBlur={handleOnBlur}
                                onInputChange={handleInputChange}
                                onChange={e => {
                                    const option = e as TaskOptions;
                                    setSelectedTask(option);
                                    setFieldValue('tarefa', option !== null ? option.value : '');
                                    setTaskTitle("");
                                    if (isTaskSearchable) {
                                        setSelectedProject({
                                            value: option.valueAux ? option.valueAux : '',
                                            label: option.labelAux ? option.labelAux : '',
                                        });
                                        setFieldValue('projeto', option.valueAux);
                                    }
                                }}
                                name={'tarefa'}
                                noOptionsMessage={(search: {inputValue: string}) => {
                                    return isTaskSearchable &&
                                        search.inputValue.length < 3
                                        ? t('Digite o nome da tarefa para pesquisar.')
                                        : t('Sem tarefa');
                                }}
                                error={errors.tarefa ? errors.tarefa : ''}
                                touched={touched.tarefa ? touched.tarefa : false}
                                loadOptions={loadOptionsTask as any}
                                additional={{
                                    page: 1,
                                    projeto:
                                        selectedProject !== null
                                        ? selectedProject?.value?.toString()
                                            : '',
                                    isProjectSelected: !isTaskSearchable
                                }}
                                key={JSON.stringify(selectedProject)}
                                errorMessage={true}
                                data-testid={"Tarefa"}
                                style={errors.tarefa && touched.tarefa ? timerSelectErrorStyle : timerSelectStyle}
                            />
                        </div>

                        <AddTaskButton 
                            title={t('Adicionar Tarefa')}
                            type='button' 
                            disabled={isFieldDisabled || values.tipo === 'ocio'}
                            onClick={() => {
                                if(selectedProject?.value?.toString()?.length <= 0 || taskTitle.length === 0) {
                                    toast.error(t("Para adicionar tarefa, selecione o projeto e digite o nome da tarefa!"));
                                } else {
                                    handleAddTask(taskTitle)
                                }
                            }}
                        >
                            <AiOutlinePlus
                                color={"#426172"} 
                                size={20}
                            />
                        </AddTaskButton>
                    </SelectTaskContainer>
                
                    {!isTimer && (
                        <SelectDateContainer>
                            <div>
                                <FieldFormik
                                    max={verificaData()}
                                    type="date"
                                    nameField="data"
                                    disabled={loading}
                                    autoComplete={'off'}
                                    errors={errors.data ? errors.data : ''}
                                    touched={touched.data ? touched.data : false}
                                    onChange={e => {
                                        const target = e.target as HTMLInputElement;
                                        setDate(target.value);
                                        setFieldValue('data', target.value);
                                    }}
                                    value={date}
                                    data-testid={"Data"}
                                    className={`select-data-input ${errors.data && touched.data ? "select-error-active" : ""}`}
                                    style={{opacity: loading ? 0.5 : 1}}

                                />
                            </div>

                            <div>
                                <BasicTimePicker 
                                    className={`select-data-input ${errors.duracao && touched.duracao ? "select-error-active" : ""}`} 
                                    ampm={false}
                                    disabled={loading} 
                                    value={duration}
                                    data-testid={"Duracao"}
                                    onChange={(e) => {
                                        console.log(e)
                                        setDuration(e)
                                    }}   
                                    nameField="duracao"                                 
                                />
                            </div>
                        </SelectDateContainer>
                    )}
                      {!isTimer &&  <CheckboxContainer>
                        <input
                        type='checkbox' 
                        id='tipo'
                        name='tipo'
                        checked={hourType !== 'trab'} 
                        style={{padding: 0, accentColor: 'black'}}
                        color='#000000'
                        onChange={(e) => {
                            setHourType(e.target.checked ? 'ocio' : 'trab');
                            setFieldValue('tipo', e.target.checked ? 'ocio' : 'trab');
                            setFieldValue('projeto', '');
                            setFieldValue('tarefa', '');
                            setSelectedProject(initialOptionProject);
                            setSelectedTask(initialOptionTask);
                            setTaskTitle("");

                        }} 
                        />
                        <LabelCheckbox htmlFor='tipo'>Tempo Ocioso</LabelCheckbox>
                        </CheckboxContainer>
                        }
                         <FieldContainer>
                            <Label htmlFor='descricao'> Descrição </Label>
                            <Textarea id='descricao' name='descricao' value={description} onChange={(e) => {
                                setDescription(e.target.value)
                                setFieldValue('descricao', e.target.value)
                            }} />
                        </FieldContainer>

                    <StartButton type={"submit"} disabled={loading || (isTimer && (requestingStopwatch || loadingStopwatch || values?.tipo === 'ocio'))}>
                        {(loadingStopwatch && isTimer) || loading ? (
                            <span>
                                <LoaderCircle />
                            </span>
                        ) : (
                            <>
                                {StartIcon && 
                                    <StartIcon
                                        color={"#426172"} 
                                        size={20}
                                    />
                                }
                                {isTimer ? isTimerText : t("Salvar registro de horas")}
                            </>
                        )}
                            
                    </StartButton>
                </FormContainer>
                );
            }}
        </Formik>
    )
}