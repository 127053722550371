import React from "react";
import { MdEdit } from "react-icons/md";

import { CicloResponse } from "../../../../../services/providers/okrs/ciclo/types";
import { RowWrapper } from "../../../../../components/tailwindCommon/Table/RowWrapper";
import moment from "moment";
import { useSetAtom } from "jotai";
import { cicloModalAtom } from "../../../../../atoms/Okrs";

interface CicloRowProps {
    readonly ciclo: CicloResponse
}

export function CicloRow({ciclo}: CicloRowProps) {
    const setCicloModalState = useSetAtom(cicloModalAtom)

    const cicloModalEdit = () => {
        setCicloModalState({
			editing: true,
			selectedItem: ciclo,
			isModalOpen: true
        })
	};

    return (
        <div>
            <RowWrapper className='bg-ivory-primary text-white'>
                <div className='col-span-5 flex items-center gap-2'>
                    <p>{ciclo.nome}</p>
                </div>
                
                <div className='col-span-2 flex items-center gap-2'>
                    {moment(ciclo.data_inicial).format("DD/MM/YYYY")}
                </div>
                
                <div className='col-span-2 flex items-center gap-2'>
                    {moment(ciclo.data_final).format("DD/MM/YYYY")}
                </div>
                
                <div className='col-span-1 flex items-center'>
                    <button type="button" className='flex items-center justify-center p-0 m-0 w-7 h-7 border border-white rounded-md' onClick={cicloModalEdit}>
                        <MdEdit className='w-5 h-5'  />
                    </button>
                </div>
            </RowWrapper> 
        </div>
    )
}