import React, { Dispatch, SetStateAction } from 'react';
import { MdAddCircleOutline, MdPlayCircleOutline, MdOutlineStopCircle } from 'react-icons/md';
import { StopwatchForm } from './form';
import { StopwatchContainer, StopwatchContent } from './styled';
import { useStopwatch } from '../../../contexts/Stopwatch';
import { FormikProps } from 'formik';
import { FormValueProps } from './form/types';

export interface StopwatchProps {
    selectedMenu: "timer" | "manual";
    setOpenSide: Dispatch<SetStateAction<boolean>>;
    isOpenSide: boolean;
    formRef: React.RefObject<FormikProps<FormValueProps>>
}

export function Stopwatch({selectedMenu, formRef, setOpenSide, isOpenSide}: StopwatchProps) {
    const isTimer = selectedMenu === "timer";
    const { currentStopwatch, preSelectedRecord } = useStopwatch();







    const stopWatchIcon = currentStopwatch === "" ? MdPlayCircleOutline : MdOutlineStopCircle;
    return (
        <StopwatchContainer>

                <StopwatchContent>
                    <StopwatchForm  
                        formRef={formRef}
                        isOpenSide={isOpenSide}
                        setOpenSide={setOpenSide}
                        StartIcon={isTimer ? stopWatchIcon : MdAddCircleOutline} 
                        isTimer={isTimer}
                        preSelectedRecord={preSelectedRecord}
                    />
                </StopwatchContent>

               
        </StopwatchContainer>
    )
}