import React, { useEffect, useState } from 'react';

import ModalGenerico from '../../../../components/containers/Modais/ModalGenerico';
import { InfoContaForm } from '../../../../form/InfoConta';

import '../../../../components/containers/Modais/modalGeral.css';
import './style.css';
import { useTranslation } from 'react-i18next';
import "../../../../i18n";

export default function ModalContaPessoal(props) {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);

	useEffect(() => {}, []);

	const handleClickSave = () => {
		document.getElementById('input-submit-conta').click();
	};

	return (
		<>
			<ModalGenerico
				title={t("Mudar Senha")}
				modalGenericoShow={props.modalInfoPessoaShow}
				modalIsShowing={props.modalIsShowing}
				btnSave={true}
				onClickSave={handleClickSave}
				showEllipsis={false}
				loadingBtn={loading}
			>
				<div className="modal-width-horas">
					{props.editingInfoConta ? (
						<InfoContaForm
							modalGenericoShow={props.modalInfoPessoaShow}
							handleLoading={setLoading}
							editing={true}
						/>
					) : (
						<InfoContaForm
							modalGenericoShow={props.modalInfoPessoaShow}
							handleLoading={setLoading}
							editing={false}
						/>
					)}
				</div>
			</ModalGenerico>
		</>
	);
}
