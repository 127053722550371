import React, { useState } from 'react';

import ModalGenerico from '../../../../components/containers/Modais/ModalGenerico';
import { FeedbackForm } from '../../../../form/Feedback';

import '../../../../components/containers/Modais/modalGeral.css';
import './style.css';
import { useTranslation } from 'react-i18next';
import "../../../../i18n";

export default function ModalFeedback(props) {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);

	const handleClickSave = () => {
		document.getElementById('input-submit-feedback').click();
	};

	return (
		<>
			<ModalGenerico
				title={t("O que você gostaria de nos dizer?")}
				modalGenericoShow={props.modalFeedbackShow}
				modalIsShowing={props.modalIsShowing}
				btnSave={true}
				onClickSave={handleClickSave}
				showEllipsis={false}
				loadingBtn={loading}
			>
				<div className="modal-width-horas">
					<FeedbackForm
						modalFeedbackShow={props.modalFeedbackShow}
						handleLoading={setLoading}
						editing={false}
						handleClickSave={handleClickSave}
						handlePostFeedback={props.handlePostFeedback}
					/>
				</div>
			</ModalGenerico>
		</>
	);
}
