import moment from 'moment';
export const manipulaPeriodo = (opcao, diaAux) => {
	// const periodoDeDias = [];
	let diaAlvo = moment();
	let dataInicial = moment().format('YYYY-MM-DD');
	let dataFinal = moment().format('YYYY-MM-DD');

	switch (opcao) {
		case 'todo':
			dataInicial = '';
			dataFinal = '';
			break;
		case 'hoje':
			dataInicial = moment().format('YYYY-MM-DD');
			dataFinal = moment().format('YYYY-MM-DD');
			break;
		case 'ontem':
			dataInicial = moment()
				.subtract(1, 'days')
				.format('YYYY-MM-DD');
			dataFinal = moment()
				.subtract(1, 'days')
				.format('YYYY-MM-DD');
			break;
		case 'semana':
			// DEFINE O DIA ALVO
			diaAlvo = moment().format('ddd');
			// DEFINE A DATA DO PRIMEIRO DIA DA SEMANA ATUAL
			while (diaAlvo !== 'dom') {
				diaAlvo = moment(dataInicial)
					.subtract(1, 'days')
					.format('ddd');
				dataInicial = moment(dataInicial)
					.subtract(1, 'days')
					.format('YYYY-MM-DD');
			}
			// GERA O ARRAY COM OS DIAS DA SEMANA ATUAL
			for (let i = 0; i <= 6; i++) {
				if (i === 6) {
					dataFinal = moment(dataInicial)
						.add(i, 'days')
						.format('YYYY-MM-DD');
				}
			}
			break;
		case 'semanaP':
			// DEFINE O DIA ALVO
			diaAlvo = moment()
				.subtract(7, 'days')
				.format('ddd');
			dataInicial = moment().subtract(7, 'days');
			// DEFINE A DATA DO PRIMEIRO DIA DA SEMANA ATUAL
			while (diaAlvo !== 'dom') {
				diaAlvo = moment(dataInicial)
					.subtract(1, 'days')
					.format('ddd');
				dataInicial = moment(dataInicial)
					.subtract(1, 'days')
					.format('YYYY-MM-DD');
			}
			// GERA O ARRAY COM OS DIAS DA SEMANA ATUAL
			for (let i = 0; i <= 6; i++) {
				if (i === 6) {
					dataFinal = moment(dataInicial)
						.add(i, 'days')
						.format('YYYY-MM-DD');
				}
			}
			break;
		case 'mes':
			// DEFINE O PRIMEIRO E ÚLTIMO DIA DO MÊS
			dataInicial = moment(diaAlvo)
				.startOf('month')
				.format('YYYY-MM-DD');
			dataFinal = moment(diaAlvo)
				.endOf('month')
				.format('YYYY-MM-DD');

			// DEFINE O DIA ALVO COMO INICIAL
			diaAlvo = dataInicial;
			// GERA O ARRAY COM OS DIAS DO MES
			while (
				moment(diaAlvo).isBetween(dataInicial, dataFinal, undefined, '[]')
			) {
				diaAlvo = moment(diaAlvo).add(1, 'days');
			}
			break;
		case 'mesP':
			// DEFINE O DIA ALVO
			diaAlvo = moment().subtract(1, 'months');
			// DEFINE O PRIMEIRO E ÚLTIMO DIA DO MÊS
			dataInicial = moment(diaAlvo)
				.startOf('month')
				.format('YYYY-MM-DD');
			dataFinal = moment(diaAlvo)
				.endOf('month')
				.format('YYYY-MM-DD');

			// DEFINE O DIA ALVO COMO INICIAL
			diaAlvo = dataInicial;
			// GERA O ARRAY COM OS DIAS DO MES
			while (
				moment(diaAlvo).isBetween(dataInicial, dataFinal, undefined, '[]')
			) {
				diaAlvo = moment(diaAlvo).add(1, 'days');
			}
			break;
		case 'ano':
			// DEFINE O PRIMEIRO E ÚLTIMO DIA DO MÊS
			dataInicial = moment()
				.startOf('year')
				.format('YYYY-MM-DD');
			dataFinal = moment()
				.endOf('year')
				.format('YYYY-MM-DD');
			// DEFINE O DIA ALVO COMO INICIAL
			diaAlvo = dataInicial;
			break;
		case 'anoP':
			// DEFINE O DIA ALVO
			diaAlvo = moment().subtract(1, 'years');
			// DEFINE O PRIMEIRO E ÚLTIMO DIA DO MÊS
			dataInicial = moment(diaAlvo)
				.startOf('year')
				.format('YYYY-MM-DD');
			dataFinal = moment(diaAlvo)
				.endOf('year')
				.format('YYYY-MM-DD');

			// DEFINE O DIA ALVO COMO INICIAL
			diaAlvo = dataInicial;
			break;
		case 'next':
			// DEFINE O DIA ALVO
			diaAlvo = moment(diaAux).format('ddd');
			dataInicial = diaAux;

			// DEFINE A DATA DO PRIMEIRO DIA DA SEMANA ATUAL
			while (diaAlvo !== 'dom') {
				diaAlvo = moment(dataInicial)
					.subtract(1, 'days')
					.format('ddd');
				dataInicial = moment(dataInicial)
					.subtract(1, 'days')
					.format('YYYY-MM-DD');
			}
			// GERA O ARRAY COM OS DIAS DA SEMANA ATUAL
			for (let i = 0; i <= 6; i++) {
				if (i === 6) {
					dataFinal = moment(dataInicial)
						.add(i, 'days')
						.format('YYYY-MM-DD');
				}
			}
			break;
		case 'prev':
			// DEFINE O DIA ALVO
			diaAlvo = moment(diaAux).format('ddd');
			dataInicial = diaAux;

			// DEFINE A DATA DO PRIMEIRO DIA DA SEMANA ATUAL
			while (diaAlvo !== 'dom') {
				diaAlvo = moment(dataInicial)
					.subtract(1, 'days')
					.format('ddd');
				dataInicial = moment(dataInicial)
					.subtract(1, 'days')
					.format('YYYY-MM-DD');
			}

			// GERA O ARRAY COM OS DIAS DA SEMANA ATUAL
			for (let i = 0; i <= 6; i++) {
				if (i === 6) {
					dataFinal = moment(dataInicial)
						.add(i, 'days')
						.format('YYYY-MM-DD');
				}
			}

			break;
		default:
			break;
	}
	return [dataInicial, dataFinal];
};

export const manipulaPeriodoSelecionado = (opcao, diaAux) => {
	// setOpcaoCalendario(opcao);
	const periodoDeDias = [];
	let diaAlvo = moment();
	let dataInicial = moment().format('YYYY-MM-DD');
	let dataFinal = moment().format('YYYY-MM-DD');
	//
	// if (opcao === 'personalizado') {
	// 	setIntervaloPersonalizado(true);
	// } else {
	// 	setIntervaloPersonalizado(false);
	// }
	const calculateDays = (
		subtracted = 0,
		formated = 'YYYY-MM-DD',
		started = 'today',
	) => {
		dataInicial = moment()
			.subtract(subtracted, 'days')
			.startOf(started)
			.format(formated);
		dataFinal = moment()
			.subtract(subtracted, 'days')
			.format(formated);
		periodoDeDias.push({
			diaSemana: moment(dataInicial).format('ddd'),
			data: moment(dataInicial).format('YYYY-MM-DD'),
		});
		return { dataInicial, dataFinal, periodoDeDias };
	};
	const calculateWeekDays = (subtracted = 0, started = 'week') => {
		dataInicial = moment()
			.startOf(started)
			.subtract(subtracted, 'days')
			.format('YYYY-MM-DD');
		for (let i = 0; i <= 6; i++) {
			periodoDeDias.push({
				diaSemana: moment(dataInicial)
					.add(i, 'days')
					.format('ddd'),
				data: moment(dataInicial)
					.add(i, 'days')
					.format('YYYY-MM-DD'),
			});

			if (i === 6) {
				dataFinal = moment(dataInicial)
					.add(i, 'days')
					.format('YYYY-MM-DD');
			}
		}
	};
	const calculateMouthDays = (subtracted = 0) => {
		// DEFINE O PRIMEIRO E ÚLTIMO DIA DO MÊS
		diaAlvo = moment().subtract(subtracted, 'months');
		dataInicial = moment(diaAlvo)
			.startOf('month')
			.format('YYYY-MM-DD');
		dataFinal = moment(diaAlvo)
			.endOf('month')
			.format('YYYY-MM-DD');
		// DEFINE O DIA ALVO COMO INICIAL
		diaAlvo = dataInicial;
		// GERA O ARRAY COM OS DIAS DO MES
		while (moment(diaAlvo).isBetween(dataInicial, dataFinal, undefined, '[]')) {
			periodoDeDias.push({
				diaSemana: moment(diaAlvo).format('ddd'),
				data: moment(diaAlvo).format('YYYY-MM-DD'),
			});
			diaAlvo = moment(diaAlvo).add(1, 'days');
		}
	};
	const calculateYearDays = (subtracted = 0) => {
		// DEFINE O DIA ALVO
		diaAlvo = moment().subtract(subtracted, 'years');
		// DEFINE O PRIMEIRO E ÚLTIMO DIA DO MÊS
		dataInicial = moment(diaAlvo)
			.startOf('year')
			.format('YYYY-MM-DD');
		dataFinal = moment(diaAlvo)
			.endOf('year')
			.format('YYYY-MM-DD');

		// DEFINE O DIA ALVO COMO INICIAL
		diaAlvo = dataInicial;
	};
	//
	switch (opcao) {
		case 'hoje':
			calculateDays();
			break;
		case 'ontem':
			calculateDays(1);
			break;
		case 'semana':
			calculateWeekDays();
			break;
		case 'semanaP':
			calculateWeekDays(7);
			break;
		case 'mes':
			calculateMouthDays();
			break;
		case 'mesP':
			calculateMouthDays(1);
			break;
		case 'ano':
			calculateYearDays();
			break;
		case 'anoP':
			calculateYearDays(1);
			break;
		case 'next':
			// DEFINE O DIA ALVO
			diaAlvo = moment(diaAux).format('ddd');
			dataInicial = diaAux;

			// DEFINE A DATA DO PRIMEIRO DIA DA SEMANA ATUAL
			while (diaAlvo !== 'dom') {
				diaAlvo = moment(dataInicial)
					.subtract(1, 'days')
					.format('ddd');
				dataInicial = moment(dataInicial)
					.subtract(1, 'days')
					.format('YYYY-MM-DD');
			}

			// GERA O ARRAY COM OS DIAS DA SEMANA ATUAL
			for (let i = 0; i <= 6; i++) {
				periodoDeDias.push({
					diaSemana: moment(dataInicial)
						.add(i, 'days')
						.format('ddd'),
					data: moment(dataInicial)
						.add(i, 'days')
						.format('YYYY-MM-DD'),
				});

				if (i === 6) {
					dataFinal = moment(dataInicial)
						.add(i, 'days')
						.format('YYYY-MM-DD');
				}
			}
			break;
		case 'prev':
			// DEFINE O DIA ALVO
			diaAlvo = moment(diaAux).format('ddd');
			dataInicial = diaAux;

			// DEFINE A DATA DO PRIMEIRO DIA DA SEMANA ATUAL
			while (diaAlvo !== 'dom') {
				diaAlvo = moment(dataInicial)
					.subtract(1, 'days')
					.format('ddd');
				dataInicial = moment(dataInicial)
					.subtract(1, 'days')
					.format('YYYY-MM-DD');
			}

			// GERA O ARRAY COM OS DIAS DA SEMANA ATUAL
			for (let i = 0; i <= 6; i++) {
				periodoDeDias.push({
					diaSemana: moment(dataInicial)
						.add(i, 'days')
						.format('ddd'),
					data: moment(dataInicial)
						.add(i, 'days')
						.format('YYYY-MM-DD'),
				});
				if (i === 6) {
					dataFinal = moment(dataInicial)
						.add(i, 'days')
						.format('YYYY-MM-DD');
				}
			}

			break;
		default:
			break;
	}
	return { dataInicial, dataFinal, periodoDeDias };
	// setDataInicialFilterAux(dataInicial);
	// setDataFinalFilterAux(dataFinal);
	// setDiasDaSemanaArrayAux(periodoDeDias);
};
