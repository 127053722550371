import React, { ReactNode, useCallback } from 'react';
import { LoaderPagination } from '../loaders/LoaderPagination';

interface DinamicPaginationProps {
	children: ReactNode;
	loading: boolean;
	totalPages: number | null;
	currentPage: number;
	lengthData: number;
	returnPage: (page: number) => void;
	msgToEmptyData: string;
	contentHeight?: number | null;
	scrollDisabled?: boolean;
}

const DinamicPagination = ({
	children,
	loading,
	totalPages,
	currentPage,
	lengthData,
	returnPage,
	msgToEmptyData,
	contentHeight = null,
	scrollDisabled = false
}: DinamicPaginationProps) => {
	const paginacaoDinamica = useCallback((e: React.MouseEvent | React.UIEvent) => {
		const element = e.target as HTMLInputElement;
		if (totalPages === currentPage) {
			return;
		} else {
			if (!loading) {
				if (element.scrollHeight - element.scrollTop === element.clientHeight) {
					const page = currentPage + 1;
					returnPage(page);
					element.scrollTop -= 5;
				}
			}
		}
	}, [returnPage, totalPages, currentPage, loading]);

	return (
		<div
			className={` paginacao ${scrollDisabled ? 'disable-scroll' : ''}`}
			id="registros-horas"
			style={
				contentHeight ? { height: `calc(100vh - ${contentHeight}px)` } : {}
			}
			onScroll={paginacaoDinamica}
		>
			{children}
			{lengthData === 0 && loading === false ? (
				<span
					className='pt-4'
					style={{
						display: 'flex',
						justifyContent: 'center',
						color: '#8099a7',
					}}
				>
					{msgToEmptyData}
				</span>
			) : (
				<></>
			)}
			<LoaderPagination
				showLoader={totalPages === currentPage && loading === false}
				loading={loading}
				onClick={paginacaoDinamica}
			/>
		</div>
	);
};

export default DinamicPagination;
