import React, { useState } from 'react';
import { CellData, SelectAllProps } from '../types';
import TCellSelectAll from '../TCellSelectAll';
import THeadCell from '../THeadCell';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { GenericButton } from '../../../planus2/Buttons/GenericButton';
import { FaCirclePlus } from 'react-icons/fa6';
import { t } from 'i18next';
import { AddExtraColumnModal } from '../../../../pages/PersonManagement/AddExtraColumnModal';
import { ExtraColumn } from '../../../../models/ExtraColumn';

export interface THeaderProps {
	headerData: CellData[];
	selectAll?: SelectAllProps;
	handleChangeHeader?: (value: string, id: string) => void;
	handleDeleteHeader?: (id: string) => void;
	onAddNewColumn?: (newColumn: ExtraColumn) => void;
	onDragEnd?: (result: DropResult) => void;
}

export default function THeader({
	headerData,
	selectAll,
	handleChangeHeader,
	handleDeleteHeader,
	onAddNewColumn,
	onDragEnd = () => {},
}: Readonly<THeaderProps>) {
	const [isNewFieldOpen, setIsNewFieldOpen] = useState(false);

	const handleToggleNewField = () => {
		setIsNewFieldOpen((prev) => !prev);
	};

	return (
		<thead
			className={` sticky top-0 z-10 rounded-lg h-10 shadow-table border border-solid bg-white border-table-borderHeader`}
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable
					droppableId="table-header"
					direction="horizontal"
					type="column"
				>
					{(provided, snapshot) => (
						<tr
							{...provided.droppableProps}
							ref={provided.innerRef}
							className="[&>*:first-child]:rounded-tl-table  bg-table-bgHeader [&>*:last-child]:rounded-tr-table [&>*:first-child]:pl-5 [&>*:last-child]:pr-5"
						>
							{selectAll?.position === 'start' && (
								<TCellSelectAll
									onClick={selectAll.onSelectAll}
									actions={selectAll.actions}
								/>
							)}

							{headerData.map((header, index) => (
								<THeadCell
									key={`${header.id}-${header.title}-${index}`}
									item={header}
									handleChange={handleChangeHeader}
									handleDelete={handleDeleteHeader}
								/>
							))}
								{provided.placeholder}

							{onAddNewColumn &&  (
								<>
									<th className="relative ">
										{isNewFieldOpen && (
											<div className="absolute right-0 top-10 pr-5">
												<AddExtraColumnModal
													handleAfterPost={onAddNewColumn}
													handleCloseModal={handleToggleNewField}
												/>
											</div>
										)}
										{!snapshot.isDraggingOver && <GenericButton
											onClick={handleToggleNewField}
											className="whitespace-nowrap  w-full bg-ivory-primary text-white px-4"
										>
											<FaCirclePlus /> {t('Novo campo')}
										</GenericButton>}
									</th>
									<th className="w-full"></th>
								</>
							)}

							{selectAll && selectAll.position !== 'start' && (
								<TCellSelectAll
									onClick={selectAll.onSelectAll}
									actions={selectAll.actions}
								/>
							)}
						</tr>
					)}
				</Droppable>
			</DragDropContext>
		</thead>
	);
}
