import React, { useCallback, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../models/Select';
import {  Td } from './styled';
import { CustomInput } from './CustomInput';

interface TableCellTextProps {
	name: string;
	value: string | number;
	type?: React.HTMLInputTypeAttribute;
	width?: string;
	options?: SelectOptions[];
	id?: number | string;
	disabled?: boolean;
	fontSize?: string;
	// handleChange: ((value: string | number) => void);
	onBlur: (value: string | number, name: string, id?: number | string) => void | string;
	onFocus?: (value: string | number, name: string, id?: number | string) => string;
	onChange?: (value: string | number, name: string, id?: number | string) => string;
	color?: string;
	customStyle?: string;
	isCurrency?: boolean;
	mask?: string;
}

export default function TableCellText({
	name,
	value,
	type,
	width,
	onBlur,
	onFocus,
	onChange,
	id,
	disabled,
	fontSize,
	color,
	customStyle,
	isCurrency,
	mask
}: TableCellTextProps) {
	const [defaultValue, setDefaultValue] = useState(value);

	useEffect(() => {
		setDefaultValue(value);
	}, [value]);

	const handleBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement, Element>) => {
			if (e.target.value !== value?.toString()) {
				const result = onBlur(e.target.value, name, id);

				if(result) {
					setDefaultValue(result)
				}
			}
		},
		[onBlur, name, id, value],
	);

	const handleFocus = useCallback(
		(e: React.FocusEvent<HTMLInputElement, Element>) => {
			if (onFocus) {
				setDefaultValue(onFocus(e.target.value, name, id));
			}
		},
		[onFocus, name, id, value],
	);

	const handleKeyUp = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter') {
			e.currentTarget.blur();
		}
	}, [onBlur, name, id, value])

	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			setDefaultValue(onChange(e.target.value, name, id));
		} else {
			setDefaultValue(e.target.value);
		}
	}, []);

	return (
		<Td>
			<CustomInput
				customStyle={customStyle}
				color={color}
				fontSize={fontSize}
				disabled={disabled}
				onBlur={handleBlur}
				onFocus={handleFocus}
				onChange={handleChange}
				inputWidth={width}
				type={type}
				name={name}
				id={id?.toString()}
				value={defaultValue}
				onKeyUp={handleKeyUp}
				title={defaultValue?.toString()}
				isCurrency={isCurrency}
				mask={mask}
			/>
		</Td>
	);
}
