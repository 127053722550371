import React, { useEffect, useState } from 'react';

import '../../i18n';

import FeedbackService from '../../services/providers/feedback';
import { FeedbackSchema } from './validation';

import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import '../../form/formGeral.css';
import './style.css';

//FileImport
import { uniqueId } from 'lodash';
import Label from '../../components/common/Labels/label';
import fileSize from 'filesize';
import FileList from '../../pages/Registros/Projetos/ConfigAvancada/UploudFile/FileList';
import UploudFile from '../../pages/Registros/Projetos/ConfigAvancada/UploudFile';

//UploadFile
import Dropzone from 'react-dropzone';
import {
	DropContainer,
	UploadMessage,
} from '../../pages/Registros/Projetos/ConfigAvancada/UploudFile/styles.js';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export const FeedbackForm = props => {
	const {t} = useTranslation();
	const initialValues = { descricao: '' };
	const [fileData] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [currentUploadedFiles, setCurrentUploadedFiles] = useState([]);
	useEffect(() => {
	}, [uploadedFiles, fileData]);
console.log(currentUploadedFiles)
	const _postFeedbackComentario = params => {
		const data = new FormData();
		data.append('descricao', params.descricao);
		currentUploadedFiles.forEach((item, index) =>
			data.append(`arquivo-${index}`, item.file),
		);
		const feedbackService = new FeedbackService();
		feedbackService.postFeedbackComentario(
			data,
			response => {
				if (response) {
					toast.success(t("Feedback salvo com sucesso!"));

					props.modalFeedbackShow(false);
				}
				props.handleLoading(false);
			},
			error => {
				toast.error(error);
				props.handleLoading(false);
			},
		);
	};

	const handleUpload = files => {
		const currentUploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: fileSize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}));
		setUploadedFiles(prev => [...prev, ...currentUploadedFiles]);
		setCurrentUploadedFiles(prev => [...prev, ...currentUploadedFiles]);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={FeedbackSchema}
			enableReinitialize={true}
			onSubmit={(values, actions) => {
				actions.setSubmitting(true);

				_postFeedbackComentario(values);

				values['descricao'] = '';
			}}
			render={props => (
				<>
					<FormikForm>
						<fieldset>
							<div className="modal-bg-center">
								<div className="container">
									<div className="row">
										<div className="col-12">
											<Label text={t("Digite seus comentários aqui")} />
											<Field
												autoComplete={'off'}
												name="descricao"
												component="textarea"
												// disabled={loading}
												className={
													props.errors.descricao && props.touched.descricao
														? 'form-error-style-textarea-feedback'
														: 'form-default-style-textarea-feedback'
												}
											/>

											<ErrorMessage name="descricao">
												{msg => (
													<div>
														<div className="input-error-style" role="alert">
															<FontAwesomeIcon
																icon={faExclamationTriangle}
																className="mr-1"
															/>
															{msg}
														</div>
													</div>
												)}
											</ErrorMessage>
											<p className="p-feedback-descricao">
												{t("Ao pressionar")}{' '}<b>{t("Salvar e Fechar")}</b>{' '} 
												{t("seus comentários serão usados para melhorar os produtos e serviços do Planus. Com base em seus comentários, poderemos fornecer informações adicionais para ajudá-lo com o problema de suporte.")}
											</p>
											<div
												className="col-sm-12"
												style={{ background: '#f7f6f5' }}
											>
												<Label text={t("ANEXAR ARQUIVO")} />
												<div
													style={{
														background: 'white',
														borderRadius: '10px',
													}}
												>
													<div
														style={{
															padding: '24px',
															background: 'white',
															borderRadius: '10px',
														}}
													>
														{!!uploadedFiles.length && (
															<FileList files={uploadedFiles} />
														)}
														<UploudFile onUpload={handleUpload} />
													</div>
												</div>
											</div>
											<br />
											<input
												id="input-submit-feedback"
												style={{ display: 'none' }}
												type="submit"
											/>
										</div>
									</div>
								</div>
							</div>
						</fieldset>
					</FormikForm>
				</>
			)}
		/>
	);
};

const UploadFile = ({ onUpload }) => {
	const renderDragMessage = (isDragActive, isDragReject) => {
		if (!isDragActive) {
			return (
				<UploadMessage>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<div className="msg-upload">
							{t("Solte o documento aqui ou clique para anexar")}
						</div>
						<div className="msg-upload-support">
							{t("Suporta")}: JPEG, PNG, PDF até 5mb
						</div>
					</div>
				</UploadMessage>
			);
		}

		if (isDragReject) {
			return <UploadMessage type="error">{t("Arquivo não suportado")}</UploadMessage>;
		}

		return <UploadMessage type="success">{t("Solte os arquivos aqui")}</UploadMessage>;
	};

	return (
		<Dropzone accept="image/*,.pdf" onDropAccepted={onUpload}>
			{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
				<DropContainer
					{...getRootProps()}
					isDragActive={isDragActive}
					isDragReject={isDragReject}
				>
					<input {...getInputProps()} />
					{renderDragMessage(isDragActive, isDragReject)}
				</DropContainer>
			)}
		</Dropzone>
	);
};

export default UploadFile;
