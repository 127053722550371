import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import PersonService from '../services/providers/responsible';

import PTBR from './locales/pt-br/translation.json';
import ENUK from './locales/en-uk/translation.json';

const resources = {
	'pt-BR': { translation: PTBR },
	'en-UK': { translation: ENUK },
};

const findInitialLanguage = (value) => {
	const currentLanguageResource = Object.keys(resources).find(key => key === value);
	return currentLanguageResource ? currentLanguageResource : Object.keys(resources)[0];
}

const initialLanguage =
	localStorage.getItem('PlanusLanguage') === null
		? 'pt-BR'
		: findInitialLanguage(localStorage.getItem('PlanusLanguage'));

i18n.use(initReactI18next).init({
	resources,
	lng: initialLanguage,
	fallbackLng: initialLanguage,
	interpolation: { escapeValue: false },
	returnNull: false,
});

export default i18n;

export async function changeLang(lang) {
	i18n.changeLanguage(findInitialLanguage(lang), (err) => {
		if (err) return console.log('something went wrong loading', err);
	}).then(() => {
		if(lang) {
			localStorage.setItem("PlanusLanguage", lang);
		}
	});
}

export async function changeUserLang(lang, pessoaId) {
	const personService = new PersonService();
	await personService.patchPessoaAsync(
		{idioma: lang}, 
		pessoaId
	);
}
