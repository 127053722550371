import React from 'react';

interface TitleSubtitleCellProps {
	title: string;
	subtitle: string;
	onClick?: () => void;
	className?: string;
	imgSrc?: string;
}

export default function TitleSubtitleCell({
	title,
	subtitle,
	onClick,
	className,
	imgSrc
}: Readonly<TitleSubtitleCellProps>) {
	return (
		<td onClick={onClick} className={onClick && `cursor-pointer hover:opacity-80  px-2 ${className}`}>
			<div className='flex flex-row gap-4 items-center overflow-hidden text-ellipsis'>
				{imgSrc && <img alt={title} src={imgSrc} className='h-10 w-10 rounded-full overflow-hidden min-w-10' />}
			<div className={`flex flex-col text-ivory-primary overflow-hidden ${onClick && 'hover:underline'}`}>
				<div className="text-base font-black min-w-[400px] xl:min-w-0 xl:whitespace-nowrap overflow-hidden text-ellipsis w-full hover:whitespace-normal">{title}</div>
				<p className="text-smaller">{subtitle}</p>
			</div>
			</div>
		</td>
	);
}
