import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import '../../../i18n';
import HttpService from '../../config/http';
import { CallbackType } from '../types';

export default class ContaService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	postConta = (passForm: FormData, success: CallbackType<AxiosResponse<{message: string}>>, errorCallback: CallbackType<string>) => {
		this.api
			.put(`api/v1/contas-planus/mudar-senha/`, passForm)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						const errorText = result.data.new_password;
						throw new Error(errorText ? errorText : (t('Erro ao tentar mudar a senha!')));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
}
