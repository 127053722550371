import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Icon from '../Icons/icon';

import '../../../i18n';

import './style.css';
import { useTranslation } from 'react-i18next';

export interface CopyButton {
	textToCopy: string; 
	iconTitle: string; 
	containerClassname?: string; 
	className?: string;
}

export default function CopyButton({ textToCopy, iconTitle, containerClassname, className, ...rest }: CopyButton) {
	const {t} = useTranslation();
	const [copied, setCopied] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (copied) setCopied(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [copied]);

 
	return (
		<CopyToClipboard
			text={textToCopy || ''}
			onCopy={(text, result) => {
				if (result) {
					setCopied(true);
				}
			}}
			{...rest}
		>
			<div className={`${containerClassname || ''} copy-container`}>
				<Icon
					className={className || ''}
					title={iconTitle || t('Copiar').toString()}
					pointer
					icon={faShareAlt}
					style={{
						transition: 'all 300ms ease-in-out',
					}}
				/>
				{copied && <span className='copied-span'>{t("Copiado")}</span>}
			</div>
		</CopyToClipboard>
	);
}
