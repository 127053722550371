import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { components } from 'react-select';
import { SearchIcon } from './styled';

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<SearchIcon
				icon={faSearch}
				className={
					props.selectProps.menuIsOpen ? 'search-modal-open' : 'search-modal'
				}
			/>
		</components.DropdownIndicator>
	);
};

export default DropdownIndicator;
