import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { Responsible } from '../../../models/Responsible';
import { formatValuesToSelectAsync } from '../../../utils/funcoes/reactSelect';
import HttpService from '../../config/http';
import { CallbackType, DataType } from '../types';
import { highlightCurrentUserFilter } from '../../../utils/funcoes/arraysFunctions';
import { UserDetails } from '../../../models/UserDetails';

export default class PessoaService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getPessoas = (search: string, success: CallbackType<AxiosResponse<DataType<Responsible[]>>>, errorCallback: CallbackType<string>) => {
		this.api
			.get(`api/v1/pessoas/?search=${search}`)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getPessoasSelect = (
		cliente: number[], 
		projeto: number[], 
		tarefa: number[], 
		success: CallbackType<AxiosResponse<Responsible[]>>, 
		errorCallback: CallbackType<string>
	) => {
		this.api
			.get(
				`api/v1/pessoas/?cliente__in=${cliente}&projeto__in=${projeto}&tarefas__in=${tarefa}&minimal=true`,
			)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getPessoasPaginacao = (page: number, {acesso, search, deleted, additional}: {acesso: string, search: string, deleted:boolean, additional: string}, success: CallbackType<DataType<Responsible[]>>, errorCallback: CallbackType<string>, config?: AxiosRequestConfig) => {
		this.api
			.get(`api/v1/pessoas/?paginate=true&page=${page}&search=${search}&acesso=${acesso}&deleted=${deleted}&${additional}`, config)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else if (result.status === 499) {
					success({count: 0, next: null,previous: null,results: result.data, total_pages: 1,cancelled: true})
				}else{
					if (typeof result.data.results === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => {
				if (error.name === 'AbortError') {
					console.log('Requisição cancelada');
				} else {
					errorCallback(error.toString())
				}
			});
	};

	getPessoasAllPaginacao = (page: number, {acesso, search, deleted}: {acesso: string, search: string, deleted: string}, success: CallbackType<DataType<Responsible[]>>, errorCallback: CallbackType<string>) => {
		this.api
			.get(`api/v1/pessoas/?paginate=true&page=${page}&all=true&search=${search}&acesso=${acesso}&deleted=${deleted}`)
			.then(result => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data.results === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	loadPessoasSelect = async (
		search: string,
		loadedOptions: any,
		{
			page = 1,
			placeholder = '',
			cliente = '',
			projeto = '',
			tarefa = '',
			adminProjeto = '',
			userDetails = {} as UserDetails,
		},
	) => {
		const response = await this.api.get(
			`api/v1/pessoas/?cliente__in=${cliente}&projeto__in=${projeto}&tarefas__in=${tarefa}&admin_projeto=${adminProjeto}&minimal=true&paginate=true&page=${page}&search=${search}&`,
		);

		if (response.status !== 200) {
			if (typeof response?.data === typeof {}) {
				const objError = response?.data;
				for (const key in objError) {
					throw new Error(`${objError[key]}`);
				}
			} else {
				throw new Error(t("Erro ao tentar carregar as pessoas!"));
			}
		}
		
		let tempOption = [];

		if(userDetails?.id_pessoa) {
			tempOption = highlightCurrentUserFilter(formatValuesToSelectAsync(response?.data?.results, placeholder), userDetails);
		} else {
			tempOption = formatValuesToSelectAsync(response?.data?.results, placeholder);
		}

		return {
			options: tempOption,
			hasMore: !!response?.data?.next,
			additional: {
				page: page + 1,
				placeholder,
				cliente,
				projeto,
				tarefa,
				adminProjeto,
			},
		};
	};

	getPessoasSelectGerente = (
		cliente: number[],
		projeto: number[],
		tarefa: number[],
		success: CallbackType<AxiosResponse<DataType<Responsible[]>>>,
		errorCallback: CallbackType<string>,
	) => {
		this.api
			.get(
				`api/v1/pessoas/?admin_projeto=true&cliente__in=${cliente}&projeto__in=${projeto}&tarefas__in=${tarefa}&minimal=true`,
			)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getPessoasByID = (ID: number[], projetoID: number[], clientID: number[], success: CallbackType<AxiosResponse<DataType<Responsible[]>>>, errorCallback: CallbackType<string>) => {
		// Recupera todas as pessoas que ja foram membros
		this.api
			.get(
				`api/v1/pessoas?minimal=0&id__in=${ID}&projeto__in=${projetoID}&cliente__in=${clientID}`,
			)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getPessoaActiveMembersByID = (
		ID: number[],
		projetoID: number[],
		clientID: number[],
		success: CallbackType<AxiosResponse<DataType<Responsible[]>>>,
		errorCallback: CallbackType<string>,
	) => {
		// Recupera todas as pessoas que são membrosAtivos
		this.api
			.get(
				`api/v1/pessoas?minimal=0&id__in=${ID}&membroativo__in=${projetoID}&cliente__in=${clientID}`,
			)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getPessoasID = (id: number | undefined, success: CallbackType<AxiosResponse<Responsible>>, errorCallback: CallbackType<string>) => {
		this.api
			.get(`api/v1/pessoas/${id}?minimal=2`)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getPessoaByID = (id: number | undefined, success: CallbackType<AxiosResponse<Responsible>>, errorCallback: CallbackType<string>) => {
		this.api
			.get(`api/v1/pessoas/${id}`)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as pessoas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	postPessoa = (params: FormData, success: CallbackType<AxiosResponse<Responsible>>, errorCallback: CallbackType<string>) => {
		this.api
			.postFile('api/v1/pessoas/', params)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar adicionar pessoa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchPessoa = (params: any, id: number | string, success: CallbackType<AxiosResponse<Responsible>>, errorCallback: CallbackType<string>, additional: string = '') => {
		this.api
			.patchFile(`api/v1/pessoas/${id}/?${additional}`, params)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar a pessoa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchPessoaAsync = async (params: any, id: number | string, additional: string = '') => {
		try {
			const result: AxiosResponse<Responsible> = await this.api
				.patchFile(`api/v1/pessoas/${id}/?${additional}`, params);
			
			if (result.status === 200) {
				return result;
			} else {
				throw new Error(t("Erro ao tentar atualizar a pessoa!"));
			}
		} catch {
			Error(t("Erro ao tentar atualizar a pessoa!"))
		}
	};

	patchPessoaAll = (params: any, id: number, success: CallbackType<AxiosResponse<Responsible>>, errorCallback: CallbackType<string>) => {
		this.api
			.patchFile(`api/v1/pessoas-all/${id}/`, params)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar a pessoa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};


	deletePessoa = (id: number, success: CallbackType<AxiosResponse<string>>, errorCallback: CallbackType<string>) => {
		this.api
			.delete('api/v1/pessoas/' + id)
			.then(result => {
				if (result.status === 301 || result.status === 204) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar remover a pessoa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
}
