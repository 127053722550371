export const currencyMask = (event, setValue) => {
	// var tecla = !evento ? window.event.keyCode : evento.which;
	if (event) {
		var valor = event.target.value.replace(/[^\d]+/gi, '').reverse();
		var mascara = '###.###,##'.reverse();
		var resultado = '';
		for (var x = 0, y = 0; x < mascara.length && y < valor.length; ) {
			if (mascara.charAt(x) !== '#') {
				resultado += mascara.charAt(x);
				x++;
			} else {
				resultado += valor.charAt(y);
				y++;
				x++;
			}
		}
		// campo.value = resultado.reverse();
		setValue(prevCusto => (prevCusto = resultado.reverse().replace(',', '.')));
	}
};
export const currencyMaskProjeto = (event, setValue) => {
	// var tecla = !evento ? window.event.keyCode : evento.which;
	if (event) {
		var valor = event.target.value.replace(/[^\d]+/gi, '').reverse();
		var mascara = '###.###.###,##'.reverse();
		var resultado = '';
		for (var x = 0, y = 0; x < mascara.length && y < valor.length; ) {
			if (mascara.charAt(x) !== '#') {
				resultado += mascara.charAt(x);
				x++;
			} else {
				resultado += valor.charAt(y);
				y++;
				x++;
			}
		}
		// campo.value = resultado.reverse();
		setValue(prevCusto => (resultado.reverse()));
	}
};

export const currencyMaskReturnProjeto = event => {
	// var tecla = !evento ? window.event.keyCode : evento.which;
	if (event) {
		var valor = event.replace(/[^\d]+/gi, '').reverse();
		var mascara = '###.###.###,##'.reverse();
		var resultado = '';
		for (var x = 0, y = 0; x < mascara.length && y < valor.length; ) {
			if (mascara.charAt(x) !== '#') {
				resultado += mascara.charAt(x);
				x++;
			} else {
				resultado += valor.charAt(y);
				y++;
				x++;
			}
		}
		// campo.value = resultado.reverse();
		const removeThousandDot = resultado.replaceAll('.', '');
		const removeComma = removeThousandDot.replaceAll(',', '.');
		return removeComma.reverse();
	}
};
export const currencyMaskReturnProjetoGet = event => {
	// var tecla = !evento ? window.event.keyCode : evento.which;
	if (event) {
		var valor = event.replace(/[^\d]+/gi, '').reverse();
		var mascara = '###.###,##'.reverse();
		var resultado = '';
		for (var x = 0, y = 0; x < mascara.length && y < valor.length; ) {
			if (mascara.charAt(x) !== '#') {
				resultado += mascara.charAt(x);
				x++;
			} else {
				resultado += valor.charAt(y);
				y++;
				x++;
			}
		}
		// campo.value = resultado.reverse();
		return resultado.reverse();
	}
};
export const currencyMaskReturn = event => {
	// var tecla = !evento ? window.event.keyCode : evento.which;
	if (event) {
		var valor = event.replace(/[^\d]+/gi, '').reverse();
		var mascara = '###.###.###,##'.reverse();
		var resultado = '';
		for (var x = 0, y = 0; x < mascara.length && y < valor.length; ) {
			if (mascara.charAt(x) !== '#') {
				resultado += mascara.charAt(x);
				x++;
			} else {
				resultado += valor.charAt(y);
				y++;
				x++;
			}
		}
		// campo.value = resultado.reverse();
		return resultado.reverse().replace(',', '.');
	}
};

export const replaceComma = (event, setValue) => {
	setValue(prevCusto => (prevCusto = event.reverse().replace(',', '.')));
};

String.prototype.reverse = function() {
	return this.split('')
		.reverse()
		.join('');
};

export const formatterCurrency = new Intl.NumberFormat('pt-br', {
	style: 'currency',
	currency: 'BRL',
});

export const hoursXcurrency = (horas, valor) => {
	var actualTime = horas.split(':');
	var totalHour = actualTime[0];
	var totalMin = actualTime[1];
	const totalValue = totalHour * valor + (totalMin / 60) * valor;
	return totalValue.toFixed(2);
};
