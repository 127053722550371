import React, { ButtonHTMLAttributes } from 'react';
import { LoaderCircle } from './LoaderCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { LoaderButton, LoaderContainer, LoaderLoading } from './styled';

import '../../../i18n';
import { useTranslation } from 'react-i18next';

interface LoaderPaginationProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	showLoader: boolean;
	loading: boolean;
}

export const LoaderPagination = ({
	showLoader,
	loading,
	...rest
}: LoaderPaginationProps) => {
	const { t } = useTranslation();
	return (
		<>
			{showLoader ? (
				<div></div>
			) : (
				<LoaderContainer>
					<LoaderButton {...rest}>
						{loading ? (
							<LoaderLoading>
								<span>{t("Carregando")}</span>{' '}
								<span>
									<LoaderCircle />
								</span>
							</LoaderLoading>
						) : (
							<LoaderLoading>
								<span>{t("Carregar mais")}</span>
								<span>
									<FontAwesomeIcon icon={faAngleDown} />
								</span>
							</LoaderLoading>
						)}
					</LoaderButton>
				</LoaderContainer>
			)}
		</>
	);
};
