import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const HeaderSmall = styled.div`
    display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;

    .logo-planus-small {
        position: absolute;
        top: 15px;
        left: 35px;
    }
`;

interface MenuLabelProps {
    isCheked: boolean;
}

export const MenuLabel = styled.label<MenuLabelProps>`
    margin-bottom: 0;

    #open-close-small {
        border-radius: 10px;
        cursor: pointer;
        z-index: 5;
        background: rgba(0, 0, 0, 0.3);
        margin: 10px 10px 10px 0;
        width: 40px;
        height: 40px;
        transition: all 0.5s ease;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            box-shadow: inset 0 0 0 1px rgba(215, 242, 5, 0.26);
        }

        .bars-icon {
            color: #ffffff;
            display: ${({isCheked}) => isCheked ? 'none' : 'block'};;
        }

        .close-icon {
            color: #ffffff;
            display: ${({isCheked}) => isCheked ? 'block' : 'none'};;
        }
    }

    
`;

export const InputCheck = styled.input`
    display: none;
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        border: 2px solid #9cabb3;
        border-radius: 3px;
        background-color: white;
    }

    &:checked:after {
        content: '';
        display: block;
        width: 5px;
        height: 10px;
        border: solid #426172;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 6px;
    }
`;

interface ControlMeProps {
    isCheked: boolean;
}

export const ControlMe = styled.div<ControlMeProps>`
    margin: 0;
    padding: 0;
    height: ${({isCheked}) => isCheked ? '100vh' : '0px'};
    max-height: 100vh;
    transition: all 0.3s ease;
`;

interface TopBarContainerProps {
    isCheked: boolean;
}

export const TopBarContainer = styled.div<TopBarContainerProps>`
    position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	margin-top: 70px;
    overflow: ${({isCheked}) => isCheked ? 'auto' : 'hidden'};

    .top-bar {
        height: 100%;
        transition: all 0.5s ease;
        display: ${({isCheked}) => isCheked ? 'flex' : 'none'};
        flex-direction: column;
        text-decoration: none;
    }

    .top-bar a {
        text-decoration: none;
    }
`;

export const TopNavContainer = styled.div`
    display: flex;
	align-items: center;
	margin-top: 10px;
	padding: 5px 0 5px 0;
	cursor: pointer;

    &:hover {
        background-color: #3b5766;
    }

    .top-icons-container {
        margin-left: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
    }

    .top-sideBar-text {
        display: block;
        color: #bdcdd6;
        text-align: center;
        margin: 0 0 0 10px;
        padding: 0;
        font-size: 14px;
        font-weight: bold;
    }
`;

export const IconStyled = styled(FontAwesomeIcon)`
	color: white;
	font-size: 16px;
`;

export const ProfileContainer = styled.div`
    height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

    .top-profile-bottom {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 0 10px 70px 10px;
        padding: 20px 0px 20px 0px;
        background-color: #0000001a;
        cursor: pointer;
        border-radius: 10px;
    }   
`;

export const ProfileDefaultImage = styled.img`
    border-radius: 50%;
	width: 40px;
	height: 40px;
	margin-left: 20px;
	cursor: pointer;
	border: 1px solid var(--degradeR-btn-color);
`;

export const ProfileTextContainer = styled.div`
    margin-left: 20px;
	display: flex;
	flex-direction: column;

    .top-profile-text {
        color: var(--sidebar-text-color);
        font-size: var(--normal-text-size);
        font-weight: bold;
        margin-bottom: 2px;
        display: inline;
        visibility: visible;
    }

    .top-profile-text-description {
        color: var(--sidebar-text-color);
        font-size: var(--medium-text-size);
        margin: 0 0 0 8px;
        display: inline-block;
    }
`;

export const LogoutContainer = styled.div`
    display: flex;
	align-items: center;
    
    .top-faUser-icon {
        color: #ffffff;
        font-size: 12px;
        display: inline-block;
    }
`;