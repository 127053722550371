import React, { useCallback, useEffect, useState } from 'react';
import HeaderLink from '../../components/common/HeaderLink';
import { Responsible } from '../../models/Responsible';
import PessoaService from '../../services/providers/responsible';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import PersonFilter from './PersonFilter';
import PersonModal from './PersonModal';
import { acessoOptions } from './utils';
import { verifyPeopleManagment } from '../../utils/funcoes/validarPerfil';
import { useHistory } from 'react-router-dom';
import { SelectOptions } from '../../models/Select';
import formatObjectToFilter from '../../utils/funcoes/formatObjectToFilter';
import { ConfirmModal } from '../../components/containers/Modais/ConfirmModal';
import { ActivesCount } from './ActivesCount';
import { useAtom } from 'jotai';
import {
	extraColumnsAtom,
} from '../../atoms/PersonManagementAtoms';
import { useUser } from '../../contexts/User';
import { sortAlphabetically } from '../../utils/funcoes/sort.ts';
import TurTutorial from '../../components/tourTutorial';
import { FaCirclePlus } from 'react-icons/fa6';
import HeaderButton from '../../components/planus2/Buttons/HeaderButton/index.tsx';
import PersonList from './PersonList/index.tsx';
import { Company } from '../../models/Company.ts';
import CompanyService from '../../services/providers/company/index.ts';

let controller: AbortController | null = null;

export default function PersonManagement() {
	const [people, setPeople] = useState<Responsible[]>([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [acesso, setAcesso] = useState<SelectOptions>({
		label: t('Perfil'),
		value: '',
	});
	const [extraColumns] = useAtom(extraColumnsAtom);
	const [checked, setChecked] = useState<number[]>([]);

    //filters
    const [search, setSearch] = useState<string>('');
	const [isActive, setIsActive] = useState<boolean>(true);

	const [loadingDeleteAll, setLoadingDeleteAll] = useState(false);
	const { userDetails, loadingUser } = useUser();
	const [addIds, setAddIds] = useState<number[]>([]);

	const [loadingPeople, setLoadingPeople] = useState<boolean>(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState<null | number>(null);
    const [companyInfo, setCompanyInfo] = useState<Company>();

	const personService = new PessoaService();
	const companyService = new CompanyService();

	const history = useHistory();

	const handleAddPerson = (person: Responsible) => {
		if (extraColumns) {
			setAddIds((prev) => [...prev, person.id]);
			setPeople((prev) => sortAlphabetically([...prev, person], 'nome'));
		}
	};

	const handleCompanyInfo = () => {
		companyService.getCompany().then(result => {
            setCompanyInfo(result)
        });
	}

	const handleDeletePerson = (id: number) => {
		setPeople((prev) => prev.map((item) => {
			if (item.id === id) {
				return {
					...item,
					deleted: true,
				};
			}
			return item;
		}));
		setChecked((prev) => prev.filter((item) => item !== id));
		const getNewPeople = (prev: Responsible[]) => prev.filter(item => item.id !== id);
		setTimeout(() => {
			setPeople(prev => getNewPeople(prev))
		}, 1000);
	};

	const handleCheckAfterDelete = (id: number) => {
		const getCheckedFiltered = (prev: number[]) => prev.filter((item) => item !== id);
		setChecked((prev) => getCheckedFiltered(prev));
	}
	const deletePeople = async () => {
		setLoadingDeleteAll(true);
		await Promise.all(checked.map( async (id) => {
			const person = people.filter((item) => item.id === id)[0];
			personService.deletePessoa(
				id,
				(response) => {
					console.log("response", response);
					handleDeletePerson(id);					
					toast.success(
						`${t('Perfil de')} ${person.nome} ${t('desativado com sucesso')}`,
					);
					handleCompanyInfo();
				},
				() => {
					handleCheckAfterDelete(id);
					toast.error(
						t(`Ocorreu um erro ao desativar o perfil de ${person.nome}!`),
					);
				},
			);
		}));
	};
	useEffect(() => {
		handleCompanyInfo();
	}, [])


	useEffect(() => {
		if (checked.length === 0) {
			setLoadingDeleteAll(false);
		}
	}, [checked]);

	useEffect(() => {
		if (!loadingUser && userDetails) {
			const hasAccess = verifyPeopleManagment(userDetails?.acesso_cod);
			if (!hasAccess) {
				history.push('/home');
			}
		}
	}, [userDetails, loadingUser]);
	


	const handleToogleModal = useCallback(() => {
		setIsModalVisible((prev) => !prev);
	}, []);

	const getPeople = useCallback(
		async (
			page = 1,
			values: { search: string; acesso: string; deleted: boolean } = {
				acesso: formatObjectToFilter(acesso),
				search: search,
				deleted: !isActive,
			},
			additional = 'all=true',
		) => {
			if (page === 1) {
				setCurrentPage(1);
				setTotalPages(null);
			}

			setLoadingPeople(true);

			if (controller !== null) {
				controller.abort();
			}

			controller = new AbortController();
			const params = {
				search: values.search,
				acesso: values.acesso,
				deleted: values.deleted,
				additional: additional + '&serializer=gestao',
			};

			handleGetPessoa(page, params);
		},
		[PessoaService, people, extraColumns],
	);

	const handleGetPessoa  = (page: number, params: any) => {
		personService.getPessoasPaginacao(
			page,
			params,
			(response) => {
				if (response && !response.cancelled) {
					const tempPeople = response?.results;
					if (page === 1) {
						setPeople(tempPeople);
					} else {
						const existsPerson = tempPeople.find((item) =>
							addIds.includes(item.id),
						);
						if (existsPerson) {
							const prevPeople = people.filter(
								(item) => item.id !== existsPerson.id,
							);

							setPeople([...prevPeople, ...tempPeople]);
							const filterFunctionAddedIds = (id: number) => id !== existsPerson.id
							const filterAddedIds = (prev: number[]) => {
								return prev.filter(filterFunctionAddedIds);
							};
							setAddIds((prev) => filterAddedIds(prev));
						} else {
							setPeople((prev) => [...prev, ...tempPeople]);
						}
					}
					setCurrentPage(page);
					setTotalPages(response?.total_pages);
				}
				if (!response?.cancelled) {
					setLoadingPeople(false);
				}
			},
			() => {
				setLoadingPeople(false);
			},
			{ signal: controller?.signal },
		);
	}

	


	return (
		<div className={`flex flex-col gap-5 absolute left-0 right-0 bottom-0 top-0 ${loadingPeople ? 'cursor-progress' : ''}`}>
			{userDetails && !userDetails.has_finished_tour && (
				<TurTutorial nomeRota={'pessoas'} />
			)}
			<HeaderLink type="person">

			</HeaderLink>
			<PersonModal
					isShowingModal={isModalVisible}
					setIsShowingModal={setIsModalVisible}
					handleAfterPost={handleAddPerson}
				/>
			<div className="flex flex-row items-center justify-center gap-5">
				<PersonFilter
					acessoOptions={acessoOptions}
					getPeople={getPeople}
					loading={loadingPeople}
					acesso={acesso}
					isActive={isActive}
					search={search}
					setAcesso={setAcesso}
					setIsActive={setIsActive}
					setSearch={setSearch}
				/>
				<HeaderButton onClick={handleToogleModal} id="react-tour_add-person">
					<FaCirclePlus /> Pessoa
				</HeaderButton>
			</div>
			<PersonList 
				checked={checked}
				currentPage={currentPage}
				getPeople={getPeople}
				loadingPeople={loadingPeople}
				people={people}
				setChecked={setChecked}
				setIsConfirmModalOpen={setIsConfirmModalOpen}
				setPeople={setPeople}
				totalPages={totalPages}
			/>

			
			<ActivesCount companyInfo={companyInfo} />

			<ConfirmModal
				loading={loadingDeleteAll}
				text={'Desativar usuários selecionados?'}
				handleConfirm={deletePeople}
				isConfirmModalOpen={isConfirmModalOpen}
				setIsConfirmModalOpen={setIsConfirmModalOpen}
			/>
		</div>
	);
}
