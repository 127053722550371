import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	ModalConteudoGenerico,
	ModalPrincipalGenerico,
} from '../../../components/containers/Modais/ModalComSideGenerico';
import { useTranslation } from 'react-i18next';
import ObjetivoForm from './form';
import { ModalContainerObjetivo } from './style';
import { ObjetivoResponse } from '../../../services/providers/okrs/objetivo/types';
import ObjetivoService from '../../../services/providers/okrs/objetivo';
import { objetivosManagerAtom } from '../../../atoms/Okrs';
import { useAtom } from 'jotai';
import { toast } from 'react-toastify';

export interface ModalObjetivoProps {
	setModalShow: Dispatch<SetStateAction<boolean>>;
	objetivo?: ObjetivoResponse;
    refreshProgresso: ({timeId, objetivoId}: {timeId: number, objetivoId?: number}) => void
    getObjetivosPagination: (page:number, timeId: number) => Promise<void>
	editingObjetivo?: boolean;
}

export const ModalObjetivo = ({
	setModalShow,
	objetivo,
	editingObjetivo = false,
	getObjetivosPagination,
	refreshProgresso
}: ModalObjetivoProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
    const objetivosService = new ObjetivoService()
    const [objetivosManager, setObjetivosManager] = useAtom(objetivosManagerAtom)

	const deleteObjetivo = (id: number) => {	
		setLoading(true)	
		objetivosService.deleteObjetivo(
			id
		).then(async () => {
			toast.success(t('Objetivo removido com sucesso!'));

			if(objetivo) {
				setObjetivosManager(prev => {
					const objetivosSelected = prev.objetivosManager[objetivo.time]
					const tempObjetivos = [...(objetivosSelected?.objetivos ?? [])].filter(item => item.id !== id);
					
					return {
						objetivosManager: {
							...prev.objetivosManager,
							[objetivo.time]: {
								...objetivosSelected,
								objetivos: tempObjetivos,
							}
						},
					}
				});

				await getObjetivosPagination((objetivosManager.objetivosManager[id]?.currentPage ?? 1), objetivo.time)
				refreshProgresso({timeId: objetivo.time})
				setModalShow(false);
			}
		}).catch((err: any) => {
			toast.error(err)
		}).finally(() => {
			setLoading(false)
			setModalShow(false)	
		});
	};

	return (
		<ModalPrincipalGenerico modalGenericoShow={setModalShow}>
			<ModalContainerObjetivo>
				<ModalConteudoGenerico
					copyText=""
					itemId={objetivo?.id}
					title={editingObjetivo ? t('Edição de Objetivo') : t('Novo Objetivo')}
					modalGenericoShow={setModalShow}
					btnSave={true}
					loadingBtn={loading}
					modalIsShowing={true}
					saveAndContinue={false}
					handleDeletar={editingObjetivo ? deleteObjetivo : undefined}
					onClickSave={() => {
						document.getElementById('formSideModal-objetivo')?.click();
					}}
					onClickSaveAndContinue={() => {}}
					showEllipsis={true}
				>
					<ObjetivoForm
						objetivo={objetivo}
						editing={editingObjetivo}
						loading={loading}
						setLoading={setLoading}
						setModalShow={setModalShow}
					/>
				</ModalConteudoGenerico>
			</ModalContainerObjetivo>
		</ModalPrincipalGenerico>
	);
};
