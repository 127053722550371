import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faTable } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import './style.css';
import { useTranslation } from 'react-i18next';

export default function TotalHorasTarefas({
    registros,
    dataInicial,
    dataFinal,
    loadingGetHoras,
    getRelatorioCSV,
    getRelatorioXLSX,
    loadingRelatorioCSV,
	handlePrint
}) {
	const {t} = useTranslation();
    // Objeto extraido da requisição
    const [listTarefas, setListTarefas] = useState([]);
    const [dataInicialFinal, setDataInicialFinal] = useState('');
	const relatorioTitle = t("Total de Horas por tarefa")

    useEffect(() => {
        setDataInicialFinal(
            `${moment(dataInicial).format('L')} - ${moment(dataFinal).format('L')}`,
        );
    }, []);

    useEffect(() => {
        GenerateDataReport();
        setDataInicialFinal(
            `${moment(dataInicial).format('L')} - ${moment(dataFinal).format('L')}`,
        );
    }, [registros]);

    const GenerateDataReport = () => {
        const listTarefasAux = [];
        // FILTRO DE ID'S REPETIDOS E CRIAÇÃO DO ARRAY "ListTarefa"
        registros.forEach(registro => {
            // Tarefa
            let tarefaDuplicada =
                listTarefasAux.findIndex(tarefa => {
                    return  registro.tipo === 'trab' ? registro.tarefa === tarefa.id : registro.tarefa_nome == tarefa.tarefa_nome && registro.pessoa_nome == tarefa.pessoa_nome;
                }) !== -1;
            if (!tarefaDuplicada) {
                listTarefasAux.push({
                    id: registro.tarefa,
                    nome: registro.tarefa_nome,
                    projeto_nome: registro.projeto_nome,
                    tarefa_nome: registro.tarefa_nome,
                    admins: registro.admins,
                    pessoa_nome: registro.pessoa_nome,
                    registros: [],
                });
            }
        });
        // ADIÇÃO DE REGISTROS AOS OBJETOS DO ARRAY "ListTarefa"
        registros.forEach(registro => {
            listTarefasAux.forEach(tarefa => {
                if (registro.tipo === 'trab' ? registro.tarefa === tarefa.id : registro.tarefa_nome == tarefa.tarefa_nome && registro.pessoa_nome == tarefa.pessoa_nome) {
                    tarefa.registros.push(registro.duracao);
                }
            });
        });
        listTarefasAux.sort((a, b) =>{
          //Se nome do projeto for igual ordenar por nome da tarefa, se nao ordenar por nome do projeto
          if(a.projeto_nome === b.projeto_nome){
            return a.nome < b.nome ? -1 : 1
          }
          if(a.projeto_nome < b.projeto_nome){
            return -1;
          }
          // Se o nome do projeto for diferente, e o nome não for menor(vier antes) entao ele deve vir depois.
          return 1;
        })
        setListTarefas(listTarefasAux);
    };

    const calculaTotalHorasEspecifico = registros => {
            let totalHoras = 0;
            let totalMinutos = 0;
            let totalAbsoluto = 0;
			let totalSegundos = 0;


            registros.forEach(registro => {
                totalHoras = totalHoras + parseInt(registro.slice(0, 2));
                totalMinutos = totalMinutos + parseInt(registro.slice(3, 5));
				totalSegundos = totalSegundos + parseFloat(registro.slice(6, registro.length));
            });

			
			const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60);
			const minutes = ((tempTotalMinutos) % 60);
			let finalTotalMinutos = minutes < 10 ? `0${minutes}` : `${minutes}`;
			totalAbsoluto = `${totalHoras + parseInt(tempTotalMinutos / 60)}:${finalTotalMinutos}`;
			return totalAbsoluto;
	};

	const calculaTotalHorasGeral = () => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;
		let totalSegundos = 0;

		registros.forEach(registro => {
			totalHoras = totalHoras + parseInt(registro.duracao.slice(0, 2));
			totalMinutos = totalMinutos + parseInt(registro.duracao.slice(3, 5));
			totalSegundos = totalSegundos + parseFloat(registro.duracao.slice(6, registro.duracao.length));
		});
	

		const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60);
		const minutes = ((tempTotalMinutos) % 60);

		let finalTotalMinutos = minutes < 10 ? `0${minutes}` : `${minutes}`;

        totalAbsoluto = `${totalHoras + parseInt(tempTotalMinutos / 60)}:${finalTotalMinutos}`;

		return totalAbsoluto;
	};

	const handlePrintData = () => {
		const dataInfo = []

		let dataValues = listTarefas.map(tarefa => {
			const tempRow = {
				[t("Projeto")]: tarefa.projeto_nome,
				[t("Responsável")]: tarefa.pessoa_nome,
				[t("Tarefa")]: tarefa.nome,
				[t("Total")]: calculaTotalHorasEspecifico(tarefa.registros)
			}

			return tempRow
		})
	
		const totalRow = {
			[t("Projeto")]: "",
			[t("Responsável")]: "",
			[t("Tarefa")]: "",
			[t("Total")]: calculaTotalHorasGeral()
		}

		dataValues = [...dataValues, totalRow]

		const periodoString = dataInicialFinal
		
		handlePrint({
			dataInfo, 
			dataValues, 
			periodoString, 
			title: relatorioTitle
		})
	}


	return (
		<div id="printable">
			<div>
				<div className="relatorio-header-container">
					<p className="relatorio-title">{relatorioTitle}</p>

					<div style={{ display: 'flex' }}>
						<div
							onClick={() => getRelatorioCSV()}
							className="relatorio-print-container"
							disabled={true}
							style={{
								pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
								opacity: loadingRelatorioCSV ? '0.5' : '1',
							}}
						>
							<FontAwesomeIcon
								icon={faTable}
								className="print-icon-relatorio"
							/>
							<p className="print-text-relatorio">{t("Gerar CSV")}</p>
							{loadingRelatorioCSV ? (
								<span style={{ marginLeft: '4px' }}>
									<CarregandoDados />{' '}
								</span>
							) : (
								<></>
							)}
						</div>
						<div
							onClick={() => getRelatorioXLSX()}
							className="relatorio-print-container"
							disabled={true}
							style={{
								pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
								opacity: loadingRelatorioCSV ? '0.5' : '1',
							}}
						>
							<FontAwesomeIcon
								icon={faTable}
								className="print-icon-relatorio"
							/>
							<p className="print-text-relatorio">{t("Gerar Excel")}</p>
							{loadingRelatorioCSV ? (
								<span style={{ marginLeft: '4px' }}>
									<CarregandoDados />{' '}
								</span>
							) : (
								<></>
							)}
						</div>
						<div
							onClick={handlePrintData}
							className="relatorio-print-container"
						>
							<FontAwesomeIcon
								icon={faPrint}
								className="print-icon-relatorio"
							/>
							<p className="print-text-relatorio">{t("Imprimir")}</p>
						</div>
					</div>
				</div>

				{loadingGetHoras ? (
					<CarregandoDados />
				) : (
					<table className="relatorio-table">
						<thead>
							<tr>
								<th className="cabecalho-table-financeiro" colSpan="1">
									{dataInicialFinal}
								</th>
							</tr>
							<tr>
								<th className="cabecalho-periodo-totalhorasdiarias">{t("Projeto")}</th>
								<th className="cabecalho-periodo-totalhorasdiarias">{t("Responsável")}</th>
								<th className="cabecalho-periodo-totalhorasdiarias">{t("Tarefa")}</th>
								<th className="cabecalho-total">{t("Total")}</th>
							</tr>
						</thead>
						<tbody>
							{listTarefas.map((tarefa, index) => (
								<tr>
									<td className="tarefa-th-totalhorasdiarias">
										{tarefa.projeto_nome}
										{/* {index === 0 &&
											tarefa.admins.map(item => <div>{item.nome}</div>)} */}
									</td>
									<td className="tarefa-th-totalhorasdiarias">
										{/* {tarefa.admins.map(item => (
											<div>{item.nome}</div>
										))} */}
										{tarefa.pessoa_nome}
									</td>
									<td className="tarefa-th-totalhorasdiarias">{tarefa.nome}</td>
									<td className="total-row-registro-totalhorasdiarias">
										{calculaTotalHorasEspecifico(tarefa.registros)}
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<th className="span-table-botton-totalhorasdiarias" colSpan="3"></th>
								<th
									style={{
										background: '#5ad8e3',
										color: '#112631',
									}}
									id="total-table"
								>
									{calculaTotalHorasGeral()}
								</th>
							</tr>
						</tfoot>
					</table>
				)}
			</div>
		</div>
	);
}

const CarregandoDados = () => (
	<>
		<div className="loader-alinhamento">
			<div className="loader-conteudo"></div>
		</div>
	</>
);
