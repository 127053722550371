import styled, { keyframes} from "styled-components";
import { theme } from "../../../../../../../styles/theme";

export const SelectContainer = styled.div`
    background-color: white;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: relative;
`;

interface SuffixProps {
    valueLength: number;
}

export const Test = styled.div<SuffixProps>`
    position: absolute;
    left: ${({valueLength}) => valueLength + 2}ch;
    top: 3px;
`;

export const InputWrapper = styled.div`
    display: inline-block;
    position: relative;
    border: 1px solid ${theme.colors.text.sidebarText};
    border-radius: 7px;
    color: ${theme.colors.text.titleText};
    font-weight: 400;
    height: 30px;
`

export const Input = styled.input`
    color: ${theme.colors.text.titleText};
    font-weight: 400;
    line-height: 18px;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
    padding: 5px;
    outline: none;
    border: none;
    &:focus {
        outline: none;
    }
    &::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    };
    position: relative;
    background-color: transparent;
`


const animateTop = keyframes`
   from {
			margin-top: -200px;
			opacity: 0;
		}
    to {
        margin-top: 0;
        opacity: 1;
    }
`

export const SelectButton = styled.button`
    background-color: ${theme.colors.button.darkBtn};
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    &:hover {
        opacity: 0.8;
    }
`

export const PeriodText = styled.div`
    color: ${theme.colors.text.titleText};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    strong {
        text-align: center;
    }
`

export const PeriodModal = styled.div`
    position: absolute;
    top: 62px;
    right: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    color: ${theme.colors.text.subtitleText};
    font-weight: bold;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, 
                rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    transition: all 0.4s ease 0s;
    animation-name: ${animateTop};
    animation-duration: 0.3s;
    border: 1px solid #b1adad;
    z-index: 2;
`;

export const DatePickerContainer = styled.div`
    border-radius: 5px;

`

export const SaveButton = styled.button`
    background: ${theme.colors.button.degradeLBtn};
    border: none;
    border-radius: 20px;
    height: 40px;
    width: 120px;
    color: white;
    font-size: 13.5px;
    font-weight: 600;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    line-height: 16px;
    margin-right: 0px;
    outline: none !important;
`;

export const FormFooter = styled.div`
    border-top: 1px solid rgb(219, 227, 230);
    height: 20px;
    display: flex;
    align-items: flex-end;
`

export const ButtonContainer = styled.div`
    top: 2em;
    width: 100%;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
`

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    @media (max-width: 500px) {
            flex-direction: column;
            height: auto;
    }
`

export const ExportButton = styled.button`
    border: none;
    background-color: transparent;
    gap: 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    color: ${theme.colors.text.titleText};
    align-items: center;
    justify-content: center;
    &:hover {
        opacity: 0.8;
    }
`