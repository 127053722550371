import React from 'react';
import PlanusLogo from '../../../../assets/svgs/planus';

import './style.css';

const BannerLogin = ({ titulo, subTitulo, distanceBetweenLogo, logoWidth, logoMargin }) => (
	<div>
		<div className={logoMargin && `${logoMargin}`} style={{ width: logoWidth && `${logoWidth}` }}>
			<PlanusLogo />
		</div>
		<h4
			className="modal-addSenha-title"
			style={{
				fontWeight: '700',
				color: '#406173',
				lineHeight: '22px',
				marginTop: `${distanceBetweenLogo}px`,
			}}
		>
			{titulo}
		</h4>
		<h6 className="modal-addSenha-subtitle">{subTitulo}</h6>
	</div>
);

export default BannerLogin;
