import styled from 'styled-components';
import { theme } from '../../../../styles/theme';

export const FilterSelectContainer = styled.div`
	border-top: 1px solid ${theme.colors.input.borderBackgroundInput};
	@media (min-width: 320px) {
		border-top: none;
	}
	@media (min-width: 900px) {
		border-left: none;
	}
`;

export const OptionsWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const OptionLabel = styled.div`
	font-size: 15px;
	width: 100%;
`;

export const OptionContainer = styled.div`
	padding: 10px;
	cursor: pointer;
	&:hover {
		background-color: ${theme.colors.background.modalBg};
	}
`;

export const ValueContainerWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
`;

export const Label = styled.div`
	margin-left: 5px;
	font-weight: bold;
	color: ${theme.colors.background.mainBg};
`;
