import { AxiosResponse } from 'axios';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { t } from 'i18next';
import { History, TaskComment } from '../../../models/History';
import { LoadOptionsAdditional, SelectOptions } from '../../../models/Select';
import { LoadTaskData, Task } from '../../../models/Task';
import { formatTaskWithProjectToSelect } from '../../../utils/funcoes/reactSelect';
import HttpService from '../../config/http';
import { getDeliveryDateFilters } from '../../helpers/deliveryDate';
import { CallbackType, DataType } from '../types';
import { PostPatchParams, PostPatchResponse, UpdateTaskParams } from './types';

interface FilterProps {
	search: string,
	cliente: string | number,
	projeto: string | number,
	pessoa: string | number,
	autor: string | number,
	encerradas: string,
}

interface ExtraFilterProps extends FilterProps {
	page: number,
	tarefa: string | number,
	tags: string,
	status: string,
	startDate: Date | undefined,
	endDate: Date | undefined,
	signal?: AbortSignal
}

export default class TarefaService {
	private api: HttpService;

	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getTarefasProjeto = async (
		success: (response: Task[]) => void,
		errorCallback: CallbackType<string>,
		projetoId: number | string = '',
	) => {
		this.api
			.get(`api/v1/tarefas/?projeto=${projetoId}`)
			.then((response: AxiosResponse<Task[]>) => {
				if (response.status === 200) {
					success(response.data);
				} else {
					if (typeof response.data === typeof {}) {
						for (const [, value] of Object.entries(response.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as tarefas!"));
					}
				}
			})
			.catch(err => errorCallback(err));
	};

    getTarefas = (
        {
			search,
			cliente,
			projeto,
			pessoa,
			autor,
			encerradas,
		}: FilterProps,
        success: (response: Task[]) => void,
		errorCallback: CallbackType<string>
    ) => {
        this.api.get(`api/v1/tarefas/?ordering=-updated_at&search=${search}&cliente=${cliente}&projeto=${projeto}&responsaveis__in=${pessoa}&criador=${autor}&status=${encerradas}`).then((result: AxiosResponse<Task[]>) => {
            if (result.status === 200) {
                success(result.data);
            } else {
                if (typeof result.data === typeof {}) {
                    const objError = result.data;
                    for (const key in objError) {
                        throw new Error(`${objError[key]}`);
                    }
                } else {
                    throw new Error(t("Erro ao tentar carregar as tarefas!"));
                }
            }
        }).catch((err) => errorCallback(err));
    }

    getTarefasPaginacao = (
		{
			page,
			search,
			cliente,
			projeto,
			pessoa,
			autor,
			encerradas,
			tarefa,
			tags,
			status,
			startDate,
			endDate,
			signal
		}: ExtraFilterProps,
        success: (response: DataType<Task[]>) => void,
		errorCallback: CallbackType<string>
	) => {
        const {data_conclusao, data_conclusao__gte,data_conclusao__lte} = getDeliveryDateFilters(status, startDate, endDate);
		this.api
			.get(
				`api/v1/tarefas/?page=${page}&paginate=${page}&id=${tarefa}&ordering=-updated_at&search=${search}&cliente__in=${cliente}&projeto__in=${projeto}&responsaveis__in=${pessoa}&criador__in=${autor}&status=${encerradas}&tags=${tags}&data_conclusao=${data_conclusao}&data_conclusao__gte=${data_conclusao__gte}&data_conclusao__lte=${data_conclusao__lte}`,
				{
					signal: signal
				}
			)
			.then((result: AxiosResponse<DataType<Task[]>>) => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key as keyof typeof objError]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as tarefas!"));
					}
				}
			})
			.catch(error => {
				if (error.name === 'AbortError') {
					console.log('Requisição cancelada');
				} else {
					errorCallback(error.toString())
				}
			});
	};

    getTarefasSelect = (
        cliente: string | number,
        projeto: string | number,
        pessoa: string | number,
        success: (response: SelectOptions[]) => void,
		errorCallback: CallbackType<string>
        ) => {
		this.api
			.get(
				`api/v1/tarefas/?cliente=${cliente}&responsaveis__in=${pessoa}&projeto=${projeto}&ordering=-updated_at&minimal=true`,
			)
			.then((result: AxiosResponse<SelectOptions[]>) => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar as tarefas!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	loadTarefasSelect = async (
		search: string,
		loadedOptions: OptionsOrGroups<any, GroupBase<any>>,
		{
            page = 1,
            placeholder = '',
            cliente = '',
            projeto = '',
            pessoa = '',
            isProjectSelected = false
        }: LoadOptionsAdditional,
	) => {
		const response: AxiosResponse<LoadTaskData> = await this.api.get(
			`api/v1/tarefas/?cliente=${cliente}&responsaveis__in=${pessoa}&projeto=${projeto}&ordering=-updated_at&minimal=true&paginate=true&page=${page}&search=${search}`,
		);
		if (response.status !== 200) {
			throw new Error(t("Erro ao tentar carregar as tarefas!"));
		}

		return {
			options: formatTaskWithProjectToSelect(response?.data?.results, placeholder, isProjectSelected),
			hasMore: !!response?.data?.next,
			additional: {
				cliente,
				projeto,
				page: page + 1,
				pessoa,
			},
		};
	};

	postTarefa = (params: PostPatchParams,  success: (response: PostPatchResponse) => void,
    errorCallback: CallbackType<string>) => {
		this.api
			.post(`api/v1/tarefas/${params.kanban ? '?serializer=kanban' : ''}`, params)
			.then((result: AxiosResponse<PostPatchResponse>) => {
				if (result.status === 201) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar salvar a tarefa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	patchTarefa = (params: PostPatchParams, id: number | string, success: (response: PostPatchResponse) => void ,
     errorCallback: CallbackType<string>) => {
		this.api
			.patchFile(`api/v1/tarefas/${id}/?all=true`, params)
			.then(result => {
				if (result.status === 200) {
					success(result.data);

				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar a tarefa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	encerrarTarefa = ({errorCallback, id, success}: UpdateTaskParams) => {
		this.api
			.patch(`api/v1/tarefas/${id}/?all=true`, { status: 'done' })
			.then((result: AxiosResponse) => {
				if (result.status === 200) {
					success(result);
				} else if (result.status === 403) {
					throw new Error(t("Sem permissão. O usuário não é um responsável pela tarefa, o criador ou um administrador.!"));
				}
				else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar a tarefa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
	reabrirTarefa = ({id, success, errorCallback}: UpdateTaskParams) => {
		this.api
			.patch(`api/v1/tarefas/${id}/?status=done`, { status: 'started' })
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar tarefa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteTarefa = ({id, success, errorCallback}: UpdateTaskParams) => {
		this.api
			.delete(`api/v1/tarefas/${id}/?all=true`)
			.then(result => {
				if (result.status === 204 || result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar remover a tarefa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getTarefaHistorico = (id: number | string, success: (response: History[]) => void, errorCallback: CallbackType<string>) => {
		this.api
			.get(
				`api/v1/tarefas-planus/historic/${id}/`,
			)
			.then((result: AxiosResponse<History[]>) => {
				if (result.status === 200) {
					success(result.data);
				} else {
					if (typeof result.data === typeof {}) {
						const objError = result.data;
						for (const key in objError) {
							throw new Error(`${objError[key]}`);
						}
					} else {
						throw new Error(t("Erro ao tentar carregar o histórico da tarefa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	postTarefaComentario = (params: {descricao: string, tarefa: string | number}, success: (response: AxiosResponse<TaskComment>) => void, errorCallback: CallbackType<string>) => {
		this.api
			.post(`api/v1/comentario-tarefa/`, params)
			.then(result => {
				if (result.status === 201) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar salvar o comentário!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	deleteTarefaComentario = (id: number | string, success: (response: AxiosResponse<string>) => void, errorCallback: CallbackType<string>) => {
		this.api
			.delete(`api/v1/comentario-tarefa/${id}/`)
			.then(result => {
				if (result.status === 204) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar remover a tarefa!")); 
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};

	getTarefa = (id: number | string, success: (response: AxiosResponse<Task>) => void, errorCallback: CallbackType<string>) => {
		this.api.get(`api/v1/tarefas/${id}?all=true`).then((result: AxiosResponse<Task>) => {
			if (result.status === 200) {
				success(result)
			} else {
				if (typeof result.data === typeof {}) {
					for (const [, value] of Object.entries(result.data)) {
						throw new Error(`${value}`);
					}
				} else {
					throw new Error(t("Erro ao tentar acessar a tarefa!"));
				}
			}
		}).catch((error) => errorCallback(error.toString()))
	}

	patchTarefaComentario = (params: {descricao: string}, id: number | string, success: (response: AxiosResponse<TaskComment>) => void, errorCallback: CallbackType<string>) => {
		this.api
			.patchFile(`api/v1/comentario-tarefa/${id}/`, params)
			.then(result => {
				if (result.status === 200) {
					success(result);
				} else {
					if (typeof result.data === typeof {}) {
						for (const [, value] of Object.entries(result.data)) {
							throw new Error(`${value}`);
						}
					} else {
						throw new Error(t("Erro ao tentar atualizar a tarefa!"));
					}
				}
			})
			.catch(error => errorCallback(error.toString()));
	};
}

