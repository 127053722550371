import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Header = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	margin-bottom: 20px;
`;

export const InfoBtnBack = styled(Link)`
	width: 130px;
	height: 47px;
	border: none;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;
	color: #426172;
	-webkit-user-select: none;
	user-select: none;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	&:hover {
		outline: none;
		border: none;
		color: #426172;
		text-decoration: none;
	}
	@media (max-width: 768px) {
		width: 67px;
	}
`;

export const InfoBackIcon = styled(FontAwesomeIcon)`
	font-size: 12px;
	margin-right: 15px;
`;

export const ActionsMenuContainer = styled.div`
	display: flex;
	flex: 1 1 0%;
	justify-content: space-between;
`;

export const TaskTitleContainer = styled.div`
	display: flex;
`;

export const TaskTitle = styled.div`
	margin: 0 0 0 20px;
	white-space: nowrap;
	display: flex;
	padding: 0;
	font-weight: 700;
	color: #406173;
	font-size: 16px;
	align-self: center;
	@media (max-width: 1050px) {
		white-space: normal;
	}
`;

export const TaskId = styled.div`
	margin: 0;
	padding: 0;
	font-weight: 700;
	color: #406173;
	font-size: 16px;
	align-self: center;
	margin-left: 30px;
`;
