import React, { useCallback, useState } from 'react';
import TarefaService from '../../../../../services/providers/task';

import { toast } from 'react-toastify';

import { useDataTask } from '../../../../../contexts/InfoTarefa/DataTask';
import './style.css';
import { Task } from '../../../../../models/Task';
import { useFilters } from '../../../../../contexts/Filter/Task/Filter';
import formatArrayToFilter from '../../../../../utils/funcoes/formatArrayToFilter';
import { t } from 'i18next';
import { useUser } from '../../../../../contexts/User';
import Table from '../../../../../components/common/Table';
import DefaultCell from '../../../../../components/common/Table/Cells/DefaultCell';
import TitleSubtitleCell from '../../../../../components/common/Table/Cells/TitleSubtitleCell';
import PeopleCell from '../../../../../components/common/Table/Cells/PeopleCell';
import DateCell from '../../../../../components/common/Table/Cells/DateCell';
import ActionsCell from '../../../../../components/common/Table/ActionsCell';
import { TbClockPlus, TbReload } from 'react-icons/tb';
import { Check, Edit } from '@mui/icons-material';
import TCellSelectAll from '../../../../../components/common/Table/TCellSelectAll';
import TRow from '../../../../../components/common/Table/TRow';
import { useHistory } from 'react-router-dom';
import { dinamicPagination } from '../../../../../utils/funcoes/dinamicPagination';

interface ListProps {
	loadingEncerrarTarefa: boolean;
	setEditingHoras: React.Dispatch<React.SetStateAction<boolean>>;
	setHoraEditada: React.Dispatch<React.SetStateAction<Task | undefined>>;
	setModalHorasShow: (value: boolean) => void;
	setEditingTarefa: React.Dispatch<React.SetStateAction<boolean>>;
	setTarefaEditada: React.Dispatch<React.SetStateAction<Task | undefined>>;
	setModalTarefaShow: React.Dispatch<React.SetStateAction<boolean>>;
	handleEncerrar: (id: string | number) => void;
	converterDuracaoTotal: (hour: string) => string;
	reopenTask: (id: string | number) => void;
}

const List = ({
	converterDuracaoTotal,
	setEditingHoras,
	setHoraEditada,
	setModalHorasShow,
	setEditingTarefa,
	setTarefaEditada,
	setModalTarefaShow,
	loadingEncerrarTarefa,
	handleEncerrar,
	reopenTask,
}: ListProps) => {
	const { dataTask } = useDataTask();
	const [isSelectAll, setIsSelectAll] = useState(false);
	const [checkedIds, setCheckedIds] = useState<string[]>([]);
	const history = useHistory();

	const { loadingTasks, totalPages, pages, getTasksPagination } = useDataTask();
	const { loadingUser } = useUser();
	const { selectedProject } = useFilters();
	const [finishTaskLoad, setFinishTaskLoad] = useState(false);
	const _encerrarTarefa = (id: number | string) => {
		setFinishTaskLoad(true);
		const tarefaService = new TarefaService();
		tarefaService.encerrarTarefa({
			id: id,
			success: (response) => {
				if (response) {
					getTasksPagination();
					encerrarTarefaAnimacao(id);
					toast.success(`${t('Tarefa encerrada com sucesso')}!`);
				}
				setFinishTaskLoad(false);
			},
			errorCallback: (error) => {
				toast.error(error);
				setFinishTaskLoad(false);
			},
		});
	};

	const encerrarTarefaAnimacao = (id: number | string) => {
		const rowEncerrada = document.getElementById(`tarefa-row${id}`);
		if (rowEncerrada) {
			rowEncerrada.style.transition = 'all 0.3s';
			rowEncerrada.style.opacity = '0%';
			setTimeout(() => {
				rowEncerrada.style.display = 'none';
			}, 600);
		}
	};

	const endMultiTasks = useCallback(
		(tarefas: string[]) => {
			tarefas.forEach((tarefa) => _encerrarTarefa(tarefa));
			setCheckedIds([]);
		},
		[_encerrarTarefa],
	);

	const handleLoaderPagination = useCallback(() => {
		const selected = Array.isArray(selectedProject)
			? selectedProject
			: [selectedProject];
		getTasksPagination(formatArrayToFilter(selected) || '', pages + 1);
	}, [selectedProject, pages, getTasksPagination]);

	const handleSelectAll = () => {
		if (isSelectAll) {
			setIsSelectAll(false);
			setCheckedIds([]);
		} else {
			setIsSelectAll(true);
			setCheckedIds(dataTask.map((item) => item.id.toString()));
		}
	};

	const handleCheck = (id: string) => {
		setCheckedIds((prev) => {
			if (prev.includes(id)) {
				return prev.filter((item) => item !== id);
			} else {
				return [...prev, id];
			}
		});
	};

	const handleAddHour = (data: Task) => {
		setEditingHoras(true);
		setHoraEditada(data);
		setModalHorasShow(true);
	};

	const handleEditHour = (data: Task) => {
		setEditingTarefa(true);
		setTarefaEditada(data);
		setModalTarefaShow(true);
	};

	const handleCloseTask = (id: string | number) => {
		if (!loadingEncerrarTarefa) {
			handleEncerrar(id);
		}
	};

	const handleCloseTasks = useCallback(() => {
		endMultiTasks(checkedIds);
	}, [endMultiTasks, checkedIds]);

	const handleGoToDetails = (data: Task) => {
		history.push(
			`tarefas/${data.id}/${data.nome
				.replace(/\s/g, '')
				.replace(/[/\\]/g, '')}`,
			{ task: data, urlBack: history.location.pathname },
		);
	};

	return (
			<Table
				className={`xl:table-fixed ${finishTaskLoad ? 'cursor-progress' : 'cursor-default'}`}
				headerData={[
					{ title: 'ID', width: '5%' },
					{ title: 'Nome da tarefa', width: '35%' },
					{ title: 'Responsáveis', width: '15%' },
					{ title: 'Data Criação', width: '10%' },
					{ title: 'Data Entrega', width: '10%' },
					{ title: 'Horas gastas', width: '10%' },
					{ title: 'Ações', width: '11%' },
				]}
				selectAll={{
					actions: (
						<Check className="text-ivory-primary" onClick={handleCloseTasks} />
					),
					onSelectAll: handleSelectAll,
				}}
				pagination={{
					showLoader: totalPages === pages,
					loading: loadingTasks || loadingUser,
					onClick: (e) => dinamicPagination({element: e?.target, callBack: handleLoaderPagination, loading: loadingTasks, page: pages, totalPages }),
				}}
			>
				{dataTask.map((data) => {
					return (
						<TRow key={data.id} id={`tarefa-row${data.id}`}>
							<DefaultCell>{data.id.toString()}</DefaultCell>
							<TitleSubtitleCell
								onClick={() => handleGoToDetails(data)}
								title={data.nome}
								subtitle={data.projeto_nome ?? ''}
								className={data.status === 'done' ? 'line-through' : undefined}
							/>
							<PeopleCell people={data.pessoas_responsaveis} />
							<DateCell
								date={data.created_at ? new Date(data.created_at) : new Date()}
							/>
							<DateCell date={new Date(data.data_conclusao)} isDelivery />
							<DefaultCell>
								{data.duracao_total
									? converterDuracaoTotal(data.duracao_total)
									: '00h 00m'}
							</DefaultCell>
							<ActionsCell
								icons={
									data.status === 'done'
										? [
												{
													icon: <TbReload size={16} />,
													onClick: () => reopenTask(data.id),
												},
											]
										: [
												{
													icon: <TbClockPlus size={15} />,
													onClick: () => handleAddHour(data),
												},
												{
													icon: <Edit fontSize={'small'} />,
													onClick: () => handleEditHour(data),
												},
												{
													icon: <Check />,
													onClick: () => handleCloseTask(data.id),
												},
											]
								}
							/>
							<TCellSelectAll
								onCheck={() => handleCheck(data.id.toString())}
								checked={checkedIds.includes(data.id.toString())}
								onClick={() => {}}
							/>
						</TRow>
					);
				})}
			</Table>
	);
};

export default List;
