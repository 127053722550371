import { faCheck, faPen, faTrash, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../containers/Modais/ConfirmModal';

import '../../../i18n';

import CopyButton from '../CopyButton';
import './style.css';
import { ActionsMenuContainer, ButtonContainer, IconActions } from './styled';

interface ActionsMenuProps {
	editOption?: boolean;
	handleEdit?: () => void;
	deleteOption?: boolean;
	handleDelete?: () => void;
	finishOption?: boolean;
	handleFinish?: () => void;
	reactivateOption?: boolean;
	handleReactivate?: () => void;
	hasSeparator?: boolean;
	copyOption?: boolean;
	copyText?: string;
}

export function ActionsMenu({
	editOption = true,
	handleEdit = () => {},
	deleteOption = true,
	handleDelete = () => {},
	finishOption = true,
	handleFinish = () => {},
	hasSeparator = true,
	copyOption = false,
	copyText = '',
	handleReactivate = () => {},
	reactivateOption = false
}: ActionsMenuProps) {
	const { t } = useTranslation();
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

	const handleDeleteConfirm = useCallback(() => {
		setIsConfirmModalOpen(false);
		handleDelete();
	}, [setIsConfirmModalOpen, handleDelete]);

	const handleOpenConfirmModal = useCallback(() => {
		setIsConfirmModalOpen(true);
	}, [setIsConfirmModalOpen]);

	return (
		<>
			<ConfirmModal
				handleConfirm={handleDeleteConfirm}
				isConfirmModalOpen={isConfirmModalOpen}
				setIsConfirmModalOpen={setIsConfirmModalOpen}
			/>
			<ActionsMenuContainer hasSeparator={hasSeparator}>
				{copyOption && (
					<CopyButton
						textToCopy={copyText}
						iconTitle={t('Copiar Tarefa')}
						containerClassname={'actions-menu-button'}
						className="icon-actions"
					/>
				)}
				{editOption && (
					<ButtonContainer onClick={handleEdit} title={t('Editar').toString()}>
						<IconActions icon={faPen} />
					</ButtonContainer>
				)}
				{deleteOption && (
					<ButtonContainer
						onClick={handleOpenConfirmModal}
						title={t('Deletar').toString()}
					>
						<IconActions icon={faTrash} />
					</ButtonContainer>
				)}
				{finishOption && (
					<ButtonContainer
						onClick={handleFinish}
						title={t('Encerrar').toString()}
					>
						<IconActions icon={faCheck} />
					</ButtonContainer>
				)}
				{reactivateOption && (
					<ButtonContainer
						onClick={handleReactivate}
						title={t('Reativar').toString()}
					>
						<IconActions icon={faPowerOff} />
					</ButtonContainer>
				)}
			</ActionsMenuContainer>
		</>
	);
}
