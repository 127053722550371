import React, { HTMLAttributes } from "react";

interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {}

export function ClientsIcon(props: IconProps) {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path d="M14 10.5V12.1797H12.3203V10.5H14ZM14 7.17969V8.82031H12.3203V7.17969H14ZM15.6797 13.8203V5.5H9V7.17969H10.6797V8.82031H9V10.5H10.6797V12.1797H9V13.8203H15.6797ZM7.32031 3.82031V2.17969H5.67969V3.82031H7.32031ZM7.32031 7.17969V5.5H5.67969V7.17969H7.32031ZM7.32031 10.5V8.82031H5.67969V10.5H7.32031ZM7.32031 13.8203V12.1797H5.67969V13.8203H7.32031ZM4 3.82031V2.17969H2.32031V3.82031H4ZM4 7.17969V5.5H2.32031V7.17969H4ZM4 10.5V8.82031H2.32031V10.5H4ZM4 13.8203V12.1797H2.32031V13.8203H4ZM9 3.82031H17.3203V15.5H0.679688V0.5H9V3.82031Z" fill="white"/>
</svg>
  )
}