import React from 'react';
import styled from 'styled-components';
import { SelectOptions } from '../../../../../models/Select';
import defaultImage from '../../../../../assets/images/company.png';
import { BiX} from 'react-icons/bi';

interface ProjectWrapperProps {
	isSelected: boolean;
}

const ProjectOptionWrapper  = styled.div<ProjectWrapperProps>`
	display: flex;
	align-items: center;
	padding: 12px 0;
	margin: 0 24px;
	gap: 24px;
	border-bottom: 1px solid rgba(66, 97, 114, 0.3);
	font-weight: 700;
	width: 100%;
	img {
		width: 100%;
		max-width: 36px;
		max-height: 36px;
		object-fit: contain;
	}

	.icon-container {
		width: 100%;
		max-width: 36px;
		max-height: 36px;
		object-fit: contain;
	}
	
	.label-content {
		display: flex;
		flex-direction: column;
		.client-span {
			font-size: 0.688rem;
		}	
	}
	
`;

export interface ProjectOptionProps {
	data: SelectOptions;
	isSelected: boolean;
}

export const ProjectOption = (props: ProjectOptionProps) => {
	const {data, isSelected} = props;
	const image = data.image ? data.image : defaultImage;
	return (
		<>
			{data.value === '' ? (
				<ProjectOptionWrapper isSelected={isSelected}>
					<div className='icon-container'>
						<BiX size={36}  />
					</div>
					<div className='label-content'>
					<span>Limpar filtro</span>
					</div>
				</ProjectOptionWrapper>
			) : (
				<ProjectOptionWrapper isSelected={isSelected}>
					<img src={image} alt="" />
					<div className='label-content'>
						<span>{data.label}</span>
						<span className='client-span'>{data.labelAux}</span>
					</div>	
				</ProjectOptionWrapper>
			)}
		</>
	);
};
