import React, { useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ComentarioTarefaSchema } from './validation';
import {
	ButtonAddHour,
	ButtonContainer,
	ButtonSave,
	IconHour,
	InfoTarefaComment,
} from './styled';
import { t } from 'i18next';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useStopwatch } from '../../../../../../../contexts/Stopwatch';
import { Task } from '../../../../../../../models/Task';

interface HistoricoFormProps {
	handleSubmitComentario: (values: { descricao: string }) => void;
	task: Task;
	handlePostPatchHora: () => void;
}

export default function HistoricoForm({
	handleSubmitComentario,
	task,
	handlePostPatchHora
}: HistoricoFormProps) {

	const { handleToggleModal, setPreSelectedRecord, setHandleAfterPostPatch } = useStopwatch();
	const initialValues = {
		descricao: '',
	};

	const handleSubmitHistorico = useCallback(
		(
			values: { descricao: string },
			actions: FormikHelpers<{
				descricao: string;
			}>,
		) => {
			actions.setSubmitting(true);
			handleSubmitComentario(values);
		},
		[handleSubmitComentario],
	);

	const handleAddHour = useCallback(() => {
		setHandleAfterPostPatch(() => handlePostPatchHora);
		setPreSelectedRecord({projeto: task.projeto, projeto_nome: task.projeto_nome || '', tarefa: parseInt(task.id.toString()), tarefa_nome: task.nome })
		handleToggleModal(true);
	}, [setPreSelectedRecord, task, handleToggleModal, setHandleAfterPostPatch, handlePostPatchHora ]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={ComentarioTarefaSchema}
			enableReinitialize={true}
			onSubmit={handleSubmitHistorico}
		>
			<Form>
				<InfoTarefaComment
					name="descricao"
					id="descricao"
					placeholder={`${t('Insira o comentário aqui')}...`}
					autoComplete={'off'}
					type="text"
					component="textarea"
				/>
				<ButtonContainer>
					<ButtonSave type="submit" title={t('Salvar')}>
						{t('Salvar')}
					</ButtonSave>

					<ButtonAddHour
						title={t('Adicionar Registro de Hora')}
						onClick={handleAddHour}
					>
						<IconHour icon={faClock} />
					</ButtonAddHour>
				</ButtonContainer>
			</Form>
		</Formik>
	);
}
