import { components } from "react-select";
import styled from "styled-components";

export const SelectedWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 8px;
`;

export const KanbanValueContainer = styled(components.ValueContainer)`
	display: flex !important;
	gap: 16px;
	align-items: center;
	overflow: hidden;
	width: min-content;
	padding-left: 20px !important;
	gap: 16px;
	align-items: center;

	.kanban-search-icon {
		display: none;

		@media (min-width: 1200px) {
			display: initial;
		}
	}
`;

export const ContainerDefault = styled.div`
	-webkit-box-align: center;
		align-items: center;
		display: grid;
		flex: 1 1 0%;
		flex-wrap: wrap;
		padding: 0px 2px 0px 0px;
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
		box-sizing: border-box;
		margin: 0px;
		font-size: 0.875rem;
		color: rgb(66, 97, 114);
		min-height: 24px !important;
		height: 24px !important;
`;
