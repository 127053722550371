import React, { useCallback, useEffect, useRef, useState } from 'react';

import { t } from 'i18next';
import { GroupBase, MultiValue, SingleValue } from 'react-select';
import ToggleSwitch from '../../../../components/common/ToggleSwitch';

import FilterContainer from '../../../../components/containers/Filter';
import { Option } from '../../../../components/containers/Filter/Option';
import { SelectFilterContainer } from '../../../../components/containers/Filter/styled';
import { LoadOptionsAdditional, SelectOptions } from '../../../../models/Select';
import { selectFilterStyle } from '../../../../utils/extenal-libs';
import AsyncSelector from '../../../../components/common/AsyncSelector';
import { useUser } from '../../../../contexts/User';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { initialValues, useProject } from '../../../../contexts/Project';
import { useProjectFilters } from '../../../../contexts/Filter/Project';
import { LoadOptions } from 'react-select-async-paginate';
import { MultiValueContainer } from '../../Tarefas/filtro/MultiValueContainer';
import { useLocation } from 'react-router-dom';

export interface ProjectsFilterProps {
}

export function ProjectsFilter() {
	const {userDetails} = useUser();
	const location = useLocation();

    const filterOptions: SelectOptions[] = userDetails?.acesso_cod === 'a' ? [
		{label: t('Clientes'), value: 'client' },
        {label: t('Ativos'), value: 'ativo'},
        {label: t('Meus projetos'), value: 'meusProjetos'},
    ] : [
		{ label: t('Clientes'), value: 'client' },
	]


    const [openFilters, setOpenFilters] = useState(filterOptions);
	const {
		activeOnly, 
		setActiveOnly, 
		clienteValorSelecionado, 
		setClienteValorSelecionado,
		myProjects,
		setMyProjects,
		loadingProjects,
		setSearchProject,
		searchProject,
		getProjects
	} = useProject();
	const {clientOptions, loadClientesSelect} = useProjectFilters(); 

    const resetFilters = useCallback(() => {
        setActiveOnly(true);
        setMyProjects(false);
        setClienteValorSelecionado(initialValues.clienteValorSelecionado);
    }, [])

    const elementsRef = {
        ativo: useRef<HTMLDivElement>(null),
        meusProjetos: useRef<HTMLDivElement>(null),
        client: useRef<HTMLDivElement>(null)
    }

    const checkVisible = (value: keyof typeof elementsRef) => {
		const hasValue = (item: SelectOptions) => item.value === value; 
		const isVisible = openFilters.some(hasValue)
		return isVisible;
	}

	const handleChangeClient = useCallback((e: MultiValue<SelectOptions> | SingleValue<SelectOptions>) => {
		setClienteValorSelecionado(e as SelectOptions)
	}, [setClienteValorSelecionado])

	const handleChangeActive = () => {
        setActiveOnly((prev: boolean) => !prev);
    }


	const handleChangeMyProjects = () => {
        setMyProjects((prev: boolean) => !prev);
    }

	const formatComponents = (): Partial<SelectComponents<SelectOptions, boolean, GroupBase<SelectOptions>>> => {
		return {MultiValueContainer, Option}
	}

	useEffect(() => {
		setOpenFilters(filterOptions)
	}, [userDetails]);

	const handleSearch = useCallback(() => {
		getProjects();
	}, [getProjects])

    return (
        <FilterContainer
			filterOptions={filterOptions}
			handleSearch={handleSearch}
			loadingFilters={loadingProjects}
			openFilters={openFilters}
			resetFilters={resetFilters}
			searchFilter={searchProject}
			selectedFilters={{...useProject()}}
			setOpenFilters={setOpenFilters}
			setSearchFilter={setSearchProject}
			search={true}
		>

			{checkVisible('client') && 
				(<SelectFilterContainer  ref={elementsRef.client}>
					<AsyncSelector
						loadOptions={loadClientesSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional>}
						additional={{
							page: 1,
							hasActiveProjects: activeOnly || undefined,
							placeholder: t('Todos'),
						}}
						components={formatComponents()}
						value={clienteValorSelecionado}
						isClearable={false}
						onChange={handleChangeClient}
						placeholder={t('Clientes')}
						style={selectFilterStyle}
						options={clientOptions}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						blurInputOnSelect={false}
					/>
				</SelectFilterContainer>)
			}

			{checkVisible('meusProjetos') && 
				<ToggleSwitch 
					checked={myProjects} 
					setChecked={handleChangeMyProjects} 
					label={t("Meus projetos")} 
				/>
            }

            {checkVisible('ativo') && location.pathname !== '/registros/projetos-kanban' && 
				<ToggleSwitch 
					checked={activeOnly} 
					setChecked={handleChangeActive} 
					label={t("Apenas ativos")} 
				/>
            }

    </FilterContainer>
    )
}