import React, { useEffect, useState } from 'react';
import { Client } from '../../models/Client';
import ClienteService from '../../services/providers/client';
import { toast } from 'react-toastify';
import { LoaderCircle } from '../../components/common/loaders/LoaderCircle';
import { ClientFilter } from './Filtro';
import { GenericButton } from '../../components/planus2/Buttons/GenericButton';
import { PlusCircleIcon } from '../../assets/svgs/iconsComponents/PlusCircleIcon';
import { ClientModal } from './Modal';
import {
	clientPageFiltersAtom,
	clientsPagesItemsAtom,
} from '../../atoms/Client';
import { useAtom } from 'jotai';
import HeaderLink from '../../components/common/HeaderLink';

export function ClientesPage() {
	const [clients, setClients] = useAtom(clientsPagesItemsAtom);
	const [selectedClient, setSelectedClient] = useState<Client>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [clientFilter, setClientFilter] = useAtom(clientPageFiltersAtom);
	const clienteService = new ClienteService();

	const openEditModal = (client: Client) => {
		setSelectedClient(client);
		setIsModalOpen(true);
	};

	const openNewModal = () => {
		setSelectedClient(undefined);
		setIsModalOpen(true);
	};

	const getClientes = () => {
		setClientFilter((prev) => ({
			...prev,
			loading: true,
		}));

		clienteService.getClientes(
			(response) => {
				if (response.data) {
					setClients({
						clients: response.data,
					});
				}
				setClientFilter((prev) => ({
					...prev,
					loading: false,
				}));
			},
			(error) => {
				setClients({
					clients: [],
				});
				setClientFilter((prev) => ({
					...prev,
					loading: false,
				}));
				toast.error(error);
			},
			clientFilter.search,
		);
	};

	useEffect(() => {
		getClientes();
	}, []);

	return (
		<>
			<HeaderLink type={'client'} />

			
	

      <div className="flex gap-7 py-5">
        <ClientFilter handleSearch={getClientes} />

        <GenericButton className="bg-ivory-primary w-72 h-10 text-white font-black" onClick={openNewModal}>
          <PlusCircleIcon className="fill-white" />
          Novo Cliente
        </GenericButton>
      </div>

      <div className='overflow-auto pb-2'>
        <div className="grid grid-cols-2 2xl:grid-cols-8 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-7">
          {clients.clients.map(client => {
            return (
              <button 
                key={`client-${client.id}`} 
                className="rounded-xl bg-white border border-zinc-400 h-32 pt-2 flex flex-col gap-4 items-center hover:brightness-95" 
                onClick={() => openEditModal(client)}
              >
                <div>
                  <img src={client.logo_cliente} alt={client.nome} className="rounded-full border border-zinc-400 w-12 h-12" />
                </div>

                <p className="font-black text-center px-3 line-clamp-2" title={client.nome}>{client.nome}</p>
              </button>
            )
          })}
        </div>
        {clientFilter.loading && (
          <div className="py-6">
            <LoaderCircle />
          </div>
        )}
      </div>
      {isModalOpen && <ClientModal
				closeModal={() => setIsModalOpen(false)}
				isModalOpen={isModalOpen}
				client={selectedClient}
			/>}
    </>
  )
}
