import React, { useState } from 'react';

import Login from '../index';
import AuthService from '../../../services/security/auth';
import { RecuperarSenhaSchema } from './validation.js';
import ButtonForm from '../components/ButtonForm/index.js';
import BannerLogin from '../components/BannerLogin/index.js';
import FormInput from '../components/FormInput/index.js';

import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import './style.css';

import '../../../i18n';
import { useTranslation } from 'react-i18next';

export const EsqueciSenha = props => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);
	const [enviadoSucesso, setEnviadoSucesso] = useState(false);
	const initialValues = { email: '' };

	const _esqueciSenha = values => {
		const service = new AuthService();
		setLoading(true);
		const form = new FormData();
		for (var key in values) {
			form.append(key, values[key]);
		}

		service.esqueciSenha(
			form,
			response => {
				setLoading(false);
				setEnviadoSucesso(true);
				toast.success(
					t("Pedido realizado com sucesso! Em instances enviaremos o link para o email informado"),
				);
			},
			error => {
				toast.error(error);
				setLoading(false);
			},
		);
	};

	return (
		<Login>
			<div className="login-limiter">
				<Formik
					initialValues={initialValues}
					validationSchema={RecuperarSenhaSchema}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						actions.setSubmitting(true);
						_esqueciSenha(values);
					}}
				>
					{({ errors, touched }) => (
						<Form className="login-form-container">
							{!enviadoSucesso ? (
								<BannerLogin
									titulo={t("Esqueci a senha")}
									subTitulo={t("Confirme o email e enviaremos para você um link para criação de uma nova senha")}
								/>
							) : (
								<BannerLogin
									titulo={t("Pedido enviado com sucesso!")}
									subTitulo={t("Dentro de instantes enviaremos o código de acesso para seu e-mail")}
								/>
							)}

							<FormInput
								nome="E-mail"
								campo="email"
								errors={errors.email}
								touched={touched.email}
								disabled={enviadoSucesso}
							/>
							<ButtonForm
								loading={loading}
								enviadoSucesso={enviadoSucesso}
								btnType="enviar"
							/>
						</Form>
					)}
				</Formik>
			</div>
		</Login>
	);
};
