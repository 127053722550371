import React from 'react';
import { Responsible } from '../../../models/Responsible';
import profileImage from '../../../assets/images/profile.png';
import { t } from 'i18next';
import { IoPersonAddSharp } from 'react-icons/io5';

function calculateOverResponsaveis(team: Responsible[] | undefined, max: number) {
	const overResponsaveis = team ? team.length - max : 0;
	const overResponsaveisSlice = overResponsaveis > 0 ? overResponsaveis * -1 : undefined;
	const slicedResponsaveis = team
		? team.slice(0, overResponsaveisSlice)
		: [];
	const overResponsaveisNames = team
		? team.slice(overResponsaveis * -1).map((resp) => resp.nome)
		: [];
	return {overResponsaveis, slicedResponsaveis, overResponsaveisNames}
}
  
interface TeamImagesProps {
	team?: Responsible[];
	max?: number;
	onAdd?: () => void;
	imageSize?: string;
    className?: string;
}
export default function TeamImages({
	team,
	imageSize,
	max = 4,
	onAdd,
    className
}: Readonly<TeamImagesProps>) {
	const {overResponsaveis, overResponsaveisNames, slicedResponsaveis} = calculateOverResponsaveis(team, max);
	return (
		<div className={`flex ${className}`}>
			{team ? (
				<>
					<div className='flex'>
                    {slicedResponsaveis.map((item) => {
						return (
							<img
								className={`${imageSize ?? 'h-6 w-6'} rounded-full`}
								key={item.id}
								src={item.foto_perfil ? item.foto_perfil : profileImage}
								title={item.nome}
								alt={''}
								style={{
									marginRight: slicedResponsaveis.length === 1 ? '' : '-4px',
								}}
							/>
						);
					})}
					{overResponsaveis > 0 && (
						<span
							className={`flex text-sm items-center justify-center ${
								imageSize ?? 'h-6 w-6'
							} rounded-full bg-ivory-primary text-white font-semibold`}
							title={overResponsaveisNames.join(', ')}
						>
							+{overResponsaveis}
						</span>
					)}
                    </div>
					{onAdd && (
						<span
							className={`flex items-center justify-center ${
								imageSize ?? 'h-6 w-6'
							} rounded-full bg-ivory-primary-light  text-white font-bold`}
							title={overResponsaveisNames.join(', ')}
						>
							<button
								className="flex items-start justify-center border-none bg-transparent text-white p-0"
								type="button"
								title={t('Atribuir').toString()}
								onClick={onAdd}
							>
								<IoPersonAddSharp
									size={12}
									className="text-white cursor-pointer"
								/>
							</button>
						</span>
					)}
				</>
			) : (
				<>
					<img
						className={`${imageSize ?? 'h-6 w-6'} rounded-full cursor-progress`}
						src={profileImage}
						alt={''}
						style={{
							marginRight: slicedResponsaveis.length === 1 ? '' : '-4px',
						}}
					/>
					<img
						className={`${imageSize ?? 'h-6 w-6'} rounded-full cursor-progress`}
						src={profileImage}
						alt={''}
						style={{
							marginRight: slicedResponsaveis.length === 1 ? '' : '-4px',
						}}
					/>
					<img
						className={`${imageSize ?? 'h-6 w-6'} rounded-full cursor-progress`}
						src={profileImage}
						alt={''}
						style={{
							marginRight: slicedResponsaveis.length === 1 ? '' : '-4px',
						}}
					/>
					<img
						className={`${imageSize ?? 'h-6 w-6'} rounded-full cursor-progress`}
						src={profileImage}
						alt={''}
						style={{
							marginRight: slicedResponsaveis.length === 1 ? '' : '-4px',
						}}
					/>
				</>
			)}
		</div>
	);
}
