import React, { ReactElement } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IconProps {
	icon: IconProp;
	title?: string;
	type?: 'iconBtn';
	colorIcon?: string;
	fontSizeIcon?: string;
	pointer?: boolean;
	className?: string;
	style?: object;
}

const Icon = ({
	title,
	type,
	colorIcon,
	fontSizeIcon,
	pointer,
	...rest
}: IconProps): ReactElement => {
	const iconStyle = {
		iconBtn: { marginRight: '15px' },
	};

	return (
		<FontAwesomeIcon
			title={title}
			{...rest}
			style={
				(type && iconStyle[type],
				colorIcon ? { color: colorIcon } : undefined,
				fontSizeIcon ? { fontSize: fontSizeIcon } : undefined,
				pointer ? { cursor: 'pointer' } : undefined)
			}
		/>
	);
};

export default Icon;
