import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../ToggleSwitch";

interface ToggleCellProps {
    checked: boolean;
    onToggle: () => void;
}

export default function ToggleCell({checked, onToggle}: Readonly<ToggleCellProps>) {

    const [value, setValue] = useState(checked);

    useEffect(() => {
        setValue(checked)
    }, [checked])

    const handleToggle = () => {
        setValue(prev => !prev);
        onToggle();
    }
    return <td>
        <ToggleSwitch
            checked={value}
            setChecked={handleToggle}
        ></ToggleSwitch>
    </td>
}