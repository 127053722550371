import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

interface KanbanItemProps {
    readonly index: number;
    readonly id: string;
    readonly children: React.ReactNode;
    readonly requestingAdd: {
        readonly componentId: number | null
    };
}

export default function KanbanItem({children, id, index, requestingAdd}: KanbanItemProps) {
    const isRequestingAdd = requestingAdd.componentId === parseInt(id);
    return  (<div className='border-ivory-primary-lighter border border-solid rounded-default' style={{cursor: isRequestingAdd ? 'progress' : 'initial'}}>
            <div className={`${isRequestingAdd ? 'pointer-events-none' : 'pointer-events-auto'}`}>
                <Draggable key={id} draggableId={id} index={index}>
                    {(provided, snapshot) => {
                        return <div className={`min-h-[120px] flex text-white ${snapshot.isDragging ? 'bg-slate-400' : 'bg-[#F4F5F7]'} rounded overflow-hidden`}  ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} style={{...provided.draggableProps.style}} >
                            {children}
                        </div>
                    }
                    }
                </Draggable>
            </div>
        </div>)

}