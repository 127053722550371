import React, { useCallback, useEffect, useState } from "react";
import ToggleSwitch from "../../../../common/ToggleSwitch";
import { Td } from "./styled";

interface TableCellToggleProps {
    name: string;
    value: boolean;
    width?: string;
    id?: number | string;
    disabled?: boolean;
    title?: string;
    onClick: (value: string | number | boolean, name: string, id?: number | string) => void;
}

export default function TableCellToggle({name, onClick, value,disabled, id, width}: TableCellToggleProps) {
    const [checked, setChecked ] = useState(value);
    
    useEffect(() => {
        setChecked(value)
    }, [value]);

    const handleChecked = useCallback(() => {
        setChecked(prev => !prev)
        onClick(!value, name, id);
    }, [value, name, id])
    

    return(
        <Td width={width}>
            <ToggleSwitch 
                checked={checked} 
                setChecked={handleChecked}
                disabled={disabled} 
            />
        </Td>
    )
}