import { HoursRecord } from "../../../../../../models/HoursRecord";

export const calculateCurrentValue = (horas_realizadas: string, preco: number) => {
    const splitHours = horas_realizadas.split(':');
    const valueHour = parseInt(splitHours[0]) * preco;
    const valueMinute = parseInt(splitHours[1])/60 * preco;
    const valueSecond = parseInt(splitHours[1])/3600 * preco;
    const total = valueHour + valueMinute + valueSecond;
    return total;
}

export const extractHour = (hoursRecord: HoursRecord[]) => {
    const totalHours = hoursRecord.reduce((accumulator, currentHour) => {
        const splitHour = currentHour.duracao.split(":");
        return accumulator + parseInt(splitHour[0]);
    }, 0);
    const totalMinutes = hoursRecord.reduce((accumulator, currentHour) => {
        const splitHour = currentHour.duracao.split(":");
        return accumulator + parseInt(splitHour[1]);
    }, 0);
    const totalSeconds = hoursRecord.reduce((accumulator, currentHour) => {
        const splitHour = currentHour.duracao.split(":");
        return accumulator + parseInt(splitHour[2]);
    }, 0);

    const seconds = totalSeconds % 60;
    const finalSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const minutes = (totalMinutes + Math.floor(seconds / 60));
    const finalMinutes = (minutes % 60) < 10 ? `0${minutes % 60}` : minutes % 60;
    const hours = totalHours + Math.floor(minutes / 60);
    const finalHours = hours < 10 ? `0${hours}` : hours;

    return `${finalHours}:${finalMinutes}:${finalSeconds}`;

}